import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ElectronService, LoadingSpinnerService } from '@core/services';
import { DesktopSettings } from '@shared/models';

@Component({
  templateUrl: './desktop-settings-dialog.component.html',
  styleUrls: ['./desktop-settings-dialog.component.scss'],
})
export class DesktopSettingsDialog implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    autoLaunch: false,
    startMinimized: { value: false, disabled: true },
  });
  isElectron = this.electron.isElectronApp;
  ipcRenderer = this.electron.ipcRenderer;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DesktopSettingsDialog>,
    private electron: ElectronService,
    private spinner: LoadingSpinnerService
  ) {}

  ngOnInit() {
    if (!this.isElectron) {
      this.dialogRef.close();
    }

    this.spinner.show();
    this.ipcRenderer.send('getDesktopSettings', {});

    this.ipcRenderer.on('desktopSettings', (evt, arg: DesktopSettings) => {
      this.form.controls['autoLaunch'].patchValue(arg.autoLaunchEnabled);
      if (arg.autoLaunchEnabled) {
        this.form.controls['startMinimized'].enable();
        const startMinimized =
          localStorage.getItem('start_minimized') === 'true';
        this.form.controls['startMinimized'].patchValue(startMinimized);
      }
      this.spinner.hide();
    });
  }

  toggleAutoLaunch(checked: boolean) {
    if (checked) {
      this.form.controls['startMinimized'].enable();
    } else {
      if (this.form.controls['startMinimized'].value === true) {
        this.form.controls['startMinimized'].patchValue(false);
      }
      this.form.controls['startMinimized'].disable();
    }
  }

  save() {
    this.spinner.show();
    const controls = this.form.controls;
    const settings: DesktopSettings = {
      autoLaunchEnabled: controls['autoLaunch'].value,
      startMinimized: controls['startMinimized'].value,
    };

    localStorage.setItem('start_minimized', settings.startMinimized.toString());
    this.ipcRenderer.send('setDesktopSettings', settings);
    this.spinner.hide();
    this.dialogRef.close();
  }
}
