import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements OnChanges {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;
  @Input() isVisible = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible) {
      changes.isVisible.currentValue
        ? this.menuTrigger?.openMenu()
        : this.menuTrigger?.closeMenu();
    }
  }
}
