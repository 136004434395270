<!-- Actions Trigger -->
<header>
  <h2>{{ 'details' | translate }}</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</header>

<!-- Show user info -->
<main [ngClass]="{ dark: themeService.isDarkTheme() }">

  <!-- Avatar -->
  <div class="avatar-info">
    <app-avatar [type]="data.type" (click)="showImgOnFullScreen()" [photoUrl]="getAvatar()"></app-avatar>
  </div>

  <!-- Name and email -->
  <div class="primary-info">
    <span>{{ getDisplayName() }} </span>
    <span>
      {{ getEmail() }}
      <mat-icon *ngIf="!isRoom()" (click)="copyToClipboard(getEmail())">file_copy</mat-icon>
    </span>
  </div>

  <!-- Extension, sector, status -->
  <div class="secondary-info" *ngIf="!isRoom(); else roomMembers">
    <span><strong> {{ 'extension' | translate }}: </strong>{{ data.user?.extension }} </span>
    <span *ngIf="!!data.user?.companySector">
      <strong>{{ 'sector' | translate }}:</strong> {{ data.user?.companySector }}
    </span>
    <span *ngIf="canShowExtensionStatus()">
      <strong>{{ 'statusTelephony' | translate }}:</strong> {{ getUserExtensionStatus() | translate }}
    </span>
    <span *ngIf="canShowCollaborationStatus()">
      <strong>{{ 'statusCollaboration' | translate }}:</strong> {{ getUserStatus() | translate }}
    </span>
  </div>

</main>

<!-- Ng template - Room members -->
<ng-template #roomMembers>
  <div class="members-info">
    <div class="member row" *ngFor="let member of members">
      <app-contact-info [clickable]="true" [infos]="['status', 'sector']" [contact]="member" [showStatus]="true"
        [isAvatarSmall]="false" [isSmall]="true"></app-contact-info>
      <span *ngIf="isRoomAdmin(member)">{{'administrator' | translate}}</span>
    </div>
  </div>
</ng-template>
