export const ExternalContactTypes = [ 
    'cellPhone',
    'work',
    'extension',
    'personal',
    'other',
    'serviceNumber'
]

export const EXTERNAL_CONTACTS_IMPORT_LIMIT = 7000;

export const NUMBER_OF_CONTACTS_TO_FETCH = 40;