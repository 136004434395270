<h1 mat-dialog-title>{{ 'desktopOutdated' | translate }}</h1>

<mat-dialog-content class="mat-typography column">
  <!-- Current version -->
  <span>{{ 'currentVersion' | translate }}: <strong>{{ data.version }}</strong></span>

  <!-- Latest version -->
  <span>{{ 'latestVersion' | translate }}: <strong>{{ data.newVersion }}</strong></span>

  <!-- Break line -->
  <br>

  <!-- Desc -->
  <p [innerHTML]="'desktopOutdatedDesc' | translate"></p>
</mat-dialog-content>

<mat-dialog-actions align="end">

  <button mat-raised-button mat-dialog-close type="button" class="btn-warning" color="primary"
    (click)="downloadNewDesktopVersion()" style="text-transform: uppercase;">
    {{ 'download'| translate }}
  </button>

</mat-dialog-actions>
