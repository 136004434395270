import { Component, OnInit } from '@angular/core';
import { ElectronService } from '@core/services';
import {
  APP_STORE_URL,
  GOOGLE_PLAY_STORE_URL,
  LINUX_DOWNLOAD_URL,
  WINDOWS_DOWNLOAD_URL
} from '@shared/constants';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  linuxDownloadUrl = LINUX_DOWNLOAD_URL;
  windowsDownloadUrl = WINDOWS_DOWNLOAD_URL;
  googlePlayStoreUrl = GOOGLE_PLAY_STORE_URL;
  appStoreUrl = APP_STORE_URL;

  winMouseOver = false;
  linuxMouseOver = false;
  androidMouseOver = false;
  iosMouseOver = false;

  constructor(private electron: ElectronService) { }

  ngOnInit(): void {
  }

  /**
   * Verify current platform to show component only on web platform.
   *
   * @returns True if current platform equal to web
   */
  public checkPlatform(): boolean {
    return !this.electron.isElectronApp;
  }
}
