import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
  @Input() photoUrl: string;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() type: 'user' | 'group' | 'meet' | 'chatgpt' = 'user';
  @Input() isSmall: boolean = false;
  @Input() noRegister: boolean = false;

  constructor() { }

  ngOnInit(): void { }

  /**
   * On image error
   * @param event Event
   */
  public onImgError(event): void {
    event.target.src = this.getDefaultAvatar();
  }

  /**
   * Get default avatar
   * @returns {string} Default avatar
   */
  public getDefaultAvatar(): string {
    switch (this.type) {
      // If is chatgpt room
      case 'chatgpt':
        return 'assets/icons/chatgpt.svg';

      // If room is DM
      case 'user':
        return 'assets/images/avatar.svg';

      // If room is meet group
      case 'meet':
        return 'assets/images/scheduled-avatar.svg';

      // Default group avatar
      default:
        return 'assets/images/group-avatar.svg';
    }
  }

  /**
   * Emit loaded event
   */
  public contentLoaded() {
    if (this.loaded) this.loaded.emit(true);
  }
}
