import { User } from "./user.model";

export interface Tenant {
  active: boolean;
  clusterId: string;
  createdAt?: Date;
  email?: string;
  domainId: string;
  imgUrl: string;
  id: string;
  loginOptions: { enable: boolean; name: string }[];
  maxUsersLimit: number;
  name: string;
  needToSync: boolean;
  partner: string;
  recordingEnabled: boolean;
  collaborationEnabled: boolean;
  simultcalls: string;
  tenantId: string;
  language: string;
  updatedAt?: Date;
  users: User[];
  usersCount: number;
  trialTime?: any;
  trialTimeEnabled?: boolean;
  planType?: PlanTypes;
  pbxIntegration?: any;  
  messageModificationMaxTime?: MessageModificationMaxTimeOptions;

}

export enum PlanTypes {
  TRIAL = "trial",
  SHOWCASE = "showcase",
  CONTRACT = "contract",
  CLIENT = "client"
}

export enum MessageModificationMaxTimeOptions {
  Unlimited = 'unlimited',
  Never = 'never',
  OneMinute = 'one_minute',
  FiveMinutes = 'five_minutes',
  TenMinutes = 'ten_minutes',
  FifteenMinutes = 'fifteen_minutes'
}