import { Injectable } from '@angular/core';
import { Database, DatabaseReference, off, onValue, ref, set } from '@angular/fire/database';
import {
  AuthService,
  ClientService,
  DesktopService,
  ElectronService,
  RoomService,
  TenantService
} from '@core/services';
import { formMatrixFromUserId, isRoomMuted } from '@shared/utils';
import { MatrixEvent, Room } from 'matrix-js-sdk';
import { CachedReceipt, ReceiptType } from 'matrix-js-sdk/lib/@types/read_receipts';

@Injectable({
  providedIn: 'root',
})
export class UnreadService {
  private markedAsUnreadRef: DatabaseReference;
  private roomsMarkedAsUnread = new Set<string>();

  constructor(
    private clientService: ClientService,
    private tenantService: TenantService,
    private database: Database,
    private authService: AuthService,
    private electron: ElectronService,
    private desktopService: DesktopService,
    private roomService: RoomService,
  ) { }

  /**
   * Get Database Unread Rooms Path
   * @returns {string} Unread Rooms path
   */
  private getPathOfRoomsMarkedAsUnread(): string {
    return `companies/${this.tenantService.tenant.id}/users/${this.authService.userId}/rooms/markedAsUnread`
  }


  /**
   * Mark room as unread
   * @param {string} roomId Room id to mark as unread
   */
  public updateMarkRoomAsUnread(roomId: string, remove: boolean = false): Promise<void> {
    // Check if room is already marked as unread an remove it
    remove
      ? this.roomsMarkedAsUnread.delete(roomId)
      : this.roomsMarkedAsUnread.add(roomId);

    // Set
    return set(this.markedAsUnreadRef, this.getRoomsMarkedAsUnread());
  }

  /**
   * Listening rooms marked as unread
   */
  public listeningRoomsMarkedAsUnread(): void {
    // Form path
    const path = this.getPathOfRoomsMarkedAsUnread();

    // Database connections ref
    this.markedAsUnreadRef = ref(this.database, path);

    // Get inicial values
    onValue(this.markedAsUnreadRef, (snapshot) => {
      this.roomsMarkedAsUnread.clear();
      snapshot.val()?.forEach((roomId: string) => this.roomsMarkedAsUnread.add(roomId));
    });
  }

  /**
   * Get rooms marked as unread
   * @returns {string[]} Rooms marked as unread
   */
  private getRoomsMarkedAsUnread(): string[] {
    return Array.from(this.roomsMarkedAsUnread);
  }

  /**
   * Has room marked as unread
   * @param {string} roomId Room id
   */
  public hasRoomMarkedAsUnread(roomId: string): boolean {
    return this.roomsMarkedAsUnread.has(roomId);
  }

  /**
   * Stop Listening
   */
  public stopListeningMarkedAsUnread(): void {
    try {
      off(this.markedAsUnreadRef);
    } catch (error) { }
  }

  /**
   * Mark room as unread
   * @param {string} roomId Room id
   */
  public markAsUnread(roomId: string) {
    this.updateMarkRoomAsUnread(roomId, false);
  }

  /**
  * Mark last event on timeline as read
  * @param {string} roomId Room id
  */
  public async markAsRead(roomId: string | Room): Promise<void> {
    try {
      // Get matrix client
      const matrixClient = this.clientService.getClient();

      // Get room
      const room = roomId instanceof Room ? roomId : matrixClient.getRoom(roomId);

      // Return if room not exist
      if (!room) return;

      // Update unread messages
      this.updateMarkRoomAsUnread(room.roomId, true);

      // Get Last event
      let latestEvent = room.getLastLiveEvent();

      // Return if last event not exist
      if (!latestEvent) return;

      // Check if current user is the sender
      if (latestEvent.getSender() === matrixClient.getUserId()) {
        // Get last event send by other user
        latestEvent = [...room.getLiveTimeline().getEvents()]
          .reverse()
          .find(e => e.getSender() !== matrixClient.getUserId()) ?? latestEvent;
      }

      // Get matrix user id
      const mUserId = formMatrixFromUserId(this.authService.userId);

      // Get Readers
      const readers: CachedReceipt[] = room?.getReceiptsForEvent(latestEvent) ?? [];

      // Check if current user read the message
      const iRead = readers.find(r => r.userId == mUserId);

      // Return if current user read the message
      if (iRead) return;

      // Confirm reception
      await this.sendReadReceipt(latestEvent);

      // Set electron counter
      if (this.electron.isElectronApp)
        this.desktopService.updateBadge(this.getUnreadChatsCount() - 1);

    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Get Unread Chats Count
   * @returns {number} Number of unread msgs in all chats
   */
  public getUnreadChatsCount(): number {
    // Init count
    let count = 0;

    // Get rooms
    const directs = this.roomService.getDMsRooms();
    const groups = this.roomService.getGroupsRooms();

    // Set chats
    const chats: Room[] = directs.concat(groups);

    // For each chat - Get unread rooms
    chats.forEach((chat) => {
      if (this.getUnreadMsgsCount(chat) > 0) count++;
    });

    // Return count
    return count;
  }

  /**
   * Get Count Of Unread Groups
   * @returns {number} Number of Unread Groups
   */
  public getCountOfUnreadGroups(): number {
    // Init count
    let count = 0;

    // For each group - Get unread rooms
    this.roomService.getGroupsRooms()?.forEach((chat) => {
      if (this.getUnreadMsgsCount(chat) > 0) count++;
    });

    // Return count
    return count;
  }

  /**
   * Get Count Of Unread DMs
   * @returns {number} Number of Unread DMs
   */
  public getCountOfUnreadDMs(): number {
    // Init count
    let count = 0;

    // For each DM - Get unread rooms
    this.roomService.getDMsRooms()?.forEach((chat) => {
      if (this.getUnreadMsgsCount(chat) > 0) count++;
    });

    // Return count
    return count;
  }


  /**
   * Send read receipt
   * @param {MatrixEvent} event Matrix Event
   */
  public async sendReadReceipt(event: MatrixEvent): Promise<any> {
    // Confirm reception
    try {
      // Get matrix client
      const matrixClient = this.clientService.getClient();

      // Init promises
      let promises = [];

      // Send read receipt
      promises.push(matrixClient.sendReadReceipt(event));

      // Send private read receipt
      promises.push(matrixClient.sendReceipt(event, ReceiptType.Read, {}, true));

      // Send private read receipt
      return await Promise.allSettled(promises);
    } catch (e) {
      console.error('sendReadReceipt', e);
    }
  }


  /**
   * Check if room is muted
   * @param {string} roomId Room id
   * @returns {boolean} True if room is muted
   */
  public isRoomMuted(roomId: string): boolean {
    // Get matrix client
    const mx = this.clientService.getClient();

    // Check if room is muted
    return !!isRoomMuted(mx, roomId);
  }

  /**
   * Get unread messages from room
   * @param {Room} room Room id
   * @returns {number} Unread messages
   */
  public getUnreadMsgsCount(room: Room): number {
    return this.getUnreadMsgs(room);
  }

  /**
   * Get unread messages info from room
   * @param {Room} room Room id
   * @returns {UnreadCount} Unread messages info
   */
  public getUnreadMsgs(room: Room): number {
    // Get room
    return room?.getUnreadNotificationCount() ?? 0;
  }
}
