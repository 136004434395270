import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Database,
  DatabaseReference,
  OnDisconnect,
  onDisconnect,
  onValue,
  push,
  ref,
  remove,
  serverTimestamp,
  ThenableReference,
  Unsubscribe
} from '@angular/fire/database';
import { AuthService, ContactService } from '@core/services';
import { UserExtensionLocalPresence, UserPresence, UserPresenceDevice } from '@shared/enums';
import { Presence, User } from '@shared/models';
import {
  getConnectionsFunctionsPath,
  getLSStatusConnectionKey,
  getLSTenantId,
  hasLSRegisterError,
  setLSStatusConnectionKey
} from '@shared/utils';
import 'firebase/auth';
import { ElectronService } from 'ngx-electron';
import { lastValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserPresenceService {
  private hasInitialized = false;
  private canCreateConnection = true;
  private newConnectionRef: ThenableReference = null;
  private connectionRef: DatabaseReference = null;
  private onDisconnectRef: OnDisconnect = null;
  private connectionUnsubscribe: Unsubscribe = null;
  public hasConnection: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private database: Database,
    private authService: AuthService,
    private contactService: ContactService,
    private electronService: ElectronService
  ) { }

  /**
   * Initialize user presence service
   */
  public initialize(): void {
    // If has already initialized, return
    if (this.hasInitialized) return;

    // Get tenant id
    const tenantId = this.authService.user.companyId ?? getLSTenantId();

    // Subscribe to connection status
    this.subscribeConnection(tenantId);
  }

  /**
   * Subscribe to connection status
   * @param {string} tenantId Tenant id | Company id
   */
  private subscribeConnection(tenantId: string): void {
    // Return if has already initialized
    if (this.hasInitialized) return;

    // Set hasInitialized as true to prevent multiple subscriptions
    this.hasInitialized = true;

    // Log
    console.log('Listening for connection status...');

    // Get connection key from local storage
    const connectionKey = getLSStatusConnectionKey();

    // User id
    const uid = this.authService.user.id;

    // Database connections ref
    this.connectionRef = connectionKey
      ? ref(this.database, `companies/${tenantId}/users/${uid}/connections/${connectionKey}`)
      : ref(this.database, `companies/${tenantId}/users/${uid}/connections`);

    if (connectionKey) {
      // Set has connection as true
      this.hasConnection.next(true);

      // Listen for changes in connection status
      this.connectionUnsubscribe = onValue(this.connectionRef, (snap) => {
        if (snap.val() === null && this.canCreateConnection) {
          // We're disconnected! Do anything here that should happen only if online (or on reconnect)
          // Add this device to my connections list
          const currentPresence = this.getPresenceFromUser();

          // Set the current user's presence status for this device
          if (currentPresence && currentPresence.force)
            this.setPresence(currentPresence.status as UserPresence, currentPresence.force);
          else
            this.setPresence(UserPresence.AVAILABLE);
        }
      });
    }
    else {
      // Database connected ref
      const connectedRef = ref(this.database, '.info/connected');

      // Listen for changes in connection status
      this.connectionUnsubscribe = onValue(connectedRef, (snap) => {
        if (snap.val() === true && this.canCreateConnection) {
          // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
          this.newConnectionRef = push(this.connectionRef);

          // Save connection key to local storage
          setLSStatusConnectionKey(this.newConnectionRef.key);

          // When I disconnect, remove this device
          this.onDisconnectRef = onDisconnect(this.newConnectionRef);
          this.onDisconnectRef.remove();

          // Add this device to my connections list
          const currentPresence = this.getPresenceFromUser();

          // Set the current user's presence status for this device
          if (currentPresence && currentPresence.force)
            this.setPresence(currentPresence.status as UserPresence, currentPresence.force);
          else
            this.setPresence(UserPresence.AVAILABLE);

          // Set has connection as true
          this.hasConnection.next(true);
        }
      });
    }
  }

  /**
   * Get user in contacts
   * @param {string} uid User id
   * @returns {User} User
   */
  public getUser(uid: string): User {
    return this.contactService.contacts$.getValue()?.find((user) => user.id === uid);
  }

  /**
   * Set all connections status
   * @param {UserPresence} status User presence status
   * @param {boolean} force Force status regardless of priority
   */
  public setAllConnections(status: UserPresence, force: boolean = false) {
    this.setPresence(status, force, true);
  }

  /**
   * Set user presence status
   *
   * @param status New status
   * @param force Force status regardless of priority
   * @returns
   */
  async setPresence(status: UserPresence, force: boolean = false, isAll: boolean = false): Promise<void> {
    // Get tenant id
    const companyId = this.authService.user.companyId;

    // Get user id
    const userId = this.authService.userId;

    // Get path
    const url = getConnectionsFunctionsPath();

    // Prepare body
    const body = {
      status,
      force,
      timestamp: serverTimestamp(),
      companyId,
      userId,
      connectionId: getLSStatusConnectionKey(),
      device: this.getPresenceDevice()
    };

    // Remove connection id if isAll
    if (isAll) delete body.connectionId;

    // Make request
    return lastValueFrom(this.http.put<void>(url, body))
  }

  /**
   * Get user presence device
   * @returns {UserPresenceDevice} User presence device
   */
  public getPresenceDevice(): UserPresenceDevice {
    return this.electronService.isElectronApp ? UserPresenceDevice.DESKTOP : UserPresenceDevice.WEB;
  }

  /**
   * Delete status
   */
  public deletePresence(): Promise<void> {
    // Get user id
    const id = this.authService.user.id;

    // Get tenant id
    const companyId = this.authService.user.companyId ?? localStorage.getItem('mobi_phone:tenantId');

    // Database connections ref
    const myConnectionsRef = ref(this.database, `companies/${companyId}/users/${id}/connections`);

    // Remove user info from Database
    return remove(myConnectionsRef);
  }

  /**
   * Check if user is in presentation
   */
  public isUserInPresentation(id: string = this.authService.userId): boolean {
    return this.getUserStatus(id).status == UserPresence.IN_PRESENTATION;
  }

  /**
   * Check if user is not disturb
   */
  public isUserNotDisturb(id: string = this.authService.userId): boolean {
    return this.getUserStatus(id).status == UserPresence.DONOTDISTURB;
  }

  /**
   * Remove current connection
   */
  async removeConnection(): Promise<void> {
    try {
      // Unsubscribe from .info/connected events
      this.connectionUnsubscribe();

      // Set hasInitialized as false to initiate .info/connected event again
      this.hasInitialized = false;

      // Cancel onDisconnect trigger
      this.onDisconnectRef?.cancel();

      // Remove current connection
      await remove(this.newConnectionRef ?? this.connectionRef);
    } catch (error) {
      console.log(error.message);
    }
  }

  /**
   * Remove connection by desktop
   */
  public async removeConnectionByDesktop(): Promise<void> {
    // Set can create connection as false
    this.canCreateConnection = false;

    // Remove connection
    await this.removeConnection();
  }

  /**
   * Get presence from user
   * @param {string} uid User id
   * @returns {Presence} User presence
   */
  public getPresenceFromUser(uid: string = this.authService.userId): Presence {
    return this.getUser(uid)?.presence ?? { status: UserPresence.OFFLINE, force: false, platforms: [] };
  }

  /**
   * Get user presence status
   * @param {string} uid User id
   * @returns {Presence} User presence status
   */
  public getUserStatus(uid: string = this.authService.userId): Presence {
    return this.getPresenceFromUser(uid);
  }

  /**
   * Get user extension status
   * @returns {string} Extension status
   */
  public getUserExtensionStatus(id: string): UserExtensionLocalPresence {
    // Get contact
    const contact = this.getUser(id);

    // Get presence
    const presence = this.getPresenceFromUser(id);

    // Const has local user registered error
    const hasRegisterError = hasLSRegisterError() && this.authService?.userId == id;

    // Check extension status
    switch (true) {
      // Check if user is offline
      case (!contact?.isRegistered && presence?.status != UserPresence.ONLY_PUSH) || hasRegisterError:
        return UserExtensionLocalPresence.ExtensionOffline;

      // Extension status - By monitor
      default:
        return `ExtensionUser${presence.status}` as UserExtensionLocalPresence;
    }
  }
}
