import { OverlayModule } from '@angular/cdk/overlay';
import {
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@core/core.module';
import { MonitorInterceptor } from '@core/interceptor/http-timing-monitor';
import { environment } from '@environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Register locales
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEs, 'es');

/**
 * Create translate loader
 * @param {HttpClient} http Http client
 * @returns {TranslateHttpLoader} Translate loader
 */
function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  // Log
  console.log('Create translate loader');

  // Return translate loader
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Can enable service worker - only if is https or is production
 */
function canEnableServiceWorker() {
  const isHttps = location.protocol === 'https:'; // Is https
  const isProduction = environment.production; // Is production
  const isNotMobile = !/Mobi|Android/i.test(navigator.userAgent); // Is not mobile
  const isNotElectron = !/Electron/i.test(navigator.userAgent); // Is not electron
  const isNotIframe = !inIframe(); // Is not iframe

  // Log if is in iframe
  if (!isNotIframe) console.log('Mobi is in iframe - Service worker disabled');

  // Return if can enable service worker
  return (isHttps || isProduction) && isNotMobile && isNotElectron && isNotIframe;
}

/**
 * Check if is in iframe
 * @returns {boolean} If is in iframe
 */
function inIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    MatDialogModule,
    OverlayModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Enable only if is https or is production
      enabled: canEnableServiceWorker(),

      // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgChartsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: MonitorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
