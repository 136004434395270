import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ElectronService } from '@core/services';

@Component({
  selector: 'app-request-permission-denied',
  templateUrl: './request-permission-denied.component.html',
  styleUrls: ['./request-permission-denied.component.scss'],
})
export class RequestPermissionDeniedComponent implements OnInit {
  // Default description
  public desc = 'permissionDeniedMessage';
  public isDesktop = false;

  constructor(
    private dialogRef: MatDialogRef<RequestPermissionDeniedComponent>,
    private electron: ElectronService,
  ) { }

  ngOnInit(): void {
    this.isDesktop = this.electron.isElectronApp;
    this.setDesc();
  }

  /**
   * Set the description based on the platform
   */
  private setDesc(): void {
    this.desc =  this.isDesktop ? 'desktopPermissionDeniedMessage' : 'permissionDeniedMessage';
  }

  /**
   * Close the dialog
   */
  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Restart desktop app
   */
  public restartApp(): void {
    this.electron.ipcRenderer.send('restartDesktopApplication');
  }

}
