import { MessageModificationMaxTimeOptions } from "@shared/models";

export const MESSAGE_MODIFICATION_MAX_TIME_LIST = [
    {option: MessageModificationMaxTimeOptions.Unlimited, value: null},
    {option: MessageModificationMaxTimeOptions.Never, value: 0},
    {option: MessageModificationMaxTimeOptions.FifteenMinutes, value: 900000},
    {option: MessageModificationMaxTimeOptions.TenMinutes, value: 600000},
    {option: MessageModificationMaxTimeOptions.FiveMinutes, value: 300000},
    {option: MessageModificationMaxTimeOptions.OneMinute, value: 60000}
]


