// Day of the week
export const DAYS_OF_THE_WEEK = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

// gaps between pages
export const PAGINATION_GAP = 10;

// Max name length
export const NAME_MAX_LENGTH = 20;

// Max sector abbreviation length
export const SECTOR_ABBREVIATION_MAX_LENGTH = 6;

// Fake identity platform user email domain
export const FAKE_IDENTITY_PLATFORM_USER_EMAIL_DOMAIN = 'leucotron.com.br';

// Company identity platform user email
export const COMPANY_IDENTITY_PLATFORM_USER_EMAIL = `lcs-admin-panel@${FAKE_IDENTITY_PLATFORM_USER_EMAIL_DOMAIN}`;

// Company identity platform user password prefix
export const PASS_SECRET = 'L3uc0.';

// Default jitsi subject
export const DEFAULT_JITSI_SUBJECT = 'Mobi Leucotron';

// Default SIP port
export const SIP_PORT = 52060;

// Import users limit
export const USERS_IMPORT_LIMIT = 50;

// Apps links
export const LINUX_DOWNLOAD_URL = `${location.protocol}//arquivos.leucotron.com.br/update/mobiphone/release/mobi-phone-desktop.deb`;
export const WINDOWS_DOWNLOAD_URL = `${location.protocol}//arquivos.leucotron.com.br/update/mobiphone/release/mobi-phone-desktop.exe`;

// App stores links
export const GOOGLE_PLAY_STORE_URL = `https://play.google.com/store/apps/details?id=com.leucotron.android.mobiphone`;
export const APP_STORE_URL = `https://apps.apple.com/br/app/mobi-phone/id1622070557`;
