import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

type Data = {
  save: any;
  text?: string;
};

@Component({
  selector: 'app-exiting-confirmation',
  templateUrl: './exiting-confirmation.component.html',
  styleUrls: ['./exiting-confirmation.component.scss']
})
export class ExitingConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ExitingConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void { }

  /**
   * Function to specificate if user should leave the screen and lose all the changes or
   * continue editing
   *
   * @param shouldLeave - Boolean that indicates if it should or should not leave the route
   */
  public leaveWithoutSaving(shouldLeave: boolean): void {
    this.dialogRef.close(shouldLeave);
  }

}

/**
 * Use this.data.save() to access the method that saves the form (with await, inside async function)
 **/
