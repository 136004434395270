<h1 mat-dialog-title>{{ data.title | translate }}</h1>

<mat-dialog-content class="mat-typography column" *ngIf="data.desc">
  <p [innerHTML]="data.desc | translate"></p>
  <span *ngIf="data.info" class="info-box">
    {{ data.info | translate }}
  </span>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style="text-transform: uppercase;">{{cancelBtnText | translate }}</button>
  <button mat-raised-button (click)="onSubmit()" type="submit" class="btn-warning" color="primary"
    style="text-transform: uppercase;">
    {{ data.btnText | translate }}
  </button>
</mat-dialog-actions>
