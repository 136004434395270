import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ElectronService,
  HandleVersionService,
  HttpTimingMonitorService,
  ThemeService,
  UserMediaService,
  UserPresenceService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DevModeModalComponent, DialogForceUpdateComponent } from '@shared/components';
import { CUSTOM_ICONS } from '@shared/constants';
import { ReleaseDate } from '@shared/enums';
import { PREFIX_STORAGE, ReleaseDateData } from '@shared/models';
import { isLcsPanelPage, setDesktopVersion } from '@shared/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public checked = false;
  public color: ThemePalette = 'accent';
  public releaseDate: Map<ReleaseDate, ReleaseDateData> = new Map();
  public isProduction: boolean = environment.production;
  constructor(
    private themeService: ThemeService,
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private auth: Auth,
    private electron: ElectronService,
    private userMediaService: UserMediaService,
    private handleVersion: HandleVersionService,
    private httpMonitorService: HttpTimingMonitorService,
    private dialog: MatDialog,
    private presenceService: UserPresenceService,
  ) {
    this.setLanguage();
    this.initCustomIcons();
  }

  async ngOnInit(): Promise<void> {
    // Init theme
    this.themeService.initTheme();

    // Set language code
    this.auth.languageCode = 'pt-br';

    // Get user media devices
    !this.isLCSAdminPanel() && this.userMediaService.fetchDevices();

    // Get release dates
    this.releaseDate = await this.handleVersion.getReleaseDates();

    // Config electron
    this.configElectron();
  }

  /**
   * Config electron
   * @returns void
   */
  private configElectron(): void {
    // Electron configuration
    if (this.electron.isElectronApp) {
      this.getDesktopVersion();
      this.checkVersion();
      this.checkNewDesktopVersion();

      this.electron.ipcRenderer.on('started', (event, args) => {
        const startMinimized: boolean =
          localStorage.getItem('start_minimized') === 'true';

        const windowHeight: number = Number(
          localStorage.getItem('windowHeight') ?? '0'
        );
        const windowWidth: number = Number(
          localStorage.getItem('windowWidth') ?? '0'
        );

        this.electron.ipcRenderer.send('startSettings', {
          startMinimized,
          windowHeight,
          windowWidth,
        });
      });

      this.electron.ipcRenderer.on(
        'resized',
        (event, size: { windowHeight: number; windowWidth: number }) => {
          localStorage.setItem('windowHeight', size.windowHeight.toString());
          localStorage.setItem('windowWidth', size.windowWidth.toString());
        }
      );

      this.electron.ipcRenderer.on('closed', async () => {
        await this.presenceService.removeConnectionByDesktop();
      });
    }
  }

  /**
   * Can show dev mode
   */
  public canShowDevMode(): boolean {
    const isNotProduction = !environment.production;
    const isNotMobile = !/Mobi|Android/i.test(navigator.userAgent);
    const isNotLcsAdminPanel = !this.isLCSAdminPanel();
    return isNotProduction && isNotMobile && isNotLcsAdminPanel;
  }

  /**
   * Check if is LCS Admin Panel
   * @returns boolean if is LCS Admin Panel
   */
  public isLCSAdminPanel(): boolean {
    return isLcsPanelPage();
  }

  /**
   * Get desktop version
   */
  private getDesktopVersion() {
    // Request desktop version
    this.electron.ipcRenderer.send('getVersion');

    // Get desktop version
    this.electron.ipcRenderer.on('version', (event, version: string) => {
      // Set desktop version
      setDesktopVersion(version);

      // Check if is a break version
      if (this.isBreakVersion(version)) this.forceUpdate(version);
    });
  }

  /**
   * Check if is a break version of desktop
   * @param {string} version - Current Desktop version (ex: 1.0.0)
   * @returns boolean if is a break version
   */
  private isBreakVersion(version: string): boolean {
    // Get break version from release dates
    const breakVersion = this.releaseDate?.get(ReleaseDate.Desktop)?.breakVersion ?? '0.0.0';

    // Check if is a break version
    return this.handleVersion.compareVersions(version, breakVersion);
  }

  /**
   * Show dialog to force update
   * @param {string} version - Current version
   */
  public forceUpdate(version: string): void {
    // Open dialog to force update
    this.dialog.open(DialogForceUpdateComponent, {
      panelClass: 'simple-dialog',
      disableClose: true,
      data: { version, newVersion: this.releaseDate?.get(ReleaseDate.Desktop)?.version },
    });
  }

  public isDevModeActive() {
    return this.httpMonitorService.getInterceptorActive()
  }
  public toggleHttpMonitor(e: any) {
    this.httpMonitorService.setInterceptorActive(
      e.checked
    )
    console.log(`%c Dev mode ${this.httpMonitorService.getInterceptorActive() ? "" : "des"}ativado`, 'background: #3d6db9; color: white; font-size: 15px')
  }
  /**
   * Set current language
   */
  private setLanguage(): void {
    // Get language from localstorage
    let language = localStorage.getItem(`${PREFIX_STORAGE}:language`);
    // If not found, get default browser language
    if (!language) { language = this.translate.getBrowserLang(); }
    // Set english as default language
    this.translate.setDefaultLang('en');
    // Set current language
    this.translate.use(language);
    // Set language in desktop app
    if (this.electron.isElectronApp) this.electron.ipcRenderer.send('setDesktopLanguage', language);
  }

  private async checkVersion(): Promise<void> {
    const renderer = this.electron.ipcRenderer;
    const mobiPhoneVersion: string = await this.handleVersion.getVersion();
    if (mobiPhoneVersion === null) {
      renderer.send('clearCache');
      return;
    }
    let localVersion = localStorage.getItem(`${PREFIX_STORAGE}:version`);
    const curVersion = environment.appVersion;

    if (localVersion === null) {
      localStorage.setItem(`${PREFIX_STORAGE}:version`, curVersion);
      localVersion = environment.appVersion;
    }

    if (localVersion > mobiPhoneVersion) {
      await this.handleVersion.setVersion(localVersion);
      renderer.send('clearCache');
    }
    if (localVersion < mobiPhoneVersion) {
      localStorage.setItem(`${PREFIX_STORAGE}:version`, mobiPhoneVersion);
      renderer.send('clearCache');
    }
  }

  checkNewDesktopVersion(): void {
    if (this.electron.isElectronApp) {
      const renderer = this.electron.ipcRenderer;

      renderer.on('hasUpdate', (evt, newVersion: string) => {
        const data = {
          hasUpdate: true,
          newVersion,
        };
        sessionStorage.setItem('hasUpdate', JSON.stringify(data));
      });
    }
  }

  private initCustomIcons(): void {
    CUSTOM_ICONS.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }

  public openDevModal() {
    this.dialog.open(DevModeModalComponent, { panelClass: 'dev-mode-modal-dialog' })
  }
}
