import { Injectable } from '@angular/core';
import {
  CollectionReference,
  Firestore,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  updateDoc
} from '@angular/fire/firestore';
import { LcsEventsService } from '@core/services';
import { LcsEventDescriptions, LcsEventIntegrationTypes } from '@shared/enums';
import { AppIntegration } from '@shared/models';
import { getDiffBetweenObj, getIntegrationPath, getLSTenantId, getLSUserId, getUsersCollectionPath } from '@shared/utils';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  // Local integrations
  private integrationApps: AppIntegration[] = [];

  // Observable to get the selected app
  private selectedApp$: Subject<AppIntegration> = new Subject<AppIntegration>();

  constructor(
    private firestore: Firestore,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
   * Set apps - Local
   * @param {AppIntegration[]} apps Apps to be set
   */
  public setApps(apps: AppIntegration[]) {
    this.integrationApps = [...apps];
  }

  /**
   * Get all apps - Local
   * @returns {AppIntegration[]} All apps
   */
  public getApps(): AppIntegration[] {
    return this.integrationApps;
  }

  /**
   * Add app to the list
   * @param {AppIntegration} app App to be selected
   */
  public selectApp(app: AppIntegration) {
    this.selectedApp$.next(app);
  }

  /**
   * Get selected app
   * @returns {Observable<AppIntegration>} The selected app
   */
  public getSelectedApp$(): Observable<AppIntegration> {
    return this.selectedApp$.asObservable();
  }

  /**
   * Set app as favorite
   * @param {string} appId App ID
   * @param {boolean} favorite Favorite app
   */
  public setAppFavorite(appId: string, favorite: boolean): Promise<void> {
    const userId: string = getLSUserId();
    const companyId = getLSTenantId();
    const path = getUsersCollectionPath(companyId, userId);
    const docRef = doc(this.firestore, path);
    return updateDoc(docRef, { favoriteList: favorite ? arrayUnion(appId) : arrayRemove(appId) });
  }

  /**
 * Get integrations
 * @returns All integrations that belong to current company
 */
  public fetchAll(): Observable<AppIntegration[]> {
    const col = <CollectionReference<AppIntegration>>(
      collection(this.firestore, getIntegrationPath())
    );

    return collectionData<AppIntegration>(col, { idField: 'id' }).pipe();
  }

  /**
   * Find an integration by its ID
   * @param {string} id Integration ID
   * @returns {Promise<Integration>} The integration that has the given ID
   */
  public async findById(id: string): Promise<AppIntegration> {
    // Get the integration path
    const path = getIntegrationPath(id);

    // Get the document reference
    const docRef = doc(this.firestore, path);

    // Get the document
    const document = await getDoc(docRef);

    // Check if the document exists and return it
    if (document.exists()) return { id: document.id, ...document.data() } as AppIntegration;
    else return null;
  }

  /**
   * Create a new integration
   * @param {Integration} integration Obj that contains the integration data
   */
  public async create(integration: AppIntegration): Promise<void> {
    const colRef = collection(this.firestore, getIntegrationPath());
    return addDoc(colRef, {
      companyId: getLSTenantId(),
      name: integration.name,
      icon: integration.icon,
      type: integration.type,
      enabled: integration.enabled,
      domain: integration.domain,
      groups: integration.groups ?? [],
      openInWindow: integration.openInWindow ?? true,
    }).then(() => this.handleSuccess(LcsEventIntegrationTypes.INTEGRATION_APP_CREATION, { created: integration }))
      .catch((error) => this.handleError(error, LcsEventIntegrationTypes.INTEGRATION_APP_CREATION));
  }

  /**
   * Delete a integration
   * @param {AppIntegration} integration Integration to be deleted
   */
  public async deleteIntegration(integration: AppIntegration): Promise<void> {
    const docRef = doc(this.firestore, getIntegrationPath(integration.id));
    return deleteDoc(docRef)
      .then(() => this.handleSuccess(LcsEventIntegrationTypes.INTEGRATION_APP_DELETION, { deleted: integration }))
      .catch((error) => this.handleError(error, LcsEventIntegrationTypes.INTEGRATION_APP_DELETION));
  }

  /**
   * Update a integration
   * @param {Integration} integration New Data
   * @param {Integration} original Original Data
   * @returns The new integration
   */
  public async update(integration: AppIntegration, original: AppIntegration): Promise<void> {
    // Get the document reference
    const docRef = doc(this.firestore, getIntegrationPath(integration.id));

    // Update the integration
    return updateDoc(docRef, {
      companyId: getLSTenantId(),
      name: integration.name,
      icon: integration.icon,
      type: integration.type,
      enabled: integration.enabled,
      domain: integration.domain,
      groups: integration.groups ?? [],
      openInWindow: integration.openInWindow ?? true,
    })
      .then(() => this.handleSuccess(LcsEventIntegrationTypes.INTEGRATION_APP_UPDATE, {
        id: integration.id,
        name: integration.name,
        diff: getDiffBetweenObj(integration, original)
      }))
      .catch((error) => this.handleError(error, LcsEventIntegrationTypes.INTEGRATION_APP_UPDATE));
  }

  /**
   * Handle success
   * @param {LcsEventIntegrationTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsEventIntegrationTypes, value: any): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, true);
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsEventIntegrationTypes} type The event type
   */
  public handleError(error: any, type: LcsEventIntegrationTypes): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, true);

    // Throw the error
    throw error;
  }
}
