export * from './ai.model';
export * from './app-integration.model';
export * from './capture-groups.model';
export * from './cdr.model';
export * from './chatgpt.model';
export * from './cluster.model';
export * from './contact.model';
export * from './desktop-settings.model';
export * from './emoji.model';
export * from './entryRoute.model';
export * from './equipment.model';
export * from './external-user.model';
export * from './extra-actions.model';
export * from './gif.model';
export * from './group.model';
export * from './guard.model';
export * from './history.model';
export * from './jitsi.model';
export * from './lcs-events.model';
export * from './matrix.model';
export * from './meeting-rooms.model';
export * from './pbx.model';
export * from './properties';
export * from './sector.model';
export * from './socket.model';
export * from './tenant.model';
export * from './ua-settings.model';
export * from './user.model';
export * from './versions.model';

