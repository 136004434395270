import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CaptureGroupService, UtilsService } from '@core/services';
import { CaptureGroup } from '@shared/models';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-capture-groups-select',
  templateUrl: './capture-groups-select.component.html',
  styleUrls: ['./capture-groups-select.component.scss']
})
export class CaptureGroupsSelectComponent implements OnInit, OnDestroy {
  @Input() public control: FormControl;
  @Input() public default: string[] = [];
  @Input() public canCreate = true;

  public inputControl: FormControl = new FormControl('');
  public allData: CaptureGroup[] = [];
  public selectedData: CaptureGroup[] = [];
  public hasSelected = false;
  public filtered: Observable<CaptureGroup[]>;

  // Destroy
  private destroy$ = new Subject<void>();

  constructor(
    private utils: UtilsService,
    private captureGroupService: CaptureGroupService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.getCaptureGroups();
  }

  /**
   * Fetch capture groups
   */
  private getCaptureGroups(): void {
    this.captureGroupService
      .fetch()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => this.setData(data));
  }

  /**
   * Set data
   * @param {CaptureGroup[]} data The capture groups
   */
  private setData(data: CaptureGroup[]) {
    this.allData = data;
    this.initFilteredUsers();
    if (this.default?.length > 0) this.selectedData = data.filter((group) => this.default.includes(group.id));
  }

  /**
   * Init filtered users
   */
  public initFilteredUsers(): void {
    this.filtered = this.inputControl
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        startWith(''),
        map((value: string | null) => value
          ? this.filter(value)
          : this.getFilteredData()
        )
      );
  }

  /**
   * Filter
   * @param value The value
   */
  private filter(value: string): CaptureGroup[] {
    // Filter groups
    const groups = this.getFilteredData();

    // Return filtered groups
    try {
      return groups.filter(data => data.name.toLowerCase().includes(value?.toLowerCase()));
    } catch (error) {
      return [...groups];
    }
  }

  private getFilteredData(): CaptureGroup[] {
    return this.allData.filter((data) => !this.isSelected(data))
  }


  public isSelected(group: CaptureGroup): boolean {
    return this.selectedData.some((data) => data.id === group.id);
  }

  public remove(data: CaptureGroup) {
    this.selectedData = this.selectedData.filter((selected) => selected.id !== data.id);
    this.hasSelected = this.selectedData.length > 0;
    this.updateFormControl();
  }

  /**
   * On img error
   */
  public onImgError(event: any): void {
    event.target.src = 'assets/images/avatar.svg';
  }

  /**
   * Create group
   */
  public createGroup(): void {
    this.utils.navigateInAdminPanel('/capture-groups/create');
  }


  /**
   * Select user
   */
  public selected(event: MatAutocompleteSelectedEvent) {
    // Get value
    const value = event.option.value;

    // Check if value is valid
    if (!value) return;

    // Add value to selected data
    this.selectedData.push(value);
    this.hasSelected = true;
    this.inputControl.setValue('');
    this.updateFormControl();
  }

  /**
   * Update form control
   */
  public updateFormControl() {
    this.control.setValue([...this.selectedData.map((data) => data.id)]);
  }


}
