<h1 mat-dialog-title>{{ data.title | translate }}</h1>

<mat-dialog-content class="mat-typography column">
  <p>{{ data.desc | translate }}</p>
  <span *ngIf="data.info" class="info-box" [innerHTML]="data.info | translate"></span>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close type="button" class="btn-warning" color="primary"
    style="text-transform: uppercase;">
    {{ data.btnText | translate }}
  </button>
</mat-dialog-actions>
