<mat-toolbar class="toolbar">
  <span class="spacer"></span>

  <div class="logo">
    <picture>
      <source media="(max-width: 959px)" srcset="assets/images/mobi-logo-488x87.png" />
      <source media="(min-width: 960px)" srcset="assets/images/mobi-logo-708x67.png" />
      <img src="assets/images/mobi-logo-708x67.png" alt="Mobi" loading="lazy" />
    </picture>
  </div>

  <div class="right-container">
    <div class="notifications">
      <button mat-icon-button *ngIf="false">
        <mat-icon matBadge="!" matBadgeSize="small" matBadgePosition="above before" [matBadgeHidden]="!hasNotification"
          [matMenuTriggerFor]="notifications">
          {{ hasNotification ? "notifications_active" : "notifications" }}
        </mat-icon>
      </button>
    </div>
    <ng-container *ngIf="auth.user$ | async as user">
      <app-avatar-with-status class="avatar-header" [isSmall]="true" [contact]="user" mat-icon-button
        [matMenuTriggerFor]="menu">
      </app-avatar-with-status>
      <mat-menu class="user-menu" #menu="matMenu">

        <div class="container">
          <div class="profile-wrapper">
            <app-avatar class="profile--lg" [photoUrl]="user.photoUrl">
            </app-avatar>
          </div>

          <div class="menu--details">

            <span class="display-name">{{ getDisplayName(user) }}</span>
            <span class="email">{{ user.email }}</span>

            <div>
              <button class="select-status-button" mat-button [matMenuTriggerFor]="presence"
                (click)="$event.stopPropagation();$event.preventDefault">
                <div class="wrapper">
                  <div class="presence" [ngClass]="getUserStatus()"></div>
                  <span>{{getUserStatus() | translate}}</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </button>

              <mat-menu class="menu-presence" #presence="matMenu">

                <h5> {{ "setupUserPresenceStatus" | translate }}</h5>

                <button mat-menu-item (click)="changePresence('Available')">
                  <div class="presence Available"></div>{{ 'Available' | translate}}
                </button>

                <button mat-menu-item (click)="changePresence('DoNotDisturb')">
                  <div class="presence DoNotDisturb"></div>{{ 'DoNotDisturb' | translate}}
                </button>

                <button mat-menu-item (click)="changePresence('Busy')">
                  <div class="presence Busy"></div>{{ 'Busy' | translate}}
                </button>

                <button mat-menu-item (click)="changePresence('Away')">
                  <div class="presence Away"></div>{{ 'showAsAway' | translate}}
                </button>

                <button mat-menu-item (click)="changePresence('Offline')">
                  <div class="presence Offline"></div>{{ 'showAsOffline' | translate}}
                </button>
              </mat-menu>
            </div>
            <!-- Company sector -->
            <span class="user-attr-info" *ngIf="!!user.companySector">
              {{'sector' | translate}}: {{ user.companySector }}
            </span>

            <!-- extension -->
            <span class="user-attr-info" *ngIf="hasPABXIntegration(user)">
              {{'extension' | translate}}: {{ user.extension }}
            </span>
          </div>

          <mat-divider></mat-divider>
          <button class="menu-action-button" mat-menu-item (click)="onOpenSettingsDialog()">
            <mat-icon>settings</mat-icon><span>{{ "settings" | translate }}</span>
          </button>

          <mat-divider></mat-divider>
          <button class="menu-action-button" mat-menu-item (click)="onLogout()">
            <mat-icon>logout</mat-icon><span>{{ "logout" | translate }}</span>
          </button>

        </div>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>

<div class="register-error" *ngIf="registrationError">
  <span>
    {{ "header.registerError" | translate}}
  </span>
  <a (click)="forceRegister()"> {{ "header.tryAgain" | translate}}</a>
</div>

<app-switch-device-notification></app-switch-device-notification>

<mat-menu class="notification-menu" #notifications="matMenu">
  <notification-center (hasNotifications)="setNotifications($event)"></notification-center>
</mat-menu>
