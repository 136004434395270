import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerService, RoomService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { DialogInfoComponent } from '@shared/components/dialog-info/dialog-info.component';
import { MatrixUserRole, User } from '@shared/models';
import { formMatrixFromUserId } from '@shared/utils';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-select-role',
  templateUrl: './select-role.component.html',
  styleUrls: ['./select-role.component.scss']
})
export class SelectRoleComponent implements OnInit, OnDestroy {
  // Inputs
  @Input() roomId: string;
  @Input() myUserId: string;
  @Input() user: User;
  @Input() members: User[];

  // Available user roles
  public userRoles = Object.values(MatrixUserRole);

  // Form
  public form: FormGroup = this.fb.group({
    role: [MatrixUserRole.Moderator],
  });

  // Destroy
  public destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private roomService: RoomService,
    private translate: TranslateService,
    private spinner: LoadingSpinnerService,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // Set current user role
    this.setDefaultRole();

    // Set form value changes
    this.form
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        // Check if role is the same
        if (value.role === this.getCurrentUserRole(this.user)) return;

        // Get desc
        const desc = value.role === MatrixUserRole.Admin
          ? 'transformIntoAdmin'
          : 'transformIntoModerator';

        // Confirm dialog
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'simple-dialog',
          data: {
            title: 'warning',
            desc: this.translate.instant(desc, { user: this.user.displayName }),
            btnText: 'yes',
            cancelBtnText: 'no',
          },
        });

        // After dialog closed
        dialogRef
          .afterClosed()
          .pipe(first())
          .subscribe((result) => result
            ? this.updateUserRole(value.role, this.user)
            : this.setDefaultRole());
      });
  }

  /**
   * Check if is room admin
   * @param {User} user User
   * @returns {boolean} True if is room admin
   */
  public isRoomAdmin(user: User): boolean {
    return this.roomService.isAdmin(this.roomId, formMatrixFromUserId(user.id));
  }

  /**
   * Get current user role
   * @param {User} user User
   * @returns {MatrixUserRole} User role
   */
  public getCurrentUserRole(user: User): MatrixUserRole {
    return this.isRoomAdmin(user) ? MatrixUserRole.Admin : MatrixUserRole.Moderator;
  }

  /**
   * Set default role
   */
  public setDefaultRole(): void {
    this.form.patchValue({ role: this.getCurrentUserRole(this.user) });
  }

  /**
   * Update user role
   * @param {MatrixUserRole} role User role
   * @param {User} user User
   */
  public async updateUserRole(role: MatrixUserRole, user: User): Promise<void> {
    try {
      // Show spinner
      this.spinner.show();

      // Form matrix user id
      const mUserId = formMatrixFromUserId(user.id);

      // Init new admins and remove admins
      const nAdmins = [];
      const rAdmins = [];

      // Add user to new role
      role === MatrixUserRole.Admin
        ? nAdmins.push(mUserId)
        : rAdmins.push(mUserId);

      // Get admin user id
      let adminUserId = this.isRoomAdmin(user) ? mUserId : this.myUserId;

      // If adminUserId is not admin, get admin user id
      if (!this.roomService.isAdmin(this.roomId, adminUserId)) {
        const admin = this.members.find((member) => this.isRoomAdmin(member));
        adminUserId = formMatrixFromUserId(admin.id);
      }

      // Update room members
      await this.roomService.setRoomAdmins(adminUserId, this.roomId, nAdmins, rAdmins);
    } catch (error) {
      // Handle error
      this.handleError(error);
    } finally {
      // Hide spinner
      this.spinner.hide();
    }
  }

  /**
   * Handle error
   * @param {Error} error Error
   */
  private handleError(error: any): void {
    // Go back to previous role
    this.setDefaultRole();

    // Log error
    console.error('Error on update user role:', error);

    // Get error status
    const status = error.status || 500;

    // Init error message
    let title = 'warning';
    let desc = 'resourceUnavailable';
    let info = '';

    // Switch status
    switch (status) {
      case 403:
        desc = 'userHasNotJoinedYet';
        break;
      case 409:
        desc = 'atLeastOneAdmin';
        break;
      case 429:
        desc = 'waitBeforeTryAgain';
        const minutes = this.getRetryAfter(error);
        minutes && (info = this.translate.instant('tryAgainIn', { time: minutes }));
        break;
    }

    // Show dialog info with error
    this.dialog.open(DialogInfoComponent, {
      panelClass: 'simple-dialog',
      data: {
        title,
        desc,
        info,
        btnText: 'OK',
      },
    });
  }

  /**
   * Get retry_after_ms from error
   * @param {any} error Error
   * @returns {number} Retry after minutes
   */
  private getRetryAfter(error: any): number {
    return error?.error?.retry_after_ms ? Math.ceil(error.error.retry_after_ms / 1000 / 60) : null;
  }
}
