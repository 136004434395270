<!-- Hour -->
<mat-form-field [style.width]="width" appearance="outline" [ngClass]="{ dark: themeService.isDarkTheme() }">
    <mat-label *ngIf="label">{{label | translate}}</mat-label>
    
    <input matInput type="text" [formControl]="control" [value]="(control.value | mask: 'Hh:mm SSSS')" #name
      [(defaultValue)]="defaultHourValue" />
  
    <mat-select style="position: absolute;" disableOptionCentering panelClass="time-selector"
      (selectionChange)="updateOption($event)" [value]="this.control.value">
      <mat-option [value]="null">{{ "none" | translate }}</mat-option>
      <mat-option [id]="option" *ngFor="let option of options" [value]="option">
        {{option}}
      </mat-option>
    </mat-select>
  
  </mat-form-field>
  