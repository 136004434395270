import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PAGINATION_GAP } from '@shared/constants';
import { ScrollPagination } from '@shared/models';
import { getMaxElementsForPagination, normalizeTerm } from '@shared/utils';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent<T> implements OnInit, OnChanges {
  @ViewChild('infiniteScroll') infiniteScroll: ElementRef<HTMLDivElement>; // Infinite scroll element
  @Input() searchedTerm: string; // Search term
  @Input() allItems: T[] = []; // All items
  @Input() sortFunction: (a: T, b: T) => number; // Sort function
  @Input() sortBy: string; // Sort by property
  @Input() filterBy: string[] = []; // Filter by properties
  @Input() isDisplayGrid: boolean; // Treatment for grid is way different, because it can alloc items side by side
  @Input() filter: any;
  @Output() updateItems: EventEmitter<T[]> = new EventEmitter<T[]>(); // Update items
  @Output() scrollState: EventEmitter<string> = new EventEmitter<string>(); // Update items

  private normalizedFilter = ''; // Search term normalized
  public items: T[] = []; // Current item list to show in view
  private maxElements = getMaxElementsForPagination(); // Max elements for pagination
  private realFilter;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    // Listen to search term changes
    if (changes.searchedTerm) this.applySearchTerm(changes.searchedTerm.currentValue);

    if(changes.filter) {
      this.applyFilter(changes.filter.currentValue)
    }
    // Listen to all items changes ignore first change
    if (changes.allItems && !changes.allItems.firstChange) this.updateList();

  }

  ngOnInit(): void {
    // Set limit
    this.setMaxElements();

    // Init items
    this.initItems();
  }

  /**
   * Apply search term
   * @param {string} term Search term
   */
  private applySearchTerm(term: string): void {
    this.normalizedFilter = normalizeTerm(term);
    this.initItems();
  }

    /**
   * Apply search term
   * @param {string} term Search term
   */
    private applyFilter(filter: any): void {
      this.realFilter = filter;
      this.initItems();
    }

  /**
   * Get max elements for pagination based on screen resolution
   */
  public setMaxElements() {
    this.maxElements = getMaxElementsForPagination(this.isDisplayGrid);
  }

  /**
   * Scroll Handler
   * @param {ScrollPagination} event Scroll direction
   */
  public scrollHandler(event: ScrollPagination): void {
    // Emit scroll state
    this.scrollState.emit(event)
    switch (event) {
      case 'top':
        this.initItems();
        break;

      case 'bottom':
        // Push 10 more items
        this.items = this.getItems()
          .slice(0, this.items.length + PAGINATION_GAP);

        // Emit items
        this.updateItems.emit(this.items);

        break;
    }
  }

  /**
   * Force sort items
   */
  public updateList() {
    // Get current length
    const currentLength = this.items.length > this.maxElements
      ? this.items.length
      : this.maxElements;

    // Set items
    this.items = this.getItems() // Get items with filter applied
      .slice(0, currentLength); // Return items with current length

    // Emit items
    this.updateItems.emit(this.items);
  }

  /**
   * Sort items
   * @param {T[]} items
   */
  public sortItems(items: T[]) {
    // Sort items
    switch (true) {
      // Sort items if has sort by property
      case !!this.sortBy:
        items.sort((a, b) => normalizeTerm(a[this.sortBy]) > normalizeTerm(b[this.sortBy]) ? 1 : -1);
        break;

      // Sort items if has sort function
      case !!this.sortFunction:
        items.sort(this.sortFunction);
        break;
    }
  }
  /**
   * Scroll to top
   */
  public scrollToTop() {
    this.infiniteScroll?.nativeElement?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * Get items with filter applied
   * @returns {T[]} items
   */
  private getItems(): T[] {
    // If has search term, return filtered items
    let newItems = this.hasSearchTerm()
      ? this.filterBySearchTerm()
      : [...this.allItems];

    newItems = this.realFilter ? this.filterPerKey(newItems) : [...newItems]
    // Sort items
    this.sortItems(newItems);
    
    // Return items
    return newItems;
  }

  /**
   * Init items
   */
  private initItems(): void {
    this.items = this.getItems() // Get items with filter applied
      .slice(0, this.maxElements); // Get first 30 items

    // Emit items
    this.updateItems.emit(this.items);
  }

  /**
   * Check if has search term
   * @returns {boolean} True if has search term
   */
  private hasSearchTerm(): boolean {
    return !!this.normalizedFilter?.trim();
  }

  /**
   * Filter items by search term
   * @returns {T[]} Filtered items
   */
  private filterBySearchTerm(): T[] {
    return this.allItems
      .filter((item) =>
        // Check if any property contains the search term
        this.filterBy.some((property) => normalizeTerm(item[property]).includes(this.normalizedFilter))
      );
  }

  private filterPerKey(currentItems){
    if(this.realFilter.length == 0){
      return currentItems
    }
    let newitems = []
    for(const value of this.realFilter){
      const tobeAdded = currentItems.filter((item: any) => {
        return item.companySectorId == value
      })
      newitems = [...newitems, ...tobeAdded]
    }
    return newitems;
  }
}
