<mat-form-field appearance="outline">
  <!-- Label -->
  <mat-label>{{ "selectCaptureGroup" | translate }}</mat-label>

  <!-- Input -->
  <input matInput [formControl]="inputControl" [matChipInputFor]="list" [matAutocomplete]="auto" />

  <!-- Divider -->
  <mat-divider *ngIf="hasSelected"></mat-divider>

  <!-- Autocomplete -->
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let data of filtered | async" [value]="data">
      <span>{{ data.name }}</span>
    </mat-option>

    <!-- Divider -->
    <mat-divider></mat-divider>

    <!-- Default Option - Create -->
    <mat-option *ngIf="canCreate" [value]="null" (click)="createGroup()" style="font-weight: 500;">
      <mat-icon>add</mat-icon>
      <span>{{ 'createCaptureGroup' | translate }}</span>
    </mat-option>
  </mat-autocomplete>

  <!-- Chips -->
  <mat-chip-list #list>
    <mat-chip *ngFor="let data of selectedData" (removed)="remove(data)">
      {{ data.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

</mat-form-field>
