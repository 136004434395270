import { Injectable } from '@angular/core';
import { orderBy } from '@angular/fire/firestore';
import {
  FirestoreService,
  LcsEventsService,
  TenantService
} from '@core/services';
import { LcsCaptureGroupTypes, LcsEventDescriptions } from '@shared/enums';
import { CaptureGroup } from '@shared/models';
import { getCaptureGroupPath, getDiffBetweenObj } from '@shared/utils';
import { Observable, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaptureGroupService {
  constructor(
    private tenantService: TenantService,
    private fireService: FirestoreService,
    private lcsEventService: LcsEventsService,
  ) { }

  /**
   * Fetches the company
   * @returns {Observable<CaptureGroup[]>} The capture groups
   */
  public fetch(): Observable<CaptureGroup[]> {
    // Format the path to collection
    const path = getCaptureGroupPath(this.tenantService.tenant?.id);

    // Fetch
    return this.fireService.colWithIds$<CaptureGroup>(path, [orderBy('name')]);
  }

  /**
   * Fetches a capture group by ID
   * @param {string} id The capture group ID
   * @returns {Promise<CaptureGroup>} Capture group
   */
  public fetchById(id: string): Promise<CaptureGroup> {
    // Format the path to document
    const path = getCaptureGroupPath(this.tenantService.tenant?.id, id);

    // Fetch the capture group
    return lastValueFrom(this.fireService.docWithId$<CaptureGroup>(path).pipe(first()))
  }

  /**
   * Creates a capture group
   * @param {CaptureGroup} data The capture group data
   */
  public async create(data: CaptureGroup, spinner: boolean = true): Promise<void> {
    // Format the path to the collection
    const path = getCaptureGroupPath(this.tenantService.tenant?.id);

    // Create
    return this.fireService
      .addDoc(path, data)
      .then(() => this.handleSuccess(LcsCaptureGroupTypes.CAPTURE_GROUP_CREATION, { created: data }, spinner))
      .catch((error) => this.handleError(error, LcsCaptureGroupTypes.CAPTURE_GROUP_CREATION, spinner));
  }

  /**
   * Updates a capture group
   * @param {CaptureGroup} data The capture group data
   * @param {CaptureGroup} original The original capture group data
   */
  public async update(data: CaptureGroup, original: CaptureGroup, spinner: boolean = true): Promise<void> {
    // Format the path to the document
    const path = getCaptureGroupPath(this.tenantService.tenant?.id, data.id);

    // Update
    return this.fireService
      .updateDoc(path, data)
      .then(() => this.handleSuccess(LcsCaptureGroupTypes.CAPTURE_GROUP_UPDATE, {
        id: data.id,
        name: data.name,
        diff: getDiffBetweenObj(data, original)
      }, spinner))
      .catch((error) => this.handleError(error, LcsCaptureGroupTypes.CAPTURE_GROUP_UPDATE, spinner));
  }

  /**
   * Add user to capture group
   * @param {string} userId The user ID
   * @param {string} groupId The group ID
   * @returns {Promise<void>} Promise
   */
  public async addUserToGroup(userId: string, groupId: string, spinner: boolean = true): Promise<void> {
    // Fetch the capture group
    const group = await this.fetchById(groupId);

    // Check if user is already in the group
    if (group.users.includes(userId)) return;

    // Add user to the group
    group.users.push(userId);

    // Update the group
    return this.update(group, group, spinner);
  }

  /**
   * Remove user from capture group
   * @param {string} userId The user ID
   * @param {string} groupId The group ID
   * @returns {Promise<void>} Promise
   */
  public async removeUserFromGroup(userId: string, groupId: string, spinner: boolean = true): Promise<void> {
    // Fetch the capture group
    const group = await this.fetchById(groupId);

    // Check if user is not in the group
    if (!group.users.includes(userId)) return;

    // Remove user from the group
    group.users = group.users.filter((x) => x !== userId);

    // Update the group
    return this.update(group, group, spinner);
  }

  /**
   * Handle error
   * @param {string} error The error message
   * @param {LcsCaptureGroupTypes} type The event type
   */
  public handleError(error: any, type: LcsCaptureGroupTypes, spinner: boolean = true): void {
    // Log the error
    console.error(error);

    // Create a new event
    this.lcsEventService.create({ type, description: LcsEventDescriptions.ERROR, value: { error } }, spinner);

    // Throw the error
    throw error;
  }

  /**
   * Handle success
   * @param {LcsCaptureGroupTypes} type The event type
   * @param {any} value The value
   */
  public handleSuccess(type: LcsCaptureGroupTypes, value: any, spinner: boolean = true): void {
    this.lcsEventService.create({ type, description: LcsEventDescriptions.SUCCESS, value }, spinner);
  }
}
