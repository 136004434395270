interface CustomIcon {
  name: string;
  path: string;
}

export const CUSTOM_ICONS: CustomIcon[] = [
  { name: 'history', path: 'assets/icons/history.svg' },
  { name: 'menu-help', path: 'assets/icons/icone-menu-ajuda.svg' },
  { name: 'up-arrow', path: 'assets/icons/up-arrow.svg' },
  { name: 'left-arrow', path: 'assets/icons/left-arrow.svg' },
  { name: 'left-arrow-alt', path: 'assets/icons/left-arrow-alt.svg' },
  { name: 'question_mark_circle', path: 'assets/icons/question_mark_circle.svg' },
  { name: 'filter', path: 'assets/icons/filter.svg' },
  { name: 'history_active', path: 'assets/icons/history_active.svg' },
  { name: 'contacts', path: 'assets/icons/contacts.svg' },
  { name: 'contacts_active', path: 'assets/icons/contacts_active.svg' },
  { name: 'call', path: 'assets/icons/call.svg' },
  { name: 'call_hover', path: 'assets/icons/call_hover.svg' },
  { name: 'panel', path: 'assets/icons/panel.svg' },
  { name: 'file_export', path: 'assets/icons/file_export.svg' },
  { name: 'call_white', path: 'assets/icons/call_white.svg' },
  { name: 'history', path: 'assets/icons/history.svg' },
  { name: 'dial_call', path: 'assets/icons/dial_call.svg' },
  { name: 'icon_total', path: 'assets/icons/icon_total.svg' },
  { name: 'call_end', path: 'assets/icons/call_end.svg' },
  { name: 'videocam', path: 'assets/icons/videocam.svg' },
  { name: 'videocam_hover', path: 'assets/icons/videocam_hover.svg' },
  { name: 'sharescreen', path: 'assets/icons/sharescreen.svg' },
  { name: 'menu_sharescreen', path: 'assets/icons/menu_sharescreen.svg' },
  { name: 'unsharescreen', path: 'assets/icons/unsharescreen.svg' },
  { name: 'menu_unsharescreen', path: 'assets/icons/menu_unsharescreen.svg' },
  { name: 'backspace', path: 'assets/icons/backspace.svg' },
  { name: 'transfer', path: 'assets/icons/transfer.svg' },
  { name: 'menu_transfer', path: 'assets/icons/menu_transfer.svg' },
  { name: 'pause', path: 'assets/icons/pause.svg' },
  { name: 'play', path: 'assets/icons/play.svg' },
  { name: 'speaker', path: 'assets/icons/speaker.svg' },
  { name: 'incoming_call', path: 'assets/icons/incoming_call.svg' },
  { name: 'incoming_call_grey', path: 'assets/icons/incoming_call_grey.svg' },
  { name: 'outcoming', path: 'assets/icons/outcoming.svg' },
  { name: 'incoming', path: 'assets/icons/incoming.svg' },
  { name: 'internal_calls', path: 'assets/icons/internal_calls.svg' },
  { name: 'outcoming_call', path: 'assets/icons/outcoming_call.svg' },
  { name: 'outcoming_call_grey', path: 'assets/icons/outcoming_call_grey.svg' },
  { name: 'missed_call', path: 'assets/icons/missed_call.svg' },
  {
    name: 'incoming_missed_call',
    path: 'assets/icons/incoming_missed_call.svg',
  },
  { name: 'play_audio', path: 'assets/icons/rec.svg' },
  { name: 'play_audio_hover', path: 'assets/icons/rec-hover.svg' },
  { name: 'conecta-icon', path: 'assets/icons/conecta-icon.svg' },
  { name: 'conecta-icon_active', path: 'assets/icons/conecta-icon_active.svg' },
  { name: 'phonebook-icon', path: 'assets/icons/phonebook-icon.svg' },
  {
    name: 'phonebook-icon_active',
    path: 'assets/icons/phonebook-icon_active.svg',
  },
  { name: 'user-manager-icon', path: 'assets/icons/user-manager-icon.svg' },
  {
    name: 'user-manager-icon_active',
    path: 'assets/icons/user-manager-icon_active.svg',
  },
  { name: 'lcdr-icon', path: 'assets/icons/lcdr-icon.svg' },
  { name: 'lcdr-icon_active', path: 'assets/icons/lcdr-icon_active.svg' },
  { name: 'lrec-icon', path: 'assets/icons/lrec-icon.svg' },
  { name: 'lrec-icon_active', path: 'assets/icons/lrec-icon_active.svg' },
  { name: 'supervisor-icon', path: 'assets/icons/supervisor-icon.svg' },
  {
    name: 'supervisor-icon_active',
    path: 'assets/icons/supervisor-icon_active.svg',
  },
  { name: 'agente-icon', path: 'assets/icons/agente-icon.svg' },
  { name: 'agente-icon_active', path: 'assets/icons/agente-icon_active.svg' },
  { name: 'chat', path: 'assets/icons/chat.svg' },
  { name: 'chat_active', path: 'assets/icons/chat_active.svg' },
  { name: 'chat_outline', path: 'assets/icons/chat_outline.svg' },
  { name: 'chat_outline_active', path: 'assets/icons/chat_outline_active.svg' },
  { name: 'add-user', path: 'assets/icons/add-user.svg' },
  { name: 'attach', path: 'assets/icons/attach.svg' },
  { name: 'audio', path: 'assets/icons/audio.svg' },
  { name: 'delete', path: 'assets/icons/delete.svg' },
  { name: 'download', path: 'assets/icons/download.svg' },
  { name: 'emoji', path: 'assets/icons/emoji.svg' },
  { name: 'file', path: 'assets/icons/file.svg' },
  { name: 'image', path: 'assets/icons/image.svg' },
  { name: 'new-chat', path: 'assets/icons/new-chat.svg' },
  { name: 'pause-recording', path: 'assets/icons/pause-recording.svg' },
  { name: 'play-recording', path: 'assets/icons/play-recording.svg' },
  { name: 'record', path: 'assets/icons/record.svg' },
  { name: 'send-message', path: 'assets/icons/send-message.svg' },
  { name: 'video', path: 'assets/icons/video.svg' },
  { name: 'mic', path: 'assets/icons/mic.svg' },
  { name: 'delete-recording', path: 'assets/icons/delete-recording.svg' },
  { name: 'smileys_emotion', path: 'assets/icons/smileys_emotion.svg' },
  { name: 'people_body', path: 'assets/icons/people_body.svg' },
  { name: 'animals_nature', path: 'assets/icons/animals_nature.svg' },
  { name: 'food_drink', path: 'assets/icons/food_drink.svg' },
  { name: 'travel_places', path: 'assets/icons/travel_places.svg' },
  { name: 'activities', path: 'assets/icons/activities.svg' },
  { name: 'objects', path: 'assets/icons/objects.svg' },
  { name: 'symbols', path: 'assets/icons/symbols.svg' },
  { name: 'flags', path: 'assets/icons/flags.svg' },
  { name: 'recent', path: 'assets/icons/recent.svg' },
  { name: 'group', path: 'assets/icons/group.svg' },
  { name: 'user', path: 'assets/icons/user.svg' },
  { name: 'plus', path: 'assets/icons/plus.svg' },
  { name: 'minimize', path: 'assets/icons/minimize.svg' },
  { name: 'maximize', path: 'assets/icons/maximize.svg' },
  { name: 'eye-hide', path: 'assets/icons/eye-hide.svg' },
  { name: 'forward-send', path: 'assets/icons/forward-send.svg' },
  { name: 'dialpad', path: 'assets/icons/dial-pad.svg' },
  { name: 'dialpad_active', path: 'assets/icons/dial-pad_active.svg' },
  { name: 'gif', path: 'assets/icons/gif.svg' },
  { name: 'read-confirmation', path: 'assets/icons/read-confirmation.svg' },
  {
    name: 'smileys_emotion-field',
    path: 'assets/icons/smileys_emotion-field.svg',
  },
  { name: 'instant-meeting', path: 'assets/icons/instant-meeting.svg' },
  { name: 'scheduled-meeting', path: 'assets/icons/scheduled-meeting.svg' },
  { name: 'meetings', path: 'assets/icons/meetings.svg' },
  { name: 'add-markdown', path: 'assets/icons/add-markdown.svg' },
  { name: 'calendar', path: 'assets/icons/calendar.svg' },
  { name: 'email', path: 'assets/icons/email.svg' },
  { name: 'pencil', path: 'assets/icons/pencil.svg' },
  { name: 'add-calendar', path: 'assets/icons/add-calendar.svg' },
  { name: 'company', path: 'assets/icons/company.svg' },
  { name: 'go-back-arrow', path: 'assets/icons/go-back-arrow.svg' },
  { name: 'timer', path: 'assets/icons/timer.svg' },
  {
    name: 'cloud-computing',
    path: 'assets/icons/cloud-computing.svg',
  },
  {
    name: 'customer',
    path: 'assets/icons/customer.svg',
  },
  {
    name: 'new-document',
    path: 'assets/icons/new-document.svg',
  },
  {
    name: 'unread-message',
    path: 'assets/icons/unread-message.svg',
  },
  {
    name: 'read-message',
    path: 'assets/icons/read-message.svg',
  },
  { name: 'rec', path: 'assets/icons/rec.svg' },
  { name: 'icon-transcription', path: 'assets/icons/icon-transcription.svg' },
  { name: 'dial-pad-short', path: 'assets/icons/dial-pad-short.svg' },
  { name: 'voice-rec', path: 'assets/icons/voice-rec.svg' },
  { name: 'icon-anexar', path: 'assets/icons/icon-anexar.svg' },
  { name: 'icon-emoji', path: 'assets/icons/icon-emoji.svg' },
  { name: 'icon-formatar', path: 'assets/icons/icon-formatar.svg' },
  { name: 'icon-gif', path: 'assets/icons/icon-gif.svg' },
  { name: 'icon-ia', path: 'assets/icons/icon-ia.svg' },
  { name: 'icon-plus', path: 'assets/icons/icon-plus.svg' },
  { name: 'people-group', path: 'assets/icons/people-group.svg' },
  { name: 'multiple-users', path: 'assets/icons/multiple-users.svg' },

  // Panel Icons
  { name: 'icon-audio-library', path: 'assets/icons/icon-audio-library.svg' },
  { name: 'icon-call-register', path: 'assets/icons/icon-call-register.svg' },
  { name: 'icon-capture-groups', path: 'assets/icons/icon-capture-groups.svg' },
  { name: 'icon-company-sectors', path: 'assets/icons/icon-company-sectors.svg' },
  { name: 'icon-dashboard', path: 'assets/icons/icon-dashboard.svg' },
  { name: 'icon-deviations', path: 'assets/icons/icon-deviations.svg' },
  { name: 'icon-entry-route', path: 'assets/icons/icon-entry-route.svg' },
  { name: 'icon-pabx-integrations', path: 'assets/icons/icon-pabx-integrations.svg' },
  { name: 'icon-schedule-groups', path: 'assets/icons/icon-schedule-groups.svg' },
  { name: 'icon-system-codes', path: 'assets/icons/icon-system-codes.svg' },
  { name: 'icon-timetable-conditions', path: 'assets/icons/icon-timetable-conditions.svg' },
  { name: 'icon-trunks', path: 'assets/icons/icon-trunks.svg' },
  { name: 'icon-apps', path: 'assets/icons/icon-apps.svg' },
  { name: 'icon-ring-groups', path: 'assets/icons/icon-ring-groups.svg' },
  { name: 'icon-uras', path: 'assets/icons/icon-uras.svg' },
  { name: 'icon-user', path: 'assets/icons/icon-user.svg' },
  { name: 'icon-log-file', path: 'assets/icons/icon-log-file.svg' },
  { name: 'icon-advanced-configurations', path: 'assets/icons/icon-advanced-configurations.svg' },
  { name: 'icon-providers', path: 'assets/icons/icon-providers.svg' },
  { name: 'icon-advanced-configurations', path: 'assets/icons/icon-advanced-configurations.svg' },
  { name: 'icon-basic-configurations', path: 'assets/icons/icon-basic-configurations.svg' },
  { name: 'icon-logs', path: 'assets/icons/icon-logs.svg' },
  { name: 'icon-shortcuts', path: 'assets/icons/icon-shortcuts.svg' },
  { name: 'icon-extensions', path: 'assets/icons/icon-extensions.svg' },
];
