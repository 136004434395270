import { environment } from "@environments/environment";
import { getLSTenantId } from "./common.storage";

/**
 * Get Firebase User Path
 * @param {string} tenantId Company id
 * @param {string} userId User id
 * @returns {string} User path
 */
export const getUsersCollectionPath = (tenantId: string, userId?: string): string => {
  return userId
    ? `companies/${tenantId}/users/${userId}`
    : `companies/${tenantId}/users`;
}

/**
 * Get path for user's calling with
 * @param {string} tenantId Company id
 * @param {string} userId User id
 * @returns {string} Calling with path
 */
export const getCallingWithPath = (tenantId: string, userId: string): string => {
  return `companies/${tenantId}/users/${userId}/callingWith`;
}

/**
 * Get path for company count
 * @param {string} tenantId Company id
 * @returns {string} ICompany count path
 */
export const getCompanyCountPath = (tenantId: string): string => {
  return `counters/companies/${tenantId}`;
}

/**
 * Get Firebase Contact Path
 * @param {string} tenantId Company id
 * @param {string} contactId Contact id
 * @returns {string} Contact path
 */
export const getContactsPath = (tenantId: string, contactId?: string): string => {
  return contactId
    ? `companies/${tenantId}/contacts/${contactId}`
    : `companies/${tenantId}/contacts`;
}

/**
 * Get Firebase Company sector path
 * @param {string} tenantId Company id
 * @param {string} sectorId Sector id
 */
export const getCompanySectorPath = (tenantId: string, sectorId?: string): string => {
  return sectorId
    ? `companies/${tenantId}/company-sectors/${sectorId}`
    : `companies/${tenantId}/company-sectors`;
}

/**
 * Get Firebase Capture Group path
 * @param {string} tenantId Company id
 * @param {string} id Capture Group id
 */
export const getCaptureGroupPath = (tenantId: string, id?: string): string => {
  return id
    ? `companies/${tenantId}/capture-groups/${id}`
    : `companies/${tenantId}/capture-groups`;
}

/**
 * Create PABX sector path
 * @param {string} id PABX Sector ID
 * @returns {string} path to the PABX sector collection
 */
export const getPABXSectorPath = (id?: string): string => {
  return `companies/${getLSTenantId()}/sectors/${id ?? ''}`
}

/**
 * Form the PABX path
 * @param {string} id PABX ID
 * @return {string} Firestore path
 */
export const getPABXPath = (id?: string): string => {
  return `companies/${getLSTenantId()}/pbx${id ? `/${id}` : ''}`;
}

/**
 * Form the extensions path
 * @param {string} extId Extension ID
 */
export const getExtensionPathByCompanyId = (extId?: string): string => {
  return `companies/${getLSTenantId()}/extensions${extId ? `/${extId}` : ''}`
}

/**
 * Get Device Path
 * @param {string} deviceId Device ID
 * @returns {string} Device path
 */
export const getDevicePath = (deviceId?: string): string => {
  return `devices${deviceId ? `/${deviceId}` : ''}`
}

/**
 * Form the devices extension path
 * @param {string} deviceId Device ID
 * @param {string} extensionId Extension ID
 * @returns {string} Device extension path
 */
export const getDeviceExtensionPath = (deviceId: string, extensionId?: string): string => {
  return `devices/${deviceId}/extensions${extensionId ? `/${extensionId}` : ''}`
}

/**
 * Form the devices providers path
 * @param {string} deviceId Device ID
 * @param {string} providerId Provider ID
 * @returns {string} Device provider path
 */
export const getDeviceProviderPath = (deviceId: string, providerId?: string): string => {
  return `devices/${deviceId}/providers${providerId ? `/${providerId}` : ''}`
}

/**
 * Get path to create equip extensions
 */
export const getCreateEquipExtensionsPath = (): string => {
  return `${environment.baseURL}/device/createext`;
}

/**
 * Form the cdr path
 * @returns the cdr path
 */
export const getCDRPath = () => {
  return `${environment.baseURL}/cdr`;
}

/**
 * Get matrix path
 * @param {string} tenantId Tenant id
 * @param {string} roomId Group id
 * @param {string} endpoint Endpoint
 * @returns {string} Matrix path
 */
export const getMatrixPath = (tenantId: string, roomId: string, endpoint?: string): string => {
  return `${environment.baseURL}/matrix/tenants/${tenantId}/rooms/${roomId}${endpoint ? `/${endpoint}` : ''}`;
}

/**
 * Create user path
 * @param {string} companyId Company ID
 * @param {string} id user ID
 * @returns {string} path to the user collection
 */
export const getUserFunctionsPath = (companyId: string, id?: string): string => {
  return `${environment.baseURL}/users/tenants/${companyId}/users${id ? `/${id}` : ''}`;
}

/**
 * Create user connections path
 * @returns {string} path to the user connections
 */
export const getConnectionsFunctionsPath = (): string => {
  return `${environment.baseURL}/users/connections`;
}

/**
 * Get the path to the lcs events
 * @param {string} endpoint Path endpoint
 * @returns {string} path to the lcs events
 */
export const getLCSEventPath = (endpoint: string): string => {
  return `${environment.baseURL}/lcsEvents/lcs/events${endpoint ? `/${endpoint}` : ''}`;
}

/**
 * Create group path
 * @param {string} id Grupo ID
 * @returns {string} path to the group collection
 */
export const getGroupsPath = (id?: string): string => {
  return `companies/${getLSTenantId()}/groups/${id ?? ''}`
}

/**
 * Create integration path
 * @param {string} id Integration ID
 * @returns {string} path to the integration collection
 */
export const getIntegrationPath = (id?: string): string => {
  return `companies/${getLSTenantId()}/integrations/${id ?? ''}`
}


/**
 * Get Firebase Trunk Path
 * @param {string} tenantId Company id
 * @param {string} trunkId Trunk id
 * @returns {string} Trunk path
 */
export const getTrunksPath = (tenantId: string, trunkId?: string): string => {
  return trunkId
    ? `companies/${tenantId}/trunks/${trunkId}`
    : `companies/${tenantId}/trunks`;
}

/**
 * Get Firebase Contact Path
 * @param {string} tenantId Company id
 * @param {string} ringGroupId Access code id
 * @returns {string} path to the ring Groups collection
 */
export const getRingGroupsPath = (tenantId: string, ringGroupId?: string): string => {
  return ringGroupId
    ? `companies/${tenantId}/ring-groups/${ringGroupId}`
    : `companies/${tenantId}/ring-groups`;
}

/**
 * Get Firebase TimeCondition Path
 * @param {string} tenantId Company id
 * @param {string} timeConditionId TimeCondition id
 * @returns {string} TimeCondition path
 */
export const getTimeConditionsPath = (tenantId: string, timeConditionId?: string): string => {
  return timeConditionId
    ? `companies/${tenantId}/timeConditions/${timeConditionId}`
    : `companies/${tenantId}/timeConditions`;
}



/**
 * Get Firebase Entry Routes Path
 * @param {string} tenantId Company id
 * @param {string} entryRoute EntryRoute id
 * @returns {string} EntryRoute path
 */
export const getEntryRoutesPath = (tenantId: string, entryRoute?: string): string => {
  return entryRoute
    ? `companies/${tenantId}/entryRoutes/${entryRoute}`
    : `companies/${tenantId}/entryRoutes`;
}
