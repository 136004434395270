import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { where } from '@angular/fire/firestore';
import { FirestoreService } from '@core/services';
import {
  AppIntegration,
  Group,
  PlanTypes,
  Tenant
} from '@shared/models';
import { setLSCompanyPABXIntegration, setLSTenantId } from '@shared/utils';
import { Observable, combineLatest, lastValueFrom } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  tenant: Tenant;
  tenants: Tenant[];

  constructor(
    private auth: Auth,
    private firestore: FirestoreService
  ) { }

  private async setTenant(tenantId: string): Promise<void> {
    this.tenant = await lastValueFrom(
      this.firestore
        .docWithId$<Tenant>(`companies/${tenantId}`)
        .pipe(first())
    );

    // Set PABX integration
    setLSCompanyPABXIntegration(this.tenant.pbxIntegration);
  }

  async setTenantId(tenantId: string): Promise<void> {
    this.auth.tenantId = tenantId;
    await this.setTenant(tenantId);
    setLSTenantId(tenantId);
  }

  async findTenantByUserEmail(email: string): Promise<Tenant[]> {
    this.tenants = [];
    const tenantData: Tenant[] = await this.firestore
      .colWithIds$(`companies`)
      .pipe(
        first(),
        switchMap((tenants: any[]) => {
          const res = tenants.map((t: any) => {
            return this.firestore
              .col$(`companies/${t.id}/users`, [
                where('email', '==', email),
              ])
              .pipe(
                first(),
                map((users) => Object.assign(t, { users }))
              );
          });
          return combineLatest(res);
        })
      )
      .pipe(
        map((tenants: Tenant[]) => {
          return tenants.filter((tenant) => {
            for (const user of tenant.users) {
              if (user.email === email) {
                return true;
              }
            }
          });
        })
      )
      .toPromise();

    if (tenantData) {
      this.tenants.push(...tenantData);
    }

    return new Promise((resolve, reject) => {
      resolve(this.tenants);
    });
  }

  getById(id: string): Observable<Tenant> {
    return this.firestore.docWithId$<Tenant>(
      `companies/${id}`
    );
  }

  async getCompanyById(id: string) {
    return await lastValueFrom(this.firestore.docWithId$<any>(
      `companies/${id}`
    ).pipe(first()))
  }

  /**
   * Check if can show chat
   * @returns {boolean} true if can show chat
   */
  public canShowChat(): boolean {
    return !!this.tenant.collaborationEnabled;
  }

  getIntegrations(): Promise<AppIntegration[]> {
    const tenantId: string = this.tenant?.id;
    const path = `companies/${tenantId}/integrations`;
    return lastValueFrom(
      this.firestore.colWithIds$<AppIntegration>(path).pipe(
        map((integrations) =>
          integrations.map((integration) => {
            // Remove .svg from the name with exist
            if (integration.icon?.name) {
              integration.icon.name = integration.icon.name.replace('.svg', '');
            }
            return integration;
          })
        ),
        first()
      )
    );
  }

  getGroupById(groupId: string): Promise<Group> {
    const tenantId: string = this.tenant?.id;
    const path = `companies/${tenantId}/groups/${groupId}`;
    return lastValueFrom(this.firestore.docWithId$<Group>(path).pipe(first()));
  }

  /**
  * Check if company has expiry date
  * @param {PlanTypes} option Plan Types
  * @returns {boolean} true if company has expiry date
  */
  public hasExpiryDate(option: PlanTypes): boolean {
    switch (option) {
      case PlanTypes.SHOWCASE:
      case PlanTypes.TRIAL:
        return true;

      default:
        return false;
    }
  }

  /**
   * Check if trial ended
   * @param {Company} company Company data
   * @returns {boolean} True if trial ended
   */
  public isTrialFinished(): boolean {
    const company = this.tenant;
    return (
      this.hasExpiryDate(company.planType) &&
      company.trialTime?.toDate()?.getTime() < new Date().getTime()
    );
  }
}
