import { Injectable } from '@angular/core';
import { ipcRenderer, shell } from 'electron';

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  // Electron variables
  public ipcRenderer: typeof ipcRenderer;
  public shell: typeof shell;

  // Electron flag
  public isElectronApp: boolean = /Electron/i.test(navigator.userAgent);

  // Platform
  public isWindows: boolean = navigator.userAgent.toLowerCase().indexOf('win') > -1;
  public isMac: boolean = navigator.userAgent.toLowerCase().indexOf('mac') > -1;
  public isLinux: boolean = navigator.userAgent.toLowerCase().indexOf('linux') > -1;

  constructor() {
    // Set Electron variables
    if (this.isElectronApp) {
      this.ipcRenderer = window.require('electron')?.ipcRenderer;
      this.shell = window.require('electron')?.shell;
    }
  }
}
