import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivate } from "@angular/router";
import { ExitingConfirmationComponent } from "@shared/components";
import { CanComponentDeactivate } from "@shared/models";
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog) { }

  public canDeactivate(component: CanComponentDeactivate): Observable<boolean> | boolean {
    // Check if the component is not dirty
    if (component.isNotDirty()) return true;

    // Open a dialog to confirm the user's intention to navigate away from the component
    const dialogRef = this.dialog.open(ExitingConfirmationComponent, {
      disableClose: true,
      panelClass: 'confirm-change-dialog',
      data: {
        text: component.unsavedChangesMessage,
        save: () => component.submit?.() // Método opcional
      }
    });

    // Wait for the dialog to be closed and return the result as an Observable emitting a boolean value
    return dialogRef.afterClosed().pipe(first());
  }
}
