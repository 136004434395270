export const EMOTICON_MAP: Map<string, string> = new Map([
  [':)', '😊'],
  [':D', '😃'],
  [':P', '😛'],
  [':(', '🙁'],
  [':|', '😐'],
  [';)', '😉'],
  [';D', '😆'],
  [':O', '😮'],
  [':0', '😮'],
  [':s', '😕'],
  [':@', '😠'],
  [':\'(', '😢'],
  [':$', '🤑'],
  [':*', '😘'],
  [':3', '😽'],
  [':-)', '😊'],
  [':-D', '😃'],
  [':-P', '😛'],
  [':-(', '🙁'],
  [':-/', '🤔'],
  [':-\\', '🤔'],
  [':-|', '😐'],
  [';-)', '😉'],
  [';-D', '😆'],
  [':-O', '😮'],
  [':-s', '😕'],
  [':X', '😵'],
  [':-@', '😠'],
  [':\'-(', '😢'],
  [':-$', '🤑'],
  [':-*', '😘'],
  [':-3', '😽'],
  ['<3', '❤'],
  ['</3', '💔'],
  [':v', '✌️'],
  [':fire:', '🔥'],
  [':thumbsup:', '👍'],
  [':thumbsdown:', '👎']
]);

export const EMOJI_LIST = [
  {
    "codes": "1f600",
    "char": "😀",
    "name": "Grinning Face",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente",
      "grinning face"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grin grinning happy joyful joyous look mug radiant radiante riendo risa riso risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso visage",
    "imgUrl": "assets/emojis/1f600.svg"
  },
  {
    "codes": "1f603",
    "char": "😃",
    "name": "Grinning Face With Big Eyes",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente com olhos grandes",
      "grinning face with big eyes",
      "cara sonriente con ojos grandes"
    ],
    "keywordsStr": "aberto alegre animado apariencia beaming big brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido emocionado empolgado enthusiastic entusiasmado excitante excited expresión expression expressão eyes face faz features feliz fisionomia fisonomía glad gozoso grandes grinning joyful joyous look mug ojos olhos radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso visage with",
    "imgUrl": "assets/emojis/1f603.svg"
  },
  {
    "codes": "1f604",
    "char": "😄",
    "name": "Grinning Face With Smiling Eyes",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente com olhos sorridentes",
      "grinning face with smiling eyes",
      "cara sonriente con ojos sonrientes"
    ],
    "keywordsStr": "aberto alegre apariencia beaming bright brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão eyes face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous look mug ojos olhos radiant radiante riendo risonho rosto rostro satisfecho satisfeito satisfied semblante smiling sonriendo sonriente sonrientes sorridente sorridentes sorrindo sorriso visage with",
    "imgUrl": "assets/emojis/1f604.svg"
  },
  {
    "codes": "1f606",
    "char": "😆",
    "name": "Grinning Squinting Face",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto de estrabismo sorridente",
      "grinning squinting face",
      "cara sonriente entrecerrando los ojos"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery content contente contento countenance de delighted divertido empolgado entrecerrando entusiasmado estrabismo expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous look los mug ojos radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso squinting visage",
    "imgUrl": "assets/emojis/1f606.svg"
  },
  {
    "codes": "1f601",
    "char": "😁",
    "name": "Beaming Face With Smiling Eyes",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto radiante com olhos sorridentes",
      "beaming face with smiling eyes",
      "cara radiante con ojos sonrientes"
    ],
    "keywordsStr": "aberto alegre animado apariencia beaming big brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido ecstatic empolgado entusiasmado excited expresión expression expressão extasiado eyes face faz features feliz fisionomia fisonomía glad gozoso gran grinning joyful joyous largo look mug ojos olhos radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smile smiling sonriendo sonriente sonrientes sonrisa sorridente sorridentes sorrindo sorriso visage with",
    "imgUrl": "assets/emojis/1f601.svg"
  },
  {
    "codes": "1f605",
    "char": "😅",
    "name": "Grinning Face With Sweat",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente com suor",
      "grinning face with sweat",
      "cara sonriente con sudor"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous look mug radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso sudor suor sweat visage with",
    "imgUrl": "assets/emojis/1f605.svg"
  },
  {
    "codes": "1f923",
    "char": "🤣",
    "name": "Rolling On The Floor Laughing",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rolando no chão de tanto rir",
      "rolling on the floor laughing",
      "rodando por el suelo riendo"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery chão content contente contento countenance de delighted divertido divertidísimo el empolgado engraçado entusiasmado expresión expression expressão extrema extreme face faz features feliz fisionomia fisonomía floor funny glad gozoso grinning joyful joyous laughing laughter look mug no on parar por radiant radiante riendo rir risa risada risonho rodando rolando rolling rosto rostro satisfecho satisfeito semblante sin smiling sonriendo sonriente sorridente sorrindo sorriso suelo tanto the visage",
    "imgUrl": "assets/emojis/1f923.svg"
  },
  {
    "codes": "1f602",
    "char": "😂",
    "name": "Face With Tears Of Joy",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto com lágrimas de alegria",
      "face with tears of joy",
      "rostro con lágrimas de alegría"
    ],
    "keywordsStr": "aberto alegre alegria alegría apariencia bawling beaming brilhante brillante cackling cara carcajeando cheerful cheery chorando chuckling com con content contente contento countenance crying de delighted desabafo desconsolado divertido emoção empolgado engraçado entusiasmado eufórico expresión expression expressão face faz features feliz fisionomia fisonomía fuerte gargalhando giggling gimiendo glad gozoso gracioso grinning hard hilarante hilariante hilarious hilário jocoso joy joyful joyous lagrimeando lamentando laughing llorando look lágrimas mug muito of radiant radiante riendo rindo rir risa risonho roaring rosto rostro satisfecho satisfeito semblante smiling snickering sobbing sollozando sonriendo sonriente sorridente sorrindo sorriso tears teary triste tristeza visage wailing weeping with",
    "imgUrl": "assets/emojis/1f602.svg"
  },
  {
    "codes": "1f642",
    "char": "🙂",
    "name": "Slightly Smiling Face",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto levemente sorridente",
      "slightly smiling face",
      "cara ligeramente sonriente"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous levemente ligeramente look mug radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante slightly smiling sonriendo sonriente sorridente sorrindo sorriso visage",
    "imgUrl": "assets/emojis/1f642.svg"
  },
  {
    "codes": "1f643",
    "char": "🙃",
    "name": "Upside Down Face",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "cara de cabeça para baixo",
      "upside-down face",
      "cara al revés"
    ],
    "keywordsStr": "aberto al alegre apariencia baixo beaming brilhante brillante cabeça cara cheerful cheery content contente contento countenance de delighted divertido empolgado entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous look mug para radiant radiante revés riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso upside-down visage",
    "imgUrl": "assets/emojis/1f643.svg"
  },
  {
    "codes": "1f609",
    "char": "😉",
    "name": "Winking Face",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto piscando",
      "winking face",
      "cara de guiño"
    ],
    "keywordsStr": "aberto alegre apariencia beaming blinking brilhante brillante brincadeira brincalhão cara charme charming cheerful cheery content contente contento coqueteando countenance de delighted divertido empolgado encantador entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía flertando flirting glad gozoso grinning guiñando guiño insinuante joyful joyous juguetón look mug olhar olhar brincalhão parpadeando piscadela piscando piscar playful pícaro radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso teasing visage wink winking",
    "imgUrl": "assets/emojis/1f609.svg"
  },
  {
    "codes": "1f60a",
    "char": "😊",
    "name": "Smiling Face With Smiling Eyes",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente com olhos sorridentes",
      "smiling face with smiling eyes",
      "cara sonriente con ojos sonrientes"
    ],
    "keywordsStr": "aberto alegre apariencia beaming brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão eyes face faz features feliz fisionomia fisonomía glad gozoso grinning joyful joyous look mug ojos olhos radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sonrientes sorridente sorridentes sorrindo sorriso visage with",
    "imgUrl": "assets/emojis/1f60a.svg"
  },
  {
    "codes": "1f607",
    "char": "😇",
    "name": "Smiling Face With Halo",
    "category": "Smileys & Emotion (face-smiling)",
    "group": "smileysAndEmotion",
    "subgroup": "face-smiling",
    "keywords": [
      "cara",
      "face",
      "sorrindo",
      "smiling",
      "sonriendo",
      "rosto sorrindo",
      "face smiling",
      "cara sonriente",
      "rosto sorridente com auréola",
      "smiling face with halo",
      "cara sonriente con halo"
    ],
    "keywordsStr": "aberto alegre apariencia auréola beaming brilhante brillante cara cheerful cheery com con content contente contento countenance delighted divertido empolgado entusiasmado expresión expression expressão face faz features feliz fisionomia fisonomía glad gozoso grinning halo joyful joyous look mug radiant radiante riendo risonho rosto rostro satisfecho satisfeito semblante smiling sonriendo sonriente sorridente sorrindo sorriso visage with",
    "imgUrl": "assets/emojis/1f607.svg"
  },
  {
    "codes": "1f970",
    "char": "🥰",
    "name": "Smiling Face With Hearts",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "rosto sorridente com corações",
      "smiling face with hearts",
      "cara sonriente con corazones"
    ],
    "keywordsStr": "afecto affection apariencia cara carinho cariño com con corazones corações countenance de expresión expression expressão face faz features fisionomia fisonomía hearts look mug rosto rostro semblante smiling sonriente sorridente visage with",
    "imgUrl": "assets/emojis/1f970.svg"
  },
  {
    "codes": "1f60d",
    "char": "😍",
    "name": "Smiling Face With Heart Eyes",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "rosto sorridente com olhos de coração",
      "smiling face with heart-eyes",
      "Cara sonriente con ojos de corazón"
    ],
    "keywordsStr": "Cara afecto affection apariencia cara carinho cariño com con corazón coração countenance de expresión expression expressão face faz features fisionomia fisonomía heart-eyes look mug ojos olhos rosto rostro semblante smiling sonriente sorridente visage with",
    "imgUrl": "assets/emojis/1f60d.svg"
  },
  {
    "codes": "1f929",
    "char": "🤩",
    "name": "Star Struck",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "estrela",
      "star-struck",
      "estrellado"
    ],
    "keywordsStr": "afecto affection apariencia cara carinho cariño countenance de estrela estrellado expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante star-struck visage",
    "imgUrl": "assets/emojis/1f929.svg"
  },
  {
    "codes": "1f618",
    "char": "😘",
    "name": "Face Blowing A Kiss",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "rosto mandando um beijo",
      "face blowing a kiss",
      "cara lanzando un beso"
    ],
    "keywordsStr": "a afecto affection apariencia beijo beso blowing cara carinho cariño countenance de expresión expression expressão face faz features fisionomia fisonomía kiss lanzando look mandando mug rosto rostro semblante um un visage",
    "imgUrl": "assets/emojis/1f618.svg"
  },
  {
    "codes": "1f617",
    "char": "😗",
    "name": "Kissing Face",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "cara de beijo",
      "kissing face",
      "cara de besos"
    ],
    "keywordsStr": "afecto affection apariencia beijo besos cara carinho cariño countenance de expresión expression expressão face faz features fisionomia fisonomía kissing look mug rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f617.svg"
  },
  {
    "codes": "263a",
    "char": "☺️",
    "name": "Smiling Face",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "rosto sorridente",
      "smiling face",
      "cara sonriente"
    ],
    "keywordsStr": "afecto affection apariencia cara carinho cariño countenance de expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante smiling sonriente sorridente visage",
    "imgUrl": "assets/emojis/263a.svg"
  },
  {
    "codes": "1f61a",
    "char": "😚",
    "name": "Kissing Face With Closed Eyes",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "beijando o rosto com os olhos fechados",
      "kissing face with closed eyes",
      "besando la cara con los ojos cerrados"
    ],
    "keywordsStr": "afecto affection apariencia beijando besando cara carinho cariño cerrados closed com con countenance de expresión expression expressão eyes face faz features fechados fisionomia fisonomía kissing la look los mug o ojos olhos os rosto rostro semblante visage with",
    "imgUrl": "assets/emojis/1f61a.svg"
  },
  {
    "codes": "1f619",
    "char": "😙",
    "name": "Kissing Face With Smiling Eyes",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "beijando rosto com olhos sorridentes",
      "kissing face with smiling eyes",
      "cara de beso con ojos sonrientes"
    ],
    "keywordsStr": "afecto affection apariencia beijando beso cara carinho cariño com con countenance de expresión expression expressão eyes face faz features fisionomia fisonomía kissing look mug ojos olhos rosto rostro semblante smiling sonrientes sorridentes visage with",
    "imgUrl": "assets/emojis/1f619.svg"
  },
  {
    "codes": "1f972",
    "char": "🥲",
    "name": "Smiling Face With Tear",
    "category": "Smileys & Emotion (face-affection)",
    "group": "smileysAndEmotion",
    "subgroup": "face-affection",
    "keywords": [
      "cara",
      "face",
      "carinho",
      "affection",
      "afecto",
      "carinho de rosto",
      "face affection",
      "cara de cariño",
      "rosto sorridente com lágrima",
      "smiling face with tear",
      "cara sonriente con lágrimas"
    ],
    "keywordsStr": "afecto affection apariencia cara carinho cariño com con countenance de expresión expression expressão face faz features fisionomia fisonomía look lágrima lágrimas mug rosto rostro semblante smiling sonriente sorridente tear visage with",
    "imgUrl": "assets/emojis/1f972.svg"
  },
  {
    "codes": "1f60b",
    "char": "😋",
    "name": "Face Savoring Food",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "cara saboreando comida",
      "face savoring food",
      "cara saboreando la comida"
    ],
    "keywordsStr": "apariencia cara comida countenance do expresión expression expressão face faz features fisionomia fisonomía food la lengua look língua mug rosto rostro saboreando savoring semblante tongue visage",
    "imgUrl": "assets/emojis/1f60b.svg"
  },
  {
    "codes": "1f61b",
    "char": "😛",
    "name": "Face With Tongue",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "rosto com língua",
      "face with tongue",
      "cara con lengua"
    ],
    "keywordsStr": "apariencia cara com con countenance do expresión expression expressão face faz features fisionomia fisonomía lengua look língua mug rosto rostro semblante tongue visage with",
    "imgUrl": "assets/emojis/1f61b.svg"
  },
  {
    "codes": "1f61c",
    "char": "😜",
    "name": "Winking Face With Tongue",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "rosto piscando com a língua",
      "winking face with tongue",
      "guiñando la cara con la lengua"
    ],
    "keywordsStr": "a apariencia blinking brincalhão cara charme com con coqueteando countenance do expresión expression expressão face faz features fisionomia fisonomía flertando flirting guiñando insinuante juguetón la lengua look língua mug olhar olhar brincalhão parpadeando piscadela piscando piscar playful pícaro rosto rostro semblante teasing tongue visage winking with",
    "imgUrl": "assets/emojis/1f61c.svg"
  },
  {
    "codes": "1f92a",
    "char": "🤪",
    "name": "Zany Face",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "cara de louco",
      "zany face",
      "cara loca"
    ],
    "keywordsStr": "apariencia cara countenance de do expresión expression expressão face faz features fisionomia fisonomía lengua loca look louco língua mug rosto rostro semblante tongue visage zany",
    "imgUrl": "assets/emojis/1f92a.svg"
  },
  {
    "codes": "1f61d",
    "char": "😝",
    "name": "Squinting Face With Tongue",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "rosto vesgo com língua",
      "squinting face with tongue",
      "entrecerrar los ojos con la lengua"
    ],
    "keywordsStr": "apariencia cara com con countenance do entrecerrar expresión expression expressão face faz features fisionomia fisonomía la lengua look los língua mug ojos rosto rostro semblante squinting tongue vesgo visage with",
    "imgUrl": "assets/emojis/1f61d.svg"
  },
  {
    "codes": "1f911",
    "char": "🤑",
    "name": "Money Mouth Face",
    "category": "Smileys & Emotion (face-tongue)",
    "group": "smileysAndEmotion",
    "subgroup": "face-tongue",
    "keywords": [
      "cara",
      "face",
      "língua",
      "tongue",
      "lengua",
      "língua do rosto",
      "face tongue",
      "cara lengua",
      "cara de boca de dinheiro",
      "money-mouth face",
      "cara de boca de dinero"
    ],
    "keywordsStr": "apariencia boca cara countenance de dinero dinheiro do expresión expression expressão face faz features fisionomia fisonomía lengua look língua money-mouth mug rosto rostro semblante tongue visage",
    "imgUrl": "assets/emojis/1f911.svg"
  },
  {
    "codes": "1f917",
    "char": "🤗",
    "name": "Hugging Face",
    "category": "Smileys & Emotion (face-hand)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hand",
    "keywords": [
      "cara",
      "face",
      "mão",
      "hand",
      "mano",
      "mão de rosto",
      "face hand",
      "cara mano",
      "cara de abraço",
      "hugging face",
      "cara abrazada"
    ],
    "keywordsStr": "abrazada abraço apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía hand hugging look mano mug mão rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f917.svg"
  },
  {
    "codes": "1f92d",
    "char": "🤭",
    "name": "Face With Hand Over Mouth",
    "category": "Smileys & Emotion (face-hand)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hand",
    "keywords": [
      "cara",
      "face",
      "mão",
      "hand",
      "mano",
      "mão de rosto",
      "face hand",
      "cara mano",
      "rosto com a mão na boca",
      "face with hand over mouth",
      "cara con la mano sobre la boca"
    ],
    "keywordsStr": "a apariencia boca cara com con countenance de expresión expression expressão face faz features fisionomia fisonomía hand la look mano mouth mug mão na over rosto rostro semblante sobre visage with",
    "imgUrl": "assets/emojis/1f92d.svg"
  },
  {
    "codes": "1f92b",
    "char": "🤫",
    "name": "Shushing Face",
    "category": "Smileys & Emotion (face-hand)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hand",
    "keywords": [
      "cara",
      "face",
      "mão",
      "hand",
      "mano",
      "mão de rosto",
      "face hand",
      "cara mano",
      "cara calada",
      "shushing face",
      "cara callada"
    ],
    "keywordsStr": "apariencia calada callada cara countenance de expresión expression expressão face faz features fisionomia fisonomía hand look mano mug mão rosto rostro semblante shushing visage",
    "imgUrl": "assets/emojis/1f92b.svg"
  },
  {
    "codes": "1f914",
    "char": "🤔",
    "name": "Thinking Face",
    "category": "Smileys & Emotion (face-hand)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hand",
    "keywords": [
      "cara",
      "face",
      "mão",
      "hand",
      "mano",
      "mão de rosto",
      "face hand",
      "cara mano",
      "cara de pensamento",
      "thinking face",
      "cara pensante"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía hand look mano mug mão pensamento pensante rosto rostro semblante thinking visage",
    "imgUrl": "assets/emojis/1f914.svg"
  },
  {
    "codes": "1f910",
    "char": "🤐",
    "name": "Zipper Mouth Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "cara de boca de zíper",
      "zipper-mouth face",
      "cara de boca con cremallera"
    ],
    "keywordsStr": "apariencia boca cara con countenance cremallera cética cético de escéptico expresión expression expressão face faz features fisionomia fisonomía look mug neutra neutral neutro rosto rostro semblante skeptical visage zipper-mouth zíper",
    "imgUrl": "assets/emojis/1f910.svg"
  },
  {
    "codes": "1f928",
    "char": "🤨",
    "name": "Face With Raised Eyebrow",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "rosto com sobrancelha levantada",
      "face with raised eyebrow",
      "cara con ceja levantada"
    ],
    "keywordsStr": "apariencia cara ceja com con countenance cética cético escéptico expresión expression expressão eyebrow face faz features fisionomia fisonomía levantada look mug neutra neutral neutro raised rosto rostro semblante skeptical sobrancelha visage with",
    "imgUrl": "assets/emojis/1f928.svg"
  },
  {
    "codes": "1f610",
    "char": "😐",
    "name": "Neutral Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "cara neutra",
      "neutral face",
      "rostro neutro"
    ],
    "keywordsStr": "apariencia cara countenance cética cético escéptico expresión expression expressão face faz features fisionomia fisonomía look mug neutra neutral neutro rosto rostro semblante skeptical visage",
    "imgUrl": "assets/emojis/1f610.svg"
  },
  {
    "codes": "1f611",
    "char": "😑",
    "name": "Expressionless Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "rosto inexpressivo",
      "expressionless face",
      "cara inexpresiva"
    ],
    "keywordsStr": "apariencia cara countenance cética cético escéptico expresión expression expressionless expressão face faz features fisionomia fisonomía inexpresiva inexpressivo look mug neutra neutral neutro rosto rostro semblante skeptical visage",
    "imgUrl": "assets/emojis/1f611.svg"
  },
  {
    "codes": "1f636",
    "char": "😶",
    "name": "Face Without Mouth",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "rosto sem boca",
      "face without mouth",
      "rostro sin boca"
    ],
    "keywordsStr": "apariencia boca cara countenance cética cético escéptico expresión expression expressão face faz features fisionomia fisonomía look mouth mug neutra neutral neutro rosto rostro sem semblante sin skeptical visage without",
    "imgUrl": "assets/emojis/1f636.svg"
  },
  {
    "codes": "1f60f",
    "char": "😏",
    "name": "Smirking Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "rosto sorridente",
      "smirking face",
      "cara sonriente"
    ],
    "keywordsStr": "apariencia cara countenance cética cético escéptico expresión expression expressão face faz features fisionomia fisonomía look mug neutra neutral neutro rosto rostro semblante skeptical smirking sonriente sorridente visage",
    "imgUrl": "assets/emojis/1f60f.svg"
  },
  {
    "codes": "1f612",
    "char": "😒",
    "name": "Unamused Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "cara sem graça",
      "unamused face",
      "cara sin gracia"
    ],
    "keywordsStr": "apariencia cara countenance cética cético escéptico expresión expression expressão face faz features fisionomia fisonomía gracia graça look mug neutra neutral neutro rosto rostro sem semblante sin skeptical unamused visage",
    "imgUrl": "assets/emojis/1f612.svg"
  },
  {
    "codes": "1f644",
    "char": "🙄",
    "name": "Face With Rolling Eyes",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "rosto com olhos revirados",
      "face with rolling eyes",
      "cara con los ojos en blanco"
    ],
    "keywordsStr": "apariencia blanco cara com con countenance cética cético en escéptico expresión expression expressão eyes face faz features fisionomia fisonomía look los mug neutra neutral neutro ojos olhos revirados rolling rosto rostro semblante skeptical visage with",
    "imgUrl": "assets/emojis/1f644.svg"
  },
  {
    "codes": "1f62c",
    "char": "😬",
    "name": "Grimacing Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "cara de careta",
      "grimacing face",
      "cara de mueca"
    ],
    "keywordsStr": "apariencia cara careta countenance cética cético de escéptico expresión expression expressão face faz features fisionomia fisonomía grimacing look mueca mug neutra neutral neutro rosto rostro semblante skeptical visage",
    "imgUrl": "assets/emojis/1f62c.svg"
  },
  {
    "codes": "1f925",
    "char": "🤥",
    "name": "Lying Face",
    "category": "Smileys & Emotion (face-neutral-skeptical)",
    "group": "smileysAndEmotion",
    "subgroup": "face-neutral-skeptical",
    "keywords": [
      "cara",
      "face",
      "neutro",
      "neutral",
      "cético",
      "skeptical",
      "escéptico",
      "cara neutra cética",
      "face neutral skeptical",
      "cara neutral escéptico",
      "cara de mentira",
      "lying face"
    ],
    "keywordsStr": "apariencia cara countenance cética cético de escéptico expresión expression expressão face faz features fisionomia fisonomía look lying mentira mug neutra neutral neutro rosto rostro semblante skeptical visage",
    "imgUrl": "assets/emojis/1f925.svg"
  },
  {
    "codes": "1f60c",
    "char": "😌",
    "name": "Relieved Face",
    "category": "Smileys & Emotion (face-sleepy)",
    "group": "smileysAndEmotion",
    "subgroup": "face-sleepy",
    "keywords": [
      "cara",
      "face",
      "sonolento",
      "sleepy",
      "soñoliento",
      "cara de sono",
      "face sleepy",
      "cara de sueño",
      "rosto aliviado",
      "relieved face",
      "cara de alivio"
    ],
    "keywordsStr": "aliviado alivio apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mug relieved rosto rostro semblante sleepy sono sonolento soñoliento sueño visage",
    "imgUrl": "assets/emojis/1f60c.svg"
  },
  {
    "codes": "1f614",
    "char": "😔",
    "name": "Pensive Face",
    "category": "Smileys & Emotion (face-sleepy)",
    "group": "smileysAndEmotion",
    "subgroup": "face-sleepy",
    "keywords": [
      "cara",
      "face",
      "sonolento",
      "sleepy",
      "soñoliento",
      "cara de sono",
      "face sleepy",
      "cara de sueño",
      "cara pensativa",
      "pensive face"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mug pensativa pensive rosto rostro semblante sleepy sono sonolento soñoliento sueño visage",
    "imgUrl": "assets/emojis/1f614.svg"
  },
  {
    "codes": "1f62a",
    "char": "😪",
    "name": "Sleepy Face",
    "category": "Smileys & Emotion (face-sleepy)",
    "group": "smileysAndEmotion",
    "subgroup": "face-sleepy",
    "keywords": [
      "cara",
      "face",
      "sonolento",
      "sleepy",
      "soñoliento",
      "cara de sono",
      "face sleepy",
      "cara de sueño",
      "sleepy face"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante sleepy sono sonolento soñoliento sueño visage",
    "imgUrl": "assets/emojis/1f62a.svg"
  },
  {
    "codes": "1f924",
    "char": "🤤",
    "name": "Drooling Face",
    "category": "Smileys & Emotion (face-sleepy)",
    "group": "smileysAndEmotion",
    "subgroup": "face-sleepy",
    "keywords": [
      "cara",
      "face",
      "sonolento",
      "sleepy",
      "soñoliento",
      "cara de sono",
      "face sleepy",
      "cara de sueño",
      "cara de babar",
      "drooling face",
      "cara babeante"
    ],
    "keywordsStr": "apariencia babar babeante cara countenance de drooling expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante sleepy sono sonolento soñoliento sueño visage",
    "imgUrl": "assets/emojis/1f924.svg"
  },
  {
    "codes": "1f634",
    "char": "😴",
    "name": "Sleeping Face",
    "category": "Smileys & Emotion (face-sleepy)",
    "group": "smileysAndEmotion",
    "subgroup": "face-sleepy",
    "keywords": [
      "cara",
      "face",
      "sonolento",
      "sleepy",
      "soñoliento",
      "cara de sono",
      "face sleepy",
      "cara de sueño",
      "sleeping face",
      "cara durmiente"
    ],
    "keywordsStr": "apariencia cara countenance de durmiente expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante sleeping sleepy sono sonolento soñoliento sueño visage",
    "imgUrl": "assets/emojis/1f634.svg"
  },
  {
    "codes": "1f637",
    "char": "😷",
    "name": "Face With Medical Mask",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "rosto com máscara médica",
      "face with medical mask",
      "cara con mascarilla médica"
    ],
    "keywordsStr": "apariencia cara com con countenance enferma expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mascarilla mask medical mug máscara médica rosto rostro semblante unwell visage with",
    "imgUrl": "assets/emojis/1f637.svg"
  },
  {
    "codes": "1f912",
    "char": "🤒",
    "name": "Face With Thermometer",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "rosto com termômetro",
      "face with thermometer",
      "cara con termómetro"
    ],
    "keywordsStr": "apariencia cara com con countenance enferma expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mug rosto rostro semblante termómetro termômetro thermometer unwell visage with",
    "imgUrl": "assets/emojis/1f912.svg"
  },
  {
    "codes": "1f915",
    "char": "🤕",
    "name": "Face With Head Bandage",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "rosto com bandagem na cabeça",
      "face with head-bandage",
      "cara con vendaje en la cabeza"
    ],
    "keywordsStr": "apariencia bandagem cabeza cabeça cara com con countenance en enferma expresión expression expressão face faz features fisionomia fisonomía head-bandage indisposto la look mal malestar mug na rosto rostro semblante unwell vendaje visage with",
    "imgUrl": "assets/emojis/1f915.svg"
  },
  {
    "codes": "1f922",
    "char": "🤢",
    "name": "Nauseated Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara enjoada",
      "nauseated face",
      "cara de náuseas"
    ],
    "keywordsStr": "apariencia cara countenance de enferma enjoada expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mug nauseated náuseas rosto rostro semblante unwell visage",
    "imgUrl": "assets/emojis/1f922.svg"
  },
  {
    "codes": "1f92e",
    "char": "🤮",
    "name": "Face Vomiting",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara vomitando",
      "face vomiting",
      "cara de vómitos"
    ],
    "keywordsStr": "apariencia cara countenance de enferma expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mug rosto rostro semblante unwell visage vomitando vomiting vómitos",
    "imgUrl": "assets/emojis/1f92e.svg"
  },
  {
    "codes": "1f927",
    "char": "🤧",
    "name": "Sneezing Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara espirrando",
      "sneezing face",
      "cara de estornudo"
    ],
    "keywordsStr": "apariencia cara countenance de enferma espirrando estornudo expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mug rosto rostro semblante sneezing unwell visage",
    "imgUrl": "assets/emojis/1f927.svg"
  },
  {
    "codes": "1f975",
    "char": "🥵",
    "name": "Hot Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara quente",
      "hot face",
      "cara caliente"
    ],
    "keywordsStr": "apariencia caliente cara countenance enferma expresión expression expressão face faz features fisionomia fisonomía hot indisposto look mal malestar mug quente rosto rostro semblante unwell visage",
    "imgUrl": "assets/emojis/1f975.svg"
  },
  {
    "codes": "1f976",
    "char": "🥶",
    "name": "Cold Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara fria",
      "cold face",
      "cara fría"
    ],
    "keywordsStr": "apariencia cara cold countenance enferma expresión expression expressão face faz features fisionomia fisonomía fria fría indisposto look mal malestar mug rosto rostro semblante unwell visage",
    "imgUrl": "assets/emojis/1f976.svg"
  },
  {
    "codes": "1f974",
    "char": "🥴",
    "name": "Woozy Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara tonta",
      "woozy face",
      "cara mareada"
    ],
    "keywordsStr": "apariencia cara countenance enferma expresión expression expressão face faz features fisionomia fisonomía indisposto look mal malestar mareada mug rosto rostro semblante tonta unwell visage woozy",
    "imgUrl": "assets/emojis/1f974.svg"
  },
  {
    "codes": "1f635",
    "char": "😵",
    "name": "Knocked Out Face",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cara de nocaute",
      "knocked-out face",
      "cara noqueada"
    ],
    "keywordsStr": "apariencia cara countenance de enferma expresión expression expressão face faz features fisionomia fisonomía indisposto knocked-out look mal malestar mug nocaute noqueada rosto rostro semblante unwell visage",
    "imgUrl": "assets/emojis/1f635.svg"
  },
  {
    "codes": "1f92f",
    "char": "🤯",
    "name": "Exploding Head",
    "category": "Smileys & Emotion (face-unwell)",
    "group": "smileysAndEmotion",
    "subgroup": "face-unwell",
    "keywords": [
      "cara",
      "face",
      "indisposto",
      "unwell",
      "malestar",
      "cara mal",
      "face unwell",
      "cara enferma",
      "cabeça explosiva",
      "exploding head",
      "cabeza explosiva"
    ],
    "keywordsStr": "apariencia cabeza cabeça cara countenance enferma exploding explosiva expresión expression expressão face faz features fisionomia fisonomía head indisposto look mal malestar mug rosto rostro semblante unwell visage",
    "imgUrl": "assets/emojis/1f92f.svg"
  },
  {
    "codes": "1f920",
    "char": "🤠",
    "name": "Cowboy Hat Face",
    "category": "Smileys & Emotion (face-hat)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hat",
    "keywords": [
      "cara",
      "face",
      "chapéu",
      "hat",
      "sombrero",
      "chapéu de rosto",
      "face hat",
      "sombrero de cara",
      "cara de chapéu de cowboy",
      "cowboy hat face",
      "cara de sombrero de vaquero"
    ],
    "keywordsStr": "apariencia cara chapéu countenance cowboy de expresión expression expressão face faz features fisionomia fisonomía hat look mug rosto rostro semblante sombrero vaquero visage",
    "imgUrl": "assets/emojis/1f920.svg"
  },
  {
    "codes": "1f973",
    "char": "🥳",
    "name": "Partying Face",
    "category": "Smileys & Emotion (face-hat)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hat",
    "keywords": [
      "cara",
      "face",
      "chapéu",
      "hat",
      "sombrero",
      "chapéu de rosto",
      "face hat",
      "sombrero de cara",
      "cara de festa",
      "partying face",
      "cara de fiesta"
    ],
    "keywordsStr": "alegre apariencia cara celebrando celebrating chapéu comemorando countenance de expresión expression expressão face faz features festa festejando fiesta fisionomia fisonomía hat joyous look mug partying rosto rostro semblante sombrero visage",
    "imgUrl": "assets/emojis/1f973.svg"
  },
  {
    "codes": "1f978",
    "char": "🥸",
    "name": "Disguised Face",
    "category": "Smileys & Emotion (face-hat)",
    "group": "smileysAndEmotion",
    "subgroup": "face-hat",
    "keywords": [
      "cara",
      "face",
      "chapéu",
      "hat",
      "sombrero",
      "chapéu de rosto",
      "face hat",
      "sombrero de cara",
      "cara disfarçada",
      "disguised face",
      "rostro disfrazado"
    ],
    "keywordsStr": "apariencia cara chapéu countenance de disfarçada disfrazado disguised expresión expression expressão face faz features fisionomia fisonomía hat look mug rosto rostro semblante sombrero visage",
    "imgUrl": "assets/emojis/1f978.svg"
  },
  {
    "codes": "1f60e",
    "char": "😎",
    "name": "Smiling Face With Sunglasses",
    "category": "Smileys & Emotion (face-glasses)",
    "group": "smileysAndEmotion",
    "subgroup": "face-glasses",
    "keywords": [
      "cara",
      "face",
      "óculos",
      "glasses",
      "gafas",
      "óculos de rosto",
      "face glasses",
      "anteojos para la cara",
      "rosto sorridente com óculos de sol",
      "smiling face with sunglasses",
      "cara sonriente con gafas de sol"
    ],
    "keywordsStr": "anteojos apariencia cara com con confiante confident cool countenance de estiloso expresión expression expressão face faz features fisionomia fisonomía gafas glasses la look mug para rosto rostro seguro semblante smiling sol sonriente sorridente stylish sunglasses visage with óculos",
    "imgUrl": "assets/emojis/1f60e.svg"
  },
  {
    "codes": "1f913",
    "char": "🤓",
    "name": "Nerd Face",
    "category": "Smileys & Emotion (face-glasses)",
    "group": "smileysAndEmotion",
    "subgroup": "face-glasses",
    "keywords": [
      "cara",
      "face",
      "óculos",
      "glasses",
      "gafas",
      "óculos de rosto",
      "face glasses",
      "anteojos para la cara",
      "cara de nerd",
      "nerd face"
    ],
    "keywordsStr": "anteojos apariencia cara countenance de estudioso expresión expression expressão face faz features fisionomia fisonomía gafas geek glasses intelectual intellectual la look mug nerd para rosto rostro semblante studious visage óculos",
    "imgUrl": "assets/emojis/1f913.svg"
  },
  {
    "codes": "1f9d0",
    "char": "🧐",
    "name": "Face With Monocle",
    "category": "Smileys & Emotion (face-glasses)",
    "group": "smileysAndEmotion",
    "subgroup": "face-glasses",
    "keywords": [
      "cara",
      "face",
      "óculos",
      "glasses",
      "gafas",
      "óculos de rosto",
      "face glasses",
      "anteojos para la cara",
      "rosto com monóculo",
      "face with monocle",
      "cara con monóculo"
    ],
    "keywordsStr": "anteojos apariencia cara com con countenance de expresión expression expressão face faz features fisionomia fisonomía gafas glasses la look monocle monóculo mug para rosto rostro semblante visage with óculos",
    "imgUrl": "assets/emojis/1f9d0.svg"
  },
  {
    "codes": "1f615",
    "char": "😕",
    "name": "Confused Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara confusa",
      "confused face",
      "cara confundida"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned confundida confusa confused countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f615.svg"
  },
  {
    "codes": "1f61f",
    "char": "😟",
    "name": "Worried Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "worried face"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f61f.svg"
  },
  {
    "codes": "1f641",
    "char": "🙁",
    "name": "Slightly Frowning Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto levemente carrancudo",
      "slightly frowning face",
      "cara ligeramente fruncida"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara carrancudo concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía frowning fruncida inquieto intrigado levemente ligeramente look mug preocupada preocupado receoso rosto rostro semblante slightly tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f641.svg"
  },
  {
    "codes": "2639",
    "char": "☹️",
    "name": "Frowning Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara carrancuda",
      "frowning face",
      "cara con el ceño fruncido"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara carrancuda ceño con concerned countenance desasosegado desconfortável distressed el expresión expression expressão face faz features fisionomia fisonomía frowning fruncido inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/2639.svg"
  },
  {
    "codes": "1f62e",
    "char": "😮",
    "name": "Face With Open Mouth",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto de boca aberta",
      "face with open mouth",
      "cara con la boca abierta"
    ],
    "keywordsStr": "aberta abierta alarmado angustiado ansioso anxious apariencia apprehensive boca cara con concerned countenance de desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado la look mouth mug open preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage with worried",
    "imgUrl": "assets/emojis/1f62e.svg"
  },
  {
    "codes": "1f62f",
    "char": "😯",
    "name": "Hushed Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara calada",
      "hushed face",
      "cara callada"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive calada callada cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía hushed inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f62f.svg"
  },
  {
    "codes": "1f632",
    "char": "😲",
    "name": "Astonished Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de espanto",
      "astonished face",
      "cara de asombro"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive asombro astonished cara concerned countenance de desasosegado desconfortável distressed espanto expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f632.svg"
  },
  {
    "codes": "1f633",
    "char": "😳",
    "name": "Flushed Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara corada",
      "flushed face",
      "cara sonrojada"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned corada countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía flushed inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante sonrojada tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f633.svg"
  },
  {
    "codes": "1f97a",
    "char": "🥺",
    "name": "Pleading Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto suplicante",
      "pleading face",
      "cara suplicante"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug pleading preocupada preocupado receoso rosto rostro semblante suplicante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f97a.svg"
  },
  {
    "codes": "1f626",
    "char": "😦",
    "name": "Frowning Face With Open Mouth",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara carrancuda com a boca aberta",
      "frowning face with open mouth",
      "cara fruncida con la boca abierta"
    ],
    "keywordsStr": "a aberta abierta alarmado angustiado ansioso anxious apariencia apprehensive boca cara carrancuda com con concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía frowning fruncida inquieto intrigado la look mouth mug open preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage with worried",
    "imgUrl": "assets/emojis/1f626.svg"
  },
  {
    "codes": "1f627",
    "char": "😧",
    "name": "Anguished Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto angustiado",
      "anguished face",
      "cara angustiada"
    ],
    "keywordsStr": "alarmado anguished angustiada angustiado ansioso anxious apariencia apprehensive cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f627.svg"
  },
  {
    "codes": "1f628",
    "char": "😨",
    "name": "Fearful Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de medo",
      "fearful face",
      "cara temerosa"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance de desasosegado desconfortável distressed expresión expression expressão face faz fearful features fisionomia fisonomía inquieto intrigado look medo mug preocupada preocupado receoso rosto rostro semblante temerosa tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f628.svg"
  },
  {
    "codes": "1f630",
    "char": "😰",
    "name": "Anxious Face With Sweat",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto ansioso com suor",
      "anxious face with sweat",
      "cara ansiosa con sudor"
    ],
    "keywordsStr": "alarmado angustiado ansiosa ansioso anxious apariencia apprehensive cara com con concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante sudor suor sweat tenso troubled uneasy visage with worried",
    "imgUrl": "assets/emojis/1f630.svg"
  },
  {
    "codes": "1f625",
    "char": "😥",
    "name": "Sad But Relieved Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto triste, mas aliviado",
      "sad but relieved face",
      "cara triste pero aliviada"
    ],
    "keywordsStr": "alarmado aliviada aliviado angustiado ansioso anxious apariencia apprehensive but cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mas mug pero preocupada preocupado receoso relieved rosto rostro sad semblante tenso triste triste, troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f625.svg"
  },
  {
    "codes": "1f622",
    "char": "😢",
    "name": "Crying Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de choro",
      "crying face",
      "cara de llanto"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara choro concerned countenance crying de desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado llanto look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f622.svg"
  },
  {
    "codes": "1f62d",
    "char": "😭",
    "name": "Loudly Crying Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de choro alto",
      "loudly crying face",
      "cara de llanto en voz alta"
    ],
    "keywordsStr": "alarmado alta alto angustiado ansioso anxious apariencia apprehensive cara choro concerned countenance crying de desasosegado desconfortável distressed en expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado llanto look loudly mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage voz worried",
    "imgUrl": "assets/emojis/1f62d.svg"
  },
  {
    "codes": "1f631",
    "char": "😱",
    "name": "Face Screaming In Fear",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara gritando de medo",
      "face screaming in fear",
      "cara gritando de miedo"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance de desasosegado desconfortável distressed expresión expression expressão face faz fear features fisionomia fisonomía gritando in inquieto intrigado look medo miedo mug preocupada preocupado receoso rosto rostro screaming semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f631.svg"
  },
  {
    "codes": "1f616",
    "char": "😖",
    "name": "Confounded Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara confusa",
      "confounded face",
      "cara confundida"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned confounded confundida confusa countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f616.svg"
  },
  {
    "codes": "1f623",
    "char": "😣",
    "name": "Persevering Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "rosto perseverante",
      "persevering face",
      "rostro perseverante"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug perseverante persevering preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f623.svg"
  },
  {
    "codes": "1f61e",
    "char": "😞",
    "name": "Disappointed Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de decepção",
      "disappointed face",
      "cara decepcionada"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cara concerned countenance de decepcionada decepção desasosegado desconfortável disappointed distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f61e.svg"
  },
  {
    "codes": "1f613",
    "char": "😓",
    "name": "Downcast Face With Sweat",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara abatida com suor",
      "downcast face with sweat",
      "cara abatida por el sudor"
    ],
    "keywordsStr": "abatida alarmado angustiado ansioso anxious apariencia apprehensive cara com concerned countenance desasosegado desconfortável distressed downcast el expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug por preocupada preocupado receoso rosto rostro semblante sudor suor sweat tenso troubled uneasy visage with worried",
    "imgUrl": "assets/emojis/1f613.svg"
  },
  {
    "codes": "1f629",
    "char": "😩",
    "name": "Weary Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara cansada",
      "weary face"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cansada cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage weary worried",
    "imgUrl": "assets/emojis/1f629.svg"
  },
  {
    "codes": "1f62b",
    "char": "😫",
    "name": "Tired Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara cansada",
      "tired face"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive cansada cara concerned countenance desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso tired troubled uneasy visage worried",
    "imgUrl": "assets/emojis/1f62b.svg"
  },
  {
    "codes": "1f971",
    "char": "🥱",
    "name": "Yawning Face",
    "category": "Smileys & Emotion (face-concerned)",
    "group": "smileysAndEmotion",
    "subgroup": "face-concerned",
    "keywords": [
      "cara",
      "face",
      "preocupado",
      "concerned",
      "rosto preocupado",
      "face concerned",
      "cara preocupada",
      "cara de bocejo",
      "yawning face",
      "cara de bostezo"
    ],
    "keywordsStr": "alarmado angustiado ansioso anxious apariencia apprehensive bocejo bostezo cara concerned countenance de desasosegado desconfortável distressed expresión expression expressão face faz features fisionomia fisonomía inquieto intrigado look mug preocupada preocupado receoso rosto rostro semblante tenso troubled uneasy visage worried yawning",
    "imgUrl": "assets/emojis/1f971.svg"
  },
  {
    "codes": "1f624",
    "char": "😤",
    "name": "Face With Steam From Nose",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "rosto com vapor do nariz",
      "face with steam from nose",
      "cara con vapor de la nariz"
    ],
    "keywordsStr": "apariencia cara com con countenance de do expresión expression expressão face faz features fisionomia fisonomía from la look mug nariz negativa negative negativo nose rosto rostro semblante steam vapor visage with",
    "imgUrl": "assets/emojis/1f624.svg"
  },
  {
    "codes": "1f621",
    "char": "😡",
    "name": "Pouting Face",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "cara de bico",
      "pouting face",
      "cara de puchero"
    ],
    "keywordsStr": "apariencia bico cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mug negativa negative negativo pouting puchero rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f621.svg"
  },
  {
    "codes": "1f620",
    "char": "😠",
    "name": "Angry Face",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "cara de raiva",
      "angry face",
      "cara de enojo"
    ],
    "keywordsStr": "angry apariencia cara countenance de enojo expresión expression expressão face faz features fisionomia fisonomía look mug negativa negative negativo raiva rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f620.svg"
  },
  {
    "codes": "1f92c",
    "char": "🤬",
    "name": "Face With Symbols On Mouth",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "rosto com símbolos na boca",
      "face with symbols on mouth",
      "cara con símbolos en la boca"
    ],
    "keywordsStr": "apariencia boca cara com con countenance en expresión expression expressão face faz features fisionomia fisonomía la look mouth mug na negativa negative negativo on rosto rostro semblante symbols símbolos visage with",
    "imgUrl": "assets/emojis/1f92c.svg"
  },
  {
    "codes": "1f608",
    "char": "😈",
    "name": "Smiling Face With Horns",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "rosto sorridente com chifres",
      "smiling face with horns",
      "cara sonriente con cuernos"
    ],
    "keywordsStr": "apariencia cara chifres com con countenance cuernos expresión expression expressão face faz features fisionomia fisonomía horns look mug negativa negative negativo rosto rostro semblante smiling sonriente sorridente visage with",
    "imgUrl": "assets/emojis/1f608.svg"
  },
  {
    "codes": "1f47f",
    "char": "👿",
    "name": "Angry Face With Horns",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "cara de raiva com chifres",
      "angry face with horns",
      "cara enfadada con cuernos"
    ],
    "keywordsStr": "angry apariencia cara chifres com con countenance cuernos de enfadada expresión expression expressão face faz features fisionomia fisonomía horns look mug negativa negative negativo raiva rosto rostro semblante visage with",
    "imgUrl": "assets/emojis/1f47f.svg"
  },
  {
    "codes": "1f480",
    "char": "💀",
    "name": "Skull",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "crânio",
      "skull",
      "calavera"
    ],
    "keywordsStr": "apariencia calavera cara countenance crânio expresión expression expressão face faz features fisionomia fisonomía look mug negativa negative negativo rosto rostro semblante skull visage",
    "imgUrl": "assets/emojis/1f480.svg"
  },
  {
    "codes": "2620",
    "char": "☠️",
    "name": "Skull And Crossbones",
    "category": "Smileys & Emotion (face-negative)",
    "group": "smileysAndEmotion",
    "subgroup": "face-negative",
    "keywords": [
      "cara",
      "face",
      "negativo",
      "negative",
      "cara negativa",
      "face negative",
      "caveira e ossos cruzados",
      "skull and crossbones",
      "calavera y tibias cruzadas"
    ],
    "keywordsStr": "and apariencia calavera cara caveira countenance crossbones cruzadas cruzados e expresión expression expressão face faz features fisionomia fisonomía look mug negativa negative negativo ossos rosto rostro semblante skull tibias visage y",
    "imgUrl": "assets/emojis/2620.svg"
  },
  {
    "codes": "1f4a9",
    "char": "💩",
    "name": "Pile Of Poo",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "pilha de cocô",
      "pile of poo",
      "pila de caca"
    ],
    "keywordsStr": "apariencia caca cara cocô costume countenance de disfraz expresión expression expressão face faz features fisionomia fisonomía look mug of pila pile pilha poo rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f4a9.svg"
  },
  {
    "codes": "1f921",
    "char": "🤡",
    "name": "Clown Face",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "cara de palhaço",
      "clown face",
      "cara de payaso"
    ],
    "keywordsStr": "apariencia cara clown costume countenance de disfraz expresión expression expressão face faz features fisionomia fisonomía look mug palhaço payaso rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f921.svg"
  },
  {
    "codes": "1f479",
    "char": "👹",
    "name": "Ogre",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "ogro",
      "ogre"
    ],
    "keywordsStr": "apariencia cara costume countenance de disfraz expresión expression expressão face faz features fisionomia fisonomía look mug ogre ogro rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f479.svg"
  },
  {
    "codes": "1f47a",
    "char": "👺",
    "name": "Goblin",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "goblin",
      "duende"
    ],
    "keywordsStr": "apariencia cara costume countenance de disfraz duende expresión expression expressão face faz features fisionomia fisonomía goblin look mug rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f47a.svg"
  },
  {
    "codes": "1f47b",
    "char": "👻",
    "name": "Ghost",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "fantasma",
      "ghost"
    ],
    "keywordsStr": "apariencia cara costume countenance de disfraz expresión expression expressão face fantasma faz features fisionomia fisonomía ghost look mug rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f47b.svg"
  },
  {
    "codes": "1f47d",
    "char": "👽",
    "name": "Alien",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "alienígena",
      "alien",
      "extranjero"
    ],
    "keywordsStr": "alien alienígena apariencia cara costume countenance de disfraz expresión expression expressão extranjero face faz features fisionomia fisonomía look mug rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f47d.svg"
  },
  {
    "codes": "1f47e",
    "char": "👾",
    "name": "Alien Monster",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "monstro alienígena",
      "alien monster",
      "monstruo alienígena"
    ],
    "keywordsStr": "alien alienígena apariencia cara costume countenance de disfraz expresión expression expressão face faz features fisionomia fisonomía look monster monstro monstruo mug rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f47e.svg"
  },
  {
    "codes": "1f916",
    "char": "🤖",
    "name": "Robot",
    "category": "Smileys & Emotion (face-costume)",
    "group": "smileysAndEmotion",
    "subgroup": "face-costume",
    "keywords": [
      "cara",
      "face",
      "traje",
      "costume",
      "disfraz",
      "traje de rosto",
      "face costume",
      "disfraz de cara",
      "robô",
      "robot"
    ],
    "keywordsStr": "apariencia cara costume countenance de disfraz expresión expression expressão face faz features fisionomia fisonomía look mug robot robô rosto rostro semblante traje visage",
    "imgUrl": "assets/emojis/1f916.svg"
  },
  {
    "codes": "1f63a",
    "char": "😺",
    "name": "Grinning Cat",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato sorridente",
      "grinning cat",
      "gato sonriente"
    ],
    "keywordsStr": "apariencia cara cat countenance de expresión expression expressão face faz features fisionomia fisonomía gato grinning look mug rosto rostro semblante sonriente sorridente visage",
    "imgUrl": "assets/emojis/1f63a.svg"
  },
  {
    "codes": "1f638",
    "char": "😸",
    "name": "Grinning Cat With Smiling Eyes",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato sorridente com olhos sorridentes",
      "grinning cat with smiling eyes",
      "gato sonriente con ojos sonrientes"
    ],
    "keywordsStr": "apariencia cara cat com con countenance de expresión expression expressão eyes face faz features fisionomia fisonomía gato grinning look mug ojos olhos rosto rostro semblante smiling sonriente sonrientes sorridente sorridentes visage with",
    "imgUrl": "assets/emojis/1f638.svg"
  },
  {
    "codes": "1f639",
    "char": "😹",
    "name": "Cat With Tears Of Joy",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato com lágrimas de alegria",
      "cat with tears of joy",
      "gato con lágrimas de alegría"
    ],
    "keywordsStr": "alegria alegría apariencia cara cat com con countenance de expresión expression expressão face faz features fisionomia fisonomía gato joy look lágrimas mug of rosto rostro semblante tears visage with",
    "imgUrl": "assets/emojis/1f639.svg"
  },
  {
    "codes": "1f63b",
    "char": "😻",
    "name": "Smiling Cat With Heart Eyes",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato sorridente com olhos de coração",
      "smiling cat with heart-eyes",
      "gato sonriente con ojos de corazón"
    ],
    "keywordsStr": "apariencia cara cat com con corazón coração countenance de expresión expression expressão face faz features fisionomia fisonomía gato heart-eyes look mug ojos olhos rosto rostro semblante smiling sonriente sorridente visage with",
    "imgUrl": "assets/emojis/1f63b.svg"
  },
  {
    "codes": "1f63c",
    "char": "😼",
    "name": "Cat With Wry Smile",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato com sorriso irônico",
      "cat with wry smile",
      "gato con sonrisa irónica"
    ],
    "keywordsStr": "apariencia cara cat com con countenance de expresión expression expressão face faz features fisionomia fisonomía gato irónica irônico look mug rosto rostro semblante smile sonrisa sorriso visage with wry",
    "imgUrl": "assets/emojis/1f63c.svg"
  },
  {
    "codes": "1f63d",
    "char": "😽",
    "name": "Kissing Cat",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato beijando",
      "kissing cat",
      "gato besando"
    ],
    "keywordsStr": "apariencia beijando besando cara cat countenance de expresión expression expressão face faz features fisionomia fisonomía gato kissing look mug rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f63d.svg"
  },
  {
    "codes": "1f640",
    "char": "🙀",
    "name": "Weary Cat",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato cansado",
      "weary cat"
    ],
    "keywordsStr": "apariencia cansado cara cat countenance de expresión expression expressão face faz features fisionomia fisonomía gato look mug rosto rostro semblante visage weary",
    "imgUrl": "assets/emojis/1f640.svg"
  },
  {
    "codes": "1f63f",
    "char": "😿",
    "name": "Crying Cat",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato chorando",
      "crying cat",
      "gato llorando"
    ],
    "keywordsStr": "apariencia bawling cara cat chorando countenance crying de desabafo desconsolado emoção expresión expression expressão face faz features fisionomia fisonomía gato gimiendo lagrimeando lamentando llorando look lágrimas mug rosto rostro semblante sobbing sollozando teary triste tristeza visage wailing weeping",
    "imgUrl": "assets/emojis/1f63f.svg"
  },
  {
    "codes": "1f63e",
    "char": "😾",
    "name": "Pouting Cat",
    "category": "Smileys & Emotion (cat-face)",
    "group": "smileysAndEmotion",
    "subgroup": "cat-face",
    "keywords": [
      "gato",
      "cat",
      "cara",
      "face",
      "cara de gato",
      "cat face",
      "gato fazendo beicinho",
      "pouting cat",
      "gato haciendo pucheros"
    ],
    "keywordsStr": "apariencia beicinho cara cat countenance de expresión expression expressão face faz fazendo features fisionomia fisonomía gato haciendo look mug pouting pucheros rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f63e.svg"
  },
  {
    "codes": "1f648",
    "char": "🙈",
    "name": "See No Evil Monkey",
    "category": "Smileys & Emotion (monkey-face)",
    "group": "smileysAndEmotion",
    "subgroup": "monkey-face",
    "keywords": [
      "macaco",
      "monkey",
      "mono",
      "cara",
      "face",
      "cara de macaco",
      "monkey face",
      "cara de mono",
      "macaco que não vê o mal",
      "see-no-evil monkey",
      "mono que no ve el mal"
    ],
    "keywordsStr": "apariencia cara countenance de el expresión expression expressão face faz features fisionomia fisonomía look macaco mal monkey mono mug no não o que rosto rostro see-no-evil semblante ve visage vê",
    "imgUrl": "assets/emojis/1f648.svg"
  },
  {
    "codes": "1f649",
    "char": "🙉",
    "name": "Hear No Evil Monkey",
    "category": "Smileys & Emotion (monkey-face)",
    "group": "smileysAndEmotion",
    "subgroup": "monkey-face",
    "keywords": [
      "macaco",
      "monkey",
      "mono",
      "cara",
      "face",
      "cara de macaco",
      "monkey face",
      "cara de mono",
      "macaco não ouve mal",
      "hear-no-evil monkey",
      "Mono que no escucha el mal"
    ],
    "keywordsStr": "Mono apariencia cara countenance de el escucha expresión expression expressão face faz features fisionomia fisonomía hear-no-evil look macaco mal monkey mono mug no não ouve que rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f649.svg"
  },
  {
    "codes": "1f64a",
    "char": "🙊",
    "name": "Speak No Evil Monkey",
    "category": "Smileys & Emotion (monkey-face)",
    "group": "smileysAndEmotion",
    "subgroup": "monkey-face",
    "keywords": [
      "macaco",
      "monkey",
      "mono",
      "cara",
      "face",
      "cara de macaco",
      "monkey face",
      "cara de mono",
      "macaco não fala mal",
      "speak-no-evil monkey",
      "mono que no hable mal"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face fala faz features fisionomia fisonomía hable look macaco mal monkey mono mug no não que rosto rostro semblante speak-no-evil visage",
    "imgUrl": "assets/emojis/1f64a.svg"
  },
  {
    "codes": "1f48b",
    "char": "💋",
    "name": "Kiss Mark",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "marca de beijo",
      "kiss mark",
      "marca de beso"
    ],
    "keywordsStr": "beijo beso de emoción emotion emoção kiss marca mark",
    "imgUrl": "assets/emojis/1f48b.svg"
  },
  {
    "codes": "1f48c",
    "char": "💌",
    "name": "Love Letter",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "carta de amor",
      "love letter"
    ],
    "keywordsStr": "amor carta de emoción emotion emoção letter love",
    "imgUrl": "assets/emojis/1f48c.svg"
  },
  {
    "codes": "1f498",
    "char": "💘",
    "name": "Heart With Arrow",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração com flecha",
      "heart with arrow",
      "corazón con flecha"
    ],
    "keywordsStr": "arrow com con corazón coração emoción emotion emoção flecha heart with",
    "imgUrl": "assets/emojis/1f498.svg"
  },
  {
    "codes": "1f49d",
    "char": "💝",
    "name": "Heart With Ribbon",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração com fita",
      "heart with ribbon",
      "corazón con cinta"
    ],
    "keywordsStr": "cinta com con corazón coração emoción emotion emoção fita heart ribbon with",
    "imgUrl": "assets/emojis/1f49d.svg"
  },
  {
    "codes": "1f496",
    "char": "💖",
    "name": "Sparkling Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração brilhante",
      "sparkling heart",
      "corazón chispeante"
    ],
    "keywordsStr": "brilhante chispeante corazón coração emoción emotion emoção heart sparkling",
    "imgUrl": "assets/emojis/1f496.svg"
  },
  {
    "codes": "1f497",
    "char": "💗",
    "name": "Growing Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração em crescimento",
      "growing heart",
      "corazón en crecimiento"
    ],
    "keywordsStr": "corazón coração crecimiento crescimento em emoción emotion emoção en growing heart",
    "imgUrl": "assets/emojis/1f497.svg"
  },
  {
    "codes": "1f493",
    "char": "💓",
    "name": "Beating Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração batendo",
      "beating heart",
      "corazón latiendo"
    ],
    "keywordsStr": "batendo beating corazón coração emoción emotion emoção heart latiendo",
    "imgUrl": "assets/emojis/1f493.svg"
  },
  {
    "codes": "1f49e",
    "char": "💞",
    "name": "Revolving Hearts",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "corações girando",
      "revolving hearts",
      "corazones giratorios"
    ],
    "keywordsStr": "corazones corações emoción emotion emoção girando giratorios hearts revolving",
    "imgUrl": "assets/emojis/1f49e.svg"
  },
  {
    "codes": "1f495",
    "char": "💕",
    "name": "Two Hearts",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "dois corações",
      "two hearts",
      "dos corazones"
    ],
    "keywordsStr": "corazones corações dois dos emoción emotion emoção hearts two",
    "imgUrl": "assets/emojis/1f495.svg"
  },
  {
    "codes": "1f49f",
    "char": "💟",
    "name": "Heart Decoration",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "decoração coração",
      "heart decoration",
      "decoración del corazón"
    ],
    "keywordsStr": "corazón coração decoración decoration decoração del emoción emotion emoção heart",
    "imgUrl": "assets/emojis/1f49f.svg"
  },
  {
    "codes": "2763",
    "char": "❣️",
    "name": "Heart Exclamation",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "exclamação do coração",
      "heart exclamation",
      "exclamación del corazón"
    ],
    "keywordsStr": "corazón coração del do emoción emotion emoção exclamación exclamation exclamação heart",
    "imgUrl": "assets/emojis/2763.svg"
  },
  {
    "codes": "1f494",
    "char": "💔",
    "name": "Broken Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração partido",
      "broken heart",
      "corazón roto"
    ],
    "keywordsStr": "broken corazón coração emoción emotion emoção heart partido roto",
    "imgUrl": "assets/emojis/1f494.svg"
  },
  {
    "codes": "2764-fe0f-200d-1f525",
    "char": "❤️‍🔥",
    "name": "Heart On Fire",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração em chamas",
      "heart on fire",
      "corazón en llamas"
    ],
    "keywordsStr": "chamas corazón coração em emoción emotion emoção en fire heart llamas on",
    "imgUrl": "assets/emojis/2764-fe0f-200d-1f525.svg"
  },
  {
    "codes": "2764-fe0f-200d-1fa79",
    "char": "❤️‍🩹",
    "name": "Mending Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração consertando",
      "mending heart",
      "reparando corazón"
    ],
    "keywordsStr": "consertando corazón coração emoción emotion emoção heart mending reparando",
    "imgUrl": "assets/emojis/2764-fe0f-200d-1fa79.svg"
  },
  {
    "codes": "2764",
    "char": "❤",
    "name": "Red Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração vermelho",
      "red heart",
      "corazón rojo"
    ],
    "keywordsStr": "corazón coração emoción emotion emoção heart red rojo vermelho",
    "imgUrl": "assets/emojis/2764.svg"
  },
  {
    "codes": "1f9e1",
    "char": "🧡",
    "name": "Orange Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração laranja",
      "orange heart",
      "corazón naranja"
    ],
    "keywordsStr": "corazón coração emoción emotion emoção heart laranja naranja orange",
    "imgUrl": "assets/emojis/1f9e1.svg"
  },
  {
    "codes": "1f49b",
    "char": "💛",
    "name": "Yellow Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração amarelo",
      "yellow heart",
      "corazón amarillo"
    ],
    "keywordsStr": "amarelo amarillo corazón coração emoción emotion emoção heart yellow",
    "imgUrl": "assets/emojis/1f49b.svg"
  },
  {
    "codes": "1f49a",
    "char": "💚",
    "name": "Green Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração verde",
      "green heart",
      "corazón verde"
    ],
    "keywordsStr": "corazón coração emoción emotion emoção green heart verde",
    "imgUrl": "assets/emojis/1f49a.svg"
  },
  {
    "codes": "1f499",
    "char": "💙",
    "name": "Blue Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração azul",
      "blue heart",
      "corazón azul"
    ],
    "keywordsStr": "azul blue corazón coração emoción emotion emoção heart",
    "imgUrl": "assets/emojis/1f499.svg"
  },
  {
    "codes": "1f49c",
    "char": "💜",
    "name": "Purple Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração roxo",
      "purple heart",
      "corazón púrpura"
    ],
    "keywordsStr": "corazón coração emoción emotion emoção heart purple púrpura roxo",
    "imgUrl": "assets/emojis/1f49c.svg"
  },
  {
    "codes": "1f90e",
    "char": "🤎",
    "name": "Brown Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração marrom",
      "brown heart",
      "corazón marrón"
    ],
    "keywordsStr": "brown corazón coração emoción emotion emoção heart marrom marrón",
    "imgUrl": "assets/emojis/1f90e.svg"
  },
  {
    "codes": "1f5a4",
    "char": "🖤",
    "name": "Black Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração negro",
      "black heart",
      "corazón negro"
    ],
    "keywordsStr": "black corazón coração emoción emotion emoção heart negro",
    "imgUrl": "assets/emojis/1f5a4.svg"
  },
  {
    "codes": "1f90d",
    "char": "🤍",
    "name": "White Heart",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "coração branco",
      "white heart",
      "corazón blanco"
    ],
    "keywordsStr": "blanco branco corazón coração emoción emotion emoção heart white",
    "imgUrl": "assets/emojis/1f90d.svg"
  },
  {
    "codes": "1f4af",
    "char": "💯",
    "name": "Hundred Points",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "cem pontos",
      "hundred points",
      "cien puntos"
    ],
    "keywordsStr": "cem cien emoción emotion emoção hundred points pontos puntos",
    "imgUrl": "assets/emojis/1f4af.svg"
  },
  {
    "codes": "1f4a2",
    "char": "💢",
    "name": "Anger Symbol",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "símbolo da raiva",
      "anger symbol",
      "símbolo de ira"
    ],
    "keywordsStr": "anger da de emoción emotion emoção ira raiva symbol símbolo",
    "imgUrl": "assets/emojis/1f4a2.svg"
  },
  {
    "codes": "1f4a5",
    "char": "💥",
    "name": "Collision",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "colisão",
      "collision",
      "colisión"
    ],
    "keywordsStr": "colisión colisão collision emoción emotion emoção",
    "imgUrl": "assets/emojis/1f4a5.svg"
  },
  {
    "codes": "1f4ab",
    "char": "💫",
    "name": "Dizzy",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "tonto",
      "dizzy",
      "mareado"
    ],
    "keywordsStr": "dizzy emoción emotion emoção mareado tonto",
    "imgUrl": "assets/emojis/1f4ab.svg"
  },
  {
    "codes": "1f4a6",
    "char": "💦",
    "name": "Sweat Droplets",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "gotas de suor",
      "sweat droplets",
      "gotas de sudor"
    ],
    "keywordsStr": "de droplets emoción emotion emoção gotas sudor suor sweat",
    "imgUrl": "assets/emojis/1f4a6.svg"
  },
  {
    "codes": "1f4a8",
    "char": "💨",
    "name": "Dashing Away",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "desaparecendo",
      "dashing away",
      "salir corriendo"
    ],
    "keywordsStr": "away corriendo dashing desaparecendo emoción emotion emoção salir",
    "imgUrl": "assets/emojis/1f4a8.svg"
  },
  {
    "codes": "1f573",
    "char": "🕳️",
    "name": "Hole",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "buraco",
      "hole",
      "agujero"
    ],
    "keywordsStr": "agujero buraco emoción emotion emoção hole",
    "imgUrl": "assets/emojis/1f573.svg"
  },
  {
    "codes": "1f4a3",
    "char": "💣",
    "name": "Bomb",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "bomba",
      "bomb"
    ],
    "keywordsStr": "bomb bomba emoción emotion emoção",
    "imgUrl": "assets/emojis/1f4a3.svg"
  },
  {
    "codes": "1f4ac",
    "char": "💬",
    "name": "Speech Balloon",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "balão de fala",
      "speech balloon",
      "globo de diálogo"
    ],
    "keywordsStr": "balloon balão de diálogo emoción emotion emoção fala globo speech",
    "imgUrl": "assets/emojis/1f4ac.svg"
  },
  {
    "codes": "1f5e8",
    "char": "🗨️",
    "name": "Left Speech Bubble",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "bolha de fala esquerda",
      "left speech bubble",
      "globo de diálogo izquierdo"
    ],
    "keywordsStr": "bolha bubble de diálogo emoción emotion emoção esquerda fala globo izquierdo left speech",
    "imgUrl": "assets/emojis/1f5e8.svg"
  },
  {
    "codes": "1f5ef",
    "char": "🗯️",
    "name": "Right Anger Bubble",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "bolha de raiva certa",
      "right anger bubble",
      "burbuja de ira correcta"
    ],
    "keywordsStr": "anger bolha bubble burbuja certa correcta de emoción emotion emoção ira raiva right",
    "imgUrl": "assets/emojis/1f5ef.svg"
  },
  {
    "codes": "1f4ad",
    "char": "💭",
    "name": "Thought Balloon",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "balão de pensamento",
      "thought balloon",
      "globo de pensamiento"
    ],
    "keywordsStr": "balloon balão de emoción emotion emoção globo pensamento pensamiento thought",
    "imgUrl": "assets/emojis/1f4ad.svg"
  },
  {
    "codes": "1f4a4",
    "char": "💤",
    "name": "Zzz",
    "category": "Smileys & Emotion (emotion)",
    "group": "smileysAndEmotion",
    "subgroup": "emotion",
    "keywords": [
      "emoção",
      "emotion",
      "emoción",
      "zzz"
    ],
    "keywordsStr": "emoción emotion emoção zzz",
    "imgUrl": "assets/emojis/1f4a4.svg"
  },
  {
    "codes": "1f44b",
    "char": "👋",
    "name": "Waving Hand",
    "category": "People & Body (hand-fingers-open)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-open",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "aberto",
      "open",
      "abierto",
      "dedos da mão abertos",
      "hand fingers open",
      "manos dedos abiertos",
      "acenando a mão",
      "waving hand",
      "agitando la mano"
    ],
    "keywordsStr": "a aberto abertos abierto abiertos acenando agitando da dedos fingers hand la mano manos mão open waving",
    "imgUrl": "assets/emojis/1f44b.svg"
  },
  {
    "codes": "1f91a",
    "char": "🤚",
    "name": "Raised Back Of Hand",
    "category": "People & Body (hand-fingers-open)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-open",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "aberto",
      "open",
      "abierto",
      "dedos da mão abertos",
      "hand fingers open",
      "manos dedos abiertos",
      "levantar as costas da mão",
      "raised back of hand",
      "dorso de la mano levantado"
    ],
    "keywordsStr": "aberto abertos abierto abiertos as back costas da de dedos dorso fingers hand la levantado levantar mano manos mão of open raised",
    "imgUrl": "assets/emojis/1f91a.svg"
  },
  {
    "codes": "1f590",
    "char": "🖐️",
    "name": "Hand With Fingers Splayed",
    "category": "People & Body (hand-fingers-open)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-open",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "aberto",
      "open",
      "abierto",
      "dedos da mão abertos",
      "hand fingers open",
      "manos dedos abiertos",
      "mão com os dedos abertos",
      "hand with fingers splayed",
      "mano con los dedos abiertos"
    ],
    "keywordsStr": "aberto abertos abierto abiertos com con da dedos fingers hand los mano manos mão open os splayed with",
    "imgUrl": "assets/emojis/1f590.svg"
  },
  {
    "codes": "270b",
    "char": "✋",
    "name": "Raised Hand",
    "category": "People & Body (hand-fingers-open)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-open",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "aberto",
      "open",
      "abierto",
      "dedos da mão abertos",
      "hand fingers open",
      "manos dedos abiertos",
      "mão levantada",
      "raised hand",
      "mano levantada"
    ],
    "keywordsStr": "aberto abertos abierto abiertos da dedos fingers hand levantada mano manos mão open raised",
    "imgUrl": "assets/emojis/270b.svg"
  },
  {
    "codes": "1f596",
    "char": "🖖",
    "name": "Vulcan Salute",
    "category": "People & Body (hand-fingers-open)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-open",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "aberto",
      "open",
      "abierto",
      "dedos da mão abertos",
      "hand fingers open",
      "manos dedos abiertos",
      "saudação vulcana",
      "vulcan salute",
      "saludo vulcano"
    ],
    "keywordsStr": "aberto abertos abierto abiertos da dedos fingers hand mano manos mão open saludo salute saudação vulcan vulcana vulcano",
    "imgUrl": "assets/emojis/1f596.svg"
  },
  {
    "codes": "1f44c",
    "char": "👌",
    "name": "Ok Hand",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "OK mão",
      "OK hand",
      "OK mano"
    ],
    "keywordsStr": "OK dedos fingers hand mano mão parciais parcial parciales partial",
    "imgUrl": "assets/emojis/1f44c.svg"
  },
  {
    "codes": "1f90c",
    "char": "🤌",
    "name": "Pinched Fingers",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "dedos apertados",
      "pinched fingers",
      "dedos pellizcados"
    ],
    "keywordsStr": "apertados dedos fingers hand mano mão parciais parcial parciales partial pellizcados pinched",
    "imgUrl": "assets/emojis/1f90c.svg"
  },
  {
    "codes": "1f90f",
    "char": "🤏",
    "name": "Pinching Hand",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "apertando a mão",
      "pinching hand",
      "mano pellizcando"
    ],
    "keywordsStr": "a apertando dedos fingers hand mano mão parciais parcial parciales partial pellizcando pinching",
    "imgUrl": "assets/emojis/1f90f.svg"
  },
  {
    "codes": "270c",
    "char": "✌️",
    "name": "Victory Hand",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "mão da vitória",
      "victory hand",
      "mano de la victoria"
    ],
    "keywordsStr": "da de dedos fingers hand la mano mão parciais parcial parciales partial victoria victory vitória",
    "imgUrl": "assets/emojis/270c.svg"
  },
  {
    "codes": "1f91e",
    "char": "🤞",
    "name": "Crossed Fingers",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "dedos cruzados",
      "crossed fingers"
    ],
    "keywordsStr": "crossed cruzados dedos fingers hand mano mão parciais parcial parciales partial",
    "imgUrl": "assets/emojis/1f91e.svg"
  },
  {
    "codes": "1f91f",
    "char": "🤟",
    "name": "Love You Gesture",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "gesto de amor",
      "love-you gesture"
    ],
    "keywordsStr": "amor de dedos fingers gesto gesture hand love-you mano mão parciais parcial parciales partial",
    "imgUrl": "assets/emojis/1f91f.svg"
  },
  {
    "codes": "1f918",
    "char": "🤘",
    "name": "Sign Of The Horns",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "sinal dos chifres",
      "sign of the horns",
      "signo de los cuernos"
    ],
    "keywordsStr": "chifres cuernos de dedos dos fingers hand horns los mano mão of parciais parcial parciales partial sign signo sinal the",
    "imgUrl": "assets/emojis/1f918.svg"
  },
  {
    "codes": "1f919",
    "char": "🤙",
    "name": "Call Me Hand",
    "category": "People & Body (hand-fingers-partial)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-partial",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "parcial",
      "partial",
      "mão dedos parciais",
      "hand fingers partial",
      "mano dedos parciales",
      "me chama mano",
      "call me hand",
      "llámame mano"
    ],
    "keywordsStr": "call chama dedos fingers hand llámame mano me mão parciais parcial parciales partial",
    "imgUrl": "assets/emojis/1f919.svg"
  },
  {
    "codes": "1f448",
    "char": "👈",
    "name": "Backhand Index Pointing Left",
    "category": "People & Body (hand-single-finger)",
    "group": "peopleAndBody",
    "subgroup": "hand-single-finger",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "único",
      "single",
      "soltero",
      "dedo",
      "finger",
      "mão única dedo",
      "hand single finger",
      "mano de un solo dedo",
      "índice de backhand apontando para a esquerda",
      "backhand index pointing left",
      "índice de revés apuntando a la izquierda"
    ],
    "keywordsStr": "a apontando apuntando backhand de dedo esquerda finger hand index izquierda la left mano mão para pointing revés single solo soltero un índice única único",
    "imgUrl": "assets/emojis/1f448.svg"
  },
  {
    "codes": "1f449",
    "char": "👉",
    "name": "Backhand Index Pointing Right",
    "category": "People & Body (hand-single-finger)",
    "group": "peopleAndBody",
    "subgroup": "hand-single-finger",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "único",
      "single",
      "soltero",
      "dedo",
      "finger",
      "mão única dedo",
      "hand single finger",
      "mano de un solo dedo",
      "índice de reverso apontando para a direita",
      "backhand index pointing right",
      "índice de revés apuntando a la derecha"
    ],
    "keywordsStr": "a apontando apuntando backhand de dedo derecha direita finger hand index la mano mão para pointing reverso revés right single solo soltero un índice única único",
    "imgUrl": "assets/emojis/1f449.svg"
  },
  {
    "codes": "1f446",
    "char": "👆",
    "name": "Backhand Index Pointing Up",
    "category": "People & Body (hand-single-finger)",
    "group": "peopleAndBody",
    "subgroup": "hand-single-finger",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "único",
      "single",
      "soltero",
      "dedo",
      "finger",
      "mão única dedo",
      "hand single finger",
      "mano de un solo dedo",
      "índice de backhand apontando para cima",
      "backhand index pointing up",
      "índice de revés apuntando hacia arriba"
    ],
    "keywordsStr": "apontando apuntando arriba backhand cima de dedo finger hacia hand index mano mão para pointing revés single solo soltero un up índice única único",
    "imgUrl": "assets/emojis/1f446.svg"
  },
  {
    "codes": "1f447",
    "char": "👇",
    "name": "Backhand Index Pointing Down",
    "category": "People & Body (hand-single-finger)",
    "group": "peopleAndBody",
    "subgroup": "hand-single-finger",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "único",
      "single",
      "soltero",
      "dedo",
      "finger",
      "mão única dedo",
      "hand single finger",
      "mano de un solo dedo",
      "índice de backhand apontando para baixo",
      "backhand index pointing down",
      "índice de revés apuntando hacia abajo"
    ],
    "keywordsStr": "abajo apontando apuntando backhand baixo de dedo down finger hacia hand index mano mão para pointing revés single solo soltero un índice única único",
    "imgUrl": "assets/emojis/1f447.svg"
  },
  {
    "codes": "261d",
    "char": "☝️",
    "name": "Index Pointing Up",
    "category": "People & Body (hand-single-finger)",
    "group": "peopleAndBody",
    "subgroup": "hand-single-finger",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "único",
      "single",
      "soltero",
      "dedo",
      "finger",
      "mão única dedo",
      "hand single finger",
      "mano de un solo dedo",
      "índice apontando para cima",
      "index pointing up",
      "índice apuntando hacia arriba"
    ],
    "keywordsStr": "apontando apuntando arriba cima de dedo finger hacia hand index mano mão para pointing single solo soltero un up índice única único",
    "imgUrl": "assets/emojis/261d.svg"
  },
  {
    "codes": "1f44d",
    "char": "👍",
    "name": "Thumbs Up",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "polegar para cima",
      "thumbs up",
      "pulgares arriba",
      "joia",
      "like"
    ],
    "keywordsStr": "arriba cerrado cerrados cima closed da de dedos fechado fechados fingers hand joia la like mano mão para polegar pulgares thumbs up",
    "imgUrl": "assets/emojis/1f44d.svg"
  },
  {
    "codes": "1f44e",
    "char": "👎",
    "name": "Thumbs Down",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "polegar para baixo",
      "thumbs down",
      "pulgar hacia abajo"
    ],
    "keywordsStr": "abajo baixo cerrado cerrados closed da de dedos down fechado fechados fingers hacia hand la mano mão para polegar pulgar thumbs",
    "imgUrl": "assets/emojis/1f44e.svg"
  },
  {
    "codes": "270a",
    "char": "✊",
    "name": "Raised Fist",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "punho levantado",
      "raised fist",
      "puño levantado"
    ],
    "keywordsStr": "cerrado cerrados closed da de dedos fechado fechados fingers fist hand la levantado mano mão punho puño raised",
    "imgUrl": "assets/emojis/270a.svg"
  },
  {
    "codes": "1f44a",
    "char": "👊",
    "name": "Oncoming Fist",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "punho que se aproxima",
      "oncoming fist",
      "puño que se aproxima"
    ],
    "keywordsStr": "aproxima cerrado cerrados closed da de dedos fechado fechados fingers fist hand la mano mão oncoming punho puño que se",
    "imgUrl": "assets/emojis/1f44a.svg"
  },
  {
    "codes": "1f91b",
    "char": "🤛",
    "name": "Left Facing Fist",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "punho virado para a esquerda",
      "left-facing fist",
      "puño hacia la izquierda"
    ],
    "keywordsStr": "a cerrado cerrados closed da de dedos esquerda fechado fechados fingers fist hacia hand izquierda la left-facing mano mão para punho puño virado",
    "imgUrl": "assets/emojis/1f91b.svg"
  },
  {
    "codes": "1f91c",
    "char": "🤜",
    "name": "Right Facing Fist",
    "category": "People & Body (hand-fingers-closed)",
    "group": "peopleAndBody",
    "subgroup": "hand-fingers-closed",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "dedos",
      "fingers",
      "fechado",
      "closed",
      "cerrado",
      "dedos da mão fechados",
      "hand fingers closed",
      "dedos de la mano cerrados",
      "punho virado para a direita",
      "right-facing fist",
      "puño hacia la derecha"
    ],
    "keywordsStr": "a cerrado cerrados closed da de dedos derecha direita fechado fechados fingers fist hacia hand la mano mão para punho puño right-facing virado",
    "imgUrl": "assets/emojis/1f91c.svg"
  },
  {
    "codes": "1f44f",
    "char": "👏",
    "name": "Clapping Hands",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "bater palmas",
      "clapping hands",
      "aplaudiendo"
    ],
    "keywordsStr": "aplaudiendo bater clapping hands manos mãos palmas",
    "imgUrl": "assets/emojis/1f44f.svg"
  },
  {
    "codes": "1f64c",
    "char": "🙌",
    "name": "Raising Hands",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "levantar as mãos",
      "raising hands",
      "levantar las manos"
    ],
    "keywordsStr": "as hands las levantar manos mãos raising",
    "imgUrl": "assets/emojis/1f64c.svg"
  },
  {
    "codes": "1f450",
    "char": "👐",
    "name": "Open Hands",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "mãos abertas",
      "open hands",
      "manos abiertas"
    ],
    "keywordsStr": "abertas abiertas hands manos mãos open",
    "imgUrl": "assets/emojis/1f450.svg"
  },
  {
    "codes": "1f932",
    "char": "🤲",
    "name": "Palms Up Together",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "palmas juntas",
      "palms up together"
    ],
    "keywordsStr": "hands juntas manos mãos palmas palms together up",
    "imgUrl": "assets/emojis/1f932.svg"
  },
  {
    "codes": "1f91d",
    "char": "🤝",
    "name": "Handshake",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "aperto de mão",
      "handshake",
      "apretón de manos"
    ],
    "keywordsStr": "aperto apretón de hands handshake manos mão mãos",
    "imgUrl": "assets/emojis/1f91d.svg"
  },
  {
    "codes": "1f64f",
    "char": "🙏",
    "name": "Folded Hands",
    "category": "People & Body (hands)",
    "group": "peopleAndBody",
    "subgroup": "hands",
    "keywords": [
      "mãos",
      "hands",
      "manos",
      "mãos cruzadas",
      "folded hands",
      "manos juntas"
    ],
    "keywordsStr": "cruzadas folded hands juntas manos mãos",
    "imgUrl": "assets/emojis/1f64f.svg"
  },
  {
    "codes": "270d",
    "char": "✍️",
    "name": "Writing Hand",
    "category": "People & Body (hand-prop)",
    "group": "peopleAndBody",
    "subgroup": "hand-prop",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "prop",
      "accesorio",
      "apoio de mão",
      "hand prop",
      "apoyo de mano",
      "mão de escrita",
      "writing hand",
      "mano escribiendo"
    ],
    "keywordsStr": "accesorio apoio apoyo de escribiendo escrita hand mano mão prop writing",
    "imgUrl": "assets/emojis/270d.svg"
  },
  {
    "codes": "1f485",
    "char": "💅",
    "name": "Nail Polish",
    "category": "People & Body (hand-prop)",
    "group": "peopleAndBody",
    "subgroup": "hand-prop",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "prop",
      "accesorio",
      "apoio de mão",
      "hand prop",
      "apoyo de mano",
      "esmalte",
      "nail polish",
      "esmalte de uñas"
    ],
    "keywordsStr": "accesorio apoio apoyo de esmalte hand mano mão nail polish prop uñas",
    "imgUrl": "assets/emojis/1f485.svg"
  },
  {
    "codes": "1f933",
    "char": "🤳",
    "name": "Selfie",
    "category": "People & Body (hand-prop)",
    "group": "peopleAndBody",
    "subgroup": "hand-prop",
    "keywords": [
      "mão",
      "hand",
      "mano",
      "prop",
      "accesorio",
      "apoio de mão",
      "hand prop",
      "apoyo de mano",
      "selfie",
      "autofoto"
    ],
    "keywordsStr": "accesorio apoio apoyo autofoto de hand mano mão prop selfie",
    "imgUrl": "assets/emojis/1f933.svg"
  },
  {
    "codes": "1f4aa",
    "char": "💪",
    "name": "Flexed Biceps",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "bíceps flexionado",
      "flexed biceps"
    ],
    "keywordsStr": "biceps body bíceps corpo cuerpo del do flexed flexionado partes parts",
    "imgUrl": "assets/emojis/1f4aa.svg"
  },
  {
    "codes": "1f9be",
    "char": "🦾",
    "name": "Mechanical Arm",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "braço mecânico",
      "mechanical arm",
      "brazo mecánico"
    ],
    "keywordsStr": "arm body brazo braço corpo cuerpo del do mechanical mecánico mecânico partes parts",
    "imgUrl": "assets/emojis/1f9be.svg"
  },
  {
    "codes": "1f9bf",
    "char": "🦿",
    "name": "Mechanical Leg",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "perna mecânica",
      "mechanical leg",
      "pata mecánica"
    ],
    "keywordsStr": "body corpo cuerpo del do leg mechanical mecánica mecânica partes parts pata perna",
    "imgUrl": "assets/emojis/1f9bf.svg"
  },
  {
    "codes": "1f9b5",
    "char": "🦵",
    "name": "Leg",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "perna",
      "leg",
      "pierna"
    ],
    "keywordsStr": "body corpo cuerpo del do leg partes parts perna pierna",
    "imgUrl": "assets/emojis/1f9b5.svg"
  },
  {
    "codes": "1f9b6",
    "char": "🦶",
    "name": "Foot",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "pé",
      "foot",
      "pie"
    ],
    "keywordsStr": "body corpo cuerpo del do foot partes parts pie pé",
    "imgUrl": "assets/emojis/1f9b6.svg"
  },
  {
    "codes": "1f442",
    "char": "👂",
    "name": "Ear",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "orelha",
      "ear",
      "oreja"
    ],
    "keywordsStr": "body corpo cuerpo del do ear oreja orelha partes parts",
    "imgUrl": "assets/emojis/1f442.svg"
  },
  {
    "codes": "1f9bb",
    "char": "🦻",
    "name": "Ear With Hearing Aid",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "ouvido com aparelho auditivo",
      "ear with hearing aid",
      "oído con audífono"
    ],
    "keywordsStr": "aid aparelho auditivo audífono body com con corpo cuerpo del do ear hearing ouvido oído partes parts with",
    "imgUrl": "assets/emojis/1f9bb.svg"
  },
  {
    "codes": "1f443",
    "char": "👃",
    "name": "Nose",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "nariz",
      "nose"
    ],
    "keywordsStr": "body corpo cuerpo del do nariz nose partes parts",
    "imgUrl": "assets/emojis/1f443.svg"
  },
  {
    "codes": "1f9e0",
    "char": "🧠",
    "name": "Brain",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "cérebro",
      "brain",
      "cerebro"
    ],
    "keywordsStr": "body brain cerebro corpo cuerpo cérebro del do partes parts",
    "imgUrl": "assets/emojis/1f9e0.svg"
  },
  {
    "codes": "1fac0",
    "char": "🫀",
    "name": "Anatomical Heart",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "coração anatômico",
      "anatomical heart",
      "corazón anatómico"
    ],
    "keywordsStr": "anatomical anatómico anatômico body corazón coração corpo cuerpo del do heart partes parts",
    "imgUrl": "assets/emojis/1fac0.svg"
  },
  {
    "codes": "1fac1",
    "char": "🫁",
    "name": "Lungs",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "pulmões",
      "lungs",
      "pulmones"
    ],
    "keywordsStr": "body corpo cuerpo del do lungs partes parts pulmones pulmões",
    "imgUrl": "assets/emojis/1fac1.svg"
  },
  {
    "codes": "1f9b7",
    "char": "🦷",
    "name": "Tooth",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "dente",
      "tooth",
      "diente"
    ],
    "keywordsStr": "body corpo cuerpo del dente diente do partes parts tooth",
    "imgUrl": "assets/emojis/1f9b7.svg"
  },
  {
    "codes": "1f9b4",
    "char": "🦴",
    "name": "Bone",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "osso",
      "bone",
      "hueso"
    ],
    "keywordsStr": "body bone corpo cuerpo del do hueso osso partes parts",
    "imgUrl": "assets/emojis/1f9b4.svg"
  },
  {
    "codes": "1f440",
    "char": "👀",
    "name": "Eyes",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "olhos",
      "eyes",
      "ojos"
    ],
    "keywordsStr": "body corpo cuerpo del do eyes ojos olhos partes parts",
    "imgUrl": "assets/emojis/1f440.svg"
  },
  {
    "codes": "1f441",
    "char": "👁️",
    "name": "Eye",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "olho",
      "eye",
      "ojo"
    ],
    "keywordsStr": "body corpo cuerpo del do eye ojo olho partes parts",
    "imgUrl": "assets/emojis/1f441.svg"
  },
  {
    "codes": "1f445",
    "char": "👅",
    "name": "Tongue",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "língua",
      "tongue",
      "lengua"
    ],
    "keywordsStr": "body corpo cuerpo del do lengua língua partes parts tongue",
    "imgUrl": "assets/emojis/1f445.svg"
  },
  {
    "codes": "1f444",
    "char": "👄",
    "name": "Mouth",
    "category": "People & Body (body-parts)",
    "group": "peopleAndBody",
    "subgroup": "body-parts",
    "keywords": [
      "corpo",
      "body",
      "cuerpo",
      "partes",
      "parts",
      "partes do corpo",
      "body parts",
      "partes del cuerpo",
      "boca",
      "mouth"
    ],
    "keywordsStr": "boca body corpo cuerpo del do mouth partes parts",
    "imgUrl": "assets/emojis/1f444.svg"
  },
  {
    "codes": "1f476",
    "char": "👶",
    "name": "Baby",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "bebê",
      "baby",
      "bebé"
    ],
    "keywordsStr": "baby bebé bebê person persona pessoa",
    "imgUrl": "assets/emojis/1f476.svg"
  },
  {
    "codes": "1f9d2",
    "char": "🧒",
    "name": "Child",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "criança",
      "child",
      "niño"
    ],
    "keywordsStr": "child criança niño person persona pessoa",
    "imgUrl": "assets/emojis/1f9d2.svg"
  },
  {
    "codes": "1f466",
    "char": "👦",
    "name": "Boy",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "menino",
      "boy",
      "niño"
    ],
    "keywordsStr": "boy menino niño person persona pessoa",
    "imgUrl": "assets/emojis/1f466.svg"
  },
  {
    "codes": "1f467",
    "char": "👧",
    "name": "Girl",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "menina",
      "girl",
      "niña"
    ],
    "keywordsStr": "girl menina niña person persona pessoa",
    "imgUrl": "assets/emojis/1f467.svg"
  },
  {
    "codes": "1f9d1",
    "char": "🧑",
    "name": "Person",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1.svg"
  },
  {
    "codes": "1f471",
    "char": "👱",
    "name": "Person Blond Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f471.svg"
  },
  {
    "codes": "1f468",
    "char": "👨",
    "name": "Man",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f468.svg"
  },
  {
    "codes": "1f9d4",
    "char": "🧔",
    "name": "Person Beard",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa barba beard",
    "imgUrl": "assets/emojis/1f9d4.svg"
  },
  {
    "codes": "1f9d4-200d-2642-fe0f",
    "char": "🧔‍♂️",
    "name": "Man Beard",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa barba beard",
    "imgUrl": "assets/emojis/1f9d4-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d4-200d-2640-fe0f",
    "char": "🧔‍♀️",
    "name": "Woman Beard",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman barba beard",
    "imgUrl": "assets/emojis/1f9d4-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f468-200d-1f9b0",
    "char": "👨‍🦰",
    "name": "Man Red Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f468-200d-1f9b0.svg"
  },
  {
    "codes": "1f468-200d-1f9b1",
    "char": "👨‍🦱",
    "name": "Man Curly Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa cacheado curly rizado cabelo hair pelo",
    "imgUrl": "assets/emojis/1f468-200d-1f9b1.svg"
  },
  {
    "codes": "1f468-200d-1f9b3",
    "char": "👨‍🦳",
    "name": "Man White Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f468-200d-1f9b3.svg"
  },
  {
    "codes": "1f468-200d-1f9b2",
    "char": "👨‍🦲",
    "name": "Man Bald",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f468-200d-1f9b2.svg"
  },
  {
    "codes": "1f469",
    "char": "👩",
    "name": "Woman",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f469.svg"
  },
  {
    "codes": "1f469-200d-1f9b0",
    "char": "👩‍🦰",
    "name": "Woman Red Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9b0.svg"
  },
  {
    "codes": "1f9d1-200d-1f9b0",
    "char": "🧑‍🦰",
    "name": "Person Red Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9b0.svg"
  },
  {
    "codes": "1f469-200d-1f9b1",
    "char": "👩‍🦱",
    "name": "Woman Curly Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9b1.svg"
  },
  {
    "codes": "1f9d1-200d-1f9b1",
    "char": "🧑‍🦱",
    "name": "Person Curly Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9b1.svg"
  },
  {
    "codes": "1f469-200d-1f9b3",
    "char": "👩‍🦳",
    "name": "Woman White Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9b3.svg"
  },
  {
    "codes": "1f9d1-200d-1f9b3",
    "char": "🧑‍🦳",
    "name": "Person White Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9b3.svg"
  },
  {
    "codes": "1f469-200d-1f9b2",
    "char": "👩‍🦲",
    "name": "Woman Bald",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9b2.svg"
  },
  {
    "codes": "1f9d1-200d-1f9b2",
    "char": "🧑‍🦲",
    "name": "Person Bald",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona"
    ],
    "keywordsStr": "person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9b2.svg"
  },
  {
    "codes": "1f471-200d-2640-fe0f",
    "char": "👱‍♀️",
    "name": "Woman Blond Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "mulher",
      "woman",
      "mujer"
    ],
    "keywordsStr": "mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f471-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f471-200d-2642-fe0f",
    "char": "👱‍♂️",
    "name": "Man Blond Hair",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "homem",
      "man",
      "hombre"
    ],
    "keywordsStr": "hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f471-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d3",
    "char": "🧓",
    "name": "Older Person",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "pessoa idosa",
      "older person",
      "persona mayor"
    ],
    "keywordsStr": "idosa mayor older person persona pessoa",
    "imgUrl": "assets/emojis/1f9d3.svg"
  },
  {
    "codes": "1f474",
    "char": "👴",
    "name": "Old Man",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "velho",
      "old man",
      "viejo"
    ],
    "keywordsStr": "man old person persona pessoa velho viejo",
    "imgUrl": "assets/emojis/1f474.svg"
  },
  {
    "codes": "1f475",
    "char": "👵",
    "name": "Old Woman",
    "category": "People & Body (person)",
    "group": "peopleAndBody",
    "subgroup": "person",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "velha",
      "old woman",
      "anciana"
    ],
    "keywordsStr": "anciana old person persona pessoa velha woman",
    "imgUrl": "assets/emojis/1f475.svg"
  },
  {
    "codes": "1f64d",
    "char": "🙍",
    "name": "Person Frowning",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa franzindo a testa",
      "person frowning",
      "persona frunciendo el ceño"
    ],
    "keywordsStr": "a ceño de el franzindo frowning frunciendo gesto gesture person persona pessoa testa",
    "imgUrl": "assets/emojis/1f64d.svg"
  },
  {
    "codes": "1f64d-200d-2642-fe0f",
    "char": "🙍‍♂️",
    "name": "Man Frowning",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem franzindo a testa",
      "man frowning",
      "hombre frunciendo el ceño"
    ],
    "keywordsStr": "a ceño de el franzindo frowning frunciendo gesto gesture hombre homem man person persona pessoa testa",
    "imgUrl": "assets/emojis/1f64d-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f64d-200d-2640-fe0f",
    "char": "🙍‍♀️",
    "name": "Woman Frowning",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher carrancuda",
      "woman frowning",
      "mujer frunciendo el ceño"
    ],
    "keywordsStr": "carrancuda ceño de el frowning frunciendo gesto gesture mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f64d-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f64e",
    "char": "🙎",
    "name": "Person Pouting",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa fazendo beicinho",
      "person pouting",
      "persona haciendo pucheros"
    ],
    "keywordsStr": "beicinho de fazendo gesto gesture haciendo person persona pessoa pouting pucheros",
    "imgUrl": "assets/emojis/1f64e.svg"
  },
  {
    "codes": "1f64e-200d-2642-fe0f",
    "char": "🙎‍♂️",
    "name": "Man Pouting",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem fazendo beicinho",
      "man pouting",
      "hombre haciendo pucheros"
    ],
    "keywordsStr": "beicinho de fazendo gesto gesture haciendo hombre homem man person persona pessoa pouting pucheros",
    "imgUrl": "assets/emojis/1f64e-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f64e-200d-2640-fe0f",
    "char": "🙎‍♀️",
    "name": "Woman Pouting",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher fazendo beicinho",
      "woman pouting",
      "mujer haciendo pucheros"
    ],
    "keywordsStr": "beicinho de fazendo gesto gesture haciendo mujer mulher person persona pessoa pouting pucheros woman",
    "imgUrl": "assets/emojis/1f64e-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f645",
    "char": "🙅",
    "name": "Person Gesturing No",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa gesticulando NÃO",
      "person gesturing NO",
      "persona haciendo un gesto de NO"
    ],
    "keywordsStr": "NO NÃO de gesticulando gesto gesture gesturing haciendo person persona pessoa un",
    "imgUrl": "assets/emojis/1f645.svg"
  },
  {
    "codes": "1f645-200d-2642-fe0f",
    "char": "🙅‍♂️",
    "name": "Man Gesturing No",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem gesticulando NÃO",
      "man gesturing NO",
      "hombre haciendo un gesto de NO"
    ],
    "keywordsStr": "NO NÃO de gesticulando gesto gesture gesturing haciendo hombre homem man person persona pessoa un",
    "imgUrl": "assets/emojis/1f645-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f645-200d-2640-fe0f",
    "char": "🙅‍♀️",
    "name": "Woman Gesturing No",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher gesticulando NÃO",
      "woman gesturing NO",
      "mujer gesticulando NO"
    ],
    "keywordsStr": "NO NÃO de gesticulando gesto gesture gesturing mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f645-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f646",
    "char": "🙆",
    "name": "Person Gesturing Ok",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa gesticulando OK",
      "person gesturing OK",
      "persona gesticulando OK"
    ],
    "keywordsStr": "OK de gesticulando gesto gesture gesturing person persona pessoa",
    "imgUrl": "assets/emojis/1f646.svg"
  },
  {
    "codes": "1f646-200d-2642-fe0f",
    "char": "🙆‍♂️",
    "name": "Man Gesturing Ok",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem gesticulando OK",
      "man gesturing OK",
      "hombre gesticulando OK"
    ],
    "keywordsStr": "OK de gesticulando gesto gesture gesturing hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f646-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f646-200d-2640-fe0f",
    "char": "🙆‍♀️",
    "name": "Woman Gesturing Ok",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher gesticulando OK",
      "woman gesturing OK",
      "mujer gesticulando OK"
    ],
    "keywordsStr": "OK de gesticulando gesto gesture gesturing mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f646-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f481",
    "char": "💁",
    "name": "Person Tipping Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa derrubando a mão",
      "person tipping hand",
      "persona que da propina"
    ],
    "keywordsStr": "a da de derrubando gesto gesture hand mão person persona pessoa propina que tipping",
    "imgUrl": "assets/emojis/1f481.svg"
  },
  {
    "codes": "1f481-200d-2642-fe0f",
    "char": "💁‍♂️",
    "name": "Man Tipping Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem derrubando a mão",
      "man tipping hand",
      "hombre dando propina a la mano"
    ],
    "keywordsStr": "a dando de derrubando gesto gesture hand hombre homem la man mano mão person persona pessoa propina tipping",
    "imgUrl": "assets/emojis/1f481-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f481-200d-2640-fe0f",
    "char": "💁‍♀️",
    "name": "Woman Tipping Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher derrubando a mão",
      "woman tipping hand",
      "mujer dando propina a la mano"
    ],
    "keywordsStr": "a dando de derrubando gesto gesture hand la mano mujer mulher mão person persona pessoa propina tipping woman",
    "imgUrl": "assets/emojis/1f481-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f64b",
    "char": "🙋",
    "name": "Person Raising Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa levantando a mão",
      "person raising hand",
      "persona levantando la mano"
    ],
    "keywordsStr": "a de gesto gesture hand la levantando mano mão person persona pessoa raising",
    "imgUrl": "assets/emojis/1f64b.svg"
  },
  {
    "codes": "1f64b-200d-2642-fe0f",
    "char": "🙋‍♂️",
    "name": "Man Raising Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem levantando a mão",
      "man raising hand",
      "hombre levantando la mano"
    ],
    "keywordsStr": "a de gesto gesture hand hombre homem la levantando man mano mão person persona pessoa raising",
    "imgUrl": "assets/emojis/1f64b-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f64b-200d-2640-fe0f",
    "char": "🙋‍♀️",
    "name": "Woman Raising Hand",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher levantando a mão",
      "woman raising hand",
      "mujer levantando la mano"
    ],
    "keywordsStr": "a de gesto gesture hand la levantando mano mujer mulher mão person persona pessoa raising woman",
    "imgUrl": "assets/emojis/1f64b-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9cf",
    "char": "🧏",
    "name": "Deaf Person",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "surdo",
      "deaf person",
      "persona sorda"
    ],
    "keywordsStr": "de deaf gesto gesture person persona pessoa sorda surdo",
    "imgUrl": "assets/emojis/1f9cf.svg"
  },
  {
    "codes": "1f9cf-200d-2642-fe0f",
    "char": "🧏‍♂️",
    "name": "Deaf Man",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem surdo",
      "deaf man",
      "hombre sordo"
    ],
    "keywordsStr": "de deaf gesto gesture hombre homem man person persona pessoa sordo surdo",
    "imgUrl": "assets/emojis/1f9cf-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9cf-200d-2640-fe0f",
    "char": "🧏‍♀️",
    "name": "Deaf Woman",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher surda",
      "deaf woman",
      "mujer sorda"
    ],
    "keywordsStr": "de deaf gesto gesture mujer mulher person persona pessoa sorda surda woman",
    "imgUrl": "assets/emojis/1f9cf-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f647",
    "char": "🙇",
    "name": "Person Bowing",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa se curvando",
      "person bowing",
      "persona inclinándose"
    ],
    "keywordsStr": "bowing curvando de gesto gesture inclinándose person persona pessoa se",
    "imgUrl": "assets/emojis/1f647.svg"
  },
  {
    "codes": "1f647-200d-2642-fe0f",
    "char": "🙇‍♂️",
    "name": "Man Bowing",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem se curvando",
      "man bowing",
      "hombre inclinándose"
    ],
    "keywordsStr": "bowing curvando de gesto gesture hombre homem inclinándose man person persona pessoa se",
    "imgUrl": "assets/emojis/1f647-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f647-200d-2640-fe0f",
    "char": "🙇‍♀️",
    "name": "Woman Bowing",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher se curvando",
      "woman bowing",
      "mujer inclinándose"
    ],
    "keywordsStr": "bowing curvando de gesto gesture inclinándose mujer mulher person persona pessoa se woman",
    "imgUrl": "assets/emojis/1f647-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f926",
    "char": "🤦",
    "name": "Person Facepalming",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "facepalming pessoa",
      "person facepalming",
      "persona con la palma de la mano"
    ],
    "keywordsStr": "con de facepalming gesto gesture la mano palma person persona pessoa",
    "imgUrl": "assets/emojis/1f926.svg"
  },
  {
    "codes": "1f926-200d-2642-fe0f",
    "char": "🤦‍♂️",
    "name": "Man Facepalming",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "man facepalming",
      "hombre palmeando la cara"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face facepalming faz features fisionomia fisonomía gesto gesture hombre la look man mug palmeando person persona pessoa rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f926-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f926-200d-2640-fe0f",
    "char": "🤦‍♀️",
    "name": "Woman Facepalming",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher facepalming",
      "woman facepalming",
      "mujer palmeando la cara"
    ],
    "keywordsStr": "apariencia cara countenance de expresión expression expressão face facepalming faz features fisionomia fisonomía gesto gesture la look mug mujer mulher palmeando person persona pessoa rosto rostro semblante visage woman",
    "imgUrl": "assets/emojis/1f926-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f937",
    "char": "🤷",
    "name": "Person Shrugging",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "pessoa dando de ombros",
      "person shrugging",
      "persona encogiéndose de hombros"
    ],
    "keywordsStr": "dando de encogiéndose gesto gesture hombros ombros person persona pessoa shrugging",
    "imgUrl": "assets/emojis/1f937.svg"
  },
  {
    "codes": "1f937-200d-2642-fe0f",
    "char": "🤷‍♂️",
    "name": "Man Shrugging",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "homem dando de ombros",
      "man shrugging",
      "hombre encogiéndose de hombros"
    ],
    "keywordsStr": "dando de encogiéndose gesto gesture hombre hombros homem man ombros person persona pessoa shrugging",
    "imgUrl": "assets/emojis/1f937-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f937-200d-2640-fe0f",
    "char": "🤷‍♀️",
    "name": "Woman Shrugging",
    "category": "People & Body (person-gesture)",
    "group": "peopleAndBody",
    "subgroup": "person-gesture",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "gesto",
      "gesture",
      "gesto de pessoa",
      "person gesture",
      "gesto de persona",
      "mulher dando de ombros",
      "woman shrugging",
      "mujer encogiéndose de hombros"
    ],
    "keywordsStr": "dando de encogiéndose gesto gesture hombros mujer mulher ombros person persona pessoa shrugging woman",
    "imgUrl": "assets/emojis/1f937-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d1-200d-2695-fe0f",
    "char": "🧑‍⚕️",
    "name": "Health Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "trabalhador de saúde",
      "health worker",
      "trabajador de la salud"
    ],
    "keywordsStr": "de health la papel person persona pessoa rol role salud saúde trabajador trabalhador worker",
    "imgUrl": "assets/emojis/1f9d1-200d-2695-fe0f.svg"
  },
  {
    "codes": "1f468-200d-2695-fe0f",
    "char": "👨‍⚕️",
    "name": "Man Health Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem trabalhador da saúde",
      "man health worker",
      "hombre trabajador de la salud"
    ],
    "keywordsStr": "da de health hombre homem la man papel person persona pessoa rol role salud saúde trabajador trabalhador worker",
    "imgUrl": "assets/emojis/1f468-200d-2695-fe0f.svg"
  },
  {
    "codes": "1f469-200d-2695-fe0f",
    "char": "👩‍⚕️",
    "name": "Woman Health Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher trabalhadora de saúde",
      "woman health worker",
      "mujer trabajadora de la salud"
    ],
    "keywordsStr": "de health la mujer mulher papel person persona pessoa rol role salud saúde trabajadora trabalhadora woman worker",
    "imgUrl": "assets/emojis/1f469-200d-2695-fe0f.svg"
  },
  {
    "codes": "1f9d1-200d-1f393",
    "char": "🧑‍🎓",
    "name": "Student",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "estudante",
      "student",
      "estudiante"
    ],
    "keywordsStr": "de estudante estudiante papel person persona pessoa rol role student",
    "imgUrl": "assets/emojis/1f9d1-200d-1f393.svg"
  },
  {
    "codes": "1f468-200d-1f393",
    "char": "👨‍🎓",
    "name": "Man Student",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem estudante",
      "man student",
      "hombre estudiante"
    ],
    "keywordsStr": "de estudante estudiante hombre homem man papel person persona pessoa rol role student",
    "imgUrl": "assets/emojis/1f468-200d-1f393.svg"
  },
  {
    "codes": "1f469-200d-1f393",
    "char": "👩‍🎓",
    "name": "Woman Student",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "estudante mulher",
      "woman student",
      "estudiante mujer"
    ],
    "keywordsStr": "de estudante estudiante mujer mulher papel person persona pessoa rol role student woman",
    "imgUrl": "assets/emojis/1f469-200d-1f393.svg"
  },
  {
    "codes": "1f9d1-200d-1f3eb",
    "char": "🧑‍🏫",
    "name": "Teacher",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "professor",
      "teacher",
      "maestro"
    ],
    "keywordsStr": "de maestro papel person persona pessoa professor rol role teacher",
    "imgUrl": "assets/emojis/1f9d1-200d-1f3eb.svg"
  },
  {
    "codes": "1f468-200d-1f3eb",
    "char": "👨‍🏫",
    "name": "Man Teacher",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem professor",
      "man teacher",
      "maestro hombre"
    ],
    "keywordsStr": "de hombre homem maestro man papel person persona pessoa professor rol role teacher",
    "imgUrl": "assets/emojis/1f468-200d-1f3eb.svg"
  },
  {
    "codes": "1f469-200d-1f3eb",
    "char": "👩‍🏫",
    "name": "Woman Teacher",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "professora",
      "woman teacher",
      "mujer maestra"
    ],
    "keywordsStr": "de maestra mujer papel person persona pessoa professora rol role teacher woman",
    "imgUrl": "assets/emojis/1f469-200d-1f3eb.svg"
  },
  {
    "codes": "1f9d1-200d-2696-fe0f",
    "char": "🧑‍⚖️",
    "name": "Judge",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "juiz",
      "judge",
      "juez"
    ],
    "keywordsStr": "de judge juez juiz papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-2696-fe0f.svg"
  },
  {
    "codes": "1f468-200d-2696-fe0f",
    "char": "👨‍⚖️",
    "name": "Man Judge",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem juiz",
      "man judge",
      "hombre juez"
    ],
    "keywordsStr": "de hombre homem judge juez juiz man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-2696-fe0f.svg"
  },
  {
    "codes": "1f469-200d-2696-fe0f",
    "char": "👩‍⚖️",
    "name": "Woman Judge",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher juíza",
      "woman judge",
      "mujer juez"
    ],
    "keywordsStr": "de judge juez juíza mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-2696-fe0f.svg"
  },
  {
    "codes": "1f9d1-200d-1f33e",
    "char": "🧑‍🌾",
    "name": "Farmer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "agricultor",
      "farmer"
    ],
    "keywordsStr": "agricultor de farmer papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f33e.svg"
  },
  {
    "codes": "1f468-200d-1f33e",
    "char": "👨‍🌾",
    "name": "Man Farmer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem agricultor",
      "man farmer",
      "hombre agricultor"
    ],
    "keywordsStr": "agricultor de farmer hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f33e.svg"
  },
  {
    "codes": "1f469-200d-1f33e",
    "char": "👩‍🌾",
    "name": "Woman Farmer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher agricultora",
      "woman farmer",
      "mujer agricultora"
    ],
    "keywordsStr": "agricultora de farmer mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f33e.svg"
  },
  {
    "codes": "1f9d1-200d-1f373",
    "char": "🧑‍🍳",
    "name": "Cook",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "cozinhar",
      "cook",
      "cocinar"
    ],
    "keywordsStr": "cocinar cook cozinhar de papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f373.svg"
  },
  {
    "codes": "1f468-200d-1f373",
    "char": "👨‍🍳",
    "name": "Man Cook",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem cozinheiro",
      "man cook",
      "hombre cocinero"
    ],
    "keywordsStr": "cocinero cook cozinheiro de hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f373.svg"
  },
  {
    "codes": "1f469-200d-1f373",
    "char": "👩‍🍳",
    "name": "Woman Cook",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher cozinheira",
      "woman cook",
      "mujer cocinera"
    ],
    "keywordsStr": "cocinera cook cozinheira de mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f373.svg"
  },
  {
    "codes": "1f9d1-200d-1f527",
    "char": "🧑‍🔧",
    "name": "Mechanic",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mecânico",
      "mechanic",
      "mecanico"
    ],
    "keywordsStr": "de mecanico mechanic mecânico papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f527.svg"
  },
  {
    "codes": "1f468-200d-1f527",
    "char": "👨‍🔧",
    "name": "Man Mechanic",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem mecânico",
      "man mechanic",
      "hombre mecánico"
    ],
    "keywordsStr": "de hombre homem man mechanic mecánico mecânico papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f527.svg"
  },
  {
    "codes": "1f469-200d-1f527",
    "char": "👩‍🔧",
    "name": "Woman Mechanic",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mecânica mulher",
      "woman mechanic",
      "mujer mecánica"
    ],
    "keywordsStr": "de mechanic mecánica mecânica mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f527.svg"
  },
  {
    "codes": "1f9d1-200d-1f3ed",
    "char": "🧑‍🏭",
    "name": "Factory Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "trabalhador de fábrica",
      "factory worker",
      "trabajador de fábrica"
    ],
    "keywordsStr": "de factory fábrica papel person persona pessoa rol role trabajador trabalhador worker",
    "imgUrl": "assets/emojis/1f9d1-200d-1f3ed.svg"
  },
  {
    "codes": "1f468-200d-1f3ed",
    "char": "👨‍🏭",
    "name": "Man Factory Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem operário de fábrica",
      "man factory worker",
      "hombre trabajador de fábrica"
    ],
    "keywordsStr": "de factory fábrica hombre homem man operário papel person persona pessoa rol role trabajador worker",
    "imgUrl": "assets/emojis/1f468-200d-1f3ed.svg"
  },
  {
    "codes": "1f469-200d-1f3ed",
    "char": "👩‍🏭",
    "name": "Woman Factory Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher operária de fábrica",
      "woman factory worker",
      "mujer obrera de fábrica"
    ],
    "keywordsStr": "de factory fábrica mujer mulher obrera operária papel person persona pessoa rol role woman worker",
    "imgUrl": "assets/emojis/1f469-200d-1f3ed.svg"
  },
  {
    "codes": "1f9d1-200d-1f4bc",
    "char": "🧑‍💼",
    "name": "Office Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "funcionário de escritório",
      "office worker",
      "trabajador de oficina"
    ],
    "keywordsStr": "de escritório funcionário office oficina papel person persona pessoa rol role trabajador worker",
    "imgUrl": "assets/emojis/1f9d1-200d-1f4bc.svg"
  },
  {
    "codes": "1f468-200d-1f4bc",
    "char": "👨‍💼",
    "name": "Man Office Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "trabalhador de escritório homem",
      "man office worker",
      "hombre oficinista"
    ],
    "keywordsStr": "de escritório hombre homem man office oficinista papel person persona pessoa rol role trabalhador worker",
    "imgUrl": "assets/emojis/1f468-200d-1f4bc.svg"
  },
  {
    "codes": "1f469-200d-1f4bc",
    "char": "👩‍💼",
    "name": "Woman Office Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher trabalhadora de escritório",
      "woman office worker",
      "mujer oficinista"
    ],
    "keywordsStr": "de escritório mujer mulher office oficinista papel person persona pessoa rol role trabalhadora woman worker",
    "imgUrl": "assets/emojis/1f469-200d-1f4bc.svg"
  },
  {
    "codes": "1f9d1-200d-1f52c",
    "char": "🧑‍🔬",
    "name": "Scientist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "cientista",
      "scientist",
      "científico"
    ],
    "keywordsStr": "cientista científico de papel person persona pessoa rol role scientist",
    "imgUrl": "assets/emojis/1f9d1-200d-1f52c.svg"
  },
  {
    "codes": "1f468-200d-1f52c",
    "char": "👨‍🔬",
    "name": "Man Scientist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem cientista",
      "man scientist",
      "hombre científico"
    ],
    "keywordsStr": "cientista científico de hombre homem man papel person persona pessoa rol role scientist",
    "imgUrl": "assets/emojis/1f468-200d-1f52c.svg"
  },
  {
    "codes": "1f469-200d-1f52c",
    "char": "👩‍🔬",
    "name": "Woman Scientist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher cientista",
      "woman scientist",
      "mujer científica"
    ],
    "keywordsStr": "cientista científica de mujer mulher papel person persona pessoa rol role scientist woman",
    "imgUrl": "assets/emojis/1f469-200d-1f52c.svg"
  },
  {
    "codes": "1f9d1-200d-1f4bb",
    "char": "🧑‍💻",
    "name": "Technologist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "tecnólogo",
      "technologist"
    ],
    "keywordsStr": "de papel person persona pessoa rol role technologist tecnólogo",
    "imgUrl": "assets/emojis/1f9d1-200d-1f4bb.svg"
  },
  {
    "codes": "1f468-200d-1f4bb",
    "char": "👨‍💻",
    "name": "Man Technologist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem tecnólogo",
      "man technologist",
      "hombre tecnólogo"
    ],
    "keywordsStr": "de hombre homem man papel person persona pessoa rol role technologist tecnólogo",
    "imgUrl": "assets/emojis/1f468-200d-1f4bb.svg"
  },
  {
    "codes": "1f469-200d-1f4bb",
    "char": "👩‍💻",
    "name": "Woman Technologist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher tecnóloga",
      "woman technologist",
      "mujer tecnóloga"
    ],
    "keywordsStr": "de mujer mulher papel person persona pessoa rol role technologist tecnóloga woman",
    "imgUrl": "assets/emojis/1f469-200d-1f4bb.svg"
  },
  {
    "codes": "1f9d1-200d-1f3a4",
    "char": "🧑‍🎤",
    "name": "Singer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "cantor",
      "singer",
      "cantante"
    ],
    "keywordsStr": "cantante cantor de papel person persona pessoa rol role singer",
    "imgUrl": "assets/emojis/1f9d1-200d-1f3a4.svg"
  },
  {
    "codes": "1f468-200d-1f3a4",
    "char": "👨‍🎤",
    "name": "Man Singer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem cantor",
      "man singer",
      "hombre cantante"
    ],
    "keywordsStr": "cantante cantor de hombre homem man papel person persona pessoa rol role singer",
    "imgUrl": "assets/emojis/1f468-200d-1f3a4.svg"
  },
  {
    "codes": "1f469-200d-1f3a4",
    "char": "👩‍🎤",
    "name": "Woman Singer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "cantora mulher",
      "woman singer",
      "mujer cantante"
    ],
    "keywordsStr": "cantante cantora de mujer mulher papel person persona pessoa rol role singer woman",
    "imgUrl": "assets/emojis/1f469-200d-1f3a4.svg"
  },
  {
    "codes": "1f9d1-200d-1f3a8",
    "char": "🧑‍🎨",
    "name": "Artist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "artista",
      "artist"
    ],
    "keywordsStr": "artist artista de papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f3a8.svg"
  },
  {
    "codes": "1f468-200d-1f3a8",
    "char": "👨‍🎨",
    "name": "Man Artist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem artista",
      "man artist",
      "hombre artista"
    ],
    "keywordsStr": "artist artista de hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f3a8.svg"
  },
  {
    "codes": "1f469-200d-1f3a8",
    "char": "👩‍🎨",
    "name": "Woman Artist",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher artista",
      "woman artist",
      "mujer artista"
    ],
    "keywordsStr": "artist artista de mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f3a8.svg"
  },
  {
    "codes": "1f9d1-200d-2708-fe0f",
    "char": "🧑‍✈️",
    "name": "Pilot",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "piloto",
      "pilot"
    ],
    "keywordsStr": "de papel person persona pessoa pilot piloto rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-2708-fe0f.svg"
  },
  {
    "codes": "1f468-200d-2708-fe0f",
    "char": "👨‍✈️",
    "name": "Man Pilot",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem piloto",
      "man pilot",
      "hombre piloto"
    ],
    "keywordsStr": "de hombre homem man papel person persona pessoa pilot piloto rol role",
    "imgUrl": "assets/emojis/1f468-200d-2708-fe0f.svg"
  },
  {
    "codes": "1f469-200d-2708-fe0f",
    "char": "👩‍✈️",
    "name": "Woman Pilot",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher piloto",
      "woman pilot",
      "mujer piloto"
    ],
    "keywordsStr": "de mujer mulher papel person persona pessoa pilot piloto rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-2708-fe0f.svg"
  },
  {
    "codes": "1f9d1-200d-1f680",
    "char": "🧑‍🚀",
    "name": "Astronaut",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "astronauta",
      "astronaut"
    ],
    "keywordsStr": "astronaut astronauta de papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f680.svg"
  },
  {
    "codes": "1f468-200d-1f680",
    "char": "👨‍🚀",
    "name": "Man Astronaut",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem astronauta",
      "man astronaut",
      "hombre astronauta"
    ],
    "keywordsStr": "astronaut astronauta de hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f680.svg"
  },
  {
    "codes": "1f469-200d-1f680",
    "char": "👩‍🚀",
    "name": "Woman Astronaut",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher astronauta",
      "woman astronaut",
      "mujer astronauta"
    ],
    "keywordsStr": "astronaut astronauta de mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f680.svg"
  },
  {
    "codes": "1f9d1-200d-1f692",
    "char": "🧑‍🚒",
    "name": "Firefighter",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "bombeiro",
      "firefighter",
      "bombero"
    ],
    "keywordsStr": "bombeiro bombero de firefighter papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f692.svg"
  },
  {
    "codes": "1f468-200d-1f692",
    "char": "👨‍🚒",
    "name": "Man Firefighter",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem bombeiro",
      "man firefighter",
      "hombre bombero"
    ],
    "keywordsStr": "bombeiro bombero de firefighter hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f692.svg"
  },
  {
    "codes": "1f469-200d-1f692",
    "char": "👩‍🚒",
    "name": "Woman Firefighter",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher bombeira",
      "woman firefighter",
      "mujer bombero"
    ],
    "keywordsStr": "bombeira bombero de firefighter mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f692.svg"
  },
  {
    "codes": "1f46e",
    "char": "👮",
    "name": "Police Officer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "policial",
      "police officer",
      "oficial de policía"
    ],
    "keywordsStr": "de officer oficial papel person persona pessoa police policial policía rol role",
    "imgUrl": "assets/emojis/1f46e.svg"
  },
  {
    "codes": "1f46e-200d-2642-fe0f",
    "char": "👮‍♂️",
    "name": "Man Police Officer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem policial",
      "man police officer",
      "hombre oficial de policía"
    ],
    "keywordsStr": "de hombre homem man officer oficial papel person persona pessoa police policial policía rol role",
    "imgUrl": "assets/emojis/1f46e-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f46e-200d-2640-fe0f",
    "char": "👮‍♀️",
    "name": "Woman Police Officer",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher policial",
      "woman police officer",
      "mujer policía"
    ],
    "keywordsStr": "de mujer mulher officer papel person persona pessoa police policial policía rol role woman",
    "imgUrl": "assets/emojis/1f46e-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f575",
    "char": "🕵️",
    "name": "Detective",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "detetive",
      "detective"
    ],
    "keywordsStr": "de detective detetive papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f575.svg"
  },
  {
    "codes": "1f575-fe0f-200d-2642-fe0f",
    "char": "🕵️‍♂️",
    "name": "Man Detective",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem detetive",
      "man detective",
      "hombre detective"
    ],
    "keywordsStr": "de detective detetive hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f575-fe0f-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f575-fe0f-200d-2640-fe0f",
    "char": "🕵️‍♀️",
    "name": "Woman Detective",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "detetive mulher",
      "woman detective",
      "mujer detective"
    ],
    "keywordsStr": "de detective detetive mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f575-fe0f-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f482",
    "char": "💂",
    "name": "Guard",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "guarda",
      "guard",
      "guardia"
    ],
    "keywordsStr": "de guard guarda guardia papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f482.svg"
  },
  {
    "codes": "1f482-200d-2642-fe0f",
    "char": "💂‍♂️",
    "name": "Man Guard",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "guarda homem",
      "man guard",
      "guardia hombre"
    ],
    "keywordsStr": "de guard guarda guardia hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f482-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f482-200d-2640-fe0f",
    "char": "💂‍♀️",
    "name": "Woman Guard",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "guarda mulher",
      "woman guard",
      "mujer guardia"
    ],
    "keywordsStr": "de guard guarda guardia mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f482-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f977",
    "char": "🥷",
    "name": "Ninja",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "ninja",
      "ninjas"
    ],
    "keywordsStr": "de ninja ninjas papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f977.svg"
  },
  {
    "codes": "1f477",
    "char": "👷",
    "name": "Construction Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "trabalhador da construção",
      "construction worker",
      "trabajador de la construcción"
    ],
    "keywordsStr": "construcción construction construção da de la papel person persona pessoa rol role trabajador trabalhador worker",
    "imgUrl": "assets/emojis/1f477.svg"
  },
  {
    "codes": "1f477-200d-2642-fe0f",
    "char": "👷‍♂️",
    "name": "Man Construction Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem trabalhador da construção civil",
      "man construction worker",
      "hombre trabajador de la construcción"
    ],
    "keywordsStr": "civil construcción construction construção da de hombre homem la man papel person persona pessoa rol role trabajador trabalhador worker",
    "imgUrl": "assets/emojis/1f477-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f477-200d-2640-fe0f",
    "char": "👷‍♀️",
    "name": "Woman Construction Worker",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher trabalhadora da construção civil",
      "woman construction worker",
      "mujer trabajadora de la construcción"
    ],
    "keywordsStr": "civil construcción construction construção da de la mujer mulher papel person persona pessoa rol role trabajadora trabalhadora woman worker",
    "imgUrl": "assets/emojis/1f477-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f934",
    "char": "🤴",
    "name": "Prince",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "príncipe",
      "prince"
    ],
    "keywordsStr": "de papel person persona pessoa prince príncipe rol role",
    "imgUrl": "assets/emojis/1f934.svg"
  },
  {
    "codes": "1f478",
    "char": "👸",
    "name": "Princess",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "princesa",
      "princess"
    ],
    "keywordsStr": "de papel person persona pessoa princesa princess rol role",
    "imgUrl": "assets/emojis/1f478.svg"
  },
  {
    "codes": "1f473",
    "char": "👳",
    "name": "Person Wearing Turban",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "pessoa usando turbante",
      "person wearing turban",
      "persona con turbante"
    ],
    "keywordsStr": "con de papel person persona pessoa rol role turban turbante usando wearing",
    "imgUrl": "assets/emojis/1f473.svg"
  },
  {
    "codes": "1f473-200d-2642-fe0f",
    "char": "👳‍♂️",
    "name": "Man Wearing Turban",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem usando turbante",
      "man wearing turban",
      "hombre con turbante"
    ],
    "keywordsStr": "con de hombre homem man papel person persona pessoa rol role turban turbante usando wearing",
    "imgUrl": "assets/emojis/1f473-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f473-200d-2640-fe0f",
    "char": "👳‍♀️",
    "name": "Woman Wearing Turban",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher usando turbante",
      "woman wearing turban",
      "mujer con turbante"
    ],
    "keywordsStr": "con de mujer mulher papel person persona pessoa rol role turban turbante usando wearing woman",
    "imgUrl": "assets/emojis/1f473-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f472",
    "char": "👲",
    "name": "Person With Skullcap",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "pessoa com calota craniana",
      "person with skullcap",
      "persona con casquete"
    ],
    "keywordsStr": "calota casquete com con craniana de papel person persona pessoa rol role skullcap with",
    "imgUrl": "assets/emojis/1f472.svg"
  },
  {
    "codes": "1f9d5",
    "char": "🧕",
    "name": "Woman With Headscarf",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher com lenço na cabeça",
      "woman with headscarf",
      "mujer con pañuelo en la cabeza"
    ],
    "keywordsStr": "cabeza cabeça com con de en headscarf la lenço mujer mulher na papel pañuelo person persona pessoa rol role with woman",
    "imgUrl": "assets/emojis/1f9d5.svg"
  },
  {
    "codes": "1f935",
    "char": "🤵",
    "name": "Person In Tuxedo",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "pessoa de smoking",
      "person in tuxedo",
      "persona en esmoquin"
    ],
    "keywordsStr": "de en esmoquin in papel person persona pessoa rol role smoking tuxedo",
    "imgUrl": "assets/emojis/1f935.svg"
  },
  {
    "codes": "1f935-200d-2642-fe0f",
    "char": "🤵‍♂️",
    "name": "Man In Tuxedo",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem de smoking",
      "man in tuxedo",
      "hombre en esmoquin"
    ],
    "keywordsStr": "de en esmoquin hombre homem in man papel person persona pessoa rol role smoking tuxedo",
    "imgUrl": "assets/emojis/1f935-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f935-200d-2640-fe0f",
    "char": "🤵‍♀️",
    "name": "Woman In Tuxedo",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher de smoking",
      "woman in tuxedo",
      "mujer en esmoquin"
    ],
    "keywordsStr": "de en esmoquin in mujer mulher papel person persona pessoa rol role smoking tuxedo woman",
    "imgUrl": "assets/emojis/1f935-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f470",
    "char": "👰",
    "name": "Person With Veil",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "pessoa com véu",
      "person with veil",
      "persona con velo"
    ],
    "keywordsStr": "com con de papel person persona pessoa rol role veil velo véu with",
    "imgUrl": "assets/emojis/1f470.svg"
  },
  {
    "codes": "1f470-200d-2642-fe0f",
    "char": "👰‍♂️",
    "name": "Man With Veil",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem com véu",
      "man with veil",
      "hombre con velo"
    ],
    "keywordsStr": "com con de hombre homem man papel person persona pessoa rol role veil velo véu with",
    "imgUrl": "assets/emojis/1f470-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f470-200d-2640-fe0f",
    "char": "👰‍♀️",
    "name": "Woman With Veil",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher com véu",
      "woman with veil",
      "mujer con velo"
    ],
    "keywordsStr": "com con de mujer mulher papel person persona pessoa rol role veil velo véu with woman",
    "imgUrl": "assets/emojis/1f470-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f930",
    "char": "🤰",
    "name": "Pregnant Woman",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher grávida",
      "pregnant woman",
      "mujer embarazada"
    ],
    "keywordsStr": "de embarazada grávida mujer mulher papel person persona pessoa pregnant rol role woman",
    "imgUrl": "assets/emojis/1f930.svg"
  },
  {
    "codes": "1f931",
    "char": "🤱",
    "name": "Breast Feeding",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "amamentação",
      "breast-feeding",
      "lactancia materna"
    ],
    "keywordsStr": "amamentação breast-feeding de lactancia materna papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f931.svg"
  },
  {
    "codes": "1f469-200d-1f37c",
    "char": "👩‍🍼",
    "name": "Woman Feeding Baby",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "mulher alimentando bebê",
      "woman feeding baby",
      "mujer dando de comer al bebé"
    ],
    "keywordsStr": "al alimentando baby bebé bebê comer dando de feeding mujer mulher papel person persona pessoa rol role woman",
    "imgUrl": "assets/emojis/1f469-200d-1f37c.svg"
  },
  {
    "codes": "1f468-200d-1f37c",
    "char": "👨‍🍼",
    "name": "Man Feeding Baby",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "homem alimentando bebê",
      "man feeding baby",
      "hombre alimentando bebe"
    ],
    "keywordsStr": "alimentando baby bebe bebê de feeding hombre homem man papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f468-200d-1f37c.svg"
  },
  {
    "codes": "1f9d1-200d-1f37c",
    "char": "🧑‍🍼",
    "name": "Person Feeding Baby",
    "category": "People & Body (person-role)",
    "group": "peopleAndBody",
    "subgroup": "person-role",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "papel",
      "role",
      "papel de pessoa",
      "person role",
      "rol de persona",
      "pessoa alimentando bebê",
      "person feeding baby",
      "persona alimentando bebe"
    ],
    "keywordsStr": "alimentando baby bebe bebê de feeding papel person persona pessoa rol role",
    "imgUrl": "assets/emojis/1f9d1-200d-1f37c.svg"
  },
  {
    "codes": "1f47c",
    "char": "👼",
    "name": "Baby Angel",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "anjo bebê",
      "baby angel",
      "bebé ángel"
    ],
    "keywordsStr": "angel anjo baby bebé bebê de fantasia fantasy fantasía person persona pessoa ángel",
    "imgUrl": "assets/emojis/1f47c.svg"
  },
  {
    "codes": "1f385",
    "char": "🎅",
    "name": "Santa Claus",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "Papai Noel",
      "Santa Claus",
      "Papá Noel"
    ],
    "keywordsStr": "Claus Noel Papai Papá Santa de fantasia fantasy fantasía person persona pessoa",
    "imgUrl": "assets/emojis/1f385.svg"
  },
  {
    "codes": "1f936",
    "char": "🤶",
    "name": "Mrs Claus",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "Sra. Claus",
      "Mrs. Claus",
      "Señora Claus"
    ],
    "keywordsStr": "Claus Mrs. Señora Sra. de fantasia fantasy fantasía person persona pessoa",
    "imgUrl": "assets/emojis/1f936.svg"
  },
  {
    "codes": "1f9d1-200d-1f384",
    "char": "🧑‍🎄",
    "name": "Mx Claus",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mx cláusula",
      "mx claus",
      "mx noel"
    ],
    "keywordsStr": "claus cláusula de fantasia fantasy fantasía mx noel person persona pessoa",
    "imgUrl": "assets/emojis/1f9d1-200d-1f384.svg"
  },
  {
    "codes": "1f9b8",
    "char": "🦸",
    "name": "Superhero",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "super-herói",
      "superhero",
      "superhéroe"
    ],
    "keywordsStr": "de fantasia fantasy fantasía person persona pessoa super-herói superhero superhéroe",
    "imgUrl": "assets/emojis/1f9b8.svg"
  },
  {
    "codes": "1f9b8-200d-2642-fe0f",
    "char": "🦸‍♂️",
    "name": "Man Superhero",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem super-herói",
      "man superhero",
      "hombre superhéroe"
    ],
    "keywordsStr": "de fantasia fantasy fantasía hombre homem man person persona pessoa super-herói superhero superhéroe",
    "imgUrl": "assets/emojis/1f9b8-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9b8-200d-2640-fe0f",
    "char": "🦸‍♀️",
    "name": "Woman Superhero",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher super-heroína",
      "woman superhero",
      "mujer superhéroe"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mujer mulher person persona pessoa super-heroína superhero superhéroe woman",
    "imgUrl": "assets/emojis/1f9b8-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9b9",
    "char": "🦹",
    "name": "Supervillain",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "supervilão",
      "supervillain",
      "supervillano"
    ],
    "keywordsStr": "de fantasia fantasy fantasía person persona pessoa supervillain supervillano supervilão",
    "imgUrl": "assets/emojis/1f9b9.svg"
  },
  {
    "codes": "1f9b9-200d-2642-fe0f",
    "char": "🦹‍♂️",
    "name": "Man Supervillain",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem supervilão",
      "man supervillain",
      "hombre supervillano"
    ],
    "keywordsStr": "de fantasia fantasy fantasía hombre homem man person persona pessoa supervillain supervillano supervilão",
    "imgUrl": "assets/emojis/1f9b9-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9b9-200d-2640-fe0f",
    "char": "🦹‍♀️",
    "name": "Woman Supervillain",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher supervilã",
      "woman supervillain",
      "mujer supervillana"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mujer mulher person persona pessoa supervillain supervillana supervilã woman",
    "imgUrl": "assets/emojis/1f9b9-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d9",
    "char": "🧙",
    "name": "Mage",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mago",
      "mage"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mage mago person persona pessoa",
    "imgUrl": "assets/emojis/1f9d9.svg"
  },
  {
    "codes": "1f9d9-200d-2642-fe0f",
    "char": "🧙‍♂️",
    "name": "Man Mage",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem mago",
      "man mage",
      "hombre mago"
    ],
    "keywordsStr": "de fantasia fantasy fantasía hombre homem mage mago man person persona pessoa",
    "imgUrl": "assets/emojis/1f9d9-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d9-200d-2640-fe0f",
    "char": "🧙‍♀️",
    "name": "Woman Mage",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher maga",
      "woman mage",
      "mujer maga"
    ],
    "keywordsStr": "de fantasia fantasy fantasía maga mage mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9d9-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9da",
    "char": "🧚",
    "name": "Fairy",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "fada",
      "fairy",
      "hada"
    ],
    "keywordsStr": "de fada fairy fantasia fantasy fantasía hada person persona pessoa",
    "imgUrl": "assets/emojis/1f9da.svg"
  },
  {
    "codes": "1f9da-200d-2642-fe0f",
    "char": "🧚‍♂️",
    "name": "Man Fairy",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem fada",
      "man fairy",
      "hombre hada"
    ],
    "keywordsStr": "de fada fairy fantasia fantasy fantasía hada hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f9da-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9da-200d-2640-fe0f",
    "char": "🧚‍♀️",
    "name": "Woman Fairy",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher fada",
      "woman fairy",
      "mujer hada"
    ],
    "keywordsStr": "de fada fairy fantasia fantasy fantasía hada mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9da-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9db",
    "char": "🧛",
    "name": "Vampire",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "vampiro",
      "vampire"
    ],
    "keywordsStr": "de fantasia fantasy fantasía person persona pessoa vampire vampiro",
    "imgUrl": "assets/emojis/1f9db.svg"
  },
  {
    "codes": "1f9db-200d-2642-fe0f",
    "char": "🧛‍♂️",
    "name": "Man Vampire",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem vampiro",
      "man vampire",
      "vampiro hombre"
    ],
    "keywordsStr": "de fantasia fantasy fantasía hombre homem man person persona pessoa vampire vampiro",
    "imgUrl": "assets/emojis/1f9db-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9db-200d-2640-fe0f",
    "char": "🧛‍♀️",
    "name": "Woman Vampire",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher vampira",
      "woman vampire",
      "mujer vampiro"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mujer mulher person persona pessoa vampira vampire vampiro woman",
    "imgUrl": "assets/emojis/1f9db-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9dc",
    "char": "🧜",
    "name": "Merperson",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "sereia",
      "merperson",
      "persona sirena"
    ],
    "keywordsStr": "de fantasia fantasy fantasía merperson person persona pessoa sereia sirena",
    "imgUrl": "assets/emojis/1f9dc.svg"
  },
  {
    "codes": "1f9dc-200d-2642-fe0f",
    "char": "🧜‍♂️",
    "name": "Merman",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "sereia",
      "merman",
      "tritón"
    ],
    "keywordsStr": "de fantasia fantasy fantasía merman person persona pessoa sereia tritón",
    "imgUrl": "assets/emojis/1f9dc-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9dc-200d-2640-fe0f",
    "char": "🧜‍♀️",
    "name": "Mermaid",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "sereia",
      "mermaid",
      "sirena"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mermaid person persona pessoa sereia sirena",
    "imgUrl": "assets/emojis/1f9dc-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9dd",
    "char": "🧝",
    "name": "Elf",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "elfo",
      "elf"
    ],
    "keywordsStr": "de elf elfo fantasia fantasy fantasía person persona pessoa",
    "imgUrl": "assets/emojis/1f9dd.svg"
  },
  {
    "codes": "1f9dd-200d-2642-fe0f",
    "char": "🧝‍♂️",
    "name": "Man Elf",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem elfo",
      "man elf",
      "hombre elfo"
    ],
    "keywordsStr": "de elf elfo fantasia fantasy fantasía hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f9dd-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9dd-200d-2640-fe0f",
    "char": "🧝‍♀️",
    "name": "Woman Elf",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher elfa",
      "woman elf",
      "mujer elfa"
    ],
    "keywordsStr": "de elf elfa fantasia fantasy fantasía mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9dd-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9de",
    "char": "🧞",
    "name": "Genie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "gênio",
      "genie",
      "genio"
    ],
    "keywordsStr": "de fantasia fantasy fantasía genie genio gênio person persona pessoa",
    "imgUrl": "assets/emojis/1f9de.svg"
  },
  {
    "codes": "1f9de-200d-2642-fe0f",
    "char": "🧞‍♂️",
    "name": "Man Genie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem gênio",
      "man genie",
      "hombre genio"
    ],
    "keywordsStr": "de fantasia fantasy fantasía genie genio gênio hombre homem man person persona pessoa",
    "imgUrl": "assets/emojis/1f9de-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9de-200d-2640-fe0f",
    "char": "🧞‍♀️",
    "name": "Woman Genie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher gênio",
      "woman genie",
      "mujer genio"
    ],
    "keywordsStr": "de fantasia fantasy fantasía genie genio gênio mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9de-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9df",
    "char": "🧟",
    "name": "Zombie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "zumbi",
      "zombie",
      "zombi"
    ],
    "keywordsStr": "de fantasia fantasy fantasía person persona pessoa zombi zombie zumbi",
    "imgUrl": "assets/emojis/1f9df.svg"
  },
  {
    "codes": "1f9df-200d-2642-fe0f",
    "char": "🧟‍♂️",
    "name": "Man Zombie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "homem zumbi",
      "man zombie",
      "hombre zombi"
    ],
    "keywordsStr": "de fantasia fantasy fantasía hombre homem man person persona pessoa zombi zombie zumbi",
    "imgUrl": "assets/emojis/1f9df-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9df-200d-2640-fe0f",
    "char": "🧟‍♀️",
    "name": "Woman Zombie",
    "category": "People & Body (person-fantasy)",
    "group": "peopleAndBody",
    "subgroup": "person-fantasy",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "fantasia",
      "fantasy",
      "fantasía",
      "fantasia de pessoa",
      "person fantasy",
      "fantasía de persona",
      "mulher zumbi",
      "woman zombie",
      "mujer zombi"
    ],
    "keywordsStr": "de fantasia fantasy fantasía mujer mulher person persona pessoa woman zombi zombie zumbi",
    "imgUrl": "assets/emojis/1f9df-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f486",
    "char": "💆",
    "name": "Person Getting Massage",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa recebendo massagem",
      "person getting massage",
      "persona recibiendo masaje"
    ],
    "keywordsStr": "actividad activity atividade da de getting la masaje massage massagem person persona pessoa recebendo recibiendo",
    "imgUrl": "assets/emojis/1f486.svg"
  },
  {
    "codes": "1f486-200d-2642-fe0f",
    "char": "💆‍♂️",
    "name": "Man Getting Massage",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem recebendo massagem",
      "man getting massage",
      "hombre recibiendo masaje"
    ],
    "keywordsStr": "actividad activity atividade da de getting hombre homem la man masaje massage massagem person persona pessoa recebendo recibiendo",
    "imgUrl": "assets/emojis/1f486-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f486-200d-2640-fe0f",
    "char": "💆‍♀️",
    "name": "Woman Getting Massage",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher recebendo massagem",
      "woman getting massage",
      "mujer recibiendo masaje"
    ],
    "keywordsStr": "actividad activity atividade da de getting la masaje massage massagem mujer mulher person persona pessoa recebendo recibiendo woman",
    "imgUrl": "assets/emojis/1f486-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f487",
    "char": "💇",
    "name": "Person Getting Haircut",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa cortando o cabelo",
      "person getting haircut",
      "persona cortándose el pelo"
    ],
    "keywordsStr": "actividad activity atividade cabelo cortando cortándose da de el getting haircut la o pelo person persona pessoa",
    "imgUrl": "assets/emojis/1f487.svg"
  },
  {
    "codes": "1f487-200d-2642-fe0f",
    "char": "💇‍♂️",
    "name": "Man Getting Haircut",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem cortando o cabelo",
      "man getting haircut",
      "hombre cortándose el pelo"
    ],
    "keywordsStr": "actividad activity atividade cabelo cortando cortándose da de el getting haircut hombre homem la man o pelo person persona pessoa",
    "imgUrl": "assets/emojis/1f487-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f487-200d-2640-fe0f",
    "char": "💇‍♀️",
    "name": "Woman Getting Haircut",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher cortando o cabelo",
      "woman getting haircut",
      "mujer cortándose el pelo"
    ],
    "keywordsStr": "actividad activity atividade cabelo cortando cortándose da de el getting haircut la mujer mulher o pelo person persona pessoa woman",
    "imgUrl": "assets/emojis/1f487-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f6b6",
    "char": "🚶",
    "name": "Person Walking",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa andando",
      "person walking",
      "persona caminando"
    ],
    "keywordsStr": "actividad activity andando atividade caminando da de la person persona pessoa walking",
    "imgUrl": "assets/emojis/1f6b6.svg"
  },
  {
    "codes": "1f6b6-200d-2642-fe0f",
    "char": "🚶‍♂️",
    "name": "Man Walking",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem andando",
      "man walking",
      "hombre caminando"
    ],
    "keywordsStr": "actividad activity andando atividade caminando da de hombre homem la man person persona pessoa walking",
    "imgUrl": "assets/emojis/1f6b6-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f6b6-200d-2640-fe0f",
    "char": "🚶‍♀️",
    "name": "Woman Walking",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher andando",
      "woman walking",
      "mujer caminando"
    ],
    "keywordsStr": "actividad activity andando atividade caminando da de la mujer mulher person persona pessoa walking woman",
    "imgUrl": "assets/emojis/1f6b6-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9cd",
    "char": "🧍",
    "name": "Person Standing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa em pé",
      "person standing",
      "persona de pie"
    ],
    "keywordsStr": "actividad activity atividade da de em la person persona pessoa pie pé standing",
    "imgUrl": "assets/emojis/1f9cd.svg"
  },
  {
    "codes": "1f9cd-200d-2642-fe0f",
    "char": "🧍‍♂️",
    "name": "Man Standing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem de pé",
      "man standing",
      "hombre de pie"
    ],
    "keywordsStr": "actividad activity atividade da de hombre homem la man person persona pessoa pie pé standing",
    "imgUrl": "assets/emojis/1f9cd-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9cd-200d-2640-fe0f",
    "char": "🧍‍♀️",
    "name": "Woman Standing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher em pé",
      "woman standing",
      "mujer de pie"
    ],
    "keywordsStr": "actividad activity atividade da de em la mujer mulher person persona pessoa pie pé standing woman",
    "imgUrl": "assets/emojis/1f9cd-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9ce",
    "char": "🧎",
    "name": "Person Kneeling",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa ajoelhada",
      "person kneeling",
      "persona arrodillada"
    ],
    "keywordsStr": "actividad activity ajoelhada arrodillada atividade da de kneeling la person persona pessoa",
    "imgUrl": "assets/emojis/1f9ce.svg"
  },
  {
    "codes": "1f9ce-200d-2642-fe0f",
    "char": "🧎‍♂️",
    "name": "Man Kneeling",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem ajoelhado",
      "man kneeling",
      "hombre arrodillado"
    ],
    "keywordsStr": "actividad activity ajoelhado arrodillado atividade da de hombre homem kneeling la man person persona pessoa",
    "imgUrl": "assets/emojis/1f9ce-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9ce-200d-2640-fe0f",
    "char": "🧎‍♀️",
    "name": "Woman Kneeling",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher ajoelhada",
      "woman kneeling",
      "mujer arrodillada"
    ],
    "keywordsStr": "actividad activity ajoelhada arrodillada atividade da de kneeling la mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9ce-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d1-200d-1f9af",
    "char": "🧑‍🦯",
    "name": "Person With White Cane",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa com bengala branca",
      "person with white cane",
      "persona con bastón blanco"
    ],
    "keywordsStr": "actividad activity atividade bastón bengala blanco branca cane com con da de la person persona pessoa white with",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9af.svg"
  },
  {
    "codes": "1f468-200d-1f9af",
    "char": "👨‍🦯",
    "name": "Man With White Cane",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem com bengala branca",
      "man with white cane",
      "hombre con bastón blanco"
    ],
    "keywordsStr": "actividad activity atividade bastón bengala blanco branca cane com con da de hombre homem la man person persona pessoa white with",
    "imgUrl": "assets/emojis/1f468-200d-1f9af.svg"
  },
  {
    "codes": "1f469-200d-1f9af",
    "char": "👩‍🦯",
    "name": "Woman With White Cane",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher com bengala branca",
      "woman with white cane",
      "mujer con bastón blanco"
    ],
    "keywordsStr": "actividad activity atividade bastón bengala blanco branca cane com con da de la mujer mulher person persona pessoa white with woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9af.svg"
  },
  {
    "codes": "1f9d1-200d-1f9bc",
    "char": "🧑‍🦼",
    "name": "Person In Motorized Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa em cadeira de rodas motorizada",
      "person in motorized wheelchair",
      "persona en silla de ruedas motorizada"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en in la motorizada motorized person persona pessoa rodas ruedas silla wheelchair",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9bc.svg"
  },
  {
    "codes": "1f468-200d-1f9bc",
    "char": "👨‍🦼",
    "name": "Man In Motorized Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem em cadeira de rodas motorizada",
      "man in motorized wheelchair",
      "hombre en silla de ruedas motorizada"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en hombre homem in la man motorizada motorized person persona pessoa rodas ruedas silla wheelchair",
    "imgUrl": "assets/emojis/1f468-200d-1f9bc.svg"
  },
  {
    "codes": "1f469-200d-1f9bc",
    "char": "👩‍🦼",
    "name": "Woman In Motorized Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher em cadeira de rodas motorizada",
      "woman in motorized wheelchair",
      "mujer en silla de ruedas motorizada"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en in la motorizada motorized mujer mulher person persona pessoa rodas ruedas silla wheelchair woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9bc.svg"
  },
  {
    "codes": "1f9d1-200d-1f9bd",
    "char": "🧑‍🦽",
    "name": "Person In Manual Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa em cadeira de rodas manual",
      "person in manual wheelchair",
      "persona en silla de ruedas manual"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en in la manual person persona pessoa rodas ruedas silla wheelchair",
    "imgUrl": "assets/emojis/1f9d1-200d-1f9bd.svg"
  },
  {
    "codes": "1f468-200d-1f9bd",
    "char": "👨‍🦽",
    "name": "Man In Manual Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem em cadeira de rodas manual",
      "man in manual wheelchair",
      "hombre en silla de ruedas manual"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en hombre homem in la man manual person persona pessoa rodas ruedas silla wheelchair",
    "imgUrl": "assets/emojis/1f468-200d-1f9bd.svg"
  },
  {
    "codes": "1f469-200d-1f9bd",
    "char": "👩‍🦽",
    "name": "Woman In Manual Wheelchair",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher em cadeira de rodas manual",
      "woman in manual wheelchair",
      "mujer en silla de ruedas manual"
    ],
    "keywordsStr": "actividad activity atividade cadeira da de em en in la manual mujer mulher person persona pessoa rodas ruedas silla wheelchair woman",
    "imgUrl": "assets/emojis/1f469-200d-1f9bd.svg"
  },
  {
    "codes": "1f3c3",
    "char": "🏃",
    "name": "Person Running",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa correndo",
      "person running",
      "persona corriendo"
    ],
    "keywordsStr": "actividad activity atividade correndo corriendo da de la person persona pessoa running",
    "imgUrl": "assets/emojis/1f3c3.svg"
  },
  {
    "codes": "1f3c3-200d-2642-fe0f",
    "char": "🏃‍♂️",
    "name": "Man Running",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem correndo",
      "man running",
      "hombre corriendo"
    ],
    "keywordsStr": "actividad activity atividade correndo corriendo da de hombre homem la man person persona pessoa running",
    "imgUrl": "assets/emojis/1f3c3-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f3c3-200d-2640-fe0f",
    "char": "🏃‍♀️",
    "name": "Woman Running",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher correndo",
      "woman running",
      "mujer corriendo"
    ],
    "keywordsStr": "actividad activity atividade correndo corriendo da de la mujer mulher person persona pessoa running woman",
    "imgUrl": "assets/emojis/1f3c3-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f483",
    "char": "💃",
    "name": "Woman Dancing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher dançando",
      "woman dancing",
      "mujer bailando"
    ],
    "keywordsStr": "actividad activity atividade bailando da dancing dançando de la mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f483.svg"
  },
  {
    "codes": "1f57a",
    "char": "🕺",
    "name": "Man Dancing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem dançando",
      "man dancing",
      "hombre bailando"
    ],
    "keywordsStr": "actividad activity atividade bailando da dancing dançando de hombre homem la man person persona pessoa",
    "imgUrl": "assets/emojis/1f57a.svg"
  },
  {
    "codes": "1f574",
    "char": "🕴️",
    "name": "Person In Suit Levitating",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa de terno levitando",
      "person in suit levitating",
      "persona en traje levitando"
    ],
    "keywordsStr": "actividad activity atividade da de en in la levitando levitating person persona pessoa suit terno traje",
    "imgUrl": "assets/emojis/1f574.svg"
  },
  {
    "codes": "1f46f",
    "char": "👯",
    "name": "People With Bunny Ears",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoas com orelhas de coelho",
      "people with bunny ears",
      "personas con orejas de conejo"
    ],
    "keywordsStr": "actividad activity atividade bunny coelho com con conejo da de ears la orejas orelhas people person persona personas pessoa pessoas with",
    "imgUrl": "assets/emojis/1f46f.svg"
  },
  {
    "codes": "1f46f-200d-2642-fe0f",
    "char": "👯‍♂️",
    "name": "Men With Bunny Ears",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homens com orelhas de coelho",
      "men with bunny ears",
      "hombres con orejas de conejo"
    ],
    "keywordsStr": "actividad activity atividade bunny coelho com con conejo da de ears hombres homens la men orejas orelhas person persona pessoa with",
    "imgUrl": "assets/emojis/1f46f-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f46f-200d-2640-fe0f",
    "char": "👯‍♀️",
    "name": "Women With Bunny Ears",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulheres com orelhas de coelho",
      "women with bunny ears",
      "mujeres con orejas de conejo"
    ],
    "keywordsStr": "actividad activity atividade bunny coelho com con conejo da de ears la mujeres mulheres orejas orelhas person persona pessoa with women",
    "imgUrl": "assets/emojis/1f46f-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d6",
    "char": "🧖",
    "name": "Person In Steamy Room",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa na sala cheia de vapor",
      "person in steamy room",
      "persona en una habitación llena de vapor"
    ],
    "keywordsStr": "actividad activity atividade cheia da de en habitación in la llena na person persona pessoa room sala steamy una vapor",
    "imgUrl": "assets/emojis/1f9d6.svg"
  },
  {
    "codes": "1f9d6-200d-2642-fe0f",
    "char": "🧖‍♂️",
    "name": "Man In Steamy Room",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem na sala cheia de vapor",
      "man in steamy room",
      "hombre en una habitación llena de vapor"
    ],
    "keywordsStr": "actividad activity atividade cheia da de en habitación hombre homem in la llena man na person persona pessoa room sala steamy una vapor",
    "imgUrl": "assets/emojis/1f9d6-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d6-200d-2640-fe0f",
    "char": "🧖‍♀️",
    "name": "Woman In Steamy Room",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher na sala cheia de vapor",
      "woman in steamy room",
      "mujer en cuarto de vapor"
    ],
    "keywordsStr": "actividad activity atividade cheia cuarto da de en in la mujer mulher na person persona pessoa room sala steamy vapor woman",
    "imgUrl": "assets/emojis/1f9d6-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d7",
    "char": "🧗",
    "name": "Person Climbing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "pessoa escalada",
      "person climbing",
      "persona escalando"
    ],
    "keywordsStr": "actividad activity atividade climbing da de escalada escalando la person persona pessoa",
    "imgUrl": "assets/emojis/1f9d7.svg"
  },
  {
    "codes": "1f9d7-200d-2642-fe0f",
    "char": "🧗‍♂️",
    "name": "Man Climbing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "homem escalando",
      "man climbing",
      "hombre escalando"
    ],
    "keywordsStr": "actividad activity atividade climbing da de escalando hombre homem la man person persona pessoa",
    "imgUrl": "assets/emojis/1f9d7-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d7-200d-2640-fe0f",
    "char": "🧗‍♀️",
    "name": "Woman Climbing",
    "category": "People & Body (person-activity)",
    "group": "peopleAndBody",
    "subgroup": "person-activity",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "atividade",
      "activity",
      "actividad",
      "atividade da pessoa",
      "person activity",
      "actividad de la persona",
      "mulher escalada",
      "woman climbing",
      "mujer escalando"
    ],
    "keywordsStr": "actividad activity atividade climbing da de escalada escalando la mujer mulher person persona pessoa woman",
    "imgUrl": "assets/emojis/1f9d7-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f93a",
    "char": "🤺",
    "name": "Person Fencing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "esgrima de pessoa",
      "person fencing",
      "persona esgrima"
    ],
    "keywordsStr": "de deporte esgrima esporte fencing person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f93a.svg"
  },
  {
    "codes": "1f3c7",
    "char": "🏇",
    "name": "Horse Racing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "corrida de cavalos",
      "horse racing",
      "carreras de caballos"
    ],
    "keywordsStr": "caballos carreras cavalos corrida de deporte esporte horse person persona pessoa pessoal racing sport",
    "imgUrl": "assets/emojis/1f3c7.svg"
  },
  {
    "codes": "26f7",
    "char": "⛷️",
    "name": "Skier",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "esquiador",
      "skier"
    ],
    "keywordsStr": "deporte esporte esquiador person persona pessoa pessoal skier sport",
    "imgUrl": "assets/emojis/26f7.svg"
  },
  {
    "codes": "1f3c2",
    "char": "🏂",
    "name": "Snowboarder",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "snowboard",
      "snowboarder"
    ],
    "keywordsStr": "deporte esporte person persona pessoa pessoal snowboard snowboarder sport",
    "imgUrl": "assets/emojis/1f3c2.svg"
  },
  {
    "codes": "1f3cc",
    "char": "🏌️",
    "name": "Person Golfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa de golfe",
      "person golfing",
      "persona jugando al golf"
    ],
    "keywordsStr": "al de deporte esporte golf golfe golfing jugando person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f3cc.svg"
  },
  {
    "codes": "1f3cc-fe0f-200d-2642-fe0f",
    "char": "🏌️‍♂️",
    "name": "Man Golfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem de golfe",
      "man golfing",
      "hombre jugando al golf"
    ],
    "keywordsStr": "al de deporte esporte golf golfe golfing hombre homem jugando man person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f3cc-fe0f-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f3cc-fe0f-200d-2640-fe0f",
    "char": "🏌️‍♀️",
    "name": "Woman Golfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher golfe",
      "woman golfing",
      "mujer jugando al golf"
    ],
    "keywordsStr": "al deporte esporte golf golfe golfing jugando mujer mulher person persona pessoa pessoal sport woman",
    "imgUrl": "assets/emojis/1f3cc-fe0f-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f3c4",
    "char": "🏄",
    "name": "Person Surfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa surfando",
      "person surfing",
      "persona surfeando"
    ],
    "keywordsStr": "deporte esporte person persona pessoa pessoal sport surfando surfeando surfing",
    "imgUrl": "assets/emojis/1f3c4.svg"
  },
  {
    "codes": "1f3c4-200d-2642-fe0f",
    "char": "🏄‍♂️",
    "name": "Man Surfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem surfando",
      "man surfing",
      "hombre surfeando"
    ],
    "keywordsStr": "deporte esporte hombre homem man person persona pessoa pessoal sport surfando surfeando surfing",
    "imgUrl": "assets/emojis/1f3c4-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f3c4-200d-2640-fe0f",
    "char": "🏄‍♀️",
    "name": "Woman Surfing",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher surfando",
      "woman surfing",
      "mujer surfeando"
    ],
    "keywordsStr": "deporte esporte mujer mulher person persona pessoa pessoal sport surfando surfeando surfing woman",
    "imgUrl": "assets/emojis/1f3c4-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f6a3",
    "char": "🚣",
    "name": "Person Rowing Boat",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "barco a remo pessoa",
      "person rowing boat",
      "persona bote de remos"
    ],
    "keywordsStr": "a barco boat bote de deporte esporte person persona pessoa pessoal remo remos rowing sport",
    "imgUrl": "assets/emojis/1f6a3.svg"
  },
  {
    "codes": "1f6a3-200d-2642-fe0f",
    "char": "🚣‍♂️",
    "name": "Man Rowing Boat",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem barco a remo",
      "man rowing boat",
      "hombre bote de remos"
    ],
    "keywordsStr": "a barco boat bote de deporte esporte hombre homem man person persona pessoa pessoal remo remos rowing sport",
    "imgUrl": "assets/emojis/1f6a3-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f6a3-200d-2640-fe0f",
    "char": "🚣‍♀️",
    "name": "Woman Rowing Boat",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "barco a remo mulher",
      "woman rowing boat",
      "mujer bote de remos"
    ],
    "keywordsStr": "a barco boat bote de deporte esporte mujer mulher person persona pessoa pessoal remo remos rowing sport woman",
    "imgUrl": "assets/emojis/1f6a3-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f3ca",
    "char": "🏊",
    "name": "Person Swimming",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa nadando",
      "person swimming",
      "persona nadando"
    ],
    "keywordsStr": "deporte esporte nadando person persona pessoa pessoal sport swimming",
    "imgUrl": "assets/emojis/1f3ca.svg"
  },
  {
    "codes": "1f3ca-200d-2642-fe0f",
    "char": "🏊‍♂️",
    "name": "Man Swimming",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem nadando",
      "man swimming",
      "hombre nadando"
    ],
    "keywordsStr": "deporte esporte hombre homem man nadando person persona pessoa pessoal sport swimming",
    "imgUrl": "assets/emojis/1f3ca-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f3ca-200d-2640-fe0f",
    "char": "🏊‍♀️",
    "name": "Woman Swimming",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher nadando",
      "woman swimming",
      "mujer nadando"
    ],
    "keywordsStr": "deporte esporte mujer mulher nadando person persona pessoa pessoal sport swimming woman",
    "imgUrl": "assets/emojis/1f3ca-200d-2640-fe0f.svg"
  },
  {
    "codes": "26f9",
    "char": "⛹️",
    "name": "Person Bouncing Ball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa quicando bola",
      "person bouncing ball",
      "persona que bota la pelota"
    ],
    "keywordsStr": "ball bola bota bouncing deporte esporte la pelota person persona pessoa pessoal que quicando sport",
    "imgUrl": "assets/emojis/26f9.svg"
  },
  {
    "codes": "26f9-fe0f-200d-2642-fe0f",
    "char": "⛹️‍♂️",
    "name": "Man Bouncing Ball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem quicando bola",
      "man bouncing ball",
      "hombre botando la pelota"
    ],
    "keywordsStr": "ball bola botando bouncing deporte esporte hombre homem la man pelota person persona pessoa pessoal quicando sport",
    "imgUrl": "assets/emojis/26f9-fe0f-200d-2642-fe0f.svg"
  },
  {
    "codes": "26f9-fe0f-200d-2640-fe0f",
    "char": "⛹️‍♀️",
    "name": "Woman Bouncing Ball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher quicando bola",
      "woman bouncing ball",
      "mujer botando una pelota"
    ],
    "keywordsStr": "ball bola botando bouncing deporte esporte mujer mulher pelota person persona pessoa pessoal quicando sport una woman",
    "imgUrl": "assets/emojis/26f9-fe0f-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f3cb",
    "char": "🏋️",
    "name": "Person Lifting Weights",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa levantando pesos",
      "person lifting weights",
      "persona levantando pesas"
    ],
    "keywordsStr": "deporte esporte levantando lifting person persona pesas pesos pessoa pessoal sport weights",
    "imgUrl": "assets/emojis/1f3cb.svg"
  },
  {
    "codes": "1f3cb-fe0f-200d-2642-fe0f",
    "char": "🏋️‍♂️",
    "name": "Man Lifting Weights",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem levantando pesos",
      "man lifting weights",
      "hombre levantando pesas"
    ],
    "keywordsStr": "deporte esporte hombre homem levantando lifting man person persona pesas pesos pessoa pessoal sport weights",
    "imgUrl": "assets/emojis/1f3cb-fe0f-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f3cb-fe0f-200d-2640-fe0f",
    "char": "🏋️‍♀️",
    "name": "Woman Lifting Weights",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher levantando pesos",
      "woman lifting weights",
      "mujer levantando pesas"
    ],
    "keywordsStr": "deporte esporte levantando lifting mujer mulher person persona pesas pesos pessoa pessoal sport weights woman",
    "imgUrl": "assets/emojis/1f3cb-fe0f-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f6b4",
    "char": "🚴",
    "name": "Person Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa de bicicleta",
      "person biking",
      "persona en bicicleta"
    ],
    "keywordsStr": "bicicleta biking de deporte en esporte person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f6b4.svg"
  },
  {
    "codes": "1f6b4-200d-2642-fe0f",
    "char": "🚴‍♂️",
    "name": "Man Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem de bicicleta",
      "man biking",
      "hombre en bicicleta"
    ],
    "keywordsStr": "bicicleta biking de deporte en esporte hombre homem man person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f6b4-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f6b4-200d-2640-fe0f",
    "char": "🚴‍♀️",
    "name": "Woman Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher de bicicleta",
      "woman biking",
      "mujer en bicicleta"
    ],
    "keywordsStr": "bicicleta biking de deporte en esporte mujer mulher person persona pessoa pessoal sport woman",
    "imgUrl": "assets/emojis/1f6b4-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f6b5",
    "char": "🚵",
    "name": "Person Mountain Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa mountain bike",
      "person mountain biking",
      "persona en bicicleta de montaña"
    ],
    "keywordsStr": "bicicleta bike biking de deporte en esporte montaña mountain person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f6b5.svg"
  },
  {
    "codes": "1f6b5-200d-2642-fe0f",
    "char": "🚵‍♂️",
    "name": "Man Mountain Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem mountain bike",
      "man mountain biking",
      "hombre en bicicleta de montaña"
    ],
    "keywordsStr": "bicicleta bike biking de deporte en esporte hombre homem man montaña mountain person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f6b5-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f6b5-200d-2640-fe0f",
    "char": "🚵‍♀️",
    "name": "Woman Mountain Biking",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher mountain bike",
      "woman mountain biking",
      "mujer en bicicleta de montaña"
    ],
    "keywordsStr": "bicicleta bike biking de deporte en esporte montaña mountain mujer mulher person persona pessoa pessoal sport woman",
    "imgUrl": "assets/emojis/1f6b5-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f938",
    "char": "🤸",
    "name": "Person Cartwheeling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa dando cambalhotas",
      "person cartwheeling",
      "persona dando volteretas"
    ],
    "keywordsStr": "cambalhotas cartwheeling dando deporte esporte person persona pessoa pessoal sport volteretas",
    "imgUrl": "assets/emojis/1f938.svg"
  },
  {
    "codes": "1f938-200d-2642-fe0f",
    "char": "🤸‍♂️",
    "name": "Man Cartwheeling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem dando cambalhotas",
      "man cartwheeling",
      "hombre dando volteretas"
    ],
    "keywordsStr": "cambalhotas cartwheeling dando deporte esporte hombre homem man person persona pessoa pessoal sport volteretas",
    "imgUrl": "assets/emojis/1f938-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f938-200d-2640-fe0f",
    "char": "🤸‍♀️",
    "name": "Woman Cartwheeling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher dando cambalhotas",
      "woman cartwheeling",
      "mujer dando volteretas"
    ],
    "keywordsStr": "cambalhotas cartwheeling dando deporte esporte mujer mulher person persona pessoa pessoal sport volteretas woman",
    "imgUrl": "assets/emojis/1f938-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f93c",
    "char": "🤼",
    "name": "People Wrestling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "luta de pessoas",
      "people wrestling",
      "gente luchando"
    ],
    "keywordsStr": "de deporte esporte gente luchando luta people person persona pessoa pessoal pessoas sport wrestling",
    "imgUrl": "assets/emojis/1f93c.svg"
  },
  {
    "codes": "1f93c-200d-2642-fe0f",
    "char": "🤼‍♂️",
    "name": "Men Wrestling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homens lutando",
      "men wrestling",
      "hombres luchando"
    ],
    "keywordsStr": "deporte esporte hombres homens luchando lutando men person persona pessoa pessoal sport wrestling",
    "imgUrl": "assets/emojis/1f93c-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f93c-200d-2640-fe0f",
    "char": "🤼‍♀️",
    "name": "Women Wrestling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "luta feminina",
      "women wrestling",
      "mujeres luchando"
    ],
    "keywordsStr": "deporte esporte feminina luchando luta mujeres person persona pessoa pessoal sport women wrestling",
    "imgUrl": "assets/emojis/1f93c-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f93d",
    "char": "🤽",
    "name": "Person Playing Water Polo",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa jogando pólo aquático",
      "person playing water polo",
      "persona jugando waterpolo"
    ],
    "keywordsStr": "aquático deporte esporte jogando jugando person persona pessoa pessoal playing polo pólo sport water waterpolo",
    "imgUrl": "assets/emojis/1f93d.svg"
  },
  {
    "codes": "1f93d-200d-2642-fe0f",
    "char": "🤽‍♂️",
    "name": "Man Playing Water Polo",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem jogando pólo aquático",
      "man playing water polo",
      "hombre jugando al waterpolo"
    ],
    "keywordsStr": "al aquático deporte esporte hombre homem jogando jugando man person persona pessoa pessoal playing polo pólo sport water waterpolo",
    "imgUrl": "assets/emojis/1f93d-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f93d-200d-2640-fe0f",
    "char": "🤽‍♀️",
    "name": "Woman Playing Water Polo",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher jogando pólo aquático",
      "woman playing water polo",
      "mujer jugando al waterpolo"
    ],
    "keywordsStr": "al aquático deporte esporte jogando jugando mujer mulher person persona pessoa pessoal playing polo pólo sport water waterpolo woman",
    "imgUrl": "assets/emojis/1f93d-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f93e",
    "char": "🤾",
    "name": "Person Playing Handball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa jogando handebol",
      "person playing handball",
      "persona jugando balonmano"
    ],
    "keywordsStr": "balonmano deporte esporte handball handebol jogando jugando person persona pessoa pessoal playing sport",
    "imgUrl": "assets/emojis/1f93e.svg"
  },
  {
    "codes": "1f93e-200d-2642-fe0f",
    "char": "🤾‍♂️",
    "name": "Man Playing Handball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem jogando handebol",
      "man playing handball",
      "hombre jugando balonmano"
    ],
    "keywordsStr": "balonmano deporte esporte handball handebol hombre homem jogando jugando man person persona pessoa pessoal playing sport",
    "imgUrl": "assets/emojis/1f93e-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f93e-200d-2640-fe0f",
    "char": "🤾‍♀️",
    "name": "Woman Playing Handball",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher jogando handebol",
      "woman playing handball",
      "mujer jugando balonmano"
    ],
    "keywordsStr": "balonmano deporte esporte handball handebol jogando jugando mujer mulher person persona pessoa pessoal playing sport woman",
    "imgUrl": "assets/emojis/1f93e-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f939",
    "char": "🤹",
    "name": "Person Juggling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "pessoa malabarismo",
      "person juggling",
      "persona haciendo malabares"
    ],
    "keywordsStr": "deporte esporte haciendo juggling malabares malabarismo person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f939.svg"
  },
  {
    "codes": "1f939-200d-2642-fe0f",
    "char": "🤹‍♂️",
    "name": "Man Juggling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "homem malabarismo",
      "man juggling",
      "hombre haciendo malabares"
    ],
    "keywordsStr": "deporte esporte haciendo hombre homem juggling malabares malabarismo man person persona pessoa pessoal sport",
    "imgUrl": "assets/emojis/1f939-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f939-200d-2640-fe0f",
    "char": "🤹‍♀️",
    "name": "Woman Juggling",
    "category": "People & Body (person-sport)",
    "group": "peopleAndBody",
    "subgroup": "person-sport",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "esporte",
      "sport",
      "deporte",
      "esporte pessoal",
      "person sport",
      "deporte persona",
      "mulher malabarismo",
      "woman juggling",
      "mujer haciendo malabares"
    ],
    "keywordsStr": "deporte esporte haciendo juggling malabares malabarismo mujer mulher person persona pessoa pessoal sport woman",
    "imgUrl": "assets/emojis/1f939-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f9d8",
    "char": "🧘",
    "name": "Person In Lotus Position",
    "category": "People & Body (person-resting)",
    "group": "peopleAndBody",
    "subgroup": "person-resting",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "descansando",
      "resting",
      "pessoa descansando",
      "person resting",
      "persona descansando",
      "pessoa em posição de lótus",
      "person in lotus position",
      "persona en posición de loto"
    ],
    "keywordsStr": "de descansando em en in loto lotus lótus person persona pessoa posición position posição resting",
    "imgUrl": "assets/emojis/1f9d8.svg"
  },
  {
    "codes": "1f9d8-200d-2642-fe0f",
    "char": "🧘‍♂️",
    "name": "Man In Lotus Position",
    "category": "People & Body (person-resting)",
    "group": "peopleAndBody",
    "subgroup": "person-resting",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "descansando",
      "resting",
      "pessoa descansando",
      "person resting",
      "persona descansando",
      "homem em posição de lótus",
      "man in lotus position",
      "hombre en posición de loto"
    ],
    "keywordsStr": "de descansando em en hombre homem in loto lotus lótus man person persona pessoa posición position posição resting",
    "imgUrl": "assets/emojis/1f9d8-200d-2642-fe0f.svg"
  },
  {
    "codes": "1f9d8-200d-2640-fe0f",
    "char": "🧘‍♀️",
    "name": "Woman In Lotus Position",
    "category": "People & Body (person-resting)",
    "group": "peopleAndBody",
    "subgroup": "person-resting",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "descansando",
      "resting",
      "pessoa descansando",
      "person resting",
      "persona descansando",
      "mulher em posição de lótus",
      "woman in lotus position",
      "mujer en posición de loto"
    ],
    "keywordsStr": "de descansando em en in loto lotus lótus mujer mulher person persona pessoa posición position posição resting woman",
    "imgUrl": "assets/emojis/1f9d8-200d-2640-fe0f.svg"
  },
  {
    "codes": "1f6c0",
    "char": "🛀",
    "name": "Person Taking Bath",
    "category": "People & Body (person-resting)",
    "group": "peopleAndBody",
    "subgroup": "person-resting",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "descansando",
      "resting",
      "pessoa descansando",
      "person resting",
      "persona descansando",
      "pessoa tomando banho",
      "person taking bath",
      "persona bañándose"
    ],
    "keywordsStr": "banho bath bañándose descansando person persona pessoa resting taking tomando",
    "imgUrl": "assets/emojis/1f6c0.svg"
  },
  {
    "codes": "1f6cc",
    "char": "🛌",
    "name": "Person In Bed",
    "category": "People & Body (person-resting)",
    "group": "peopleAndBody",
    "subgroup": "person-resting",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "descansando",
      "resting",
      "pessoa descansando",
      "person resting",
      "persona descansando",
      "pessoa na cama",
      "person in bed",
      "persona en la cama"
    ],
    "keywordsStr": "bed cama descansando en in la na person persona pessoa resting",
    "imgUrl": "assets/emojis/1f6cc.svg"
  },
  {
    "codes": "1f9d1-200d-1f91d-200d-1f9d1",
    "char": "🧑‍🤝‍🧑",
    "name": "People Holding Hands",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "pessoas de mãos dadas",
      "people holding hands",
      "personas cogidas de la mano"
    ],
    "keywordsStr": "cogidas dadas de familia family família hands holding la mano mãos people personas pessoas",
    "imgUrl": "assets/emojis/1f9d1-200d-1f91d-200d-1f9d1.svg"
  },
  {
    "codes": "1f46d",
    "char": "👭",
    "name": "Women Holding Hands",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "mulheres de mãos dadas",
      "women holding hands",
      "mujeres cogidas de la mano"
    ],
    "keywordsStr": "cogidas dadas de familia family família hands holding la mano mujeres mulheres mãos women",
    "imgUrl": "assets/emojis/1f46d.svg"
  },
  {
    "codes": "1f46b",
    "char": "👫",
    "name": "Woman And Man Holding Hands",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "mulher e homem de mãos dadas",
      "woman and man holding hands",
      "mujer y hombre tomados de la mano"
    ],
    "keywordsStr": "and dadas de e familia family família hands holding hombre homem la man mano mujer mulher mãos tomados woman y",
    "imgUrl": "assets/emojis/1f46b.svg"
  },
  {
    "codes": "1f46c",
    "char": "👬",
    "name": "Men Holding Hands",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "homens de mãos dadas",
      "men holding hands",
      "hombres tomados de la mano"
    ],
    "keywordsStr": "dadas de familia family família hands holding hombres homens la mano men mãos tomados",
    "imgUrl": "assets/emojis/1f46c.svg"
  },
  {
    "codes": "1f48f",
    "char": "💏",
    "name": "Kiss",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "beijo",
      "kiss",
      "beso"
    ],
    "keywordsStr": "beijo beso familia family família kiss",
    "imgUrl": "assets/emojis/1f48f.svg"
  },
  {
    "codes": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f468",
    "char": "👩‍❤️‍💋‍👨",
    "name": "Kiss Woman Man",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "beijo",
      "kiss",
      "beso"
    ],
    "keywordsStr": "beijo beso familia family família kiss",
    "imgUrl": "assets/emojis/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"
  },
  {
    "codes": "1f468-200d-2764-fe0f-200d-1f48b-200d-1f468",
    "char": "👨‍❤️‍💋‍👨",
    "name": "Kiss Man Man",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "beijo",
      "kiss",
      "beso"
    ],
    "keywordsStr": "beijo beso familia family família kiss",
    "imgUrl": "assets/emojis/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg"
  },
  {
    "codes": "1f469-200d-2764-fe0f-200d-1f48b-200d-1f469",
    "char": "👩‍❤️‍💋‍👩",
    "name": "Kiss Woman Woman",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "beijo",
      "kiss",
      "beso"
    ],
    "keywordsStr": "beijo beso familia family família kiss",
    "imgUrl": "assets/emojis/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg"
  },
  {
    "codes": "1f491",
    "char": "💑",
    "name": "Couple With Heart",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "casal com coração",
      "couple with heart",
      "pareja con corazón"
    ],
    "keywordsStr": "casal com con corazón coração couple familia family família heart pareja with",
    "imgUrl": "assets/emojis/1f491.svg"
  },
  {
    "codes": "1f469-200d-2764-fe0f-200d-1f468",
    "char": "👩‍❤️‍👨",
    "name": "Couple With Heart Woman Man",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "casal com coração",
      "couple with heart",
      "pareja con corazón"
    ],
    "keywordsStr": "casal com con corazón coração couple familia family família heart pareja with",
    "imgUrl": "assets/emojis/1f469-200d-2764-fe0f-200d-1f468.svg"
  },
  {
    "codes": "1f468-200d-2764-fe0f-200d-1f468",
    "char": "👨‍❤️‍👨",
    "name": "Couple With Heart Man Man",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "casal com coração",
      "couple with heart",
      "pareja con corazón"
    ],
    "keywordsStr": "casal com con corazón coração couple familia family família heart pareja with",
    "imgUrl": "assets/emojis/1f468-200d-2764-fe0f-200d-1f468.svg"
  },
  {
    "codes": "1f469-200d-2764-fe0f-200d-1f469",
    "char": "👩‍❤️‍👩",
    "name": "Couple With Heart Woman Woman",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia",
      "casal com coração",
      "couple with heart",
      "pareja con corazón"
    ],
    "keywordsStr": "casal com con corazón coração couple familia family família heart pareja with",
    "imgUrl": "assets/emojis/1f469-200d-2764-fe0f-200d-1f469.svg"
  },
  {
    "codes": "1f46a",
    "char": "👪",
    "name": "Family",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f46a.svg"
  },
  {
    "codes": "1f468-200d-1f469-200d-1f466",
    "char": "👨‍👩‍👦",
    "name": "Family Man Woman Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f469-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f469-200d-1f467",
    "char": "👨‍👩‍👧",
    "name": "Family Man Woman Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f469-200d-1f467.svg"
  },
  {
    "codes": "1f468-200d-1f469-200d-1f467-200d-1f466",
    "char": "👨‍👩‍👧‍👦",
    "name": "Family Man Woman Girl Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f469-200d-1f467-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f469-200d-1f466-200d-1f466",
    "char": "👨‍👩‍👦‍👦",
    "name": "Family Man Woman Boy Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f469-200d-1f466-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f469-200d-1f467-200d-1f467",
    "char": "👨‍👩‍👧‍👧",
    "name": "Family Man Woman Girl Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f469-200d-1f467-200d-1f467.svg"
  },
  {
    "codes": "1f468-200d-1f468-200d-1f466",
    "char": "👨‍👨‍👦",
    "name": "Family Man Man Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f468-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f468-200d-1f467",
    "char": "👨‍👨‍👧",
    "name": "Family Man Man Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f468-200d-1f467.svg"
  },
  {
    "codes": "1f468-200d-1f468-200d-1f467-200d-1f466",
    "char": "👨‍👨‍👧‍👦",
    "name": "Family Man Man Girl Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f468-200d-1f467-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f468-200d-1f466-200d-1f466",
    "char": "👨‍👨‍👦‍👦",
    "name": "Family Man Man Boy Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f468-200d-1f466-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f468-200d-1f467-200d-1f467",
    "char": "👨‍👨‍👧‍👧",
    "name": "Family Man Man Girl Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f468-200d-1f467-200d-1f467.svg"
  },
  {
    "codes": "1f469-200d-1f469-200d-1f466",
    "char": "👩‍👩‍👦",
    "name": "Family Woman Woman Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f469-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f469-200d-1f467",
    "char": "👩‍👩‍👧",
    "name": "Family Woman Woman Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f469-200d-1f467.svg"
  },
  {
    "codes": "1f469-200d-1f469-200d-1f467-200d-1f466",
    "char": "👩‍👩‍👧‍👦",
    "name": "Family Woman Woman Girl Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f469-200d-1f467-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f469-200d-1f466-200d-1f466",
    "char": "👩‍👩‍👦‍👦",
    "name": "Family Woman Woman Boy Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f469-200d-1f466-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f469-200d-1f467-200d-1f467",
    "char": "👩‍👩‍👧‍👧",
    "name": "Family Woman Woman Girl Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f469-200d-1f467-200d-1f467.svg"
  },
  {
    "codes": "1f468-200d-1f466",
    "char": "👨‍👦",
    "name": "Family Man Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f466-200d-1f466",
    "char": "👨‍👦‍👦",
    "name": "Family Man Boy Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f466-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f467",
    "char": "👨‍👧",
    "name": "Family Man Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f467.svg"
  },
  {
    "codes": "1f468-200d-1f467-200d-1f466",
    "char": "👨‍👧‍👦",
    "name": "Family Man Girl Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f467-200d-1f466.svg"
  },
  {
    "codes": "1f468-200d-1f467-200d-1f467",
    "char": "👨‍👧‍👧",
    "name": "Family Man Girl Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f468-200d-1f467-200d-1f467.svg"
  },
  {
    "codes": "1f469-200d-1f466",
    "char": "👩‍👦",
    "name": "Family Woman Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f466-200d-1f466",
    "char": "👩‍👦‍👦",
    "name": "Family Woman Boy Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f466-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f467",
    "char": "👩‍👧",
    "name": "Family Woman Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f467.svg"
  },
  {
    "codes": "1f469-200d-1f467-200d-1f466",
    "char": "👩‍👧‍👦",
    "name": "Family Woman Girl Boy",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f467-200d-1f466.svg"
  },
  {
    "codes": "1f469-200d-1f467-200d-1f467",
    "char": "👩‍👧‍👧",
    "name": "Family Woman Girl Girl",
    "category": "People & Body (family)",
    "group": "peopleAndBody",
    "subgroup": "family",
    "keywords": [
      "família",
      "family",
      "familia"
    ],
    "keywordsStr": "familia family família",
    "imgUrl": "assets/emojis/1f469-200d-1f467-200d-1f467.svg"
  },
  {
    "codes": "1f5e3",
    "char": "🗣️",
    "name": "Speaking Head",
    "category": "People & Body (person-symbol)",
    "group": "peopleAndBody",
    "subgroup": "person-symbol",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "símbolo",
      "symbol",
      "símbolo de pessoa",
      "person symbol",
      "símbolo de persona",
      "cabeça falante",
      "speaking head",
      "cabeza parlante"
    ],
    "keywordsStr": "cabeza cabeça de falante head parlante person persona pessoa speaking symbol símbolo",
    "imgUrl": "assets/emojis/1f5e3.svg"
  },
  {
    "codes": "1f464",
    "char": "👤",
    "name": "Bust In Silhouette",
    "category": "People & Body (person-symbol)",
    "group": "peopleAndBody",
    "subgroup": "person-symbol",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "símbolo",
      "symbol",
      "símbolo de pessoa",
      "person symbol",
      "símbolo de persona",
      "busto em silhueta",
      "bust in silhouette",
      "busto en silueta"
    ],
    "keywordsStr": "bust busto de em en in person persona pessoa silhouette silhueta silueta symbol símbolo",
    "imgUrl": "assets/emojis/1f464.svg"
  },
  {
    "codes": "1f465",
    "char": "👥",
    "name": "Busts In Silhouette",
    "category": "People & Body (person-symbol)",
    "group": "peopleAndBody",
    "subgroup": "person-symbol",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "símbolo",
      "symbol",
      "símbolo de pessoa",
      "person symbol",
      "símbolo de persona",
      "bustos em silhueta",
      "busts in silhouette",
      "bustos en silueta"
    ],
    "keywordsStr": "bustos busts de em en in person persona pessoa silhouette silhueta silueta symbol símbolo",
    "imgUrl": "assets/emojis/1f465.svg"
  },
  {
    "codes": "1fac2",
    "char": "🫂",
    "name": "People Hugging",
    "category": "People & Body (person-symbol)",
    "group": "peopleAndBody",
    "subgroup": "person-symbol",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "símbolo",
      "symbol",
      "símbolo de pessoa",
      "person symbol",
      "símbolo de persona",
      "pessoas se abraçando",
      "people hugging",
      "gente abrazándose"
    ],
    "keywordsStr": "abrazándose abraçando de gente hugging people person persona pessoa pessoas se symbol símbolo",
    "imgUrl": "assets/emojis/1fac2.svg"
  },
  {
    "codes": "1f463",
    "char": "👣",
    "name": "Footprints",
    "category": "People & Body (person-symbol)",
    "group": "peopleAndBody",
    "subgroup": "person-symbol",
    "keywords": [
      "pessoa",
      "person",
      "persona",
      "símbolo",
      "symbol",
      "símbolo de pessoa",
      "person symbol",
      "símbolo de persona",
      "pegadas",
      "footprints",
      "huellas"
    ],
    "keywordsStr": "de footprints huellas pegadas person persona pessoa symbol símbolo",
    "imgUrl": "assets/emojis/1f463.svg"
  },
  {
    "codes": "1f435",
    "char": "🐵",
    "name": "Monkey Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de macaco",
      "monkey face",
      "cara de mono"
    ],
    "keywordsStr": "animal animales apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look macaco mammal mamífero monkey mono mug rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f435.svg"
  },
  {
    "codes": "1f412",
    "char": "🐒",
    "name": "Monkey",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "macaco",
      "monkey",
      "mono"
    ],
    "keywordsStr": "animal animales macaco mammal mamífero monkey mono",
    "imgUrl": "assets/emojis/1f412.svg"
  },
  {
    "codes": "1f98d",
    "char": "🦍",
    "name": "Gorilla",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "gorila",
      "gorilla"
    ],
    "keywordsStr": "animal animales gorila gorilla mammal mamífero",
    "imgUrl": "assets/emojis/1f98d.svg"
  },
  {
    "codes": "1f9a7",
    "char": "🦧",
    "name": "Orangutan",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "orangotango",
      "orangutan",
      "orangután"
    ],
    "keywordsStr": "animal animales mammal mamífero orangotango orangutan orangután",
    "imgUrl": "assets/emojis/1f9a7.svg"
  },
  {
    "codes": "1f436",
    "char": "🐶",
    "name": "Dog Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de cachorro",
      "dog face",
      "cara de perro"
    ],
    "keywordsStr": "animal animales apariencia cachorro cara countenance de dog expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mug perro rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f436.svg"
  },
  {
    "codes": "1f415",
    "char": "🐕",
    "name": "Dog",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cachorro",
      "dog",
      "perro"
    ],
    "keywordsStr": "animal animales cachorro dog mammal mamífero perro",
    "imgUrl": "assets/emojis/1f415.svg"
  },
  {
    "codes": "1f9ae",
    "char": "🦮",
    "name": "Guide Dog",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cachorro-guia",
      "guide dog",
      "perro guía"
    ],
    "keywordsStr": "animal animales cachorro-guia dog guide guía mammal mamífero perro",
    "imgUrl": "assets/emojis/1f9ae.svg"
  },
  {
    "codes": "1f415-200d-1f9ba",
    "char": "🐕‍🦺",
    "name": "Service Dog",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cachorro de serviço",
      "service dog",
      "perro de servicio"
    ],
    "keywordsStr": "animal animales cachorro de dog mammal mamífero perro service servicio serviço",
    "imgUrl": "assets/emojis/1f415-200d-1f9ba.svg"
  },
  {
    "codes": "1f429",
    "char": "🐩",
    "name": "Poodle",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "poodle",
      "caniche"
    ],
    "keywordsStr": "animal animales caniche mammal mamífero poodle",
    "imgUrl": "assets/emojis/1f429.svg"
  },
  {
    "codes": "1f43a",
    "char": "🐺",
    "name": "Wolf",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "lobo",
      "wolf"
    ],
    "keywordsStr": "animal animales lobo mammal mamífero wolf",
    "imgUrl": "assets/emojis/1f43a.svg"
  },
  {
    "codes": "1f98a",
    "char": "🦊",
    "name": "Fox",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "raposa",
      "fox",
      "zorro"
    ],
    "keywordsStr": "animal animales fox mammal mamífero raposa zorro",
    "imgUrl": "assets/emojis/1f98a.svg"
  },
  {
    "codes": "1f99d",
    "char": "🦝",
    "name": "Raccoon",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "guaxinim",
      "raccoon",
      "mapache"
    ],
    "keywordsStr": "animal animales guaxinim mammal mamífero mapache raccoon",
    "imgUrl": "assets/emojis/1f99d.svg"
  },
  {
    "codes": "1f431",
    "char": "🐱",
    "name": "Cat Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de gato",
      "cat face"
    ],
    "keywordsStr": "animal animales apariencia cara cat countenance de expresión expression expressão face faz features fisionomia fisonomía gato look mammal mamífero mug rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f431.svg"
  },
  {
    "codes": "1f408",
    "char": "🐈",
    "name": "Cat",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "gato",
      "cat"
    ],
    "keywordsStr": "animal animales cat gato mammal mamífero",
    "imgUrl": "assets/emojis/1f408.svg"
  },
  {
    "codes": "1f408-200d-2b1b",
    "char": "🐈‍⬛",
    "name": "Black Cat",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "gato preto",
      "black cat",
      "gato negro"
    ],
    "keywordsStr": "animal animales black cat gato mammal mamífero negro preto",
    "imgUrl": "assets/emojis/1f408-200d-2b1b.svg"
  },
  {
    "codes": "1f981",
    "char": "🦁",
    "name": "Lion",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "leão",
      "lion",
      "león"
    ],
    "keywordsStr": "animal animales leão león lion mammal mamífero",
    "imgUrl": "assets/emojis/1f981.svg"
  },
  {
    "codes": "1f42f",
    "char": "🐯",
    "name": "Tiger Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de tigre",
      "tiger face"
    ],
    "keywordsStr": "animal animales apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mug rosto rostro semblante tiger tigre visage",
    "imgUrl": "assets/emojis/1f42f.svg"
  },
  {
    "codes": "1f405",
    "char": "🐅",
    "name": "Tiger",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "tigre",
      "tiger"
    ],
    "keywordsStr": "animal animales mammal mamífero tiger tigre",
    "imgUrl": "assets/emojis/1f405.svg"
  },
  {
    "codes": "1f406",
    "char": "🐆",
    "name": "Leopard",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "leopardo",
      "leopard"
    ],
    "keywordsStr": "animal animales leopard leopardo mammal mamífero",
    "imgUrl": "assets/emojis/1f406.svg"
  },
  {
    "codes": "1f434",
    "char": "🐴",
    "name": "Horse Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de cavalo",
      "horse face",
      "cara de caballo"
    ],
    "keywordsStr": "animal animales apariencia caballo cara cavalo countenance de expresión expression expressão face faz features fisionomia fisonomía horse look mammal mamífero mug rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f434.svg"
  },
  {
    "codes": "1f40e",
    "char": "🐎",
    "name": "Horse",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cavalo",
      "horse",
      "caballo"
    ],
    "keywordsStr": "animal animales caballo cavalo horse mammal mamífero",
    "imgUrl": "assets/emojis/1f40e.svg"
  },
  {
    "codes": "1f984",
    "char": "🦄",
    "name": "Unicorn",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "unicórnio",
      "unicorn",
      "unicornio"
    ],
    "keywordsStr": "animal animales mammal mamífero unicorn unicornio unicórnio",
    "imgUrl": "assets/emojis/1f984.svg"
  },
  {
    "codes": "1f993",
    "char": "🦓",
    "name": "Zebra",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "zebra",
      "cebra"
    ],
    "keywordsStr": "animal animales cebra mammal mamífero zebra",
    "imgUrl": "assets/emojis/1f993.svg"
  },
  {
    "codes": "1f98c",
    "char": "🦌",
    "name": "Deer",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "veado",
      "deer",
      "ciervo"
    ],
    "keywordsStr": "animal animales ciervo deer mammal mamífero veado",
    "imgUrl": "assets/emojis/1f98c.svg"
  },
  {
    "codes": "1f9ac",
    "char": "🦬",
    "name": "Bison",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "bisonte",
      "bison"
    ],
    "keywordsStr": "animal animales bison bisonte mammal mamífero",
    "imgUrl": "assets/emojis/1f9ac.svg"
  },
  {
    "codes": "1f42e",
    "char": "🐮",
    "name": "Cow Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de vaca",
      "cow face"
    ],
    "keywordsStr": "animal animales apariencia cara countenance cow de expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mug rosto rostro semblante vaca visage",
    "imgUrl": "assets/emojis/1f42e.svg"
  },
  {
    "codes": "1f402",
    "char": "🐂",
    "name": "Ox",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "boi",
      "ox",
      "buey"
    ],
    "keywordsStr": "animal animales boi buey mammal mamífero ox",
    "imgUrl": "assets/emojis/1f402.svg"
  },
  {
    "codes": "1f403",
    "char": "🐃",
    "name": "Water Buffalo",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "búfalo d'água",
      "water buffalo",
      "búfalo de agua"
    ],
    "keywordsStr": "agua animal animales buffalo búfalo d'água de mammal mamífero water",
    "imgUrl": "assets/emojis/1f403.svg"
  },
  {
    "codes": "1f404",
    "char": "🐄",
    "name": "Cow",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "vaca",
      "cow"
    ],
    "keywordsStr": "animal animales cow mammal mamífero vaca",
    "imgUrl": "assets/emojis/1f404.svg"
  },
  {
    "codes": "1f437",
    "char": "🐷",
    "name": "Pig Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de porco",
      "pig face",
      "cara de cerdo"
    ],
    "keywordsStr": "animal animales apariencia cara cerdo countenance de expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mug pig porco rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f437.svg"
  },
  {
    "codes": "1f416",
    "char": "🐖",
    "name": "Pig",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "porco",
      "pig",
      "cerdo"
    ],
    "keywordsStr": "animal animales cerdo mammal mamífero pig porco",
    "imgUrl": "assets/emojis/1f416.svg"
  },
  {
    "codes": "1f417",
    "char": "🐗",
    "name": "Boar",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "javali",
      "boar",
      "jabalí"
    ],
    "keywordsStr": "animal animales boar jabalí javali mammal mamífero",
    "imgUrl": "assets/emojis/1f417.svg"
  },
  {
    "codes": "1f43d",
    "char": "🐽",
    "name": "Pig Nose",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "nariz de porco",
      "pig nose",
      "nariz de cerdo"
    ],
    "keywordsStr": "animal animales cerdo de mammal mamífero nariz nose pig porco",
    "imgUrl": "assets/emojis/1f43d.svg"
  },
  {
    "codes": "1f40f",
    "char": "🐏",
    "name": "Ram",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "ram",
      "carnero"
    ],
    "keywordsStr": "animal animales carnero mammal mamífero ram",
    "imgUrl": "assets/emojis/1f40f.svg"
  },
  {
    "codes": "1f411",
    "char": "🐑",
    "name": "Ewe",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "ovelha",
      "ewe",
      "oveja"
    ],
    "keywordsStr": "animal animales ewe mammal mamífero oveja ovelha",
    "imgUrl": "assets/emojis/1f411.svg"
  },
  {
    "codes": "1f410",
    "char": "🐐",
    "name": "Goat",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cabra",
      "goat"
    ],
    "keywordsStr": "animal animales cabra goat mammal mamífero",
    "imgUrl": "assets/emojis/1f410.svg"
  },
  {
    "codes": "1f42a",
    "char": "🐪",
    "name": "Camel",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "camelo",
      "camel",
      "camello"
    ],
    "keywordsStr": "animal animales camel camello camelo mammal mamífero",
    "imgUrl": "assets/emojis/1f42a.svg"
  },
  {
    "codes": "1f42b",
    "char": "🐫",
    "name": "Two Hump Camel",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "camelo de duas corcovas",
      "two-hump camel",
      "camello de dos jorobas"
    ],
    "keywordsStr": "animal animales camel camello camelo corcovas de dos duas jorobas mammal mamífero two-hump",
    "imgUrl": "assets/emojis/1f42b.svg"
  },
  {
    "codes": "1f999",
    "char": "🦙",
    "name": "Llama",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "lhama",
      "llama"
    ],
    "keywordsStr": "animal animales lhama llama mammal mamífero",
    "imgUrl": "assets/emojis/1f999.svg"
  },
  {
    "codes": "1f992",
    "char": "🦒",
    "name": "Giraffe",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "girafa",
      "giraffe",
      "jirafa"
    ],
    "keywordsStr": "animal animales girafa giraffe jirafa mammal mamífero",
    "imgUrl": "assets/emojis/1f992.svg"
  },
  {
    "codes": "1f418",
    "char": "🐘",
    "name": "Elephant",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "elefante",
      "elephant"
    ],
    "keywordsStr": "animal animales elefante elephant mammal mamífero",
    "imgUrl": "assets/emojis/1f418.svg"
  },
  {
    "codes": "1f9a3",
    "char": "🦣",
    "name": "Mammoth",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "mamute",
      "mammoth",
      "mamut"
    ],
    "keywordsStr": "animal animales mammal mammoth mamut mamute mamífero",
    "imgUrl": "assets/emojis/1f9a3.svg"
  },
  {
    "codes": "1f98f",
    "char": "🦏",
    "name": "Rhinoceros",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "rinoceronte",
      "rhinoceros"
    ],
    "keywordsStr": "animal animales mammal mamífero rhinoceros rinoceronte",
    "imgUrl": "assets/emojis/1f98f.svg"
  },
  {
    "codes": "1f99b",
    "char": "🦛",
    "name": "Hippopotamus",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "hipopótamo",
      "hippopotamus"
    ],
    "keywordsStr": "animal animales hipopótamo hippopotamus mammal mamífero",
    "imgUrl": "assets/emojis/1f99b.svg"
  },
  {
    "codes": "1f42d",
    "char": "🐭",
    "name": "Mouse Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de rato",
      "mouse face",
      "cara de raton"
    ],
    "keywordsStr": "animal animales apariencia cara countenance de expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mouse mug rato raton rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f42d.svg"
  },
  {
    "codes": "1f401",
    "char": "🐁",
    "name": "Mouse",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "rato",
      "mouse",
      "ratón"
    ],
    "keywordsStr": "animal animales mammal mamífero mouse rato ratón",
    "imgUrl": "assets/emojis/1f401.svg"
  },
  {
    "codes": "1f400",
    "char": "🐀",
    "name": "Rat",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "rato",
      "rat",
      "rata"
    ],
    "keywordsStr": "animal animales mammal mamífero rat rata rato",
    "imgUrl": "assets/emojis/1f400.svg"
  },
  {
    "codes": "1f439",
    "char": "🐹",
    "name": "Hamster",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "hamster",
      "hámster"
    ],
    "keywordsStr": "animal animales hamster hámster mammal mamífero",
    "imgUrl": "assets/emojis/1f439.svg"
  },
  {
    "codes": "1f430",
    "char": "🐰",
    "name": "Rabbit Face",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "cara de coelho",
      "rabbit face",
      "cara de conejo"
    ],
    "keywordsStr": "animal animales apariencia cara coelho conejo countenance de expresión expression expressão face faz features fisionomia fisonomía look mammal mamífero mug rabbit rosto rostro semblante visage",
    "imgUrl": "assets/emojis/1f430.svg"
  },
  {
    "codes": "1f407",
    "char": "🐇",
    "name": "Rabbit",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "coelho",
      "rabbit",
      "conejo"
    ],
    "keywordsStr": "animal animales coelho conejo mammal mamífero rabbit",
    "imgUrl": "assets/emojis/1f407.svg"
  },
  {
    "codes": "1f43f",
    "char": "🐿️",
    "name": "Chipmunk",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "esquilo",
      "chipmunk",
      "ardilla"
    ],
    "keywordsStr": "animal animales ardilla chipmunk esquilo mammal mamífero",
    "imgUrl": "assets/emojis/1f43f.svg"
  },
  {
    "codes": "1f9ab",
    "char": "🦫",
    "name": "Beaver",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "castor",
      "beaver"
    ],
    "keywordsStr": "animal animales beaver castor mammal mamífero",
    "imgUrl": "assets/emojis/1f9ab.svg"
  },
  {
    "codes": "1f994",
    "char": "🦔",
    "name": "Hedgehog",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "ouriço",
      "hedgehog",
      "erizo"
    ],
    "keywordsStr": "animal animales erizo hedgehog mammal mamífero ouriço",
    "imgUrl": "assets/emojis/1f994.svg"
  },
  {
    "codes": "1f987",
    "char": "🦇",
    "name": "Bat",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "morcego",
      "bat",
      "murciélago"
    ],
    "keywordsStr": "animal animales bat mammal mamífero morcego murciélago",
    "imgUrl": "assets/emojis/1f987.svg"
  },
  {
    "codes": "1f43b",
    "char": "🐻",
    "name": "Bear",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "urso",
      "bear",
      "oso"
    ],
    "keywordsStr": "animal animales bear mammal mamífero oso urso",
    "imgUrl": "assets/emojis/1f43b.svg"
  },
  {
    "codes": "1f43b-200d-2744-fe0f",
    "char": "🐻‍❄️",
    "name": "Polar Bear",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "urso polar",
      "polar bear",
      "oso polar"
    ],
    "keywordsStr": "animal animales bear mammal mamífero oso polar urso",
    "imgUrl": "assets/emojis/1f43b-200d-2744-fe0f.svg"
  },
  {
    "codes": "1f428",
    "char": "🐨",
    "name": "Koala",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "coala",
      "koala"
    ],
    "keywordsStr": "animal animales coala koala mammal mamífero",
    "imgUrl": "assets/emojis/1f428.svg"
  },
  {
    "codes": "1f43c",
    "char": "🐼",
    "name": "Panda",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "panda"
    ],
    "keywordsStr": "animal animales mammal mamífero panda",
    "imgUrl": "assets/emojis/1f43c.svg"
  },
  {
    "codes": "1f9a5",
    "char": "🦥",
    "name": "Sloth",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "preguiça",
      "sloth",
      "perezoso"
    ],
    "keywordsStr": "animal animales mammal mamífero perezoso preguiça sloth",
    "imgUrl": "assets/emojis/1f9a5.svg"
  },
  {
    "codes": "1f9a6",
    "char": "🦦",
    "name": "Otter",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "lontra",
      "otter",
      "nutria"
    ],
    "keywordsStr": "animal animales lontra mammal mamífero nutria otter",
    "imgUrl": "assets/emojis/1f9a6.svg"
  },
  {
    "codes": "1f9a8",
    "char": "🦨",
    "name": "Skunk",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "gambá",
      "skunk",
      "mofeta"
    ],
    "keywordsStr": "animal animales gambá mammal mamífero mofeta skunk",
    "imgUrl": "assets/emojis/1f9a8.svg"
  },
  {
    "codes": "1f998",
    "char": "🦘",
    "name": "Kangaroo",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "canguru",
      "kangaroo",
      "canguro"
    ],
    "keywordsStr": "animal animales canguro canguru kangaroo mammal mamífero",
    "imgUrl": "assets/emojis/1f998.svg"
  },
  {
    "codes": "1f9a1",
    "char": "🦡",
    "name": "Badger",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "texugo",
      "badger",
      "tejón"
    ],
    "keywordsStr": "animal animales badger mammal mamífero tejón texugo",
    "imgUrl": "assets/emojis/1f9a1.svg"
  },
  {
    "codes": "1f43e",
    "char": "🐾",
    "name": "Paw Prints",
    "category": "Animals & Nature (animal-mammal)",
    "group": "animalsAndNature",
    "subgroup": "animal-mammal",
    "keywords": [
      "animal",
      "animales",
      "mamífero",
      "mammal",
      "animal mamífero",
      "animal mammal",
      "mamífero animal",
      "impressões da pata",
      "paw prints",
      "huellas de patas"
    ],
    "keywordsStr": "animal animales da de huellas impressões mammal mamífero pata patas paw prints",
    "imgUrl": "assets/emojis/1f43e.svg"
  },
  {
    "codes": "1f983",
    "char": "🦃",
    "name": "Turkey",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "peru",
      "turkey",
      "pavo"
    ],
    "keywordsStr": "animal animales bird pavo peru pájaro pássaro turkey",
    "imgUrl": "assets/emojis/1f983.svg"
  },
  {
    "codes": "1f414",
    "char": "🐔",
    "name": "Chicken",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "frango",
      "chicken",
      "pollo"
    ],
    "keywordsStr": "animal animales bird chicken frango pollo pájaro pássaro",
    "imgUrl": "assets/emojis/1f414.svg"
  },
  {
    "codes": "1f413",
    "char": "🐓",
    "name": "Rooster",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "galo",
      "rooster",
      "gallo"
    ],
    "keywordsStr": "animal animales bird gallo galo pájaro pássaro rooster",
    "imgUrl": "assets/emojis/1f413.svg"
  },
  {
    "codes": "1f423",
    "char": "🐣",
    "name": "Hatching Chick",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pintinho chocando",
      "hatching chick",
      "pollito de incubación"
    ],
    "keywordsStr": "animal animales bird chick chocando de hatching incubación pintinho pollito pájaro pássaro",
    "imgUrl": "assets/emojis/1f423.svg"
  },
  {
    "codes": "1f424",
    "char": "🐤",
    "name": "Baby Chick",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "gatinha",
      "baby chick",
      "pollito bebé"
    ],
    "keywordsStr": "animal animales baby bebé bird chick gatinha pollito pájaro pássaro",
    "imgUrl": "assets/emojis/1f424.svg"
  },
  {
    "codes": "1f425",
    "char": "🐥",
    "name": "Front Facing Baby Chick",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pintinho de frente",
      "front-facing baby chick",
      "pollito bebé de frente"
    ],
    "keywordsStr": "animal animales baby bebé bird chick de frente front-facing pintinho pollito pájaro pássaro",
    "imgUrl": "assets/emojis/1f425.svg"
  },
  {
    "codes": "1f426",
    "char": "🐦",
    "name": "Bird",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal"
    ],
    "keywordsStr": "animal animales bird pájaro pássaro",
    "imgUrl": "assets/emojis/1f426.svg"
  },
  {
    "codes": "1f427",
    "char": "🐧",
    "name": "Penguin",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pinguim",
      "penguin",
      "pingüino"
    ],
    "keywordsStr": "animal animales bird penguin pinguim pingüino pájaro pássaro",
    "imgUrl": "assets/emojis/1f427.svg"
  },
  {
    "codes": "1f54a",
    "char": "🕊️",
    "name": "Dove",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pomba",
      "dove",
      "paloma"
    ],
    "keywordsStr": "animal animales bird dove paloma pomba pájaro pássaro",
    "imgUrl": "assets/emojis/1f54a.svg"
  },
  {
    "codes": "1f985",
    "char": "🦅",
    "name": "Eagle",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "águia",
      "eagle",
      "águila"
    ],
    "keywordsStr": "animal animales bird eagle pájaro pássaro águia águila",
    "imgUrl": "assets/emojis/1f985.svg"
  },
  {
    "codes": "1f986",
    "char": "🦆",
    "name": "Duck",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pato",
      "duck"
    ],
    "keywordsStr": "animal animales bird duck pato pájaro pássaro",
    "imgUrl": "assets/emojis/1f986.svg"
  },
  {
    "codes": "1f9a2",
    "char": "🦢",
    "name": "Swan",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "cisne",
      "swan"
    ],
    "keywordsStr": "animal animales bird cisne pájaro pássaro swan",
    "imgUrl": "assets/emojis/1f9a2.svg"
  },
  {
    "codes": "1f989",
    "char": "🦉",
    "name": "Owl",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "coruja",
      "owl",
      "búho"
    ],
    "keywordsStr": "animal animales bird búho coruja owl pájaro pássaro",
    "imgUrl": "assets/emojis/1f989.svg"
  },
  {
    "codes": "1f9a4",
    "char": "🦤",
    "name": "Dodo",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "dodô",
      "dodo"
    ],
    "keywordsStr": "animal animales bird dodo dodô pájaro pássaro",
    "imgUrl": "assets/emojis/1f9a4.svg"
  },
  {
    "codes": "1fab6",
    "char": "🪶",
    "name": "Feather",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pena",
      "feather",
      "pluma"
    ],
    "keywordsStr": "animal animales bird feather pena pluma pájaro pássaro",
    "imgUrl": "assets/emojis/1fab6.svg"
  },
  {
    "codes": "1f9a9",
    "char": "🦩",
    "name": "Flamingo",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "flamingo",
      "flamenco"
    ],
    "keywordsStr": "animal animales bird flamenco flamingo pájaro pássaro",
    "imgUrl": "assets/emojis/1f9a9.svg"
  },
  {
    "codes": "1f99a",
    "char": "🦚",
    "name": "Peacock",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "pavão",
      "peacock",
      "pavo real"
    ],
    "keywordsStr": "animal animales bird pavo pavão peacock pájaro pássaro real",
    "imgUrl": "assets/emojis/1f99a.svg"
  },
  {
    "codes": "1f99c",
    "char": "🦜",
    "name": "Parrot",
    "category": "Animals & Nature (animal-bird)",
    "group": "animalsAndNature",
    "subgroup": "animal-bird",
    "keywords": [
      "animal",
      "animales",
      "pássaro",
      "bird",
      "pájaro",
      "pássaro animal",
      "animal bird",
      "pájaro animal",
      "papagaio",
      "parrot",
      "loro"
    ],
    "keywordsStr": "animal animales bird loro papagaio parrot pájaro pássaro",
    "imgUrl": "assets/emojis/1f99c.svg"
  },
  {
    "codes": "1f438",
    "char": "🐸",
    "name": "Frog",
    "category": "Animals & Nature (animal-amphibian)",
    "group": "animalsAndNature",
    "subgroup": "animal-amphibian",
    "keywords": [
      "animal",
      "animales",
      "anfíbio",
      "amphibian",
      "anfibio",
      "animal anfíbio",
      "animal amphibian",
      "animal anfibio",
      "sapo",
      "frog",
      "rana"
    ],
    "keywordsStr": "amphibian anfibio anfíbio animal animales frog rana sapo",
    "imgUrl": "assets/emojis/1f438.svg"
  },
  {
    "codes": "1f40a",
    "char": "🐊",
    "name": "Crocodile",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "crocodilo",
      "crocodile",
      "cocodrilo"
    ],
    "keywordsStr": "animal animales cocodrilo crocodile crocodilo reptil reptile réptil",
    "imgUrl": "assets/emojis/1f40a.svg"
  },
  {
    "codes": "1f422",
    "char": "🐢",
    "name": "Turtle",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "tartaruga",
      "turtle",
      "tortuga"
    ],
    "keywordsStr": "animal animales reptil reptile réptil tartaruga tortuga turtle",
    "imgUrl": "assets/emojis/1f422.svg"
  },
  {
    "codes": "1f98e",
    "char": "🦎",
    "name": "Lizard",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "lagarto",
      "lizard"
    ],
    "keywordsStr": "animal animales lagarto lizard reptil reptile réptil",
    "imgUrl": "assets/emojis/1f98e.svg"
  },
  {
    "codes": "1f40d",
    "char": "🐍",
    "name": "Snake",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "cobra",
      "snake",
      "serpiente"
    ],
    "keywordsStr": "animal animales cobra reptil reptile réptil serpiente snake",
    "imgUrl": "assets/emojis/1f40d.svg"
  },
  {
    "codes": "1f432",
    "char": "🐲",
    "name": "Dragon Face",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "cara de dragão",
      "dragon face",
      "cara de dragón"
    ],
    "keywordsStr": "animal animales apariencia cara countenance de dragon dragão dragón expresión expression expressão face faz features fisionomia fisonomía look mug reptil reptile rosto rostro réptil semblante visage",
    "imgUrl": "assets/emojis/1f432.svg"
  },
  {
    "codes": "1f409",
    "char": "🐉",
    "name": "Dragon",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "dragão",
      "dragon",
      "dragón"
    ],
    "keywordsStr": "animal animales dragon dragão dragón reptil reptile réptil",
    "imgUrl": "assets/emojis/1f409.svg"
  },
  {
    "codes": "1f995",
    "char": "🦕",
    "name": "Sauropod",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "saurópode",
      "sauropod",
      "saurópodo"
    ],
    "keywordsStr": "animal animales reptil reptile réptil sauropod saurópode saurópodo",
    "imgUrl": "assets/emojis/1f995.svg"
  },
  {
    "codes": "1f996",
    "char": "🦖",
    "name": "T Rex",
    "category": "Animals & Nature (animal-reptile)",
    "group": "animalsAndNature",
    "subgroup": "animal-reptile",
    "keywords": [
      "animal",
      "animales",
      "réptil",
      "reptile",
      "reptil",
      "réptil animal",
      "animal reptile",
      "reptil animal",
      "T-Rex",
      "tiranosaurio rex"
    ],
    "keywordsStr": "T-Rex animal animales reptil reptile rex réptil tiranosaurio",
    "imgUrl": "assets/emojis/1f996.svg"
  },
  {
    "codes": "1f433",
    "char": "🐳",
    "name": "Spouting Whale",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "baleia jorrando",
      "spouting whale",
      "ballena echando chorros"
    ],
    "keywordsStr": "animal animales baleia ballena chorros echando jorrando marine marinha marinho marino spouting whale",
    "imgUrl": "assets/emojis/1f433.svg"
  },
  {
    "codes": "1f40b",
    "char": "🐋",
    "name": "Whale",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "baleia",
      "whale",
      "ballena"
    ],
    "keywordsStr": "animal animales baleia ballena marine marinha marinho marino whale",
    "imgUrl": "assets/emojis/1f40b.svg"
  },
  {
    "codes": "1f42c",
    "char": "🐬",
    "name": "Dolphin",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "golfinho",
      "dolphin",
      "delfín"
    ],
    "keywordsStr": "animal animales delfín dolphin golfinho marine marinha marinho marino",
    "imgUrl": "assets/emojis/1f42c.svg"
  },
  {
    "codes": "1f9ad",
    "char": "🦭",
    "name": "Seal",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "selo",
      "seal",
      "sello"
    ],
    "keywordsStr": "animal animales marine marinha marinho marino seal sello selo",
    "imgUrl": "assets/emojis/1f9ad.svg"
  },
  {
    "codes": "1f41f",
    "char": "🐟",
    "name": "Fish",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "peixe",
      "fish",
      "pez"
    ],
    "keywordsStr": "animal animales fish marine marinha marinho marino peixe pez",
    "imgUrl": "assets/emojis/1f41f.svg"
  },
  {
    "codes": "1f420",
    "char": "🐠",
    "name": "Tropical Fish",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "peixes tropicais",
      "tropical fish",
      "peces tropicales"
    ],
    "keywordsStr": "animal animales fish marine marinha marinho marino peces peixes tropicais tropical tropicales",
    "imgUrl": "assets/emojis/1f420.svg"
  },
  {
    "codes": "1f421",
    "char": "🐡",
    "name": "Blowfish",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "peixe baiacu",
      "blowfish",
      "pez globo"
    ],
    "keywordsStr": "animal animales baiacu blowfish globo marine marinha marinho marino peixe pez",
    "imgUrl": "assets/emojis/1f421.svg"
  },
  {
    "codes": "1f988",
    "char": "🦈",
    "name": "Shark",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "tubarão",
      "shark",
      "tiburón"
    ],
    "keywordsStr": "animal animales marine marinha marinho marino shark tiburón tubarão",
    "imgUrl": "assets/emojis/1f988.svg"
  },
  {
    "codes": "1f419",
    "char": "🐙",
    "name": "Octopus",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "polvo",
      "octopus",
      "pulpo"
    ],
    "keywordsStr": "animal animales marine marinha marinho marino octopus polvo pulpo",
    "imgUrl": "assets/emojis/1f419.svg"
  },
  {
    "codes": "1f41a",
    "char": "🐚",
    "name": "Spiral Shell",
    "category": "Animals & Nature (animal-marine)",
    "group": "animalsAndNature",
    "subgroup": "animal-marine",
    "keywords": [
      "animal",
      "animales",
      "marinha",
      "marine",
      "marino",
      "animal marinho",
      "animal marine",
      "animal marino",
      "concha espiral",
      "spiral shell",
      "cáscara espiral"
    ],
    "keywordsStr": "animal animales concha cáscara espiral marine marinha marinho marino shell spiral",
    "imgUrl": "assets/emojis/1f41a.svg"
  },
  {
    "codes": "1f40c",
    "char": "🐌",
    "name": "Snail",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "caracol",
      "snail"
    ],
    "keywordsStr": "animal animales bicho bug caracol error snail",
    "imgUrl": "assets/emojis/1f40c.svg"
  },
  {
    "codes": "1f98b",
    "char": "🦋",
    "name": "Butterfly",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "borboleta",
      "butterfly",
      "mariposa"
    ],
    "keywordsStr": "animal animales bicho borboleta bug butterfly error mariposa",
    "imgUrl": "assets/emojis/1f98b.svg"
  },
  {
    "codes": "1f41b",
    "char": "🐛",
    "name": "Bug",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug"
    ],
    "keywordsStr": "animal animales bicho bug error",
    "imgUrl": "assets/emojis/1f41b.svg"
  },
  {
    "codes": "1f41c",
    "char": "🐜",
    "name": "Ant",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "formiga",
      "ant",
      "hormiga"
    ],
    "keywordsStr": "animal animales ant bicho bug error formiga hormiga",
    "imgUrl": "assets/emojis/1f41c.svg"
  },
  {
    "codes": "1f41d",
    "char": "🐝",
    "name": "Honeybee",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "abelha",
      "honeybee",
      "abeja"
    ],
    "keywordsStr": "abeja abelha animal animales bicho bug error honeybee",
    "imgUrl": "assets/emojis/1f41d.svg"
  },
  {
    "codes": "1fab2",
    "char": "🪲",
    "name": "Beetle",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "besouro",
      "beetle",
      "escarabajo"
    ],
    "keywordsStr": "animal animales beetle besouro bicho bug error escarabajo",
    "imgUrl": "assets/emojis/1fab2.svg"
  },
  {
    "codes": "1f41e",
    "char": "🐞",
    "name": "Lady Beetle",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "senhora besouro",
      "lady beetle",
      "mariquita"
    ],
    "keywordsStr": "animal animales beetle besouro bicho bug error lady mariquita senhora",
    "imgUrl": "assets/emojis/1f41e.svg"
  },
  {
    "codes": "1f997",
    "char": "🦗",
    "name": "Cricket",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "grilo",
      "cricket",
      "grillo"
    ],
    "keywordsStr": "animal animales bicho bug cricket error grillo grilo",
    "imgUrl": "assets/emojis/1f997.svg"
  },
  {
    "codes": "1fab3",
    "char": "🪳",
    "name": "Cockroach",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "barata",
      "cockroach",
      "cucaracha"
    ],
    "keywordsStr": "animal animales barata bicho bug cockroach cucaracha error",
    "imgUrl": "assets/emojis/1fab3.svg"
  },
  {
    "codes": "1f577",
    "char": "🕷️",
    "name": "Spider",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "aranha",
      "spider",
      "araña"
    ],
    "keywordsStr": "animal animales aranha araña bicho bug error spider",
    "imgUrl": "assets/emojis/1f577.svg"
  },
  {
    "codes": "1f578",
    "char": "🕸️",
    "name": "Spider Web",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "teia de aranha",
      "spider web",
      "telaraña"
    ],
    "keywordsStr": "animal animales aranha bicho bug de error spider teia telaraña web",
    "imgUrl": "assets/emojis/1f578.svg"
  },
  {
    "codes": "1f982",
    "char": "🦂",
    "name": "Scorpion",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "escorpião",
      "scorpion",
      "escorpión"
    ],
    "keywordsStr": "animal animales bicho bug error escorpião escorpión scorpion",
    "imgUrl": "assets/emojis/1f982.svg"
  },
  {
    "codes": "1f99f",
    "char": "🦟",
    "name": "Mosquito",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "mosquito"
    ],
    "keywordsStr": "animal animales bicho bug error mosquito",
    "imgUrl": "assets/emojis/1f99f.svg"
  },
  {
    "codes": "1fab0",
    "char": "🪰",
    "name": "Fly",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "voar",
      "fly",
      "volar"
    ],
    "keywordsStr": "animal animales bicho bug error fly voar volar",
    "imgUrl": "assets/emojis/1fab0.svg"
  },
  {
    "codes": "1fab1",
    "char": "🪱",
    "name": "Worm",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "verme",
      "worm",
      "gusano"
    ],
    "keywordsStr": "animal animales bicho bug error gusano verme worm",
    "imgUrl": "assets/emojis/1fab1.svg"
  },
  {
    "codes": "1f9a0",
    "char": "🦠",
    "name": "Microbe",
    "category": "Animals & Nature (animal-bug)",
    "group": "animalsAndNature",
    "subgroup": "animal-bug",
    "keywords": [
      "animal",
      "animales",
      "bug",
      "error",
      "bicho animal",
      "animal bug",
      "micróbio",
      "microbe",
      "microbio"
    ],
    "keywordsStr": "animal animales bicho bug error microbe microbio micróbio",
    "imgUrl": "assets/emojis/1f9a0.svg"
  },
  {
    "codes": "1f490",
    "char": "💐",
    "name": "Bouquet",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "buquê",
      "bouquet",
      "ramo"
    ],
    "keywordsStr": "bouquet buquê de flor flower la plant planta plantar ramo",
    "imgUrl": "assets/emojis/1f490.svg"
  },
  {
    "codes": "1f338",
    "char": "🌸",
    "name": "Cherry Blossom",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "flor de cerejeira",
      "cherry blossom",
      "flor de cerezo"
    ],
    "keywordsStr": "blossom cerejeira cerezo cherry de flor flower la plant planta plantar",
    "imgUrl": "assets/emojis/1f338.svg"
  },
  {
    "codes": "1f4ae",
    "char": "💮",
    "name": "White Flower",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "flor branca",
      "white flower",
      "flor blanca"
    ],
    "keywordsStr": "blanca branca de flor flower la plant planta plantar white",
    "imgUrl": "assets/emojis/1f4ae.svg"
  },
  {
    "codes": "1f3f5",
    "char": "🏵️",
    "name": "Rosette",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "roseta",
      "rosette"
    ],
    "keywordsStr": "de flor flower la plant planta plantar roseta rosette",
    "imgUrl": "assets/emojis/1f3f5.svg"
  },
  {
    "codes": "1f339",
    "char": "🌹",
    "name": "Rose",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "rosa",
      "rose"
    ],
    "keywordsStr": "de flor flower la plant planta plantar rosa rose",
    "imgUrl": "assets/emojis/1f339.svg"
  },
  {
    "codes": "1f940",
    "char": "🥀",
    "name": "Wilted Flower",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "flor murcha",
      "wilted flower",
      "flor marchita"
    ],
    "keywordsStr": "de flor flower la marchita murcha plant planta plantar wilted",
    "imgUrl": "assets/emojis/1f940.svg"
  },
  {
    "codes": "1f33a",
    "char": "🌺",
    "name": "Hibiscus",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "hibisco",
      "hibiscus"
    ],
    "keywordsStr": "de flor flower hibisco hibiscus la plant planta plantar",
    "imgUrl": "assets/emojis/1f33a.svg"
  },
  {
    "codes": "1f33b",
    "char": "🌻",
    "name": "Sunflower",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "girassol",
      "sunflower",
      "girasol"
    ],
    "keywordsStr": "de flor flower girasol girassol la plant planta plantar sunflower",
    "imgUrl": "assets/emojis/1f33b.svg"
  },
  {
    "codes": "1f33c",
    "char": "🌼",
    "name": "Blossom",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "florescer",
      "blossom"
    ],
    "keywordsStr": "blossom de flor florescer flower la plant planta plantar",
    "imgUrl": "assets/emojis/1f33c.svg"
  },
  {
    "codes": "1f337",
    "char": "🌷",
    "name": "Tulip",
    "category": "Animals & Nature (plant-flower)",
    "group": "animalsAndNature",
    "subgroup": "plant-flower",
    "keywords": [
      "planta",
      "plant",
      "flor",
      "flower",
      "plantar flor",
      "plant flower",
      "flor de la planta",
      "tulipa",
      "tulip",
      "tulipán"
    ],
    "keywordsStr": "de flor flower la plant planta plantar tulip tulipa tulipán",
    "imgUrl": "assets/emojis/1f337.svg"
  },
  {
    "codes": "1f331",
    "char": "🌱",
    "name": "Seedling",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "mudas",
      "seedling",
      "planta de semillero"
    ],
    "keywordsStr": "de mudas other otro outro plant planta plantar seedling semillero",
    "imgUrl": "assets/emojis/1f331.svg"
  },
  {
    "codes": "1fab4",
    "char": "🪴",
    "name": "Potted Plant",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "planta em vaso",
      "potted plant",
      "planta en maceta"
    ],
    "keywordsStr": "em en maceta other otro outro plant planta plantar potted vaso",
    "imgUrl": "assets/emojis/1fab4.svg"
  },
  {
    "codes": "1f332",
    "char": "🌲",
    "name": "Evergreen Tree",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "árvore perene",
      "evergreen tree",
      "árbol de hoja perenne"
    ],
    "keywordsStr": "de evergreen hoja other otro outro perene perenne plant planta plantar tree árbol árvore",
    "imgUrl": "assets/emojis/1f332.svg"
  },
  {
    "codes": "1f333",
    "char": "🌳",
    "name": "Deciduous Tree",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "árvore decídua",
      "deciduous tree",
      "árbol de hoja caduca"
    ],
    "keywordsStr": "caduca de deciduous decídua hoja other otro outro plant planta plantar tree árbol árvore",
    "imgUrl": "assets/emojis/1f333.svg"
  },
  {
    "codes": "1f334",
    "char": "🌴",
    "name": "Palm Tree",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "palmeira",
      "palm tree",
      "palmera"
    ],
    "keywordsStr": "other otro outro palm palmeira palmera plant planta plantar tree",
    "imgUrl": "assets/emojis/1f334.svg"
  },
  {
    "codes": "1f335",
    "char": "🌵",
    "name": "Cactus",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "cacto",
      "cactus"
    ],
    "keywordsStr": "cacto cactus other otro outro plant planta plantar",
    "imgUrl": "assets/emojis/1f335.svg"
  },
  {
    "codes": "1f33e",
    "char": "🌾",
    "name": "Sheaf Of Rice",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "feixe de arroz",
      "sheaf of rice",
      "gavilla de arroz"
    ],
    "keywordsStr": "arroz de feixe gavilla of other otro outro plant planta plantar rice sheaf",
    "imgUrl": "assets/emojis/1f33e.svg"
  },
  {
    "codes": "1f33f",
    "char": "🌿",
    "name": "Herb",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "erva",
      "herb",
      "hierba"
    ],
    "keywordsStr": "erva herb hierba other otro outro plant planta plantar",
    "imgUrl": "assets/emojis/1f33f.svg"
  },
  {
    "codes": "2618",
    "char": "☘️",
    "name": "Shamrock",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "trevo",
      "shamrock",
      "trébol"
    ],
    "keywordsStr": "other otro outro plant planta plantar shamrock trevo trébol",
    "imgUrl": "assets/emojis/2618.svg"
  },
  {
    "codes": "1f340",
    "char": "🍀",
    "name": "Four Leaf Clover",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "trevo de quatro folhas",
      "four leaf clover",
      "trébol de cuatro hojas"
    ],
    "keywordsStr": "clover cuatro de folhas four hojas leaf other otro outro plant planta plantar quatro trevo trébol",
    "imgUrl": "assets/emojis/1f340.svg"
  },
  {
    "codes": "1f341",
    "char": "🍁",
    "name": "Maple Leaf",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "folha de bordo",
      "maple leaf",
      "hoja de arce"
    ],
    "keywordsStr": "arce bordo de folha hoja leaf maple other otro outro plant planta plantar",
    "imgUrl": "assets/emojis/1f341.svg"
  },
  {
    "codes": "1f342",
    "char": "🍂",
    "name": "Fallen Leaf",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "folha caída",
      "fallen leaf",
      "hoja caída"
    ],
    "keywordsStr": "caída fallen folha hoja leaf other otro outro plant planta plantar",
    "imgUrl": "assets/emojis/1f342.svg"
  },
  {
    "codes": "1f343",
    "char": "🍃",
    "name": "Leaf Fluttering In Wind",
    "category": "Animals & Nature (plant-other)",
    "group": "animalsAndNature",
    "subgroup": "plant-other",
    "keywords": [
      "planta",
      "plant",
      "outro",
      "other",
      "otro",
      "plantar outro",
      "plant other",
      "plantar otro",
      "folha flutuando ao vento",
      "leaf fluttering in wind",
      "hoja revoloteando en el viento"
    ],
    "keywordsStr": "ao el en fluttering flutuando folha hoja in leaf other otro outro plant planta plantar revoloteando vento viento wind",
    "imgUrl": "assets/emojis/1f343.svg"
  },
  {
    "codes": "1f347",
    "char": "🍇",
    "name": "Grapes",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "uvas",
      "grapes"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta grapes uvas",
    "imgUrl": "assets/emojis/1f347.svg"
  },
  {
    "codes": "1f348",
    "char": "🍈",
    "name": "Melon",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "melão",
      "melon",
      "melón"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta melon melão melón",
    "imgUrl": "assets/emojis/1f348.svg"
  },
  {
    "codes": "1f349",
    "char": "🍉",
    "name": "Watermelon",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "melancia",
      "watermelon",
      "sandía"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta melancia sandía watermelon",
    "imgUrl": "assets/emojis/1f349.svg"
  },
  {
    "codes": "1f34a",
    "char": "🍊",
    "name": "Tangerine",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "tangerina",
      "tangerine",
      "mandarina"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta mandarina tangerina tangerine",
    "imgUrl": "assets/emojis/1f34a.svg"
  },
  {
    "codes": "1f34b",
    "char": "🍋",
    "name": "Lemon",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "limão",
      "lemon",
      "limón"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta lemon limão limón",
    "imgUrl": "assets/emojis/1f34b.svg"
  },
  {
    "codes": "1f34c",
    "char": "🍌",
    "name": "Banana",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "banana",
      "plátano"
    ],
    "keywordsStr": "alimentar alimenticia banana comida food fruit fruta plátano",
    "imgUrl": "assets/emojis/1f34c.svg"
  },
  {
    "codes": "1f34d",
    "char": "🍍",
    "name": "Pineapple",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "abacaxi",
      "pineapple",
      "piña"
    ],
    "keywordsStr": "abacaxi alimentar alimenticia comida food fruit fruta pineapple piña",
    "imgUrl": "assets/emojis/1f34d.svg"
  },
  {
    "codes": "1f96d",
    "char": "🥭",
    "name": "Mango",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "manga",
      "mango"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta manga mango",
    "imgUrl": "assets/emojis/1f96d.svg"
  },
  {
    "codes": "1f34e",
    "char": "🍎",
    "name": "Red Apple",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "maçã vermelha",
      "red apple",
      "manzana roja"
    ],
    "keywordsStr": "alimentar alimenticia apple comida food fruit fruta manzana maçã red roja vermelha",
    "imgUrl": "assets/emojis/1f34e.svg"
  },
  {
    "codes": "1f34f",
    "char": "🍏",
    "name": "Green Apple",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "maçã verde",
      "green apple",
      "manzana verde"
    ],
    "keywordsStr": "alimentar alimenticia apple comida food fruit fruta green manzana maçã verde",
    "imgUrl": "assets/emojis/1f34f.svg"
  },
  {
    "codes": "1f350",
    "char": "🍐",
    "name": "Pear",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "pêra",
      "pear",
      "pera"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta pear pera pêra",
    "imgUrl": "assets/emojis/1f350.svg"
  },
  {
    "codes": "1f351",
    "char": "🍑",
    "name": "Peach",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "pêssego",
      "peach",
      "melocotón"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta melocotón peach pêssego",
    "imgUrl": "assets/emojis/1f351.svg"
  },
  {
    "codes": "1f352",
    "char": "🍒",
    "name": "Cherries",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "cerejas",
      "cherries",
      "cerezas"
    ],
    "keywordsStr": "alimentar alimenticia cerejas cerezas cherries comida food fruit fruta",
    "imgUrl": "assets/emojis/1f352.svg"
  },
  {
    "codes": "1f353",
    "char": "🍓",
    "name": "Strawberry",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "morango",
      "strawberry",
      "fresa"
    ],
    "keywordsStr": "alimentar alimenticia comida food fresa fruit fruta morango strawberry",
    "imgUrl": "assets/emojis/1f353.svg"
  },
  {
    "codes": "1fad0",
    "char": "🫐",
    "name": "Blueberries",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "mirtilos",
      "blueberries",
      "arándanos"
    ],
    "keywordsStr": "alimentar alimenticia arándanos blueberries comida food fruit fruta mirtilos",
    "imgUrl": "assets/emojis/1fad0.svg"
  },
  {
    "codes": "1f95d",
    "char": "🥝",
    "name": "Kiwi Fruit",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "kiwi",
      "kiwi fruit"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta kiwi",
    "imgUrl": "assets/emojis/1f95d.svg"
  },
  {
    "codes": "1f345",
    "char": "🍅",
    "name": "Tomato",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "tomate",
      "tomato"
    ],
    "keywordsStr": "alimentar alimenticia comida food fruit fruta tomate tomato",
    "imgUrl": "assets/emojis/1f345.svg"
  },
  {
    "codes": "1fad2",
    "char": "🫒",
    "name": "Olive",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "azeitona",
      "olive",
      "oliva"
    ],
    "keywordsStr": "alimentar alimenticia azeitona comida food fruit fruta oliva olive",
    "imgUrl": "assets/emojis/1fad2.svg"
  },
  {
    "codes": "1f965",
    "char": "🥥",
    "name": "Coconut",
    "category": "Food & Drink (food-fruit)",
    "group": "foodAndDrink",
    "subgroup": "food-fruit",
    "keywords": [
      "comida",
      "food",
      "fruta",
      "fruit",
      "fruta alimentar",
      "food fruit",
      "fruta alimenticia",
      "coco",
      "coconut"
    ],
    "keywordsStr": "alimentar alimenticia coco coconut comida food fruit fruta",
    "imgUrl": "assets/emojis/1f965.svg"
  },
  {
    "codes": "1f951",
    "char": "🥑",
    "name": "Avocado",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "abacate",
      "avocado",
      "aguacate"
    ],
    "keywordsStr": "abacate aguacate alimento avocado comida food vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f951.svg"
  },
  {
    "codes": "1f346",
    "char": "🍆",
    "name": "Eggplant",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "berinjela",
      "eggplant",
      "berenjena"
    ],
    "keywordsStr": "alimento berenjena berinjela comida eggplant food vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f346.svg"
  },
  {
    "codes": "1f954",
    "char": "🥔",
    "name": "Potato",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "batata",
      "potato",
      "papa"
    ],
    "keywordsStr": "alimento batata comida food papa potato vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f954.svg"
  },
  {
    "codes": "1f955",
    "char": "🥕",
    "name": "Carrot",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "cenoura",
      "carrot",
      "zanahoria"
    ],
    "keywordsStr": "alimento carrot cenoura comida food vegetable vegetal verdura zanahoria",
    "imgUrl": "assets/emojis/1f955.svg"
  },
  {
    "codes": "1f33d",
    "char": "🌽",
    "name": "Ear Of Corn",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "espiga de milho",
      "ear of corn",
      "mazorca de maíz"
    ],
    "keywordsStr": "alimento comida corn de ear espiga food mazorca maíz milho of vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f33d.svg"
  },
  {
    "codes": "1f336",
    "char": "🌶️",
    "name": "Hot Pepper",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "pimenta quente",
      "hot pepper",
      "pimiento picante"
    ],
    "keywordsStr": "alimento comida food hot pepper picante pimenta pimiento quente vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f336.svg"
  },
  {
    "codes": "1fad1",
    "char": "🫑",
    "name": "Bell Pepper",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "pimentão",
      "bell pepper",
      "pimiento morrón"
    ],
    "keywordsStr": "alimento bell comida food morrón pepper pimentão pimiento vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1fad1.svg"
  },
  {
    "codes": "1f952",
    "char": "🥒",
    "name": "Cucumber",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "pepino",
      "cucumber"
    ],
    "keywordsStr": "alimento comida cucumber food pepino vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f952.svg"
  },
  {
    "codes": "1f96c",
    "char": "🥬",
    "name": "Leafy Green",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "verde folhoso",
      "leafy green",
      "verde frondoso"
    ],
    "keywordsStr": "alimento comida folhoso food frondoso green leafy vegetable vegetal verde verdura",
    "imgUrl": "assets/emojis/1f96c.svg"
  },
  {
    "codes": "1f966",
    "char": "🥦",
    "name": "Broccoli",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "brócolis",
      "broccoli",
      "brócoli"
    ],
    "keywordsStr": "alimento broccoli brócoli brócolis comida food vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f966.svg"
  },
  {
    "codes": "1f9c4",
    "char": "🧄",
    "name": "Garlic",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "alho",
      "garlic",
      "ajo"
    ],
    "keywordsStr": "ajo alho alimento comida food garlic vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f9c4.svg"
  },
  {
    "codes": "1f9c5",
    "char": "🧅",
    "name": "Onion",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "cebola",
      "onion",
      "cebolla"
    ],
    "keywordsStr": "alimento cebola cebolla comida food onion vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f9c5.svg"
  },
  {
    "codes": "1f344",
    "char": "🍄",
    "name": "Mushroom",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "cogumelo",
      "mushroom",
      "hongo"
    ],
    "keywordsStr": "alimento cogumelo comida food hongo mushroom vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f344.svg"
  },
  {
    "codes": "1f95c",
    "char": "🥜",
    "name": "Peanuts",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "amendoim",
      "peanuts",
      "cacahuetes"
    ],
    "keywordsStr": "alimento amendoim cacahuetes comida food peanuts vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f95c.svg"
  },
  {
    "codes": "1f330",
    "char": "🌰",
    "name": "Chestnut",
    "category": "Food & Drink (food-vegetable)",
    "group": "foodAndDrink",
    "subgroup": "food-vegetable",
    "keywords": [
      "comida",
      "food",
      "vegetal",
      "vegetable",
      "verdura",
      "alimento vegetal",
      "food vegetable",
      "comida vegetal",
      "castanha",
      "chestnut",
      "castaño"
    ],
    "keywordsStr": "alimento castanha castaño chestnut comida food vegetable vegetal verdura",
    "imgUrl": "assets/emojis/1f330.svg"
  },
  {
    "codes": "1f35e",
    "char": "🍞",
    "name": "Bread",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pão",
      "bread",
      "pan"
    ],
    "keywordsStr": "bread comida food pan preparada preparado prepared pão",
    "imgUrl": "assets/emojis/1f35e.svg"
  },
  {
    "codes": "1f950",
    "char": "🥐",
    "name": "Croissant",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "croissant"
    ],
    "keywordsStr": "comida croissant food preparada preparado prepared",
    "imgUrl": "assets/emojis/1f950.svg"
  },
  {
    "codes": "1f956",
    "char": "🥖",
    "name": "Baguette Bread",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pão baguete",
      "baguette bread",
      "pan baguette"
    ],
    "keywordsStr": "baguete baguette bread comida food pan preparada preparado prepared pão",
    "imgUrl": "assets/emojis/1f956.svg"
  },
  {
    "codes": "1fad3",
    "char": "🫓",
    "name": "Flatbread",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pão achatado",
      "flatbread",
      "pan plano"
    ],
    "keywordsStr": "achatado comida flatbread food pan plano preparada preparado prepared pão",
    "imgUrl": "assets/emojis/1fad3.svg"
  },
  {
    "codes": "1f968",
    "char": "🥨",
    "name": "Pretzel",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pretzel",
      "galleta salada"
    ],
    "keywordsStr": "comida food galleta preparada preparado prepared pretzel salada",
    "imgUrl": "assets/emojis/1f968.svg"
  },
  {
    "codes": "1f96f",
    "char": "🥯",
    "name": "Bagel",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "baguete",
      "bagel",
      "panecillo"
    ],
    "keywordsStr": "bagel baguete comida food panecillo preparada preparado prepared",
    "imgUrl": "assets/emojis/1f96f.svg"
  },
  {
    "codes": "1f95e",
    "char": "🥞",
    "name": "Pancakes",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "panquecas",
      "pancakes",
      "panqueques"
    ],
    "keywordsStr": "comida food pancakes panquecas panqueques preparada preparado prepared",
    "imgUrl": "assets/emojis/1f95e.svg"
  },
  {
    "codes": "1f9c7",
    "char": "🧇",
    "name": "Waffle",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "waffle",
      "gofre"
    ],
    "keywordsStr": "comida food gofre preparada preparado prepared waffle",
    "imgUrl": "assets/emojis/1f9c7.svg"
  },
  {
    "codes": "1f9c0",
    "char": "🧀",
    "name": "Cheese Wedge",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "cunha de queijo",
      "cheese wedge",
      "cuña de queso"
    ],
    "keywordsStr": "cheese comida cunha cuña de food preparada preparado prepared queijo queso wedge",
    "imgUrl": "assets/emojis/1f9c0.svg"
  },
  {
    "codes": "1f356",
    "char": "🍖",
    "name": "Meat On Bone",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "carne no osso",
      "meat on bone",
      "carne con hueso"
    ],
    "keywordsStr": "bone carne comida con food hueso meat no on osso preparada preparado prepared",
    "imgUrl": "assets/emojis/1f356.svg"
  },
  {
    "codes": "1f357",
    "char": "🍗",
    "name": "Poultry Leg",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "perna de frango",
      "poultry leg",
      "pata de pollo"
    ],
    "keywordsStr": "comida de food frango leg pata perna pollo poultry preparada preparado prepared",
    "imgUrl": "assets/emojis/1f357.svg"
  },
  {
    "codes": "1f969",
    "char": "🥩",
    "name": "Cut Of Meat",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "corte de carne",
      "cut of meat"
    ],
    "keywordsStr": "carne comida corte cut de food meat of preparada preparado prepared",
    "imgUrl": "assets/emojis/1f969.svg"
  },
  {
    "codes": "1f953",
    "char": "🥓",
    "name": "Bacon",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "bacon",
      "tocino"
    ],
    "keywordsStr": "bacon comida food preparada preparado prepared tocino",
    "imgUrl": "assets/emojis/1f953.svg"
  },
  {
    "codes": "1f354",
    "char": "🍔",
    "name": "Hamburger",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "hambúrguer",
      "hamburger",
      "hamburguesa"
    ],
    "keywordsStr": "comida food hamburger hamburguesa hambúrguer preparada preparado prepared",
    "imgUrl": "assets/emojis/1f354.svg"
  },
  {
    "codes": "1f35f",
    "char": "🍟",
    "name": "French Fries",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "batatas fritas",
      "french fries",
      "papas fritas"
    ],
    "keywordsStr": "batatas comida food french fries fritas papas preparada preparado prepared",
    "imgUrl": "assets/emojis/1f35f.svg"
  },
  {
    "codes": "1f355",
    "char": "🍕",
    "name": "Pizza",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pizza"
    ],
    "keywordsStr": "comida food pizza preparada preparado prepared",
    "imgUrl": "assets/emojis/1f355.svg"
  },
  {
    "codes": "1f32d",
    "char": "🌭",
    "name": "Hot Dog",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "cachorro quente",
      "hot dog",
      "perro caliente"
    ],
    "keywordsStr": "cachorro caliente comida dog food hot perro preparada preparado prepared quente",
    "imgUrl": "assets/emojis/1f32d.svg"
  },
  {
    "codes": "1f96a",
    "char": "🥪",
    "name": "Sandwich",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "sanduíche",
      "sandwich",
      "sándwich"
    ],
    "keywordsStr": "comida food preparada preparado prepared sanduíche sandwich sándwich",
    "imgUrl": "assets/emojis/1f96a.svg"
  },
  {
    "codes": "1f32e",
    "char": "🌮",
    "name": "Taco",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "taco"
    ],
    "keywordsStr": "comida food preparada preparado prepared taco",
    "imgUrl": "assets/emojis/1f32e.svg"
  },
  {
    "codes": "1f32f",
    "char": "🌯",
    "name": "Burrito",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "burrinho",
      "burrito"
    ],
    "keywordsStr": "burrinho burrito comida food preparada preparado prepared",
    "imgUrl": "assets/emojis/1f32f.svg"
  },
  {
    "codes": "1fad4",
    "char": "🫔",
    "name": "Tamale",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "tamal",
      "tamale"
    ],
    "keywordsStr": "comida food preparada preparado prepared tamal tamale",
    "imgUrl": "assets/emojis/1fad4.svg"
  },
  {
    "codes": "1f959",
    "char": "🥙",
    "name": "Stuffed Flatbread",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pão recheado",
      "stuffed flatbread",
      "pan plano relleno"
    ],
    "keywordsStr": "comida flatbread food pan plano preparada preparado prepared pão recheado relleno stuffed",
    "imgUrl": "assets/emojis/1f959.svg"
  },
  {
    "codes": "1f9c6",
    "char": "🧆",
    "name": "Falafel",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "falafel"
    ],
    "keywordsStr": "comida falafel food preparada preparado prepared",
    "imgUrl": "assets/emojis/1f9c6.svg"
  },
  {
    "codes": "1f95a",
    "char": "🥚",
    "name": "Egg",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "ovo",
      "egg",
      "huevo"
    ],
    "keywordsStr": "comida egg food huevo ovo preparada preparado prepared",
    "imgUrl": "assets/emojis/1f95a.svg"
  },
  {
    "codes": "1f373",
    "char": "🍳",
    "name": "Cooking",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "cozinhar",
      "cooking",
      "cocinar"
    ],
    "keywordsStr": "cocinar comida cooking cozinhar food preparada preparado prepared",
    "imgUrl": "assets/emojis/1f373.svg"
  },
  {
    "codes": "1f958",
    "char": "🥘",
    "name": "Shallow Pan Of Food",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "panela rasa de comida",
      "shallow pan of food",
      "cacerola poco profunda de comida"
    ],
    "keywordsStr": "cacerola comida de food of pan panela poco preparada preparado prepared profunda rasa shallow",
    "imgUrl": "assets/emojis/1f958.svg"
  },
  {
    "codes": "1f372",
    "char": "🍲",
    "name": "Pot Of Food",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pote de comida",
      "pot of food",
      "olla de comida"
    ],
    "keywordsStr": "comida de food of olla pot pote preparada preparado prepared",
    "imgUrl": "assets/emojis/1f372.svg"
  },
  {
    "codes": "1fad5",
    "char": "🫕",
    "name": "Fondue",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "fondure",
      "fondue"
    ],
    "keywordsStr": "comida fondue fondure food preparada preparado prepared",
    "imgUrl": "assets/emojis/1fad5.svg"
  },
  {
    "codes": "1f963",
    "char": "🥣",
    "name": "Bowl With Spoon",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "tigela com colher",
      "bowl with spoon",
      "tazón con cuchara"
    ],
    "keywordsStr": "bowl colher com comida con cuchara food preparada preparado prepared spoon tazón tigela with",
    "imgUrl": "assets/emojis/1f963.svg"
  },
  {
    "codes": "1f957",
    "char": "🥗",
    "name": "Green Salad",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "salada verde",
      "green salad",
      "ensalada verde"
    ],
    "keywordsStr": "comida ensalada food green preparada preparado prepared salad salada verde",
    "imgUrl": "assets/emojis/1f957.svg"
  },
  {
    "codes": "1f37f",
    "char": "🍿",
    "name": "Popcorn",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "pipoca",
      "popcorn",
      "palomitas de maíz"
    ],
    "keywordsStr": "comida de food maíz palomitas pipoca popcorn preparada preparado prepared",
    "imgUrl": "assets/emojis/1f37f.svg"
  },
  {
    "codes": "1f9c8",
    "char": "🧈",
    "name": "Butter",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "manteiga",
      "butter",
      "mantequilla"
    ],
    "keywordsStr": "butter comida food manteiga mantequilla preparada preparado prepared",
    "imgUrl": "assets/emojis/1f9c8.svg"
  },
  {
    "codes": "1f9c2",
    "char": "🧂",
    "name": "Salt",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "sal",
      "salt"
    ],
    "keywordsStr": "comida food preparada preparado prepared sal salt",
    "imgUrl": "assets/emojis/1f9c2.svg"
  },
  {
    "codes": "1f96b",
    "char": "🥫",
    "name": "Canned Food",
    "category": "Food & Drink (food-prepared)",
    "group": "foodAndDrink",
    "subgroup": "food-prepared",
    "keywords": [
      "comida",
      "food",
      "preparado",
      "prepared",
      "comida preparada",
      "food prepared",
      "alimentos enlatados",
      "canned food",
      "comida enlatada"
    ],
    "keywordsStr": "alimentos canned comida enlatada enlatados food preparada preparado prepared",
    "imgUrl": "assets/emojis/1f96b.svg"
  },
  {
    "codes": "1f371",
    "char": "🍱",
    "name": "Bento Box",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "caixa bento",
      "bento box",
      "caja bento"
    ],
    "keywordsStr": "asian asiática asiático bento box caixa caja comida food",
    "imgUrl": "assets/emojis/1f371.svg"
  },
  {
    "codes": "1f358",
    "char": "🍘",
    "name": "Rice Cracker",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "bolacha de arroz",
      "rice cracker",
      "galleta de arroz"
    ],
    "keywordsStr": "arroz asian asiática asiático bolacha comida cracker de food galleta rice",
    "imgUrl": "assets/emojis/1f358.svg"
  },
  {
    "codes": "1f359",
    "char": "🍙",
    "name": "Rice Ball",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "bolinho de arroz",
      "rice ball",
      "bola de arroz"
    ],
    "keywordsStr": "arroz asian asiática asiático ball bola bolinho comida de food rice",
    "imgUrl": "assets/emojis/1f359.svg"
  },
  {
    "codes": "1f35a",
    "char": "🍚",
    "name": "Cooked Rice",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "arroz cozido",
      "cooked rice",
      "arroz cocido"
    ],
    "keywordsStr": "arroz asian asiática asiático cocido comida cooked cozido food rice",
    "imgUrl": "assets/emojis/1f35a.svg"
  },
  {
    "codes": "1f35b",
    "char": "🍛",
    "name": "Curry Rice",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "arroz de caril",
      "curry rice",
      "arroz al curry"
    ],
    "keywordsStr": "al arroz asian asiática asiático caril comida curry de food rice",
    "imgUrl": "assets/emojis/1f35b.svg"
  },
  {
    "codes": "1f35c",
    "char": "🍜",
    "name": "Steaming Bowl",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "taça fumegante",
      "steaming bowl",
      "cuenco humeante"
    ],
    "keywordsStr": "asian asiática asiático bowl comida cuenco food fumegante humeante steaming taça",
    "imgUrl": "assets/emojis/1f35c.svg"
  },
  {
    "codes": "1f35d",
    "char": "🍝",
    "name": "Spaghetti",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "espaguete",
      "spaghetti",
      "espagueti"
    ],
    "keywordsStr": "asian asiática asiático comida espaguete espagueti food spaghetti",
    "imgUrl": "assets/emojis/1f35d.svg"
  },
  {
    "codes": "1f360",
    "char": "🍠",
    "name": "Roasted Sweet Potato",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "batata doce assada",
      "roasted sweet potato",
      "batata asada"
    ],
    "keywordsStr": "asada asian asiática asiático assada batata comida doce food potato roasted sweet",
    "imgUrl": "assets/emojis/1f360.svg"
  },
  {
    "codes": "1f362",
    "char": "🍢",
    "name": "Oden",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "oden"
    ],
    "keywordsStr": "asian asiática asiático comida food oden",
    "imgUrl": "assets/emojis/1f362.svg"
  },
  {
    "codes": "1f363",
    "char": "🍣",
    "name": "Sushi",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "sushi"
    ],
    "keywordsStr": "asian asiática asiático comida food sushi",
    "imgUrl": "assets/emojis/1f363.svg"
  },
  {
    "codes": "1f364",
    "char": "🍤",
    "name": "Fried Shrimp",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "camarão frito",
      "fried shrimp",
      "camarones fritos"
    ],
    "keywordsStr": "asian asiática asiático camarones camarão comida food fried frito fritos shrimp",
    "imgUrl": "assets/emojis/1f364.svg"
  },
  {
    "codes": "1f365",
    "char": "🍥",
    "name": "Fish Cake With Swirl",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "bolo de peixe com redemoinho",
      "fish cake with swirl",
      "pastel de pescado con remolino"
    ],
    "keywordsStr": "asian asiática asiático bolo cake com comida con de fish food pastel peixe pescado redemoinho remolino swirl with",
    "imgUrl": "assets/emojis/1f365.svg"
  },
  {
    "codes": "1f96e",
    "char": "🥮",
    "name": "Moon Cake",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "bolo da lua",
      "moon cake",
      "pastel de luna"
    ],
    "keywordsStr": "asian asiática asiático bolo cake comida da de food lua luna moon pastel",
    "imgUrl": "assets/emojis/1f96e.svg"
  },
  {
    "codes": "1f361",
    "char": "🍡",
    "name": "Dango",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "dango"
    ],
    "keywordsStr": "asian asiática asiático comida dango food",
    "imgUrl": "assets/emojis/1f361.svg"
  },
  {
    "codes": "1f95f",
    "char": "🥟",
    "name": "Dumpling",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "bolinho",
      "dumpling",
      "bola de masa hervida"
    ],
    "keywordsStr": "asian asiática asiático bola bolinho comida de dumpling food hervida masa",
    "imgUrl": "assets/emojis/1f95f.svg"
  },
  {
    "codes": "1f960",
    "char": "🥠",
    "name": "Fortune Cookie",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "biscoito da sorte",
      "fortune cookie",
      "galleta de la fortuna"
    ],
    "keywordsStr": "asian asiática asiático biscoito comida cookie da de food fortuna fortune galleta la sorte",
    "imgUrl": "assets/emojis/1f960.svg"
  },
  {
    "codes": "1f961",
    "char": "🥡",
    "name": "Takeout Box",
    "category": "Food & Drink (food-asian)",
    "group": "foodAndDrink",
    "subgroup": "food-asian",
    "keywords": [
      "comida",
      "food",
      "asiático",
      "asian",
      "comida asiática",
      "food asian",
      "caixa para viagem",
      "takeout box",
      "caja de comida para llevar"
    ],
    "keywordsStr": "asian asiática asiático box caixa caja comida de food llevar para takeout viagem",
    "imgUrl": "assets/emojis/1f961.svg"
  },
  {
    "codes": "1f980",
    "char": "🦀",
    "name": "Crab",
    "category": "Food & Drink (food-marine)",
    "group": "foodAndDrink",
    "subgroup": "food-marine",
    "keywords": [
      "comida",
      "food",
      "marinha",
      "marine",
      "marino",
      "alimentos marinhos",
      "food marine",
      "comida marina",
      "caranguejo",
      "crab",
      "cangrejo"
    ],
    "keywordsStr": "alimentos cangrejo caranguejo comida crab food marina marine marinha marinhos marino",
    "imgUrl": "assets/emojis/1f980.svg"
  },
  {
    "codes": "1f99e",
    "char": "🦞",
    "name": "Lobster",
    "category": "Food & Drink (food-marine)",
    "group": "foodAndDrink",
    "subgroup": "food-marine",
    "keywords": [
      "comida",
      "food",
      "marinha",
      "marine",
      "marino",
      "alimentos marinhos",
      "food marine",
      "comida marina",
      "lagosta",
      "lobster",
      "langosta"
    ],
    "keywordsStr": "alimentos comida food lagosta langosta lobster marina marine marinha marinhos marino",
    "imgUrl": "assets/emojis/1f99e.svg"
  },
  {
    "codes": "1f990",
    "char": "🦐",
    "name": "Shrimp",
    "category": "Food & Drink (food-marine)",
    "group": "foodAndDrink",
    "subgroup": "food-marine",
    "keywords": [
      "comida",
      "food",
      "marinha",
      "marine",
      "marino",
      "alimentos marinhos",
      "food marine",
      "comida marina",
      "camarão",
      "shrimp",
      "camarón"
    ],
    "keywordsStr": "alimentos camarão camarón comida food marina marine marinha marinhos marino shrimp",
    "imgUrl": "assets/emojis/1f990.svg"
  },
  {
    "codes": "1f991",
    "char": "🦑",
    "name": "Squid",
    "category": "Food & Drink (food-marine)",
    "group": "foodAndDrink",
    "subgroup": "food-marine",
    "keywords": [
      "comida",
      "food",
      "marinha",
      "marine",
      "marino",
      "alimentos marinhos",
      "food marine",
      "comida marina",
      "lula",
      "squid",
      "calamar"
    ],
    "keywordsStr": "alimentos calamar comida food lula marina marine marinha marinhos marino squid",
    "imgUrl": "assets/emojis/1f991.svg"
  },
  {
    "codes": "1f9aa",
    "char": "🦪",
    "name": "Oyster",
    "category": "Food & Drink (food-marine)",
    "group": "foodAndDrink",
    "subgroup": "food-marine",
    "keywords": [
      "comida",
      "food",
      "marinha",
      "marine",
      "marino",
      "alimentos marinhos",
      "food marine",
      "comida marina",
      "ostra",
      "oyster"
    ],
    "keywordsStr": "alimentos comida food marina marine marinha marinhos marino ostra oyster",
    "imgUrl": "assets/emojis/1f9aa.svg"
  },
  {
    "codes": "1f366",
    "char": "🍦",
    "name": "Soft Ice Cream",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "sorvete macio",
      "soft ice cream",
      "helado suave"
    ],
    "keywordsStr": "comida cream doce dulce food helado ice macio soft sorvete suave sweet",
    "imgUrl": "assets/emojis/1f366.svg"
  },
  {
    "codes": "1f367",
    "char": "🍧",
    "name": "Shaved Ice",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "gelo raspado",
      "shaved ice",
      "hielo raspado"
    ],
    "keywordsStr": "comida doce dulce food gelo hielo ice raspado shaved sweet",
    "imgUrl": "assets/emojis/1f367.svg"
  },
  {
    "codes": "1f368",
    "char": "🍨",
    "name": "Ice Cream",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "sorvete",
      "ice cream",
      "helado"
    ],
    "keywordsStr": "comida cream doce dulce food helado ice sorvete sweet",
    "imgUrl": "assets/emojis/1f368.svg"
  },
  {
    "codes": "1f369",
    "char": "🍩",
    "name": "Doughnut",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "rosquinha",
      "doughnut",
      "rosquilla"
    ],
    "keywordsStr": "comida doce doughnut dulce food rosquilla rosquinha sweet",
    "imgUrl": "assets/emojis/1f369.svg"
  },
  {
    "codes": "1f36a",
    "char": "🍪",
    "name": "Cookie",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "biscoito",
      "cookie",
      "galleta"
    ],
    "keywordsStr": "biscoito comida cookie doce dulce food galleta sweet",
    "imgUrl": "assets/emojis/1f36a.svg"
  },
  {
    "codes": "1f382",
    "char": "🎂",
    "name": "Birthday Cake",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "bolo de aniversário",
      "birthday cake",
      "pastel de cumpleaños"
    ],
    "keywordsStr": "aniversário birthday bolo cake comida cumpleaños de doce dulce food pastel sweet",
    "imgUrl": "assets/emojis/1f382.svg"
  },
  {
    "codes": "1f370",
    "char": "🍰",
    "name": "Shortcake",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "bolo curto",
      "shortcake",
      "tarta"
    ],
    "keywordsStr": "bolo comida curto doce dulce food shortcake sweet tarta",
    "imgUrl": "assets/emojis/1f370.svg"
  },
  {
    "codes": "1f9c1",
    "char": "🧁",
    "name": "Cupcake",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "cupcake",
      "magdalena"
    ],
    "keywordsStr": "comida cupcake doce dulce food magdalena sweet",
    "imgUrl": "assets/emojis/1f9c1.svg"
  },
  {
    "codes": "1f967",
    "char": "🥧",
    "name": "Pie",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "torta",
      "pie",
      "pastel"
    ],
    "keywordsStr": "comida doce dulce food pastel pie sweet torta",
    "imgUrl": "assets/emojis/1f967.svg"
  },
  {
    "codes": "1f36b",
    "char": "🍫",
    "name": "Chocolate Bar",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "barra de chocolate",
      "chocolate bar"
    ],
    "keywordsStr": "bar barra chocolate comida de doce dulce food sweet",
    "imgUrl": "assets/emojis/1f36b.svg"
  },
  {
    "codes": "1f36c",
    "char": "🍬",
    "name": "Candy",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "candy",
      "caramelo"
    ],
    "keywordsStr": "candy caramelo comida doce dulce food sweet",
    "imgUrl": "assets/emojis/1f36c.svg"
  },
  {
    "codes": "1f36d",
    "char": "🍭",
    "name": "Lollipop",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "pirulito",
      "lollipop",
      "piruleta"
    ],
    "keywordsStr": "comida doce dulce food lollipop piruleta pirulito sweet",
    "imgUrl": "assets/emojis/1f36d.svg"
  },
  {
    "codes": "1f36e",
    "char": "🍮",
    "name": "Custard",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "creme",
      "custard",
      "flan"
    ],
    "keywordsStr": "comida creme custard doce dulce flan food sweet",
    "imgUrl": "assets/emojis/1f36e.svg"
  },
  {
    "codes": "1f36f",
    "char": "🍯",
    "name": "Honey Pot",
    "category": "Food & Drink (food-sweet)",
    "group": "foodAndDrink",
    "subgroup": "food-sweet",
    "keywords": [
      "comida",
      "food",
      "doce",
      "sweet",
      "dulce",
      "comida doce",
      "food sweet",
      "comida dulce",
      "pote de mel",
      "honey pot",
      "tarro de miel"
    ],
    "keywordsStr": "comida de doce dulce food honey mel miel pot pote sweet tarro",
    "imgUrl": "assets/emojis/1f36f.svg"
  },
  {
    "codes": "1f37c",
    "char": "🍼",
    "name": "Baby Bottle",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "biberão",
      "baby bottle",
      "biberón"
    ],
    "keywordsStr": "baby beber biberão biberón bottle drink",
    "imgUrl": "assets/emojis/1f37c.svg"
  },
  {
    "codes": "1f95b",
    "char": "🥛",
    "name": "Glass Of Milk",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copo de leite",
      "glass of milk",
      "vaso de leche"
    ],
    "keywordsStr": "beber copo de drink glass leche leite milk of vaso",
    "imgUrl": "assets/emojis/1f95b.svg"
  },
  {
    "codes": "2615",
    "char": "☕",
    "name": "Hot Beverage",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "bebida quente",
      "hot beverage",
      "bebida caliente"
    ],
    "keywordsStr": "beber bebida beverage caliente drink hot quente",
    "imgUrl": "assets/emojis/2615.svg"
  },
  {
    "codes": "1fad6",
    "char": "🫖",
    "name": "Teapot",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "bule",
      "teapot",
      "tetera"
    ],
    "keywordsStr": "beber bule drink teapot tetera",
    "imgUrl": "assets/emojis/1fad6.svg"
  },
  {
    "codes": "1f375",
    "char": "🍵",
    "name": "Teacup Without Handle",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "xícara de chá sem alça",
      "teacup without handle",
      "taza de té sin asa"
    ],
    "keywordsStr": "alça asa beber chá de drink handle sem sin taza teacup té without xícara",
    "imgUrl": "assets/emojis/1f375.svg"
  },
  {
    "codes": "1f376",
    "char": "🍶",
    "name": "Sake",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "saúde",
      "sake",
      "bien"
    ],
    "keywordsStr": "beber bien drink sake saúde",
    "imgUrl": "assets/emojis/1f376.svg"
  },
  {
    "codes": "1f37e",
    "char": "🍾",
    "name": "Bottle With Popping Cork",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "garrafa com rolha estourando",
      "bottle with popping cork",
      "botella con corcho que revienta"
    ],
    "keywordsStr": "beber botella bottle com con corcho cork drink estourando garrafa popping que revienta rolha with",
    "imgUrl": "assets/emojis/1f37e.svg"
  },
  {
    "codes": "1f377",
    "char": "🍷",
    "name": "Wine Glass",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copo de vinho",
      "wine glass",
      "copa de vino"
    ],
    "keywordsStr": "beber copa copo de drink glass vinho vino wine",
    "imgUrl": "assets/emojis/1f377.svg"
  },
  {
    "codes": "1f378",
    "char": "🍸",
    "name": "Cocktail Glass",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copo coquetel",
      "cocktail glass",
      "copa de coctel"
    ],
    "keywordsStr": "beber cocktail coctel copa copo coquetel de drink glass",
    "imgUrl": "assets/emojis/1f378.svg"
  },
  {
    "codes": "1f379",
    "char": "🍹",
    "name": "Tropical Drink",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "bebida tropical",
      "tropical drink"
    ],
    "keywordsStr": "beber bebida drink tropical",
    "imgUrl": "assets/emojis/1f379.svg"
  },
  {
    "codes": "1f37a",
    "char": "🍺",
    "name": "Beer Mug",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "caneca de cerveja",
      "beer mug",
      "jarra de cerveza"
    ],
    "keywordsStr": "beber beer caneca cerveja cerveza de drink jarra mug",
    "imgUrl": "assets/emojis/1f37a.svg"
  },
  {
    "codes": "1f37b",
    "char": "🍻",
    "name": "Clinking Beer Mugs",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "canecas de cerveja tilintando",
      "clinking beer mugs",
      "jarras de cerveza tintineando"
    ],
    "keywordsStr": "beber beer canecas cerveja cerveza clinking de drink jarras mugs tilintando tintineando",
    "imgUrl": "assets/emojis/1f37b.svg"
  },
  {
    "codes": "1f942",
    "char": "🥂",
    "name": "Clinking Glasses",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copos tinindo",
      "clinking glasses",
      "vasos tintineando"
    ],
    "keywordsStr": "beber clinking copos drink glasses tinindo tintineando vasos",
    "imgUrl": "assets/emojis/1f942.svg"
  },
  {
    "codes": "1f943",
    "char": "🥃",
    "name": "Tumbler Glass",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copo copo",
      "tumbler glass",
      "vaso de vaso"
    ],
    "keywordsStr": "beber copo de drink glass tumbler vaso",
    "imgUrl": "assets/emojis/1f943.svg"
  },
  {
    "codes": "1f964",
    "char": "🥤",
    "name": "Cup With Straw",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "copo com canudo",
      "cup with straw",
      "taza con pajita"
    ],
    "keywordsStr": "beber canudo com con copo cup drink pajita straw taza with",
    "imgUrl": "assets/emojis/1f964.svg"
  },
  {
    "codes": "1f9cb",
    "char": "🧋",
    "name": "Bubble Tea",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "chá bolha",
      "bubble tea",
      "té de burbujas"
    ],
    "keywordsStr": "beber bolha bubble burbujas chá de drink tea té",
    "imgUrl": "assets/emojis/1f9cb.svg"
  },
  {
    "codes": "1f9c3",
    "char": "🧃",
    "name": "Beverage Box",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "caixa de bebidas",
      "beverage box",
      "caja de bebidas"
    ],
    "keywordsStr": "beber bebidas beverage box caixa caja de drink",
    "imgUrl": "assets/emojis/1f9c3.svg"
  },
  {
    "codes": "1f9c9",
    "char": "🧉",
    "name": "Mate",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "companheiro",
      "mate",
      "compañero"
    ],
    "keywordsStr": "beber companheiro compañero drink mate",
    "imgUrl": "assets/emojis/1f9c9.svg"
  },
  {
    "codes": "1f9ca",
    "char": "🧊",
    "name": "Ice",
    "category": "Food & Drink (drink)",
    "group": "foodAndDrink",
    "subgroup": "drink",
    "keywords": [
      "beber",
      "drink",
      "gelo",
      "ice",
      "hielo"
    ],
    "keywordsStr": "beber drink gelo hielo ice",
    "imgUrl": "assets/emojis/1f9ca.svg"
  },
  {
    "codes": "1f962",
    "char": "🥢",
    "name": "Chopsticks",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "pauzinhos",
      "chopsticks",
      "palillos"
    ],
    "keywordsStr": "chopsticks dishware louça palillos pauzinhos vajilla",
    "imgUrl": "assets/emojis/1f962.svg"
  },
  {
    "codes": "1f37d",
    "char": "🍽️",
    "name": "Fork And Knife With Plate",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "garfo e faca com prato",
      "fork and knife with plate",
      "tenedor y cuchillo con plato"
    ],
    "keywordsStr": "and com con cuchillo dishware e faca fork garfo knife louça plate plato prato tenedor vajilla with y",
    "imgUrl": "assets/emojis/1f37d.svg"
  },
  {
    "codes": "1f374",
    "char": "🍴",
    "name": "Fork And Knife",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "garfo e faca",
      "fork and knife",
      "tenedor y cuchillo"
    ],
    "keywordsStr": "and cuchillo dishware e faca fork garfo knife louça tenedor vajilla y",
    "imgUrl": "assets/emojis/1f374.svg"
  },
  {
    "codes": "1f944",
    "char": "🥄",
    "name": "Spoon",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "colher",
      "spoon",
      "cuchara"
    ],
    "keywordsStr": "colher cuchara dishware louça spoon vajilla",
    "imgUrl": "assets/emojis/1f944.svg"
  },
  {
    "codes": "1f52a",
    "char": "🔪",
    "name": "Kitchen Knife",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "faca de cozinha",
      "kitchen knife",
      "cuchillo de cocina"
    ],
    "keywordsStr": "cocina cozinha cuchillo de dishware faca kitchen knife louça vajilla",
    "imgUrl": "assets/emojis/1f52a.svg"
  },
  {
    "codes": "1f3fa",
    "char": "🏺",
    "name": "Amphora",
    "category": "Food & Drink (dishware)",
    "group": "foodAndDrink",
    "subgroup": "dishware",
    "keywords": [
      "louça",
      "dishware",
      "vajilla",
      "ânfora",
      "amphora",
      "ánfora"
    ],
    "keywordsStr": "amphora dishware louça vajilla ánfora ânfora",
    "imgUrl": "assets/emojis/1f3fa.svg"
  },
  {
    "codes": "1f30d",
    "char": "🌍",
    "name": "Globe Showing Europe Africa",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "globo mostrando Europa-África",
      "globe showing Europe-Africa",
      "globo que muestra Europa-África"
    ],
    "keywordsStr": "Europa-África Europe-Africa de globe globo lugar lugares map mapa mostrando muestra place que showing",
    "imgUrl": "assets/emojis/1f30d.svg"
  },
  {
    "codes": "1f30e",
    "char": "🌎",
    "name": "Globe Showing Americas",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "globo mostrando as Américas",
      "globe showing Americas",
      "globo que muestra las Américas"
    ],
    "keywordsStr": "Americas Américas as de globe globo las lugar lugares map mapa mostrando muestra place que showing",
    "imgUrl": "assets/emojis/1f30e.svg"
  },
  {
    "codes": "1f30f",
    "char": "🌏",
    "name": "Globe Showing Asia Australia",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "globo mostrando Ásia-Austrália",
      "globe showing Asia-Australia",
      "globo que muestra Asia-Australia"
    ],
    "keywordsStr": "Asia-Australia de globe globo lugar lugares map mapa mostrando muestra place que showing Ásia-Austrália",
    "imgUrl": "assets/emojis/1f30f.svg"
  },
  {
    "codes": "1f310",
    "char": "🌐",
    "name": "Globe With Meridians",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "globo com meridianos",
      "globe with meridians",
      "globo con meridianos"
    ],
    "keywordsStr": "com con de globe globo lugar lugares map mapa meridianos meridians place with",
    "imgUrl": "assets/emojis/1f310.svg"
  },
  {
    "codes": "1f5fa",
    "char": "🗺️",
    "name": "World Map",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "mapa do mundo",
      "world map",
      "mapa del mundo"
    ],
    "keywordsStr": "de del do lugar lugares map mapa mundo place world",
    "imgUrl": "assets/emojis/1f5fa.svg"
  },
  {
    "codes": "1f5fe",
    "char": "🗾",
    "name": "Map Of Japan",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "mapa do Japão",
      "map of Japan",
      "mapa de Japón"
    ],
    "keywordsStr": "Japan Japão Japón de do lugar lugares map mapa of place",
    "imgUrl": "assets/emojis/1f5fe.svg"
  },
  {
    "codes": "1f9ed",
    "char": "🧭",
    "name": "Compass",
    "category": "Travel & Places (place-map)",
    "group": "travelAndPlaces",
    "subgroup": "place-map",
    "keywords": [
      "lugar",
      "place",
      "mapa",
      "map",
      "mapa de lugares",
      "place map",
      "mapa de lugar",
      "bússola",
      "compass",
      "brújula"
    ],
    "keywordsStr": "brújula bússola compass de lugar lugares map mapa place",
    "imgUrl": "assets/emojis/1f9ed.svg"
  },
  {
    "codes": "1f3d4",
    "char": "🏔️",
    "name": "Snow Capped Mountain",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "montanha coberta de neve",
      "snow-capped mountain",
      "montaña cubierta de nieve"
    ],
    "keywordsStr": "coberta cubierta de geographic geográfico lugar montanha montaña mountain neve nieve place snow-capped",
    "imgUrl": "assets/emojis/1f3d4.svg"
  },
  {
    "codes": "26f0",
    "char": "⛰️",
    "name": "Mountain",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "montanha",
      "mountain",
      "montaña"
    ],
    "keywordsStr": "geographic geográfico lugar montanha montaña mountain place",
    "imgUrl": "assets/emojis/26f0.svg"
  },
  {
    "codes": "1f30b",
    "char": "🌋",
    "name": "Volcano",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "vulcão",
      "volcano",
      "volcán"
    ],
    "keywordsStr": "geographic geográfico lugar place volcano volcán vulcão",
    "imgUrl": "assets/emojis/1f30b.svg"
  },
  {
    "codes": "1f5fb",
    "char": "🗻",
    "name": "Mount Fuji",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "monte fuji",
      "mount fuji"
    ],
    "keywordsStr": "fuji geographic geográfico lugar monte mount place",
    "imgUrl": "assets/emojis/1f5fb.svg"
  },
  {
    "codes": "1f3d5",
    "char": "🏕️",
    "name": "Camping",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "camping",
      "campamento"
    ],
    "keywordsStr": "campamento camping geographic geográfico lugar place",
    "imgUrl": "assets/emojis/1f3d5.svg"
  },
  {
    "codes": "1f3d6",
    "char": "🏖️",
    "name": "Beach With Umbrella",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "praia com guarda-chuva",
      "beach with umbrella",
      "playa con sombrilla"
    ],
    "keywordsStr": "beach com con geographic geográfico guarda-chuva lugar place playa praia sombrilla umbrella with",
    "imgUrl": "assets/emojis/1f3d6.svg"
  },
  {
    "codes": "1f3dc",
    "char": "🏜️",
    "name": "Desert",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "deserto",
      "desert",
      "desierto"
    ],
    "keywordsStr": "desert deserto desierto geographic geográfico lugar place",
    "imgUrl": "assets/emojis/1f3dc.svg"
  },
  {
    "codes": "1f3dd",
    "char": "🏝️",
    "name": "Desert Island",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "ilha deserta",
      "desert island",
      "isla desierta"
    ],
    "keywordsStr": "desert deserta desierta geographic geográfico ilha isla island lugar place",
    "imgUrl": "assets/emojis/1f3dd.svg"
  },
  {
    "codes": "1f3de",
    "char": "🏞️",
    "name": "National Park",
    "category": "Travel & Places (place-geographic)",
    "group": "travelAndPlaces",
    "subgroup": "place-geographic",
    "keywords": [
      "lugar",
      "place",
      "geográfico",
      "geographic",
      "lugar geográfico",
      "place geographic",
      "parque nacional",
      "national park"
    ],
    "keywordsStr": "geographic geográfico lugar nacional national park parque place",
    "imgUrl": "assets/emojis/1f3de.svg"
  },
  {
    "codes": "1f3df",
    "char": "🏟️",
    "name": "Stadium",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "estádio",
      "stadium",
      "estadio"
    ],
    "keywordsStr": "building construcción construção del do edificio estadio estádio lugar place stadium",
    "imgUrl": "assets/emojis/1f3df.svg"
  },
  {
    "codes": "1f3db",
    "char": "🏛️",
    "name": "Classical Building",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "edifício clássico",
      "classical building",
      "edificio clásico"
    ],
    "keywordsStr": "building classical clásico clássico construcción construção del do edificio edifício lugar place",
    "imgUrl": "assets/emojis/1f3db.svg"
  },
  {
    "codes": "1f3d7",
    "char": "🏗️",
    "name": "Building Construction",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "construção de edifícios",
      "building construction",
      "construcción de edificios"
    ],
    "keywordsStr": "building construcción construction construção de del do edificio edificios edifícios lugar place",
    "imgUrl": "assets/emojis/1f3d7.svg"
  },
  {
    "codes": "1f9f1",
    "char": "🧱",
    "name": "Brick",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "tijolo",
      "brick",
      "ladrillo"
    ],
    "keywordsStr": "brick building construcción construção del do edificio ladrillo lugar place tijolo",
    "imgUrl": "assets/emojis/1f9f1.svg"
  },
  {
    "codes": "1faa8",
    "char": "🪨",
    "name": "Rock",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "rocha",
      "rock",
      "roca"
    ],
    "keywordsStr": "building construcción construção del do edificio lugar place roca rocha rock",
    "imgUrl": "assets/emojis/1faa8.svg"
  },
  {
    "codes": "1fab5",
    "char": "🪵",
    "name": "Wood",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "madeira",
      "wood",
      "madera"
    ],
    "keywordsStr": "building construcción construção del do edificio lugar madeira madera place wood",
    "imgUrl": "assets/emojis/1fab5.svg"
  },
  {
    "codes": "1f6d6",
    "char": "🛖",
    "name": "Hut",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "cabana",
      "hut",
      "choza"
    ],
    "keywordsStr": "building cabana choza construcción construção del do edificio hut lugar place",
    "imgUrl": "assets/emojis/1f6d6.svg"
  },
  {
    "codes": "1f3d8",
    "char": "🏘️",
    "name": "Houses",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "casas",
      "houses"
    ],
    "keywordsStr": "building casas construcción construção del do edificio houses lugar place",
    "imgUrl": "assets/emojis/1f3d8.svg"
  },
  {
    "codes": "1f3da",
    "char": "🏚️",
    "name": "Derelict House",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "casa abandonada",
      "derelict house"
    ],
    "keywordsStr": "abandonada building casa construcción construção del derelict do edificio house lugar place",
    "imgUrl": "assets/emojis/1f3da.svg"
  },
  {
    "codes": "1f3e0",
    "char": "🏠",
    "name": "House",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "casa",
      "house"
    ],
    "keywordsStr": "building casa construcción construção del do edificio house lugar place",
    "imgUrl": "assets/emojis/1f3e0.svg"
  },
  {
    "codes": "1f3e1",
    "char": "🏡",
    "name": "House With Garden",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "casa com jardim",
      "house with garden",
      "casa con jardín"
    ],
    "keywordsStr": "building casa com con construcción construção del do edificio garden house jardim jardín lugar place with",
    "imgUrl": "assets/emojis/1f3e1.svg"
  },
  {
    "codes": "1f3e2",
    "char": "🏢",
    "name": "Office Building",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "edifício de escritórios",
      "office building",
      "edificio de oficinas"
    ],
    "keywordsStr": "building construcción construção de del do edificio edifício escritórios lugar office oficinas place",
    "imgUrl": "assets/emojis/1f3e2.svg"
  },
  {
    "codes": "1f3e3",
    "char": "🏣",
    "name": "Japanese Post Office",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "correio japonês",
      "Japanese post office",
      "Oficina de correos japonesa"
    ],
    "keywordsStr": "Japanese Oficina building construcción construção correio correos de del do edificio japonesa japonês lugar office place post",
    "imgUrl": "assets/emojis/1f3e3.svg"
  },
  {
    "codes": "1f3e4",
    "char": "🏤",
    "name": "Post Office",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "correio",
      "post office",
      "oficina de correos"
    ],
    "keywordsStr": "building construcción construção correio correos de del do edificio lugar office oficina place post",
    "imgUrl": "assets/emojis/1f3e4.svg"
  },
  {
    "codes": "1f3e5",
    "char": "🏥",
    "name": "Hospital",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "hospital",
      "hospitales"
    ],
    "keywordsStr": "building construcción construção del do edificio hospital hospitales lugar place",
    "imgUrl": "assets/emojis/1f3e5.svg"
  },
  {
    "codes": "1f3e6",
    "char": "🏦",
    "name": "Bank",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "banco",
      "bank"
    ],
    "keywordsStr": "banco bank building construcción construção del do edificio lugar place",
    "imgUrl": "assets/emojis/1f3e6.svg"
  },
  {
    "codes": "1f3e8",
    "char": "🏨",
    "name": "Hotel",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "hotel",
      "hoteles"
    ],
    "keywordsStr": "building construcción construção del do edificio hotel hoteles lugar place",
    "imgUrl": "assets/emojis/1f3e8.svg"
  },
  {
    "codes": "1f3e9",
    "char": "🏩",
    "name": "Love Hotel",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "hotel do amor",
      "love hotel",
      "hotel del amor"
    ],
    "keywordsStr": "amor building construcción construção del do edificio hotel love lugar place",
    "imgUrl": "assets/emojis/1f3e9.svg"
  },
  {
    "codes": "1f3ea",
    "char": "🏪",
    "name": "Convenience Store",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "loja de conveniência",
      "convenience store",
      "tienda de conveniencia"
    ],
    "keywordsStr": "building construcción construção convenience conveniencia conveniência de del do edificio loja lugar place store tienda",
    "imgUrl": "assets/emojis/1f3ea.svg"
  },
  {
    "codes": "1f3eb",
    "char": "🏫",
    "name": "School",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "escola",
      "school",
      "escuela"
    ],
    "keywordsStr": "building construcción construção del do edificio escola escuela lugar place school",
    "imgUrl": "assets/emojis/1f3eb.svg"
  },
  {
    "codes": "1f3ec",
    "char": "🏬",
    "name": "Department Store",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "loja de departamentos",
      "department store",
      "tienda departamental"
    ],
    "keywordsStr": "building construcción construção de del departamental departamentos department do edificio loja lugar place store tienda",
    "imgUrl": "assets/emojis/1f3ec.svg"
  },
  {
    "codes": "1f3ed",
    "char": "🏭",
    "name": "Factory",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "fábrica",
      "factory"
    ],
    "keywordsStr": "building construcción construção del do edificio factory fábrica lugar place",
    "imgUrl": "assets/emojis/1f3ed.svg"
  },
  {
    "codes": "1f3ef",
    "char": "🏯",
    "name": "Japanese Castle",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "Castelo Japonês",
      "Japanese castle",
      "castillo japonés"
    ],
    "keywordsStr": "Castelo Japanese Japonês building castillo castle construcción construção del do edificio japonés lugar place",
    "imgUrl": "assets/emojis/1f3ef.svg"
  },
  {
    "codes": "1f3f0",
    "char": "🏰",
    "name": "Castle",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "castelo",
      "castle",
      "castillo"
    ],
    "keywordsStr": "building castelo castillo castle construcción construção del do edificio lugar place",
    "imgUrl": "assets/emojis/1f3f0.svg"
  },
  {
    "codes": "1f492",
    "char": "💒",
    "name": "Wedding",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "casamento",
      "wedding",
      "boda"
    ],
    "keywordsStr": "boda building casamento construcción construção del do edificio lugar place wedding",
    "imgUrl": "assets/emojis/1f492.svg"
  },
  {
    "codes": "1f5fc",
    "char": "🗼",
    "name": "Tokyo Tower",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "torre de Tóquio",
      "Tokyo tower",
      "Torre de Tokio"
    ],
    "keywordsStr": "Tokio Tokyo Torre Tóquio building construcción construção de del do edificio lugar place torre tower",
    "imgUrl": "assets/emojis/1f5fc.svg"
  },
  {
    "codes": "1f5fd",
    "char": "🗽",
    "name": "Statue Of Liberty",
    "category": "Travel & Places (place-building)",
    "group": "travelAndPlaces",
    "subgroup": "place-building",
    "keywords": [
      "lugar",
      "place",
      "construção",
      "building",
      "edificio",
      "construção do lugar",
      "place building",
      "construcción del lugar",
      "Estátua da Liberdade",
      "Statue of Liberty",
      "Estatua de la Libertad"
    ],
    "keywordsStr": "Estatua Estátua Liberdade Libertad Liberty Statue building construcción construção da de del do edificio la lugar of place",
    "imgUrl": "assets/emojis/1f5fd.svg"
  },
  {
    "codes": "26ea",
    "char": "⛪",
    "name": "Church",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "igreja",
      "church",
      "iglesia"
    ],
    "keywordsStr": "church iglesia igreja lugar place religioso religious",
    "imgUrl": "assets/emojis/26ea.svg"
  },
  {
    "codes": "1f54c",
    "char": "🕌",
    "name": "Mosque",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "mesquita",
      "mosque",
      "mezquita"
    ],
    "keywordsStr": "lugar mesquita mezquita mosque place religioso religious",
    "imgUrl": "assets/emojis/1f54c.svg"
  },
  {
    "codes": "1f6d5",
    "char": "🛕",
    "name": "Hindu Temple",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "templo hindu",
      "hindu temple",
      "templo hindú"
    ],
    "keywordsStr": "hindu hindú lugar place religioso religious temple templo",
    "imgUrl": "assets/emojis/1f6d5.svg"
  },
  {
    "codes": "1f54d",
    "char": "🕍",
    "name": "Synagogue",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "sinagoga",
      "synagogue"
    ],
    "keywordsStr": "lugar place religioso religious sinagoga synagogue",
    "imgUrl": "assets/emojis/1f54d.svg"
  },
  {
    "codes": "26e9",
    "char": "⛩️",
    "name": "Shinto Shrine",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "santuário xintoísta",
      "shinto shrine",
      "santuario sintoísta"
    ],
    "keywordsStr": "lugar place religioso religious santuario santuário shinto shrine sintoísta xintoísta",
    "imgUrl": "assets/emojis/26e9.svg"
  },
  {
    "codes": "1f54b",
    "char": "🕋",
    "name": "Kaaba",
    "category": "Travel & Places (place-religious)",
    "group": "travelAndPlaces",
    "subgroup": "place-religious",
    "keywords": [
      "lugar",
      "place",
      "religioso",
      "religious",
      "lugar religioso",
      "place religious",
      "caaba",
      "kaaba"
    ],
    "keywordsStr": "caaba kaaba lugar place religioso religious",
    "imgUrl": "assets/emojis/1f54b.svg"
  },
  {
    "codes": "26f2",
    "char": "⛲",
    "name": "Fountain",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "fonte",
      "fountain",
      "fuente"
    ],
    "keywordsStr": "colocar fonte fountain fuente lugar other otro outro place",
    "imgUrl": "assets/emojis/26f2.svg"
  },
  {
    "codes": "26fa",
    "char": "⛺",
    "name": "Tent",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "tenda",
      "tent",
      "tienda de campaña"
    ],
    "keywordsStr": "campaña colocar de lugar other otro outro place tenda tent tienda",
    "imgUrl": "assets/emojis/26fa.svg"
  },
  {
    "codes": "1f301",
    "char": "🌁",
    "name": "Foggy",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "nevoeiro",
      "foggy",
      "brumoso"
    ],
    "keywordsStr": "brumoso colocar foggy lugar nevoeiro other otro outro place",
    "imgUrl": "assets/emojis/1f301.svg"
  },
  {
    "codes": "1f303",
    "char": "🌃",
    "name": "Night With Stars",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "noite com estrelas",
      "night with stars",
      "noche con estrellas"
    ],
    "keywordsStr": "colocar com con estrelas estrellas lugar night noche noite other otro outro place stars with",
    "imgUrl": "assets/emojis/1f303.svg"
  },
  {
    "codes": "1f3d9",
    "char": "🏙️",
    "name": "Cityscape",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "paisagem urbana",
      "cityscape",
      "paisaje urbano"
    ],
    "keywordsStr": "cityscape colocar lugar other otro outro paisagem paisaje place urbana urbano",
    "imgUrl": "assets/emojis/1f3d9.svg"
  },
  {
    "codes": "1f304",
    "char": "🌄",
    "name": "Sunrise Over Mountains",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "nascer do sol sobre as montanhas",
      "sunrise over mountains",
      "amanecer sobre las montañas"
    ],
    "keywordsStr": "amanecer as colocar do las lugar montanhas montañas mountains nascer other otro outro over place sobre sol sunrise",
    "imgUrl": "assets/emojis/1f304.svg"
  },
  {
    "codes": "1f305",
    "char": "🌅",
    "name": "Sunrise",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "nascer do sol",
      "sunrise",
      "amanecer"
    ],
    "keywordsStr": "amanecer colocar do lugar nascer other otro outro place sol sunrise",
    "imgUrl": "assets/emojis/1f305.svg"
  },
  {
    "codes": "1f306",
    "char": "🌆",
    "name": "Cityscape At Dusk",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "paisagem urbana ao entardecer",
      "cityscape at dusk",
      "paisaje urbano al atardecer"
    ],
    "keywordsStr": "al ao at atardecer cityscape colocar dusk entardecer lugar other otro outro paisagem paisaje place urbana urbano",
    "imgUrl": "assets/emojis/1f306.svg"
  },
  {
    "codes": "1f307",
    "char": "🌇",
    "name": "Sunset",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "pôr do sol",
      "sunset",
      "puesta de sol"
    ],
    "keywordsStr": "colocar de do lugar other otro outro place puesta pôr sol sunset",
    "imgUrl": "assets/emojis/1f307.svg"
  },
  {
    "codes": "1f309",
    "char": "🌉",
    "name": "Bridge At Night",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "ponte à noite",
      "bridge at night",
      "puente de noche"
    ],
    "keywordsStr": "at bridge colocar de lugar night noche noite other otro outro place ponte puente à",
    "imgUrl": "assets/emojis/1f309.svg"
  },
  {
    "codes": "2668",
    "char": "♨️",
    "name": "Hot Springs",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "fontes termais",
      "hot springs",
      "aguas termales"
    ],
    "keywordsStr": "aguas colocar fontes hot lugar other otro outro place springs termais termales",
    "imgUrl": "assets/emojis/2668.svg"
  },
  {
    "codes": "1f3a0",
    "char": "🎠",
    "name": "Carousel Horse",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "cavalo carrossel",
      "carousel horse",
      "caballo de carrusel"
    ],
    "keywordsStr": "caballo carousel carrossel carrusel cavalo colocar de horse lugar other otro outro place",
    "imgUrl": "assets/emojis/1f3a0.svg"
  },
  {
    "codes": "1f3a1",
    "char": "🎡",
    "name": "Ferris Wheel",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "roda gigante",
      "ferris wheel",
      "rueda de la fortuna"
    ],
    "keywordsStr": "colocar de ferris fortuna gigante la lugar other otro outro place roda rueda wheel",
    "imgUrl": "assets/emojis/1f3a1.svg"
  },
  {
    "codes": "1f3a2",
    "char": "🎢",
    "name": "Roller Coaster",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "montanha russa",
      "roller coaster",
      "montaña rusa"
    ],
    "keywordsStr": "coaster colocar lugar montanha montaña other otro outro place roller rusa russa",
    "imgUrl": "assets/emojis/1f3a2.svg"
  },
  {
    "codes": "1f488",
    "char": "💈",
    "name": "Barber Pole",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "poste de barbeiro",
      "barber pole",
      "poste de barbero"
    ],
    "keywordsStr": "barbeiro barber barbero colocar de lugar other otro outro place pole poste",
    "imgUrl": "assets/emojis/1f488.svg"
  },
  {
    "codes": "1f3aa",
    "char": "🎪",
    "name": "Circus Tent",
    "category": "Travel & Places (place-other)",
    "group": "travelAndPlaces",
    "subgroup": "place-other",
    "keywords": [
      "lugar",
      "place",
      "outro",
      "other",
      "otro",
      "colocar outro",
      "place other",
      "colocar otro",
      "tenda de circo",
      "circus tent",
      "carpa de circo"
    ],
    "keywordsStr": "carpa circo circus colocar de lugar other otro outro place tenda tent",
    "imgUrl": "assets/emojis/1f3aa.svg"
  },
  {
    "codes": "1f682",
    "char": "🚂",
    "name": "Locomotive",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "locomotiva",
      "locomotive",
      "locomotora"
    ],
    "keywordsStr": "de ground locomotiva locomotive locomotora suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f682.svg"
  },
  {
    "codes": "1f683",
    "char": "🚃",
    "name": "Railway Car",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "carro ferroviário",
      "railway car",
      "vagón de tren"
    ],
    "keywordsStr": "car carro de ferroviário ground railway suelo terra terreno terrestre transport transporte tren vagón",
    "imgUrl": "assets/emojis/1f683.svg"
  },
  {
    "codes": "1f684",
    "char": "🚄",
    "name": "High Speed Train",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trem de alta velocidade",
      "high-speed train",
      "tren de alta velocidad"
    ],
    "keywordsStr": "alta de ground high-speed suelo terra terreno terrestre train transport transporte trem tren velocidad velocidade",
    "imgUrl": "assets/emojis/1f684.svg"
  },
  {
    "codes": "1f685",
    "char": "🚅",
    "name": "Bullet Train",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trem bala",
      "bullet train",
      "tren bala"
    ],
    "keywordsStr": "bala bullet de ground suelo terra terreno terrestre train transport transporte trem tren",
    "imgUrl": "assets/emojis/1f685.svg"
  },
  {
    "codes": "1f686",
    "char": "🚆",
    "name": "Train",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trem",
      "train",
      "tren"
    ],
    "keywordsStr": "de ground suelo terra terreno terrestre train transport transporte trem tren",
    "imgUrl": "assets/emojis/1f686.svg"
  },
  {
    "codes": "1f687",
    "char": "🚇",
    "name": "Metro",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "metrô",
      "metro"
    ],
    "keywordsStr": "de ground metro metrô suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f687.svg"
  },
  {
    "codes": "1f688",
    "char": "🚈",
    "name": "Light Rail",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trilho leve",
      "light rail",
      "tren ligero"
    ],
    "keywordsStr": "de ground leve ligero light rail suelo terra terreno terrestre transport transporte tren trilho",
    "imgUrl": "assets/emojis/1f688.svg"
  },
  {
    "codes": "1f689",
    "char": "🚉",
    "name": "Station",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "estação",
      "station",
      "estación"
    ],
    "keywordsStr": "de estación estação ground station suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f689.svg"
  },
  {
    "codes": "1f68a",
    "char": "🚊",
    "name": "Tram",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "bonde",
      "tram",
      "tranvía"
    ],
    "keywordsStr": "bonde de ground suelo terra terreno terrestre tram transport transporte tranvía",
    "imgUrl": "assets/emojis/1f68a.svg"
  },
  {
    "codes": "1f69d",
    "char": "🚝",
    "name": "Monorail",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "monotrilho",
      "monorail",
      "monorraíl"
    ],
    "keywordsStr": "de ground monorail monorraíl monotrilho suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f69d.svg"
  },
  {
    "codes": "1f69e",
    "char": "🚞",
    "name": "Mountain Railway",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "ferrovia de montanha",
      "mountain railway",
      "tren de montaña"
    ],
    "keywordsStr": "de ferrovia ground montanha montaña mountain railway suelo terra terreno terrestre transport transporte tren",
    "imgUrl": "assets/emojis/1f69e.svg"
  },
  {
    "codes": "1f68b",
    "char": "🚋",
    "name": "Tram Car",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "carro elétrico",
      "tram car",
      "vagón de tranvía"
    ],
    "keywordsStr": "car carro de elétrico ground suelo terra terreno terrestre tram transport transporte tranvía vagón",
    "imgUrl": "assets/emojis/1f68b.svg"
  },
  {
    "codes": "1f68c",
    "char": "🚌",
    "name": "Bus",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "ônibus",
      "bus",
      "autobús"
    ],
    "keywordsStr": "autobús bus de ground suelo terra terreno terrestre transport transporte ônibus",
    "imgUrl": "assets/emojis/1f68c.svg"
  },
  {
    "codes": "1f68d",
    "char": "🚍",
    "name": "Oncoming Bus",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "ônibus que se aproxima",
      "oncoming bus",
      "autobús que se aproxima"
    ],
    "keywordsStr": "aproxima autobús bus de ground oncoming que se suelo terra terreno terrestre transport transporte ônibus",
    "imgUrl": "assets/emojis/1f68d.svg"
  },
  {
    "codes": "1f68e",
    "char": "🚎",
    "name": "Trolleybus",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trólebus",
      "trolleybus",
      "trolebús"
    ],
    "keywordsStr": "de ground suelo terra terreno terrestre transport transporte trolebús trolleybus trólebus",
    "imgUrl": "assets/emojis/1f68e.svg"
  },
  {
    "codes": "1f690",
    "char": "🚐",
    "name": "Minibus",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "microônibus",
      "minibus",
      "minibús"
    ],
    "keywordsStr": "de ground microônibus minibus minibús suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f690.svg"
  },
  {
    "codes": "1f691",
    "char": "🚑",
    "name": "Ambulance",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "ambulância",
      "ambulance",
      "ambulancia"
    ],
    "keywordsStr": "ambulance ambulancia ambulância de ground suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f691.svg"
  },
  {
    "codes": "1f692",
    "char": "🚒",
    "name": "Fire Engine",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "motor de incêndio",
      "fire engine",
      "camión de bomberos"
    ],
    "keywordsStr": "bomberos camión de engine fire ground incêndio motor suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f692.svg"
  },
  {
    "codes": "1f693",
    "char": "🚓",
    "name": "Police Car",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "carro de polícia",
      "police car",
      "coche de policía"
    ],
    "keywordsStr": "car carro coche de ground police policía polícia suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f693.svg"
  },
  {
    "codes": "1f694",
    "char": "🚔",
    "name": "Oncoming Police Car",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "carro de polícia que se aproxima",
      "oncoming police car",
      "coche de policía que se aproxima"
    ],
    "keywordsStr": "aproxima car carro coche de ground oncoming police policía polícia que se suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f694.svg"
  },
  {
    "codes": "1f695",
    "char": "🚕",
    "name": "Taxi",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "táxi",
      "taxi"
    ],
    "keywordsStr": "de ground suelo taxi terra terreno terrestre transport transporte táxi",
    "imgUrl": "assets/emojis/1f695.svg"
  },
  {
    "codes": "1f696",
    "char": "🚖",
    "name": "Oncoming Taxi",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "táxi que se aproxima",
      "oncoming taxi",
      "taxi que se aproxima"
    ],
    "keywordsStr": "aproxima de ground oncoming que se suelo taxi terra terreno terrestre transport transporte táxi",
    "imgUrl": "assets/emojis/1f696.svg"
  },
  {
    "codes": "1f697",
    "char": "🚗",
    "name": "Automobile",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "automóvel",
      "automobile",
      "automóvil"
    ],
    "keywordsStr": "automobile automóvel automóvil de ground suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f697.svg"
  },
  {
    "codes": "1f698",
    "char": "🚘",
    "name": "Oncoming Automobile",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "automóvel que se aproxima",
      "oncoming automobile",
      "automóvil que se aproxima"
    ],
    "keywordsStr": "aproxima automobile automóvel automóvil de ground oncoming que se suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f698.svg"
  },
  {
    "codes": "1f699",
    "char": "🚙",
    "name": "Sport Utility Vehicle",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "veículo utilitário esportivo",
      "sport utility vehicle",
      "vehículo utilitario deportivo"
    ],
    "keywordsStr": "de deportivo esportivo ground sport suelo terra terreno terrestre transport transporte utilitario utility utilitário vehicle vehículo veículo",
    "imgUrl": "assets/emojis/1f699.svg"
  },
  {
    "codes": "1f6fb",
    "char": "🛻",
    "name": "Pickup Truck",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "caminhonete",
      "pickup truck",
      "camioneta"
    ],
    "keywordsStr": "caminhonete camioneta de ground pickup suelo terra terreno terrestre transport transporte truck",
    "imgUrl": "assets/emojis/1f6fb.svg"
  },
  {
    "codes": "1f69a",
    "char": "🚚",
    "name": "Delivery Truck",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "caminhão de entrega",
      "delivery truck",
      "camión de reparto"
    ],
    "keywordsStr": "caminhão camión de delivery entrega ground reparto suelo terra terreno terrestre transport transporte truck",
    "imgUrl": "assets/emojis/1f69a.svg"
  },
  {
    "codes": "1f69b",
    "char": "🚛",
    "name": "Articulated Lorry",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "camião articulado",
      "articulated lorry",
      "camión articulado"
    ],
    "keywordsStr": "articulado articulated camião camión de ground lorry suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f69b.svg"
  },
  {
    "codes": "1f69c",
    "char": "🚜",
    "name": "Tractor",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trator",
      "tractor"
    ],
    "keywordsStr": "de ground suelo terra terreno terrestre tractor transport transporte trator",
    "imgUrl": "assets/emojis/1f69c.svg"
  },
  {
    "codes": "1f3ce",
    "char": "🏎️",
    "name": "Racing Car",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "carro de corrida",
      "racing car",
      "coche de carreras"
    ],
    "keywordsStr": "car carreras carro coche corrida de ground racing suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f3ce.svg"
  },
  {
    "codes": "1f3cd",
    "char": "🏍️",
    "name": "Motorcycle",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "motocicleta",
      "motorcycle"
    ],
    "keywordsStr": "de ground motocicleta motorcycle suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f3cd.svg"
  },
  {
    "codes": "1f6f5",
    "char": "🛵",
    "name": "Motor Scooter",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "motocicleta",
      "motor scooter",
      "motoneta"
    ],
    "keywordsStr": "de ground motocicleta motoneta motor scooter suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6f5.svg"
  },
  {
    "codes": "1f9bd",
    "char": "🦽",
    "name": "Manual Wheelchair",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "cadeira de rodas manual",
      "manual wheelchair",
      "silla de ruedas manual"
    ],
    "keywordsStr": "cadeira de ground manual rodas ruedas silla suelo terra terreno terrestre transport transporte wheelchair",
    "imgUrl": "assets/emojis/1f9bd.svg"
  },
  {
    "codes": "1f9bc",
    "char": "🦼",
    "name": "Motorized Wheelchair",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "cadeira de rodas motorizada",
      "motorized wheelchair",
      "silla de ruedas motorizada"
    ],
    "keywordsStr": "cadeira de ground motorizada motorized rodas ruedas silla suelo terra terreno terrestre transport transporte wheelchair",
    "imgUrl": "assets/emojis/1f9bc.svg"
  },
  {
    "codes": "1f6fa",
    "char": "🛺",
    "name": "Auto Rickshaw",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "auto riquixá",
      "auto rickshaw"
    ],
    "keywordsStr": "auto de ground rickshaw riquixá suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6fa.svg"
  },
  {
    "codes": "1f6b2",
    "char": "🚲",
    "name": "Bicycle",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "bicicleta",
      "bicycle"
    ],
    "keywordsStr": "bicicleta bicycle de ground suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6b2.svg"
  },
  {
    "codes": "1f6f4",
    "char": "🛴",
    "name": "Kick Scooter",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trotinette",
      "kick scooter",
      "patinete"
    ],
    "keywordsStr": "de ground kick patinete scooter suelo terra terreno terrestre transport transporte trotinette",
    "imgUrl": "assets/emojis/1f6f4.svg"
  },
  {
    "codes": "1f6f9",
    "char": "🛹",
    "name": "Skateboard",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "skate",
      "skateboard",
      "monopatín"
    ],
    "keywordsStr": "de ground monopatín skate skateboard suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6f9.svg"
  },
  {
    "codes": "1f6fc",
    "char": "🛼",
    "name": "Roller Skate",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "patins",
      "roller skate",
      "patín de ruedas"
    ],
    "keywordsStr": "de ground patins patín roller ruedas skate suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6fc.svg"
  },
  {
    "codes": "1f68f",
    "char": "🚏",
    "name": "Bus Stop",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "parada de ônibus",
      "bus stop",
      "parada de autobús"
    ],
    "keywordsStr": "autobús bus de ground parada stop suelo terra terreno terrestre transport transporte ônibus",
    "imgUrl": "assets/emojis/1f68f.svg"
  },
  {
    "codes": "1f6e3",
    "char": "🛣️",
    "name": "Motorway",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "auto-estrada",
      "motorway",
      "autopista"
    ],
    "keywordsStr": "auto-estrada autopista de ground motorway suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6e3.svg"
  },
  {
    "codes": "1f6e4",
    "char": "🛤️",
    "name": "Railway Track",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "trilho ferroviário",
      "railway track",
      "vía férrea"
    ],
    "keywordsStr": "de ferroviário férrea ground railway suelo terra terreno terrestre track transport transporte trilho vía",
    "imgUrl": "assets/emojis/1f6e4.svg"
  },
  {
    "codes": "1f6e2",
    "char": "🛢️",
    "name": "Oil Drum",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "tambor de óleo",
      "oil drum",
      "tambor de aceite"
    ],
    "keywordsStr": "aceite de drum ground oil suelo tambor terra terreno terrestre transport transporte óleo",
    "imgUrl": "assets/emojis/1f6e2.svg"
  },
  {
    "codes": "26fd",
    "char": "⛽",
    "name": "Fuel Pump",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "bomba de combustível",
      "fuel pump",
      "bomba de combustible"
    ],
    "keywordsStr": "bomba combustible combustível de fuel ground pump suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/26fd.svg"
  },
  {
    "codes": "1f6a8",
    "char": "🚨",
    "name": "Police Car Light",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "luz do carro da polícia",
      "police car light",
      "luz de coche de policía"
    ],
    "keywordsStr": "car carro coche da de do ground light luz police policía polícia suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6a8.svg"
  },
  {
    "codes": "1f6a5",
    "char": "🚥",
    "name": "Horizontal Traffic Light",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "semáforo horizontal",
      "horizontal traffic light"
    ],
    "keywordsStr": "de ground horizontal light semáforo suelo terra terreno terrestre traffic transport transporte",
    "imgUrl": "assets/emojis/1f6a5.svg"
  },
  {
    "codes": "1f6a6",
    "char": "🚦",
    "name": "Vertical Traffic Light",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "semáforo vertical",
      "vertical traffic light"
    ],
    "keywordsStr": "de ground light semáforo suelo terra terreno terrestre traffic transport transporte vertical",
    "imgUrl": "assets/emojis/1f6a6.svg"
  },
  {
    "codes": "1f6d1",
    "char": "🛑",
    "name": "Stop Sign",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "sinal de parada",
      "stop sign",
      "señal de alto"
    ],
    "keywordsStr": "alto de ground parada señal sign sinal stop suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6d1.svg"
  },
  {
    "codes": "1f6a7",
    "char": "🚧",
    "name": "Construction",
    "category": "Travel & Places (transport-ground)",
    "group": "travelAndPlaces",
    "subgroup": "transport-ground",
    "keywords": [
      "transporte",
      "transport",
      "terra",
      "ground",
      "suelo",
      "terreno de transporte",
      "transport ground",
      "transporte terrestre",
      "construção",
      "construction",
      "construcción"
    ],
    "keywordsStr": "construcción construction construção de ground suelo terra terreno terrestre transport transporte",
    "imgUrl": "assets/emojis/1f6a7.svg"
  },
  {
    "codes": "2693",
    "char": "⚓",
    "name": "Anchor",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "âncora",
      "anchor",
      "ancla"
    ],
    "keywordsStr": "agua anchor ancla de transport transporte water água âncora",
    "imgUrl": "assets/emojis/2693.svg"
  },
  {
    "codes": "26f5",
    "char": "⛵",
    "name": "Sailboat",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "veleiro",
      "sailboat",
      "velero"
    ],
    "keywordsStr": "agua de sailboat transport transporte veleiro velero water água",
    "imgUrl": "assets/emojis/26f5.svg"
  },
  {
    "codes": "1f6f6",
    "char": "🛶",
    "name": "Canoe",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "canoa",
      "canoe"
    ],
    "keywordsStr": "agua canoa canoe de transport transporte water água",
    "imgUrl": "assets/emojis/1f6f6.svg"
  },
  {
    "codes": "1f6a4",
    "char": "🚤",
    "name": "Speedboat",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "lancha",
      "speedboat",
      "lancha rápida"
    ],
    "keywordsStr": "agua de lancha rápida speedboat transport transporte water água",
    "imgUrl": "assets/emojis/1f6a4.svg"
  },
  {
    "codes": "1f6f3",
    "char": "🛳️",
    "name": "Passenger Ship",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "navio de passageiros",
      "passenger ship",
      "barco de pasajeros"
    ],
    "keywordsStr": "agua barco de navio pasajeros passageiros passenger ship transport transporte water água",
    "imgUrl": "assets/emojis/1f6f3.svg"
  },
  {
    "codes": "26f4",
    "char": "⛴️",
    "name": "Ferry",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "ferry",
      "transbordador"
    ],
    "keywordsStr": "agua de ferry transbordador transport transporte water água",
    "imgUrl": "assets/emojis/26f4.svg"
  },
  {
    "codes": "1f6e5",
    "char": "🛥️",
    "name": "Motor Boat",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "barco a motor",
      "motor boat"
    ],
    "keywordsStr": "a agua barco boat de motor transport transporte water água",
    "imgUrl": "assets/emojis/1f6e5.svg"
  },
  {
    "codes": "1f6a2",
    "char": "🚢",
    "name": "Ship",
    "category": "Travel & Places (transport-water)",
    "group": "travelAndPlaces",
    "subgroup": "transport-water",
    "keywords": [
      "transporte",
      "transport",
      "água",
      "water",
      "agua",
      "transporte de água",
      "transport water",
      "agua de transporte",
      "navio",
      "ship",
      "barco"
    ],
    "keywordsStr": "agua barco de navio ship transport transporte water água",
    "imgUrl": "assets/emojis/1f6a2.svg"
  },
  {
    "codes": "2708",
    "char": "✈️",
    "name": "Airplane",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "avião",
      "airplane",
      "avión"
    ],
    "keywordsStr": "air aire airplane ar avião avión aéreo transport transporte",
    "imgUrl": "assets/emojis/2708.svg"
  },
  {
    "codes": "1f6e9",
    "char": "🛩️",
    "name": "Small Airplane",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "pequeno avião",
      "small airplane",
      "pequeño avión"
    ],
    "keywordsStr": "air aire airplane ar avião avión aéreo pequeno pequeño small transport transporte",
    "imgUrl": "assets/emojis/1f6e9.svg"
  },
  {
    "codes": "1f6eb",
    "char": "🛫",
    "name": "Airplane Departure",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "saída do avião",
      "airplane departure",
      "salida del avión"
    ],
    "keywordsStr": "air aire airplane ar avião avión aéreo del departure do salida saída transport transporte",
    "imgUrl": "assets/emojis/1f6eb.svg"
  },
  {
    "codes": "1f6ec",
    "char": "🛬",
    "name": "Airplane Arrival",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "chegada de avião",
      "airplane arrival",
      "llegada del avión"
    ],
    "keywordsStr": "air aire airplane ar arrival avião avión aéreo chegada de del llegada transport transporte",
    "imgUrl": "assets/emojis/1f6ec.svg"
  },
  {
    "codes": "1fa82",
    "char": "🪂",
    "name": "Parachute",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "paraquedas",
      "parachute",
      "paracaídas"
    ],
    "keywordsStr": "air aire ar aéreo paracaídas parachute paraquedas transport transporte",
    "imgUrl": "assets/emojis/1fa82.svg"
  },
  {
    "codes": "1f4ba",
    "char": "💺",
    "name": "Seat",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "assento",
      "seat",
      "asiento"
    ],
    "keywordsStr": "air aire ar asiento assento aéreo seat transport transporte",
    "imgUrl": "assets/emojis/1f4ba.svg"
  },
  {
    "codes": "1f681",
    "char": "🚁",
    "name": "Helicopter",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "helicóptero",
      "helicopter"
    ],
    "keywordsStr": "air aire ar aéreo helicopter helicóptero transport transporte",
    "imgUrl": "assets/emojis/1f681.svg"
  },
  {
    "codes": "1f69f",
    "char": "🚟",
    "name": "Suspension Railway",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "estrada de suspensão",
      "suspension railway",
      "ferrocarril de suspensión"
    ],
    "keywordsStr": "air aire ar aéreo de estrada ferrocarril railway suspension suspensión suspensão transport transporte",
    "imgUrl": "assets/emojis/1f69f.svg"
  },
  {
    "codes": "1f6a0",
    "char": "🚠",
    "name": "Mountain Cableway",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "teleférico da montanha",
      "mountain cableway",
      "teleférico de montaña"
    ],
    "keywordsStr": "air aire ar aéreo cableway da de montanha montaña mountain teleférico transport transporte",
    "imgUrl": "assets/emojis/1f6a0.svg"
  },
  {
    "codes": "1f6a1",
    "char": "🚡",
    "name": "Aerial Tramway",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "bonde aéreo",
      "aerial tramway",
      "tranvía aéreo"
    ],
    "keywordsStr": "aerial air aire ar aéreo bonde tramway transport transporte tranvía",
    "imgUrl": "assets/emojis/1f6a1.svg"
  },
  {
    "codes": "1f6f0",
    "char": "🛰️",
    "name": "Satellite",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "satélite",
      "satellite"
    ],
    "keywordsStr": "air aire ar aéreo satellite satélite transport transporte",
    "imgUrl": "assets/emojis/1f6f0.svg"
  },
  {
    "codes": "1f680",
    "char": "🚀",
    "name": "Rocket",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "foguete",
      "rocket",
      "cohete"
    ],
    "keywordsStr": "air aire ar aéreo cohete foguete rocket transport transporte",
    "imgUrl": "assets/emojis/1f680.svg"
  },
  {
    "codes": "1f6f8",
    "char": "🛸",
    "name": "Flying Saucer",
    "category": "Travel & Places (transport-air)",
    "group": "travelAndPlaces",
    "subgroup": "transport-air",
    "keywords": [
      "transporte",
      "transport",
      "ar",
      "air",
      "aire",
      "transporte aéreo",
      "transport air",
      "disco voador",
      "flying saucer",
      "platillo volador"
    ],
    "keywordsStr": "air aire ar aéreo disco flying platillo saucer transport transporte voador volador",
    "imgUrl": "assets/emojis/1f6f8.svg"
  },
  {
    "codes": "1f6ce",
    "char": "🛎️",
    "name": "Bellhop Bell",
    "category": "Travel & Places (hotel)",
    "group": "travelAndPlaces",
    "subgroup": "hotel",
    "keywords": [
      "hotel",
      "hoteles",
      "sino de mensageiro",
      "bellhop bell",
      "campana de botones"
    ],
    "keywordsStr": "bell bellhop botones campana de hotel hoteles mensageiro sino",
    "imgUrl": "assets/emojis/1f6ce.svg"
  },
  {
    "codes": "1f9f3",
    "char": "🧳",
    "name": "Luggage",
    "category": "Travel & Places (hotel)",
    "group": "travelAndPlaces",
    "subgroup": "hotel",
    "keywords": [
      "hotel",
      "hoteles",
      "bagagem",
      "luggage",
      "equipaje"
    ],
    "keywordsStr": "bagagem equipaje hotel hoteles luggage",
    "imgUrl": "assets/emojis/1f9f3.svg"
  },
  {
    "codes": "231b",
    "char": "⌛",
    "name": "Hourglass Done",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "ampulheta feita",
      "hourglass done",
      "reloj de arena hecho"
    ],
    "keywordsStr": "ampulheta arena de done feita hecho hourglass reloj tempo tiempo time",
    "imgUrl": "assets/emojis/231b.svg"
  },
  {
    "codes": "23f3",
    "char": "⏳",
    "name": "Hourglass Not Done",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "ampulheta não feita",
      "hourglass not done",
      "reloj de arena no hecho"
    ],
    "keywordsStr": "ampulheta arena de done feita hecho hourglass no not não reloj tempo tiempo time",
    "imgUrl": "assets/emojis/23f3.svg"
  },
  {
    "codes": "231a",
    "char": "⌚",
    "name": "Watch",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "observar",
      "watch",
      "ver"
    ],
    "keywordsStr": "observar tempo tiempo time ver watch",
    "imgUrl": "assets/emojis/231a.svg"
  },
  {
    "codes": "23f0",
    "char": "⏰",
    "name": "Alarm Clock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "despertador",
      "alarm clock"
    ],
    "keywordsStr": "alarm clock despertador tempo tiempo time",
    "imgUrl": "assets/emojis/23f0.svg"
  },
  {
    "codes": "23f1",
    "char": "⏱️",
    "name": "Stopwatch",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "cronômetro",
      "stopwatch",
      "cronómetro"
    ],
    "keywordsStr": "cronómetro cronômetro stopwatch tempo tiempo time",
    "imgUrl": "assets/emojis/23f1.svg"
  },
  {
    "codes": "23f2",
    "char": "⏲️",
    "name": "Timer Clock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "relógio temporizador",
      "timer clock",
      "reloj temporizador"
    ],
    "keywordsStr": "clock reloj relógio tempo temporizador tiempo time timer",
    "imgUrl": "assets/emojis/23f2.svg"
  },
  {
    "codes": "1f570",
    "char": "🕰️",
    "name": "Mantelpiece Clock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "relógio de lareira",
      "mantelpiece clock",
      "reloj de sobremesa"
    ],
    "keywordsStr": "clock de lareira mantelpiece reloj relógio sobremesa tempo tiempo time",
    "imgUrl": "assets/emojis/1f570.svg"
  },
  {
    "codes": "1f55b",
    "char": "🕛",
    "name": "Twelve Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "doze horas",
      "twelve oclock",
      "las doce en punto"
    ],
    "keywordsStr": "doce doze en horas las oclock punto tempo tiempo time twelve",
    "imgUrl": "assets/emojis/1f55b.svg"
  },
  {
    "codes": "1f567",
    "char": "🕧",
    "name": "Twelve Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "doze e meia",
      "twelve-thirty",
      "doce y media"
    ],
    "keywordsStr": "doce doze e media meia tempo tiempo time twelve-thirty y",
    "imgUrl": "assets/emojis/1f567.svg"
  },
  {
    "codes": "1f550",
    "char": "🕐",
    "name": "One Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "uma hora",
      "one oclock",
      "la una en punto"
    ],
    "keywordsStr": "en hora la oclock one punto tempo tiempo time uma una",
    "imgUrl": "assets/emojis/1f550.svg"
  },
  {
    "codes": "1f55c",
    "char": "🕜",
    "name": "One Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "uma e meia",
      "one-thirty",
      "la una y media"
    ],
    "keywordsStr": "e la media meia one-thirty tempo tiempo time uma una y",
    "imgUrl": "assets/emojis/1f55c.svg"
  },
  {
    "codes": "1f551",
    "char": "🕑",
    "name": "Two Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "duas horas",
      "two oclock",
      "las dos en punto"
    ],
    "keywordsStr": "dos duas en horas las oclock punto tempo tiempo time two",
    "imgUrl": "assets/emojis/1f551.svg"
  },
  {
    "codes": "1f55d",
    "char": "🕝",
    "name": "Two Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "duas e meia",
      "two-thirty",
      "dos y media"
    ],
    "keywordsStr": "dos duas e media meia tempo tiempo time two-thirty y",
    "imgUrl": "assets/emojis/1f55d.svg"
  },
  {
    "codes": "1f552",
    "char": "🕒",
    "name": "Three Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "três horas",
      "three oclock",
      "las tres en punto"
    ],
    "keywordsStr": "en horas las oclock punto tempo three tiempo time tres três",
    "imgUrl": "assets/emojis/1f552.svg"
  },
  {
    "codes": "1f55e",
    "char": "🕞",
    "name": "Three Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "três e meia",
      "three-thirty",
      "tres y media"
    ],
    "keywordsStr": "e media meia tempo three-thirty tiempo time tres três y",
    "imgUrl": "assets/emojis/1f55e.svg"
  },
  {
    "codes": "1f553",
    "char": "🕓",
    "name": "Four Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "quatro horas",
      "four oclock",
      "cuatro en punto"
    ],
    "keywordsStr": "cuatro en four horas oclock punto quatro tempo tiempo time",
    "imgUrl": "assets/emojis/1f553.svg"
  },
  {
    "codes": "1f55f",
    "char": "🕟",
    "name": "Four Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "quatro e meia",
      "four-thirty",
      "cuatro y media"
    ],
    "keywordsStr": "cuatro e four-thirty media meia quatro tempo tiempo time y",
    "imgUrl": "assets/emojis/1f55f.svg"
  },
  {
    "codes": "1f554",
    "char": "🕔",
    "name": "Five Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "cinco horas",
      "five oclock",
      "cinco en punto"
    ],
    "keywordsStr": "cinco en five horas oclock punto tempo tiempo time",
    "imgUrl": "assets/emojis/1f554.svg"
  },
  {
    "codes": "1f560",
    "char": "🕠",
    "name": "Five Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "cinco e meia",
      "five-thirty",
      "cinco y media"
    ],
    "keywordsStr": "cinco e five-thirty media meia tempo tiempo time y",
    "imgUrl": "assets/emojis/1f560.svg"
  },
  {
    "codes": "1f555",
    "char": "🕕",
    "name": "Six Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "seis horas",
      "six oclock",
      "seis en punto"
    ],
    "keywordsStr": "en horas oclock punto seis six tempo tiempo time",
    "imgUrl": "assets/emojis/1f555.svg"
  },
  {
    "codes": "1f561",
    "char": "🕡",
    "name": "Six Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "seis e meia",
      "six-thirty",
      "seis y media"
    ],
    "keywordsStr": "e media meia seis six-thirty tempo tiempo time y",
    "imgUrl": "assets/emojis/1f561.svg"
  },
  {
    "codes": "1f556",
    "char": "🕖",
    "name": "Seven Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "sete horas",
      "seven oclock",
      "siete en punto"
    ],
    "keywordsStr": "en horas oclock punto sete seven siete tempo tiempo time",
    "imgUrl": "assets/emojis/1f556.svg"
  },
  {
    "codes": "1f562",
    "char": "🕢",
    "name": "Seven Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "sete e meia",
      "seven-thirty",
      "siete y media"
    ],
    "keywordsStr": "e media meia sete seven-thirty siete tempo tiempo time y",
    "imgUrl": "assets/emojis/1f562.svg"
  },
  {
    "codes": "1f557",
    "char": "🕗",
    "name": "Eight Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "oito horas",
      "eight oclock",
      "ocho en punto"
    ],
    "keywordsStr": "eight en horas ocho oclock oito punto tempo tiempo time",
    "imgUrl": "assets/emojis/1f557.svg"
  },
  {
    "codes": "1f563",
    "char": "🕣",
    "name": "Eight Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "oito e meia",
      "eight-thirty",
      "ocho y media"
    ],
    "keywordsStr": "e eight-thirty media meia ocho oito tempo tiempo time y",
    "imgUrl": "assets/emojis/1f563.svg"
  },
  {
    "codes": "1f558",
    "char": "🕘",
    "name": "Nine Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "nove horas",
      "nine oclock",
      "nueve en punto"
    ],
    "keywordsStr": "en horas nine nove nueve oclock punto tempo tiempo time",
    "imgUrl": "assets/emojis/1f558.svg"
  },
  {
    "codes": "1f564",
    "char": "🕤",
    "name": "Nine Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "nove e meia",
      "nine-thirty",
      "nueve y media"
    ],
    "keywordsStr": "e media meia nine-thirty nove nueve tempo tiempo time y",
    "imgUrl": "assets/emojis/1f564.svg"
  },
  {
    "codes": "1f559",
    "char": "🕙",
    "name": "Ten Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "dez horas",
      "ten oclock",
      "diez en punto"
    ],
    "keywordsStr": "dez diez en horas oclock punto tempo ten tiempo time",
    "imgUrl": "assets/emojis/1f559.svg"
  },
  {
    "codes": "1f565",
    "char": "🕥",
    "name": "Ten Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "dez e meia",
      "ten-thirty",
      "diez y media"
    ],
    "keywordsStr": "dez diez e media meia tempo ten-thirty tiempo time y",
    "imgUrl": "assets/emojis/1f565.svg"
  },
  {
    "codes": "1f55a",
    "char": "🕚",
    "name": "Eleven Oclock",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "onze horas",
      "eleven oclock",
      "las once en punto"
    ],
    "keywordsStr": "eleven en horas las oclock once onze punto tempo tiempo time",
    "imgUrl": "assets/emojis/1f55a.svg"
  },
  {
    "codes": "1f566",
    "char": "🕦",
    "name": "Eleven Thirty",
    "category": "Travel & Places (time)",
    "group": "travelAndPlaces",
    "subgroup": "time",
    "keywords": [
      "tempo",
      "time",
      "tiempo",
      "onze e meia",
      "eleven-thirty",
      "once y media"
    ],
    "keywordsStr": "e eleven-thirty media meia once onze tempo tiempo time y",
    "imgUrl": "assets/emojis/1f566.svg"
  },
  {
    "codes": "1f311",
    "char": "🌑",
    "name": "New Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua nova",
      "new moon",
      "luna nueva"
    ],
    "keywordsStr": "cielo clima céu del do lua luna moon new nova nueva sky tempo weather",
    "imgUrl": "assets/emojis/1f311.svg"
  },
  {
    "codes": "1f312",
    "char": "🌒",
    "name": "Waxing Crescent Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua crescente crescente",
      "waxing crescent moon",
      "luna creciente"
    ],
    "keywordsStr": "cielo clima creciente crescent crescente céu del do lua luna moon sky tempo waxing weather",
    "imgUrl": "assets/emojis/1f312.svg"
  },
  {
    "codes": "1f313",
    "char": "🌓",
    "name": "First Quarter Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua do primeiro quarto",
      "first quarter moon",
      "primer cuarto de luna"
    ],
    "keywordsStr": "cielo clima cuarto céu de del do first lua luna moon primeiro primer quarter quarto sky tempo weather",
    "imgUrl": "assets/emojis/1f313.svg"
  },
  {
    "codes": "1f314",
    "char": "🌔",
    "name": "Waxing Gibbous Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua crescente crescente",
      "waxing gibbous moon",
      "luna gibosa creciente"
    ],
    "keywordsStr": "cielo clima creciente crescente céu del do gibbous gibosa lua luna moon sky tempo waxing weather",
    "imgUrl": "assets/emojis/1f314.svg"
  },
  {
    "codes": "1f315",
    "char": "🌕",
    "name": "Full Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua cheia",
      "full moon",
      "luna llena"
    ],
    "keywordsStr": "cheia cielo clima céu del do full llena lua luna moon sky tempo weather",
    "imgUrl": "assets/emojis/1f315.svg"
  },
  {
    "codes": "1f316",
    "char": "🌖",
    "name": "Waning Gibbous Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua minguante gibosa",
      "waning gibbous moon",
      "luna gibosa menguante"
    ],
    "keywordsStr": "cielo clima céu del do gibbous gibosa lua luna menguante minguante moon sky tempo waning weather",
    "imgUrl": "assets/emojis/1f316.svg"
  },
  {
    "codes": "1f317",
    "char": "🌗",
    "name": "Last Quarter Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua minguante",
      "last quarter moon",
      "último cuarto de luna"
    ],
    "keywordsStr": "cielo clima cuarto céu de del do last lua luna minguante moon quarter sky tempo weather último",
    "imgUrl": "assets/emojis/1f317.svg"
  },
  {
    "codes": "1f318",
    "char": "🌘",
    "name": "Waning Crescent Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua crescente minguante",
      "waning crescent moon",
      "luna creciente menguante"
    ],
    "keywordsStr": "cielo clima creciente crescent crescente céu del do lua luna menguante minguante moon sky tempo waning weather",
    "imgUrl": "assets/emojis/1f318.svg"
  },
  {
    "codes": "1f319",
    "char": "🌙",
    "name": "Crescent Moon",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "lua crescente",
      "crescent moon",
      "luna creciente"
    ],
    "keywordsStr": "cielo clima creciente crescent crescente céu del do lua luna moon sky tempo weather",
    "imgUrl": "assets/emojis/1f319.svg"
  },
  {
    "codes": "1f31a",
    "char": "🌚",
    "name": "New Moon Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "cara de lua nova",
      "new moon face",
      "cara de luna nueva"
    ],
    "keywordsStr": "apariencia cara cielo clima countenance céu de del do expresión expression expressão face faz features fisionomia fisonomía look lua luna moon mug new nova nueva rosto rostro semblante sky tempo visage weather",
    "imgUrl": "assets/emojis/1f31a.svg"
  },
  {
    "codes": "1f31b",
    "char": "🌛",
    "name": "First Quarter Moon Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "rosto de lua do primeiro quarto",
      "first quarter moon face",
      "cara de luna en cuarto creciente"
    ],
    "keywordsStr": "apariencia cara cielo clima countenance creciente cuarto céu de del do en expresión expression expressão face faz features first fisionomia fisonomía look lua luna moon mug primeiro quarter quarto rosto rostro semblante sky tempo visage weather",
    "imgUrl": "assets/emojis/1f31b.svg"
  },
  {
    "codes": "1f31c",
    "char": "🌜",
    "name": "Last Quarter Moon Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "rosto da lua minguante",
      "last quarter moon face",
      "cara del último cuarto de luna"
    ],
    "keywordsStr": "apariencia cara cielo clima countenance cuarto céu da de del do expresión expression expressão face faz features fisionomia fisonomía last look lua luna minguante moon mug quarter rosto rostro semblante sky tempo visage weather último",
    "imgUrl": "assets/emojis/1f31c.svg"
  },
  {
    "codes": "1f321",
    "char": "🌡️",
    "name": "Thermometer",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "termômetro",
      "thermometer",
      "termómetro"
    ],
    "keywordsStr": "cielo clima céu del do sky tempo termómetro termômetro thermometer weather",
    "imgUrl": "assets/emojis/1f321.svg"
  },
  {
    "codes": "2600",
    "char": "☀️",
    "name": "Sun",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol",
      "sun"
    ],
    "keywordsStr": "cielo clima céu del do sky sol sun tempo weather",
    "imgUrl": "assets/emojis/2600.svg"
  },
  {
    "codes": "1f31d",
    "char": "🌝",
    "name": "Full Moon Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "cara de lua cheia",
      "full moon face",
      "cara de luna llena"
    ],
    "keywordsStr": "apariencia cara cheia cielo clima countenance céu de del do expresión expression expressão face faz features fisionomia fisonomía full llena look lua luna moon mug rosto rostro semblante sky tempo visage weather",
    "imgUrl": "assets/emojis/1f31d.svg"
  },
  {
    "codes": "1f31e",
    "char": "🌞",
    "name": "Sun With Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol com rosto",
      "sun with face",
      "sol con cara"
    ],
    "keywordsStr": "apariencia cara cielo clima com con countenance céu del do expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante sky sol sun tempo visage weather with",
    "imgUrl": "assets/emojis/1f31e.svg"
  },
  {
    "codes": "1fa90",
    "char": "🪐",
    "name": "Ringed Planet",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "planeta anelado",
      "ringed planet",
      "planeta anillado"
    ],
    "keywordsStr": "anelado anillado cielo clima céu del do planet planeta ringed sky tempo weather",
    "imgUrl": "assets/emojis/1fa90.svg"
  },
  {
    "codes": "2b50",
    "char": "⭐",
    "name": "Star",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "estrela",
      "star",
      "estrella"
    ],
    "keywordsStr": "cielo clima céu del do estrela estrella sky star tempo weather",
    "imgUrl": "assets/emojis/2b50.svg"
  },
  {
    "codes": "1f31f",
    "char": "🌟",
    "name": "Glowing Star",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "estrela brilhante",
      "glowing star",
      "estrella brillante"
    ],
    "keywordsStr": "brilhante brillante cielo clima céu del do estrela estrella glowing sky star tempo weather",
    "imgUrl": "assets/emojis/1f31f.svg"
  },
  {
    "codes": "1f320",
    "char": "🌠",
    "name": "Shooting Star",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "estrela cadente",
      "shooting star",
      "estrella fugaz"
    ],
    "keywordsStr": "cadente cielo clima céu del do estrela estrella fugaz shooting sky star tempo weather",
    "imgUrl": "assets/emojis/1f320.svg"
  },
  {
    "codes": "1f30c",
    "char": "🌌",
    "name": "Milky Way",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "via láctea",
      "milky way",
      "vía láctea"
    ],
    "keywordsStr": "cielo clima céu del do láctea milky sky tempo via vía way weather",
    "imgUrl": "assets/emojis/1f30c.svg"
  },
  {
    "codes": "2601",
    "char": "☁️",
    "name": "Cloud",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "nuvem",
      "cloud",
      "nube"
    ],
    "keywordsStr": "cielo clima cloud céu del do nube nuvem sky tempo weather",
    "imgUrl": "assets/emojis/2601.svg"
  },
  {
    "codes": "26c5",
    "char": "⛅",
    "name": "Sun Behind Cloud",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol atrás da nuvem",
      "sun behind cloud",
      "sol detrás de la nube"
    ],
    "keywordsStr": "atrás behind cielo clima cloud céu da de del detrás do la nube nuvem sky sol sun tempo weather",
    "imgUrl": "assets/emojis/26c5.svg"
  },
  {
    "codes": "26c8",
    "char": "⛈️",
    "name": "Cloud With Lightning And Rain",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "nuvem com raios e chuva",
      "cloud with lightning and rain",
      "nube con relámpagos y lluvia"
    ],
    "keywordsStr": "and chuva cielo clima cloud com con céu del do e lightning lluvia nube nuvem rain raios relámpagos sky tempo weather with y",
    "imgUrl": "assets/emojis/26c8.svg"
  },
  {
    "codes": "1f324",
    "char": "🌤️",
    "name": "Sun Behind Small Cloud",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol atrás da pequena nuvem",
      "sun behind small cloud",
      "sol detrás de una pequeña nube"
    ],
    "keywordsStr": "atrás behind cielo clima cloud céu da de del detrás do nube nuvem pequena pequeña sky small sol sun tempo una weather",
    "imgUrl": "assets/emojis/1f324.svg"
  },
  {
    "codes": "1f325",
    "char": "🌥️",
    "name": "Sun Behind Large Cloud",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol atrás de grande nuvem",
      "sun behind large cloud",
      "sol detrás de una gran nube"
    ],
    "keywordsStr": "atrás behind cielo clima cloud céu de del detrás do gran grande large nube nuvem sky sol sun tempo una weather",
    "imgUrl": "assets/emojis/1f325.svg"
  },
  {
    "codes": "1f326",
    "char": "🌦️",
    "name": "Sun Behind Rain Cloud",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "sol atrás da nuvem de chuva",
      "sun behind rain cloud",
      "sol detrás de la nube de lluvia"
    ],
    "keywordsStr": "atrás behind chuva cielo clima cloud céu da de del detrás do la lluvia nube nuvem rain sky sol sun tempo weather",
    "imgUrl": "assets/emojis/1f326.svg"
  },
  {
    "codes": "1f327",
    "char": "🌧️",
    "name": "Cloud With Rain",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "nuvem com chuva",
      "cloud with rain",
      "nube con lluvia"
    ],
    "keywordsStr": "chuva cielo clima cloud com con céu del do lluvia nube nuvem rain sky tempo weather with",
    "imgUrl": "assets/emojis/1f327.svg"
  },
  {
    "codes": "1f328",
    "char": "🌨️",
    "name": "Cloud With Snow",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "nuvem com neve",
      "cloud with snow",
      "nube con nieve"
    ],
    "keywordsStr": "cielo clima cloud com con céu del do neve nieve nube nuvem sky snow tempo weather with",
    "imgUrl": "assets/emojis/1f328.svg"
  },
  {
    "codes": "1f329",
    "char": "🌩️",
    "name": "Cloud With Lightning",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "nuvem com relâmpago",
      "cloud with lightning",
      "nube con relámpago"
    ],
    "keywordsStr": "cielo clima cloud com con céu del do lightning nube nuvem relámpago relâmpago sky tempo weather with",
    "imgUrl": "assets/emojis/1f329.svg"
  },
  {
    "codes": "1f32a",
    "char": "🌪️",
    "name": "Tornado",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "tornado"
    ],
    "keywordsStr": "cielo clima céu del do sky tempo tornado weather",
    "imgUrl": "assets/emojis/1f32a.svg"
  },
  {
    "codes": "1f32b",
    "char": "🌫️",
    "name": "Fog",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "névoa",
      "fog",
      "niebla"
    ],
    "keywordsStr": "cielo clima céu del do fog niebla névoa sky tempo weather",
    "imgUrl": "assets/emojis/1f32b.svg"
  },
  {
    "codes": "1f32c",
    "char": "🌬️",
    "name": "Wind Face",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "cara de vento",
      "wind face",
      "cara de viento"
    ],
    "keywordsStr": "apariencia cara cielo clima countenance céu de del do expresión expression expressão face faz features fisionomia fisonomía look mug rosto rostro semblante sky tempo vento viento visage weather wind",
    "imgUrl": "assets/emojis/1f32c.svg"
  },
  {
    "codes": "1f300",
    "char": "🌀",
    "name": "Cyclone",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "ciclone",
      "cyclone",
      "ciclón"
    ],
    "keywordsStr": "ciclone ciclón cielo clima cyclone céu del do sky tempo weather",
    "imgUrl": "assets/emojis/1f300.svg"
  },
  {
    "codes": "1f308",
    "char": "🌈",
    "name": "Rainbow",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "arco-íris",
      "rainbow",
      "arco iris"
    ],
    "keywordsStr": "arco arco-íris cielo clima céu del do iris rainbow sky tempo weather",
    "imgUrl": "assets/emojis/1f308.svg"
  },
  {
    "codes": "1f302",
    "char": "🌂",
    "name": "Closed Umbrella",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "guarda-chuva fechado",
      "closed umbrella",
      "paraguas cerrado"
    ],
    "keywordsStr": "cerrado cielo clima closed céu del do fechado guarda-chuva paraguas sky tempo umbrella weather",
    "imgUrl": "assets/emojis/1f302.svg"
  },
  {
    "codes": "2602",
    "char": "☂️",
    "name": "Umbrella",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "guarda-chuva",
      "umbrella",
      "paraguas"
    ],
    "keywordsStr": "cielo clima céu del do guarda-chuva paraguas sky tempo umbrella weather",
    "imgUrl": "assets/emojis/2602.svg"
  },
  {
    "codes": "2614",
    "char": "☔",
    "name": "Umbrella With Rain Drops",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "guarda-chuva com gotas de chuva",
      "umbrella with rain drops",
      "paraguas con gotas de lluvia"
    ],
    "keywordsStr": "chuva cielo clima com con céu de del do drops gotas guarda-chuva lluvia paraguas rain sky tempo umbrella weather with",
    "imgUrl": "assets/emojis/2614.svg"
  },
  {
    "codes": "26a1",
    "char": "⚡",
    "name": "High Voltage",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "alta tensão",
      "high voltage",
      "alto voltaje"
    ],
    "keywordsStr": "alta alto cielo clima céu del do high sky tempo tensão voltage voltaje weather",
    "imgUrl": "assets/emojis/26a1.svg"
  },
  {
    "codes": "2744",
    "char": "❄️",
    "name": "Snowflake",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "floco de neve",
      "snowflake",
      "copo de nieve"
    ],
    "keywordsStr": "cielo clima copo céu de del do floco neve nieve sky snowflake tempo weather",
    "imgUrl": "assets/emojis/2744.svg"
  },
  {
    "codes": "2603",
    "char": "☃️",
    "name": "Snowman",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "boneco de neve",
      "snowman",
      "muñeco de nieve"
    ],
    "keywordsStr": "boneco cielo clima céu de del do muñeco neve nieve sky snowman tempo weather",
    "imgUrl": "assets/emojis/2603.svg"
  },
  {
    "codes": "26c4",
    "char": "⛄",
    "name": "Snowman Without Snow",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "boneco de neve sem neve",
      "snowman without snow",
      "muñeco de nieve sin nieve"
    ],
    "keywordsStr": "boneco cielo clima céu de del do muñeco neve nieve sem sin sky snow snowman tempo weather without",
    "imgUrl": "assets/emojis/26c4.svg"
  },
  {
    "codes": "2604",
    "char": "☄️",
    "name": "Comet",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "cometa",
      "comet"
    ],
    "keywordsStr": "cielo clima comet cometa céu del do sky tempo weather",
    "imgUrl": "assets/emojis/2604.svg"
  },
  {
    "codes": "1f525",
    "char": "🔥",
    "name": "Fire",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "fogo",
      "fire",
      "fuego"
    ],
    "keywordsStr": "cielo clima céu del do fire fogo fuego sky tempo weather",
    "imgUrl": "assets/emojis/1f525.svg"
  },
  {
    "codes": "1f4a7",
    "char": "💧",
    "name": "Droplet",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "gota",
      "droplet"
    ],
    "keywordsStr": "cielo clima céu del do droplet gota sky tempo weather",
    "imgUrl": "assets/emojis/1f4a7.svg"
  },
  {
    "codes": "1f30a",
    "char": "🌊",
    "name": "Water Wave",
    "category": "Travel & Places (sky-weather)",
    "group": "travelAndPlaces",
    "subgroup": "sky-weather",
    "keywords": [
      "céu",
      "sky",
      "cielo",
      "tempo",
      "weather",
      "clima",
      "clima do céu",
      "sky weather",
      "clima del cielo",
      "onda de água",
      "water wave",
      "onda de agua"
    ],
    "keywordsStr": "agua cielo clima céu de del do onda sky tempo water wave weather água",
    "imgUrl": "assets/emojis/1f30a.svg"
  },
  {
    "codes": "1f383",
    "char": "🎃",
    "name": "Jack O Lantern",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "jack-o-lanterna",
      "jack-o-lantern",
      "Jack-o-lantern"
    ],
    "keywordsStr": "Jack-o-lantern event evento jack-o-lantern jack-o-lanterna",
    "imgUrl": "assets/emojis/1f383.svg"
  },
  {
    "codes": "1f384",
    "char": "🎄",
    "name": "Christmas Tree",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "Árvore de Natal",
      "Christmas tree",
      "Árbol de Navidad"
    ],
    "keywordsStr": "Christmas Natal Navidad de event evento tree Árbol Árvore",
    "imgUrl": "assets/emojis/1f384.svg"
  },
  {
    "codes": "1f386",
    "char": "🎆",
    "name": "Fireworks",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "fogos de artifício",
      "fireworks",
      "fuegos artificiales"
    ],
    "keywordsStr": "artificiales artifício de event evento fireworks fogos fuegos",
    "imgUrl": "assets/emojis/1f386.svg"
  },
  {
    "codes": "1f387",
    "char": "🎇",
    "name": "Sparkler",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "brilhante",
      "sparkler",
      "bengala"
    ],
    "keywordsStr": "bengala brilhante event evento sparkler",
    "imgUrl": "assets/emojis/1f387.svg"
  },
  {
    "codes": "1f9e8",
    "char": "🧨",
    "name": "Firecracker",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "foguete",
      "firecracker",
      "petardo"
    ],
    "keywordsStr": "event evento firecracker foguete petardo",
    "imgUrl": "assets/emojis/1f9e8.svg"
  },
  {
    "codes": "2728",
    "char": "✨",
    "name": "Sparkles",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "brilhantes",
      "sparkles",
      "destellos"
    ],
    "keywordsStr": "brilhantes destellos event evento sparkles",
    "imgUrl": "assets/emojis/2728.svg"
  },
  {
    "codes": "1f388",
    "char": "🎈",
    "name": "Balloon",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "balão",
      "balloon",
      "globo"
    ],
    "keywordsStr": "balloon balão event evento globo",
    "imgUrl": "assets/emojis/1f388.svg"
  },
  {
    "codes": "1f389",
    "char": "🎉",
    "name": "Party Popper",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "piscina de festa",
      "party popper",
      "fiestero"
    ],
    "keywordsStr": "de event evento festa fiestero party piscina popper",
    "imgUrl": "assets/emojis/1f389.svg"
  },
  {
    "codes": "1f38a",
    "char": "🎊",
    "name": "Confetti Ball",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "bola de confete",
      "confetti ball",
      "bola de confeti"
    ],
    "keywordsStr": "ball bola confete confeti confetti de event evento",
    "imgUrl": "assets/emojis/1f38a.svg"
  },
  {
    "codes": "1f38b",
    "char": "🎋",
    "name": "Tanabata Tree",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "árvore tanabata",
      "tanabata tree",
      "árbol de tanabata"
    ],
    "keywordsStr": "de event evento tanabata tree árbol árvore",
    "imgUrl": "assets/emojis/1f38b.svg"
  },
  {
    "codes": "1f38d",
    "char": "🎍",
    "name": "Pine Decoration",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "decoração de pinho",
      "pine decoration",
      "decoración de pino"
    ],
    "keywordsStr": "de decoración decoration decoração event evento pine pinho pino",
    "imgUrl": "assets/emojis/1f38d.svg"
  },
  {
    "codes": "1f38e",
    "char": "🎎",
    "name": "Japanese Dolls",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "bonecas japonesas",
      "Japanese dolls",
      "Muñecas japonesas"
    ],
    "keywordsStr": "Japanese Muñecas bonecas dolls event evento japonesas",
    "imgUrl": "assets/emojis/1f38e.svg"
  },
  {
    "codes": "1f38f",
    "char": "🎏",
    "name": "Carp Streamer",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "carpa serpentina",
      "carp streamer",
      "serpentina de carpa"
    ],
    "keywordsStr": "carp carpa de event evento serpentina streamer",
    "imgUrl": "assets/emojis/1f38f.svg"
  },
  {
    "codes": "1f390",
    "char": "🎐",
    "name": "Wind Chime",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "carrilhão de vento",
      "wind chime",
      "carillón de viento"
    ],
    "keywordsStr": "carillón carrilhão chime de event evento vento viento wind",
    "imgUrl": "assets/emojis/1f390.svg"
  },
  {
    "codes": "1f391",
    "char": "🎑",
    "name": "Moon Viewing Ceremony",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "cerimônia de observação da lua",
      "moon viewing ceremony",
      "ceremonia de visualización de la luna"
    ],
    "keywordsStr": "ceremonia ceremony cerimônia da de event evento la lua luna moon observação viewing visualización",
    "imgUrl": "assets/emojis/1f391.svg"
  },
  {
    "codes": "1f9e7",
    "char": "🧧",
    "name": "Red Envelope",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "envelope vermelho",
      "red envelope",
      "sobre rojo"
    ],
    "keywordsStr": "envelope event evento red rojo sobre vermelho",
    "imgUrl": "assets/emojis/1f9e7.svg"
  },
  {
    "codes": "1f380",
    "char": "🎀",
    "name": "Ribbon",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "fita",
      "ribbon",
      "cinta"
    ],
    "keywordsStr": "cinta event evento fita ribbon",
    "imgUrl": "assets/emojis/1f380.svg"
  },
  {
    "codes": "1f381",
    "char": "🎁",
    "name": "Wrapped Gift",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "presente embrulhado",
      "wrapped gift",
      "regalo envuelto"
    ],
    "keywordsStr": "embrulhado envuelto event evento gift presente regalo wrapped",
    "imgUrl": "assets/emojis/1f381.svg"
  },
  {
    "codes": "1f397",
    "char": "🎗️",
    "name": "Reminder Ribbon",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "faixa de lembrete",
      "reminder ribbon",
      "cinta de recordatorio"
    ],
    "keywordsStr": "cinta de event evento faixa lembrete recordatorio reminder ribbon",
    "imgUrl": "assets/emojis/1f397.svg"
  },
  {
    "codes": "1f39f",
    "char": "🎟️",
    "name": "Admission Tickets",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "bilhetes de entrada",
      "admission tickets",
      "boletos de entrada"
    ],
    "keywordsStr": "admission bilhetes boletos de entrada event evento tickets",
    "imgUrl": "assets/emojis/1f39f.svg"
  },
  {
    "codes": "1f3ab",
    "char": "🎫",
    "name": "Ticket",
    "category": "Activities (event)",
    "group": "activities",
    "subgroup": "event",
    "keywords": [
      "evento",
      "event",
      "bilhete",
      "ticket",
      "boleto"
    ],
    "keywordsStr": "bilhete boleto event evento ticket",
    "imgUrl": "assets/emojis/1f3ab.svg"
  },
  {
    "codes": "1f396",
    "char": "🎖️",
    "name": "Military Medal",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "medalha militar",
      "military medal",
      "medalla militar"
    ],
    "keywordsStr": "award de medal medalha medalla militar military premio prêmio",
    "imgUrl": "assets/emojis/1f396.svg"
  },
  {
    "codes": "1f3c6",
    "char": "🏆",
    "name": "Trophy",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "troféu",
      "trophy",
      "trofeo"
    ],
    "keywordsStr": "award de medal medalha medalla premio prêmio trofeo troféu trophy",
    "imgUrl": "assets/emojis/1f3c6.svg"
  },
  {
    "codes": "1f3c5",
    "char": "🏅",
    "name": "Sports Medal",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "medalha esportiva",
      "sports medal",
      "medalla deportiva"
    ],
    "keywordsStr": "award de deportiva esportiva medal medalha medalla premio prêmio sports",
    "imgUrl": "assets/emojis/1f3c5.svg"
  },
  {
    "codes": "1f947",
    "char": "🥇",
    "name": "1st Place Medal",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "medalha de 1º lugar",
      "1st place medal",
      "Medalla del 1er lugar"
    ],
    "keywordsStr": "1er 1st 1º Medalla award de del lugar medal medalha medalla place premio prêmio",
    "imgUrl": "assets/emojis/1f947.svg"
  },
  {
    "codes": "1f948",
    "char": "🥈",
    "name": "2nd Place Medal",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "medalha de 2º lugar",
      "2nd place medal",
      "Medalla del segundo lugar"
    ],
    "keywordsStr": "2nd 2º Medalla award de del lugar medal medalha medalla place premio prêmio segundo",
    "imgUrl": "assets/emojis/1f948.svg"
  },
  {
    "codes": "1f949",
    "char": "🥉",
    "name": "3rd Place Medal",
    "category": "Activities (award-medal)",
    "group": "activities",
    "subgroup": "award-medal",
    "keywords": [
      "prêmio",
      "award",
      "premio",
      "medalha",
      "medal",
      "medalla",
      "medalha de prêmio",
      "award medal",
      "medalla de premio",
      "medalha de 3º lugar",
      "3rd place medal",
      "Medalla del 3er lugar"
    ],
    "keywordsStr": "3er 3rd 3º Medalla award de del lugar medal medalha medalla place premio prêmio",
    "imgUrl": "assets/emojis/1f949.svg"
  },
  {
    "codes": "26bd",
    "char": "⚽",
    "name": "Soccer Ball",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "bola de futebol",
      "soccer ball",
      "balón de fútbol"
    ],
    "keywordsStr": "ball balón bola de deporte esporte futebol fútbol soccer sport",
    "imgUrl": "assets/emojis/26bd.svg"
  },
  {
    "codes": "26be",
    "char": "⚾",
    "name": "Baseball",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "beisebol",
      "baseball",
      "béisbol"
    ],
    "keywordsStr": "baseball beisebol béisbol deporte esporte sport",
    "imgUrl": "assets/emojis/26be.svg"
  },
  {
    "codes": "1f94e",
    "char": "🥎",
    "name": "Softball",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "softbol",
      "softball"
    ],
    "keywordsStr": "deporte esporte softball softbol sport",
    "imgUrl": "assets/emojis/1f94e.svg"
  },
  {
    "codes": "1f3c0",
    "char": "🏀",
    "name": "Basketball",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "basquete",
      "basketball",
      "baloncesto"
    ],
    "keywordsStr": "baloncesto basketball basquete deporte esporte sport",
    "imgUrl": "assets/emojis/1f3c0.svg"
  },
  {
    "codes": "1f3d0",
    "char": "🏐",
    "name": "Volleyball",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "voleibol",
      "volleyball"
    ],
    "keywordsStr": "deporte esporte sport voleibol volleyball",
    "imgUrl": "assets/emojis/1f3d0.svg"
  },
  {
    "codes": "1f3c8",
    "char": "🏈",
    "name": "American Football",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "futebol americano",
      "american football",
      "fútbol americano"
    ],
    "keywordsStr": "american americano deporte esporte football futebol fútbol sport",
    "imgUrl": "assets/emojis/1f3c8.svg"
  },
  {
    "codes": "1f3c9",
    "char": "🏉",
    "name": "Rugby Football",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "futebol de rugby",
      "rugby football",
      "fútbol de rugby"
    ],
    "keywordsStr": "de deporte esporte football futebol fútbol rugby sport",
    "imgUrl": "assets/emojis/1f3c9.svg"
  },
  {
    "codes": "1f3be",
    "char": "🎾",
    "name": "Tennis",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "tênis",
      "tennis",
      "tenis"
    ],
    "keywordsStr": "deporte esporte sport tenis tennis tênis",
    "imgUrl": "assets/emojis/1f3be.svg"
  },
  {
    "codes": "1f94f",
    "char": "🥏",
    "name": "Flying Disc",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "disco voador",
      "flying disc",
      "disco volador"
    ],
    "keywordsStr": "deporte disc disco esporte flying sport voador volador",
    "imgUrl": "assets/emojis/1f94f.svg"
  },
  {
    "codes": "1f3b3",
    "char": "🎳",
    "name": "Bowling",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "boliche",
      "bowling",
      "bolos"
    ],
    "keywordsStr": "boliche bolos bowling deporte esporte sport",
    "imgUrl": "assets/emojis/1f3b3.svg"
  },
  {
    "codes": "1f3cf",
    "char": "🏏",
    "name": "Cricket Game",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "jogo de críquete",
      "cricket game",
      "juego de criquet"
    ],
    "keywordsStr": "cricket criquet críquete de deporte esporte game jogo juego sport",
    "imgUrl": "assets/emojis/1f3cf.svg"
  },
  {
    "codes": "1f3d1",
    "char": "🏑",
    "name": "Field Hockey",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "hóquei em campo",
      "field hockey",
      "hockey sobre hierba"
    ],
    "keywordsStr": "campo deporte em esporte field hierba hockey hóquei sobre sport",
    "imgUrl": "assets/emojis/1f3d1.svg"
  },
  {
    "codes": "1f3d2",
    "char": "🏒",
    "name": "Ice Hockey",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "hóquei no gelo",
      "ice hockey",
      "hockey sobre hielo"
    ],
    "keywordsStr": "deporte esporte gelo hielo hockey hóquei ice no sobre sport",
    "imgUrl": "assets/emojis/1f3d2.svg"
  },
  {
    "codes": "1f94d",
    "char": "🥍",
    "name": "Lacrosse",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "lacrosse"
    ],
    "keywordsStr": "deporte esporte lacrosse sport",
    "imgUrl": "assets/emojis/1f94d.svg"
  },
  {
    "codes": "1f3d3",
    "char": "🏓",
    "name": "Ping Pong",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "ping-pong",
      "ping pong"
    ],
    "keywordsStr": "deporte esporte ping ping-pong pong sport",
    "imgUrl": "assets/emojis/1f3d3.svg"
  },
  {
    "codes": "1f3f8",
    "char": "🏸",
    "name": "Badminton",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      " badminton ",
      "badminton",
      "bádminton"
    ],
    "keywordsStr": "badminton bádminton deporte esporte sport",
    "imgUrl": "assets/emojis/1f3f8.svg"
  },
  {
    "codes": "1f94a",
    "char": "🥊",
    "name": "Boxing Glove",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "luva de boxe",
      "boxing glove",
      "guante de boxeo"
    ],
    "keywordsStr": "boxe boxeo boxing de deporte esporte glove guante luva sport",
    "imgUrl": "assets/emojis/1f94a.svg"
  },
  {
    "codes": "1f94b",
    "char": "🥋",
    "name": "Martial Arts Uniform",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "uniforme de artes marciais",
      "martial arts uniform",
      "uniforme de artes marciales"
    ],
    "keywordsStr": "artes arts de deporte esporte marciais marciales martial sport uniform uniforme",
    "imgUrl": "assets/emojis/1f94b.svg"
  },
  {
    "codes": "1f945",
    "char": "🥅",
    "name": "Goal Net",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "rede de gol",
      "goal net",
      "red de la portería"
    ],
    "keywordsStr": "de deporte esporte goal gol la net portería red rede sport",
    "imgUrl": "assets/emojis/1f945.svg"
  },
  {
    "codes": "26f3",
    "char": "⛳",
    "name": "Flag In Hole",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "em",
      "in",
      "en"
    ],
    "keywordsStr": "deporte em en esporte in sport",
    "imgUrl": "assets/emojis/26f3.svg"
  },
  {
    "codes": "26f8",
    "char": "⛸️",
    "name": "Ice Skate",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "patim no gelo",
      "ice skate",
      "patinaje sobre hielo"
    ],
    "keywordsStr": "deporte esporte gelo hielo ice no patim patinaje skate sobre sport",
    "imgUrl": "assets/emojis/26f8.svg"
  },
  {
    "codes": "1f3a3",
    "char": "🎣",
    "name": "Fishing Pole",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "vara de pesca",
      "fishing pole",
      "caña de pescar"
    ],
    "keywordsStr": "caña de deporte esporte fishing pesca pescar pole sport vara",
    "imgUrl": "assets/emojis/1f3a3.svg"
  },
  {
    "codes": "1f93f",
    "char": "🤿",
    "name": "Diving Mask",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "máscara de mergulho",
      "diving mask",
      "máscara de buceo"
    ],
    "keywordsStr": "buceo de deporte diving esporte mask mergulho máscara sport",
    "imgUrl": "assets/emojis/1f93f.svg"
  },
  {
    "codes": "1f3bd",
    "char": "🎽",
    "name": "Running Shirt",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "camisa corrida",
      "running shirt",
      "camisa de correr"
    ],
    "keywordsStr": "camisa correr corrida de deporte esporte running shirt sport",
    "imgUrl": "assets/emojis/1f3bd.svg"
  },
  {
    "codes": "1f3bf",
    "char": "🎿",
    "name": "Skis",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "esquis",
      "skis",
      "esquís"
    ],
    "keywordsStr": "deporte esporte esquis esquís skis sport",
    "imgUrl": "assets/emojis/1f3bf.svg"
  },
  {
    "codes": "1f6f7",
    "char": "🛷",
    "name": "Sled",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "trenó",
      "sled",
      "trineo"
    ],
    "keywordsStr": "deporte esporte sled sport trenó trineo",
    "imgUrl": "assets/emojis/1f6f7.svg"
  },
  {
    "codes": "1f94c",
    "char": "🥌",
    "name": "Curling Stone",
    "category": "Activities (sport)",
    "group": "activities",
    "subgroup": "sport",
    "keywords": [
      "esporte",
      "sport",
      "deporte",
      "pedra de ondulação",
      "curling stone",
      "piedra que se encrespa"
    ],
    "keywordsStr": "curling de deporte encrespa esporte ondulação pedra piedra que se sport stone",
    "imgUrl": "assets/emojis/1f94c.svg"
  },
  {
    "codes": "1fa80",
    "char": "🪀",
    "name": "Yo Yo",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "ioiô",
      "yo-yo"
    ],
    "keywordsStr": "game ioiô jogo juego yo-yo",
    "imgUrl": "assets/emojis/1fa80.svg"
  },
  {
    "codes": "1fa81",
    "char": "🪁",
    "name": "Kite",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "pipa",
      "kite",
      "cometa"
    ],
    "keywordsStr": "cometa game jogo juego kite pipa",
    "imgUrl": "assets/emojis/1fa81.svg"
  },
  {
    "codes": "1f3b1",
    "char": "🎱",
    "name": "Pool 8 Ball",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "bola de bilhar 8",
      "pool 8 ball",
      "bola de billar 8"
    ],
    "keywordsStr": "8 ball bilhar billar bola de game jogo juego pool",
    "imgUrl": "assets/emojis/1f3b1.svg"
  },
  {
    "codes": "1f52e",
    "char": "🔮",
    "name": "Crystal Ball",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "bola de cristal",
      "crystal ball"
    ],
    "keywordsStr": "ball bola cristal crystal de game jogo juego",
    "imgUrl": "assets/emojis/1f52e.svg"
  },
  {
    "codes": "1fa84",
    "char": "🪄",
    "name": "Magic Wand",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "varinha mágica",
      "magic wand",
      "varita mágica"
    ],
    "keywordsStr": "game jogo juego magic mágica varinha varita wand",
    "imgUrl": "assets/emojis/1fa84.svg"
  },
  {
    "codes": "1f9ff",
    "char": "🧿",
    "name": "Nazar Amulet",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "amuleto nazar",
      "nazar amulet"
    ],
    "keywordsStr": "amulet amuleto game jogo juego nazar",
    "imgUrl": "assets/emojis/1f9ff.svg"
  },
  {
    "codes": "1f3ae",
    "char": "🎮",
    "name": "Video Game",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "jogo de vídeo",
      "video game",
      "videojuego"
    ],
    "keywordsStr": "de game jogo juego video videojuego vídeo",
    "imgUrl": "assets/emojis/1f3ae.svg"
  },
  {
    "codes": "1f579",
    "char": "🕹️",
    "name": "Joystick",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "manchete",
      "joystick",
      "palanca de mando"
    ],
    "keywordsStr": "de game jogo joystick juego manchete mando palanca",
    "imgUrl": "assets/emojis/1f579.svg"
  },
  {
    "codes": "1f3b0",
    "char": "🎰",
    "name": "Slot Machine",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "máquina caça-níqueis",
      "slot machine",
      "máquina tragamonedas"
    ],
    "keywordsStr": "caça-níqueis game jogo juego machine máquina slot tragamonedas",
    "imgUrl": "assets/emojis/1f3b0.svg"
  },
  {
    "codes": "1f3b2",
    "char": "🎲",
    "name": "Game Die",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "jogo morrer",
      "game die",
      "juego morir"
    ],
    "keywordsStr": "die game jogo juego morir morrer",
    "imgUrl": "assets/emojis/1f3b2.svg"
  },
  {
    "codes": "1f9e9",
    "char": "🧩",
    "name": "Puzzle Piece",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "peça de quebra-cabeça",
      "puzzle piece",
      "pieza de rompecabezas"
    ],
    "keywordsStr": "de game jogo juego peça piece pieza puzzle quebra-cabeça rompecabezas",
    "imgUrl": "assets/emojis/1f9e9.svg"
  },
  {
    "codes": "1f9f8",
    "char": "🧸",
    "name": "Teddy Bear",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "ursinho de pelúcia",
      "teddy bear",
      "oso de peluche"
    ],
    "keywordsStr": "bear de game jogo juego oso peluche pelúcia teddy ursinho",
    "imgUrl": "assets/emojis/1f9f8.svg"
  },
  {
    "codes": "1fa86",
    "char": "🪆",
    "name": "Nesting Dolls",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "bonecas aninhadas",
      "nesting dolls",
      "muñecas de anidación"
    ],
    "keywordsStr": "anidación aninhadas bonecas de dolls game jogo juego muñecas nesting",
    "imgUrl": "assets/emojis/1fa86.svg"
  },
  {
    "codes": "2660",
    "char": "♠️",
    "name": "Spade Suit",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "terno espada",
      "spade suit",
      "traje de espada"
    ],
    "keywordsStr": "de espada game jogo juego spade suit terno traje",
    "imgUrl": "assets/emojis/2660.svg"
  },
  {
    "codes": "2665",
    "char": "♥️",
    "name": "Heart Suit",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "traje de coração",
      "heart suit",
      "traje de corazón"
    ],
    "keywordsStr": "corazón coração de game heart jogo juego suit traje",
    "imgUrl": "assets/emojis/2665.svg"
  },
  {
    "codes": "2666",
    "char": "♦️",
    "name": "Diamond Suit",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "traje de diamante",
      "diamond suit"
    ],
    "keywordsStr": "de diamante diamond game jogo juego suit traje",
    "imgUrl": "assets/emojis/2666.svg"
  },
  {
    "codes": "2663",
    "char": "♣️",
    "name": "Club Suit",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "terno clube",
      "club suit",
      "traje de club"
    ],
    "keywordsStr": "club clube de game jogo juego suit terno traje",
    "imgUrl": "assets/emojis/2663.svg"
  },
  {
    "codes": "265f",
    "char": "♟️",
    "name": "Chess Pawn",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "peão de xadrez",
      "chess pawn",
      "peón de ajedrez"
    ],
    "keywordsStr": "ajedrez chess de game jogo juego pawn peão peón xadrez",
    "imgUrl": "assets/emojis/265f.svg"
  },
  {
    "codes": "1f0cf",
    "char": "🃏",
    "name": "Joker",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "coringa",
      "joker",
      "bromista"
    ],
    "keywordsStr": "bromista coringa game jogo joker juego",
    "imgUrl": "assets/emojis/1f0cf.svg"
  },
  {
    "codes": "1f004",
    "char": "🀄",
    "name": "Mahjong Red Dragon",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "dragão vermelho mahjong",
      "mahjong red dragon",
      "dragón rojo mahjong"
    ],
    "keywordsStr": "dragon dragão dragón game jogo juego mahjong red rojo vermelho",
    "imgUrl": "assets/emojis/1f004.svg"
  },
  {
    "codes": "1f3b4",
    "char": "🎴",
    "name": "Flower Playing Cards",
    "category": "Activities (game)",
    "group": "activities",
    "subgroup": "game",
    "keywords": [
      "jogo",
      "game",
      "juego",
      "cartas de jogo de flores",
      "flower playing cards",
      "jugando a las cartas de flores"
    ],
    "keywordsStr": "a cards cartas de flores flower game jogo juego jugando las playing",
    "imgUrl": "assets/emojis/1f3b4.svg"
  },
  {
    "codes": "1f3ad",
    "char": "🎭",
    "name": "Performing Arts",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "artes cênicas",
      "performing arts",
      "artes escénicas"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía arts crafts cênicas escénicas performing",
    "imgUrl": "assets/emojis/1f3ad.svg"
  },
  {
    "codes": "1f5bc",
    "char": "🖼️",
    "name": "Framed Picture",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "imagem emoldurada",
      "framed picture",
      "imagen enmarcada"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía arts crafts emoldurada enmarcada framed imagem imagen picture",
    "imgUrl": "assets/emojis/1f5bc.svg"
  },
  {
    "codes": "1f3a8",
    "char": "🎨",
    "name": "Artist Palette",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "paleta de artista",
      "artist palette"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía artist artista arts crafts de paleta palette",
    "imgUrl": "assets/emojis/1f3a8.svg"
  },
  {
    "codes": "1f9f5",
    "char": "🧵",
    "name": "Thread",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "fio",
      "thread",
      "hilo"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía arts crafts fio hilo thread",
    "imgUrl": "assets/emojis/1f9f5.svg"
  },
  {
    "codes": "1faa1",
    "char": "🪡",
    "name": "Sewing Needle",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "agulha de costura",
      "sewing needle",
      "aguja de coser"
    ],
    "keywordsStr": "aguja agulha artes artesanais artesanato artesanía arts coser costura crafts de needle sewing",
    "imgUrl": "assets/emojis/1faa1.svg"
  },
  {
    "codes": "1f9f6",
    "char": "🧶",
    "name": "Yarn",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "fio",
      "yarn",
      "hilo"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía arts crafts fio hilo yarn",
    "imgUrl": "assets/emojis/1f9f6.svg"
  },
  {
    "codes": "1faa2",
    "char": "🪢",
    "name": "Knot",
    "category": "Activities (arts-crafts)",
    "group": "activities",
    "subgroup": "arts-crafts",
    "keywords": [
      "artes",
      "arts",
      "artesanato",
      "crafts",
      "artesanía",
      "artes artesanais",
      "arts crafts",
      "nó",
      "knot",
      "nudo"
    ],
    "keywordsStr": "artes artesanais artesanato artesanía arts crafts knot nudo nó",
    "imgUrl": "assets/emojis/1faa2.svg"
  },
  {
    "codes": "1f453",
    "char": "👓",
    "name": "Glasses",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "óculos",
      "glasses",
      "gafas"
    ],
    "keywordsStr": "clothing gafas glasses ropa roupas óculos",
    "imgUrl": "assets/emojis/1f453.svg"
  },
  {
    "codes": "1f576",
    "char": "🕶️",
    "name": "Sunglasses",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "óculos de sol",
      "sunglasses",
      "gafas de sol"
    ],
    "keywordsStr": "clothing de gafas ropa roupas sol sunglasses óculos",
    "imgUrl": "assets/emojis/1f576.svg"
  },
  {
    "codes": "1f97d",
    "char": "🥽",
    "name": "Goggles",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "óculos",
      "goggles",
      "gafas"
    ],
    "keywordsStr": "clothing gafas goggles ropa roupas óculos",
    "imgUrl": "assets/emojis/1f97d.svg"
  },
  {
    "codes": "1f97c",
    "char": "🥼",
    "name": "Lab Coat",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "jaleco",
      "lab coat",
      "bata de laboratorio"
    ],
    "keywordsStr": "bata clothing coat de jaleco lab laboratorio ropa roupas",
    "imgUrl": "assets/emojis/1f97c.svg"
  },
  {
    "codes": "1f9ba",
    "char": "🦺",
    "name": "Safety Vest",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "colete de segurança",
      "safety vest",
      "chaleco de seguridad"
    ],
    "keywordsStr": "chaleco clothing colete de ropa roupas safety segurança seguridad vest",
    "imgUrl": "assets/emojis/1f9ba.svg"
  },
  {
    "codes": "1f454",
    "char": "👔",
    "name": "Necktie",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "gravata",
      "necktie",
      "corbata"
    ],
    "keywordsStr": "clothing corbata gravata necktie ropa roupas",
    "imgUrl": "assets/emojis/1f454.svg"
  },
  {
    "codes": "1f455",
    "char": "👕",
    "name": "T Shirt",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "camiseta",
      "t-shirt"
    ],
    "keywordsStr": "camiseta clothing ropa roupas t-shirt",
    "imgUrl": "assets/emojis/1f455.svg"
  },
  {
    "codes": "1f456",
    "char": "👖",
    "name": "Jeans",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "jeans",
      "vaqueros"
    ],
    "keywordsStr": "clothing jeans ropa roupas vaqueros",
    "imgUrl": "assets/emojis/1f456.svg"
  },
  {
    "codes": "1f9e3",
    "char": "🧣",
    "name": "Scarf",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "lenço",
      "scarf",
      "bufanda"
    ],
    "keywordsStr": "bufanda clothing lenço ropa roupas scarf",
    "imgUrl": "assets/emojis/1f9e3.svg"
  },
  {
    "codes": "1f9e4",
    "char": "🧤",
    "name": "Gloves",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "luvas",
      "gloves",
      "guantes"
    ],
    "keywordsStr": "clothing gloves guantes luvas ropa roupas",
    "imgUrl": "assets/emojis/1f9e4.svg"
  },
  {
    "codes": "1f9e5",
    "char": "🧥",
    "name": "Coat",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "casaco",
      "coat",
      "abrigo"
    ],
    "keywordsStr": "abrigo casaco clothing coat ropa roupas",
    "imgUrl": "assets/emojis/1f9e5.svg"
  },
  {
    "codes": "1f9e6",
    "char": "🧦",
    "name": "Socks",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "meias",
      "socks",
      "calcetines"
    ],
    "keywordsStr": "calcetines clothing meias ropa roupas socks",
    "imgUrl": "assets/emojis/1f9e6.svg"
  },
  {
    "codes": "1f457",
    "char": "👗",
    "name": "Dress",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "vestido",
      "dress"
    ],
    "keywordsStr": "clothing dress ropa roupas vestido",
    "imgUrl": "assets/emojis/1f457.svg"
  },
  {
    "codes": "1f458",
    "char": "👘",
    "name": "Kimono",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "quimono",
      "kimono"
    ],
    "keywordsStr": "clothing kimono quimono ropa roupas",
    "imgUrl": "assets/emojis/1f458.svg"
  },
  {
    "codes": "1f97b",
    "char": "🥻",
    "name": "Sari",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sari"
    ],
    "keywordsStr": "clothing ropa roupas sari",
    "imgUrl": "assets/emojis/1f97b.svg"
  },
  {
    "codes": "1fa71",
    "char": "🩱",
    "name": "One Piece Swimsuit",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "fato de banho de uma peça",
      "one-piece swimsuit",
      "traje de baño de una pieza"
    ],
    "keywordsStr": "banho baño clothing de fato one-piece peça pieza ropa roupas swimsuit traje uma una",
    "imgUrl": "assets/emojis/1fa71.svg"
  },
  {
    "codes": "1fa72",
    "char": "🩲",
    "name": "Briefs",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "breves",
      "briefs",
      "calzoncillos"
    ],
    "keywordsStr": "breves briefs calzoncillos clothing ropa roupas",
    "imgUrl": "assets/emojis/1fa72.svg"
  },
  {
    "codes": "1fa73",
    "char": "🩳",
    "name": "Shorts",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "shorts",
      "pantalones cortos"
    ],
    "keywordsStr": "clothing cortos pantalones ropa roupas shorts",
    "imgUrl": "assets/emojis/1fa73.svg"
  },
  {
    "codes": "1f459",
    "char": "👙",
    "name": "Bikini",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "biquíni",
      "bikini"
    ],
    "keywordsStr": "bikini biquíni clothing ropa roupas",
    "imgUrl": "assets/emojis/1f459.svg"
  },
  {
    "codes": "1f45a",
    "char": "👚",
    "name": "Womans Clothes",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "roupas femininas",
      "womans clothes",
      "ropa de mujer"
    ],
    "keywordsStr": "clothes clothing de femininas mujer ropa roupas womans",
    "imgUrl": "assets/emojis/1f45a.svg"
  },
  {
    "codes": "1f45b",
    "char": "👛",
    "name": "Purse",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "bolsa",
      "purse",
      "monedero"
    ],
    "keywordsStr": "bolsa clothing monedero purse ropa roupas",
    "imgUrl": "assets/emojis/1f45b.svg"
  },
  {
    "codes": "1f45c",
    "char": "👜",
    "name": "Handbag",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "bolsa",
      "handbag",
      "bolso"
    ],
    "keywordsStr": "bolsa bolso clothing handbag ropa roupas",
    "imgUrl": "assets/emojis/1f45c.svg"
  },
  {
    "codes": "1f45d",
    "char": "👝",
    "name": "Clutch Bag",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "bolsa de embreagem",
      "clutch bag",
      "bolso de embrague"
    ],
    "keywordsStr": "bag bolsa bolso clothing clutch de embrague embreagem ropa roupas",
    "imgUrl": "assets/emojis/1f45d.svg"
  },
  {
    "codes": "1f6cd",
    "char": "🛍️",
    "name": "Shopping Bags",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sacos de compras",
      "shopping bags",
      "bolsas de compras"
    ],
    "keywordsStr": "bags bolsas clothing compras de ropa roupas sacos shopping",
    "imgUrl": "assets/emojis/1f6cd.svg"
  },
  {
    "codes": "1f392",
    "char": "🎒",
    "name": "Backpack",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "mochila",
      "backpack"
    ],
    "keywordsStr": "backpack clothing mochila ropa roupas",
    "imgUrl": "assets/emojis/1f392.svg"
  },
  {
    "codes": "1fa74",
    "char": "🩴",
    "name": "Thong Sandal",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sandália fio dental",
      "thong sandal",
      "sandalia de tiras"
    ],
    "keywordsStr": "clothing de dental fio ropa roupas sandal sandalia sandália thong tiras",
    "imgUrl": "assets/emojis/1fa74.svg"
  },
  {
    "codes": "1f45e",
    "char": "👞",
    "name": "Mans Shoe",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sapato masculino",
      "mans shoe",
      "zapato de hombre"
    ],
    "keywordsStr": "clothing de hombre mans masculino ropa roupas sapato shoe zapato",
    "imgUrl": "assets/emojis/1f45e.svg"
  },
  {
    "codes": "1f45f",
    "char": "👟",
    "name": "Running Shoe",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sapato de corrida",
      "running shoe",
      "zapatilla para correr"
    ],
    "keywordsStr": "clothing correr corrida de para ropa roupas running sapato shoe zapatilla",
    "imgUrl": "assets/emojis/1f45f.svg"
  },
  {
    "codes": "1f97e",
    "char": "🥾",
    "name": "Hiking Boot",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "bota de caminhada",
      "hiking boot",
      "bota de montaña"
    ],
    "keywordsStr": "boot bota caminhada clothing de hiking montaña ropa roupas",
    "imgUrl": "assets/emojis/1f97e.svg"
  },
  {
    "codes": "1f97f",
    "char": "🥿",
    "name": "Flat Shoe",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sapato raso",
      "flat shoe",
      "zapato plano"
    ],
    "keywordsStr": "clothing flat plano raso ropa roupas sapato shoe zapato",
    "imgUrl": "assets/emojis/1f97f.svg"
  },
  {
    "codes": "1f460",
    "char": "👠",
    "name": "High Heeled Shoe",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sapato de salto alto",
      "high-heeled shoe",
      "zapato de tacón alto"
    ],
    "keywordsStr": "alto clothing de high-heeled ropa roupas salto sapato shoe tacón zapato",
    "imgUrl": "assets/emojis/1f460.svg"
  },
  {
    "codes": "1f461",
    "char": "👡",
    "name": "Womans Sandal",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sandália de mulher",
      "womans sandal",
      "sandalia de mujer"
    ],
    "keywordsStr": "clothing de mujer mulher ropa roupas sandal sandalia sandália womans",
    "imgUrl": "assets/emojis/1f461.svg"
  },
  {
    "codes": "1fa70",
    "char": "🩰",
    "name": "Ballet Shoes",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "sapatilhas de balé",
      "ballet shoes",
      "zapatillas de ballet"
    ],
    "keywordsStr": "ballet balé clothing de ropa roupas sapatilhas shoes zapatillas",
    "imgUrl": "assets/emojis/1fa70.svg"
  },
  {
    "codes": "1f462",
    "char": "👢",
    "name": "Womans Boot",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "bota feminina",
      "womans boot",
      "bota de mujer"
    ],
    "keywordsStr": "boot bota clothing de feminina mujer ropa roupas womans",
    "imgUrl": "assets/emojis/1f462.svg"
  },
  {
    "codes": "1f451",
    "char": "👑",
    "name": "Crown",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "coroa",
      "crown",
      "corona"
    ],
    "keywordsStr": "clothing coroa corona crown ropa roupas",
    "imgUrl": "assets/emojis/1f451.svg"
  },
  {
    "codes": "1f452",
    "char": "👒",
    "name": "Womans Hat",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "chapéu de mulher",
      "womans hat",
      "sombrero de mujer"
    ],
    "keywordsStr": "chapéu clothing de hat mujer mulher ropa roupas sombrero womans",
    "imgUrl": "assets/emojis/1f452.svg"
  },
  {
    "codes": "1f3a9",
    "char": "🎩",
    "name": "Top Hat",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "chapéu",
      "top hat",
      "sombrero de copa"
    ],
    "keywordsStr": "chapéu clothing copa de hat ropa roupas sombrero top",
    "imgUrl": "assets/emojis/1f3a9.svg"
  },
  {
    "codes": "1f393",
    "char": "🎓",
    "name": "Graduation Cap",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "tampa de formatura",
      "graduation cap",
      "gorro de graduación"
    ],
    "keywordsStr": "cap clothing de formatura gorro graduación graduation ropa roupas tampa",
    "imgUrl": "assets/emojis/1f393.svg"
  },
  {
    "codes": "1f9e2",
    "char": "🧢",
    "name": "Billed Cap",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "limite cobrado",
      "billed cap",
      "tapa facturada"
    ],
    "keywordsStr": "billed cap clothing cobrado facturada limite ropa roupas tapa",
    "imgUrl": "assets/emojis/1f9e2.svg"
  },
  {
    "codes": "1fa96",
    "char": "🪖",
    "name": "Military Helmet",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "capacete militar",
      "military helmet",
      "casco militar"
    ],
    "keywordsStr": "capacete casco clothing helmet militar military ropa roupas",
    "imgUrl": "assets/emojis/1fa96.svg"
  },
  {
    "codes": "26d1",
    "char": "⛑️",
    "name": "Rescue Workers Helmet",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "capacete do trabalhador de resgate",
      "rescue workers helmet",
      "casco de trabajador de rescate"
    ],
    "keywordsStr": "capacete casco clothing de do helmet rescate rescue resgate ropa roupas trabajador trabalhador workers",
    "imgUrl": "assets/emojis/26d1.svg"
  },
  {
    "codes": "1f4ff",
    "char": "📿",
    "name": "Prayer Beads",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "contas de oração",
      "prayer beads",
      "cuentas de oración"
    ],
    "keywordsStr": "beads clothing contas cuentas de oración oração prayer ropa roupas",
    "imgUrl": "assets/emojis/1f4ff.svg"
  },
  {
    "codes": "1f484",
    "char": "💄",
    "name": "Lipstick",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "batom",
      "lipstick",
      "lápiz labial"
    ],
    "keywordsStr": "batom clothing labial lipstick lápiz ropa roupas",
    "imgUrl": "assets/emojis/1f484.svg"
  },
  {
    "codes": "1f48d",
    "char": "💍",
    "name": "Ring",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "anel",
      "ring",
      "anillo"
    ],
    "keywordsStr": "anel anillo clothing ring ropa roupas",
    "imgUrl": "assets/emojis/1f48d.svg"
  },
  {
    "codes": "1f48e",
    "char": "💎",
    "name": "Gem Stone",
    "category": "Objects (clothing)",
    "group": "objects",
    "subgroup": "clothing",
    "keywords": [
      "roupas",
      "clothing",
      "ropa",
      "pedra preciosa",
      "gem stone",
      "piedra preciosa"
    ],
    "keywordsStr": "clothing gem pedra piedra preciosa ropa roupas stone",
    "imgUrl": "assets/emojis/1f48e.svg"
  },
  {
    "codes": "1f507",
    "char": "🔇",
    "name": "Muted Speaker",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "alto-falante silenciado",
      "muted speaker",
      "altavoz silenciado"
    ],
    "keywordsStr": "altavoz alto-falante muted silenciado som sonido sound speaker",
    "imgUrl": "assets/emojis/1f507.svg"
  },
  {
    "codes": "1f508",
    "char": "🔈",
    "name": "Speaker Low Volume",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "alto-falante baixo volume",
      "speaker low volume",
      "altavoz volumen bajo"
    ],
    "keywordsStr": "altavoz alto-falante baixo bajo low som sonido sound speaker volume volumen",
    "imgUrl": "assets/emojis/1f508.svg"
  },
  {
    "codes": "1f509",
    "char": "🔉",
    "name": "Speaker Medium Volume",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "alto-falante volume médio",
      "speaker medium volume",
      "altavoz de volumen medio"
    ],
    "keywordsStr": "altavoz alto-falante de medio medium médio som sonido sound speaker volume volumen",
    "imgUrl": "assets/emojis/1f509.svg"
  },
  {
    "codes": "1f50a",
    "char": "🔊",
    "name": "Speaker High Volume",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "alto-falante de alto volume",
      "speaker high volume",
      "altavoz de volumen alto"
    ],
    "keywordsStr": "altavoz alto alto-falante de high som sonido sound speaker volume volumen",
    "imgUrl": "assets/emojis/1f50a.svg"
  },
  {
    "codes": "1f4e2",
    "char": "📢",
    "name": "Loudspeaker",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "alto-falante",
      "loudspeaker",
      "altavoz"
    ],
    "keywordsStr": "altavoz alto-falante loudspeaker som sonido sound",
    "imgUrl": "assets/emojis/1f4e2.svg"
  },
  {
    "codes": "1f4e3",
    "char": "📣",
    "name": "Megaphone",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "megafone",
      "megaphone",
      "megáfono"
    ],
    "keywordsStr": "megafone megaphone megáfono som sonido sound",
    "imgUrl": "assets/emojis/1f4e3.svg"
  },
  {
    "codes": "1f4ef",
    "char": "📯",
    "name": "Postal Horn",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "buzina postal",
      "postal horn",
      "cuerno postal"
    ],
    "keywordsStr": "buzina cuerno horn postal som sonido sound",
    "imgUrl": "assets/emojis/1f4ef.svg"
  },
  {
    "codes": "1f514",
    "char": "🔔",
    "name": "Bell",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "sino",
      "bell",
      "campana"
    ],
    "keywordsStr": "bell campana sino som sonido sound",
    "imgUrl": "assets/emojis/1f514.svg"
  },
  {
    "codes": "1f515",
    "char": "🔕",
    "name": "Bell With Slash",
    "category": "Objects (sound)",
    "group": "objects",
    "subgroup": "sound",
    "keywords": [
      "som",
      "sound",
      "sonido",
      "sino com barra",
      "bell with slash",
      "campana con barra oblicua"
    ],
    "keywordsStr": "barra bell campana com con oblicua sino slash som sonido sound with",
    "imgUrl": "assets/emojis/1f515.svg"
  },
  {
    "codes": "1f3bc",
    "char": "🎼",
    "name": "Musical Score",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "partitura musical",
      "musical score"
    ],
    "keywordsStr": "music musical música partitura score",
    "imgUrl": "assets/emojis/1f3bc.svg"
  },
  {
    "codes": "1f3b5",
    "char": "🎵",
    "name": "Musical Note",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "nota musical",
      "musical note"
    ],
    "keywordsStr": "music musical música nota note",
    "imgUrl": "assets/emojis/1f3b5.svg"
  },
  {
    "codes": "1f3b6",
    "char": "🎶",
    "name": "Musical Notes",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "notas musicais",
      "musical notes",
      "notas musicales"
    ],
    "keywordsStr": "music musicais musical musicales música notas notes",
    "imgUrl": "assets/emojis/1f3b6.svg"
  },
  {
    "codes": "1f399",
    "char": "🎙️",
    "name": "Studio Microphone",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "microfone de estúdio",
      "studio microphone",
      "micrófono de estudio"
    ],
    "keywordsStr": "de estudio estúdio microfone microphone micrófono music música studio",
    "imgUrl": "assets/emojis/1f399.svg"
  },
  {
    "codes": "1f39a",
    "char": "🎚️",
    "name": "Level Slider",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "controle deslizante de nível",
      "level slider",
      "control deslizante de nivel"
    ],
    "keywordsStr": "control controle de deslizante level music música nivel nível slider",
    "imgUrl": "assets/emojis/1f39a.svg"
  },
  {
    "codes": "1f39b",
    "char": "🎛️",
    "name": "Control Knobs",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "botões de controle",
      "control knobs",
      "perillas de control"
    ],
    "keywordsStr": "botões control controle de knobs music música perillas",
    "imgUrl": "assets/emojis/1f39b.svg"
  },
  {
    "codes": "1f3a4",
    "char": "🎤",
    "name": "Microphone",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "microfone",
      "microphone",
      "micrófono"
    ],
    "keywordsStr": "microfone microphone micrófono music música",
    "imgUrl": "assets/emojis/1f3a4.svg"
  },
  {
    "codes": "1f3a7",
    "char": "🎧",
    "name": "Headphone",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "fone de ouvido",
      "headphone",
      "auriculares"
    ],
    "keywordsStr": "auriculares de fone headphone music música ouvido",
    "imgUrl": "assets/emojis/1f3a7.svg"
  },
  {
    "codes": "1f4fb",
    "char": "📻",
    "name": "Radio",
    "category": "Objects (music)",
    "group": "objects",
    "subgroup": "music",
    "keywords": [
      "música",
      "music",
      "rádio",
      "radio"
    ],
    "keywordsStr": "music música radio rádio",
    "imgUrl": "assets/emojis/1f4fb.svg"
  },
  {
    "codes": "1f3b7",
    "char": "🎷",
    "name": "Saxophone",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "saxofone",
      "saxophone",
      "saxofón"
    ],
    "keywordsStr": "instrument instrumento musical musicales saxofone saxofón saxophone",
    "imgUrl": "assets/emojis/1f3b7.svg"
  },
  {
    "codes": "1fa97",
    "char": "🪗",
    "name": "Accordion",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "acordeão",
      "accordion",
      "acordeón"
    ],
    "keywordsStr": "accordion acordeão acordeón instrument instrumento musical musicales",
    "imgUrl": "assets/emojis/1fa97.svg"
  },
  {
    "codes": "1f3b8",
    "char": "🎸",
    "name": "Guitar",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "guitarra",
      "guitar"
    ],
    "keywordsStr": "guitar guitarra instrument instrumento musical musicales",
    "imgUrl": "assets/emojis/1f3b8.svg"
  },
  {
    "codes": "1f3b9",
    "char": "🎹",
    "name": "Musical Keyboard",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "teclado musical",
      "musical keyboard"
    ],
    "keywordsStr": "instrument instrumento keyboard musical musicales teclado",
    "imgUrl": "assets/emojis/1f3b9.svg"
  },
  {
    "codes": "1f3ba",
    "char": "🎺",
    "name": "Trumpet",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "trompete",
      "trumpet",
      "trompeta"
    ],
    "keywordsStr": "instrument instrumento musical musicales trompeta trompete trumpet",
    "imgUrl": "assets/emojis/1f3ba.svg"
  },
  {
    "codes": "1f3bb",
    "char": "🎻",
    "name": "Violin",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "violino",
      "violin",
      "violín"
    ],
    "keywordsStr": "instrument instrumento musical musicales violin violino violín",
    "imgUrl": "assets/emojis/1f3bb.svg"
  },
  {
    "codes": "1fa95",
    "char": "🪕",
    "name": "Banjo",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "banjo"
    ],
    "keywordsStr": "banjo instrument instrumento musical musicales",
    "imgUrl": "assets/emojis/1fa95.svg"
  },
  {
    "codes": "1f941",
    "char": "🥁",
    "name": "Drum",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "tambor",
      "drum"
    ],
    "keywordsStr": "drum instrument instrumento musical musicales tambor",
    "imgUrl": "assets/emojis/1f941.svg"
  },
  {
    "codes": "1fa98",
    "char": "🪘",
    "name": "Long Drum",
    "category": "Objects (musical-instrument)",
    "group": "objects",
    "subgroup": "musical-instrument",
    "keywords": [
      "musical",
      "musicales",
      "instrumento",
      "instrument",
      "instrumento musical",
      "musical instrument",
      "tambor longo",
      "long drum",
      "tambor largo"
    ],
    "keywordsStr": "drum instrument instrumento largo long longo musical musicales tambor",
    "imgUrl": "assets/emojis/1fa98.svg"
  },
  {
    "codes": "1f4f1",
    "char": "📱",
    "name": "Mobile Phone",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "telefone celular",
      "mobile phone",
      "teléfono móvil"
    ],
    "keywordsStr": "celular mobile móvil phone telefone teléfono",
    "imgUrl": "assets/emojis/1f4f1.svg"
  },
  {
    "codes": "1f4f2",
    "char": "📲",
    "name": "Mobile Phone With Arrow",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "telefone celular com seta",
      "mobile phone with arrow",
      "teléfono móvil con flecha"
    ],
    "keywordsStr": "arrow celular com con flecha mobile móvil phone seta telefone teléfono with",
    "imgUrl": "assets/emojis/1f4f2.svg"
  },
  {
    "codes": "260e",
    "char": "☎️",
    "name": "Telephone",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "telephone"
    ],
    "keywordsStr": "phone telefone telephone teléfono",
    "imgUrl": "assets/emojis/260e.svg"
  },
  {
    "codes": "1f4de",
    "char": "📞",
    "name": "Telephone Receiver",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "receptor de telefone",
      "telephone receiver",
      "receptor de teléfono"
    ],
    "keywordsStr": "de phone receiver receptor telefone telephone teléfono",
    "imgUrl": "assets/emojis/1f4de.svg"
  },
  {
    "codes": "1f4df",
    "char": "📟",
    "name": "Pager",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "pager",
      "localizador"
    ],
    "keywordsStr": "localizador pager phone telefone teléfono",
    "imgUrl": "assets/emojis/1f4df.svg"
  },
  {
    "codes": "1f4e0",
    "char": "📠",
    "name": "Fax Machine",
    "category": "Objects (phone)",
    "group": "objects",
    "subgroup": "phone",
    "keywords": [
      "telefone",
      "phone",
      "teléfono",
      "máquina de fax",
      "fax machine"
    ],
    "keywordsStr": "de fax machine máquina phone telefone teléfono",
    "imgUrl": "assets/emojis/1f4e0.svg"
  },
  {
    "codes": "1f50b",
    "char": "🔋",
    "name": "Battery",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "bateria",
      "battery",
      "batería"
    ],
    "keywordsStr": "bateria batería battery computador computadora computer",
    "imgUrl": "assets/emojis/1f50b.svg"
  },
  {
    "codes": "1f50c",
    "char": "🔌",
    "name": "Electric Plug",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "plugue elétrico",
      "electric plug",
      "enchufe eléctrico"
    ],
    "keywordsStr": "computador computadora computer electric eléctrico elétrico enchufe plug plugue",
    "imgUrl": "assets/emojis/1f50c.svg"
  },
  {
    "codes": "1f4bb",
    "char": "💻",
    "name": "Laptop",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "notebook",
      "laptop",
      "portátil"
    ],
    "keywordsStr": "computador computadora computer laptop notebook portátil",
    "imgUrl": "assets/emojis/1f4bb.svg"
  },
  {
    "codes": "1f5a5",
    "char": "🖥️",
    "name": "Desktop Computer",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "computador de mesa",
      "desktop computer",
      "computadora de escritorio"
    ],
    "keywordsStr": "computador computadora computer de desktop escritorio mesa",
    "imgUrl": "assets/emojis/1f5a5.svg"
  },
  {
    "codes": "1f5a8",
    "char": "🖨️",
    "name": "Printer",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "impressora",
      "printer",
      "impresora"
    ],
    "keywordsStr": "computador computadora computer impresora impressora printer",
    "imgUrl": "assets/emojis/1f5a8.svg"
  },
  {
    "codes": "2328",
    "char": "⌨️",
    "name": "Keyboard",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "teclado",
      "keyboard"
    ],
    "keywordsStr": "computador computadora computer keyboard teclado",
    "imgUrl": "assets/emojis/2328.svg"
  },
  {
    "codes": "1f5b1",
    "char": "🖱️",
    "name": "Computer Mouse",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "rato de computador",
      "computer mouse",
      "ratón de la computadora"
    ],
    "keywordsStr": "computador computadora computer de la mouse rato ratón",
    "imgUrl": "assets/emojis/1f5b1.svg"
  },
  {
    "codes": "1f5b2",
    "char": "🖲️",
    "name": "Trackball",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "trackball",
      "bola de seguimiento"
    ],
    "keywordsStr": "bola computador computadora computer de seguimiento trackball",
    "imgUrl": "assets/emojis/1f5b2.svg"
  },
  {
    "codes": "1f4bd",
    "char": "💽",
    "name": "Computer Disk",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "disco de computador",
      "computer disk",
      "disco de computadora"
    ],
    "keywordsStr": "computador computadora computer de disco disk",
    "imgUrl": "assets/emojis/1f4bd.svg"
  },
  {
    "codes": "1f4be",
    "char": "💾",
    "name": "Floppy Disk",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "disquete",
      "floppy disk"
    ],
    "keywordsStr": "computador computadora computer disk disquete floppy",
    "imgUrl": "assets/emojis/1f4be.svg"
  },
  {
    "codes": "1f4bf",
    "char": "💿",
    "name": "Optical Disk",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "disco óptico",
      "optical disk"
    ],
    "keywordsStr": "computador computadora computer disco disk optical óptico",
    "imgUrl": "assets/emojis/1f4bf.svg"
  },
  {
    "codes": "1f4c0",
    "char": "📀",
    "name": "Dvd",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "dvd"
    ],
    "keywordsStr": "computador computadora computer dvd",
    "imgUrl": "assets/emojis/1f4c0.svg"
  },
  {
    "codes": "1f9ee",
    "char": "🧮",
    "name": "Abacus",
    "category": "Objects (computer)",
    "group": "objects",
    "subgroup": "computer",
    "keywords": [
      "computador",
      "computer",
      "computadora",
      "ábaco",
      "abacus"
    ],
    "keywordsStr": "abacus computador computadora computer ábaco",
    "imgUrl": "assets/emojis/1f9ee.svg"
  },
  {
    "codes": "1f3a5",
    "char": "🎥",
    "name": "Movie Camera",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "câmera de filme",
      "movie camera",
      "cámara de cine"
    ],
    "keywordsStr": "camera cine cámara câmera de filme leve ligero light luz movie video vídeo",
    "imgUrl": "assets/emojis/1f3a5.svg"
  },
  {
    "codes": "1f39e",
    "char": "🎞️",
    "name": "Film Frames",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "molduras de filme",
      "film frames",
      "fotogramas de película"
    ],
    "keywordsStr": "de film filme fotogramas frames leve ligero light luz molduras película video vídeo",
    "imgUrl": "assets/emojis/1f39e.svg"
  },
  {
    "codes": "1f4fd",
    "char": "📽️",
    "name": "Film Projector",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "projetor de filme",
      "film projector",
      "proyector de cine"
    ],
    "keywordsStr": "cine de film filme leve ligero light luz projector projetor proyector video vídeo",
    "imgUrl": "assets/emojis/1f4fd.svg"
  },
  {
    "codes": "1f3ac",
    "char": "🎬",
    "name": "Clapper Board",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "claquete",
      "clapper board",
      "tablero de claqueta"
    ],
    "keywordsStr": "board clapper claqueta claquete de leve ligero light luz tablero video vídeo",
    "imgUrl": "assets/emojis/1f3ac.svg"
  },
  {
    "codes": "1f4fa",
    "char": "📺",
    "name": "Television",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "televisão",
      "television",
      "televisión"
    ],
    "keywordsStr": "leve ligero light luz television televisión televisão video vídeo",
    "imgUrl": "assets/emojis/1f4fa.svg"
  },
  {
    "codes": "1f4f7",
    "char": "📷",
    "name": "Camera",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "câmera",
      "camera",
      "cámara"
    ],
    "keywordsStr": "camera cámara câmera leve ligero light luz video vídeo",
    "imgUrl": "assets/emojis/1f4f7.svg"
  },
  {
    "codes": "1f4f8",
    "char": "📸",
    "name": "Camera With Flash",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "câmera com flash",
      "camera with flash",
      "cámara con flash"
    ],
    "keywordsStr": "camera com con cámara câmera flash leve ligero light luz video vídeo with",
    "imgUrl": "assets/emojis/1f4f8.svg"
  },
  {
    "codes": "1f4f9",
    "char": "📹",
    "name": "Video Camera",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "câmera de vídeo",
      "video camera",
      "cámara de vídeo"
    ],
    "keywordsStr": "camera cámara câmera de leve ligero light luz video vídeo",
    "imgUrl": "assets/emojis/1f4f9.svg"
  },
  {
    "codes": "1f4fc",
    "char": "📼",
    "name": "Videocassette",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "videocassete",
      "videocassette",
      "videocasete"
    ],
    "keywordsStr": "leve ligero light luz video videocasete videocassete videocassette vídeo",
    "imgUrl": "assets/emojis/1f4fc.svg"
  },
  {
    "codes": "1f50d",
    "char": "🔍",
    "name": "Magnifying Glass Tilted Left",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lupa inclinada para a esquerda",
      "magnifying glass tilted left",
      "lupa inclinada a la izquierda"
    ],
    "keywordsStr": "a esquerda glass inclinada izquierda la left leve ligero light lupa luz magnifying para tilted video vídeo",
    "imgUrl": "assets/emojis/1f50d.svg"
  },
  {
    "codes": "1f50e",
    "char": "🔎",
    "name": "Magnifying Glass Tilted Right",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lupa inclinada para a direita",
      "magnifying glass tilted right",
      "lupa inclinada a la derecha"
    ],
    "keywordsStr": "a derecha direita glass inclinada la leve ligero light lupa luz magnifying para right tilted video vídeo",
    "imgUrl": "assets/emojis/1f50e.svg"
  },
  {
    "codes": "1f56f",
    "char": "🕯️",
    "name": "Candle",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "vela",
      "candle"
    ],
    "keywordsStr": "candle leve ligero light luz vela video vídeo",
    "imgUrl": "assets/emojis/1f56f.svg"
  },
  {
    "codes": "1f4a1",
    "char": "💡",
    "name": "Light Bulb",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lâmpada",
      "light bulb",
      "bombilla de luz"
    ],
    "keywordsStr": "bombilla bulb de leve ligero light luz lâmpada video vídeo",
    "imgUrl": "assets/emojis/1f4a1.svg"
  },
  {
    "codes": "1f526",
    "char": "🔦",
    "name": "Flashlight",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lanterna",
      "flashlight",
      "linterna"
    ],
    "keywordsStr": "flashlight lanterna leve ligero light linterna luz video vídeo",
    "imgUrl": "assets/emojis/1f526.svg"
  },
  {
    "codes": "1f3ee",
    "char": "🏮",
    "name": "Red Paper Lantern",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lanterna de papel vermelho",
      "red paper lantern",
      "linterna de papel roja"
    ],
    "keywordsStr": "de lantern lanterna leve ligero light linterna luz papel paper red roja vermelho video vídeo",
    "imgUrl": "assets/emojis/1f3ee.svg"
  },
  {
    "codes": "1fa94",
    "char": "🪔",
    "name": "Diya Lamp",
    "category": "Objects (light-video)",
    "group": "objects",
    "subgroup": "light-video",
    "keywords": [
      "luz",
      "light",
      "vídeo",
      "video",
      "vídeo leve",
      "light video",
      "video ligero",
      "lâmpada diya",
      "diya lamp",
      "lámpara diya"
    ],
    "keywordsStr": "diya lamp leve ligero light luz lámpara lâmpada video vídeo",
    "imgUrl": "assets/emojis/1fa94.svg"
  },
  {
    "codes": "1f4d4",
    "char": "📔",
    "name": "Notebook With Decorative Cover",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "caderno com capa decorativa",
      "notebook with decorative cover",
      "cuaderno con tapa decorativa"
    ],
    "keywordsStr": "book caderno capa com con cover cuaderno de decorativa decorative libro livro notebook papel paper tapa with",
    "imgUrl": "assets/emojis/1f4d4.svg"
  },
  {
    "codes": "1f4d5",
    "char": "📕",
    "name": "Closed Book",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livro fechado",
      "closed book",
      "libro cerrado"
    ],
    "keywordsStr": "book cerrado closed de fechado libro livro papel paper",
    "imgUrl": "assets/emojis/1f4d5.svg"
  },
  {
    "codes": "1f4d6",
    "char": "📖",
    "name": "Open Book",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livro aberto",
      "open book",
      "libro abierto"
    ],
    "keywordsStr": "aberto abierto book de libro livro open papel paper",
    "imgUrl": "assets/emojis/1f4d6.svg"
  },
  {
    "codes": "1f4d7",
    "char": "📗",
    "name": "Green Book",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livro verde",
      "green book",
      "libro verde"
    ],
    "keywordsStr": "book de green libro livro papel paper verde",
    "imgUrl": "assets/emojis/1f4d7.svg"
  },
  {
    "codes": "1f4d8",
    "char": "📘",
    "name": "Blue Book",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livro azul",
      "blue book",
      "libro azul"
    ],
    "keywordsStr": "azul blue book de libro livro papel paper",
    "imgUrl": "assets/emojis/1f4d8.svg"
  },
  {
    "codes": "1f4d9",
    "char": "📙",
    "name": "Orange Book",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livro laranja",
      "orange book",
      "libro naranja"
    ],
    "keywordsStr": "book de laranja libro livro naranja orange papel paper",
    "imgUrl": "assets/emojis/1f4d9.svg"
  },
  {
    "codes": "1f4da",
    "char": "📚",
    "name": "Books",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "livros",
      "books",
      "libros"
    ],
    "keywordsStr": "book books de libro libros livro livros papel paper",
    "imgUrl": "assets/emojis/1f4da.svg"
  },
  {
    "codes": "1f4d3",
    "char": "📓",
    "name": "Notebook",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "caderno",
      "notebook",
      "cuaderno"
    ],
    "keywordsStr": "book caderno cuaderno de libro livro notebook papel paper",
    "imgUrl": "assets/emojis/1f4d3.svg"
  },
  {
    "codes": "1f4d2",
    "char": "📒",
    "name": "Ledger",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "ledger",
      "libro mayor"
    ],
    "keywordsStr": "book de ledger libro livro mayor papel paper",
    "imgUrl": "assets/emojis/1f4d2.svg"
  },
  {
    "codes": "1f4c3",
    "char": "📃",
    "name": "Page With Curl",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "página com curl",
      "page with curl",
      "página con rizo"
    ],
    "keywordsStr": "book com con curl de libro livro page papel paper página rizo with",
    "imgUrl": "assets/emojis/1f4c3.svg"
  },
  {
    "codes": "1f4dc",
    "char": "📜",
    "name": "Scroll",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "rolagem",
      "scroll",
      "desplazamiento"
    ],
    "keywordsStr": "book de desplazamiento libro livro papel paper rolagem scroll",
    "imgUrl": "assets/emojis/1f4dc.svg"
  },
  {
    "codes": "1f4c4",
    "char": "📄",
    "name": "Page Facing Up",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "página virada para cima",
      "page facing up",
      "página hacia arriba"
    ],
    "keywordsStr": "arriba book cima de facing hacia libro livro page papel paper para página up virada",
    "imgUrl": "assets/emojis/1f4c4.svg"
  },
  {
    "codes": "1f4f0",
    "char": "📰",
    "name": "Newspaper",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "jornal",
      "newspaper",
      "periódico"
    ],
    "keywordsStr": "book de jornal libro livro newspaper papel paper periódico",
    "imgUrl": "assets/emojis/1f4f0.svg"
  },
  {
    "codes": "1f5de",
    "char": "🗞️",
    "name": "Rolled Up Newspaper",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "jornal enrolado",
      "rolled-up newspaper",
      "periódico enrollado"
    ],
    "keywordsStr": "book de enrolado enrollado jornal libro livro newspaper papel paper periódico rolled-up",
    "imgUrl": "assets/emojis/1f5de.svg"
  },
  {
    "codes": "1f4d1",
    "char": "📑",
    "name": "Bookmark Tabs",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "guias de favoritos",
      "bookmark tabs",
      "pestañas de marcadores"
    ],
    "keywordsStr": "book bookmark de favoritos guias libro livro marcadores papel paper pestañas tabs",
    "imgUrl": "assets/emojis/1f4d1.svg"
  },
  {
    "codes": "1f516",
    "char": "🔖",
    "name": "Bookmark",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "marcador",
      "bookmark"
    ],
    "keywordsStr": "book bookmark de libro livro marcador papel paper",
    "imgUrl": "assets/emojis/1f516.svg"
  },
  {
    "codes": "1f3f7",
    "char": "🏷️",
    "name": "Label",
    "category": "Objects (book-paper)",
    "group": "objects",
    "subgroup": "book-paper",
    "keywords": [
      "livro",
      "book",
      "libro",
      "papel",
      "paper",
      "papel de livro",
      "book paper",
      "papel de libro",
      "etiqueta",
      "label"
    ],
    "keywordsStr": "book de etiqueta label libro livro papel paper",
    "imgUrl": "assets/emojis/1f3f7.svg"
  },
  {
    "codes": "1f4b0",
    "char": "💰",
    "name": "Money Bag",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "bolsa de dinheiro",
      "money bag",
      "bolsa de dinero"
    ],
    "keywordsStr": "bag bolsa de dinero dinheiro money",
    "imgUrl": "assets/emojis/1f4b0.svg"
  },
  {
    "codes": "1fa99",
    "char": "🪙",
    "name": "Coin",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "moeda",
      "coin",
      "moneda"
    ],
    "keywordsStr": "coin dinero dinheiro moeda moneda money",
    "imgUrl": "assets/emojis/1fa99.svg"
  },
  {
    "codes": "1f4b4",
    "char": "💴",
    "name": "Yen Banknote",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "nota de ienes",
      "yen banknote",
      "billete de yen"
    ],
    "keywordsStr": "banknote billete de dinero dinheiro ienes money nota yen",
    "imgUrl": "assets/emojis/1f4b4.svg"
  },
  {
    "codes": "1f4b5",
    "char": "💵",
    "name": "Dollar Banknote",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "nota de dólar",
      "dollar banknote",
      "billete de dólar"
    ],
    "keywordsStr": "banknote billete de dinero dinheiro dollar dólar money nota",
    "imgUrl": "assets/emojis/1f4b5.svg"
  },
  {
    "codes": "1f4b6",
    "char": "💶",
    "name": "Euro Banknote",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "nota de euro",
      "euro banknote",
      "billete de euro"
    ],
    "keywordsStr": "banknote billete de dinero dinheiro euro money nota",
    "imgUrl": "assets/emojis/1f4b6.svg"
  },
  {
    "codes": "1f4b7",
    "char": "💷",
    "name": "Pound Banknote",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "nota de libra",
      "pound banknote",
      "billete de libra"
    ],
    "keywordsStr": "banknote billete de dinero dinheiro libra money nota pound",
    "imgUrl": "assets/emojis/1f4b7.svg"
  },
  {
    "codes": "1f4b8",
    "char": "💸",
    "name": "Money With Wings",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "dinheiro com asas",
      "money with wings",
      "dinero con alas"
    ],
    "keywordsStr": "alas asas com con dinero dinheiro money wings with",
    "imgUrl": "assets/emojis/1f4b8.svg"
  },
  {
    "codes": "1f4b3",
    "char": "💳",
    "name": "Credit Card",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "cartão de crédito",
      "credit card",
      "tarjeta de crédito"
    ],
    "keywordsStr": "card cartão credit crédito de dinero dinheiro money tarjeta",
    "imgUrl": "assets/emojis/1f4b3.svg"
  },
  {
    "codes": "1f9fe",
    "char": "🧾",
    "name": "Receipt",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "recibo",
      "receipt"
    ],
    "keywordsStr": "dinero dinheiro money receipt recibo",
    "imgUrl": "assets/emojis/1f9fe.svg"
  },
  {
    "codes": "1f4b9",
    "char": "💹",
    "name": "Chart Increasing With Yen",
    "category": "Objects (money)",
    "group": "objects",
    "subgroup": "money",
    "keywords": [
      "dinheiro",
      "money",
      "dinero",
      "gráfico aumentando com ienes",
      "chart increasing with yen",
      "gráfico creciente con yen"
    ],
    "keywordsStr": "aumentando chart com con creciente dinero dinheiro gráfico ienes increasing money with yen",
    "imgUrl": "assets/emojis/1f4b9.svg"
  },
  {
    "codes": "2709",
    "char": "✉️",
    "name": "Envelope",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "envelope",
      "sobre"
    ],
    "keywordsStr": "correio correo envelope mail sobre",
    "imgUrl": "assets/emojis/2709.svg"
  },
  {
    "codes": "1f4e7",
    "char": "📧",
    "name": "E Mail",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "e-mail",
      "correo electrónico"
    ],
    "keywordsStr": "correio correo e-mail electrónico mail",
    "imgUrl": "assets/emojis/1f4e7.svg"
  },
  {
    "codes": "1f4e8",
    "char": "📨",
    "name": "Incoming Envelope",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "envelope de entrada",
      "incoming envelope",
      "sobre entrante"
    ],
    "keywordsStr": "correio correo de entrada entrante envelope incoming mail sobre",
    "imgUrl": "assets/emojis/1f4e8.svg"
  },
  {
    "codes": "1f4e9",
    "char": "📩",
    "name": "Envelope With Arrow",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "envelope com seta",
      "envelope with arrow",
      "sobre con flecha"
    ],
    "keywordsStr": "arrow com con correio correo envelope flecha mail seta sobre with",
    "imgUrl": "assets/emojis/1f4e9.svg"
  },
  {
    "codes": "1f4e4",
    "char": "📤",
    "name": "Outbox Tray",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "bandeja de saída",
      "outbox tray",
      "bandeja de salida"
    ],
    "keywordsStr": "bandeja correio correo de mail outbox salida saída tray",
    "imgUrl": "assets/emojis/1f4e4.svg"
  },
  {
    "codes": "1f4e5",
    "char": "📥",
    "name": "Inbox Tray",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "bandeja da caixa de entrada",
      "inbox tray",
      "bandeja de entrada"
    ],
    "keywordsStr": "bandeja caixa correio correo da de entrada inbox mail tray",
    "imgUrl": "assets/emojis/1f4e5.svg"
  },
  {
    "codes": "1f4e6",
    "char": "📦",
    "name": "Package",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "pacote",
      "package",
      "paquete"
    ],
    "keywordsStr": "correio correo mail package pacote paquete",
    "imgUrl": "assets/emojis/1f4e6.svg"
  },
  {
    "codes": "1f4eb",
    "char": "📫",
    "name": "Closed Mailbox With Raised Flag",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "caixa de correio",
      "mailbox",
      "buzón de correo"
    ],
    "keywordsStr": "buzón caixa correio correo de mail mailbox",
    "imgUrl": "assets/emojis/1f4eb.svg"
  },
  {
    "codes": "1f4ea",
    "char": "📪",
    "name": "Closed Mailbox With Lowered Flag",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "caixa de correio",
      "mailbox",
      "buzón de correo"
    ],
    "keywordsStr": "buzón caixa correio correo de mail mailbox",
    "imgUrl": "assets/emojis/1f4ea.svg"
  },
  {
    "codes": "1f4ec",
    "char": "📬",
    "name": "Open Mailbox With Raised Flag",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "caixa de correio",
      "mailbox",
      "buzón de correo"
    ],
    "keywordsStr": "buzón caixa correio correo de mail mailbox",
    "imgUrl": "assets/emojis/1f4ec.svg"
  },
  {
    "codes": "1f4ed",
    "char": "📭",
    "name": "Open Mailbox With Lowered Flag",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "caixa de correio",
      "mailbox",
      "buzón de correo"
    ],
    "keywordsStr": "buzón caixa correio correo de mail mailbox",
    "imgUrl": "assets/emojis/1f4ed.svg"
  },
  {
    "codes": "1f4ee",
    "char": "📮",
    "name": "Postbox",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "caixa postal",
      "postbox",
      "buzón de correos"
    ],
    "keywordsStr": "buzón caixa correio correo correos de mail postal postbox",
    "imgUrl": "assets/emojis/1f4ee.svg"
  },
  {
    "codes": "1f5f3",
    "char": "🗳️",
    "name": "Ballot Box With Ballot",
    "category": "Objects (mail)",
    "group": "objects",
    "subgroup": "mail",
    "keywords": [
      "correio",
      "mail",
      "correo",
      "urna com cédula",
      "ballot box with ballot",
      "urna con papeleta"
    ],
    "keywordsStr": "ballot box com con correio correo cédula mail papeleta urna with",
    "imgUrl": "assets/emojis/1f5f3.svg"
  },
  {
    "codes": "270f",
    "char": "✏️",
    "name": "Pencil",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "lápis",
      "pencil",
      "lápiz"
    ],
    "keywordsStr": "escrever escribir lápis lápiz pencil writing",
    "imgUrl": "assets/emojis/270f.svg"
  },
  {
    "codes": "2712",
    "char": "✒️",
    "name": "Black Nib",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "ponta preta",
      "black nib",
      "punta negra"
    ],
    "keywordsStr": "black escrever escribir negra nib ponta preta punta writing",
    "imgUrl": "assets/emojis/2712.svg"
  },
  {
    "codes": "1f58b",
    "char": "🖋️",
    "name": "Fountain Pen",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "caneta tinteiro",
      "fountain pen",
      "pluma estilográfica"
    ],
    "keywordsStr": "caneta escrever escribir estilográfica fountain pen pluma tinteiro writing",
    "imgUrl": "assets/emojis/1f58b.svg"
  },
  {
    "codes": "1f58a",
    "char": "🖊️",
    "name": "Pen",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "caneta",
      "pen",
      "bolígrafo"
    ],
    "keywordsStr": "bolígrafo caneta escrever escribir pen writing",
    "imgUrl": "assets/emojis/1f58a.svg"
  },
  {
    "codes": "1f58c",
    "char": "🖌️",
    "name": "Paintbrush",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "pincel",
      "paintbrush"
    ],
    "keywordsStr": "escrever escribir paintbrush pincel writing",
    "imgUrl": "assets/emojis/1f58c.svg"
  },
  {
    "codes": "1f58d",
    "char": "🖍️",
    "name": "Crayon",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "crayon",
      "crayón"
    ],
    "keywordsStr": "crayon crayón escrever escribir writing",
    "imgUrl": "assets/emojis/1f58d.svg"
  },
  {
    "codes": "1f4dd",
    "char": "📝",
    "name": "Memo",
    "category": "Objects (writing)",
    "group": "objects",
    "subgroup": "writing",
    "keywords": [
      "escrever",
      "writing",
      "escribir",
      "memorando",
      "memo",
      "memorándum"
    ],
    "keywordsStr": "escrever escribir memo memorando memorándum writing",
    "imgUrl": "assets/emojis/1f4dd.svg"
  },
  {
    "codes": "1f4bc",
    "char": "💼",
    "name": "Briefcase",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "pasta",
      "briefcase",
      "maletín"
    ],
    "keywordsStr": "briefcase escritório maletín office oficina pasta",
    "imgUrl": "assets/emojis/1f4bc.svg"
  },
  {
    "codes": "1f4c1",
    "char": "📁",
    "name": "File Folder",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "pasta de arquivo",
      "file folder",
      "carpeta de archivos"
    ],
    "keywordsStr": "archivos arquivo carpeta de escritório file folder office oficina pasta",
    "imgUrl": "assets/emojis/1f4c1.svg"
  },
  {
    "codes": "1f4c2",
    "char": "📂",
    "name": "Open File Folder",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "abrir pasta de arquivos",
      "open file folder",
      "abrir carpeta de archivos"
    ],
    "keywordsStr": "abrir archivos arquivos carpeta de escritório file folder office oficina open pasta",
    "imgUrl": "assets/emojis/1f4c2.svg"
  },
  {
    "codes": "1f5c2",
    "char": "🗂️",
    "name": "Card Index Dividers",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "divisores de índice de cartão",
      "card index dividers",
      "separadores de índice de tarjeta"
    ],
    "keywordsStr": "card cartão de dividers divisores escritório index office oficina separadores tarjeta índice",
    "imgUrl": "assets/emojis/1f5c2.svg"
  },
  {
    "codes": "1f4c5",
    "char": "📅",
    "name": "Calendar",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "calendário",
      "calendar",
      "calendario"
    ],
    "keywordsStr": "calendar calendario calendário escritório office oficina",
    "imgUrl": "assets/emojis/1f4c5.svg"
  },
  {
    "codes": "1f4c6",
    "char": "📆",
    "name": "Tear Off Calendar",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "calendário destacável",
      "tear-off calendar",
      "calendario de corte"
    ],
    "keywordsStr": "calendar calendario calendário corte de destacável escritório office oficina tear-off",
    "imgUrl": "assets/emojis/1f4c6.svg"
  },
  {
    "codes": "1f5d2",
    "char": "🗒️",
    "name": "Spiral Notepad",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "bloco de notas espiral",
      "spiral notepad",
      "libreta espiral"
    ],
    "keywordsStr": "bloco de escritório espiral libreta notas notepad office oficina spiral",
    "imgUrl": "assets/emojis/1f5d2.svg"
  },
  {
    "codes": "1f5d3",
    "char": "🗓️",
    "name": "Spiral Calendar",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "calendário espiral",
      "spiral calendar",
      "calendario espiral"
    ],
    "keywordsStr": "calendar calendario calendário escritório espiral office oficina spiral",
    "imgUrl": "assets/emojis/1f5d3.svg"
  },
  {
    "codes": "1f4c7",
    "char": "📇",
    "name": "Card Index",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "índice de cartão",
      "card index",
      "índice de tarjeta"
    ],
    "keywordsStr": "card cartão de escritório index office oficina tarjeta índice",
    "imgUrl": "assets/emojis/1f4c7.svg"
  },
  {
    "codes": "1f4c8",
    "char": "📈",
    "name": "Chart Increasing",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "gráfico aumentando",
      "chart increasing",
      "gráfico creciente"
    ],
    "keywordsStr": "aumentando chart creciente escritório gráfico increasing office oficina",
    "imgUrl": "assets/emojis/1f4c8.svg"
  },
  {
    "codes": "1f4c9",
    "char": "📉",
    "name": "Chart Decreasing",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "gráfico decrescente",
      "chart decreasing",
      "gráfico decreciente"
    ],
    "keywordsStr": "chart decreasing decreciente decrescente escritório gráfico office oficina",
    "imgUrl": "assets/emojis/1f4c9.svg"
  },
  {
    "codes": "1f4ca",
    "char": "📊",
    "name": "Bar Chart",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "gráfico de barras",
      "bar chart"
    ],
    "keywordsStr": "bar barras chart de escritório gráfico office oficina",
    "imgUrl": "assets/emojis/1f4ca.svg"
  },
  {
    "codes": "1f4cb",
    "char": "📋",
    "name": "Clipboard",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "prancheta",
      "clipboard",
      "portapapeles"
    ],
    "keywordsStr": "clipboard escritório office oficina portapapeles prancheta",
    "imgUrl": "assets/emojis/1f4cb.svg"
  },
  {
    "codes": "1f4cc",
    "char": "📌",
    "name": "Pushpin",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "empurrão",
      "pushpin",
      "chincheta"
    ],
    "keywordsStr": "chincheta empurrão escritório office oficina pushpin",
    "imgUrl": "assets/emojis/1f4cc.svg"
  },
  {
    "codes": "1f4cd",
    "char": "📍",
    "name": "Round Pushpin",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "pino redondo",
      "round pushpin",
      "chincheta redonda"
    ],
    "keywordsStr": "chincheta escritório office oficina pino pushpin redonda redondo round",
    "imgUrl": "assets/emojis/1f4cd.svg"
  },
  {
    "codes": "1f4ce",
    "char": "📎",
    "name": "Paperclip",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "clipe de papel",
      "paperclip",
      "clip"
    ],
    "keywordsStr": "clip clipe de escritório office oficina papel paperclip",
    "imgUrl": "assets/emojis/1f4ce.svg"
  },
  {
    "codes": "1f587",
    "char": "🖇️",
    "name": "Linked Paperclips",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "clipes de papel vinculados",
      "linked paperclips",
      "clips vinculados"
    ],
    "keywordsStr": "clipes clips de escritório linked office oficina papel paperclips vinculados",
    "imgUrl": "assets/emojis/1f587.svg"
  },
  {
    "codes": "1f4cf",
    "char": "📏",
    "name": "Straight Ruler",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "régua reta",
      "straight ruler",
      "regla recta"
    ],
    "keywordsStr": "escritório office oficina recta regla reta ruler régua straight",
    "imgUrl": "assets/emojis/1f4cf.svg"
  },
  {
    "codes": "1f4d0",
    "char": "📐",
    "name": "Triangular Ruler",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "régua triangular",
      "triangular ruler",
      "regla triangular"
    ],
    "keywordsStr": "escritório office oficina regla ruler régua triangular",
    "imgUrl": "assets/emojis/1f4d0.svg"
  },
  {
    "codes": "2702",
    "char": "✂️",
    "name": "Scissors",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "tesoura",
      "scissors",
      "tijeras"
    ],
    "keywordsStr": "escritório office oficina scissors tesoura tijeras",
    "imgUrl": "assets/emojis/2702.svg"
  },
  {
    "codes": "1f5c3",
    "char": "🗃️",
    "name": "Card File Box",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "caixa de arquivo de cartão",
      "card file box",
      "caja de archivo de tarjeta"
    ],
    "keywordsStr": "archivo arquivo box caixa caja card cartão de escritório file office oficina tarjeta",
    "imgUrl": "assets/emojis/1f5c3.svg"
  },
  {
    "codes": "1f5c4",
    "char": "🗄️",
    "name": "File Cabinet",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "arquivo de arquivo",
      "file cabinet",
      "archivador"
    ],
    "keywordsStr": "archivador arquivo cabinet de escritório file office oficina",
    "imgUrl": "assets/emojis/1f5c4.svg"
  },
  {
    "codes": "1f5d1",
    "char": "🗑️",
    "name": "Wastebasket",
    "category": "Objects (office)",
    "group": "objects",
    "subgroup": "office",
    "keywords": [
      "escritório",
      "office",
      "oficina",
      "lixeira",
      "wastebasket",
      "papelera"
    ],
    "keywordsStr": "escritório lixeira office oficina papelera wastebasket",
    "imgUrl": "assets/emojis/1f5d1.svg"
  },
  {
    "codes": "1f512",
    "char": "🔒",
    "name": "Locked",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "bloqueado",
      "locked"
    ],
    "keywordsStr": "bloqueado bloqueio cerradura lock locked",
    "imgUrl": "assets/emojis/1f512.svg"
  },
  {
    "codes": "1f513",
    "char": "🔓",
    "name": "Unlocked",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "desbloqueado",
      "unlocked"
    ],
    "keywordsStr": "bloqueio cerradura desbloqueado lock unlocked",
    "imgUrl": "assets/emojis/1f513.svg"
  },
  {
    "codes": "1f50f",
    "char": "🔏",
    "name": "Locked With Pen",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "fechado com caneta",
      "locked with pen",
      "bloqueado con bolígrafo"
    ],
    "keywordsStr": "bloqueado bloqueio bolígrafo caneta cerradura com con fechado lock locked pen with",
    "imgUrl": "assets/emojis/1f50f.svg"
  },
  {
    "codes": "1f510",
    "char": "🔐",
    "name": "Locked With Key",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "fechado com chave",
      "locked with key",
      "cerrado con llave"
    ],
    "keywordsStr": "bloqueio cerrado cerradura chave com con fechado key llave lock locked with",
    "imgUrl": "assets/emojis/1f510.svg"
  },
  {
    "codes": "1f511",
    "char": "🔑",
    "name": "Key",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "chave",
      "key",
      "clave"
    ],
    "keywordsStr": "bloqueio cerradura chave clave key lock",
    "imgUrl": "assets/emojis/1f511.svg"
  },
  {
    "codes": "1f5dd",
    "char": "🗝️",
    "name": "Old Key",
    "category": "Objects (lock)",
    "group": "objects",
    "subgroup": "lock",
    "keywords": [
      "bloqueio",
      "lock",
      "cerradura",
      "chave antiga",
      "old key",
      "llave antigua"
    ],
    "keywordsStr": "antiga antigua bloqueio cerradura chave key llave lock old",
    "imgUrl": "assets/emojis/1f5dd.svg"
  },
  {
    "codes": "1f528",
    "char": "🔨",
    "name": "Hammer",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "martelo",
      "hammer",
      "martillo"
    ],
    "keywordsStr": "ferramenta hammer herramienta martelo martillo tool",
    "imgUrl": "assets/emojis/1f528.svg"
  },
  {
    "codes": "1fa93",
    "char": "🪓",
    "name": "Axe",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "machado",
      "axe",
      "hacha"
    ],
    "keywordsStr": "axe ferramenta hacha herramienta machado tool",
    "imgUrl": "assets/emojis/1fa93.svg"
  },
  {
    "codes": "26cf",
    "char": "⛏️",
    "name": "Pick",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "escolher",
      "pick",
      "elegir"
    ],
    "keywordsStr": "elegir escolher ferramenta herramienta pick tool",
    "imgUrl": "assets/emojis/26cf.svg"
  },
  {
    "codes": "2692",
    "char": "⚒️",
    "name": "Hammer And Pick",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "martelar e pegar",
      "hammer and pick",
      "martillo y pico"
    ],
    "keywordsStr": "and e ferramenta hammer herramienta martelar martillo pegar pick pico tool y",
    "imgUrl": "assets/emojis/2692.svg"
  },
  {
    "codes": "1f6e0",
    "char": "🛠️",
    "name": "Hammer And Wrench",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "martelo e chave inglesa",
      "hammer and wrench",
      "martillo y llave inglesa"
    ],
    "keywordsStr": "and chave e ferramenta hammer herramienta inglesa llave martelo martillo tool wrench y",
    "imgUrl": "assets/emojis/1f6e0.svg"
  },
  {
    "codes": "1f5e1",
    "char": "🗡️",
    "name": "Dagger",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "punhal",
      "dagger",
      "daga"
    ],
    "keywordsStr": "daga dagger ferramenta herramienta punhal tool",
    "imgUrl": "assets/emojis/1f5e1.svg"
  },
  {
    "codes": "2694",
    "char": "⚔️",
    "name": "Crossed Swords",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "espadas cruzadas",
      "crossed swords"
    ],
    "keywordsStr": "crossed cruzadas espadas ferramenta herramienta swords tool",
    "imgUrl": "assets/emojis/2694.svg"
  },
  {
    "codes": "1fa83",
    "char": "🪃",
    "name": "Boomerang",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "bumerangue",
      "boomerang",
      "bumerang"
    ],
    "keywordsStr": "boomerang bumerang bumerangue ferramenta herramienta tool",
    "imgUrl": "assets/emojis/1fa83.svg"
  },
  {
    "codes": "1f3f9",
    "char": "🏹",
    "name": "Bow And Arrow",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "arco e flecha",
      "bow and arrow",
      "arco y flecha"
    ],
    "keywordsStr": "and arco arrow bow e ferramenta flecha herramienta tool y",
    "imgUrl": "assets/emojis/1f3f9.svg"
  },
  {
    "codes": "1f6e1",
    "char": "🛡️",
    "name": "Shield",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "escudo",
      "shield"
    ],
    "keywordsStr": "escudo ferramenta herramienta shield tool",
    "imgUrl": "assets/emojis/1f6e1.svg"
  },
  {
    "codes": "1fa9a",
    "char": "🪚",
    "name": "Carpentry Saw",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "serra de carpintaria",
      "carpentry saw",
      "sierra de carpintería"
    ],
    "keywordsStr": "carpentry carpintaria carpintería de ferramenta herramienta saw serra sierra tool",
    "imgUrl": "assets/emojis/1fa9a.svg"
  },
  {
    "codes": "1f527",
    "char": "🔧",
    "name": "Wrench",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "chave",
      "wrench",
      "llave inglesa"
    ],
    "keywordsStr": "chave ferramenta herramienta inglesa llave tool wrench",
    "imgUrl": "assets/emojis/1f527.svg"
  },
  {
    "codes": "1fa9b",
    "char": "🪛",
    "name": "Screwdriver",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "chave de fenda",
      "screwdriver",
      "destornillador"
    ],
    "keywordsStr": "chave de destornillador fenda ferramenta herramienta screwdriver tool",
    "imgUrl": "assets/emojis/1fa9b.svg"
  },
  {
    "codes": "1f529",
    "char": "🔩",
    "name": "Nut And Bolt",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "porca e parafuso",
      "nut and bolt",
      "tuerca y tornillo"
    ],
    "keywordsStr": "and bolt e ferramenta herramienta nut parafuso porca tool tornillo tuerca y",
    "imgUrl": "assets/emojis/1f529.svg"
  },
  {
    "codes": "2699",
    "char": "⚙️",
    "name": "Gear",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "engrenagem",
      "gear",
      "equipo"
    ],
    "keywordsStr": "engrenagem equipo ferramenta gear herramienta tool",
    "imgUrl": "assets/emojis/2699.svg"
  },
  {
    "codes": "1f5dc",
    "char": "🗜️",
    "name": "Clamp",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "grampo",
      "clamp",
      "abrazadera"
    ],
    "keywordsStr": "abrazadera clamp ferramenta grampo herramienta tool",
    "imgUrl": "assets/emojis/1f5dc.svg"
  },
  {
    "codes": "2696",
    "char": "⚖️",
    "name": "Balance Scale",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "balança balança",
      "balance scale",
      "balanza de balanza"
    ],
    "keywordsStr": "balance balanza balança de ferramenta herramienta scale tool",
    "imgUrl": "assets/emojis/2696.svg"
  },
  {
    "codes": "1f9af",
    "char": "🦯",
    "name": "White Cane",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "cana branca",
      "white cane",
      "bastón blanco"
    ],
    "keywordsStr": "bastón blanco branca cana cane ferramenta herramienta tool white",
    "imgUrl": "assets/emojis/1f9af.svg"
  },
  {
    "codes": "1f517",
    "char": "🔗",
    "name": "Link",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "ligação",
      "link",
      "enlace"
    ],
    "keywordsStr": "enlace ferramenta herramienta ligação link tool",
    "imgUrl": "assets/emojis/1f517.svg"
  },
  {
    "codes": "26d3",
    "char": "⛓️",
    "name": "Chains",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "correntes",
      "chains",
      "cadenas"
    ],
    "keywordsStr": "cadenas chains correntes ferramenta herramienta tool",
    "imgUrl": "assets/emojis/26d3.svg"
  },
  {
    "codes": "1fa9d",
    "char": "🪝",
    "name": "Hook",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "gancho",
      "hook"
    ],
    "keywordsStr": "ferramenta gancho herramienta hook tool",
    "imgUrl": "assets/emojis/1fa9d.svg"
  },
  {
    "codes": "1f9f0",
    "char": "🧰",
    "name": "Toolbox",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "caixa de ferramentas",
      "toolbox",
      "caja de herramientas"
    ],
    "keywordsStr": "caixa caja de ferramenta ferramentas herramienta herramientas tool toolbox",
    "imgUrl": "assets/emojis/1f9f0.svg"
  },
  {
    "codes": "1f9f2",
    "char": "🧲",
    "name": "Magnet",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "ímã",
      "magnet",
      "imán"
    ],
    "keywordsStr": "ferramenta herramienta imán magnet tool ímã",
    "imgUrl": "assets/emojis/1f9f2.svg"
  },
  {
    "codes": "1fa9c",
    "char": "🪜",
    "name": "Ladder",
    "category": "Objects (tool)",
    "group": "objects",
    "subgroup": "tool",
    "keywords": [
      "ferramenta",
      "tool",
      "herramienta",
      "escada",
      "ladder",
      "escalera"
    ],
    "keywordsStr": "escada escalera ferramenta herramienta ladder tool",
    "imgUrl": "assets/emojis/1fa9c.svg"
  },
  {
    "codes": "2697",
    "char": "⚗️",
    "name": "Alembic",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "alambique",
      "alembic"
    ],
    "keywordsStr": "alambique alembic ciencia ciência science",
    "imgUrl": "assets/emojis/2697.svg"
  },
  {
    "codes": "1f9ea",
    "char": "🧪",
    "name": "Test Tube",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "tubo de ensaio",
      "test tube",
      "tubo de ensayo"
    ],
    "keywordsStr": "ciencia ciência de ensaio ensayo science test tube tubo",
    "imgUrl": "assets/emojis/1f9ea.svg"
  },
  {
    "codes": "1f9eb",
    "char": "🧫",
    "name": "Petri Dish",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "placa de petri",
      "petri dish",
      "placa de Petri"
    ],
    "keywordsStr": "Petri ciencia ciência de dish petri placa science",
    "imgUrl": "assets/emojis/1f9eb.svg"
  },
  {
    "codes": "1f9ec",
    "char": "🧬",
    "name": "Dna",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "DNA",
      "dna",
      "adn"
    ],
    "keywordsStr": "DNA adn ciencia ciência dna science",
    "imgUrl": "assets/emojis/1f9ec.svg"
  },
  {
    "codes": "1f52c",
    "char": "🔬",
    "name": "Microscope",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "microscópio",
      "microscope",
      "microscopio"
    ],
    "keywordsStr": "ciencia ciência microscope microscopio microscópio science",
    "imgUrl": "assets/emojis/1f52c.svg"
  },
  {
    "codes": "1f52d",
    "char": "🔭",
    "name": "Telescope",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "telescópio",
      "telescope",
      "telescopio"
    ],
    "keywordsStr": "ciencia ciência science telescope telescopio telescópio",
    "imgUrl": "assets/emojis/1f52d.svg"
  },
  {
    "codes": "1f4e1",
    "char": "📡",
    "name": "Satellite Antenna",
    "category": "Objects (science)",
    "group": "objects",
    "subgroup": "science",
    "keywords": [
      "ciência",
      "science",
      "ciencia",
      "antena de satélite",
      "satellite antenna",
      "antena satelital"
    ],
    "keywordsStr": "antena antenna ciencia ciência de satelital satellite satélite science",
    "imgUrl": "assets/emojis/1f4e1.svg"
  },
  {
    "codes": "1f489",
    "char": "💉",
    "name": "Syringe",
    "category": "Objects (medical)",
    "group": "objects",
    "subgroup": "medical",
    "keywords": [
      "médico",
      "medical",
      "seringa",
      "syringe",
      "jeringa"
    ],
    "keywordsStr": "jeringa medical médico seringa syringe",
    "imgUrl": "assets/emojis/1f489.svg"
  },
  {
    "codes": "1fa78",
    "char": "🩸",
    "name": "Drop Of Blood",
    "category": "Objects (medical)",
    "group": "objects",
    "subgroup": "medical",
    "keywords": [
      "médico",
      "medical",
      "gota de sangue",
      "drop of blood",
      "gota de sangre"
    ],
    "keywordsStr": "blood de drop gota medical médico of sangre sangue",
    "imgUrl": "assets/emojis/1fa78.svg"
  },
  {
    "codes": "1f48a",
    "char": "💊",
    "name": "Pill",
    "category": "Objects (medical)",
    "group": "objects",
    "subgroup": "medical",
    "keywords": [
      "médico",
      "medical",
      "pílula",
      "pill",
      "píldora"
    ],
    "keywordsStr": "medical médico pill píldora pílula",
    "imgUrl": "assets/emojis/1f48a.svg"
  },
  {
    "codes": "1fa79",
    "char": "🩹",
    "name": "Adhesive Bandage",
    "category": "Objects (medical)",
    "group": "objects",
    "subgroup": "medical",
    "keywords": [
      "médico",
      "medical",
      "bandagem adesiva",
      "adhesive bandage",
      "venda adhesiva"
    ],
    "keywordsStr": "adesiva adhesiva adhesive bandage bandagem medical médico venda",
    "imgUrl": "assets/emojis/1fa79.svg"
  },
  {
    "codes": "1fa7a",
    "char": "🩺",
    "name": "Stethoscope",
    "category": "Objects (medical)",
    "group": "objects",
    "subgroup": "medical",
    "keywords": [
      "médico",
      "medical",
      "estetoscópio",
      "stethoscope",
      "estetoscopio"
    ],
    "keywordsStr": "estetoscopio estetoscópio medical médico stethoscope",
    "imgUrl": "assets/emojis/1fa7a.svg"
  },
  {
    "codes": "1f6aa",
    "char": "🚪",
    "name": "Door",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "porta",
      "door",
      "puerta"
    ],
    "keywordsStr": "casa door hogar household porta puerta",
    "imgUrl": "assets/emojis/1f6aa.svg"
  },
  {
    "codes": "1f6d7",
    "char": "🛗",
    "name": "Elevator",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "elevador",
      "elevator",
      "ascensor"
    ],
    "keywordsStr": "ascensor casa elevador elevator hogar household",
    "imgUrl": "assets/emojis/1f6d7.svg"
  },
  {
    "codes": "1fa9e",
    "char": "🪞",
    "name": "Mirror",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "espelho",
      "mirror",
      "espejo"
    ],
    "keywordsStr": "casa espejo espelho hogar household mirror",
    "imgUrl": "assets/emojis/1fa9e.svg"
  },
  {
    "codes": "1fa9f",
    "char": "🪟",
    "name": "Window",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "janela",
      "window",
      "ventana"
    ],
    "keywordsStr": "casa hogar household janela ventana window",
    "imgUrl": "assets/emojis/1fa9f.svg"
  },
  {
    "codes": "1f6cf",
    "char": "🛏️",
    "name": "Bed",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "cama",
      "bed"
    ],
    "keywordsStr": "bed cama casa hogar household",
    "imgUrl": "assets/emojis/1f6cf.svg"
  },
  {
    "codes": "1f6cb",
    "char": "🛋️",
    "name": "Couch And Lamp",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "sofá e abajur",
      "couch and lamp",
      "sofá y lámpara"
    ],
    "keywordsStr": "abajur and casa couch e hogar household lamp lámpara sofá y",
    "imgUrl": "assets/emojis/1f6cb.svg"
  },
  {
    "codes": "1fa91",
    "char": "🪑",
    "name": "Chair",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "cadeira",
      "chair",
      "silla"
    ],
    "keywordsStr": "cadeira casa chair hogar household silla",
    "imgUrl": "assets/emojis/1fa91.svg"
  },
  {
    "codes": "1f6bd",
    "char": "🚽",
    "name": "Toilet",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "banheiro",
      "toilet",
      "inodoro"
    ],
    "keywordsStr": "banheiro casa hogar household inodoro toilet",
    "imgUrl": "assets/emojis/1f6bd.svg"
  },
  {
    "codes": "1faa0",
    "char": "🪠",
    "name": "Plunger",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "êmbolo",
      "plunger",
      "émbolo"
    ],
    "keywordsStr": "casa hogar household plunger émbolo êmbolo",
    "imgUrl": "assets/emojis/1faa0.svg"
  },
  {
    "codes": "1f6bf",
    "char": "🚿",
    "name": "Shower",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "chuveiro",
      "shower",
      "ducha"
    ],
    "keywordsStr": "casa chuveiro ducha hogar household shower",
    "imgUrl": "assets/emojis/1f6bf.svg"
  },
  {
    "codes": "1f6c1",
    "char": "🛁",
    "name": "Bathtub",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "banheira",
      "bathtub",
      "bañera"
    ],
    "keywordsStr": "banheira bathtub bañera casa hogar household",
    "imgUrl": "assets/emojis/1f6c1.svg"
  },
  {
    "codes": "1faa4",
    "char": "🪤",
    "name": "Mouse Trap",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "armadilha do rato",
      "mouse trap",
      "trampa para ratones"
    ],
    "keywordsStr": "armadilha casa do hogar household mouse para rato ratones trampa trap",
    "imgUrl": "assets/emojis/1faa4.svg"
  },
  {
    "codes": "1fa92",
    "char": "🪒",
    "name": "Razor",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "navalha",
      "razor",
      "maquinilla de afeitar"
    ],
    "keywordsStr": "afeitar casa de hogar household maquinilla navalha razor",
    "imgUrl": "assets/emojis/1fa92.svg"
  },
  {
    "codes": "1f9f4",
    "char": "🧴",
    "name": "Lotion Bottle",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "frasco de loção",
      "lotion bottle",
      "botella de loción"
    ],
    "keywordsStr": "botella bottle casa de frasco hogar household loción lotion loção",
    "imgUrl": "assets/emojis/1f9f4.svg"
  },
  {
    "codes": "1f9f7",
    "char": "🧷",
    "name": "Safety Pin",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "pino de segurança",
      "safety pin",
      "imperdible"
    ],
    "keywordsStr": "casa de hogar household imperdible pin pino safety segurança",
    "imgUrl": "assets/emojis/1f9f7.svg"
  },
  {
    "codes": "1f9f9",
    "char": "🧹",
    "name": "Broom",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "vassoura",
      "broom",
      "escoba"
    ],
    "keywordsStr": "broom casa escoba hogar household vassoura",
    "imgUrl": "assets/emojis/1f9f9.svg"
  },
  {
    "codes": "1f9fa",
    "char": "🧺",
    "name": "Basket",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "cesta",
      "basket",
      "canasta"
    ],
    "keywordsStr": "basket canasta casa cesta hogar household",
    "imgUrl": "assets/emojis/1f9fa.svg"
  },
  {
    "codes": "1f9fb",
    "char": "🧻",
    "name": "Roll Of Paper",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "rolo de papel",
      "roll of paper",
      "rollo de papel"
    ],
    "keywordsStr": "casa de hogar household of papel paper roll rollo rolo",
    "imgUrl": "assets/emojis/1f9fb.svg"
  },
  {
    "codes": "1faa3",
    "char": "🪣",
    "name": "Bucket",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "balde",
      "bucket",
      "cubo"
    ],
    "keywordsStr": "balde bucket casa cubo hogar household",
    "imgUrl": "assets/emojis/1faa3.svg"
  },
  {
    "codes": "1f9fc",
    "char": "🧼",
    "name": "Soap",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "sabão",
      "soap",
      "jabón"
    ],
    "keywordsStr": "casa hogar household jabón sabão soap",
    "imgUrl": "assets/emojis/1f9fc.svg"
  },
  {
    "codes": "1faa5",
    "char": "🪥",
    "name": "Toothbrush",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "escova de dentes",
      "toothbrush",
      "cepillo de dientes"
    ],
    "keywordsStr": "casa cepillo de dentes dientes escova hogar household toothbrush",
    "imgUrl": "assets/emojis/1faa5.svg"
  },
  {
    "codes": "1f9fd",
    "char": "🧽",
    "name": "Sponge",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "esponja",
      "sponge"
    ],
    "keywordsStr": "casa esponja hogar household sponge",
    "imgUrl": "assets/emojis/1f9fd.svg"
  },
  {
    "codes": "1f9ef",
    "char": "🧯",
    "name": "Fire Extinguisher",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "extintor de incêndio",
      "fire extinguisher",
      "extintor de incendios"
    ],
    "keywordsStr": "casa de extinguisher extintor fire hogar household incendios incêndio",
    "imgUrl": "assets/emojis/1f9ef.svg"
  },
  {
    "codes": "1f6d2",
    "char": "🛒",
    "name": "Shopping Cart",
    "category": "Objects (household)",
    "group": "objects",
    "subgroup": "household",
    "keywords": [
      "casa",
      "household",
      "hogar",
      "carrinho de compras",
      "shopping cart",
      "carrito de compras"
    ],
    "keywordsStr": "carrinho carrito cart casa compras de hogar household shopping",
    "imgUrl": "assets/emojis/1f6d2.svg"
  },
  {
    "codes": "1f6ac",
    "char": "🚬",
    "name": "Cigarette",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "cigarro",
      "cigarette",
      "cigarrillo"
    ],
    "keywordsStr": "cigarette cigarrillo cigarro object objeto other otro outro",
    "imgUrl": "assets/emojis/1f6ac.svg"
  },
  {
    "codes": "26b0",
    "char": "⚰️",
    "name": "Coffin",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "caixão",
      "coffin",
      "ataúd"
    ],
    "keywordsStr": "ataúd caixão coffin object objeto other otro outro",
    "imgUrl": "assets/emojis/26b0.svg"
  },
  {
    "codes": "1faa6",
    "char": "🪦",
    "name": "Headstone",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "lápide",
      "headstone",
      "lápida mortuoria"
    ],
    "keywordsStr": "headstone lápida lápide mortuoria object objeto other otro outro",
    "imgUrl": "assets/emojis/1faa6.svg"
  },
  {
    "codes": "26b1",
    "char": "⚱️",
    "name": "Funeral Urn",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "urna funerária",
      "funeral urn",
      "urna funeraria"
    ],
    "keywordsStr": "funeral funeraria funerária object objeto other otro outro urn urna",
    "imgUrl": "assets/emojis/26b1.svg"
  },
  {
    "codes": "1f5ff",
    "char": "🗿",
    "name": "Moai",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "moai",
      "moái"
    ],
    "keywordsStr": "moai moái object objeto other otro outro",
    "imgUrl": "assets/emojis/1f5ff.svg"
  },
  {
    "codes": "1faa7",
    "char": "🪧",
    "name": "Placard",
    "category": "Objects (other-object)",
    "group": "objects",
    "subgroup": "other-object",
    "keywords": [
      "outro",
      "other",
      "otro",
      "objeto",
      "object",
      "outro objeto",
      "other object",
      "otro objeto",
      "placa",
      "placard",
      "cartel"
    ],
    "keywordsStr": "cartel object objeto other otro outro placa placard",
    "imgUrl": "assets/emojis/1faa7.svg"
  },
  {
    "codes": "1f3e7",
    "char": "🏧",
    "name": "Atm Sign",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "Sinal ATM",
      "ATM sign",
      "Señal de cajero automático"
    ],
    "keywordsStr": "ATM Señal Sinal automático cajero de firmar señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f3e7.svg"
  },
  {
    "codes": "1f6ae",
    "char": "🚮",
    "name": "Litter In Bin Sign",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "sinal de lixo na lixeira",
      "litter in bin sign",
      "basura en el letrero de la papelera"
    ],
    "keywordsStr": "basura bin de el en firmar in la letrero litter lixeira lixo na papelera señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6ae.svg"
  },
  {
    "codes": "1f6b0",
    "char": "🚰",
    "name": "Potable Water",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "água potável",
      "potable water",
      "agua potable"
    ],
    "keywordsStr": "agua de firmar potable potável señal sign sinal transport transporte water água",
    "imgUrl": "assets/emojis/1f6b0.svg"
  },
  {
    "codes": "267f",
    "char": "♿",
    "name": "Wheelchair Symbol",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "símbolo da cadeira de rodas",
      "wheelchair symbol",
      "símbolo de silla de ruedas"
    ],
    "keywordsStr": "cadeira da de firmar rodas ruedas señal sign silla sinal symbol símbolo transport transporte wheelchair",
    "imgUrl": "assets/emojis/267f.svg"
  },
  {
    "codes": "1f6b9",
    "char": "🚹",
    "name": "Mens Room",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "quarto masculino",
      "mens room",
      "baño de hombres"
    ],
    "keywordsStr": "baño de firmar hombres masculino mens quarto room señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6b9.svg"
  },
  {
    "codes": "1f6ba",
    "char": "🚺",
    "name": "Womens Room",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "quarto feminino",
      "womens room",
      "baño de mujeres"
    ],
    "keywordsStr": "baño de feminino firmar mujeres quarto room señal sign sinal transport transporte womens",
    "imgUrl": "assets/emojis/1f6ba.svg"
  },
  {
    "codes": "1f6bb",
    "char": "🚻",
    "name": "Restroom",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "banheiro",
      "restroom",
      "baño"
    ],
    "keywordsStr": "banheiro baño de firmar restroom señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6bb.svg"
  },
  {
    "codes": "1f6bc",
    "char": "🚼",
    "name": "Baby Symbol",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "símbolo do bebê",
      "baby symbol",
      "símbolo de bebé"
    ],
    "keywordsStr": "baby bebé bebê de do firmar señal sign sinal symbol símbolo transport transporte",
    "imgUrl": "assets/emojis/1f6bc.svg"
  },
  {
    "codes": "1f6be",
    "char": "🚾",
    "name": "Water Closet",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "armário de água",
      "water closet",
      "armario de agua"
    ],
    "keywordsStr": "agua armario armário closet de firmar señal sign sinal transport transporte water água",
    "imgUrl": "assets/emojis/1f6be.svg"
  },
  {
    "codes": "1f6c2",
    "char": "🛂",
    "name": "Passport Control",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "controle de passaporte",
      "passport control",
      "control de pasaporte"
    ],
    "keywordsStr": "control controle de firmar pasaporte passaporte passport señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6c2.svg"
  },
  {
    "codes": "1f6c3",
    "char": "🛃",
    "name": "Customs",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "costumes",
      "customs",
      "costumbres"
    ],
    "keywordsStr": "costumbres costumes customs de firmar señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6c3.svg"
  },
  {
    "codes": "1f6c4",
    "char": "🛄",
    "name": "Baggage Claim",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "reivindicação de bagagem",
      "baggage claim",
      "reclamo de equipaje"
    ],
    "keywordsStr": "bagagem baggage claim de equipaje firmar reclamo reivindicação señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6c4.svg"
  },
  {
    "codes": "1f6c5",
    "char": "🛅",
    "name": "Left Luggage",
    "category": "Symbols (transport-sign)",
    "group": "symbols",
    "subgroup": "transport-sign",
    "keywords": [
      "transporte",
      "transport",
      "sinal",
      "sign",
      "firmar",
      "sinal de transporte",
      "transport sign",
      "señal de transporte",
      "bagagem deixada",
      "left luggage",
      "dejar equipaje"
    ],
    "keywordsStr": "bagagem de deixada dejar equipaje firmar left luggage señal sign sinal transport transporte",
    "imgUrl": "assets/emojis/1f6c5.svg"
  },
  {
    "codes": "26a0",
    "char": "⚠️",
    "name": "Warning",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia"
    ],
    "keywordsStr": "advertencia aviso warning",
    "imgUrl": "assets/emojis/26a0.svg"
  },
  {
    "codes": "1f6b8",
    "char": "🚸",
    "name": "Children Crossing",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "crianças atravessando",
      "children crossing",
      "niños cruzando"
    ],
    "keywordsStr": "advertencia atravessando aviso children crianças crossing cruzando niños warning",
    "imgUrl": "assets/emojis/1f6b8.svg"
  },
  {
    "codes": "26d4",
    "char": "⛔",
    "name": "No Entry",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "sem entrada",
      "no entry",
      "sin entrada"
    ],
    "keywordsStr": "advertencia aviso entrada entry no sem sin warning",
    "imgUrl": "assets/emojis/26d4.svg"
  },
  {
    "codes": "1f6ab",
    "char": "🚫",
    "name": "Prohibited",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "proibido",
      "prohibited",
      "prohibido"
    ],
    "keywordsStr": "advertencia aviso prohibido prohibited proibido warning",
    "imgUrl": "assets/emojis/1f6ab.svg"
  },
  {
    "codes": "1f6b3",
    "char": "🚳",
    "name": "No Bicycles",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "sem bicicletas",
      "no bicycles",
      "sin bicicletas"
    ],
    "keywordsStr": "advertencia aviso bicicletas bicycles no sem sin warning",
    "imgUrl": "assets/emojis/1f6b3.svg"
  },
  {
    "codes": "1f6ad",
    "char": "🚭",
    "name": "No Smoking",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "não fumar",
      "no smoking",
      "no fumar"
    ],
    "keywordsStr": "advertencia aviso fumar no não smoking warning",
    "imgUrl": "assets/emojis/1f6ad.svg"
  },
  {
    "codes": "1f6af",
    "char": "🚯",
    "name": "No Littering",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "sem lixo",
      "no littering",
      "no tirar basura"
    ],
    "keywordsStr": "advertencia aviso basura littering lixo no sem tirar warning",
    "imgUrl": "assets/emojis/1f6af.svg"
  },
  {
    "codes": "1f6b1",
    "char": "🚱",
    "name": "Non Potable Water",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "água não potável",
      "non-potable water",
      "agua no potable"
    ],
    "keywordsStr": "advertencia agua aviso no non-potable não potable potável warning water água",
    "imgUrl": "assets/emojis/1f6b1.svg"
  },
  {
    "codes": "1f6b7",
    "char": "🚷",
    "name": "No Pedestrians",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "sem pedestres",
      "no pedestrians",
      "sin peatones"
    ],
    "keywordsStr": "advertencia aviso no peatones pedestres pedestrians sem sin warning",
    "imgUrl": "assets/emojis/1f6b7.svg"
  },
  {
    "codes": "1f4f5",
    "char": "📵",
    "name": "No Mobile Phones",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "sem telefones celulares",
      "no mobile phones",
      "sin teléfonos móviles"
    ],
    "keywordsStr": "advertencia aviso celulares mobile móviles no phones sem sin telefones teléfonos warning",
    "imgUrl": "assets/emojis/1f4f5.svg"
  },
  {
    "codes": "1f51e",
    "char": "🔞",
    "name": "No One Under Eighteen",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "ninguém com menos de dezoito anos",
      "no one under eighteen",
      "nadie menor de dieciocho años"
    ],
    "keywordsStr": "advertencia anos aviso años com de dezoito dieciocho eighteen menor menos nadie ninguém no one under warning",
    "imgUrl": "assets/emojis/1f51e.svg"
  },
  {
    "codes": "2622",
    "char": "☢️",
    "name": "Radioactive",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "radioativo",
      "radioactive",
      "radiactivo"
    ],
    "keywordsStr": "advertencia aviso radiactivo radioactive radioativo warning",
    "imgUrl": "assets/emojis/2622.svg"
  },
  {
    "codes": "2623",
    "char": "☣️",
    "name": "Biohazard",
    "category": "Symbols (warning)",
    "group": "symbols",
    "subgroup": "warning",
    "keywords": [
      "aviso",
      "warning",
      "advertencia",
      "risco biológico",
      "biohazard",
      "riesgo biológico"
    ],
    "keywordsStr": "advertencia aviso biohazard biológico riesgo risco warning",
    "imgUrl": "assets/emojis/2623.svg"
  },
  {
    "codes": "2b06",
    "char": "⬆️",
    "name": "Up Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para cima",
      "up arrow",
      "flecha arriba"
    ],
    "keywordsStr": "arriba arrow cima flecha para seta up",
    "imgUrl": "assets/emojis/2b06.svg"
  },
  {
    "codes": "2197",
    "char": "↗️",
    "name": "Up Right Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para cima-direita",
      "up-right arrow",
      "flecha arriba a la derecha"
    ],
    "keywordsStr": "a arriba arrow cima-direita derecha flecha la para seta up-right",
    "imgUrl": "assets/emojis/2197.svg"
  },
  {
    "codes": "27a1",
    "char": "➡️",
    "name": "Right Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para a direita",
      "right arrow",
      "flecha derecha"
    ],
    "keywordsStr": "a arrow derecha direita flecha para right seta",
    "imgUrl": "assets/emojis/27a1.svg"
  },
  {
    "codes": "2198",
    "char": "↘️",
    "name": "Down Right Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para baixo-direita",
      "down-right arrow",
      "flecha abajo-derecha"
    ],
    "keywordsStr": "abajo-derecha arrow baixo-direita down-right flecha para seta",
    "imgUrl": "assets/emojis/2198.svg"
  },
  {
    "codes": "2b07",
    "char": "⬇️",
    "name": "Down Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para baixo",
      "down arrow",
      "flecha hacia abajo"
    ],
    "keywordsStr": "abajo arrow baixo down flecha hacia para seta",
    "imgUrl": "assets/emojis/2b07.svg"
  },
  {
    "codes": "2199",
    "char": "↙️",
    "name": "Down Left Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para baixo-esquerda",
      "down-left arrow",
      "flecha abajo izquierda"
    ],
    "keywordsStr": "abajo arrow baixo-esquerda down-left flecha izquierda para seta",
    "imgUrl": "assets/emojis/2199.svg"
  },
  {
    "codes": "2b05",
    "char": "⬅️",
    "name": "Left Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para a esquerda",
      "left arrow",
      "flecha izquierda"
    ],
    "keywordsStr": "a arrow esquerda flecha izquierda left para seta",
    "imgUrl": "assets/emojis/2b05.svg"
  },
  {
    "codes": "2196",
    "char": "↖️",
    "name": "Up Left Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para cima-esquerda",
      "up-left arrow",
      "flecha arriba izquierda"
    ],
    "keywordsStr": "arriba arrow cima-esquerda flecha izquierda para seta up-left",
    "imgUrl": "assets/emojis/2196.svg"
  },
  {
    "codes": "2195",
    "char": "↕️",
    "name": "Up Down Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para cima e para baixo",
      "up-down arrow",
      "flecha arriba-abajo"
    ],
    "keywordsStr": "arriba-abajo arrow baixo cima e flecha para seta up-down",
    "imgUrl": "assets/emojis/2195.svg"
  },
  {
    "codes": "2194",
    "char": "↔️",
    "name": "Left Right Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta esquerda-direita",
      "left-right arrow",
      "flecha izquierda-derecha"
    ],
    "keywordsStr": "arrow esquerda-direita flecha izquierda-derecha left-right seta",
    "imgUrl": "assets/emojis/2194.svg"
  },
  {
    "codes": "21a9",
    "char": "↩️",
    "name": "Right Arrow Curving Left",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta direita curvando para a esquerda",
      "right arrow curving left",
      "flecha derecha que se curva a la izquierda"
    ],
    "keywordsStr": "a arrow curva curvando curving derecha direita esquerda flecha izquierda la left para que right se seta",
    "imgUrl": "assets/emojis/21a9.svg"
  },
  {
    "codes": "21aa",
    "char": "↪️",
    "name": "Left Arrow Curving Right",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para a esquerda curvando para a direita",
      "left arrow curving right",
      "flecha izquierda que se curva a la derecha"
    ],
    "keywordsStr": "a arrow curva curvando curving derecha direita esquerda flecha izquierda la left para que right se seta",
    "imgUrl": "assets/emojis/21aa.svg"
  },
  {
    "codes": "2934",
    "char": "⤴️",
    "name": "Right Arrow Curving Up",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para a direita curvando para cima",
      "right arrow curving up",
      "flecha derecha curvándose hacia arriba"
    ],
    "keywordsStr": "a arriba arrow cima curvando curving curvándose derecha direita flecha hacia para right seta up",
    "imgUrl": "assets/emojis/2934.svg"
  },
  {
    "codes": "2935",
    "char": "⤵️",
    "name": "Right Arrow Curving Down",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para a direita curvando para baixo",
      "right arrow curving down",
      "flecha derecha curvándose hacia abajo"
    ],
    "keywordsStr": "a abajo arrow baixo curvando curving curvándose derecha direita down flecha hacia para right seta",
    "imgUrl": "assets/emojis/2935.svg"
  },
  {
    "codes": "1f503",
    "char": "🔃",
    "name": "Clockwise Vertical Arrows",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "setas verticais no sentido horário",
      "clockwise vertical arrows",
      "flechas verticales en el sentido de las agujas del reloj"
    ],
    "keywordsStr": "agujas arrow arrows clockwise de del el en flecha flechas horário las no reloj sentido seta setas verticais vertical verticales",
    "imgUrl": "assets/emojis/1f503.svg"
  },
  {
    "codes": "1f504",
    "char": "🔄",
    "name": "Counterclockwise Arrows Button",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "botão de setas no sentido anti-horário",
      "counterclockwise arrows button",
      "botón de flechas en sentido contrario a las agujas del reloj"
    ],
    "keywordsStr": "a agujas anti-horário arrow arrows botão botón button contrario counterclockwise de del en flecha flechas las no reloj sentido seta setas",
    "imgUrl": "assets/emojis/1f504.svg"
  },
  {
    "codes": "1f519",
    "char": "🔙",
    "name": "Back Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta para trás",
      "BACK arrow",
      "Flecha ATRÁS"
    ],
    "keywordsStr": "ATRÁS BACK Flecha arrow flecha para seta trás",
    "imgUrl": "assets/emojis/1f519.svg"
  },
  {
    "codes": "1f51a",
    "char": "🔚",
    "name": "End Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta FIM",
      "END arrow",
      "Flecha FIN"
    ],
    "keywordsStr": "END FIM FIN Flecha arrow flecha seta",
    "imgUrl": "assets/emojis/1f51a.svg"
  },
  {
    "codes": "1f51b",
    "char": "🔛",
    "name": "On Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "ON seta",
      "ON arrow",
      "ON flecha"
    ],
    "keywordsStr": "ON arrow flecha seta",
    "imgUrl": "assets/emojis/1f51b.svg"
  },
  {
    "codes": "1f51c",
    "char": "🔜",
    "name": "Soon Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "SETA EM BREVE",
      "SOON arrow",
      "PRONTO flecha"
    ],
    "keywordsStr": "BREVE EM PRONTO SETA SOON arrow flecha seta",
    "imgUrl": "assets/emojis/1f51c.svg"
  },
  {
    "codes": "1f51d",
    "char": "🔝",
    "name": "Top Arrow",
    "category": "Symbols (arrow)",
    "group": "symbols",
    "subgroup": "arrow",
    "keywords": [
      "seta",
      "arrow",
      "flecha",
      "seta PARA CIMA",
      "TOP arrow",
      "Flecha SUPERIOR"
    ],
    "keywordsStr": "CIMA Flecha PARA SUPERIOR TOP arrow flecha seta",
    "imgUrl": "assets/emojis/1f51d.svg"
  },
  {
    "codes": "1f6d0",
    "char": "🛐",
    "name": "Place Of Worship",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "lugar de culto",
      "place of worship"
    ],
    "keywordsStr": "culto de lugar of place religion religião religión worship",
    "imgUrl": "assets/emojis/1f6d0.svg"
  },
  {
    "codes": "269b",
    "char": "⚛️",
    "name": "Atom Symbol",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "símbolo do átomo",
      "atom symbol",
      "símbolo del átomo"
    ],
    "keywordsStr": "atom del do religion religião religión symbol símbolo átomo",
    "imgUrl": "assets/emojis/269b.svg"
  },
  {
    "codes": "1f549",
    "char": "🕉️",
    "name": "Om",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "om"
    ],
    "keywordsStr": "om religion religião religión",
    "imgUrl": "assets/emojis/1f549.svg"
  },
  {
    "codes": "2721",
    "char": "✡️",
    "name": "Star Of David",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "estrela de Davi",
      "star of David",
      "estrella de David"
    ],
    "keywordsStr": "Davi David de estrela estrella of religion religião religión star",
    "imgUrl": "assets/emojis/2721.svg"
  },
  {
    "codes": "2638",
    "char": "☸️",
    "name": "Wheel Of Dharma",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "roda do dharma",
      "wheel of dharma",
      "rueda del dharma"
    ],
    "keywordsStr": "del dharma do of religion religião religión roda rueda wheel",
    "imgUrl": "assets/emojis/2638.svg"
  },
  {
    "codes": "262f",
    "char": "☯️",
    "name": "Yin Yang",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "yin yang",
      "yin-yang"
    ],
    "keywordsStr": "religion religião religión yang yin yin-yang",
    "imgUrl": "assets/emojis/262f.svg"
  },
  {
    "codes": "271d",
    "char": "✝️",
    "name": "Latin Cross",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "cruz latina",
      "latin cross"
    ],
    "keywordsStr": "cross cruz latin latina religion religião religión",
    "imgUrl": "assets/emojis/271d.svg"
  },
  {
    "codes": "2626",
    "char": "☦️",
    "name": "Orthodox Cross",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "cruz ortodoxa",
      "orthodox cross"
    ],
    "keywordsStr": "cross cruz orthodox ortodoxa religion religião religión",
    "imgUrl": "assets/emojis/2626.svg"
  },
  {
    "codes": "262a",
    "char": "☪️",
    "name": "Star And Crescent",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "estrela e crescente",
      "star and crescent",
      "estrella y media luna"
    ],
    "keywordsStr": "and crescent crescente e estrela estrella luna media religion religião religión star y",
    "imgUrl": "assets/emojis/262a.svg"
  },
  {
    "codes": "262e",
    "char": "☮️",
    "name": "Peace Symbol",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "símbolo da paz",
      "peace symbol",
      "símbolo de paz"
    ],
    "keywordsStr": "da de paz peace religion religião religión symbol símbolo",
    "imgUrl": "assets/emojis/262e.svg"
  },
  {
    "codes": "1f54e",
    "char": "🕎",
    "name": "Menorah",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "menorá",
      "menorah"
    ],
    "keywordsStr": "menorah menorá religion religião religión",
    "imgUrl": "assets/emojis/1f54e.svg"
  },
  {
    "codes": "1f52f",
    "char": "🔯",
    "name": "Dotted Six Pointed Star",
    "category": "Symbols (religion)",
    "group": "symbols",
    "subgroup": "religion",
    "keywords": [
      "religião",
      "religion",
      "religión",
      "estrela de seis pontas pontilhada",
      "dotted six-pointed star",
      "estrella punteada de seis puntas"
    ],
    "keywordsStr": "de dotted estrela estrella pontas pontilhada puntas punteada religion religião religión seis six-pointed star",
    "imgUrl": "assets/emojis/1f52f.svg"
  },
  {
    "codes": "2648",
    "char": "♈",
    "name": "Aries",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Áries",
      "Aries"
    ],
    "keywordsStr": "Aries zodiac zodíaco Áries",
    "imgUrl": "assets/emojis/2648.svg"
  },
  {
    "codes": "2649",
    "char": "♉",
    "name": "Taurus",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Touro",
      "Taurus",
      "Tauro"
    ],
    "keywordsStr": "Tauro Taurus Touro zodiac zodíaco",
    "imgUrl": "assets/emojis/2649.svg"
  },
  {
    "codes": "264a",
    "char": "♊",
    "name": "Gemini",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Gêmeos",
      "Gemini",
      "Géminis"
    ],
    "keywordsStr": "Gemini Géminis Gêmeos zodiac zodíaco",
    "imgUrl": "assets/emojis/264a.svg"
  },
  {
    "codes": "264b",
    "char": "♋",
    "name": "Cancer",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Câncer",
      "Cancer",
      "Cáncer"
    ],
    "keywordsStr": "Cancer Cáncer Câncer zodiac zodíaco",
    "imgUrl": "assets/emojis/264b.svg"
  },
  {
    "codes": "264c",
    "char": "♌",
    "name": "Leo",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Léo",
      "Leo"
    ],
    "keywordsStr": "Leo Léo zodiac zodíaco",
    "imgUrl": "assets/emojis/264c.svg"
  },
  {
    "codes": "264d",
    "char": "♍",
    "name": "Virgo",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Virgem",
      "Virgo"
    ],
    "keywordsStr": "Virgem Virgo zodiac zodíaco",
    "imgUrl": "assets/emojis/264d.svg"
  },
  {
    "codes": "264e",
    "char": "♎",
    "name": "Libra",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Libra"
    ],
    "keywordsStr": "Libra zodiac zodíaco",
    "imgUrl": "assets/emojis/264e.svg"
  },
  {
    "codes": "264f",
    "char": "♏",
    "name": "Scorpio",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Escorpião",
      "Scorpio",
      "Escorpio"
    ],
    "keywordsStr": "Escorpio Escorpião Scorpio zodiac zodíaco",
    "imgUrl": "assets/emojis/264f.svg"
  },
  {
    "codes": "2650",
    "char": "♐",
    "name": "Sagittarius",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Sagitário",
      "Sagittarius",
      "Sagitario"
    ],
    "keywordsStr": "Sagitario Sagittarius Sagitário zodiac zodíaco",
    "imgUrl": "assets/emojis/2650.svg"
  },
  {
    "codes": "2651",
    "char": "♑",
    "name": "Capricorn",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Capricórnio",
      "Capricorn",
      "Capricornio"
    ],
    "keywordsStr": "Capricorn Capricornio Capricórnio zodiac zodíaco",
    "imgUrl": "assets/emojis/2651.svg"
  },
  {
    "codes": "2652",
    "char": "♒",
    "name": "Aquarius",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Aquário",
      "Aquarius",
      "Acuario"
    ],
    "keywordsStr": "Acuario Aquarius Aquário zodiac zodíaco",
    "imgUrl": "assets/emojis/2652.svg"
  },
  {
    "codes": "2653",
    "char": "♓",
    "name": "Pisces",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Peixes",
      "Pisces",
      "Piscis"
    ],
    "keywordsStr": "Peixes Pisces Piscis zodiac zodíaco",
    "imgUrl": "assets/emojis/2653.svg"
  },
  {
    "codes": "26ce",
    "char": "⛎",
    "name": "Ophiuchus",
    "category": "Symbols (zodiac)",
    "group": "symbols",
    "subgroup": "zodiac",
    "keywords": [
      "zodíaco",
      "zodiac",
      "Ophiuchus",
      "Ofiuco"
    ],
    "keywordsStr": "Ofiuco Ophiuchus zodiac zodíaco",
    "imgUrl": "assets/emojis/26ce.svg"
  },
  {
    "codes": "1f500",
    "char": "🔀",
    "name": "Shuffle Tracks Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de reprodução aleatória de faixas",
      "shuffle tracks button",
      "botón de reproducción aleatoria de pistas"
    ],
    "keywordsStr": "aleatoria aleatória av botão botón button de faixas pistas reproducción reprodução shuffle symbol símbolo tracks",
    "imgUrl": "assets/emojis/1f500.svg"
  },
  {
    "codes": "1f501",
    "char": "🔁",
    "name": "Repeat Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de repetição",
      "repeat button",
      "botón de repetición"
    ],
    "keywordsStr": "av botão botón button de repeat repetición repetição symbol símbolo",
    "imgUrl": "assets/emojis/1f501.svg"
  },
  {
    "codes": "1f502",
    "char": "🔂",
    "name": "Repeat Single Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "repetir botão único",
      "repeat single button",
      "repetir un solo botón"
    ],
    "keywordsStr": "av botão botón button repeat repetir single solo symbol símbolo un único",
    "imgUrl": "assets/emojis/1f502.svg"
  },
  {
    "codes": "25b6",
    "char": "▶️",
    "name": "Play Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão play",
      "play button",
      "botón de reproducción"
    ],
    "keywordsStr": "av botão botón button de play reproducción symbol símbolo",
    "imgUrl": "assets/emojis/25b6.svg"
  },
  {
    "codes": "23e9",
    "char": "⏩",
    "name": "Fast Forward Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de avanço rápido",
      "fast-forward button",
      "botón de avance rápido"
    ],
    "keywordsStr": "av avance avanço botão botón button de fast-forward rápido symbol símbolo",
    "imgUrl": "assets/emojis/23e9.svg"
  },
  {
    "codes": "23ed",
    "char": "⏭️",
    "name": "Next Track Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão da próxima faixa",
      "next track button",
      "botón de siguiente pista"
    ],
    "keywordsStr": "av botão botón button da de faixa next pista próxima siguiente symbol símbolo track",
    "imgUrl": "assets/emojis/23ed.svg"
  },
  {
    "codes": "23ef",
    "char": "⏯️",
    "name": "Play Or Pause Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão play ou pause",
      "play or pause button",
      "botón de reproducción o pausa"
    ],
    "keywordsStr": "av botão botón button de o or ou pausa pause play reproducción symbol símbolo",
    "imgUrl": "assets/emojis/23ef.svg"
  },
  {
    "codes": "25c0",
    "char": "◀️",
    "name": "Reverse Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão reverso",
      "reverse button",
      "botón de marcha atrás"
    ],
    "keywordsStr": "atrás av botão botón button de marcha reverse reverso symbol símbolo",
    "imgUrl": "assets/emojis/25c0.svg"
  },
  {
    "codes": "23ea",
    "char": "⏪",
    "name": "Fast Reverse Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de retrocesso rápido",
      "fast reverse button",
      "botón de retroceso rápido"
    ],
    "keywordsStr": "av botão botón button de fast retroceso retrocesso reverse rápido symbol símbolo",
    "imgUrl": "assets/emojis/23ea.svg"
  },
  {
    "codes": "23ee",
    "char": "⏮️",
    "name": "Last Track Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão da última faixa",
      "last track button",
      "botón de última pista"
    ],
    "keywordsStr": "av botão botón button da de faixa last pista symbol símbolo track última",
    "imgUrl": "assets/emojis/23ee.svg"
  },
  {
    "codes": "1f53c",
    "char": "🔼",
    "name": "Upwards Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão para cima",
      "upwards button",
      "botón hacia arriba"
    ],
    "keywordsStr": "arriba av botão botón button cima hacia para symbol símbolo upwards",
    "imgUrl": "assets/emojis/1f53c.svg"
  },
  {
    "codes": "23eb",
    "char": "⏫",
    "name": "Fast Up Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão rápido",
      "fast up button",
      "botón de subida rápida"
    ],
    "keywordsStr": "av botão botón button de fast rápida rápido subida symbol símbolo up",
    "imgUrl": "assets/emojis/23eb.svg"
  },
  {
    "codes": "1f53d",
    "char": "🔽",
    "name": "Downwards Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão para baixo",
      "downwards button",
      "botón hacia abajo"
    ],
    "keywordsStr": "abajo av baixo botão botón button downwards hacia para symbol símbolo",
    "imgUrl": "assets/emojis/1f53d.svg"
  },
  {
    "codes": "23ec",
    "char": "⏬",
    "name": "Fast Down Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de descida rápida",
      "fast down button",
      "botón de descenso rápido"
    ],
    "keywordsStr": "av botão botón button de descenso descida down fast rápida rápido symbol símbolo",
    "imgUrl": "assets/emojis/23ec.svg"
  },
  {
    "codes": "23f8",
    "char": "⏸️",
    "name": "Pause Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de pausa",
      "pause button",
      "botón de pausa"
    ],
    "keywordsStr": "av botão botón button de pausa pause symbol símbolo",
    "imgUrl": "assets/emojis/23f8.svg"
  },
  {
    "codes": "23f9",
    "char": "⏹️",
    "name": "Stop Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão de parada",
      "stop button",
      "botón de parada"
    ],
    "keywordsStr": "av botão botón button de parada stop symbol símbolo",
    "imgUrl": "assets/emojis/23f9.svg"
  },
  {
    "codes": "23fa",
    "char": "⏺️",
    "name": "Record Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão gravar",
      "record button",
      "botón de grabación"
    ],
    "keywordsStr": "av botão botón button de grabación gravar record symbol símbolo",
    "imgUrl": "assets/emojis/23fa.svg"
  },
  {
    "codes": "23cf",
    "char": "⏏️",
    "name": "Eject Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão ejetar",
      "eject button",
      "botón de expulsión"
    ],
    "keywordsStr": "av botão botón button de eject ejetar expulsión symbol símbolo",
    "imgUrl": "assets/emojis/23cf.svg"
  },
  {
    "codes": "1f3a6",
    "char": "🎦",
    "name": "Cinema",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "cinema",
      "cine"
    ],
    "keywordsStr": "av cine cinema symbol símbolo",
    "imgUrl": "assets/emojis/1f3a6.svg"
  },
  {
    "codes": "1f505",
    "char": "🔅",
    "name": "Dim Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão escurecer",
      "dim button",
      "botón tenue"
    ],
    "keywordsStr": "av botão botón button dim escurecer symbol símbolo tenue",
    "imgUrl": "assets/emojis/1f505.svg"
  },
  {
    "codes": "1f506",
    "char": "🔆",
    "name": "Bright Button",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "botão brilhante",
      "bright button",
      "botón brillante"
    ],
    "keywordsStr": "av botão botón bright brilhante brillante button symbol símbolo",
    "imgUrl": "assets/emojis/1f506.svg"
  },
  {
    "codes": "1f4f6",
    "char": "📶",
    "name": "Antenna Bars",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "barras de antena",
      "antenna bars"
    ],
    "keywordsStr": "antena antenna av barras bars de symbol símbolo",
    "imgUrl": "assets/emojis/1f4f6.svg"
  },
  {
    "codes": "1f4f3",
    "char": "📳",
    "name": "Vibration Mode",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "modo de vibração",
      "vibration mode",
      "modo de vibración"
    ],
    "keywordsStr": "av de mode modo symbol símbolo vibración vibration vibração",
    "imgUrl": "assets/emojis/1f4f3.svg"
  },
  {
    "codes": "1f4f4",
    "char": "📴",
    "name": "Mobile Phone Off",
    "category": "Symbols (av-symbol)",
    "group": "symbols",
    "subgroup": "av-symbol",
    "keywords": [
      "av",
      "símbolo",
      "symbol",
      "símbolo av",
      "av symbol",
      "celular desligado",
      "mobile phone off",
      "teléfono móvil apagado"
    ],
    "keywordsStr": "apagado av celular desligado mobile móvil off phone symbol símbolo teléfono",
    "imgUrl": "assets/emojis/1f4f4.svg"
  },
  {
    "codes": "26a7",
    "char": "⚧️",
    "name": "Transgender Symbol",
    "category": "Symbols (gender)",
    "group": "symbols",
    "subgroup": "gender",
    "keywords": [
      "gênero",
      "gender",
      "género",
      "símbolo transgênero",
      "transgender symbol",
      "símbolo transgénero"
    ],
    "keywordsStr": "gender género gênero symbol símbolo transgender transgénero transgênero",
    "imgUrl": "assets/emojis/26a7.svg"
  },
  {
    "codes": "2716",
    "char": "✖️",
    "name": "Multiply",
    "category": "Symbols (math)",
    "group": "symbols",
    "subgroup": "math",
    "keywords": [
      "matemática",
      "math",
      "matemáticas",
      "multiplicar",
      "multiply"
    ],
    "keywordsStr": "matemática matemáticas math multiplicar multiply",
    "imgUrl": "assets/emojis/2716.svg"
  },
  {
    "codes": "2795",
    "char": "➕",
    "name": "Plus",
    "category": "Symbols (math)",
    "group": "symbols",
    "subgroup": "math",
    "keywords": [
      "matemática",
      "math",
      "matemáticas",
      "mais",
      "plus",
      "más"
    ],
    "keywordsStr": "mais matemática matemáticas math más plus",
    "imgUrl": "assets/emojis/2795.svg"
  },
  {
    "codes": "2796",
    "char": "➖",
    "name": "Minus",
    "category": "Symbols (math)",
    "group": "symbols",
    "subgroup": "math",
    "keywords": [
      "matemática",
      "math",
      "matemáticas",
      "menos",
      "minus"
    ],
    "keywordsStr": "matemática matemáticas math menos minus",
    "imgUrl": "assets/emojis/2796.svg"
  },
  {
    "codes": "2797",
    "char": "➗",
    "name": "Divide",
    "category": "Symbols (math)",
    "group": "symbols",
    "subgroup": "math",
    "keywords": [
      "matemática",
      "math",
      "matemáticas",
      "dividir",
      "divide"
    ],
    "keywordsStr": "divide dividir matemática matemáticas math",
    "imgUrl": "assets/emojis/2797.svg"
  },
  {
    "codes": "267e",
    "char": "♾️",
    "name": "Infinity",
    "category": "Symbols (math)",
    "group": "symbols",
    "subgroup": "math",
    "keywords": [
      "matemática",
      "math",
      "matemáticas",
      "infinito",
      "infinity"
    ],
    "keywordsStr": "infinito infinity matemática matemáticas math",
    "imgUrl": "assets/emojis/267e.svg"
  },
  {
    "codes": "203c",
    "char": "‼️",
    "name": "Double Exclamation Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de exclamação duplo",
      "double exclamation mark",
      "doble signo de exclamación"
    ],
    "keywordsStr": "de doble double duplo exclamación exclamation exclamação mark ponto pontuação punctuation puntuación signo",
    "imgUrl": "assets/emojis/203c.svg"
  },
  {
    "codes": "2049",
    "char": "⁉️",
    "name": "Exclamation Question Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de interrogação de exclamação",
      "exclamation question mark",
      "signo de interrogación de exclamación"
    ],
    "keywordsStr": "de exclamación exclamation exclamação interrogación interrogação mark ponto pontuação punctuation puntuación question signo",
    "imgUrl": "assets/emojis/2049.svg"
  },
  {
    "codes": "2753",
    "char": "❓",
    "name": "Question Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de interrogação vermelho",
      "red question mark",
      "signo de interrogación rojo"
    ],
    "keywordsStr": "de interrogación interrogação mark ponto pontuação punctuation puntuación question red rojo signo vermelho",
    "imgUrl": "assets/emojis/2753.svg"
  },
  {
    "codes": "2754",
    "char": "❔",
    "name": "White Question Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de interrogação branco",
      "white question mark",
      "signo de interrogación blanco"
    ],
    "keywordsStr": "blanco branco de interrogación interrogação mark ponto pontuação punctuation puntuación question signo white",
    "imgUrl": "assets/emojis/2754.svg"
  },
  {
    "codes": "2755",
    "char": "❕",
    "name": "White Exclamation Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de exclamação branco",
      "white exclamation mark",
      "signo de exclamación blanco"
    ],
    "keywordsStr": "blanco branco de exclamación exclamation exclamação mark ponto pontuação punctuation puntuación signo white",
    "imgUrl": "assets/emojis/2755.svg"
  },
  {
    "codes": "2757",
    "char": "❗",
    "name": "Exclamation Mark",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "ponto de exclamação vermelho",
      "red exclamation mark",
      "signo de exclamación rojo"
    ],
    "keywordsStr": "de exclamación exclamation exclamação mark ponto pontuação punctuation puntuación red rojo signo vermelho",
    "imgUrl": "assets/emojis/2757.svg"
  },
  {
    "codes": "3030",
    "char": "〰️",
    "name": "Wavy Dash",
    "category": "Symbols (punctuation)",
    "group": "symbols",
    "subgroup": "punctuation",
    "keywords": [
      "pontuação",
      "punctuation",
      "puntuación",
      "traço ondulado",
      "wavy dash",
      "guión ondulado"
    ],
    "keywordsStr": "dash guión ondulado pontuação punctuation puntuación traço wavy",
    "imgUrl": "assets/emojis/3030.svg"
  },
  {
    "codes": "1f4b1",
    "char": "💱",
    "name": "Currency Exchange",
    "category": "Symbols (currency)",
    "group": "symbols",
    "subgroup": "currency",
    "keywords": [
      "moeda",
      "currency",
      "moneda",
      "câmbio de moedas",
      "currency exchange",
      "cambio de moneda"
    ],
    "keywordsStr": "cambio currency câmbio de exchange moeda moedas moneda",
    "imgUrl": "assets/emojis/1f4b1.svg"
  },
  {
    "codes": "1f4b2",
    "char": "💲",
    "name": "Heavy Dollar Sign",
    "category": "Symbols (currency)",
    "group": "symbols",
    "subgroup": "currency",
    "keywords": [
      "moeda",
      "currency",
      "moneda",
      "cifrão pesado",
      "heavy dollar sign",
      "signo de dólar pesado"
    ],
    "keywordsStr": "cifrão currency de dollar dólar heavy moeda moneda pesado sign signo",
    "imgUrl": "assets/emojis/1f4b2.svg"
  },
  {
    "codes": "267b",
    "char": "♻️",
    "name": "Recycling Symbol",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "símbolo de reciclagem",
      "recycling symbol",
      "símbolo de reciclaje"
    ],
    "keywordsStr": "de other otro outro reciclagem reciclaje recycling symbol símbolo",
    "imgUrl": "assets/emojis/267b.svg"
  },
  {
    "codes": "269c",
    "char": "⚜️",
    "name": "Fleur De Lis",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "flor-de-lis",
      "fleur-de-lis",
      "flor de lis"
    ],
    "keywordsStr": "de fleur-de-lis flor flor-de-lis lis other otro outro symbol símbolo",
    "imgUrl": "assets/emojis/269c.svg"
  },
  {
    "codes": "1f531",
    "char": "🔱",
    "name": "Trident Emblem",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "emblema tridente",
      "trident emblem"
    ],
    "keywordsStr": "emblem emblema other otro outro symbol símbolo trident tridente",
    "imgUrl": "assets/emojis/1f531.svg"
  },
  {
    "codes": "1f4db",
    "char": "📛",
    "name": "Name Badge",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "crachá de nome",
      "name badge",
      "insignia de nombre"
    ],
    "keywordsStr": "badge crachá de insignia name nombre nome other otro outro symbol símbolo",
    "imgUrl": "assets/emojis/1f4db.svg"
  },
  {
    "codes": "1f530",
    "char": "🔰",
    "name": "Japanese Symbol For Beginner",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "Símbolo japonês para iniciante",
      "Japanese symbol for beginner",
      "Símbolo japonés para principiante"
    ],
    "keywordsStr": "Japanese Símbolo beginner for iniciante japonés japonês other otro outro para principiante symbol símbolo",
    "imgUrl": "assets/emojis/1f530.svg"
  },
  {
    "codes": "2b55",
    "char": "⭕",
    "name": "Hollow Red Circle",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "círculo vermelho oco",
      "hollow red circle",
      "círculo rojo hueco"
    ],
    "keywordsStr": "circle círculo hollow hueco oco other otro outro red rojo symbol símbolo vermelho",
    "imgUrl": "assets/emojis/2b55.svg"
  },
  {
    "codes": "2705",
    "char": "✅",
    "name": "Check Mark Button",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "botão de marca de seleção",
      "check mark button",
      "botón de marca de verificación"
    ],
    "keywordsStr": "botão botón button check de marca mark other otro outro seleção symbol símbolo verificación",
    "imgUrl": "assets/emojis/2705.svg"
  },
  {
    "codes": "2611",
    "char": "☑️",
    "name": "Check Box With Check",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "caixa de seleção com verificação",
      "check box with check",
      "casilla de verificación con verificación"
    ],
    "keywordsStr": "box caixa casilla check com con de other otro outro seleção symbol símbolo verificación verificação with",
    "imgUrl": "assets/emojis/2611.svg"
  },
  {
    "codes": "2714",
    "char": "✔️",
    "name": "Check Mark",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "marca de verificação",
      "check mark",
      "marca de verificación"
    ],
    "keywordsStr": "check de marca mark other otro outro symbol símbolo verificación verificação",
    "imgUrl": "assets/emojis/2714.svg"
  },
  {
    "codes": "274c",
    "char": "❌",
    "name": "Cross Mark",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "marca cruzada",
      "cross mark"
    ],
    "keywordsStr": "cross cruzada marca mark other otro outro symbol símbolo",
    "imgUrl": "assets/emojis/274c.svg"
  },
  {
    "codes": "274e",
    "char": "❎",
    "name": "Cross Mark Button",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "botão de marca cruzada",
      "cross mark button",
      "botón de marca cruzada"
    ],
    "keywordsStr": "botão botón button cross cruzada de marca mark other otro outro symbol símbolo",
    "imgUrl": "assets/emojis/274e.svg"
  },
  {
    "codes": "27b0",
    "char": "➰",
    "name": "Curly Loop",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "laço encaracolado",
      "curly loop",
      "bucle rizado"
    ],
    "keywordsStr": "bucle curly encaracolado laço loop other otro outro rizado symbol símbolo",
    "imgUrl": "assets/emojis/27b0.svg"
  },
  {
    "codes": "27bf",
    "char": "➿",
    "name": "Double Curly Loop",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "loop duplo encaracolado",
      "double curly loop",
      "doble bucle rizado"
    ],
    "keywordsStr": "bucle curly doble double duplo encaracolado loop other otro outro rizado symbol símbolo",
    "imgUrl": "assets/emojis/27bf.svg"
  },
  {
    "codes": "303d",
    "char": "〽️",
    "name": "Part Alternation Mark",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "marca de alternância parcial",
      "part alternation mark",
      "marca de alternancia de parte"
    ],
    "keywordsStr": "alternancia alternation alternância de marca mark other otro outro parcial part parte symbol símbolo",
    "imgUrl": "assets/emojis/303d.svg"
  },
  {
    "codes": "2733",
    "char": "✳️",
    "name": "Eight Spoked Asterisk",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "asterisco de oito raios",
      "eight-spoked asterisk",
      "asterisco de ocho radios"
    ],
    "keywordsStr": "asterisco asterisk de eight-spoked ocho oito other otro outro radios raios symbol símbolo",
    "imgUrl": "assets/emojis/2733.svg"
  },
  {
    "codes": "2734",
    "char": "✴️",
    "name": "Eight Pointed Star",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "estrela de oito pontas",
      "eight-pointed star",
      "estrella de ocho puntas"
    ],
    "keywordsStr": "de eight-pointed estrela estrella ocho oito other otro outro pontas puntas star symbol símbolo",
    "imgUrl": "assets/emojis/2734.svg"
  },
  {
    "codes": "2747",
    "char": "❇️",
    "name": "Sparkle",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "brilhar",
      "sparkle",
      "chispa"
    ],
    "keywordsStr": "brilhar chispa other otro outro sparkle symbol símbolo",
    "imgUrl": "assets/emojis/2747.svg"
  },
  {
    "codes": "a9",
    "char": "©️",
    "name": "Copyright",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "direitos autorais",
      "copyright",
      "derechos de autor"
    ],
    "keywordsStr": "autor autorais copyright de derechos direitos other otro outro symbol símbolo",
    "imgUrl": "assets/emojis/a9.svg"
  },
  {
    "codes": "ae",
    "char": "®️",
    "name": "Registered",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "registrado",
      "registered"
    ],
    "keywordsStr": "other otro outro registered registrado symbol símbolo",
    "imgUrl": "assets/emojis/ae.svg"
  },
  {
    "codes": "2122",
    "char": "™️",
    "name": "Trade Mark",
    "category": "Symbols (other-symbol)",
    "group": "symbols",
    "subgroup": "other-symbol",
    "keywords": [
      "outro",
      "other",
      "otro",
      "símbolo",
      "symbol",
      "outro símbolo",
      "other symbol",
      "otro símbolo",
      "marca registrada",
      "trade mark",
      "marca comercial"
    ],
    "keywordsStr": "comercial marca mark other otro outro registrada symbol símbolo trade",
    "imgUrl": "assets/emojis/2122.svg"
  },
  {
    "codes": "23-20e3",
    "char": "#️⃣",
    "name": "Keycap Number Sign",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/23-20e3.svg"
  },
  {
    "codes": "2a-20e3",
    "char": "*️⃣",
    "name": "Keycap Asterisk",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/2a-20e3.svg"
  },
  {
    "codes": "30-20e3",
    "char": "0️⃣",
    "name": "Keycap Digit Zero",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/30-20e3.svg"
  },
  {
    "codes": "31-20e3",
    "char": "1️⃣",
    "name": "Keycap Digit One",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/31-20e3.svg"
  },
  {
    "codes": "32-20e3",
    "char": "2️⃣",
    "name": "Keycap Digit Two",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/32-20e3.svg"
  },
  {
    "codes": "33-20e3",
    "char": "3️⃣",
    "name": "Keycap Digit Three",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/33-20e3.svg"
  },
  {
    "codes": "34-20e3",
    "char": "4️⃣",
    "name": "Keycap Digit Four",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/34-20e3.svg"
  },
  {
    "codes": "35-20e3",
    "char": "5️⃣",
    "name": "Keycap Digit Five",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/35-20e3.svg"
  },
  {
    "codes": "36-20e3",
    "char": "6️⃣",
    "name": "Keycap Digit Six",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/36-20e3.svg"
  },
  {
    "codes": "37-20e3",
    "char": "7️⃣",
    "name": "Keycap Digit Seven",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/37-20e3.svg"
  },
  {
    "codes": "38-20e3",
    "char": "8️⃣",
    "name": "Keycap Digit Eight",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/38-20e3.svg"
  },
  {
    "codes": "39-20e3",
    "char": "9️⃣",
    "name": "Keycap Digit Nine",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/39-20e3.svg"
  },
  {
    "codes": "1f51f",
    "char": "🔟",
    "name": "Keycap 10",
    "category": "Symbols (keycap)",
    "group": "symbols",
    "subgroup": "keycap",
    "keywords": [
      "keycap",
      "teclado"
    ],
    "keywordsStr": "keycap teclado",
    "imgUrl": "assets/emojis/1f51f.svg"
  },
  {
    "codes": "1f520",
    "char": "🔠",
    "name": "Input Latin Uppercase",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "entrada latina maiúscula",
      "input latin uppercase",
      "ingresar mayúsculas latinas"
    ],
    "keywordsStr": "alfanum alphanum entrada ingresar input latin latina latinas maiúscula mayúsculas uppercase",
    "imgUrl": "assets/emojis/1f520.svg"
  },
  {
    "codes": "1f521",
    "char": "🔡",
    "name": "Input Latin Lowercase",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "input latino minúsculo",
      "input latin lowercase",
      "ingresar minúsculas latinas"
    ],
    "keywordsStr": "alfanum alphanum ingresar input latin latinas latino lowercase minúsculas minúsculo",
    "imgUrl": "assets/emojis/1f521.svg"
  },
  {
    "codes": "1f522",
    "char": "🔢",
    "name": "Input Numbers",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "números de entrada",
      "input numbers",
      "ingresar numeros"
    ],
    "keywordsStr": "alfanum alphanum de entrada ingresar input numbers numeros números",
    "imgUrl": "assets/emojis/1f522.svg"
  },
  {
    "codes": "1f523",
    "char": "🔣",
    "name": "Input Symbols",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "símbolos de entrada",
      "input symbols"
    ],
    "keywordsStr": "alfanum alphanum de entrada input symbols símbolos",
    "imgUrl": "assets/emojis/1f523.svg"
  },
  {
    "codes": "1f524",
    "char": "🔤",
    "name": "Input Latin Letters",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "entrar letras latinas",
      "input latin letters",
      "ingresar letras latinas"
    ],
    "keywordsStr": "alfanum alphanum entrar ingresar input latin latinas letras letters",
    "imgUrl": "assets/emojis/1f524.svg"
  },
  {
    "codes": "1f170",
    "char": "🅰️",
    "name": "A Button Blood Type",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Um botão (tipo de sangue)",
      "A button Blood Type",
      "Un botón (tipo de sangre)"
    ],
    "keywordsStr": "(tipo A Blood Type Um Un alfanum alphanum botão botón button de sangre) sangue)",
    "imgUrl": "assets/emojis/1f170.svg"
  },
  {
    "codes": "1f18e",
    "char": "🆎",
    "name": "Ab Button Blood Type",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Botão AB (tipo sanguíneo)",
      "AB button Blood Type",
      "Botón AB (tipo de sangre)"
    ],
    "keywordsStr": "(tipo AB Blood Botão Botón Type alfanum alphanum button de sangre) sanguíneo)",
    "imgUrl": "assets/emojis/1f18e.svg"
  },
  {
    "codes": "1f171",
    "char": "🅱️",
    "name": "B Button Blood Type",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Botão B (tipo sanguíneo)",
      "B button Blood Type",
      "Botón B (tipo de sangre)"
    ],
    "keywordsStr": "(tipo B Blood Botão Botón Type alfanum alphanum button de sangre) sanguíneo)",
    "imgUrl": "assets/emojis/1f171.svg"
  },
  {
    "codes": "1f191",
    "char": "🆑",
    "name": "Cl Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão CL",
      "CL button",
      "Botón CL"
    ],
    "keywordsStr": "Botón CL alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f191.svg"
  },
  {
    "codes": "1f192",
    "char": "🆒",
    "name": "Cool Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão COOL",
      "COOL button",
      "Botón FRÍO"
    ],
    "keywordsStr": "Botón COOL FRÍO alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f192.svg"
  },
  {
    "codes": "1f193",
    "char": "🆓",
    "name": "Free Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão LIVRE",
      "FREE button",
      "Botón GRATIS"
    ],
    "keywordsStr": "Botón FREE GRATIS LIVRE alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f193.svg"
  },
  {
    "codes": "2139",
    "char": "ℹ️",
    "name": "Information",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "informações",
      "information",
      "información"
    ],
    "keywordsStr": "alfanum alphanum información information informações",
    "imgUrl": "assets/emojis/2139.svg"
  },
  {
    "codes": "1f194",
    "char": "🆔",
    "name": "Id Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão de identificação",
      "ID button",
      "Botón de identificación"
    ],
    "keywordsStr": "Botón ID alfanum alphanum botão button de identificación identificação",
    "imgUrl": "assets/emojis/1f194.svg"
  },
  {
    "codes": "24c2",
    "char": "Ⓜ️",
    "name": "Circled M",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "circulado M",
      "circled M",
      "M en un círculo"
    ],
    "keywordsStr": "M alfanum alphanum circled circulado círculo en un",
    "imgUrl": "assets/emojis/24c2.svg"
  },
  {
    "codes": "1f195",
    "char": "🆕",
    "name": "New Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão NOVO",
      "NEW button",
      "Botón NUEVO"
    ],
    "keywordsStr": "Botón NEW NOVO NUEVO alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f195.svg"
  },
  {
    "codes": "1f196",
    "char": "🆖",
    "name": "Ng Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão NG",
      "NG button",
      "Botón NG"
    ],
    "keywordsStr": "Botón NG alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f196.svg"
  },
  {
    "codes": "1f17e",
    "char": "🅾️",
    "name": "O Button Blood Type",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Botão O (tipo sanguíneo)",
      "O button Blood Type",
      "Botón O (tipo de sangre)"
    ],
    "keywordsStr": "(tipo Blood Botão Botón O Type alfanum alphanum button de sangre) sanguíneo)",
    "imgUrl": "assets/emojis/1f17e.svg"
  },
  {
    "codes": "1f197",
    "char": "🆗",
    "name": "Ok Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão OK",
      "OK button",
      "Botón Aceptar"
    ],
    "keywordsStr": "Aceptar Botón OK alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f197.svg"
  },
  {
    "codes": "1f17f",
    "char": "🅿️",
    "name": "P Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Botão P",
      "P button",
      "Botón P"
    ],
    "keywordsStr": "Botão Botón P alfanum alphanum button",
    "imgUrl": "assets/emojis/1f17f.svg"
  },
  {
    "codes": "1f198",
    "char": "🆘",
    "name": "Sos Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão SOS",
      "SOS button",
      "Botón SOS"
    ],
    "keywordsStr": "Botón SOS alfanum alphanum botão button",
    "imgUrl": "assets/emojis/1f198.svg"
  },
  {
    "codes": "1f199",
    "char": "🆙",
    "name": "Up Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "botão para CIMA",
      "UP button",
      "¡ARRIBA botón"
    ],
    "keywordsStr": "CIMA UP alfanum alphanum botão botón button para ¡ARRIBA",
    "imgUrl": "assets/emojis/1f199.svg"
  },
  {
    "codes": "1f19a",
    "char": "🆚",
    "name": "Vs Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum",
      "Botão VS",
      "VS button",
      "Botón VS"
    ],
    "keywordsStr": "Botão Botón VS alfanum alphanum button",
    "imgUrl": "assets/emojis/1f19a.svg"
  },
  {
    "codes": "1f201",
    "char": "🈁",
    "name": "Japanese Here Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f201.svg"
  },
  {
    "codes": "1f202",
    "char": "🈂️",
    "name": "Japanese Service Charge Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f202.svg"
  },
  {
    "codes": "1f237",
    "char": "🈷️",
    "name": "Japanese Monthly Amount Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f237.svg"
  },
  {
    "codes": "1f236",
    "char": "🈶",
    "name": "Japanese Not Free Of Charge Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f236.svg"
  },
  {
    "codes": "1f22f",
    "char": "🈯",
    "name": "Japanese Reserved Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f22f.svg"
  },
  {
    "codes": "1f250",
    "char": "🉐",
    "name": "Japanese Bargain Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f250.svg"
  },
  {
    "codes": "1f239",
    "char": "🈹",
    "name": "Japanese Discount Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f239.svg"
  },
  {
    "codes": "1f21a",
    "char": "🈚",
    "name": "Japanese Free Of Charge Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f21a.svg"
  },
  {
    "codes": "1f232",
    "char": "🈲",
    "name": "Japanese Prohibited Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f232.svg"
  },
  {
    "codes": "1f251",
    "char": "🉑",
    "name": "Japanese Acceptable Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f251.svg"
  },
  {
    "codes": "1f238",
    "char": "🈸",
    "name": "Japanese Application Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f238.svg"
  },
  {
    "codes": "1f234",
    "char": "🈴",
    "name": "Japanese Passing Grade Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f234.svg"
  },
  {
    "codes": "1f233",
    "char": "🈳",
    "name": "Japanese Vacancy Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f233.svg"
  },
  {
    "codes": "3297",
    "char": "㊗️",
    "name": "Japanese Congratulations Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/3297.svg"
  },
  {
    "codes": "3299",
    "char": "㊙️",
    "name": "Japanese Secret Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/3299.svg"
  },
  {
    "codes": "1f23a",
    "char": "🈺",
    "name": "Japanese Open For Business Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f23a.svg"
  },
  {
    "codes": "1f235",
    "char": "🈵",
    "name": "Japanese No Vacancy Button",
    "category": "Symbols (alphanum)",
    "group": "symbols",
    "subgroup": "alphanum",
    "keywords": [
      "alfanum",
      "alphanum"
    ],
    "keywordsStr": "alfanum alphanum",
    "imgUrl": "assets/emojis/1f235.svg"
  },
  {
    "codes": "1f534",
    "char": "🔴",
    "name": "Large Red Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo vermelho",
      "red circle",
      "círculo rojo"
    ],
    "keywordsStr": "circle círculo geometric geométrico red rojo vermelho",
    "imgUrl": "assets/emojis/1f534.svg"
  },
  {
    "codes": "1f7e0",
    "char": "🟠",
    "name": "Large Orange Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo laranja",
      "orange circle",
      "círculo naranja"
    ],
    "keywordsStr": "circle círculo geometric geométrico laranja naranja orange",
    "imgUrl": "assets/emojis/1f7e0.svg"
  },
  {
    "codes": "1f7e1",
    "char": "🟡",
    "name": "Large Yellow Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo amarelo",
      "yellow circle",
      "círculo amarillo"
    ],
    "keywordsStr": "amarelo amarillo circle círculo geometric geométrico yellow",
    "imgUrl": "assets/emojis/1f7e1.svg"
  },
  {
    "codes": "1f7e2",
    "char": "🟢",
    "name": "Large Green Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo verde",
      "green circle"
    ],
    "keywordsStr": "circle círculo geometric geométrico green verde",
    "imgUrl": "assets/emojis/1f7e2.svg"
  },
  {
    "codes": "1f535",
    "char": "🔵",
    "name": "Large Blue Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo azul",
      "blue circle"
    ],
    "keywordsStr": "azul blue circle círculo geometric geométrico",
    "imgUrl": "assets/emojis/1f535.svg"
  },
  {
    "codes": "1f7e3",
    "char": "🟣",
    "name": "Large Purple Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo roxo",
      "purple circle",
      "círculo morado"
    ],
    "keywordsStr": "circle círculo geometric geométrico morado purple roxo",
    "imgUrl": "assets/emojis/1f7e3.svg"
  },
  {
    "codes": "1f7e4",
    "char": "🟤",
    "name": "Large Brown Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo marrom",
      "brown circle",
      "círculo marrón"
    ],
    "keywordsStr": "brown circle círculo geometric geométrico marrom marrón",
    "imgUrl": "assets/emojis/1f7e4.svg"
  },
  {
    "codes": "26ab",
    "char": "⚫",
    "name": "Black Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo preto",
      "black circle",
      "círculo negro"
    ],
    "keywordsStr": "black circle círculo geometric geométrico negro preto",
    "imgUrl": "assets/emojis/26ab.svg"
  },
  {
    "codes": "26aa",
    "char": "⚪",
    "name": "White Circle",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "círculo branco",
      "white circle",
      "círculo blanco"
    ],
    "keywordsStr": "blanco branco circle círculo geometric geométrico white",
    "imgUrl": "assets/emojis/26aa.svg"
  },
  {
    "codes": "1f7e5",
    "char": "🟥",
    "name": "Large Red Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado vermelho",
      "red square",
      "cuadrado rojo"
    ],
    "keywordsStr": "cuadrado geometric geométrico quadrado red rojo square vermelho",
    "imgUrl": "assets/emojis/1f7e5.svg"
  },
  {
    "codes": "1f7e7",
    "char": "🟧",
    "name": "Large Orange Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado laranja",
      "orange square",
      "cuadrado naranja"
    ],
    "keywordsStr": "cuadrado geometric geométrico laranja naranja orange quadrado square",
    "imgUrl": "assets/emojis/1f7e7.svg"
  },
  {
    "codes": "1f7e8",
    "char": "🟨",
    "name": "Large Yellow Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado amarelo",
      "yellow square",
      "cuadrado amarillo"
    ],
    "keywordsStr": "amarelo amarillo cuadrado geometric geométrico quadrado square yellow",
    "imgUrl": "assets/emojis/1f7e8.svg"
  },
  {
    "codes": "1f7e9",
    "char": "🟩",
    "name": "Large Green Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado verde",
      "green square",
      "cuadrado verde"
    ],
    "keywordsStr": "cuadrado geometric geométrico green quadrado square verde",
    "imgUrl": "assets/emojis/1f7e9.svg"
  },
  {
    "codes": "1f7e6",
    "char": "🟦",
    "name": "Large Blue Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado azul",
      "blue square",
      "cuadrado azul"
    ],
    "keywordsStr": "azul blue cuadrado geometric geométrico quadrado square",
    "imgUrl": "assets/emojis/1f7e6.svg"
  },
  {
    "codes": "1f7ea",
    "char": "🟪",
    "name": "Large Purple Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado roxo",
      "purple square",
      "cuadrado morado"
    ],
    "keywordsStr": "cuadrado geometric geométrico morado purple quadrado roxo square",
    "imgUrl": "assets/emojis/1f7ea.svg"
  },
  {
    "codes": "1f7eb",
    "char": "🟫",
    "name": "Large Brown Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado marrom",
      "brown square",
      "cuadrado marrón"
    ],
    "keywordsStr": "brown cuadrado geometric geométrico marrom marrón quadrado square",
    "imgUrl": "assets/emojis/1f7eb.svg"
  },
  {
    "codes": "2b1b",
    "char": "⬛",
    "name": "Black Large Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado preto grande",
      "black large square",
      "cuadrado grande negro"
    ],
    "keywordsStr": "black cuadrado geometric geométrico grande large negro preto quadrado square",
    "imgUrl": "assets/emojis/2b1b.svg"
  },
  {
    "codes": "2b1c",
    "char": "⬜",
    "name": "White Large Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado grande branco",
      "white large square",
      "cuadrado grande blanco"
    ],
    "keywordsStr": "blanco branco cuadrado geometric geométrico grande large quadrado square white",
    "imgUrl": "assets/emojis/2b1c.svg"
  },
  {
    "codes": "25fc",
    "char": "◼️",
    "name": "Black Medium Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado médio preto",
      "black medium square",
      "cuadrado medio negro"
    ],
    "keywordsStr": "black cuadrado geometric geométrico medio medium médio negro preto quadrado square",
    "imgUrl": "assets/emojis/25fc.svg"
  },
  {
    "codes": "25fb",
    "char": "◻️",
    "name": "White Medium Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado médio branco",
      "white medium square",
      "cuadrado mediano blanco"
    ],
    "keywordsStr": "blanco branco cuadrado geometric geométrico mediano medium médio quadrado square white",
    "imgUrl": "assets/emojis/25fb.svg"
  },
  {
    "codes": "25fe",
    "char": "◾",
    "name": "Black Medium Small Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado médio-pequeno preto",
      "black medium-small square",
      "cuadrado negro mediano-pequeño"
    ],
    "keywordsStr": "black cuadrado geometric geométrico mediano-pequeño medium-small médio-pequeno negro preto quadrado square",
    "imgUrl": "assets/emojis/25fe.svg"
  },
  {
    "codes": "25fd",
    "char": "◽",
    "name": "White Medium Small Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado médio-pequeno branco",
      "white medium-small square",
      "cuadrado blanco mediano-pequeño"
    ],
    "keywordsStr": "blanco branco cuadrado geometric geométrico mediano-pequeño medium-small médio-pequeno quadrado square white",
    "imgUrl": "assets/emojis/25fd.svg"
  },
  {
    "codes": "25aa",
    "char": "▪️",
    "name": "Black Small Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado preto pequeno",
      "black small square",
      "pequeño cuadrado negro"
    ],
    "keywordsStr": "black cuadrado geometric geométrico negro pequeno pequeño preto quadrado small square",
    "imgUrl": "assets/emojis/25aa.svg"
  },
  {
    "codes": "25ab",
    "char": "▫️",
    "name": "White Small Square",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "quadrado pequeno branco",
      "white small square",
      "pequeño cuadrado blanco"
    ],
    "keywordsStr": "blanco branco cuadrado geometric geométrico pequeno pequeño quadrado small square white",
    "imgUrl": "assets/emojis/25ab.svg"
  },
  {
    "codes": "1f536",
    "char": "🔶",
    "name": "Large Orange Diamond",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "grande diamante laranja",
      "large orange diamond",
      "diamante naranja grande"
    ],
    "keywordsStr": "diamante diamond geometric geométrico grande laranja large naranja orange",
    "imgUrl": "assets/emojis/1f536.svg"
  },
  {
    "codes": "1f537",
    "char": "🔷",
    "name": "Large Blue Diamond",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "grande diamante azul",
      "large blue diamond",
      "diamante azul grande"
    ],
    "keywordsStr": "azul blue diamante diamond geometric geométrico grande large",
    "imgUrl": "assets/emojis/1f537.svg"
  },
  {
    "codes": "1f538",
    "char": "🔸",
    "name": "Small Orange Diamond",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "pequeno diamante laranja",
      "small orange diamond",
      "pequeño diamante naranja"
    ],
    "keywordsStr": "diamante diamond geometric geométrico laranja naranja orange pequeno pequeño small",
    "imgUrl": "assets/emojis/1f538.svg"
  },
  {
    "codes": "1f539",
    "char": "🔹",
    "name": "Small Blue Diamond",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "pequeno diamante azul",
      "small blue diamond",
      "pequeño diamante azul"
    ],
    "keywordsStr": "azul blue diamante diamond geometric geométrico pequeno pequeño small",
    "imgUrl": "assets/emojis/1f539.svg"
  },
  {
    "codes": "1f53a",
    "char": "🔺",
    "name": "Red Triangle Pointed Up",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "triângulo vermelho apontado para cima",
      "red triangle pointed up",
      "triángulo rojo apuntando hacia arriba"
    ],
    "keywordsStr": "apontado apuntando arriba cima geometric geométrico hacia para pointed red rojo triangle triángulo triângulo up vermelho",
    "imgUrl": "assets/emojis/1f53a.svg"
  },
  {
    "codes": "1f53b",
    "char": "🔻",
    "name": "Red Triangle Pointed Down",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "triângulo vermelho apontado para baixo",
      "red triangle pointed down",
      "triángulo rojo apuntando hacia abajo"
    ],
    "keywordsStr": "abajo apontado apuntando baixo down geometric geométrico hacia para pointed red rojo triangle triángulo triângulo vermelho",
    "imgUrl": "assets/emojis/1f53b.svg"
  },
  {
    "codes": "1f4a0",
    "char": "💠",
    "name": "Diamond With A Dot",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "diamante com um ponto",
      "diamond with a dot",
      "diamante con un punto"
    ],
    "keywordsStr": "a com con diamante diamond dot geometric geométrico ponto punto um un with",
    "imgUrl": "assets/emojis/1f4a0.svg"
  },
  {
    "codes": "1f518",
    "char": "🔘",
    "name": "Radio Button",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "botão de rádio",
      "radio button",
      "botón de opción"
    ],
    "keywordsStr": "botão botón button de geometric geométrico opción radio rádio",
    "imgUrl": "assets/emojis/1f518.svg"
  },
  {
    "codes": "1f533",
    "char": "🔳",
    "name": "White Square Button",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "botão quadrado branco",
      "white square button",
      "botón cuadrado blanco"
    ],
    "keywordsStr": "blanco botão botón branco button cuadrado geometric geométrico quadrado square white",
    "imgUrl": "assets/emojis/1f533.svg"
  },
  {
    "codes": "1f532",
    "char": "🔲",
    "name": "Black Square Button",
    "category": "Symbols (geometric)",
    "group": "symbols",
    "subgroup": "geometric",
    "keywords": [
      "geométrico",
      "geometric",
      "botão quadrado preto",
      "black square button",
      "botón cuadrado negro"
    ],
    "keywordsStr": "black botão botón button cuadrado geometric geométrico negro preto quadrado square",
    "imgUrl": "assets/emojis/1f532.svg"
  },
  {
    "codes": "1f3c1",
    "char": "🏁",
    "name": "Chequered Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3c1.svg"
  },
  {
    "codes": "1f6a9",
    "char": "🚩",
    "name": "Triangular Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f6a9.svg"
  },
  {
    "codes": "1f38c",
    "char": "🎌",
    "name": "Crossed Flags",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera",
      "bandeiras",
      "flags",
      "banderas"
    ],
    "keywordsStr": "bandeira bandeiras bandera banderas flag flags",
    "imgUrl": "assets/emojis/1f38c.svg"
  },
  {
    "codes": "1f3f4",
    "char": "🏴",
    "name": "Black Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3f4.svg"
  },
  {
    "codes": "1f3f3",
    "char": "🏳️",
    "name": "White Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3f3.svg"
  },
  {
    "codes": "1f3f3-fe0f-200d-1f308",
    "char": "🏳️‍🌈",
    "name": "Rainbow Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3f3-fe0f-200d-1f308.svg"
  },
  {
    "codes": "1f3f3-fe0f-200d-26a7-fe0f",
    "char": "🏳️‍⚧️",
    "name": "Transgender Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3f3-fe0f-200d-26a7-fe0f.svg"
  },
  {
    "codes": "1f3f4-200d-2620-fe0f",
    "char": "🏴‍☠️",
    "name": "Pirate Flag",
    "category": "Flags (flag)",
    "group": "flags",
    "subgroup": "flag",
    "keywords": [
      "bandeira",
      "flag",
      "bandera"
    ],
    "keywordsStr": "bandeira bandera flag",
    "imgUrl": "assets/emojis/1f3f4-200d-2620-fe0f.svg"
  },
  {
    "codes": "1f1e6-1f1e8",
    "char": "🇦🇨",
    "name": "Flag Ascension Island",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ascensão",
      "Ascension",
      "Ascensión"
    ],
    "keywordsStr": "Ascension Ascensión Ascensão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1e8.svg"
  },
  {
    "codes": "1f1e6-1f1e9",
    "char": "🇦🇩",
    "name": "Flag Andorra",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Andorra"
    ],
    "keywordsStr": "Andorra bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1e9.svg"
  },
  {
    "codes": "1f1e6-1f1ea",
    "char": "🇦🇪",
    "name": "Flag United Arab Emirates",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Unidos",
      "United"
    ],
    "keywordsStr": "Unidos United bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1ea.svg"
  },
  {
    "codes": "1f1e6-1f1eb",
    "char": "🇦🇫",
    "name": "Flag Afghanistan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Afeganistão",
      "Afghanistan",
      "Afganistán"
    ],
    "keywordsStr": "Afeganistão Afganistán Afghanistan bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1eb.svg"
  },
  {
    "codes": "1f1e6-1f1ec",
    "char": "🇦🇬",
    "name": "Flag Antigua & Barbuda",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Antígua",
      "Antigua"
    ],
    "keywordsStr": "Antigua Antígua bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1ec.svg"
  },
  {
    "codes": "1f1e6-1f1ee",
    "char": "🇦🇮",
    "name": "Flag Anguilla",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Anguila",
      "Anguilla"
    ],
    "keywordsStr": "Anguila Anguilla bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1ee.svg"
  },
  {
    "codes": "1f1e6-1f1f1",
    "char": "🇦🇱",
    "name": "Flag Albania",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Albânia",
      "Albania"
    ],
    "keywordsStr": "Albania Albânia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f1.svg"
  },
  {
    "codes": "1f1e6-1f1f2",
    "char": "🇦🇲",
    "name": "Flag Armenia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Armênia",
      "Armenia"
    ],
    "keywordsStr": "Armenia Armênia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f2.svg"
  },
  {
    "codes": "1f1e6-1f1f4",
    "char": "🇦🇴",
    "name": "Flag Angola",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Angola"
    ],
    "keywordsStr": "Angola bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f4.svg"
  },
  {
    "codes": "1f1e6-1f1f6",
    "char": "🇦🇶",
    "name": "Flag Antarctica",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Antártica",
      "Antarctica",
      "Antártida"
    ],
    "keywordsStr": "Antarctica Antártica Antártida bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f6.svg"
  },
  {
    "codes": "1f1e6-1f1f7",
    "char": "🇦🇷",
    "name": "Flag Argentina",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Argentina"
    ],
    "keywordsStr": "Argentina bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f7.svg"
  },
  {
    "codes": "1f1e6-1f1f8",
    "char": "🇦🇸",
    "name": "Flag American Samoa",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Americano",
      "American",
      "americano"
    ],
    "keywordsStr": "American Americano americano bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1f8.svg"
  },
  {
    "codes": "1f1e6-1f1f9",
    "char": "🇦🇹",
    "name": "Flag Austria",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Áustria",
      "Austria"
    ],
    "keywordsStr": "Austria bandeira bandera country del do flag país Áustria",
    "imgUrl": "assets/emojis/1f1e6-1f1f9.svg"
  },
  {
    "codes": "1f1e6-1f1fa",
    "char": "🇦🇺",
    "name": "Flag Australia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Austrália",
      "Australia"
    ],
    "keywordsStr": "Australia Austrália bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1fa.svg"
  },
  {
    "codes": "1f1e6-1f1fc",
    "char": "🇦🇼",
    "name": "Flag Aruba",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Aruba"
    ],
    "keywordsStr": "Aruba bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1fc.svg"
  },
  {
    "codes": "1f1e6-1f1fd",
    "char": "🇦🇽",
    "name": "Flag Aland Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Aland"
    ],
    "keywordsStr": "Aland bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1fd.svg"
  },
  {
    "codes": "1f1e6-1f1ff",
    "char": "🇦🇿",
    "name": "Flag Azerbaijan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Azerbaijão",
      "Azerbaijan",
      "Azerbaiyán"
    ],
    "keywordsStr": "Azerbaijan Azerbaijão Azerbaiyán bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e6-1f1ff.svg"
  },
  {
    "codes": "1f1e7-1f1e6",
    "char": "🇧🇦",
    "name": "Flag Bosnia Herzegovina",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bósnia",
      "Bosnia"
    ],
    "keywordsStr": "Bosnia Bósnia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1e6.svg"
  },
  {
    "codes": "1f1e7-1f1e7",
    "char": "🇧🇧",
    "name": "Flag Barbados",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Barbados"
    ],
    "keywordsStr": "Barbados bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1e7.svg"
  },
  {
    "codes": "1f1e7-1f1e9",
    "char": "🇧🇩",
    "name": "Flag Bangladesh",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bangladesh",
      "Bangladés"
    ],
    "keywordsStr": "Bangladesh Bangladés bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1e9.svg"
  },
  {
    "codes": "1f1e7-1f1ea",
    "char": "🇧🇪",
    "name": "Flag Belgium",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bélgica",
      "Belgium"
    ],
    "keywordsStr": "Belgium Bélgica bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ea.svg"
  },
  {
    "codes": "1f1e7-1f1eb",
    "char": "🇧🇫",
    "name": "Flag Burkina Faso",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Burquina",
      "Burkina",
      "burkina"
    ],
    "keywordsStr": "Burkina Burquina bandeira bandera burkina country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1eb.svg"
  },
  {
    "codes": "1f1e7-1f1ec",
    "char": "🇧🇬",
    "name": "Flag Bulgaria",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bulgária",
      "Bulgaria"
    ],
    "keywordsStr": "Bulgaria Bulgária bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ec.svg"
  },
  {
    "codes": "1f1e7-1f1ed",
    "char": "🇧🇭",
    "name": "Flag Bahrain",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bahrein",
      "Bahrain",
      "Baréin"
    ],
    "keywordsStr": "Bahrain Bahrein Baréin bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ed.svg"
  },
  {
    "codes": "1f1e7-1f1ee",
    "char": "🇧🇮",
    "name": "Flag Burundi",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Burundi",
      "Burundí"
    ],
    "keywordsStr": "Burundi Burundí bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ee.svg"
  },
  {
    "codes": "1f1e7-1f1ef",
    "char": "🇧🇯",
    "name": "Flag Benin",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Benin",
      "Benín"
    ],
    "keywordsStr": "Benin Benín bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ef.svg"
  },
  {
    "codes": "1f1e7-1f1f1",
    "char": "🇧🇱",
    "name": "Flag St Barthelemy",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1e7-1f1f1.svg"
  },
  {
    "codes": "1f1e7-1f1f2",
    "char": "🇧🇲",
    "name": "Flag Bermuda",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bermudas",
      "Bermuda"
    ],
    "keywordsStr": "Bermuda Bermudas bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f2.svg"
  },
  {
    "codes": "1f1e7-1f1f3",
    "char": "🇧🇳",
    "name": "Flag Brunei",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bruno",
      "Brunei",
      "Brunéi"
    ],
    "keywordsStr": "Brunei Bruno Brunéi bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f3.svg"
  },
  {
    "codes": "1f1e7-1f1f4",
    "char": "🇧🇴",
    "name": "Flag Bolivia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bolívia",
      "Bolivia"
    ],
    "keywordsStr": "Bolivia Bolívia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f4.svg"
  },
  {
    "codes": "1f1e7-1f1f6",
    "char": "🇧🇶",
    "name": "Flag Caribbean Netherlands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Caribe",
      "Caribbean"
    ],
    "keywordsStr": "Caribbean Caribe bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f6.svg"
  },
  {
    "codes": "1f1e7-1f1f7",
    "char": "🇧🇷",
    "name": "Flag Brazil",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Brasil",
      "Brazil"
    ],
    "keywordsStr": "Brasil Brazil bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f7.svg"
  },
  {
    "codes": "1f1e7-1f1f8",
    "char": "🇧🇸",
    "name": "Flag Bahamas",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bahamas"
    ],
    "keywordsStr": "Bahamas bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f8.svg"
  },
  {
    "codes": "1f1e7-1f1f9",
    "char": "🇧🇹",
    "name": "Flag Bhutan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Butão",
      "Bhutan",
      "Bután"
    ],
    "keywordsStr": "Bhutan Bután Butão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1f9.svg"
  },
  {
    "codes": "1f1e7-1f1fb",
    "char": "🇧🇻",
    "name": "Flag Bouvet Island",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bouvete",
      "Bouvet"
    ],
    "keywordsStr": "Bouvet Bouvete bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1fb.svg"
  },
  {
    "codes": "1f1e7-1f1fc",
    "char": "🇧🇼",
    "name": "Flag Botswana",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Botsuana",
      "Botswana"
    ],
    "keywordsStr": "Botsuana Botswana bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1fc.svg"
  },
  {
    "codes": "1f1e7-1f1fe",
    "char": "🇧🇾",
    "name": "Flag Belarus",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Bielorrússia",
      "Belarus",
      "Bielorrusia"
    ],
    "keywordsStr": "Belarus Bielorrusia Bielorrússia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1fe.svg"
  },
  {
    "codes": "1f1e7-1f1ff",
    "char": "🇧🇿",
    "name": "Flag Belize",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Belize",
      "Belice"
    ],
    "keywordsStr": "Belice Belize bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e7-1f1ff.svg"
  },
  {
    "codes": "1f1e8-1f1e6",
    "char": "🇨🇦",
    "name": "Flag Canada",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Canadá",
      "Canada"
    ],
    "keywordsStr": "Canada Canadá bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1e6.svg"
  },
  {
    "codes": "1f1e8-1f1e8",
    "char": "🇨🇨",
    "name": "Flag Cocos Keeling Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cocos"
    ],
    "keywordsStr": "Cocos bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1e8.svg"
  },
  {
    "codes": "1f1e8-1f1e9",
    "char": "🇨🇩",
    "name": "Flag Congo Kinshasa",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Congo"
    ],
    "keywordsStr": "Congo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1e9.svg"
  },
  {
    "codes": "1f1e8-1f1eb",
    "char": "🇨🇫",
    "name": "Flag Central African Republic",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Central",
      "Céntrico"
    ],
    "keywordsStr": "Central Céntrico bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1eb.svg"
  },
  {
    "codes": "1f1e8-1f1ec",
    "char": "🇨🇬",
    "name": "Flag Congo Brazzaville",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Congo"
    ],
    "keywordsStr": "Congo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1ec.svg"
  },
  {
    "codes": "1f1e8-1f1ed",
    "char": "🇨🇭",
    "name": "Flag Switzerland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Suíça",
      "Switzerland",
      "Suiza"
    ],
    "keywordsStr": "Suiza Suíça Switzerland bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1ed.svg"
  },
  {
    "codes": "1f1e8-1f1ee",
    "char": "🇨🇮",
    "name": "Flag Cote Divoire",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Costa",
      "Cote"
    ],
    "keywordsStr": "Costa Cote bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1ee.svg"
  },
  {
    "codes": "1f1e8-1f1f0",
    "char": "🇨🇰",
    "name": "Flag Cook Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cozinhar",
      "Cook",
      "Cocinar"
    ],
    "keywordsStr": "Cocinar Cook Cozinhar bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f0.svg"
  },
  {
    "codes": "1f1e8-1f1f1",
    "char": "🇨🇱",
    "name": "Flag Chile",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Chile"
    ],
    "keywordsStr": "Chile bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f1.svg"
  },
  {
    "codes": "1f1e8-1f1f2",
    "char": "🇨🇲",
    "name": "Flag Cameroon",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Camarões",
      "Cameroon",
      "Camerún"
    ],
    "keywordsStr": "Camarões Cameroon Camerún bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f2.svg"
  },
  {
    "codes": "1f1e8-1f1f3",
    "char": "🇨🇳",
    "name": "Flag China",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "China"
    ],
    "keywordsStr": "China bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f3.svg"
  },
  {
    "codes": "1f1e8-1f1f4",
    "char": "🇨🇴",
    "name": "Flag Colombia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Colômbia",
      "Colombia"
    ],
    "keywordsStr": "Colombia Colômbia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f4.svg"
  },
  {
    "codes": "1f1e8-1f1f5",
    "char": "🇨🇵",
    "name": "Flag Clipperton Island",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Clipperton"
    ],
    "keywordsStr": "Clipperton bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f5.svg"
  },
  {
    "codes": "1f1e8-1f1f7",
    "char": "🇨🇷",
    "name": "Flag Costa Rica",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Costa"
    ],
    "keywordsStr": "Costa bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1f7.svg"
  },
  {
    "codes": "1f1e8-1f1fa",
    "char": "🇨🇺",
    "name": "Flag Cuba",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cuba"
    ],
    "keywordsStr": "Cuba bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1fa.svg"
  },
  {
    "codes": "1f1e8-1f1fb",
    "char": "🇨🇻",
    "name": "Flag Cape Verde",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cabo",
      "Cape"
    ],
    "keywordsStr": "Cabo Cape bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1fb.svg"
  },
  {
    "codes": "1f1e8-1f1fc",
    "char": "🇨🇼",
    "name": "Flag Curacao",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Curaçao",
      "Curazao"
    ],
    "keywordsStr": "Curazao Curaçao bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1fc.svg"
  },
  {
    "codes": "1f1e8-1f1fd",
    "char": "🇨🇽",
    "name": "Flag Christmas Island",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Natal",
      "Christmas",
      "Navidad"
    ],
    "keywordsStr": "Christmas Natal Navidad bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1fd.svg"
  },
  {
    "codes": "1f1e8-1f1fe",
    "char": "🇨🇾",
    "name": "Flag Cyprus",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Chipre",
      "Cyprus"
    ],
    "keywordsStr": "Chipre Cyprus bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1fe.svg"
  },
  {
    "codes": "1f1e8-1f1ff",
    "char": "🇨🇿",
    "name": "Flag Czechia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tcheca",
      "Czechia",
      "Chequia"
    ],
    "keywordsStr": "Chequia Czechia Tcheca bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e8-1f1ff.svg"
  },
  {
    "codes": "1f1e9-1f1ea",
    "char": "🇩🇪",
    "name": "Flag Germany",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Alemanha",
      "Germany",
      "Alemania"
    ],
    "keywordsStr": "Alemanha Alemania Germany bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1ea.svg"
  },
  {
    "codes": "1f1e9-1f1ec",
    "char": "🇩🇬",
    "name": "Flag Diego Garcia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Diego"
    ],
    "keywordsStr": "Diego bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1ec.svg"
  },
  {
    "codes": "1f1e9-1f1ef",
    "char": "🇩🇯",
    "name": "Flag Djibouti",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Djibuti",
      "Djibouti",
      "Yibuti"
    ],
    "keywordsStr": "Djibouti Djibuti Yibuti bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1ef.svg"
  },
  {
    "codes": "1f1e9-1f1f0",
    "char": "🇩🇰",
    "name": "Flag Denmark",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Dinamarca",
      "Denmark"
    ],
    "keywordsStr": "Denmark Dinamarca bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1f0.svg"
  },
  {
    "codes": "1f1e9-1f1f2",
    "char": "🇩🇲",
    "name": "Flag Dominica",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Dominica"
    ],
    "keywordsStr": "Dominica bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1f2.svg"
  },
  {
    "codes": "1f1e9-1f1f4",
    "char": "🇩🇴",
    "name": "Flag Dominican Republic",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Dominicano",
      "Dominican",
      "dominicana"
    ],
    "keywordsStr": "Dominican Dominicano bandeira bandera country del do dominicana flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1f4.svg"
  },
  {
    "codes": "1f1e9-1f1ff",
    "char": "🇩🇿",
    "name": "Flag Algeria",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Argélia",
      "Algeria",
      "Argelia"
    ],
    "keywordsStr": "Algeria Argelia Argélia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1e9-1f1ff.svg"
  },
  {
    "codes": "1f1ea-1f1e6",
    "char": "🇪🇦",
    "name": "Flag Ceuta Melilla",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ceuta"
    ],
    "keywordsStr": "Ceuta bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1e6.svg"
  },
  {
    "codes": "1f1ea-1f1e8",
    "char": "🇪🇨",
    "name": "Flag Ecuador",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Equador",
      "Ecuador"
    ],
    "keywordsStr": "Ecuador Equador bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1e8.svg"
  },
  {
    "codes": "1f1ea-1f1ea",
    "char": "🇪🇪",
    "name": "Flag Estonia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Estônia",
      "Estonia"
    ],
    "keywordsStr": "Estonia Estônia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1ea.svg"
  },
  {
    "codes": "1f1ea-1f1ec",
    "char": "🇪🇬",
    "name": "Flag Egypt",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Egito",
      "Egypt",
      "Egipto"
    ],
    "keywordsStr": "Egipto Egito Egypt bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1ec.svg"
  },
  {
    "codes": "1f1ea-1f1ed",
    "char": "🇪🇭",
    "name": "Flag Western Sahara",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ocidental",
      "Western",
      "occidental"
    ],
    "keywordsStr": "Ocidental Western bandeira bandera country del do flag occidental país",
    "imgUrl": "assets/emojis/1f1ea-1f1ed.svg"
  },
  {
    "codes": "1f1ea-1f1f7",
    "char": "🇪🇷",
    "name": "Flag Eritrea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Eritreia",
      "Eritrea"
    ],
    "keywordsStr": "Eritrea Eritreia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1f7.svg"
  },
  {
    "codes": "1f1ea-1f1f8",
    "char": "🇪🇸",
    "name": "Flag Spain",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Espanha",
      "Spain",
      "España"
    ],
    "keywordsStr": "Espanha España Spain bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1f8.svg"
  },
  {
    "codes": "1f1ea-1f1f9",
    "char": "🇪🇹",
    "name": "Flag Ethiopia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Etiópia",
      "Ethiopia",
      "Etiopía"
    ],
    "keywordsStr": "Ethiopia Etiopía Etiópia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1f9.svg"
  },
  {
    "codes": "1f1ea-1f1fa",
    "char": "🇪🇺",
    "name": "Flag European Union",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "europeu",
      "European",
      "europeo"
    ],
    "keywordsStr": "European bandeira bandera country del do europeo europeu flag país",
    "imgUrl": "assets/emojis/1f1ea-1f1fa.svg"
  },
  {
    "codes": "1f1eb-1f1ee",
    "char": "🇫🇮",
    "name": "Flag Finland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Finlândia",
      "Finland",
      "Finlandia"
    ],
    "keywordsStr": "Finland Finlandia Finlândia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1ee.svg"
  },
  {
    "codes": "1f1eb-1f1ef",
    "char": "🇫🇯",
    "name": "Flag Fiji",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Fiji",
      "Fiyi"
    ],
    "keywordsStr": "Fiji Fiyi bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1ef.svg"
  },
  {
    "codes": "1f1eb-1f1f0",
    "char": "🇫🇰",
    "name": "Flag Falkland Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Falkland",
      "Malvinas"
    ],
    "keywordsStr": "Falkland Malvinas bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1f0.svg"
  },
  {
    "codes": "1f1eb-1f1f2",
    "char": "🇫🇲",
    "name": "Flag Micronesia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Micronésia",
      "Micronesia"
    ],
    "keywordsStr": "Micronesia Micronésia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1f2.svg"
  },
  {
    "codes": "1f1eb-1f1f4",
    "char": "🇫🇴",
    "name": "Flag Faroe Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Faroé",
      "Faroe",
      "Islas Feroe"
    ],
    "keywordsStr": "Faroe Faroé Feroe Islas bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1f4.svg"
  },
  {
    "codes": "1f1eb-1f1f7",
    "char": "🇫🇷",
    "name": "Flag France",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "França",
      "France",
      "Francia"
    ],
    "keywordsStr": "France Francia França bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1eb-1f1f7.svg"
  },
  {
    "codes": "1f1ec-1f1e6",
    "char": "🇬🇦",
    "name": "Flag Gabon",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Gabão",
      "Gabon",
      "Gabón"
    ],
    "keywordsStr": "Gabon Gabão Gabón bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1e6.svg"
  },
  {
    "codes": "1f1ec-1f1e7",
    "char": "🇬🇧",
    "name": "Flag United Kingdom",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Unidos",
      "United"
    ],
    "keywordsStr": "Unidos United bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1e7.svg"
  },
  {
    "codes": "1f1ec-1f1e9",
    "char": "🇬🇩",
    "name": "Flag Grenada",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Granada",
      "Grenada"
    ],
    "keywordsStr": "Granada Grenada bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1e9.svg"
  },
  {
    "codes": "1f1ec-1f1ea",
    "char": "🇬🇪",
    "name": "Flag Georgia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Geórgia",
      "Georgia"
    ],
    "keywordsStr": "Georgia Geórgia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1ea.svg"
  },
  {
    "codes": "1f1ec-1f1eb",
    "char": "🇬🇫",
    "name": "Flag French Guiana",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "francês",
      "French",
      "francés"
    ],
    "keywordsStr": "French bandeira bandera country del do flag francés francês país",
    "imgUrl": "assets/emojis/1f1ec-1f1eb.svg"
  },
  {
    "codes": "1f1ec-1f1ec",
    "char": "🇬🇬",
    "name": "Flag Guernsey",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guernsey"
    ],
    "keywordsStr": "Guernsey bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1ec.svg"
  },
  {
    "codes": "1f1ec-1f1ed",
    "char": "🇬🇭",
    "name": "Flag Ghana",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Gana",
      "Ghana"
    ],
    "keywordsStr": "Gana Ghana bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1ed.svg"
  },
  {
    "codes": "1f1ec-1f1ee",
    "char": "🇬🇮",
    "name": "Flag Gibraltar",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Gibraltar"
    ],
    "keywordsStr": "Gibraltar bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1ee.svg"
  },
  {
    "codes": "1f1ec-1f1f1",
    "char": "🇬🇱",
    "name": "Flag Greenland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Gronelândia",
      "Greenland",
      "Groenlandia"
    ],
    "keywordsStr": "Greenland Groenlandia Gronelândia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f1.svg"
  },
  {
    "codes": "1f1ec-1f1f2",
    "char": "🇬🇲",
    "name": "Flag Gambia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Gâmbia",
      "Gambia"
    ],
    "keywordsStr": "Gambia Gâmbia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f2.svg"
  },
  {
    "codes": "1f1ec-1f1f3",
    "char": "🇬🇳",
    "name": "Flag Guinea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guiné",
      "Guinea"
    ],
    "keywordsStr": "Guinea Guiné bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f3.svg"
  },
  {
    "codes": "1f1ec-1f1f5",
    "char": "🇬🇵",
    "name": "Flag Guadeloupe",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guadalupe",
      "Guadeloupe"
    ],
    "keywordsStr": "Guadalupe Guadeloupe bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f5.svg"
  },
  {
    "codes": "1f1ec-1f1f6",
    "char": "🇬🇶",
    "name": "Flag Equatorial Guinea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Equatorial",
      "Ecuatorial"
    ],
    "keywordsStr": "Ecuatorial Equatorial bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f6.svg"
  },
  {
    "codes": "1f1ec-1f1f7",
    "char": "🇬🇷",
    "name": "Flag Greece",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Grécia",
      "Greece",
      "Grecia"
    ],
    "keywordsStr": "Grecia Greece Grécia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f7.svg"
  },
  {
    "codes": "1f1ec-1f1f8",
    "char": "🇬🇸",
    "name": "Flag South Georgia South Sandwich Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sul",
      "South",
      "Sur"
    ],
    "keywordsStr": "South Sul Sur bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f8.svg"
  },
  {
    "codes": "1f1ec-1f1f9",
    "char": "🇬🇹",
    "name": "Flag Guatemala",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guatemala"
    ],
    "keywordsStr": "Guatemala bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1f9.svg"
  },
  {
    "codes": "1f1ec-1f1fa",
    "char": "🇬🇺",
    "name": "Flag Guam",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guam"
    ],
    "keywordsStr": "Guam bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1fa.svg"
  },
  {
    "codes": "1f1ec-1f1fc",
    "char": "🇬🇼",
    "name": "Flag Guinea Bissau",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guiné-Bissau",
      "Guinea-Bissau",
      "Guinea-Bisáu"
    ],
    "keywordsStr": "Guinea-Bissau Guinea-Bisáu Guiné-Bissau bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1fc.svg"
  },
  {
    "codes": "1f1ec-1f1fe",
    "char": "🇬🇾",
    "name": "Flag Guyana",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Guiana",
      "Guyana"
    ],
    "keywordsStr": "Guiana Guyana bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ec-1f1fe.svg"
  },
  {
    "codes": "1f1ed-1f1f0",
    "char": "🇭🇰",
    "name": "Flag Hong Kong Sar China",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Hong"
    ],
    "keywordsStr": "Hong bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1f0.svg"
  },
  {
    "codes": "1f1ed-1f1f2",
    "char": "🇭🇲",
    "name": "Flag Heard Mcdonald Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ouvi",
      "Heard",
      "Escuchado"
    ],
    "keywordsStr": "Escuchado Heard Ouvi bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1f2.svg"
  },
  {
    "codes": "1f1ed-1f1f3",
    "char": "🇭🇳",
    "name": "Flag Honduras",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Honduras"
    ],
    "keywordsStr": "Honduras bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1f3.svg"
  },
  {
    "codes": "1f1ed-1f1f7",
    "char": "🇭🇷",
    "name": "Flag Croatia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Croácia",
      "Croatia",
      "Croacia"
    ],
    "keywordsStr": "Croacia Croatia Croácia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1f7.svg"
  },
  {
    "codes": "1f1ed-1f1f9",
    "char": "🇭🇹",
    "name": "Flag Haiti",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Haiti",
      "Haití"
    ],
    "keywordsStr": "Haiti Haití bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1f9.svg"
  },
  {
    "codes": "1f1ed-1f1fa",
    "char": "🇭🇺",
    "name": "Flag Hungary",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Hungria",
      "Hungary",
      "Hungría"
    ],
    "keywordsStr": "Hungary Hungria Hungría bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ed-1f1fa.svg"
  },
  {
    "codes": "1f1ee-1f1e8",
    "char": "🇮🇨",
    "name": "Flag Canary Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Canário",
      "Canary",
      "Canario"
    ],
    "keywordsStr": "Canario Canary Canário bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1e8.svg"
  },
  {
    "codes": "1f1ee-1f1e9",
    "char": "🇮🇩",
    "name": "Flag Indonesia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Indonésia",
      "Indonesia"
    ],
    "keywordsStr": "Indonesia Indonésia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1e9.svg"
  },
  {
    "codes": "1f1ee-1f1ea",
    "char": "🇮🇪",
    "name": "Flag Ireland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Irlanda",
      "Ireland"
    ],
    "keywordsStr": "Ireland Irlanda bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1ea.svg"
  },
  {
    "codes": "1f1ee-1f1f1",
    "char": "🇮🇱",
    "name": "Flag Israel",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Israel"
    ],
    "keywordsStr": "Israel bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f1.svg"
  },
  {
    "codes": "1f1ee-1f1f2",
    "char": "🇮🇲",
    "name": "Flag Isle Of Man",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ilha",
      "Isle",
      "Isla"
    ],
    "keywordsStr": "Ilha Isla Isle bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f2.svg"
  },
  {
    "codes": "1f1ee-1f1f3",
    "char": "🇮🇳",
    "name": "Flag India",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Índia",
      "India"
    ],
    "keywordsStr": "India bandeira bandera country del do flag país Índia",
    "imgUrl": "assets/emojis/1f1ee-1f1f3.svg"
  },
  {
    "codes": "1f1ee-1f1f4",
    "char": "🇮🇴",
    "name": "Flag British Indian Ocean Territory",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "britânico",
      "British",
      "británico"
    ],
    "keywordsStr": "British bandeira bandera británico britânico country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f4.svg"
  },
  {
    "codes": "1f1ee-1f1f6",
    "char": "🇮🇶",
    "name": "Flag Iraq",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Iraque",
      "Iraq",
      "Irak"
    ],
    "keywordsStr": "Irak Iraq Iraque bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f6.svg"
  },
  {
    "codes": "1f1ee-1f1f7",
    "char": "🇮🇷",
    "name": "Flag Iran",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Irã",
      "Iran",
      "Irán"
    ],
    "keywordsStr": "Iran Irán Irã bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f7.svg"
  },
  {
    "codes": "1f1ee-1f1f8",
    "char": "🇮🇸",
    "name": "Flag Iceland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Islândia",
      "Iceland",
      "Islandia"
    ],
    "keywordsStr": "Iceland Islandia Islândia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f8.svg"
  },
  {
    "codes": "1f1ee-1f1f9",
    "char": "🇮🇹",
    "name": "Flag Italy",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Itália",
      "Italy",
      "Italia"
    ],
    "keywordsStr": "Italia Italy Itália bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ee-1f1f9.svg"
  },
  {
    "codes": "1f1ef-1f1ea",
    "char": "🇯🇪",
    "name": "Flag Jersey",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Jersey"
    ],
    "keywordsStr": "Jersey bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ef-1f1ea.svg"
  },
  {
    "codes": "1f1ef-1f1f2",
    "char": "🇯🇲",
    "name": "Flag Jamaica",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Jamaica"
    ],
    "keywordsStr": "Jamaica bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ef-1f1f2.svg"
  },
  {
    "codes": "1f1ef-1f1f4",
    "char": "🇯🇴",
    "name": "Flag Jordan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Jordânia",
      "Jordan",
      "Jordania"
    ],
    "keywordsStr": "Jordan Jordania Jordânia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ef-1f1f4.svg"
  },
  {
    "codes": "1f1ef-1f1f5",
    "char": "🇯🇵",
    "name": "Flag Japan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Japão",
      "Japan",
      "Japón"
    ],
    "keywordsStr": "Japan Japão Japón bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ef-1f1f5.svg"
  },
  {
    "codes": "1f1f0-1f1ea",
    "char": "🇰🇪",
    "name": "Flag Kenya",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Quênia",
      "Kenya",
      "Kenia"
    ],
    "keywordsStr": "Kenia Kenya Quênia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1ea.svg"
  },
  {
    "codes": "1f1f0-1f1ec",
    "char": "🇰🇬",
    "name": "Flag Kyrgyzstan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Quirguistão",
      "Kyrgyzstan",
      "Kirguistán"
    ],
    "keywordsStr": "Kirguistán Kyrgyzstan Quirguistão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1ec.svg"
  },
  {
    "codes": "1f1f0-1f1ed",
    "char": "🇰🇭",
    "name": "Flag Cambodia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Camboja",
      "Cambodia",
      "Camboya"
    ],
    "keywordsStr": "Cambodia Camboja Camboya bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1ed.svg"
  },
  {
    "codes": "1f1f0-1f1ee",
    "char": "🇰🇮",
    "name": "Flag Kiribati",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Kiribati",
      "Kiribatí"
    ],
    "keywordsStr": "Kiribati Kiribatí bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1ee.svg"
  },
  {
    "codes": "1f1f0-1f1f2",
    "char": "🇰🇲",
    "name": "Flag Comoros",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Comores",
      "Comoros",
      "Comoras"
    ],
    "keywordsStr": "Comoras Comores Comoros bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1f2.svg"
  },
  {
    "codes": "1f1f0-1f1f3",
    "char": "🇰🇳",
    "name": "Flag St Kitts Nevis",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1f0-1f1f3.svg"
  },
  {
    "codes": "1f1f0-1f1f5",
    "char": "🇰🇵",
    "name": "Flag North Korea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Norte",
      "North"
    ],
    "keywordsStr": "Norte North bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1f5.svg"
  },
  {
    "codes": "1f1f0-1f1f7",
    "char": "🇰🇷",
    "name": "Flag South Korea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sul",
      "South",
      "Sur"
    ],
    "keywordsStr": "South Sul Sur bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1f7.svg"
  },
  {
    "codes": "1f1f0-1f1fc",
    "char": "🇰🇼",
    "name": "Flag Kuwait",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Kuwait"
    ],
    "keywordsStr": "Kuwait bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1fc.svg"
  },
  {
    "codes": "1f1f0-1f1fe",
    "char": "🇰🇾",
    "name": "Flag Cayman Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cayman",
      "Caimán"
    ],
    "keywordsStr": "Caimán Cayman bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1fe.svg"
  },
  {
    "codes": "1f1f0-1f1ff",
    "char": "🇰🇿",
    "name": "Flag Kazakhstan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Cazaquistão",
      "Kazakhstan",
      "Kazajstán"
    ],
    "keywordsStr": "Cazaquistão Kazajstán Kazakhstan bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f0-1f1ff.svg"
  },
  {
    "codes": "1f1f1-1f1e6",
    "char": "🇱🇦",
    "name": "Flag Laos",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Laos"
    ],
    "keywordsStr": "Laos bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1e6.svg"
  },
  {
    "codes": "1f1f1-1f1e7",
    "char": "🇱🇧",
    "name": "Flag Lebanon",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Líbano",
      "Lebanon"
    ],
    "keywordsStr": "Lebanon Líbano bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1e7.svg"
  },
  {
    "codes": "1f1f1-1f1e8",
    "char": "🇱🇨",
    "name": "Flag St Lucia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1f1-1f1e8.svg"
  },
  {
    "codes": "1f1f1-1f1ee",
    "char": "🇱🇮",
    "name": "Flag Liechtenstein",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Liechtenstein"
    ],
    "keywordsStr": "Liechtenstein bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1ee.svg"
  },
  {
    "codes": "1f1f1-1f1f0",
    "char": "🇱🇰",
    "name": "Flag Sri Lanka",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sr",
      "Sri"
    ],
    "keywordsStr": "Sr Sri bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1f0.svg"
  },
  {
    "codes": "1f1f1-1f1f7",
    "char": "🇱🇷",
    "name": "Flag Liberia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Libéria",
      "Liberia"
    ],
    "keywordsStr": "Liberia Libéria bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1f7.svg"
  },
  {
    "codes": "1f1f1-1f1f8",
    "char": "🇱🇸",
    "name": "Flag Lesotho",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Lesoto",
      "Lesotho"
    ],
    "keywordsStr": "Lesotho Lesoto bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1f8.svg"
  },
  {
    "codes": "1f1f1-1f1f9",
    "char": "🇱🇹",
    "name": "Flag Lithuania",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Lituânia",
      "Lithuania",
      "Lituania"
    ],
    "keywordsStr": "Lithuania Lituania Lituânia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1f9.svg"
  },
  {
    "codes": "1f1f1-1f1fa",
    "char": "🇱🇺",
    "name": "Flag Luxembourg",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Luxemburgo",
      "Luxembourg"
    ],
    "keywordsStr": "Luxembourg Luxemburgo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1fa.svg"
  },
  {
    "codes": "1f1f1-1f1fb",
    "char": "🇱🇻",
    "name": "Flag Latvia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Letônia",
      "Latvia",
      "Letonia"
    ],
    "keywordsStr": "Latvia Letonia Letônia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1fb.svg"
  },
  {
    "codes": "1f1f1-1f1fe",
    "char": "🇱🇾",
    "name": "Flag Libya",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Líbia",
      "Libya",
      "Libia"
    ],
    "keywordsStr": "Libia Libya Líbia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f1-1f1fe.svg"
  },
  {
    "codes": "1f1f2-1f1e6",
    "char": "🇲🇦",
    "name": "Flag Morocco",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Marrocos",
      "Morocco",
      "Marruecos"
    ],
    "keywordsStr": "Marrocos Marruecos Morocco bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1e6.svg"
  },
  {
    "codes": "1f1f2-1f1e8",
    "char": "🇲🇨",
    "name": "Flag Monaco",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Mônaco",
      "Monaco",
      "Mónaco"
    ],
    "keywordsStr": "Monaco Mónaco Mônaco bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1e8.svg"
  },
  {
    "codes": "1f1f2-1f1e9",
    "char": "🇲🇩",
    "name": "Flag Moldova",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Moldávia",
      "Moldova",
      "Moldavia"
    ],
    "keywordsStr": "Moldavia Moldova Moldávia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1e9.svg"
  },
  {
    "codes": "1f1f2-1f1ea",
    "char": "🇲🇪",
    "name": "Flag Montenegro",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Montenegro"
    ],
    "keywordsStr": "Montenegro bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1ea.svg"
  },
  {
    "codes": "1f1f2-1f1eb",
    "char": "🇲🇫",
    "name": "Flag St Martin",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1f2-1f1eb.svg"
  },
  {
    "codes": "1f1f2-1f1ec",
    "char": "🇲🇬",
    "name": "Flag Madagascar",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Madagáscar",
      "Madagascar"
    ],
    "keywordsStr": "Madagascar Madagáscar bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1ec.svg"
  },
  {
    "codes": "1f1f2-1f1ed",
    "char": "🇲🇭",
    "name": "Flag Marshall Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Marechal",
      "Marshall"
    ],
    "keywordsStr": "Marechal Marshall bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1ed.svg"
  },
  {
    "codes": "1f1f2-1f1f0",
    "char": "🇲🇰",
    "name": "Flag North Macedonia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Norte",
      "North"
    ],
    "keywordsStr": "Norte North bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f0.svg"
  },
  {
    "codes": "1f1f2-1f1f1",
    "char": "🇲🇱",
    "name": "Flag Mali",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Mali",
      "Malí"
    ],
    "keywordsStr": "Mali Malí bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f1.svg"
  },
  {
    "codes": "1f1f2-1f1f2",
    "char": "🇲🇲",
    "name": "Flag Myanmar Burma",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Mianmar",
      "Myanmar",
      "Birmania"
    ],
    "keywordsStr": "Birmania Mianmar Myanmar bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f2.svg"
  },
  {
    "codes": "1f1f2-1f1f3",
    "char": "🇲🇳",
    "name": "Flag Mongolia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Mongólia",
      "Mongolia"
    ],
    "keywordsStr": "Mongolia Mongólia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f3.svg"
  },
  {
    "codes": "1f1f2-1f1f4",
    "char": "🇲🇴",
    "name": "Flag Macao Sar China",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Macau",
      "Macao"
    ],
    "keywordsStr": "Macao Macau bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f4.svg"
  },
  {
    "codes": "1f1f2-1f1f5",
    "char": "🇲🇵",
    "name": "Flag Northern Mariana Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Norte",
      "Northern"
    ],
    "keywordsStr": "Norte Northern bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f5.svg"
  },
  {
    "codes": "1f1f2-1f1f6",
    "char": "🇲🇶",
    "name": "Flag Martinique",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Martinica",
      "Martinique"
    ],
    "keywordsStr": "Martinica Martinique bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f6.svg"
  },
  {
    "codes": "1f1f2-1f1f7",
    "char": "🇲🇷",
    "name": "Flag Mauritania",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Mauritânia",
      "Mauritania"
    ],
    "keywordsStr": "Mauritania Mauritânia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f7.svg"
  },
  {
    "codes": "1f1f2-1f1f8",
    "char": "🇲🇸",
    "name": "Flag Montserrat",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Montserrate",
      "Montserrat",
      "Monserrat"
    ],
    "keywordsStr": "Monserrat Montserrat Montserrate bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f8.svg"
  },
  {
    "codes": "1f1f2-1f1f9",
    "char": "🇲🇹",
    "name": "Flag Malta",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Malta"
    ],
    "keywordsStr": "Malta bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1f9.svg"
  },
  {
    "codes": "1f1f2-1f1fa",
    "char": "🇲🇺",
    "name": "Flag Mauritius",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Maurício",
      "Mauritius",
      "Mauricio"
    ],
    "keywordsStr": "Mauricio Mauritius Maurício bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1fa.svg"
  },
  {
    "codes": "1f1f2-1f1fb",
    "char": "🇲🇻",
    "name": "Flag Maldives",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Maldivas",
      "Maldives"
    ],
    "keywordsStr": "Maldivas Maldives bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1fb.svg"
  },
  {
    "codes": "1f1f2-1f1fc",
    "char": "🇲🇼",
    "name": "Flag Malawi",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Malawi",
      "Malaui"
    ],
    "keywordsStr": "Malaui Malawi bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1fc.svg"
  },
  {
    "codes": "1f1f2-1f1fd",
    "char": "🇲🇽",
    "name": "Flag Mexico",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "México",
      "Mexico"
    ],
    "keywordsStr": "Mexico México bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1fd.svg"
  },
  {
    "codes": "1f1f2-1f1fe",
    "char": "🇲🇾",
    "name": "Flag Malaysia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Malásia",
      "Malaysia",
      "Malasia"
    ],
    "keywordsStr": "Malasia Malaysia Malásia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1fe.svg"
  },
  {
    "codes": "1f1f2-1f1ff",
    "char": "🇲🇿",
    "name": "Flag Mozambique",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Moçambique",
      "Mozambique"
    ],
    "keywordsStr": "Mozambique Moçambique bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f2-1f1ff.svg"
  },
  {
    "codes": "1f1f3-1f1e6",
    "char": "🇳🇦",
    "name": "Flag Namibia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Namíbia",
      "Namibia",
      "Namibio"
    ],
    "keywordsStr": "Namibia Namibio Namíbia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1e6.svg"
  },
  {
    "codes": "1f1f3-1f1e8",
    "char": "🇳🇨",
    "name": "Flag New Caledonia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Novo",
      "New",
      "Nuevo"
    ],
    "keywordsStr": "New Novo Nuevo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1e8.svg"
  },
  {
    "codes": "1f1f3-1f1ea",
    "char": "🇳🇪",
    "name": "Flag Niger",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Níger",
      "Niger"
    ],
    "keywordsStr": "Niger Níger bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1ea.svg"
  },
  {
    "codes": "1f1f3-1f1eb",
    "char": "🇳🇫",
    "name": "Flag Norfolk Island",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Norfolk"
    ],
    "keywordsStr": "Norfolk bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1eb.svg"
  },
  {
    "codes": "1f1f3-1f1ec",
    "char": "🇳🇬",
    "name": "Flag Nigeria",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Nigéria",
      "Nigeria"
    ],
    "keywordsStr": "Nigeria Nigéria bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1ec.svg"
  },
  {
    "codes": "1f1f3-1f1ee",
    "char": "🇳🇮",
    "name": "Flag Nicaragua",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Nicarágua",
      "Nicaragua"
    ],
    "keywordsStr": "Nicaragua Nicarágua bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1ee.svg"
  },
  {
    "codes": "1f1f3-1f1f1",
    "char": "🇳🇱",
    "name": "Flag Netherlands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Holanda",
      "Netherlands",
      "Países Bajos"
    ],
    "keywordsStr": "Bajos Holanda Netherlands Países bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1f1.svg"
  },
  {
    "codes": "1f1f3-1f1f4",
    "char": "🇳🇴",
    "name": "Flag Norway",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Noruega",
      "Norway"
    ],
    "keywordsStr": "Noruega Norway bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1f4.svg"
  },
  {
    "codes": "1f1f3-1f1f5",
    "char": "🇳🇵",
    "name": "Flag Nepal",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Nepal"
    ],
    "keywordsStr": "Nepal bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1f5.svg"
  },
  {
    "codes": "1f1f3-1f1f7",
    "char": "🇳🇷",
    "name": "Flag Nauru",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Nauru"
    ],
    "keywordsStr": "Nauru bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1f7.svg"
  },
  {
    "codes": "1f1f3-1f1fa",
    "char": "🇳🇺",
    "name": "Flag Niue",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Niu",
      "Niue"
    ],
    "keywordsStr": "Niu Niue bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1fa.svg"
  },
  {
    "codes": "1f1f3-1f1ff",
    "char": "🇳🇿",
    "name": "Flag New Zealand",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Novo",
      "New",
      "Nuevo"
    ],
    "keywordsStr": "New Novo Nuevo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f3-1f1ff.svg"
  },
  {
    "codes": "1f1f4-1f1f2",
    "char": "🇴🇲",
    "name": "Flag Oman",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Omã",
      "Oman",
      "Omán"
    ],
    "keywordsStr": "Oman Omán Omã bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f4-1f1f2.svg"
  },
  {
    "codes": "1f1f5-1f1e6",
    "char": "🇵🇦",
    "name": "Flag Panama",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Panamá",
      "Panama"
    ],
    "keywordsStr": "Panama Panamá bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1e6.svg"
  },
  {
    "codes": "1f1f5-1f1ea",
    "char": "🇵🇪",
    "name": "Flag Peru",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Peru",
      "Perú"
    ],
    "keywordsStr": "Peru Perú bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1ea.svg"
  },
  {
    "codes": "1f1f5-1f1eb",
    "char": "🇵🇫",
    "name": "Flag French Polynesia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "francês",
      "French",
      "francés"
    ],
    "keywordsStr": "French bandeira bandera country del do flag francés francês país",
    "imgUrl": "assets/emojis/1f1f5-1f1eb.svg"
  },
  {
    "codes": "1f1f5-1f1ec",
    "char": "🇵🇬",
    "name": "Flag Papua New Guinea",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Papua",
      "Papúa"
    ],
    "keywordsStr": "Papua Papúa bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1ec.svg"
  },
  {
    "codes": "1f1f5-1f1ed",
    "char": "🇵🇭",
    "name": "Flag Philippines",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Filipinas",
      "Philippines"
    ],
    "keywordsStr": "Filipinas Philippines bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1ed.svg"
  },
  {
    "codes": "1f1f5-1f1f0",
    "char": "🇵🇰",
    "name": "Flag Pakistan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Paquistão",
      "Pakistan",
      "Pakistán"
    ],
    "keywordsStr": "Pakistan Pakistán Paquistão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1f0.svg"
  },
  {
    "codes": "1f1f5-1f1f1",
    "char": "🇵🇱",
    "name": "Flag Poland",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Polônia",
      "Poland",
      "Polonia"
    ],
    "keywordsStr": "Poland Polonia Polônia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1f1.svg"
  },
  {
    "codes": "1f1f5-1f1f2",
    "char": "🇵🇲",
    "name": "Flag St Pierre Miquelon",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1f5-1f1f2.svg"
  },
  {
    "codes": "1f1f5-1f1f3",
    "char": "🇵🇳",
    "name": "Flag Pitcairn Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Pitcair",
      "Pitcairn"
    ],
    "keywordsStr": "Pitcair Pitcairn bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1f3.svg"
  },
  {
    "codes": "1f1f5-1f1f7",
    "char": "🇵🇷",
    "name": "Flag Puerto Rico",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Porto",
      "Puerto"
    ],
    "keywordsStr": "Porto Puerto bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1f7.svg"
  },
  {
    "codes": "1f1f5-1f1f8",
    "char": "🇵🇸",
    "name": "Flag Palestinian Territories",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Palestina",
      "Palestinian",
      "palestino"
    ],
    "keywordsStr": "Palestina Palestinian bandeira bandera country del do flag palestino país",
    "imgUrl": "assets/emojis/1f1f5-1f1f8.svg"
  },
  {
    "codes": "1f1f5-1f1f9",
    "char": "🇵🇹",
    "name": "Flag Portugal",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Portugal"
    ],
    "keywordsStr": "Portugal bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1f9.svg"
  },
  {
    "codes": "1f1f5-1f1fc",
    "char": "🇵🇼",
    "name": "Flag Palau",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Palaú",
      "Palau",
      "Palaos"
    ],
    "keywordsStr": "Palaos Palau Palaú bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1fc.svg"
  },
  {
    "codes": "1f1f5-1f1fe",
    "char": "🇵🇾",
    "name": "Flag Paraguay",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Paraguai",
      "Paraguay"
    ],
    "keywordsStr": "Paraguai Paraguay bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f5-1f1fe.svg"
  },
  {
    "codes": "1f1f6-1f1e6",
    "char": "🇶🇦",
    "name": "Flag Qatar",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Catar",
      "Qatar"
    ],
    "keywordsStr": "Catar Qatar bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f6-1f1e6.svg"
  },
  {
    "codes": "1f1f7-1f1ea",
    "char": "🇷🇪",
    "name": "Flag Reunion",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Reunião",
      "Reunion",
      "Reunión"
    ],
    "keywordsStr": "Reunion Reunião Reunión bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f7-1f1ea.svg"
  },
  {
    "codes": "1f1f7-1f1f4",
    "char": "🇷🇴",
    "name": "Flag Romania",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Romênia",
      "Romania",
      "Rumania"
    ],
    "keywordsStr": "Romania Romênia Rumania bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f7-1f1f4.svg"
  },
  {
    "codes": "1f1f7-1f1f8",
    "char": "🇷🇸",
    "name": "Flag Serbia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sérvia",
      "Serbia"
    ],
    "keywordsStr": "Serbia Sérvia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f7-1f1f8.svg"
  },
  {
    "codes": "1f1f7-1f1fa",
    "char": "🇷🇺",
    "name": "Flag Russia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Rússia",
      "Russia",
      "Rusia"
    ],
    "keywordsStr": "Rusia Russia Rússia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f7-1f1fa.svg"
  },
  {
    "codes": "1f1f7-1f1fc",
    "char": "🇷🇼",
    "name": "Flag Rwanda",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ruanda",
      "Rwanda"
    ],
    "keywordsStr": "Ruanda Rwanda bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f7-1f1fc.svg"
  },
  {
    "codes": "1f1f8-1f1e6",
    "char": "🇸🇦",
    "name": "Flag Saudi Arabia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Saudita",
      "Saudi",
      "Arabia Saudita"
    ],
    "keywordsStr": "Arabia Saudi Saudita bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1e6.svg"
  },
  {
    "codes": "1f1f8-1f1e7",
    "char": "🇸🇧",
    "name": "Flag Solomon Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Salomão",
      "Solomon",
      "Salomón"
    ],
    "keywordsStr": "Salomão Salomón Solomon bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1e7.svg"
  },
  {
    "codes": "1f1f8-1f1e8",
    "char": "🇸🇨",
    "name": "Flag Seychelles",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Seychelles"
    ],
    "keywordsStr": "Seychelles bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1e8.svg"
  },
  {
    "codes": "1f1f8-1f1e9",
    "char": "🇸🇩",
    "name": "Flag Sudan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sudão",
      "Sudan",
      "Sudán"
    ],
    "keywordsStr": "Sudan Sudán Sudão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1e9.svg"
  },
  {
    "codes": "1f1f8-1f1ea",
    "char": "🇸🇪",
    "name": "Flag Sweden",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Suécia",
      "Sweden",
      "Suecia"
    ],
    "keywordsStr": "Suecia Suécia Sweden bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1ea.svg"
  },
  {
    "codes": "1f1f8-1f1ec",
    "char": "🇸🇬",
    "name": "Flag Singapore",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Singapura",
      "Singapore",
      "Singapur"
    ],
    "keywordsStr": "Singapore Singapur Singapura bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1ec.svg"
  },
  {
    "codes": "1f1f8-1f1ed",
    "char": "🇸🇭",
    "name": "Flag St Helena",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1f8-1f1ed.svg"
  },
  {
    "codes": "1f1f8-1f1ee",
    "char": "🇸🇮",
    "name": "Flag Slovenia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Eslovênia",
      "Slovenia",
      "Eslovenia"
    ],
    "keywordsStr": "Eslovenia Eslovênia Slovenia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1ee.svg"
  },
  {
    "codes": "1f1f8-1f1ef",
    "char": "🇸🇯",
    "name": "Flag Svalbard Jan Mayen",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Svalbardo",
      "Svalbard"
    ],
    "keywordsStr": "Svalbard Svalbardo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1ef.svg"
  },
  {
    "codes": "1f1f8-1f1f0",
    "char": "🇸🇰",
    "name": "Flag Slovakia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Eslováquia",
      "Slovakia",
      "Eslovaquia"
    ],
    "keywordsStr": "Eslovaquia Eslováquia Slovakia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f0.svg"
  },
  {
    "codes": "1f1f8-1f1f1",
    "char": "🇸🇱",
    "name": "Flag Sierra Leone",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Serra",
      "Sierra"
    ],
    "keywordsStr": "Serra Sierra bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f1.svg"
  },
  {
    "codes": "1f1f8-1f1f2",
    "char": "🇸🇲",
    "name": "Flag San Marino",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "São",
      "San"
    ],
    "keywordsStr": "San São bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f2.svg"
  },
  {
    "codes": "1f1f8-1f1f3",
    "char": "🇸🇳",
    "name": "Flag Senegal",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Senegal"
    ],
    "keywordsStr": "Senegal bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f3.svg"
  },
  {
    "codes": "1f1f8-1f1f4",
    "char": "🇸🇴",
    "name": "Flag Somalia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Somália",
      "Somalia"
    ],
    "keywordsStr": "Somalia Somália bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f4.svg"
  },
  {
    "codes": "1f1f8-1f1f7",
    "char": "🇸🇷",
    "name": "Flag Suriname",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Suriname",
      "Surinam"
    ],
    "keywordsStr": "Surinam Suriname bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f7.svg"
  },
  {
    "codes": "1f1f8-1f1f8",
    "char": "🇸🇸",
    "name": "Flag South Sudan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sul",
      "South",
      "Sur"
    ],
    "keywordsStr": "South Sul Sur bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f8.svg"
  },
  {
    "codes": "1f1f8-1f1f9",
    "char": "🇸🇹",
    "name": "Flag Sao Tome Principe",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "São"
    ],
    "keywordsStr": "São bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1f9.svg"
  },
  {
    "codes": "1f1f8-1f1fb",
    "char": "🇸🇻",
    "name": "Flag El Salvador",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "El"
    ],
    "keywordsStr": "El bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1fb.svg"
  },
  {
    "codes": "1f1f8-1f1fd",
    "char": "🇸🇽",
    "name": "Flag Sint Maarten",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sinto",
      "Sint",
      "santo"
    ],
    "keywordsStr": "Sint Sinto bandeira bandera country del do flag país santo",
    "imgUrl": "assets/emojis/1f1f8-1f1fd.svg"
  },
  {
    "codes": "1f1f8-1f1fe",
    "char": "🇸🇾",
    "name": "Flag Syria",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Síria",
      "Syria",
      "Siria"
    ],
    "keywordsStr": "Siria Syria Síria bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1fe.svg"
  },
  {
    "codes": "1f1f8-1f1ff",
    "char": "🇸🇿",
    "name": "Flag Eswatini",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Eswatini",
      "Esuatini"
    ],
    "keywordsStr": "Esuatini Eswatini bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f8-1f1ff.svg"
  },
  {
    "codes": "1f1f9-1f1e6",
    "char": "🇹🇦",
    "name": "Flag Tristan Da Cunha",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tristão",
      "Tristan",
      "Tristán"
    ],
    "keywordsStr": "Tristan Tristán Tristão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1e6.svg"
  },
  {
    "codes": "1f1f9-1f1e8",
    "char": "🇹🇨",
    "name": "Flag Turks Caicos Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Turcos",
      "Turks",
      "turcos"
    ],
    "keywordsStr": "Turcos Turks bandeira bandera country del do flag país turcos",
    "imgUrl": "assets/emojis/1f1f9-1f1e8.svg"
  },
  {
    "codes": "1f1f9-1f1e9",
    "char": "🇹🇩",
    "name": "Flag Chad",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Chade",
      "Chad"
    ],
    "keywordsStr": "Chad Chade bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1e9.svg"
  },
  {
    "codes": "1f1f9-1f1eb",
    "char": "🇹🇫",
    "name": "Flag French Southern Territories",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "francês",
      "French",
      "francés"
    ],
    "keywordsStr": "French bandeira bandera country del do flag francés francês país",
    "imgUrl": "assets/emojis/1f1f9-1f1eb.svg"
  },
  {
    "codes": "1f1f9-1f1ec",
    "char": "🇹🇬",
    "name": "Flag Togo",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Togo"
    ],
    "keywordsStr": "Togo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1ec.svg"
  },
  {
    "codes": "1f1f9-1f1ed",
    "char": "🇹🇭",
    "name": "Flag Thailand",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tailândia",
      "Thailand",
      "Tailandia"
    ],
    "keywordsStr": "Tailandia Tailândia Thailand bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1ed.svg"
  },
  {
    "codes": "1f1f9-1f1ef",
    "char": "🇹🇯",
    "name": "Flag Tajikistan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tajiquistão",
      "Tajikistan",
      "Tayikistán"
    ],
    "keywordsStr": "Tajikistan Tajiquistão Tayikistán bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1ef.svg"
  },
  {
    "codes": "1f1f9-1f1f0",
    "char": "🇹🇰",
    "name": "Flag Tokelau",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Toquela",
      "Tokelau"
    ],
    "keywordsStr": "Tokelau Toquela bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f0.svg"
  },
  {
    "codes": "1f1f9-1f1f1",
    "char": "🇹🇱",
    "name": "Flag Timor Leste",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Timor-Leste",
      "Timor Oriental"
    ],
    "keywordsStr": "Oriental Timor Timor-Leste bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f1.svg"
  },
  {
    "codes": "1f1f9-1f1f2",
    "char": "🇹🇲",
    "name": "Flag Turkmenistan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Turquemenistão",
      "Turkmenistan",
      "Turkmenistán"
    ],
    "keywordsStr": "Turkmenistan Turkmenistán Turquemenistão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f2.svg"
  },
  {
    "codes": "1f1f9-1f1f3",
    "char": "🇹🇳",
    "name": "Flag Tunisia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tunísia",
      "Tunisia",
      "Túnez"
    ],
    "keywordsStr": "Tunisia Tunísia Túnez bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f3.svg"
  },
  {
    "codes": "1f1f9-1f1f4",
    "char": "🇹🇴",
    "name": "Flag Tonga",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tonga"
    ],
    "keywordsStr": "Tonga bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f4.svg"
  },
  {
    "codes": "1f1f9-1f1f7",
    "char": "🇹🇷",
    "name": "Flag Turkey",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Turquia",
      "Turkey",
      "Turquía"
    ],
    "keywordsStr": "Turkey Turquia Turquía bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f7.svg"
  },
  {
    "codes": "1f1f9-1f1f9",
    "char": "🇹🇹",
    "name": "Flag Trinidad Tobago",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Trindade",
      "Trinidad"
    ],
    "keywordsStr": "Trindade Trinidad bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1f9.svg"
  },
  {
    "codes": "1f1f9-1f1fb",
    "char": "🇹🇻",
    "name": "Flag Tuvalu",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tuval",
      "Tuvalu"
    ],
    "keywordsStr": "Tuval Tuvalu bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1fb.svg"
  },
  {
    "codes": "1f1f9-1f1fc",
    "char": "🇹🇼",
    "name": "Flag Taiwan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Taiwan",
      "Taiwán"
    ],
    "keywordsStr": "Taiwan Taiwán bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1fc.svg"
  },
  {
    "codes": "1f1f9-1f1ff",
    "char": "🇹🇿",
    "name": "Flag Tanzania",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Tanzânia",
      "Tanzania"
    ],
    "keywordsStr": "Tanzania Tanzânia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1f9-1f1ff.svg"
  },
  {
    "codes": "1f1fa-1f1e6",
    "char": "🇺🇦",
    "name": "Flag Ukraine",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Ucrânia",
      "Ukraine",
      "Ucrania"
    ],
    "keywordsStr": "Ucrania Ucrânia Ukraine bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1e6.svg"
  },
  {
    "codes": "1f1fa-1f1ec",
    "char": "🇺🇬",
    "name": "Flag Uganda",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Uganda"
    ],
    "keywordsStr": "Uganda bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1ec.svg"
  },
  {
    "codes": "1f1fa-1f1f2",
    "char": "🇺🇲",
    "name": "Flag US Outlying Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "EUA",
      "U.S.",
      "NOSOTROS"
    ],
    "keywordsStr": "EUA NOSOTROS U.S. bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1f2.svg"
  },
  {
    "codes": "1f1fa-1f1f3",
    "char": "🇺🇳",
    "name": "Flag United Nations",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Unidos",
      "United"
    ],
    "keywordsStr": "Unidos United bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1f3.svg"
  },
  {
    "codes": "1f1fa-1f1f8",
    "char": "🇺🇸",
    "name": "Flag United States",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Unidos",
      "United"
    ],
    "keywordsStr": "Unidos United bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1f8.svg"
  },
  {
    "codes": "1f1fa-1f1fe",
    "char": "🇺🇾",
    "name": "Flag Uruguay",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Uruguai",
      "Uruguay"
    ],
    "keywordsStr": "Uruguai Uruguay bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1fe.svg"
  },
  {
    "codes": "1f1fa-1f1ff",
    "char": "🇺🇿",
    "name": "Flag Uzbekistan",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Uzbequistão",
      "Uzbekistan",
      "Uzbekistán"
    ],
    "keywordsStr": "Uzbekistan Uzbekistán Uzbequistão bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fa-1f1ff.svg"
  },
  {
    "codes": "1f1fb-1f1e6",
    "char": "🇻🇦",
    "name": "Flag Vatican City",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Vaticano",
      "Vatican"
    ],
    "keywordsStr": "Vatican Vaticano bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1e6.svg"
  },
  {
    "codes": "1f1fb-1f1e8",
    "char": "🇻🇨",
    "name": "Flag St Vincent Grenadines",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "St.",
      "S t."
    ],
    "keywordsStr": "S St bandeira bandera country del do flag país t.",
    "imgUrl": "assets/emojis/1f1fb-1f1e8.svg"
  },
  {
    "codes": "1f1fb-1f1ea",
    "char": "🇻🇪",
    "name": "Flag Venezuela",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Venezuela"
    ],
    "keywordsStr": "Venezuela bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1ea.svg"
  },
  {
    "codes": "1f1fb-1f1ec",
    "char": "🇻🇬",
    "name": "Flag British Virgin Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "britânico",
      "British",
      "británico"
    ],
    "keywordsStr": "British bandeira bandera británico britânico country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1ec.svg"
  },
  {
    "codes": "1f1fb-1f1ee",
    "char": "🇻🇮",
    "name": "Flag Us Virgin Islands",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "EUA",
      "U.S.",
      "NOSOTROS"
    ],
    "keywordsStr": "EUA NOSOTROS U.S. bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1ee.svg"
  },
  {
    "codes": "1f1fb-1f1f3",
    "char": "🇻🇳",
    "name": "Flag Vietnam",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Vietnã",
      "Vietnam"
    ],
    "keywordsStr": "Vietnam Vietnã bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1f3.svg"
  },
  {
    "codes": "1f1fb-1f1fa",
    "char": "🇻🇺",
    "name": "Flag Vanuatu",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Vanuatu"
    ],
    "keywordsStr": "Vanuatu bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fb-1f1fa.svg"
  },
  {
    "codes": "1f1fc-1f1eb",
    "char": "🇼🇫",
    "name": "Flag Wallis Futuna",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Wallis"
    ],
    "keywordsStr": "Wallis bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fc-1f1eb.svg"
  },
  {
    "codes": "1f1fc-1f1f8",
    "char": "🇼🇸",
    "name": "Flag Samoa",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Samoa"
    ],
    "keywordsStr": "Samoa bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fc-1f1f8.svg"
  },
  {
    "codes": "1f1fd-1f1f0",
    "char": "🇽🇰",
    "name": "Flag Kosovo",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Kosovo"
    ],
    "keywordsStr": "Kosovo bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fd-1f1f0.svg"
  },
  {
    "codes": "1f1fe-1f1ea",
    "char": "🇾🇪",
    "name": "Flag Yemen",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Iêmen",
      "Yemen"
    ],
    "keywordsStr": "Iêmen Yemen bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fe-1f1ea.svg"
  },
  {
    "codes": "1f1fe-1f1f9",
    "char": "🇾🇹",
    "name": "Flag Mayotte",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Maiote",
      "Mayotte",
      "Mayote"
    ],
    "keywordsStr": "Maiote Mayote Mayotte bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1fe-1f1f9.svg"
  },
  {
    "codes": "1f1ff-1f1e6",
    "char": "🇿🇦",
    "name": "Flag South Africa",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Sul",
      "South",
      "Sur"
    ],
    "keywordsStr": "South Sul Sur bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ff-1f1e6.svg"
  },
  {
    "codes": "1f1ff-1f1f2",
    "char": "🇿🇲",
    "name": "Flag Zambia",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Zâmbia",
      "Zambia"
    ],
    "keywordsStr": "Zambia Zâmbia bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ff-1f1f2.svg"
  },
  {
    "codes": "1f1ff-1f1fc",
    "char": "🇿🇼",
    "name": "Flag Zimbabwe",
    "category": "Flags (country-flag)",
    "group": "flags",
    "subgroup": "country-flag",
    "keywords": [
      "país",
      "country",
      "bandeira",
      "flag",
      "bandera",
      "bandeira do país",
      "country flag",
      "bandera del país",
      "Zimbábue",
      "Zimbabwe",
      "Zimbabue"
    ],
    "keywordsStr": "Zimbabue Zimbabwe Zimbábue bandeira bandera country del do flag país",
    "imgUrl": "assets/emojis/1f1ff-1f1fc.svg"
  },
  {
    "codes": "1f3f4-e0067-e0062-e0065-e006e-e0067-e007f",
    "char": "🏴󠁧󠁢󠁥󠁮󠁧󠁿",
    "name": "Flag England",
    "category": "Flags (subdivision-flag)",
    "group": "flags",
    "subgroup": "subdivision-flag",
    "keywords": [
      "subdivisão",
      "subdivision",
      "subdivisión",
      "bandeira",
      "flag",
      "bandera",
      "bandeira de subdivisão",
      "subdivision flag",
      "bandera de subdivisión",
      "Inglaterra",
      "England"
    ],
    "keywordsStr": "England Inglaterra bandeira bandera de flag subdivision subdivisión subdivisão",
    "imgUrl": "assets/emojis/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg"
  },
  {
    "codes": "1f3f4-e0067-e0062-e0073-e0063-e0074-e007f",
    "char": "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
    "name": "Flag Scotland",
    "category": "Flags (subdivision-flag)",
    "group": "flags",
    "subgroup": "subdivision-flag",
    "keywords": [
      "subdivisão",
      "subdivision",
      "subdivisión",
      "bandeira",
      "flag",
      "bandera",
      "bandeira de subdivisão",
      "subdivision flag",
      "bandera de subdivisión",
      "Escócia",
      "Scotland",
      "Escocia"
    ],
    "keywordsStr": "Escocia Escócia Scotland bandeira bandera de flag subdivision subdivisión subdivisão",
    "imgUrl": "assets/emojis/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg"
  },
  {
    "codes": "1f3f4-e0067-e0062-e0077-e006c-e0073-e007f",
    "char": "🏴󠁧󠁢󠁷󠁬󠁳󠁿",
    "name": "Flag Wales",
    "category": "Flags (subdivision-flag)",
    "group": "flags",
    "subgroup": "subdivision-flag",
    "keywords": [
      "subdivisão",
      "subdivision",
      "subdivisión",
      "bandeira",
      "flag",
      "bandera",
      "bandeira de subdivisão",
      "subdivision flag",
      "bandera de subdivisión",
      "País de Gales",
      "Wales",
      "Gales"
    ],
    "keywordsStr": "Gales País Wales bandeira bandera de flag subdivision subdivisión subdivisão",
    "imgUrl": "assets/emojis/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg"
  }
]
