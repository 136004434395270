import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ElectronService } from '@core/services';
import { LINUX_DOWNLOAD_URL, WINDOWS_DOWNLOAD_URL } from '@shared/constants';

type Data = {
  version: string;
  newVersion: string;
}

@Component({
  selector: 'app-dialog-force-update',
  templateUrl: './dialog-force-update.component.html',
  styleUrls: ['./dialog-force-update.component.scss']
})
export class DialogForceUpdateComponent implements OnInit {

  constructor(
    private electron: ElectronService,
    public dialogRef: MatDialogRef<DialogForceUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void {
  }

  /**
   * Download New Desktop Version
   */
  public downloadNewDesktopVersion(): void {
    // Get download link
    const link = this.electron.isWindows ? WINDOWS_DOWNLOAD_URL : LINUX_DOWNLOAD_URL;

    // Open download link
    window.open(link, '_blank');
  }

}
