import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ClusterService,
  DesktopService,
  ElectronService,
  JitsiService,
  MatrixService,
  MeetingsService,
  RoomService,
  TenantService,
  UnreadService,
  UserMediaService,
  UserPresenceService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CALL_EVENT } from '@shared/constants';
import { CallEventTypes, MatrixNotificationType } from '@shared/enums';
import {
  getCurrentPushRule,
  getMsgType,
  getNotiType,
  getTheLastVisitedRoom,
  isDMRoom,
  isEditedEvent,
  isMeetGroup,
  isRoomMuted,
  parseReply,
  removeHTMLAndMarkdown
} from '@shared/utils';
import {
  ConditionKind,
  EventType,
  MatrixClient,
  MatrixEvent,
  MsgType,
  PushRuleActionName,
  PushRuleKind,
  Room,
  TweakName
} from 'matrix-js-sdk';
import { SyncState } from 'matrix-js-sdk/lib/sync';
import { Subscription } from 'rxjs';

type NotiOptions = {
  title: string;
  eventType: EventType;
  options: NotificationOptions
  roomId?: string;
  disableRedirect?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private matrixClient: MatrixClient;
  private supportEventTypes = [
    EventType.RoomCreate,
    EventType.RoomMessage,
    EventType.RoomMessageEncrypted,
    EventType.RoomMember,
    EventType.Sticker,
    CALL_EVENT
  ];
  public unreadMessages: number = 0;
  private initDate: number;
  private matrixEventsSubs: Subscription;

  constructor(
    private router: Router,
    private roomService: RoomService,
    private unreadService: UnreadService,
    private meetService: MeetingsService,
    private userMediaService: UserMediaService,
    private matrixService: MatrixService,
    private tenantService: TenantService,
    private electronService: ElectronService,
    private translate: TranslateService,
    private desktopService: DesktopService,
    private userPresenceService: UserPresenceService,
    private jitsiService: JitsiService,
    private clusterService: ClusterService,
  ) {
    if (this.tenantService.canShowChat()) this.init();
    this.initDate = new Date().getTime();
  }

  private async init(): Promise<void> {
    // Set environment variables
    await this.setCollaborationEnv();

    // Init matrix client
    this.matrixClient = await this.matrixService.initClient();

    // Listen events
    if (this.electronService.isElectronApp) {
      this.electronService.ipcRenderer.on(
        'navigateToRoom',
        (evt, notContent: NotiOptions) => {
          // Get notification content
          const { roomId, eventType } = notContent;

          // Get room
          const room = this.matrixClient.getRoom(roomId);

          // Navigate to room
          this.navigateToRoom(room, eventType);

          // Update unread messages
          this.unreadMessages--;

          // Update Badge
          this.desktopService.updateBadge(this.unreadMessages);
        }
      );
    }
  }

  /**
   * Set collaboration environment variables (matrixUrl, jitsiDomain)
   */
  private async setCollaborationEnv(): Promise<void> {
    // Get tenant (company)
    const { collaborationClusterId } = this.tenantService.tenant;

    // Check if tenant has collaboration cluster
    if (!collaborationClusterId) return;

    // Get collaboration cluster by ID and update environment variables
    const cluster = await this.clusterService.getCollaborationClusterById(collaborationClusterId);

    // Update environment variables
    environment.matrixUrl = this.getDomain(cluster.matrixDomain, true);
    environment.jitsiDomain = this.getDomain(cluster.jitsiDomain);
  }

  /**
   * Get domain with/without https://
   * @param {string} domain - Domain
   * @param {boolean} withHttps - If true, return domain with https://
   * @returns {string} - Domain with/without https://
   */
  private getDomain(domain: string, withHttps: boolean = false): string {
    if (withHttps) {
      return domain.startsWith('https') ? domain : `https://${domain}`;
    } else {
      return domain.startsWith('https') ? domain.replace('https://', '') : domain;
    }
  }

  /**
   * Listen events to notify
   */
  public listeningAllMEvents(): void {
    // Return if already listening
    if (this.matrixEventsSubs) return;

    // Log
    console.log('Listening events to notify');

    // Listening events if matrix client is ready
    this.matrixEventsSubs = this.matrixService
      .allEvents
      .subscribe((mEvent: MatrixEvent) => {
        this.listenRoomTimeline(mEvent);
      })
  }

  /**
   * Send electron notification
   * @param {MatrixEvent} mEvent Matrix Event
   * @param {Room} room Room
   */
  private sendElectronNotification(mEvent: MatrixEvent, room: Room): void {
    // Check if can show notification
    if (this.canDisplayNotification()) {
      // Get notification options
      const { title, options, eventType } = this.getNotificationOptions(mEvent, room, true);

      // Set notification data
      const msgNotiOptions: NotiOptions = {
        title,
        roomId: mEvent.getRoomId(),
        disableRedirect: false,
        options,
        eventType,
      };

      // Send notifications to desktop
      this.electronService.ipcRenderer?.send('messageNotification', msgNotiOptions);
    }

    // Get unread messages count
    this.unreadMessages = this.unreadService.getUnreadChatsCount();

    // Update Badge
    this.desktopService.updateBadge(this.unreadMessages);
  }

  /**
   * Check if can show notification
   * @returns {boolean} True if can show notification
   */
  public canDisplayNotification(): boolean {
    return !(this.userPresenceService.isUserInPresentation() || this.userPresenceService.isUserNotDisturb());
  }

  /**
   * Display notification
   * @param {MatrixEvent} mEvent Matrix Event
   * @param {Room} room Room
   */
  private displayPopupNoti(mEvent: MatrixEvent, room: Room): void {
    // Get push actions to this event
    const actions = this.matrixClient.getPushActionsForEvent(mEvent);

    // Check if can show notification
    switch (true) {
      case !actions?.notify: // Can't notify
      case mEvent.isEncrypted(): // Encrypted event
      case !this.canShowNotification(room): // Can't show notification
        return;

      // Desktop app
      case this.electronService.isElectronApp:
        this.sendElectronNotification(mEvent, room);
        return;

      // Notify
      default:
        // Check if can show notification
        if (!this.canDisplayNotification()) return;

        // Get notification options
        const { title, options, eventType } = this.getNotificationOptions(mEvent, room, true);

        // Set notification data
        const noti = new window.Notification(title, options);

        // Notification Actions
        noti.onshow = () => this.playNotiSound();
        noti.onclick = () => this.navigateToRoom(room, eventType);
        break;
    }
  }


  /**
   * Get Notification Options
   * @param {MatrixEvent} mEvent Matrix Event
   * @param {Room} room Room
   * @param {boolean} removeHTML Remove HTML from body
   * @returns {NotiOptions} Notification options with title
   */
  private getNotificationOptions(mEvent: MatrixEvent, room: Room, removeHTML: boolean): NotiOptions {
    // Init flags
    const isAGroup = !isDMRoom(room);
    const displayName = mEvent.getContent().displayname;
    const eventType = mEvent.getType() as EventType;
    let title: string;
    let body: string;
    let icon: string;

    switch (eventType) {
      // Normal messages
      case EventType.RoomMessage:
        // Set body content
        body = this.getNotificationBody(mEvent, isAGroup, removeHTML);

        // Set icon content
        icon = isAGroup
          ? this.roomService.getGroupAvatar(mEvent.getRoomId())
          : mEvent.getContent().avatar_url || 'assets/images/avatar.png';

        // Set title content
        title = isAGroup
          ? room.name
          : displayName;
        break;

      // Call events
      case CALL_EVENT:
        // Set title content
        title = isAGroup
          ? room.name
          : displayName;

        // Set body content
        body = isMeetGroup(room)
          ? `${displayName} ${this.translate.instant('startAMeet')}`
          : `${displayName} ${this.translate.instant('startACall')}`;

        // If is not a group - remove display name from body - Put the first letter in uppercase
        if (!isAGroup) {
          body = body.replace(`${displayName} `, '');
          body = body.charAt(0).toUpperCase() + body.slice(1);
        }

        // Set icon content
        icon = isAGroup
          ? this.roomService.getGroupAvatar(mEvent.getRoomId())
          : mEvent.getContent().avatar_url || 'assets/images/avatar.png';
        break;

      default:
        break;
    }

    // Return notification options
    return {
      title,
      eventType,
      options: {
        body,
        icon,
        silent: false,
      }
    }
  }

  /**
   * Get notification body
   * @param {MatrixEvent} mEvent Matrix Event
   * @param {boolean} isAGroup True if room is a group
   * @param {boolean} removeHTML True if remove HTML tags
   */
  public getNotificationBody(
    mEvent: MatrixEvent,
    isAGroup: boolean,
    removeHTML: boolean
  ): string {
    // Init body msg
    let msg = mEvent?.getContent()?.body;

    // Get display name
    const displayName = mEvent?.getContent()?.displayname;

    // Check Msg type
    switch (getMsgType(mEvent)) {
      // Text msg
      case MsgType.Text:
        // Init msg body
        msg = !!mEvent?.replyEventId ? parseReply(msg).body : msg;

        // Set body content if is a group
        msg = isAGroup ? `${displayName}: ${msg}` : msg;

        // Check if has only images on msg content
        if (mEvent?.getContent()?.hasOnlyImages)
          msg = `${displayName} ${this.translate.instant('sentAnImage')}`
        break;

      // Gif | Imgs
      case MsgType.Image:
        // Form msg
        msg = msg?.toLowerCase()?.includes('.gif')
          ? `${displayName} ${this.translate.instant('sentAGif')}`
          : `${displayName} ${this.translate.instant('sentAnImage')}`;

        break;

      // Audio
      case MsgType.Audio:
        // Form msg
        msg = `${displayName} ${this.translate.instant('sentAnAudio')}`;
        break;

      // File
      case MsgType.File:
        // Form msg
        msg = `${displayName} ${this.translate.instant('sentAFile')}`;
        break;

      // Video
      case MsgType.Video:
        // Form msg
        msg = `${displayName} ${this.translate.instant('sentAVideo')}`;
        break;
    }

    // Remove html and markdown
    if (removeHTML) msg = removeHTMLAndMarkdown(msg);

    // Return result
    return msg;
  }


  /**
   * Check if can show notification
   * @param {Room} room Room
   * @returns {boolean} True if can show notification
   */
  private canShowNotification(room: Room): boolean {
    // Check if room is in focus
    return !(getTheLastVisitedRoom() === room.roomId
      && document.visibilityState === 'visible'
      && window.location.href.includes('/#/chat')
      && window.document.hasFocus())
  }

  /**
   * Navigate to the room
   * @param {Room} room Room that is the owner of the event
   */
  private navigateToRoom(room: Room, eventType: EventType = EventType.RoomMessage) {
    // Check if is Meet
    const isMeet = isMeetGroup(room);

    // Get room id
    const roomId = room.roomId;

    // Navigate to chat
    this.router.navigate(['/chat']);

    // Init call or if is call event
    if (eventType === CALL_EVENT) isMeet
      ? this.meetService.initJitsiMeet(roomId, room.name)
      : this.jitsiService.initGroupCall(roomId);

    // Update room
    this.roomService.updateRoom(roomId, this.roomService.isRoomAGroup(room.roomId));

    // Set window in focus
    window.focus();
  }

  private playNotiSound(): void {
    this.userMediaService.notificationSound();
  }

  private isNotifEvent(mEvent): boolean {
    const eType = mEvent.getType();
    if (!this.supportEventTypes.includes(eType)) return false;
    if (eType === EventType.RoomMember) return false;

    if (mEvent.isRedacted()) return false;
    if (isEditedEvent(mEvent)) return false;

    return true;
  }

  /**
   * Listen incoming events
   * @param {MatrixEvent} mEvent Matrix Event
   */
  public listenRoomTimeline(mEvent: MatrixEvent): void {
    // Valid events type
    const validEvents = [EventType.RoomMessage, CALL_EVENT];

    // Get room from this event
    const room = this.matrixClient.getRoom(mEvent.getRoomId());

    // Get event type
    const eventType = mEvent.getType() as EventType;

    switch (true) {
      // Cannot notify
      case this.matrixClient.getSyncState() !== SyncState.Syncing: // Is matrix not ready
      case mEvent.getSender() === this.matrixClient.getUserId(): // Check if is your own msg
      case this.initDate > mEvent.getDate()?.getTime(): // Is an old event
      case !validEvents.includes(eventType): // Is a invalid type
      case mEvent?.getContent()?.type === CallEventTypes.HANGUP: // Is a HANGUP event
      case room.isSpaceRoom(): // Is room a space
      case !this.isNotifEvent(mEvent): // Check if is enable to notify
        break;

      // Notify
      default:
        this.displayPopupNoti(mEvent, room);
        break;
    }
  }

  /**
   * Check if room is muted
   * @param {string} roomId Room id
   * @returns {boolean} True if room is muted
   */
  public isRoomMuted(roomId: string): boolean {
    return isRoomMuted(this.matrixClient, roomId)
  }

  /**
   * Get current notification type for this room
   * @param {string} roomId Room id
   * @returns {MatrixNotificationType} Current notification type
   */
  public getCurrentNotificationType(roomId: string): MatrixNotificationType {
    // Get room notification type
    return getNotiType(this.matrixClient, roomId);
  }

  /**
   * Set room notification type
   * @param {string} roomId Room id
   * @param {MatrixNotificationType} type Notification type
   */
  public async setRoomNotificationType(roomId: string, type: MatrixNotificationType = MatrixNotificationType.DEFAULT) {
    const mx = this.matrixClient;
    const roomPushRule = getCurrentPushRule(mx, roomId);
    const promises = [];

    if (type === MatrixNotificationType.MUTE) {
      if (roomPushRule) {
        promises.push(mx.deletePushRule('global', PushRuleKind.RoomSpecific, roomPushRule.rule_id));
      }
      promises.push(mx.addPushRule('global', PushRuleKind.Override, roomId, {
        conditions: [
          {
            kind: ConditionKind.EventMatch,
            key: 'room_id',
            pattern: roomId,
          },
        ],
        actions: [
          PushRuleActionName.DontNotify,
        ],
      }));
      return promises;
    }

    const oldState = getNotiType(mx, roomId);

    if (oldState === MatrixNotificationType.MUTE) {
      promises.push(mx.deletePushRule('global', PushRuleKind.Override, roomId));
    }

    if (type === MatrixNotificationType.DEFAULT) {
      if (roomPushRule) {
        promises.push(mx.deletePushRule('global', PushRuleKind.RoomSpecific, roomPushRule.rule_id));
      }
      return Promise.all(promises);
    }

    // Mentions and keywords
    if (type === MatrixNotificationType.MENTIONS_AND_KEYWORDS) {
      promises.push(mx.addPushRule('global', PushRuleKind.RoomSpecific, roomId, {
        actions: [
          PushRuleActionName.DontNotify,
        ],
      }));
      promises.push(mx.setPushRuleEnabled('global', PushRuleKind.RoomSpecific, roomId, true));
      return Promise.all(promises);
    }

    // All messages
    promises.push(mx.addPushRule('global', PushRuleKind.RoomSpecific, roomId, {
      actions: [
        PushRuleActionName.Notify,
        {
          set_tweak: TweakName.Sound,
          value: 'default',
        },
      ],
    }));

    promises.push(mx.setPushRuleEnabled('global', PushRuleKind.RoomSpecific, roomId, true));

    return Promise.all(promises);
  }
}
