import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  Firestore,
  where
} from '@angular/fire/firestore';
import { FirestoreService, LcsEventsService } from '@core/services';
import { LcsEventUserTypes } from '@shared/enums';
import { Tenant, User } from '@shared/models';
import { getLSTenantId, getUserFunctionsPath, getUsersCollectionPath } from '@shared/utils';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private firestore: FirestoreService,
    private fs: Firestore,
    private http: HttpClient,
    private lcsEventsService: LcsEventsService,
  ) { }

  /**
   * Fetch all users from Firestore
   * @param {string} id tenant id
   * @returns {Promise<User[]>}
   */
  public fetchAll(id: string = getLSTenantId()): Observable<User[]> {
    const col = <CollectionReference<User>>(collection(this.fs, getUsersCollectionPath(id)));
    return collectionData<User>(col, { idField: 'id' });
  }

  /**
   * Update user data in Firestore
   * @param {object} data { [key: string]: any
   * @param {string} userId User id
   */
  public updateOnlyInFire(data: object, userId: string): Promise<void> {
    const path = getUsersCollectionPath(getLSTenantId(), userId);
    return this.firestore.updateDoc(path, data);
  }

  /**
   * Get user by email
   * @param {string} email User email
   * @param {string} tenantId Company id
   * @returns {Promise<User>} User
   */
  public getUserByEmail(email: string, tenantId: string): Promise<User> {
    const path = getUsersCollectionPath(tenantId);
    return this.firestore.getDoc<User>(path, [where('email', '==', email)]);
  }

  /**
   * Update user
   * @param {string} companyId Company id
   * @param {User} currentUser Current user data
   * @param {User} newUserData New user data
   * @returns {Promise<User>} User updated
   */
  public async update(companyId: string, currentUser: User, newUserData: User): Promise<void> {
    // Get the path to the user update endpoint
    const url = getUserFunctionsPath(companyId, currentUser.id);

    // Create request body
    const body = {
      tenantId: companyId,
      user: {
        ...newUserData,
        pbxId: newUserData.pbxId ?? '',
      },
      lcsEvent: this.lcsEventsService.baseLcsEvent({ type: LcsEventUserTypes.USER_UPDATE }),
    };

    // Return the request
    await lastValueFrom(this.http.put<User>(url, body));
  }

  /**
   * Create user on Identity Platform and Firestore
   * @param {Tenant} company Company data
   * @param {User} user User data
   * @returns {Promise<void>} Promise
   */
  public async create(company: Tenant, user: User): Promise<void> {
    // Get the path to the user creation endpoint
    const url = getUserFunctionsPath(company.id);

    // Create request body
    const body = {
      clusterId: company.clusterId,
      tenantId: company.id,
      user: {
        ...user,
        pbxId: user.pbxId ?? '',
      },
      lcsEvent: this.lcsEventsService.baseLcsEvent({ type: LcsEventUserTypes.USER_CREATION }),
    };

    // Return the request
    await lastValueFrom(this.http.post<any>(url, body));
  }

  /**
   * Delete user
   * @param {Tenant} company Company data
   * @param {User[]} users Users to delete
   */
  public async delete(company: Tenant, users: User[]): Promise<void> {
    // Get the path to the user deletion endpoint
    const url = getUserFunctionsPath(company.id);

    // Body
    const body = {
      clusterId: company.clusterId,
      tenantId: company.id,
      users,
      lcsEvent: this.lcsEventsService.baseLcsEvent({ type: LcsEventUserTypes.USER_DELETION }),
    };

    // Return the request
    await lastValueFrom(this.http.delete<void>(url, { body }));
  }


  /**
   * Import users
   * @param {Tenant} company Company data
   * @param {User[]} users Users to import
   */
  public importUsers(
    company: Tenant,
    users: User[]
  ): Promise<User[]> {
    // Get the path to the user import endpoint
    const url = getUserFunctionsPath(company.id) + '/import';

    // Create body
    const body = {
      tenantId: company.id,
      users,
      lcsEvent: this.lcsEventsService.baseLcsEvent({ type: LcsEventUserTypes.USER_IMPORT })
    };

    // Send the request to the endpoint
    return lastValueFrom(this.http.post<User[]>(url, body));
  }

  /**
  * Update tags in the user
  * @param {string} userId New Data
  * @param {string[]} tags New Data
  */
  public updateTags(userId: string, tags: string[]): Promise<void> {
    return this.updateOnlyInFire({ groups: tags }, userId);
  }
}
