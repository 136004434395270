import { ChatRoomType, FileTypes, MatrixMemberActions, MatrixRoomType, RoomFileType } from "@shared/enums";
import { EventType, IContent, MatrixEvent, MsgType, Room } from "matrix-js-sdk";
import { AiInputContent } from "./ai.model";
import { User } from "./user.model";

export interface PaginationTimelineResult {
  hasLoadedAllEvents: boolean,
  newEvents: MatrixEvent[]
}

export interface RoomFileOption {
  label: string;
  value: RoomFileType;
  icon: string;
}

export enum MatrixUserRole {
  Admin = 'admin',
  Moderator = 'moderator',
}

export interface FileResult {
  name: string;
  path: string;
  extension: string;
  type: FileTypes;
  createdAt: number;
  contentType: string;
  size: number;
}

export type InitRoomTypes = 'search' | 'chat' | 'contact' | 'forward';

export interface MatrixLoginCredentials {
  access_token: string;
  user_id: string;
  device_id: string;
}

export interface QuillDelta {
  ops: any[];
}

export interface QuillContentChangedEvent {
  editor: any, // Quill instance
  html: string; // HTML content
  text: string; // Text content
  content: QuillDelta; // Content
  delta: QuillDelta; // Delta content
  oldDelta: QuillDelta; // Old delta content
  source: ('user' | 'api' | 'silent' | undefined);
}

export interface MatrixCreateRoomOptions {
  name: string,
  joinRule: 'public' | 'invite' | 'restricted',
  topic?: string,
  usersId?: string[],
  parentId?: string,
  alias?: string,
  isSpace?: boolean,
  isEncrypted?: boolean,
  powerLevel?: number,
  blockFederation?: boolean,
}

export interface ReplyItem extends ReplyItemExtraHeaders {
  eventId: string;
  userId?: string;
  msgType?: string;
  replyMsg?: string;
}

export interface ReplyItemExtraHeaders {
  hasOnlyImages?: boolean;
}

export interface Markers {
  deleted: boolean;
  edited: boolean;
  forwarded: boolean;
}

export interface Reaction {
  user: string,
  emoji: string,
  event: MatrixEvent
}

export interface CreateRoomDialogResp {
  selectedContacts: User[],
  isGroup: boolean,
  groupName: string,
}

export interface RoomStatusChange {
  room: Room;
  type: ChatRoomType;
}

export interface FilterRooms {
  name: string,
  desc: string,
  isGroup: boolean,
  userId?: string,
  groupId?: string,
  type: MatrixRoomType
}

export interface UpdateMsg {
  content: string;
  update: boolean;
}

export type PlayerStatus = ('ended' | 'started')

export type PlayerInfo = {
  status: PlayerStatus;
  itemId: string;
}

export type Attachment = {
  file: File;
  url: string;
  isVisible: boolean;
}

export type InputCache = {
  content: any;
  attachments: Attachment[];
  aiContent: AiInputContent;
}


export interface TempTimelineEvent {
  user: User,
  eventId: string,
  content: IContent,
  type: EventType,
  msgtype?: MsgType,
  replyToEventId?: string,
}

export class TimelineItem {
  id: string;
  msg: string;
  msgType: MsgType;
  eventType: EventType;
  time: number;
  name: string;
  read: boolean;
  roomId: string;
  markers: Markers;
  replyTo: ReplyItem;
  mEvent: MatrixEvent;
  hasOnlyEmojis: boolean;
  isMemberAction: boolean;
  memberAction: MatrixMemberActions;
  content: IContent;
  isRecording: boolean;

  constructor(item: TimelineItem) {
    this.id = item.id;
    this.msg = item.msg;
    this.name = item.name;
    this.time = item.time;
    this.read = item.read;
    this.mEvent = item.mEvent;
    this.roomId = item.roomId;
    this.markers = item.markers;
    this.replyTo = item.replyTo;
    this.msgType = item.msgType;
    this.eventType = item.eventType;
    this.memberAction = item.memberAction;
    this.hasOnlyEmojis = item.hasOnlyEmojis;
    this.isMemberAction = item.isMemberAction;
    this.content = item.content;
    this.isRecording = item.isRecording;
  }

  /**
   * Check if was edited
   * @returns {boolean} True was edited
   */
  public wasEdited?(): boolean {
    return this.markers.edited;
  }

  /**
   * Check if was deleted
   * @returns {boolean} True was deleted
   */
  public wasDeleted?(): boolean {
    return this.markers.deleted;
  }

  /**
   * Has markers
   * @returns {boolean} True if has markers
   */
  public hasMarkers?(): boolean {
    return this.markers.edited || this.markers.forwarded;
  }

  /**
   * Is reply
   * @returns {boolean} True if is reply
   */
  public isReply?(): boolean {
    return !!this.replyTo;
  }

  /**
   * Get matrix event id
   * @returns {string} Matrix event id
   */
  public getMEventId?(): string {
    return this.mEvent?.getId();
  }
}
