export interface Cluster {
  id: string;
  domain: string;
  port: string;
  name: string;
  active: boolean;
}

export interface CollaborationCluster {
  id?: string;
  name: string;
  jitsiDomain: string;
  matrixDomain: string;
  active: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ExtensionMonitor {
  bigint: number;
  uuid: string;
  type: string;
  ipserver: string;
  event: string;
  iddomain: string;
  data: {
    peer?: string;
    peerstatus?: string;
    status?: string;
  }
}
