import { Injectable } from '@angular/core';
import { Database, get, onValue, ref, set } from '@angular/fire/database';
import { ReleaseDate } from '@shared/enums';
import { ReleaseDateData } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class HandleVersionService {
  constructor(private database: Database) { }

  async getVersion(): Promise<string> {
    return new Promise((resolve, reject) => {
      const databaseRef = ref(this.database, 'version');
      onValue(
        databaseRef,
        (snapshot) => {
          resolve(snapshot.val());
        },
        { onlyOnce: true }
      );
    });
  }

  async setVersion(version: string): Promise<void> {
    const databaseRef = ref(this.database, 'version');
    await set(databaseRef, version);
  }

  /**
   * Fetch Release Dates
   */
  public async getReleaseDates(): Promise<Map<ReleaseDate, ReleaseDateData>> {
    try {
      // Reference to release date
      const releaseDateRef = ref(this.database, 'releaseDate');

      // Get release dates
      const snapshot = await get(releaseDateRef);

      // Init Map release dates
      const releaseDatesMap = new Map<ReleaseDate, ReleaseDateData>();

      // Iterate over release dates
      snapshot.forEach((childSnapshot: any) => {
        const childKey = childSnapshot.key as ReleaseDate;
        const childData: ReleaseDateData = childSnapshot.val();
        releaseDatesMap.set(childKey, childData);
      });

      // Return release dates
      return releaseDatesMap;
    } catch (error) {
      console.error('Error fetching release dates:', error);
      return new Map<ReleaseDate, ReleaseDateData>();
    }
  }

  /**
   * Compare versions
   * @param {string} currentVersion Current version (ex: 1.0.0)
   * @param {string} latestVersion Latest version (ex: 1.0.1)
   * @returns {boolean} True if current version is lower than latest version
   */
  public compareVersions(currentVersion: string, latestVersion: string): boolean {
    // Split versions into parts
    const currentParts = currentVersion.split('.').map(Number);
    const latestParts = latestVersion.split('.').map(Number);

    // Get length of the longest version
    const length = Math.max(currentParts.length, latestParts.length);

    // Compare each part of the version
    for (let i = 0; i < length; i++) {
      // Get parts of the version
      const current = currentParts[i] || 0;
      const latest = latestParts[i] || 0;

      // Compare parts of the version
      if (current < latest) {
        return true;
      } else if (current > latest) {
        return false;
      }
      // Continue to next part
    }
    return false; // Versions are equal
  }
}
