import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoomService, TenantService, ThemeService, UserPresenceService, UtilsService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { UserPresence } from '@shared/enums';
import { User } from '@shared/models';
import { formMatrixFromUserId } from '@shared/utils';
import { Room } from 'matrix-js-sdk';
import { FullscreenViewComponent } from '../fullscreen-view/fullscreen-view.component';

type Data = {
  type: 'user' | 'group';
  user?: User;
  room?: Room;
  contacts?: User[];
}

@Component({
  selector: 'app-room-full-info-dialog',
  templateUrl: './room-full-info-dialog.component.html',
  styleUrls: ['./room-full-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomFullInfoDialogComponent implements OnInit {
  // Room Members
  public members: User[] = [];

  constructor(
    private dialog: MatDialog,
    private utils: UtilsService,
    private roomService: RoomService,
    private tenantService: TenantService,
    public themeService: ThemeService,
    private translate: TranslateService,
    private userPresenceService: UserPresenceService,
    public dialogRef: MatDialogRef<RoomFullInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) { }

  ngOnInit(): void {
    this.getMembers();
  }

  /**
   * Close dialog
   */
  public close(): void {
    this.dialogRef.close();
  }

  /**
   * Is room
   */
  public isRoom(): boolean {
    return this.data.type === 'group';
  }

  /**
   * Show img on full screen
   */
  public showImgOnFullScreen(): void {
    // Open dialog with full screen view
    this.dialog.open(FullscreenViewComponent, {
      panelClass: "full-screen-dialog",
      data: { url: this.getAvatar(), type: 'normal', disableActions: true }
    });

    // Close dialog
    this.dialogRef.close();
  }


  /**
   * Get user status
   * @returns {string} Status
   */
  public getUserStatus(): string {
    return this.userPresenceService.getUserStatus(this.data.user?.id).status;
  }

  /**
   * Get user extension status
   * @returns {string} Extension status
   */
  public getUserExtensionStatus(): string {
    return this.userPresenceService.getUserExtensionStatus(this.data.user?.id);
  }

  /**
   * Check if can show collaboration status
   */
  public canShowCollaborationStatus(): boolean {
    return (this.tenantService.canShowChat() || this.getUserStatus() == UserPresence.DONOTDISTURB)
      && this.getUserExtensionStatus() !== 'ExtensionOnMobile';
  }

  /**
   * Check if can show extension status
   */
  public canShowExtensionStatus(): boolean {
    return this.getUserStatus() !== UserPresence.DONOTDISTURB
  }

  /**
   * Can show tooltip icons
   */
  public canShowTooltipIcons(): boolean {
    return this.canShowCollaborationStatus() && this.canShowExtensionStatus();
  }
  /**
   * Get avatar
   */
  public getAvatar(): string {
    return this.isRoom()
      ? this.roomService.getGroupAvatar(this.data.room.roomId)
      : this.data.user?.photoUrl ?? 'assets/images/avatar.svg';
  }

  /**
   * Get display name
   * @returns {string} Display name
   */
  public getDisplayName(): string {
    return this.isRoom()
      ? this.data.room.name
      : this.data.user?.displayName;
  }

  /**
   * Get user email info
   */
  public getEmail(): string {
    return this.isRoom()
      ? `${this.members.length} ${this.translate.instant('members')}`
      : this.data.user?.email;
  }

  /**
   * Get members
   */
  public getMembers(): void {
    if (this.isRoom())
      this.members = this.roomService.getUsersFromMembers(this.data.room.roomId, this.data.contacts)
  }

  /**
   * Check if is room admin
   * @param {User} user User
   * @returns {boolean} True if is room admin
   */
  public isRoomAdmin(user: User): boolean {
    const mUserId = formMatrixFromUserId(user.id);
    return this.roomService.isAdmin(this.data.room.roomId, mUserId);
  }

  /**
   * Copy to clipboard
   * @param {string} value Value to copy
   */
  public copyToClipboard(value: string): void {
    this.utils.copy(value);
  }
}
