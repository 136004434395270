export enum UserPresence {
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  DONOTDISTURB = 'DoNotDisturb',
  IN_MEETING = 'InMeeting',
  IN_CALL = 'InCall',
  IN_PRESENTATION = 'InPresentation',
  AWAY = 'Away',
  BERIGHTBACK = 'BeRightBack',
  OFFLINE = 'Offline',
  ONLY_PUSH = 'OnlyPush'
}

export enum UserPresenceDevice {
  MOBILE = 'Mobile',
  DESKTOP = 'Desktop',
  WEB = 'Web'
}

export enum UserExtensionLocalPresence {
  // User triggers (manual)
  ExtensionUserAvailable = 'ExtensionUserAvailable', // Set by user
  ExtensionUserBusy = 'ExtensionUserBusy', // Set by user
  ExtensionUserDoNotDisturb = 'ExtensionUserDoNotDisturb', // Set by user
  ExtensionUserAway = 'ExtensionUserAway', // Set by user
  ExtensionUserBeRightBack = 'ExtensionUserBeRightBack', // Set by user
  ExtensionUserOffline = 'ExtensionUserOffline', // Set by user

  // User triggers (auto)
  ExtensionUserInCall = 'ExtensionUserInCall', // In call by user presence
  ExtensionUserInPresentation = 'ExtensionUserInPresentation', // In presentation by jitsi

  // Monitor triggers (cluster)
  ExtensionAvailable = 'ExtensionAvailable', // Available by monitor
  ExtensionInCall = 'ExtensionInCall', // In call by monitor
  ExtensionInPresentation = 'ExtensionInPresentation', // In presentation by monitor
  ExtensionOffline = 'ExtensionOffline', // Offline by monitor

  // Local triggers
  ExtensionOnlyPush = 'ExtensionOnlyPush', // User is only on mobile
  ExtensionFullOffline = 'ExtensionFullOffline', // User is full offline
}
