import { Injectable } from '@angular/core';
import {
  deleteObject, getBlob, getDownloadURL,
  ref,
  Storage, uploadBytes, UploadResult, uploadString
} from '@angular/fire/storage';
import { getLSUserId } from '@shared/utils';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storage: Storage) { }

  getBlob(path: string): Promise<Blob> {
    const storageRef = ref(this.storage, path);
    return getBlob(storageRef);
  }

  uploadBytes(path: string, file: Blob | Uint8Array | ArrayBuffer): Promise<UploadResult> {
    const storageRef = ref(this.storage, path);
    return uploadBytes(storageRef, file);
  }

  async uploadFile(path: string, file: any): Promise<void> {
    const storageRef = ref(this.storage, path);
    await uploadString(storageRef, file, 'data_url', {
      customMetadata: {
        author: getLSUserId(),
      },
    })
  }

  async removeFile(path: string): Promise<void> {
    try {
      const storageRef = ref(this.storage, path);
      await deleteObject(storageRef);
    } catch (error) {
      console.error(error);
    }
  }

  getDownloadURL(path: string): Promise<string> {
    const storageRef = ref(this.storage, path);
    return getDownloadURL(storageRef);
  }

  async uploadChatMedia(path: string, file: any): Promise<string> {
    // File to base64
    const reader = new FileReader();

    // Read file
    reader.readAsDataURL(file);

    // Wait for reader
    const resp = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    // Upload file
    await this.uploadFile(path, resp);

    // Return download url
    return this.getDownloadURL(path);
  }
}
