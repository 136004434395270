import { Component, Input, OnInit } from '@angular/core';
import { TenantService, ThemeService, UserPresenceService } from '@core/services';
import { UserExtensionLocalPresence, UserPresence } from '@shared/enums';
import { User } from '@shared/models';

@Component({
  selector: 'app-avatar-with-status',
  templateUrl: './avatar-with-status.component.html',
  styleUrls: ['./avatar-with-status.component.scss'],
})
export class AvatarWithStatusComponent implements OnInit {
  @Input() contact: User;
  @Input() isSmall: boolean = false;

  public isLoaded = false;
  public isTooltipVisible = false;
  public hasCollaboration = true;

  constructor(
    public tenantService: TenantService,
    public themeService: ThemeService,
    private userPresenceService: UserPresenceService,
  ) { }

  ngOnInit(): void {
    this.hasCollaboration = this.tenantService.canShowChat();
  }

  /**
   * Check if can show avatar
   * @param {boolean} isLoaded true if can show img
   */
  public loaded(isLoaded: boolean) {
    this.isLoaded = isLoaded;
  }

  /**
   * Get user status
   * @returns {UserPresence} Status
   */
  public getUserStatus(): UserPresence {
    return this.userPresenceService.getUserStatus(this.contact?.id).status;
  }

  /**
   * Get user extension status
   * @returns {UserExtensionLocalPresence} Extension status
   */
  public getUserExtensionStatus(): UserExtensionLocalPresence {
    return this.userPresenceService.getUserExtensionStatus(this.contact?.id);
  }

  /**
   * Check if can show collaboration status
   */
  public canShowCollaborationStatus(): boolean {
    return this.hasCollaboration;
  }

  /**
   * Check if can show extension status
   */
  public canShowExtensionStatus(): boolean {
    return (this.hasNoRegister() && !this.isOffline() && !this.isOnlyMobile()) || !this.hasCollaboration;
  }

  /**
   * Has no register
   */
  public hasNoRegister(): boolean {
    return this.getUserExtensionStatus() == UserExtensionLocalPresence.ExtensionOffline;
  }

  /**
   * Is Offline - User is offline
   * @returns {boolean} True if user is offline
   */
  public isOffline(): boolean {
    return this.getUserStatus() == UserPresence.OFFLINE;
  }

  /**
   * Is full offline
   */
  public isFullOffline(): boolean {
    return this.isOffline() && this.hasNoRegister();
  }

  /**
   * Is only mobile
   * @returns {boolean} True if user is only mobile
   */
  public isOnlyMobile(): boolean {
    return this.getUserExtensionStatus() == UserExtensionLocalPresence.ExtensionOnlyPush;
  }

  /**
   * Can show tooltip icons
   */
  public canShowTooltipIcons(): boolean {
    return this.canShowCollaborationStatus() && this.canShowExtensionStatus();
  }
}
