import { BASE_EVENTS_LOAD_LIMIT } from "@shared/constants";
import { EQUIPMENT_TYPES, PABX_INTEGRATION_OPTIONS } from "@shared/enums";
import { User } from "@shared/models";
import { getLSCompanyPABXIntegration } from "./common.storage";

var removeMd = require('remove-markdown-and-html');

// @ts-ignore
export const makeHotReload = () => window.location.reload(true);

/**
 * Remove HTML and markdown
 * @param {string} html HTML
 * @returns {string} Only text
 */
export const removeHTMLAndMarkdown = (html: string): string => {
  // Remove HTML
  html = html.replace(/<[^>]*>?/gm, '');

  // Remove &nbsp;
  html = html.replace(/&nbsp;/g, '');

  // Remove Markdown
  html = removeMd(html);

  // Parse &lt; and &gt to < and >
  html = html.replace(/&lt;/g, '<');
  html = html.replace(/&gt;/g, '>');

  // Remove HTML
  return html
}

/**
 * Data URL to File
 * @param {string} dataUrl Data URL
 * @param {string} filename Filename
 * @returns {Promise<File>} File
 */
export const dataURLtoFile = async (dataUrl: string, filename: string = 'image'): Promise<File> => {
  // fetch the file
  const res = await fetch(dataUrl);

  // convert to blob
  const blob = await res.blob();

  // Get mime type
  const mimeType = blob.type;

  // Get file extension
  const fileExtension = getFileExtensionFromMimeType(mimeType);

  // File options
  const options = { type: mimeType };

  // return new file
  return new File([blob], `${filename}.${fileExtension}`, mimeType ? options : undefined);
}

/**
 * Get file extension from mime type
 * @param {string} mimeType Mime type
 * @returns {string} File extension
 */
export const getFileExtensionFromMimeType = (mimeType: string): string => {
  const extensionsMap: Record<string, string[]> = {
    "image/jpeg": ["jpg", "jpeg"],
    "image/png": ["png"],
    "image/gif": ["gif"],
    "application/pdf": ["pdf"],
    "text/plain": ["txt"],
  };

  const extensions = extensionsMap[mimeType];

  if (!extensions || extensions.length === 0) return 'png';

  return extensions[0];
}

/**
 * Normalize input value
 * @param {string} value Input value
 * @returns {string} Normalized value
 */
export const normalizeTerm = (value: string): string => {
  // Check if value exists
  if (!value) return "";

  // Check if is a string
  if (typeof value !== 'string') return value;

  // Remove accents and put to lower case
  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}



/**
 * Set max elements for pagination.
 * Display grid has a different treatment as it is not just
 * a single vertical scroll. It can have columns, so we make it load
 * more contacts. Removing grid treatment can result in scroll not showing
 * @param isDisplayGrid If display grid is being used
 * @returns The max quantity of elements for pagination
 */
export const getMaxElementsForPagination = (isDisplayGrid?: boolean) => {
  switch (true) {
    // 4k screens
    case window.innerHeight > 1080:
      if(isDisplayGrid === true)
        return 100;
      return 60;

    // Medium screens
    case window.innerHeight <= 1080 && window.innerHeight > 780:
      if(isDisplayGrid === true) 
        return 50;
      return 30;

    // Small screens
    case window.innerHeight <= 780:
      if(isDisplayGrid === true) 
        return 35;
      return 20;
      
    // Default
    default:
      if(isDisplayGrid === true)
        return 50;
      return 30;
  }
}

/**
 * Set limit of elements to load on timeline
 */
export const getLimitOfElementsToLoadOnTimeline = () => {
  let maxElements = BASE_EVENTS_LOAD_LIMIT;

  switch (true) {
    // 4k screens
    case window.innerHeight > 1080:
      maxElements = Math.trunc(BASE_EVENTS_LOAD_LIMIT / 2);
      break;

    // Medium screens
    case window.innerHeight <= 1080 && window.screen.availHeight > 780:
      maxElements = BASE_EVENTS_LOAD_LIMIT;
      break;

    // Small screens
    case window.innerHeight <= 780:
      maxElements = Math.trunc(BASE_EVENTS_LOAD_LIMIT / 2);
      break;

    // Default
    default:
      maxElements = BASE_EVENTS_LOAD_LIMIT;
      break;
  }

  return maxElements;
}

/**
 * Set limit of elements to load on timeline
 */
export const getMsgPanelHight = () => {
  let height = "90%"

  switch (true) {
    // 4k screens
    case window.innerHeight > 1080:
      height = "96%";
      break;

    // Medium screens
    case window.innerHeight <= 1080 && window.innerHeight > 780:
      height = "93%";
      break;

    // Default
    default:
      height = "90%";
      break;
  }

  return height;
}


/**
 * Get features to open a new window
 * @param {number} w New window width
 * @param {number} h New window height
 * @returns {string} Features to open a new window
 */
export const getFeaturesToNewWindow = (w: number = 800, h: number = 600): string => {
  // Get screen size
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  // Get window size
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  // Get system zoom
  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  // Default features
  const defaultFeatures = 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=yes';

  // Return features
  return `${defaultFeatures},width=${w},height=${h},left=${left},top=${top}`;
}

/**
 * Find duplicates
 * @param {string[]} arrayOfValue Array of values
 * @returns {string[]} Array of duplicates
 */
export const toFindDuplicates = (arrayOfValue: string[]): string[] => {
  return arrayOfValue.filter(
    (item, index) => arrayOfValue.indexOf(item) !== index
  );
}

/**
 * Check if is LCS panel page
 * @returns {boolean} True if is LCS panel page
 */
export const isLcsPanelPage = (): boolean => {
  return window.location.href.includes('/#/lcs-admin-panel/');
}

/**
 * Has equipment associated
 * @param {User} user User data
 * @returns {boolean} True if has equipment
 */
export const hasEquipmentAssociated = (user?: User): boolean => {
  const companyPbxIntegration = getLSCompanyPABXIntegration();
  return !!user
    ? !!user.pbxId && EQUIPMENT_TYPES.includes(companyPbxIntegration) // Check if user has equipment associated
    : EQUIPMENT_TYPES.includes(companyPbxIntegration); // Check if company has equipment associated
};

/**
 * Check if has no PABX integration
 * @returns {boolean} True if has no PABX integration
 */
export const noPABXIntegration = (): boolean => getLSCompanyPABXIntegration() == PABX_INTEGRATION_OPTIONS.none;

/**
 * Check if is Multi talk
 * @returns {boolean} True if is Multi talk
 */
export const isMultiTalk = (): boolean => noPABXIntegration();

/**
 * Check if is Custom PABX
 * @returns {boolean} True if is Custom PABX
 */
export const isCustomPABX = (): boolean => getLSCompanyPABXIntegration() == PABX_INTEGRATION_OPTIONS.custom;

/**
 * Go Back
 */
export const goBack = () => window.history.back();

/**
 * Check if can't show PABX tab
 * @returns {boolean} True if can't show PABX tab
 */
export const canNotShowPABX = (): boolean => {
  switch (true) {
    case !isCustomPABX():
      return true;
    default:
      return !isLcsPanelPage();
  }
}

/**
 * Compare two objects
 * @param {any} obj1 Object 1
 * @param {any} obj2 Object 2
 * @returns {boolean} True if both are equal
 */
export const compareObjects = (obj1: any, obj2: any): boolean => {
  // Check if one is undefined
  if (!obj1 || !obj2) return false;

  // Check if both are objects
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};


/**
 * Compare two arrays
 * @param {any[]} arr1 Array 1
 * @param {any[]} arr2 Array 2
 * @returns {boolean} True if both are equal
 */
export const compareArrays = (arr1: any[], arr2: any[]): boolean => {
  // Check if one is undefined
  if (!arr1 || !arr2) return false;

  // Check if has different length
  if (arr1.length !== arr2.length) return false;

  // Check if both are objects and compare properties
  const hasNew = Object.keys(arr1).some((x) => !Object.keys(arr2).some((y) => compareObjects(x, y)))
  const hasOld = Object.keys(arr2).some((x) => !Object.keys(arr1).some((y) => compareObjects(x, y)))

  // Return if both are equal
  return !hasNew && !hasOld;
};

type Difference = { [key: string]: { old: any; new: any; }; };

/**
 * Get difference between objects
 * @param {T} newObject Object 1
 * @param {T} originalObject Object 2
 * @param {string[]} privateAttr Private attributes
 * @returns {Difference[]} Difference between objects
 */
export const getDiffBetweenObj = <T>(
  newObject: T,
  originalObject: T,
  privateAttr: string[] = []
): Difference[] => {
  // Init difference
  const diff: Difference[] = [];

  Object.keys(newObject).forEach((key) => {
    // Get values
    const newVal = newObject[key];
    const oldVal = originalObject[key];

    switch (true) {
      // Ignore
      case privateAttr.includes(key): // Private attribute
      case !newVal && !oldVal: // Both are empty
        break;

      // Different arrays
      case Array.isArray(newVal) && Array.isArray(oldVal) && !compareArrays(newVal, oldVal):
        diff.push({ [key]: { old: oldVal, new: newVal } });
        break;

      // Different values
      case !compareObjects(newVal, oldVal):
        diff.push({ [key]: { old: oldVal, new: newVal } });
        break;
    }
  });

  // Return difference
  return diff;
};
