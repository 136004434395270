<div style="position: relative;">
  <app-avatar [style.visibility]="isLoaded ? 'visible' : 'hidden'" (loaded)="loaded($event)" [isSmall]="isSmall"
    [photoUrl]="contact?.photoUrl" [class.light-background]="!themeService.isDarkTheme()"
    [noRegister]="hasNoRegister() && !isOffline()" [class.dark-background]="themeService.isDarkTheme()"
    [class.is-small]="isSmall" (mouseenter)="isTooltipVisible = true" (mouseleave)="isTooltipVisible = false">

    <div class="status-container">
      <div *ngIf="canShowCollaborationStatus()" class="presence-status" [ngClass]="getUserStatus()"></div>
      <div *ngIf="canShowExtensionStatus()" class="extension-status" [ngClass]="getUserExtensionStatus()"></div>
    </div>
  </app-avatar>

  <app-custom-tooltip [isVisible]="isTooltipVisible">

    <div class="tooltip-item" *ngIf="canShowExtensionStatus()">
      <mat-icon *ngIf="canShowTooltipIcons()" svgIcon="call_hover"></mat-icon>
      {{ getUserExtensionStatus() | translate }}
    </div>

    <div class="tooltip-item" *ngIf="canShowCollaborationStatus()">
      <mat-icon *ngIf="canShowTooltipIcons()" svgIcon="chat"></mat-icon>
      {{ getUserStatus() | translate }}
    </div>
  </app-custom-tooltip>
</div>
