import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AuthService,
  ClientService,
  LoadingSpinnerService,
  RoomService,
  UserService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_CHATGPT_LOGIN_ROUTE,
  DEFAULT_CHATGPT_PASSWORD,
  DEFAULT_CHATGPT_QUESTION_ROUTE,
  DEFAULT_CHATGPT_USERNAME,
  DEFAULT_ERROR_LIMIT,
  DEFAULT_MAX_TOKENS,
  DEFAULT_TEMPERATURE,
  DEFAULT_TOKEN_HEADER
} from '@shared/constants';
import { MatrixRoomType, UserPresence } from '@shared/enums';
import { ChatGgtAPIResponse, ChatGgtLoginResponse, Presence, User } from '@shared/models';
import { ICreateRoomOpts, Preset, Visibility } from 'matrix-js-sdk';
import { BehaviorSubject, first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatgptService {
  private domain = environment.chatGPTDomain;
  public isEnable = new BehaviorSubject<boolean>(false);
  public isChatGPTTyping = false;
  public token: string;
  public errorCounter = 0;
  public errorLimit = DEFAULT_ERROR_LIMIT;
  public status = new BehaviorSubject<Presence>({ force: false, status: UserPresence.AVAILABLE, platforms: [] });

  constructor(
    private spinner: LoadingSpinnerService,
    private clientService: ClientService,
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient,
    private roomService: RoomService,
    private translate: TranslateService,
  ) { }

  /**
   * Set ChatGPT status to Offline
   */
  public setOffline(): void {
    if (this.status.value.status !== UserPresence.OFFLINE) {
      this.status.next({ force: false, status: UserPresence.OFFLINE, platforms: [] });
    }
  }

  /**
   * Set ChatGPT status to AVAILABLE
   */
  public setOnline(): void {
    if (this.status.value.status !== UserPresence.AVAILABLE) {
      this.status.next({ force: false, status: UserPresence.AVAILABLE, platforms: [] })
    }
  }

  /**
   * Create ChatGPT User
   * @returns {User} ChatGPT user
   */
  public getChatGPTContact(): User {
    return {
      id: '',
      email: '',
      extension: '',
      passwordExten: '',
      presence: this.status.value,
      displayName: 'ChatGPT - BETA',
      photoUrl: 'assets/icons/chatgpt.svg',
    };
  }

  /**
   * Make login
   */
  public async login(): Promise<void> {
    // Get path
    const path = this.domain + DEFAULT_CHATGPT_LOGIN_ROUTE;

    // Form body to request
    const body = {
      username: DEFAULT_CHATGPT_USERNAME,
      password: DEFAULT_CHATGPT_PASSWORD
    };

    // Login
    const resp = await lastValueFrom<ChatGgtLoginResponse>(
      this.http.post<ChatGgtLoginResponse>(path, body).pipe(first()));

    // Save token
    this.token = resp.token;
  }

  /**
   * Send Question to chatGPT
   * @param {string} question Question
   */
  public async sendQuestion(question: string): Promise<void> {
    try {
      // Set trying to true
      this.isChatGPTTyping = true;

      // Get path
      const path = this.domain + DEFAULT_CHATGPT_QUESTION_ROUTE;

      // Form body to request
      const body = {
        prompt: question,
        temperature: DEFAULT_TEMPERATURE,
        max_tokens: DEFAULT_MAX_TOKENS
      };

      // Form request headers
      const headers = new HttpHeaders().set(DEFAULT_TOKEN_HEADER, this.token);

      // Get chatGPT answer
      const answer = await lastValueFrom<ChatGgtAPIResponse>(
        this.http.post<ChatGgtAPIResponse>(path, body, { headers }
        ).pipe(first()));

      // Get answer
      const resp = answer.dataComplet.choices[0].message.content;

      // Send resp to timeline
      this.roomService.sendChatGPTMessage(resp);

      // Reset counter and set to online
      this.errorCounter = 0;
      this.setOnline();
    } catch (error) {
      // Increment erro counter
      this.errorCounter++;

      // Unauthorized error
      const unauthorized = error.status == 401 && this.errorCounter <= this.errorLimit;

      // Invalid token
      if (unauthorized) {
        await this.login();
        this.sendQuestion(question);
      } else {
        this.setOffline();
        const message = this.translate.instant('chatGPTError');
        this.roomService.sendChatGPTMessage(message);
      }
      // Show error
      console.error(error);
    } finally {
      // Set trying to false
      this.isChatGPTTyping = false;
    }
  }

  /**
   * Create Chat GPT Room
   */
  public async createChatGPTRoom(): Promise<void> {
    // Get self chat from user
    const { chatGPTRoomId, id } = this.authService.user;

    // Check if self chat exist
    if (!chatGPTRoomId) {
      // Init room id
      let room_id: string;

      // Init options
      let options: ICreateRoomOpts = {
        is_direct: true,
        visibility: Visibility.Private,
        preset: Preset.TrustedPrivateChat,
        initial_state: [],
        invite: [],
        name: 'chatgpt',
        creation_content: {
          roomType: MatrixRoomType.GPT
        }
      };

      try {
        // Get Client
        const matrixClient = this.clientService.getClient();

        // Show spinner
        this.spinner.show();

        // Create room
        room_id = (await matrixClient.createRoom(options)).room_id;

        // Update local chatGPTRoomId
        this.authService.user.chatGPTRoomId = room_id;

        // Update data from firebase
        this.userService.updateOnlyInFire({ chatGPTRoomId: room_id }, id)

        // Added room to local rooms
        await this.roomService.addRoomToMDirect(room_id);
      } catch (error) {

        // Show error
        console.error(error);

      } finally {
        // Populate rooms
        this.roomService.populateRooms();

        // Hide spinner
        this.spinner.hide();
      }
    }
  }
}
