import { MESSAGE_MODIFICATION_MAX_TIME_LIST } from "@shared/constants/tenant.constant";
import { MessageModificationMaxTimeOptions } from "@shared/models";
import { EventTimeline } from "matrix-js-sdk";

export function getFirstLinkedTimeline(timeline): EventTimeline  {
  let tm = timeline;

  while (tm.prevTimeline) {
    tm = tm.prevTimeline;
  }

  return tm;
}

export function getLastLinkedTimeline(timeline): EventTimeline {
  let tm = timeline;

  while (tm.nextTimeline) {
    tm = tm.nextTimeline;
  }

  return tm;
}

export function getLastEventsUntilEvent(timeline: EventTimeline, eventId: string): EventTimeline {
  let tm = timeline as any;
  let exist = false;

  while (exist) {
    exist = !!tm.getEvents().find(e => e.getId() == eventId);
    tm = tm.nextTimeline;
  }

  return tm;
}

export function isTimelineLinked(tm1, tm2) {
  let tm = getFirstLinkedTimeline(tm1) as any;
  while (tm) {
    if (tm === tm2) return true;
    tm = tm.nextTimeline;
  }
  return false;
}

export function getUnfilteredTimelineSet(room) {
  return room.getUnfilteredTimelineSet();
}


export function hasEventInTimeline(eventId, timeline, room) {
  const timelineSet = getUnfilteredTimelineSet(room);
  const eventTimeline = timelineSet.getTimelineForEvent(eventId);
  if (!eventTimeline) return false;
  return isTimelineLinked(eventTimeline, timeline);
}

export function getEventInTimeline(eventId, room) {
  const timelineSet = getUnfilteredTimelineSet(room);
  return timelineSet.getTimelineForEvent(eventId);
}

export function findEventByIdInTimelineSet(eventId, room) {
  const eventTimelineSet = getUnfilteredTimelineSet(room)
  return eventTimelineSet.findEventById(eventId);
}

export function iterateLinkedTimelines(timeline, backwards, callback) {
  let tm = timeline;
  while (tm) {
    callback(tm);
    if (backwards) tm = tm.prevTimeline;
    else tm = tm.nextTimeline;
  }
}

export async function canUserModifyMessage(serverGmt: any, messageModificationOption: any, messageMillis: any) {
  // The message modification VALUE (number | null)
  const messageModificationValue = MESSAGE_MODIFICATION_MAX_TIME_LIST.filter((option) => {
    return messageModificationOption == option.option;
  })[0]?.value;

  // Unlimited or non-existant situation (both cases can edit)
  if (messageModificationOption === MessageModificationMaxTimeOptions.Unlimited || messageModificationOption === undefined) {
    return true;
  } else {

    // Difference between current server time and the message time (millis)
    const passedTime = Math.abs(serverGmt - messageMillis)

    // If the passed time is lower than the modification timeout, then user can edit
    if (passedTime <= messageModificationValue) {
      return true;
    } 
  }
  return false;
}