import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {
  AuthService,
  ElectronService,
  LoadingSpinnerService,
  NotifierService,
  ThemeService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ReleaseDate } from '@shared/enums';
import { DesktopSettings, PREFIX_STORAGE, User } from '@shared/models';
import { getDesktopVersion, isExternalRouteExist, makeHotReload } from '@shared/utils';
import { LgpdDialogComponent } from 'app/layout/components/lgpd-dialog/lgpd-dialog.component';
import { ImageCropperDialog } from '../image-cropper-dialog/image-cropper-dialog.component';
import { RemoveProfilePictureDialog } from './components/remove-profile-picture-dialog/remove-profile-picture-dialog.component';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent implements OnInit {
  isDarkMode: boolean;
  autoLaunch = false;
  startMinimized = false;
  isElectron: boolean = this.electron.isElectronApp;
  ipcRenderer = this.electron.ipcRenderer;
  desktopVersion = '';
  mobiPhoneVersion: string = environment.appVersion;
  iosVersion = '';
  androidVersion = '';
  hasUpdate: boolean = false;
  updateVersion: string;
  user: User;
  showPassword: boolean[] = [false, false, false];
  profileForm: UntypedFormGroup = this.fb.group({
    profilePicture: [null],
    name: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
  });
  passwordForm: UntypedFormGroup = this.fb.group({
    currentPassword: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required]],
  });

  public currentTap = 'profile';

  selectedOption: { option: string; title: string } = {
    option: '',
    title: this.translateService.instant('settings'),
  };

  public externalRouteNumber: number | string[];
  public blindTransferCode: string;
  public attendedTransferCode: string;

  constructor(
    private electron: ElectronService,
    private spinner: LoadingSpinnerService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private authService: AuthService,
    private translateService: TranslateService,
    private notifier: NotifierService,
    private fb: UntypedFormBuilder,
    public theme: ThemeService,
    public dialogRef: MatDialogRef<SettingsDialogComponent>
  ) { }

  ngOnInit(): void {
    this.authService.fetchReleaseDates();
    this.isDarkMode = this.theme.isDarkTheme();

    if (this.isElectron) {
      this.ipcRenderer.send('getDesktopSettings', {});

      this.ipcRenderer.on('desktopSettings', (evt, arg: DesktopSettings) => {
        this.autoLaunch = arg.autoLaunchEnabled;

        if (this.autoLaunch) {
          this.startMinimized =
            localStorage.getItem('start_minimized') === 'true';
        }
      });

      const hasUpdate = sessionStorage.getItem('hasUpdate');
      if (hasUpdate && hasUpdate !== null) {
        const data = JSON.parse(hasUpdate);
        this.hasUpdate = data.hasUpdate;
        this.updateVersion = data.newVersion;
      }

      this.setDesktopVersion();
    }

    this.getPBXData();

    this.authService.user$.subscribe((user) => {
      if (!user?.photoUrl || user?.photoUrl === '') {
        user.photoUrl = null;
      }
      this.profileForm.controls['profilePicture'].patchValue(user?.photoUrl);
      this.user = user;

      const separateDisplayName: string[] = user.displayName.split(' ');
      this.profileForm.controls['name'].patchValue(separateDisplayName[0]);
      const lastName: string = separateDisplayName
        .map((value, index) => {
          if (index > 0) {
            return value;
          }
        })
        .join(' ');
      this.profileForm.controls['lastName'].patchValue(lastName.trim());
    });
  }

  /**
   * Select tab
   * @param {MatTabChangeEvent} event Mat tab change event
   */
  public selectTab(event: MatTabChangeEvent): void {
    this.currentTap = event.tab.textLabel;
  }

  /**
   * Check if is current tab
   * @param {string} label Tab label
   * @returns {boolean} True if is current tab
   */
  public isCurrentTab(label: string): boolean {
    return this.currentTap === label;
  }

  /**
   * Get necessary PBX data
   */
  private getPBXData(): void {
    this.externalRouteNumber = this.authService.getExternalRoute();
    this.blindTransferCode = this.authService.getBlindTransferCode();
    this.attendedTransferCode = this.authService.getAttendedTransferCode();
  }

  /**
   * Get Desktop release date
   * @returns {string} Date
   */
  public getDesktopReleaseDate(): string {
    return this.getReleaseDate(ReleaseDate.Desktop)
  }

  /**
   * Get Web release date
   * @returns {string} Date
   */
  public getWebReleaseDate(): string {
    return this.getReleaseDate(ReleaseDate.Web)
  }

  /**
   * Get release Date
   * @param {ReleaseDate} app Release Date
   * @returns {string} Date
   */
  private getReleaseDate(app: ReleaseDate): string {
    // Get release date
    const releaseDates = this.authService.getReleaseDates();
    const date = releaseDates.get(app).date.split('-');

    // Get date info
    const day = +date[0];
    const month = +date[1];
    const year = +date[2];

    // Transform
    return new Date(year, month - 1, day).toString();
  }

  /**
   * Check if app is up to date
   * @param {string} version Application version
   * @param {ReleaseDate} app Application
   * @returns {boolean} True if is up to date
   */
  public isValidVersion(version: string, app: ReleaseDate): boolean {
    const releaseDates = this.authService.getReleaseDates();
    return releaseDates.get(app)?.version === version;
  }

  /**
   * Make hot reload
   */
  public updateWeb(): void {
    makeHotReload();
  }

  /**
   * Update Electron app
   */
  public updateElectron(): void {
    if (this.electron.isElectronApp) {
      const renderer = this.electron.ipcRenderer;
      renderer.send('update');
    }
  }

  /**
   * Verify if can show external route
   * @returns {boolean} to show or not the external route value
   */
  public externalRouteExist(): boolean {
    return isExternalRouteExist(this.externalRouteNumber);
  }

  /**
   * Show external route value
   * @returns {string} External route
   */
  public showExternalRouteValue(): string {
    return Array.isArray(this.externalRouteNumber)
      ? this.externalRouteNumber.join(', ')
      : `${this.externalRouteNumber}`;
  }

  onToggleTheme(event: MatRadioChange): void {
    if (event.value === 'dark') {
      this.theme.updateTheme('dark-theme');
    } else {
      this.theme.updateTheme('light-theme');
    }
    this.isDarkMode = this.theme.isDarkTheme();
  }

  toggleAutoLaunch(event: MatSlideToggleChange) {
    this.spinner.show();
    this.autoLaunch = event.checked;

    if (!event.checked) {
      this.startMinimized = false;
      localStorage.setItem('start_minimized', 'false');
    }

    const settings: DesktopSettings = {
      autoLaunchEnabled: this.autoLaunch,
    };

    this.ipcRenderer.send('setDesktopSettings', settings);
    this.spinner.hide();
  }

  toggleMinimization(event: MatSlideToggleChange) {
    this.startMinimized = event.checked;

    localStorage.setItem('start_minimized', event.checked.toString());
  }

  setDesktopVersion() {
    this.desktopVersion = getDesktopVersion();
  }

  openLGPDAgreement() {
    this.dialog.open(LgpdDialogComponent, {
      disableClose: true,
      data: { readOnly: true },
    });
  }

  openPrivacyPolicy() {
    const url = 'https://www.leucotron.com.br/politica-de-privacidade';
    if (this.electron.isElectronApp) {
      this.ipcRenderer.send('openPrivacyPolicy', url);
    } else {
      window.open(url, '_tab');
    }
  }

  showContent(option: string) {
    this.selectedOption = {
      option,
      title: this.translateService.instant(option),
    };
  }

  onImgError(target: EventTarget): void {
    (target as HTMLImageElement).src = 'assets/images/avatar.svg';
  }

  changeProfilePicture(target: EventTarget): void {
    const files: FileList = (target as HTMLInputElement).files;
    const MB = 1;
    const maxSize: number = MB * 1024 * 1024;
    if (files && files.length > 0) {
      const file: File = files.item(0);
      if (file.size < maxSize) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = async (e) => {
          const picture = e.target.result;
          const dialogRef = this.dialog.open(ImageCropperDialog, {
            width: '50%',
            disableClose: true,
            data: picture,
          });

          dialogRef.afterClosed().subscribe(async (editedImage) => {
            if (editedImage) {
              this.profileForm.controls['profilePicture'].patchValue(
                editedImage
              );
            }
            (target as HTMLInputElement).value = null;
          });
        };
      } else {
        this.notifier.showNotification({
          type: 'error',
          message: 'maxSizeExceeded',
          actionText: '',
          panelClass: 'error',
        });
      }
    }
  }

  removeProfilePicture(): void {
    const dialogRef = this.dialog.open(RemoveProfilePictureDialog, {
      width: '45%',
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.spinner.show();
        this.profileForm.controls['profilePicture'].patchValue(null);
        if (this.user.photoUrl !== null) {
          await this.authService.removeUserPhoto().finally(() => {
            this.spinner.hide();
          });
        } else {
          this.spinner.hide();
        }
      }
    });
  }

  async changeProfileData() {
    if (this.profileForm.valid) {
      try {
        this.spinner.show();
        const controls = this.profileForm.controls;

        // Save profile picture
        const profilePicture: string = controls['profilePicture'].value;
        if (profilePicture && !profilePicture.includes('http')) {
          await this.authService.setUserPhoto(profilePicture);
        }

        // Change Display Name
        const name = controls['name'].value;
        const lastName = controls['lastName'].value;
        const displayName: string = `${name} ${lastName}`;
        await this.authService.updateUserProfile(displayName);

        this.notifier.showNotification({
          type: 'profileUpdated',
          actionText: '',
          message: 'profileUpdatedMessage',
          panelClass: 'success',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.spinner.hide();
      }
    }
  }

  async changePassword() {
    const controls = this.passwordForm.controls;
    const currentPassword: string = controls['currentPassword'].value;
    const newPassword: string = controls['password'].value;
    const confirmPassword: string = controls['confirmPassword'].value;

    if (
      !(currentPassword === '' || newPassword === '' || confirmPassword === '')
    ) {
      if (currentPassword === newPassword) {
        controls['password'].setErrors({
          equalCurrentPassword: true,
        });
      }

      if (newPassword !== confirmPassword) {
        controls['confirmPassword'].setErrors({
          diffPasswords: true,
        });
      }

      if (this.passwordForm.valid) {
        this.spinner.show();
        await this.authService
          .changeUserPassword(currentPassword, newPassword)
          .then(() => {
            this.notifier.showNotification({
              type: 'passwordReseted',
              actionText: '',
              message: 'passwordResetedMessage',
              panelClass: 'success',
            });
          })
          .catch((error) => {
            if (typeof error === 'string') {
              this.notifier.showNotification({
                type: 'error',
                actionText: '',
                message: error,
                panelClass: 'error',
              });
              controls['currentPassword'].setErrors({
                incorrectPassword: true,
              });
            } else {
              this.notifier.showNotification({
                type: 'error',
                actionText: '',
                message: 'failToResetPassword',
                panelClass: 'error',
              });
            }
            console.error(error);
          })
          .finally(() => {
            this.spinner.hide();
          });
      }
    } else {
      const control =
        currentPassword === ''
          ? 'currentPassword'
          : newPassword === ''
            ? 'password'
            : 'confirmPassword';
      controls[control].setErrors({
        required: true,
      });
    }
  }

  refresh(): void {
    window.location.reload();
  }

  backButton() {
    if (this.selectedOption.option !== '') {
      this.selectedOption = {
        option: '',
        title: this.translateService.instant('settings'),
      };
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Get current language
   * @returns
   */
  currentLanguage(): string {
    return this.translateService.currentLang;
  }

  /**
   * Defines a new language
   * @param event Select change event
   */
  setLanguage(event: MatSelectChange): void {
    // Get language
    const lang = event.value;

    // Save language in storage
    localStorage.setItem(`${PREFIX_STORAGE}:language`, lang);

    // Set language in translate service
    this.translateService.use(lang);

    // Set language in desktop
    if (this.isElectron) this.ipcRenderer?.send('setDesktopLanguage', lang);
  }
}
