import { Injectable, Injector } from '@angular/core';
import {
  Database,
  off,
  onChildAdded,
  onChildRemoved,
  onValue,
  ref,
  remove
} from '@angular/fire/database';
import { MatDialog } from '@angular/material/dialog';
import {
  AnalyticsSharedService,
  AuthService,
  ClientService,
  ContactService,
  ElectronService,
  JsSIPService,
  LcsEventsService,
  PlatformService,
  RoomService,
  SocketService,
  ThemeService,
  UserPresenceService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SimpleDialogComponent } from '@shared/components';
import { DEFAULT_JITSI_ROOM_DATABASE, DEFAULT_JITSI_SUBJECT } from '@shared/constants';
import { ANALYTICS_EVENTS, CallEventTypes, CallState, SOCKET_TOPIC, UserPresence } from '@shared/enums';
import {
  ExternalAPICommands,
  ExternalAPIEventCallbacks,
  ExternalAPIEventTypes,
  ExternalAPIOptions,
  ExternalJWTPayloadData,
  GuestJWTPayloadData,
  InterfaceConfig,
  JitsiSocketData,
  JitsiStatus,
  MeetingContainerInfo,
  RoomsInMeeting
} from '@shared/models';
import {
  createExternalJWTToken,
  createJitsiRoomName,
  createMatrixJWTToken,
  getDefaultAPIOptions,
  getFeaturesToNewWindow,
  getJitsiLang,
  getUserNameWithSector,
  isDMRoom,
  isMeetGroup,
  verifyExternalJWTToken
} from '@shared/utils';
import { DatabaseReference } from 'firebase/database';
import { BehaviorSubject, Subject, Subscription, first } from 'rxjs';

type ConferenceType = ('external' | 'internal');
type DevicesStatus = ('muted' | 'unmuted');
type ElectronRespArgs = {
  participantsNumber: number;
  jitsiEvent?: any;
};

@Injectable({
  providedIn: 'root'
})
export class JitsiService {
  // Atributes
  public events = new BehaviorSubject<keyof ExternalAPIEventCallbacks>(null);
  public status = new BehaviorSubject<JitsiStatus>(JitsiStatus.standby);
  public meetingContainer = new Subject<MeetingContainerInfo>();
  public subject: string;
  public participantsNumber: number = null;
  private activeRoomId: string;
  private callingOptions = [
    CallState.CONNECTING,
    CallState.PROGRESS,
    CallState.CONFIRMED,
    CallState.INCOMING_PROGRESS,
    CallState.INCOMING_ACCEPTED,
    CallState.INCOMING_CONFIRMED,
    CallState.STREAM,
  ];
  private conferenceType: ConferenceType = 'internal';
  private externalConferenceLink: string = '';
  private audioStatus: DevicesStatus = 'muted';
  private videoStatus: DevicesStatus = 'muted';
  public roomsInMeeting = new Map<string, RoomsInMeeting>();
  private roomsInMeetingRef: DatabaseReference;
  private roomsInMeetingPath = DEFAULT_JITSI_ROOM_DATABASE;
  private hasFatalError = false;
  private jitsiWindowHandle: Window;
  private tempConferenceId: string;

  // Subscriptions
  private subscriptions = new Subscription();

  constructor(
    private database: Database,
    private auth: AuthService,
    private roomService: RoomService,
    private matrixClient: ClientService,
    private theme: ThemeService,
    private translate: TranslateService,
    private socket: SocketService,
    private platform: PlatformService,
    private electron: ElectronService,
    private userPresence: UserPresenceService,
    public dialog: MatDialog,
    private injector: Injector,
    private lcsEvents: LcsEventsService,
    private contactService: ContactService,
    private analytics: AnalyticsSharedService
  ) { }

  /**
   * Init conference
   * @param {roomId} roomId Room Id
   * @param {ConferenceType} type Conference type
   * @param {string} subject Conference subject
   */
  public initCall(
    roomId: string,
    type: ConferenceType = 'internal',
    subject: string = ''
  ): void {
    // Get instance of jsSip
    const jsSip = this.injector.get<JsSIPService>(JsSIPService);

    // Get current JsSip state
    const jsSipState = jsSip.callingEvents.value?.state;

    // Check if user is in a call
    const hasMobiPhoneCall = this.callingOptions.includes(jsSipState);

    // Added subject to the meeting
    this.subject = !!subject?.trim() ? subject : null;

    // Check if user is in a conference or a call [1/2]
    if (this.hasActiveConference() || hasMobiPhoneCall) {
      // Open dialog
      const dialogRef = this.dialog.open(SimpleDialogComponent, {
        panelClass: "simple-dialog",
        data: {
          title: hasMobiPhoneCall ? 'header.callOngoing' : 'ongoingConference',
          desc: hasMobiPhoneCall ? 'callWillBeEnded' : 'initNewConference',
          btnText: 'yes',
        }
      });

      // Get dialog resp
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe((resp) => {
          if (resp) {
            // Close current call
            hasMobiPhoneCall ? jsSip.hangup() : this.hangUp();

            // Init new call
            setTimeout(() => {
              this.initJitsiAndListenings(roomId, type);
            }, 1000)
          }
        })
    } else {
      this.initJitsiAndListenings(roomId, type);
    }

  }

  /**
   * Init group call
   */
  public initGroupCall(roomId: string): void {
    this.initCall(roomId);
  }

  /**
   * Init jitsi and listenings
   * @param {roomId} roomId Room Id
   * @param {boolean} type is External conference
   */
  private initJitsiAndListenings(roomId: string, type: ConferenceType): void {
    // Set conference type
    this.setConferenceType(type);

    // Set active room id
    this.setActiveRoomId(roomId);

    // Set fatal error to false
    this.hasFatalError = false;

    // Wait for jitsi to be disposed
    setTimeout(() => {
      if (this.electron.isElectronApp) {
        this.startJitsiOnElectron()
          .then(() => {
            this.listeningElectron(); // Init Electron listenings
          })
          .catch((error => this.handleError(error)))
      } else {
        // Prevent multi click to start multi conferences [2/2] (redundant checkup)
        if (!this.hasActiveConference()) {
          // Start jitsi in other tab
          this.openMeetPage(roomId)
            .then(() => {
              this.listenings(); // Init listenings
            })
        }
      }
    }, 500);
  }

  /**
   * Documentation: https://usefulangle.com/post/4/javascript-communication-parent-child-window
   * Open in meeting container space
   * @param {string} roomId Room Id
   */
  private async openMeetPage(roomId: string): Promise<void> {
    // Features to open a new window and center it
    const features = getFeaturesToNewWindow();

    // Target
    const target = 'jitsi-conference'

    // Base url
    const baseUrl = window.location.origin;

    // Create token
    const { token, tempConferenceId } = await this.createTokenToExternalConference(roomId);

    // Set temp conference id
    this.tempConferenceId = tempConferenceId;

    // Pure url
    const pureUrl = `${baseUrl}/#/external-meeting/${token}`

    // Open a new window with the url
    this.jitsiWindowHandle = window.open(pureUrl, target, features)

    // Set jitsi window on focus
    this.jitsiWindowHandle.focus();
  }

  /**
   * Create token to external conference
   * @param {string} roomId Room Id
   * @returns  {Promise<string>} JWT Token to external conference
   */
  private async createTokenToExternalConference(roomId: string)
    : Promise<{ token: string, tempConferenceId: string }> {
    // Get room
    const room = this.roomService.getRoom(roomId);

    // Get room name
    const jwt = await this.createJWTToken(roomId, isDMRoom(room) ? '' : room.name);

    // Create temp unique id for current conference
    const tempConferenceId = `${roomId}-${Date.now()}`;

    // Return payload
    const data: ExternalJWTPayloadData = {
      roomId,
      tempConferenceId,
      userId: this.auth.user.id,
      displayName: getUserNameWithSector(this.auth.user),
      photoUrl: this.auth.user.photoUrl,
      groupName: room.name,
      isDarkTheme: this.theme.isDarkTheme(),
      jitsiDomain: environment.jitsiDomain,
      isMobile: this.platform.isMobile(),
      lang: this.translate.currentLang,
      isGuestMeeting: false,
      isAudioMuted: true,
      isVideoMuted: true,
      jwt,
      hasSubject: !isDMRoom(room),
    }

    // Create token
    const token = createExternalJWTToken<ExternalJWTPayloadData>(data);

    // Return token and temp conference id
    return { token, tempConferenceId }
  }

  /**
   * Set active room id
   * @param {roomId} roomId Room Id
   */
  public setActiveRoomId(roomId: string) {
    this.activeRoomId = this.isExternalConference() && this.roomService.selfRoomId == roomId
      ? null
      : roomId;
  }

  /**
   * Set the conference type
   * @param type Conference type
   */
  public setConferenceType(type: ConferenceType): void {
    this.conferenceType = type;
  }

  /**
   * Get the conference type
   * @returns Conference type
   */
  public getConferenceType(): ConferenceType {
    return this.conferenceType;
  }

  /**
   * Check if is an external conference
   * @returns {boolean} True if is an external conference
   */
  public isExternalConference(): boolean {
    return this.getConferenceType() == 'external';
  }

  /**
   * Check if is an instant meet
   * @returns {boolean} True if is an instant meet
   */
  public isInstantMeet(): boolean {
    return this.hasActiveConference()
      && this.isExternalConference()
      && this.getActiveRoomId() == this.roomService.selfRoomId;
  }

  /**
   * Check if has external conference in progress
   * @returns {boolean} True if has external conference in progress
   */
  public hasExternalConferenceInProgress(): boolean {
    return this.isExternalConference() && this.hasActiveConference();
  }

  /**
   * Get jitsi options
   * @returns {Promise<ExternalAPIOptions>} Jitsi options
   */
  private async getJitsiOptions(): Promise<ExternalAPIOptions> {
    return this.conferenceType == 'external'
      ? await this.formExternalGuestAPIOptions()
      : await this.formExternalAPIOptions();
  }

  /**
   * Start Jitsi on electron
   */
  public startJitsiOnElectron(): Promise<void> {
    return new Promise(async (resolve) => {
      // Get photo
      const photoUrl = this.auth.user.photoUrl;

      // Get display name
      const displayName = getUserNameWithSector(this.auth.user);

      // Get options
      const options = await this.getJitsiOptions();

      // Get domain
      const domain = environment.jitsiDomain;

      // Form electron data
      const electronData = JSON.stringify({
        domain,
        options,
        displayName,
        photoUrl
      })

      // Send event to electron
      this.electron
        .ipcRenderer
        .send('startJitsi', electronData);

      // Resolve promise
      resolve();
    });
  }

  /**
   * Update Conference Name
   * @param {string} roomId Room id
   * @param {string} newName New group name
   */
  public updateConferenceName(roomId: string, newName: string): void {
    if (this.isCallStarted(roomId)) {
      this.electron.isElectronApp
        ? this.electron.ipcRenderer.send('updateSubject', newName)
        : this.sendJitsiAction('subject', newName);
    }
  }

  /**
   * Create external token
   * @returns {string} JWT
   */
  public createGuestToken(roomId: string): string {
    // Form conference id in base 32
    const roomIdInBase32 = createJitsiRoomName(roomId);

    // Form payload data
    const data: GuestJWTPayloadData = {
      roomName: roomIdInBase32,
      domain: environment.jitsiDomain,
    }

    // Form JWT
    return createExternalJWTToken<GuestJWTPayloadData>(data, true);
  }

  /**
   * Listenings
   */
  private listenings(): void {
    window.addEventListener('message', (event) => {
      // Check if event is from current origin
      if (event.origin !== window.location.origin) return;

      // Get data
      const data = event.data;

      // Check if temp conference id is valid
      if (data.tempConferenceId != this.tempConferenceId) return;

      // Check if is a jitsi event
      if (!data.isJitsiEvent) return;

      // Get type
      const type = data.type as ExternalAPIEventTypes;

      // Update participants number
      this.participantsNumber = data.participantsNumber;

      // Check if is a jitsi event
      switch (type) {
        case 'videoConferenceJoined':
          this.handleVideoConferenceJoined(data);
          break;

        case 'readyToClose':
          this.handleReadyToClose(data);
          break;

        case 'screenSharingStatusChanged':
          this.handleScreenSharingStatusChanged(data.data.isSharing);
          break;

        case 'videoConferenceLeft':
          this.handleVideoConferenceLeft(data);
          break;

        case 'suspendDetected':
          this.handleSuspendDetected(data);
          break;

        case 'peerConnectionFailure':
          this.handlePeerConnectionFailure(data);
          break;

        case 'errorOccurred':
          this.handleErrorOccurred(data);
          break;

        case 'micError':
          this.handleMicError(data);
          break;

        default:
          console.warn('Invalid jitsi event');
          break;
      }
    });
  }

  /**
   * Listenings
   */
  private listeningElectron(): void {
    this.status.next(JitsiStatus.start);
    this.listeningElectronCloseWindow();
    this.listeningElectronErrorOccurred();
    this.listeningElectronReadyToClose();
    this.listeningElectronJitsiSuspendDetected();
    this.listeningElectronVideoConferenceLeft();
    this.listeningElectronVideoConferenceJoined();
    this.listeningElectronScreenSharingStatusChanged();
  }


  /**
   * Listen - Video Conference Joined
   */
  private listeningElectronVideoConferenceJoined(): void {
    this.electron.ipcRenderer.on('videoConferenceJoined', (event: any, args: ElectronRespArgs) => {
      // Get participants number
      this.participantsNumber = args.participantsNumber;

      // Call observable
      this.status.next(JitsiStatus.progress);
      this.events.next('videoConferenceJoined');

      // Check if is the first participant in a external conference
      if (this.hasOnlyOneParticipant() && this.isExternalConference()) {
        // Update subject
        if (this.subject) this.updateConferenceName(this.getActiveRoomId(), this.subject);
      }
    });
  }

  /**
   * Listen - Ready To Close
   */
  private listeningElectronReadyToClose(): void {
    this.electron.ipcRenderer.on('readyToClose', (event: any, args: ElectronRespArgs) => {
      // Update Jitsi Status
      this.status.next(JitsiStatus.stop);

      // Set participants number
      this.participantsNumber = args.participantsNumber;

      // Destroy call group
      this.sendEventToDestroyGroupCall();
    });
  }

  /**
   * Listen - Jitsi Suspend Detected
   */
  private listeningElectronJitsiSuspendDetected(): void {
    this.electron.ipcRenderer.on('jitsiSuspendDetected', (event: any, args: any) => {
      // Show error
      console.log('suspendDetected', args);
      // Send error to analytics
      this.analytics.logEvent(ANALYTICS_EVENTS.jitsi_suspend, args);
      // Update events
      this.events.next('suspendDetected');
    });
  }

  /**
   * Listen - Close Window
   */
  private listeningElectronCloseWindow(): void {
    this.electron.ipcRenderer.on('closeWindow', (event: any, args: ElectronRespArgs) => {
      // Update Jitsi Status
      this.status.next(JitsiStatus.stop);

      // Close connection
      this.removeElectronListeners();
    });
  }

  /**
   * Listen Electron - Video Conference Left
   */
  private listeningElectronVideoConferenceLeft(): void {
    this.electron.ipcRenderer.on('videoConferenceLeft', (event: any, args: ElectronRespArgs) => {
      // Update events
      this.events.next('videoConferenceLeft');
    });
  }

  /**
   * Listen Electron - Video Conference Left
   */
  private listeningElectronScreenSharingStatusChanged(): void {
    this.electron.ipcRenderer.on('screenSharingStatusChanged', (event: any, args: ElectronRespArgs) => {
      args.jitsiEvent.on
        ? this.userPresence.setPresence(UserPresence.IN_PRESENTATION)
        : this.userPresence.setPresence(UserPresence.IN_MEETING)
    });
  }

  /**
   * Listen - Jitsi Error (Electron)
   */
  private listeningElectronErrorOccurred(): void {
    this.electron.ipcRenderer.on('jitsiError', (event, args: { type: ANALYTICS_EVENTS, error: any }) => {
      // Log error
      console.error('jitsiError', args.error);

      // Handle fatal error
      if (args.error?.error?.isFatal) this.handleFatalError();

      // Send error to analytics
      this.analytics.logEvent(args.type, args.error);
    });
  }

  /**
   * Handle error - Fatal Jitsi Error
   */
  private handleFatalError() {
    // Set fatal error
    this.hasFatalError = true;

    // Hang up
    this.hangUp();

    // Show alert
    this.dialog.open(SimpleDialogComponent, {
      panelClass: "simple-dialog",
      data: {
        title: 'jitsiError.title',
        desc: 'jitsiError.desc',
        hideCancel: true,
        buttonColor: 'warn'
      }
    });
  }


  /**
   * Handle error
   * @param {any} error Error
   */
  private handleError(error: any) {
    this.status.next(JitsiStatus.error)
    console.error(error);
  }

  /**
   * Check if call is in progress
   * @param {roomId} roomId Room Id
   * @returns {boolean} True if call is in progress
   */
  public isCallStarted(roomId: string): boolean {
    return this.hasActiveConference() && roomId == this.activeRoomId;
  }

  /**
   * Check if has active conference
   * @returns {boolean} True if has active conference
   */
  public hasActiveConference(): boolean {
    return [JitsiStatus.start, JitsiStatus.progress].includes(this.status.value);
  }

  /**
   * Get External Link
   * @param {string} roomId Room Id
   * @returns {string} Link to conference
   */
  public getExternalLink(roomId: string = this.roomService.externalRoomId, subject?: string): string {
    // Init flags
    const isAnExpiredJRoom = true;

    // Check if expired jitsi room
    if (isAnExpiredJRoom) {
      try {
        // Verify if
        verifyExternalJWTToken<ExternalJWTPayloadData>(this.externalConferenceLink);
      } catch (error) {
        // Generate JWT
        const jwt = this.createGuestToken(roomId);

        // Get base URL
        const { origin } = window.location;

        // Form link
        this.externalConferenceLink = `${origin}/#/meeting-guest/${jwt}`;
      }
    } else {
      // Form link
      const jRoomName = createJitsiRoomName(roomId);
      this.externalConferenceLink = `${origin}/#/meeting-guest/${jRoomName}`;
    }

    // Send subject by link
    if (subject) {
      // Remove spaces to %20
      subject = subject.replace(/\s/g, '%20');

      // Add subject to link
      this.externalConferenceLink = this.externalConferenceLink + `?subject=${subject}`
    }

    // Return link
    return this.externalConferenceLink;
  }

  /**
   * External API Options
   * @returns {ExternalAPIOptions} Options
   */
  private async formExternalAPIOptions(): Promise<ExternalAPIOptions> {
    // TOOLBAR BUTTONS
    let invalidButtons: InterfaceConfig["TOOLBAR_BUTTONS"] = [];
    let validButtons: InterfaceConfig["TOOLBAR_BUTTONS"] = [];

    // Get current room
    const room = this.roomService.getRoom();

    // Get default api options
    const isDarkTheme = this.theme.isDarkTheme();
    const platformName = this.platform.getBrowserName();
    const options = await getDefaultAPIOptions(true, isDarkTheme, platformName);

    // Room name
    options.roomName = createJitsiRoomName(room.roomId);

    // Parent node
    options.parentNode = document.querySelector('#meet');

    // User info
    options.userInfo = {
      displayName: getUserNameWithSector(this.auth.user),
      email: this.roomService.formMatrixUserId()
    };

    // Config Overwrite
    options.configOverwrite.startWithVideoMuted = this.videoStatus == 'muted';
    options.configOverwrite.startWithAudioMuted = this.audioStatus == 'muted';

    // Set subject
    isDMRoom(room)
      ? options.configOverwrite.subject = DEFAULT_JITSI_SUBJECT
      : options.configOverwrite.subject = this.subject ?? room.name;

    // Mobile
    if (this.electron.isElectronApp) {
      invalidButtons = invalidButtons.concat(['recording', 'fullscreen']);
    };

    // Update options value
    options.interfaceConfigOverwrite.TOOLBAR_BUTTONS = options
      .interfaceConfigOverwrite
      .TOOLBAR_BUTTONS
      .filter(opt => !invalidButtons.includes(opt))
      .concat(validButtons);

    // JWT
    options.jwt = await this.createJWTToken(room.roomId, options.configOverwrite.subject);

    // Lang
    options.lang = getJitsiLang(this.translate.currentLang);

    // Return options
    return options;
  }

  /**
   * Form External Guest API Options
   * @returns {ExternalAPIOptions} External API Options
   */
  private async formExternalGuestAPIOptions(): Promise<ExternalAPIOptions> {
    // TOOLBAR BUTTONS
    let invalidButtons: InterfaceConfig["TOOLBAR_BUTTONS"] = [];
    let validButtons: InterfaceConfig["TOOLBAR_BUTTONS"] = ['chat'];

    // Room Id
    const roomId = this.activeRoomId ?? this.roomService.externalRoomId;

    // Get default api options
    const isDarkTheme = this.theme.isDarkTheme();
    const platformName = this.platform.getBrowserName();
    const options = await getDefaultAPIOptions(true, isDarkTheme, platformName);

    // Room name
    options.roomName = createJitsiRoomName(roomId);

    // Parent node
    options.parentNode = document.querySelector('#meet');

    // User info
    options.userInfo = {
      displayName: getUserNameWithSector(this.auth.user),
      email: this.roomService.formMatrixUserId()
    };

    // ------ TOOLBAR BUTTONS ------
    // Electron
    if (this.electron.isElectronApp) {
      invalidButtons = invalidButtons.concat(['recording', 'fullscreen']);
    };

    // Instante meet
    if (roomId == this.roomService.externalRoomId) {
      validButtons.push('security')
    }

    // Update options value
    options.interfaceConfigOverwrite.TOOLBAR_BUTTONS = options
      .interfaceConfigOverwrite
      .TOOLBAR_BUTTONS
      .filter(opt => !invalidButtons.includes(opt))
      .concat(validButtons);

    // Config Overwrite
    options.configOverwrite.startWithVideoMuted = this.videoStatus == 'muted';
    options.configOverwrite.startWithAudioMuted = this.audioStatus == 'muted';

    // Get room
    const room = this.roomService.getRoom(roomId);

    // Set subject
    isDMRoom(room)
      ? options.configOverwrite.subject = DEFAULT_JITSI_SUBJECT
      : this.subject
        ? options.configOverwrite.subject = this.subject
        : options.configOverwrite.subject = DEFAULT_JITSI_SUBJECT;

    // JWT
    options.jwt = await this.createJWTToken(roomId, options.configOverwrite.subject);

    // Lang
    options.lang = getJitsiLang(this.translate.currentLang);

    // Return options
    return options;
  }

  /**
   * Create JWT Token
   * @returns {string} JWT
   */
  private async createJWTToken(roomId: string, subject?: string): Promise<string> {
    // Credentials
    const openIDCredentials = await this.matrixClient
      .getClient()
      .getOpenIdToken();

    // Get photo
    const photoUrl = this.auth.user.photoUrl;

    // Get display name
    const displayName = getUserNameWithSector(this.auth.user);

    // Get room members
    const roomMembers = this.roomService
      .getUsersFromMembers(roomId, this.contactService.contacts$.value, false)
      .map(user => user.id);

    // Generate JWT token
    return createMatrixJWTToken(
      roomId,
      photoUrl,
      displayName,
      openIDCredentials.access_token,
      openIDCredentials.matrix_server_name,
      this.lcsEvents.getOsInfo(),
      subject,
      roomMembers
    )
  }

  /**
   * Check if has only one participant
   * @returns {boolean} True if has only one participant
   */
  public hasOnlyOneParticipant(): boolean {
    return this.getParticipantsNumber() == 1;
  }

  /**
   * Check if is meet call
   * @returns {boolean} True if is meet call
   */
  public isMeetCall(): boolean {
    if (!this.activeRoomId) return false;
    const room = this.roomService.getRoom(this.activeRoomId);
    return isMeetGroup(room);
  }

  /**
   * Check if has no participants
   * @returns {boolean} True if has no participants
   */
  private hasNoParticipants(): boolean {
    return this.getParticipantsNumber() == 0;
  }

  /**
   * Send Event To Destroy Group Call
   */
  public async sendEventToDestroyGroupCall(): Promise<void> {
    // Send socket event
    this.sendSocketEvent(SOCKET_TOPIC.meeting_event_id);
    this.sendSocketEvent(SOCKET_TOPIC.meeting_ended);
  }

  /**
   * Delete meet from 'roomsInMeeting'
   * @param {string} roomId Room Id
   */
  public meetingEnded(roomId: string): Promise<void> {
    // Get jitsi room name
    const jitsiRoomName = createJitsiRoomName(roomId);

    // Database connections ref
    const myConnectionsRef = ref(this.database, `${this.roomsInMeetingPath}/${jitsiRoomName}`);

    // Remove meet info from Database
    return remove(myConnectionsRef);
  }

  /**
   * Get Rooms In Meeting
   * @returns {Observable<string[]>} Ids of the rooms in meeting
   */
  public getRoomsInMeeting(): void {
    // Ref
    this.roomsInMeetingRef = ref(this.database, this.roomsInMeetingPath);

    // Get inicial values
    onValue(this.roomsInMeetingRef, (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData: RoomsInMeeting = childSnapshot.val();
        this.roomsInMeeting.set(childData.roomId, { id: childKey, ...childData, });
      });
    }, { onlyOnce: true });

    // Listening when child added
    onChildAdded(this.roomsInMeetingRef, (data) => {
      const childKey = data.key;
      const childData: RoomsInMeeting = data.val();
      this.roomsInMeeting.set(childData.roomId, { id: childKey, ...childData });
    });

    // Listening when child removed
    onChildRemoved(this.roomsInMeetingRef, (data) => {
      const childData: RoomsInMeeting = data.val();
      this.roomsInMeeting.delete(childData.roomId);
    });
  }

  /**
   * Check if room is in meeting
   * @param {string} roomId Matrix room id
   * @returns {boolean} True if room is in meeting
   */
  public inMeeting(roomId: string = this.activeRoomId): boolean {
    return this.roomsInMeeting.has(roomId);
  }

  /**
   * Stop Listening Rooms In Meeting
   */
  public stopListeningRoomsInMeeting(): void {
    try {
      off(this.roomsInMeetingRef);
    } catch (error) { }
  }

  /**
   * Handle - Ready To Close
   */
  private async handleReadyToClose(e: any): Promise<void> {
    // Update Jitsi Status
    this.status.next(JitsiStatus.stop);

    // Destroy call group
    this.sendEventToDestroyGroupCall();

    // Close connection
    this.closeConnection();
  }

  /**
   * Handle - Video Conference Left
   */
  private handleVideoConferenceLeft(e: any): void {
    this.events.next('videoConferenceLeft');
  }

  /**
   * Handle - Error Occurred
   */
  private handleErrorOccurred(e: any): void {
    // Show error
    console.error('errorOccurred', e);
    // Send error to analytics
    this.analytics.logEvent(ANALYTICS_EVENTS.jitsi_error_occurred, e);
    // Update events
    this.events.next('errorOccurred');
  }

  /**
   * Handle - Mic Error
   */
  private handleMicError(e: any): void {
    // Show error
    console.error('micError', e);
    // Send error to analytics
    this.analytics.logEvent(ANALYTICS_EVENTS.mic_error, e);
    // Update events
    this.events.next('errorOccurred');
  }

  /**
   * Handle - Suspend Detected
   */
  private handleSuspendDetected(e: any): void {
    // Show error
    console.error('suspendDetected', e);
    // Send error to analytics
    this.analytics.logEvent(ANALYTICS_EVENTS.jitsi_suspend, e);
    // Update events
    this.events.next('suspendDetected');
  }

  /**
   * Handle - Peer Connection Failure
   */
  private handlePeerConnectionFailure(e: any): void {
    // Show error
    console.error('peerConnectionFailure', e);
    // Send error to analytics
    this.analytics.logEvent(ANALYTICS_EVENTS.peer_connection_failure, e)
    // Update events
    this.events.next('peerConnectionFailure');
  }

  /**
   * Handle - Screen Sharing Status Changed
   */
  private handleScreenSharingStatusChanged(isSharing: boolean): void {
    isSharing
      ? this.userPresence.setPresence(UserPresence.IN_PRESENTATION)
      : this.userPresence.setPresence(UserPresence.IN_MEETING)
  }

  /**
   * Handle - Video Conference joined
   */
  private handleVideoConferenceJoined(e: any): void {
    // Call observable
    this.status.next(JitsiStatus.progress);
    this.events.next('videoConferenceJoined');

    // Check if is the first participant in a external conference
    if (this.hasOnlyOneParticipant() && this.isExternalConference()) {
      // Update subject
      if (this.subject) this.updateConferenceName(this.getActiveRoomId(), this.subject);
    }
  }

  /**
   * Get active room id
   * @returns {string} Active room id
   */
  public getActiveRoomId(): string {
    return this.hasActiveConference()
      ? this.activeRoomId ?? this.auth.user.selfChatId
      : null;
  }

  /**
   * Get Call Event Type
   * @returns {CallEventTypes} Last Call Event Type
   */
  public getLastCallEventType(): CallEventTypes {
    return this.roomService.getLastCallEvent(this.activeRoomId)?.getContent()?.type;
  }

  /**
   * Send socket event
   * @param {SOCKET_TOPIC} topic Socket topic
   */
  public sendSocketEvent(topic: SOCKET_TOPIC, mEventId?: string): void {
    const data: JitsiSocketData = {
      eventId: mEventId ? mEventId : this.getLastEventId(),
      participantsNumber: this.getParticipantsNumber(),
      roomId: this.activeRoomId ?? this.auth.user.selfChatId,
      userId: this.auth.userId,
      platform: 'web',
    }
    this.socket.sendJitsiEvent(topic, data);
  }

  /**
   * Get last Event id
   * @returns {string} Event id
   */
  private getLastEventId(): string {
    const lastEvent = this.roomService.getLastCallEvent(this.activeRoomId);
    return lastEvent?.getContent()?.type == CallEventTypes.INVITE ? lastEvent.getId() : null;
  }

  /**
  * Close connection
  */
  public closeConnection(): void {
    // Unsubscribe
    this.subscriptions?.unsubscribe();

    // Remove window listenings
    window.removeAllListeners('message');

    // Clear temp conference id
    this.tempConferenceId = null;
  }

  /**
  * Close connection
  */
  public closeElectronConnection(): void {
    // Unsubscribe
    this.subscriptions?.unsubscribe();

    // Remove electron listenings
    this.removeElectronListeners();
  }

  /**
   * Remove electron listenings
   */
  private removeElectronListeners(): void {
    // Unsubscribe
    this.subscriptions?.unsubscribe();

    // Remove electron listenings
    this.electron.ipcRenderer.removeAllListeners('screenSharingStatusChanged');
    this.electron.ipcRenderer.removeAllListeners('videoConferenceJoined');
    this.electron.ipcRenderer.removeAllListeners('jitsiSuspendDetected');
    this.electron.ipcRenderer.removeAllListeners('videoConferenceLeft');
    this.electron.ipcRenderer.removeAllListeners('readyToClose');
    this.electron.ipcRenderer.removeAllListeners('closeWindow');
    this.electron.ipcRenderer.removeAllListeners('jitsiError');
  }

  /**
   * Ends the call
   */
  public hangUp(): void {
    this.electron.isElectronApp
      ? this.electron.ipcRenderer.send('endJitsi')
      : this.sendJitsiAction('hangup');
  }

  /**
   * Send jitsi action to another window
   * @param {ExternalAPICommands} type Jitsi action
   */
  public sendJitsiAction(type: ExternalAPICommands, data: any = {}): void {
    this.jitsiWindowHandle.postMessage({
      isJitsiAction: true,
      type,
      data,
      tempConferenceId: this.tempConferenceId
    }, window.location.origin);
  }

  /**
   *  Get Participants Number
   * @returns {number} Number of Participants
   */
  public getParticipantsNumber(): number {
    return this.participantsNumber;
  }
}
