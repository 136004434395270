import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AnalyticsSharedService,
  AuthService,
  ClientService,
  LoadingSpinnerService,
  StorageService,
  UserService
} from '@core/services';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CALL_EVENT, LAST_EVENTS_SUPPORT, NOT_MEMBER_STATUS, TYPING_TIMEOUT } from '@shared/constants';
import { ANALYTICS_EVENTS, CallEventTypes, MatrixRoomType, RoomFileType } from '@shared/enums';
import { FileResult, FilterRooms, InitRoomTypes, PREFIX_STORAGE, RoomStatusChange, User } from '@shared/models';
import {
  bindReplyToContent,
  bindReplyToEditContent,
  deleteTheLastVisitedRoom,
  formMatrixFromUserId,
  getContactIdFromMatrixId,
  getLSTenantId,
  getMatrixPath,
  getUserNameWithSector,
  isChatGPTRoom,
  isDMRoom,
  isMeetGroup,
  isReactionDeleted,
  isRedactionToReaction,
  parseReply,
  setTabIndex
} from '@shared/utils';
import {
  EventTimeline,
  EventType, IContent,
  ICreateRoomOpts,
  ISearchRequestBody,
  ISendEventResponse,
  MatrixEvent,
  Preset,
  Room,
  RoomMember,
  SearchOrderBy,
  Visibility
} from 'matrix-js-sdk';
import { SearchResult } from 'matrix-js-sdk/lib/models/search-result';
import { BehaviorSubject, first, lastValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  // Normal Atributes
  public selfRoomId: string;
  public externalRoomId: string;

  // Listeners
  public groupsUpdated = new BehaviorSubject<FilterRooms>(null);
  public hasRoomsChanges = new Subject<RoomStatusChange>();
  public lastVisitedRoom = new Subject<string>();
  public openGroupSettings = new Subject<string>();
  public editAMsg = new Subject<string>();
  public roomId = new BehaviorSubject<string>(null);
  public searchedTerm = new BehaviorSubject<string>(null);
  public replyTo = new BehaviorSubject<MatrixEvent>(null);

  // Maps and Sets
  private directs = new Map<string, Room>();
  private groups = new Map<string, Room>();
  public typingMembers = new Set<RoomMember>();
  public lastEvents = new Map<string, Map<string, MatrixEvent>>
  public invites = new Map<string, RoomMember>();

  constructor(
    private userService: UserService,
    private storage: StorageService,
    private authService: AuthService,
    private clientService: ClientService,
    private spinner: LoadingSpinnerService,
    private translate: TranslateService,
    private analytics: AnalyticsSharedService,
    public http: HttpClient
  ) { }

  /**
   * Get Display name
   * @returns {string} Display name
   */
  public getDisplayName(contact: User): string {
    return contact?.id == this.authService.userId
      ? `${contact?.displayName} (${this.translate.instant('you')})`
      : contact?.displayName;
  }

  /**
   * Get room name
   * @param {string} roomId Room id
   * @returns  {string} Room name
   */
  public getRoomName(roomId: string): string {
    return this.getRoom(roomId)?.name ?? '';
  }

  /**
   * Check if room is a group
   * @param {Room | string} room Matrix room or Matrix room id
   * @returns {boolean} True if is a group
   */
  public isRoomAGroup(room: string): boolean {
    return this.groups.has(room);
  }

  /**
   * Check if is your self
   * @param {string} userId User id
   * @returns {boolean} True if is your self
   */
  public isYourSelf(userId: string, isMUserId: boolean = false): boolean {
    return isMUserId
      ? userId === this.formMatrixUserId()
      : userId.toLowerCase() === this.authService.userId.toLowerCase();
  }

  /**
   * Set user rooms
   * @param {Room[]} rooms All user rooms
   */
  public updateGroupList(room: Room, membership: string) {
    // Verify if timeline is ready
    if (!this.groups) return;

    // Get client
    const client = this.clientService.getClient();

    // init room id
    const roomId: string = room.roomId;

    // Check membership
    switch (membership) {
      case 'invite':
        // Join in the room
        client.joinRoom(roomId)
          .then(() => {
            // If is DM invite
            if (this.isDMInvite(room)) {
              const targetUserId = this.guessDMRoomTargetId(room, client.getUserId());
              this.addRoomToMDirect(roomId, targetUserId);
            } else {
              this.addGroupToRoomsLst(room);
            }
          });
        break;

      case 'join':
        this.addGroupToRoomsLst(room);
        break;

      case 'leave':
      case 'kick':
      case 'ban':
        // Remove room from list
        if (this.directs.has(roomId)) this.removeDM(roomId);
        else if (this.groups.has(roomId)) this.removeGroup(roomId)

        // Update room list
        if (this.roomId.value == roomId) {
          this.updateRoom(null, false);
          this.deleteTheLastVisitedRoom();
        }
        break;

      default:
        break;
    }
  }

  /**
   * Update roomList when m.direct changes
   * @param {MatrixEvent} event
   */
  public updateRoomList(event: MatrixEvent) {
    // Verify if is m.direct event
    if (event.getType() !== 'm.direct') return;

    // Get current direct rooms
    const latestMDirects = this.getMDirects();

    // Get client
    const matrixClient = this.clientService.getClient();

    // For each direct room
    latestMDirects.forEach((directId) => {
      // Check if room is already in DMs
      if (this.directs.has(directId)) return;

      // Get room
      const myRoom = matrixClient.getRoom(directId);

      // Check if room exist
      if (myRoom === null) return;

      // Add room to DMs
      this.directs.set(directId, myRoom);

      // Add new room to list of rooms
      this.hasRoomsChanges.next({ room: myRoom, type: 'dm' });

      // Remove room from groups and notify listeners
      if (myRoom.getMyMembership() === 'join') this.removeGroup(directId);
    });
  }

  /**
   * Add room group to rooms list
   * @param {Room} room Room
   */
  public addGroupToRoomsLst(room: Room) {
    // Verify if is a group
    if (isDMRoom(room)) return;

    // Get room id
    const roomId = room.roomId;

    // Add room to list
    this.groups.set(roomId, room);

    // Add new room to list
    this.hasRoomsChanges.next({
      room: room,
      type: 'group'
    });

    // Notifier listeners
    this.groupsUpdated.next({
      name: room.name,
      desc: '',
      isGroup: true,
      groupId: room.roomId,
      type: MatrixRoomType.NORMAL
    });

  }

  public deleteTheLastVisitedRoom() {
    deleteTheLastVisitedRoom();
    this.lastVisitedRoom.next('');
  }

  /**
   * Create Unknown User
   * @returns {User} Unknown user
   */
  public createUnknownContact(): User {
    return {
      id: '',
      email: '',
      extension: '',
      passwordExten: '',
      displayName: this.translate.instant('Unknown'),
      photoUrl: 'assets/images/avatar-grey.svg',
    };
  }

  /**
   * Invite contact
   * @param {User} contact Contact to be invited
   * @param {string} roomId Room id
   */
  public async inviteContact(contact: User, roomId: string) {
    try {
      const matrixClient = this.clientService.getClient(); // Get client
      const mUserId = this.formMatrixUserId(contact.id); // Get matrix user id
      await matrixClient.invite(roomId, mUserId); // Invite user
    } catch (error) {
      console.error('Fails on invite user', error);
    }
  }

  /**
   * Form group name by members names
   * @param {User[]} contacts Groups members
   * @returns {string} Group name
   */
  public formGroupName(contacts: User[]): string {
    // Get all members of the group
    const members = contacts.concat([this.authService.user]);

    // Sort by alphabetically order and get only user first name
    const membersNames =
      members
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((value) => value?.displayName?.split(' ')[0]) ?? [];

    // Return group name
    switch (true) {
      case membersNames.length == 2:
        return `${membersNames[0]}, ${membersNames[1]}`;

      case membersNames.length > 2:
        return `${membersNames[0]}, ${membersNames[1]}, +${membersNames.length - 2
          }`;
    }
  }

  /**
   * Create group
   * @param {User[]} contacts Users that will be invited
   * @returns {Promise<string>} Room id
   */
  public async createGroup(contacts: User[], context: ('DM' | 'button')): Promise<string> {
    // Init flag
    let room_id: string;

    try {
      // Show spinner
      this.spinner.show();

      // Form group name
      const groupName = this.formGroupName(contacts);

      // Initiate variables
      const matrixClient = this.clientService.getClient();
      const usersIds = [];

      // Get all matrix ids
      contacts.forEach((contact) => {
        usersIds.push(this.formMatrixUserId(contact.id));
      });

      // Create Room options
      const options: ICreateRoomOpts = {
        name: groupName,
        visibility: Visibility.Private,
        invite: usersIds,
        power_level_content_override: {
          ban: 0,
          invite: 0,
          kick: 0,
          events_default: 0,
          redact: 0,
          users_default: 50,
        },
      };

      // Send event to analytics
      this.sendInitGroupEventToAnalytics(context);

      // Create group
      room_id = (await matrixClient.createRoom(options)).room_id;

      // Update room id
      this.updateRoom(room_id, true);

      // Notifier listeners
      this.groupsUpdated.next({
        name: groupName,
        desc: contacts
          .map((value) => value?.displayName?.trim())
          .filter((value) => !!value)
          .join(', '),
        isGroup: true,
        groupId: room_id,
        type: MatrixRoomType.NORMAL
      });

      // Open group settings
      this.openGroupSettings.next(room_id);
    } catch (error) {
      // Show error
      console.error(error);
    } finally {
      // Hide spinner
      this.spinner.hide();
    }

    // Return room id
    return room_id;
  }

  /**
   * Create Self Chat
   */
  public async createSelfChat(): Promise<string> {
    // Get self chat from user
    const { selfChatId, id } = this.authService.user;

    // Check if self chat exist
    if (!selfChatId) {
      // Form matrix user id
      const mUserId = this.formMatrixUserId(id);

      // Get or Create room id
      this.selfRoomId = await this.createDM(mUserId);

      // Update data from firebase
      this.userService.updateOnlyInFire({ selfChatId: this.selfRoomId }, id)
    } else {
      this.selfRoomId = selfChatId;
    }

    // Set external room id
    this.externalRoomId = this.selfRoomId;

    return this.selfRoomId;
  }


  /**
   * Check if can show the group
   * @param {string} roomId Room Id
   * @returns {boolean} True if can show the group
   */
  public canShowRoom(roomId: string, isAGroup: boolean): boolean {
    return isAGroup ? this.isMemberInRoom(roomId) : true
  }

  /**
   * Check if user is member in the room
   * @param {string} room  Room id or room
   * @param {string} userId User id
   * @returns {boolean} True if user is member in the room
   */
  public isMemberInRoom(room: string | Room, userId: string = this.authService.userId): boolean {
    return !!this.getValidMembers(room)?.find(member => member.name == userId?.toLowerCase())
  }

  /**
   * Create DM with the user
   * @param {string} userId Matrix user id
   * @param {InitRoomTypes} context Context
   * @returns {Promise<string>} Room id
   */
  public async createDM(
    userId: string,
    context?: InitRoomTypes,
    hideSpinner: boolean = false,
    isEncrypted = false
  ): Promise<string> {
    // Init room id
    let room_id: string;

    // Check if user is me
    const isMe = userId.toLowerCase() == this.formMatrixUserId();

    // Init options
    let options: ICreateRoomOpts = {
      is_direct: true,
      visibility: Visibility.Private,
      preset: Preset.TrustedPrivateChat,
      initial_state: [],
    };

    try {
      // Get Client
      const matrixClient = this.clientService.getClient();

      // Get user id in lower case
      userId = userId.toLocaleLowerCase();

      // Check if room already exist
      const room = this.hasDMWith(userId);

      // Verify if room already exist
      if (room) {
        // Get room if
        const roomId = room.roomId;

        // Return room id
        return roomId;
      };

      // Check if it's me
      if (isMe) {
        options = {
          ...options,
          invite: [],
          name: this.authService.userId.toLowerCase()
        }
      } else {
        // Set room options
        options = {
          ...options,
          invite: [userId],
        };
      }

      // Check if is encrypted
      if (isEncrypted) {
        options.initial_state.push({
          type: 'm.room.encryption',
          state_key: '',
          content: {
            algorithm: 'm.megolm.v1.aes-sha2',
          },
        });
      }

      // Show spinner
      if (!hideSpinner) this.spinner.show();

      // Send event to analytics
      if (context) this.sendInitDMEventToAnalytics(context)

      // Create room
      room_id = (await matrixClient.createRoom(options)).room_id;

      // Added room to local rooms
      await this.addRoomToMDirect(room_id, userId);

      // Set timeout to wait for the room to be created - 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
    } catch (error) {

      // Show error
      console.error(error);

    } finally {
      // Hide spinner
      if (!hideSpinner) this.spinner.hide();
    }

    // Return room id
    return room_id;
  }

  /**
   * Send Event To Analytics
   * @param {InitRoomTypes} context Context
   */
  public sendInitDMEventToAnalytics(context: InitRoomTypes): void {
    switch (context) {
      case 'contact':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_dm_by_contact_button)
        break;
      case 'chat':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_dm_by_chat_button)
        break;
      case 'search':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_dm_by_search)
        break;
      case 'forward':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_dm_by_forward_button)
        break;
    }
  }

  /**
   * Send Event To Analytics
   * @param {('DM' | 'button')} context Context
   */
  public sendInitGroupEventToAnalytics(context: ('DM' | 'button')): void {
    switch (context) {
      case 'DM':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_group_by_dm)
        break;
      case 'button':
        this.analytics.logEvent(ANALYTICS_EVENTS.init_group_by_button)
        break;
    }
  }

  /**
   * Check if already exists DM with userId
   * @param userId
   * @returns RoomId if exists
   */
  public hasDMWith(userId: string): Room {
    return [...this.directs.values()].find((room) => {

      // Get room members without yourself
      const roomMembers = [...room.getMembers()]
        .filter(member => member.name !== this.authService.userId.toLowerCase()); // Remove yourself from the array

      switch (true) {
        case roomMembers.length == 1 && roomMembers[0].userId == userId: // Valid chat
          return true;
        case roomMembers.length == 0 && userId == this.formMatrixUserId(): // Self chat
          return true;

        default:
          return false;
      }
    });
  }

  /**
   * Get members name
   * @returns {string} Members name
   */
  public getMembersName(contacts: User[], memberNames: string[]): string[] {
    // Check if both arrays are valid
    if (!contacts || !memberNames) return [];

    // Get members contact
    const membersContact = memberNames.map((name) =>
      contacts.find((contact) => contact.id.toLocaleLowerCase() == name)
    );

    // Return members name
    return membersContact
      .map((value) => value?.displayName?.trim())
      .filter((value) => !!value);
  }

  /**
   * Get room by id
   * @param {string} rId Room id
   * @returns {Room} Room
   */
  public getRoom(rId: string = this.roomId.value): Room {
    return this.clientService.getClient().getRoom(rId);
  }

  /**
   * Get room type
   * @param {string | Room} r Room or room id
   * @returns {string} Room type
   */
  public getRoomType(r: string | Room): MatrixRoomType {
    // Get room
    const room = typeof r === "string" ? this.getRoom(r) : r;

    // Get room type
    switch (true) {
      case isChatGPTRoom(room):
        return MatrixRoomType.GPT;
      case isMeetGroup(room):
        return MatrixRoomType.MEET;
      default:
        return MatrixRoomType.NORMAL;
    }
  }

  /**
   * Check if is a meet group by room id
   */
  public checkIfIsMeetGroupByRoomId(roomId: string): boolean {
    return isMeetGroup(this.getRoom(roomId));
  }

  /**
   * Get valid members
   * @param {string} roomId Room id
   * @returns {RoomMember[]} Members of the room
   */
  public getValidMembers(roomId: string | Room): RoomMember[] {
    // Get room
    let room: Room;

    // Get room
    if (typeof roomId === 'string') room = this.clientService.getClient()?.getRoom(roomId);
    else room = roomId

    // Get valid members
    return room
      ?.getMembers()
      ?.filter((value) => !NOT_MEMBER_STATUS.includes(value.membership)) ?? [];
  }

  /**
   * Update Reply
   * @param {MatrixEvent} mEvent Matrix Event
   */
  public updateReplyTo(mEvent?: MatrixEvent): void {
    this.replyTo.next(mEvent)
  }

  /**
   * Get users from room members
   * @param {string} roomId Room id
   * @param {User[]} contacts Contacts
   * @param {boolean} withOwner If return current user in co
   */
  public getUsersFromMembers(roomId: string, contacts: User[], withOwner: boolean = true): User[] {
    // Initiate users
    let users = withOwner ? [this.authService.user] : [];

    // Get members name
    const members = this.getValidMembers(roomId)?.map((value) => value.name); // Get names

    // Get users by members
    const usersMembers = contacts.filter((value) =>
      members.includes(value.id.toLocaleLowerCase())
    );

    // Updated local users
    users = users.concat(usersMembers);

    // Return users
    return [...new Set(users)];
  }

  /**
   * Kick user from the room
   * @param {string} roomId Room id
   * @param {string} userId Matrix user id
   */
  public async kick(roomId: string, userId: string): Promise<void> {
    const mx = this.clientService.getClient();
    await mx.kick(roomId, userId);
  }

  /**
   * Check if user is member of the room
   * @param {string} userId User id
   * @param {string} roomId Room id
   * @returns {boolean} True if user is a member of the room
   */
  public isMemberOfTheRoom(userId: string, roomId: string): boolean {
    // Check if room id and user id is valid
    if (!roomId || !userId) return false;

    // Get matrix client
    const mx = this.clientService.getClient();

    // Get room
    const room = mx.getRoom(roomId);

    // Get member
    const member = [...room.getMembers()]
      .find((member) => member.name == userId.toLowerCase());

    // Check
    return member && !NOT_MEMBER_STATUS.includes(member?.membership);
  }

  /**
   * Update typing list
   * @param {MatrixEvent} event Matrix Event
   * @param {RoomMember} member Room Member
   */
  public updateTypingList(event: MatrixEvent, member: RoomMember) {
    // Get matrix client
    const matrixClient = this.clientService.getClient();

    // Not add current user to array
    if (member.userId == matrixClient.getUserId()) return;

    // Add or remove if is not typing
    member.typing
      ? this.typingMembers.add(member)
      : this.typingMembers.delete(member);
  }

  /**
   * Set a profile picture to User
   * @param {string | ArrayBuffer} picture file url
   */
  public async setGroupPhoto(
    picture: string | ArrayBuffer,
    roomId: string,
    isMeet: boolean
  ): Promise<string> {
    if (!picture) return;

    // Get client
    const mx = this.clientService.getClient();

    // Get tenant id
    const tenantId: string =
      this.authService.user.companyId ??
      localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);

    // Get storage path
    const storagePath = isMeet
      ? this.getMeetAvatarPath(tenantId, roomId)
      : this.getGroupAvatarPath(tenantId, roomId);

    // Upload to Firebase Storage
    await this.storage.uploadFile(storagePath, picture);

    // Get photo url
    const url: string = await this.storage.getDownloadURL(storagePath);

    // Send event
    await mx.sendStateEvent(roomId, EventType.RoomAvatar, { url }, '');

    // Return url
    return url;
  }

  /**
   * Get group avatar
   * @param {string} tenantId Tenant id
   * @param {string} roomId Group id
   * @returns {string} Avatar url
   */
  public getGroupAvatarPath(tenantId: string, roomId: string): string {
    return `${tenantId}/collaboration/groups/${roomId}`;
  }

  /**
   * Get meet Avatar path
   * @param {string} tenantId Tenant id
   * @param {string} roomId Identifier
   * @returns {string} Avatar path
   */
  public getMeetAvatarPath(tenantId: string, roomId: string): string {
    return `${tenantId}/collaboration/meetings/${roomId}`;
  }

  /**
   * Get group avatar
   * @param {string} roomId Group id
   * @returns {string} Avatar url
   */
  public getGroupAvatar(roomId: string): string {
    // Get room
    const room = this.getRoom(roomId)

    // Return avatar
    return room?.getMxcAvatarUrl() ?? this.getDefaultAvatar(room);
  }

  /**
   * Get default avatar
   * @param {Room} room Room
   * @returns {string} Default avatar
   */
  public getDefaultAvatar(room: Room): string {
    switch (true) {
      // If is chatgpt room
      case isChatGPTRoom(room):
        return 'assets/icons/chatgpt.svg';

      // If room is DM
      case isDMRoom(room):
        return 'assets/images/avatar.svg';

      // If room is meet group
      case isMeetGroup(room):
        return 'assets/images/scheduled-avatar.svg';

      // Default group avatar
      default:
        return 'assets/images/group-avatar.svg';
    }
  }

  /**
   * List files in the room
   * @param {string} roomId Room id
   * @returns {Promise<FileResult[]>} List of files
   */
  public async listFiles(roomId: string, types: RoomFileType[] = []): Promise<FileResult[]> {
    // Get company id
    const tenantId: string =
      this.authService.user.companyId ??
      localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);

    // Get storage path
    let path = getMatrixPath(tenantId, roomId);

    // Add types to path
    if (types.length > 0) {
      // First type with ?
      path += `?type=${types[0]}`;

      // Add other types
      types.slice(1).forEach((type) => path += `&type=${type}`);
    }

    // Form files
    const files: FileResult[] = await lastValueFrom(this.http.get<FileResult[]>(path).pipe(first()));

    // Return files
    return files;
  }

  /**
   * Set room admins
   * @param {string} adminId Admin id - Admin matrix id to make request to the server
   * @param {string} roomId Room id - Room id to set the admin
   * @param {string[]} nAdmins New Admins - Admins matrix id to add as admin
   * @param {string[]} rAdmins Removed Admins - Admins matrix id to remove as admin
   */
  public async setRoomAdmins(adminId: string, roomId: string, nAdmins: string[] = [], rAdmins: string[] = []): Promise<void> {
    // Get company id
    const tenantId = getLSTenantId();

    // Get path
    const path = getMatrixPath(tenantId, roomId, 'admins');

    // Form body
    const body = { adminUserId: adminId };

    // Add new admins if exist
    if (nAdmins.length > 0) body['nUserIds'] = nAdmins;

    // Add removed admins if exist
    if (rAdmins.length > 0) body['rUserIds'] = rAdmins;

    // Form files
    await lastValueFrom(this.http.put(path, body).pipe(first()));

  }

  /**
   * Delete group avatar
   * @param {string} roomId Group id
   */
  public async deleteGroupAvatar(roomId: string, isMeet: boolean) {
    // Get client
    const mx = this.clientService.getClient();

    // Get company id
    const tenantId: string =
      this.authService.user.companyId ??
      localStorage.getItem(`${PREFIX_STORAGE}:tenantId`);

    // Get storage path
    const storagePath = isMeet
      ? this.getMeetAvatarPath(tenantId, roomId)
      : this.getGroupAvatarPath(tenantId, roomId);

    // Remove file from storage
    await this.storage.removeFile(storagePath);

    // Send event to update avatar
    await mx.sendStateEvent(roomId, EventType.RoomAvatar, { url: null }, '');
  }
  /**
   * Get the name of the members of this room
   * @param members
   * @returns {string[]} Members name
   */
  public getMembersNameOfThisRoom(
    members: RoomMember[],
    roomId: string = this.roomId.value
  ): string[] {
    return members
      .filter((value) => value.roomId == roomId)
      .map((value) => value.name);
  }

  /**
   * Check if can show typing
   * @returns {boolean} True if can show typing text
   */
  public isTyping(roomId = this.roomId.value): boolean {
    return (
      this.getMembersNameOfThisRoom([...this.typingMembers], roomId).length > 0
    );
  }

  /**
   * Check if current user is the founder of the room
   * @param {Room} room DM | Group
   * @returns {boolean} True if is the founder
   */
  public isFounder(room: Room): boolean {
    // Get client
    const mx = this.clientService.getClient();

    // Verify if is creator
    return mx.getUserId() == room.getCreator();
  }

  /**
   * Check if current user is admin of the room
   * @param {string} roomId DM id | Group id
   * @param {string} userId Matrix user id
   * @returns {boolean} True if is an administrator
   */
  public isAdmin(
    roomId: string,
    userId: string = this.formMatrixUserId()
  ): boolean {
    if (!roomId) return false;

    // Get room by id
    const members = this.getValidMembers(roomId);

    // Get power level of the user
    const powerLevel = members.find((member) => member.userId == userId)?.powerLevel ?? 50;

    // Verify if is creator
    return powerLevel == 100;
  }

  /**
   * Send typing event
   * @param {boolean} isT is typing
   */
  public sendIsTyping(isT: boolean) {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Room id
    const roomId = this.roomId.value;

    // Timeout
    const timeout = isT ? TYPING_TIMEOUT : undefined;

    // Send typing
    matrixClient.sendTyping(roomId, isT, timeout);
  }

  /**
   * Clear typing
   */
  public clearTyping() {
    this.typingMembers.clear();
  }

  /**
   * Send ChatGPT message
   */
  public sendChatGPTMessage(msg: string): Promise<ISendEventResponse> {
    // Get matrix client
    const matrixClient = this.clientService.getClient();

    // Form content
    const content: IContent = this.formMsgContent(msg, 'm.text');

    // Add info if is chatgpt
    content.isChatGPT = true;

    // Get room id
    const roomId = this.authService.user.chatGPTRoomId;

    // Send message to room
    return matrixClient.sendMessage(roomId, content);
  }

  /**
   * Get contact by id
   * @param {string} id Contact id
   * @param {User[]} contacts All contacts
   * @returns {User} Contact
   */
  public getContactById(id: string, contacts: User[]): User {
    return contacts?.find(contact => contact.id.toLocaleLowerCase() === id) ?? this.createUnknownContact();
  }

  /**
   * Get contact by matrix id
   * @param {string} sender Matrix user id
   * @param {User[]} contacts All contacts
   * @returns {User} Contact
   */
  public getContactByMatrixUserId(sender: string, contacts: User[]): User {
    const id = getContactIdFromMatrixId(sender);
    return this.getContactById(id, contacts);
  }

  /**
   * Update room
   * @param {roomId} roomId Room id
   */
  public updateRoom(roomId: string, isGroup: boolean) {
    if (!roomId) return;
    setTabIndex(isGroup ? 1 : 0);
    this.roomId.next(roomId);
  }

  /**
   * Set room name
   * @param {string} roomId Room id
   * @param {string} newName New room name
   */
  public async setRoomName(roomId: string, newName: string) {
    const mx = this.clientService.getClient();
    await mx.setRoomName(roomId, newName);
  }

  /**
   * Search term in the current room
   * @param {string} term Term to search
   * @returns {Promise<MatrixEvent[]>} List of Matrix Event
   */
  public async searchTerm(term: string): Promise<MatrixEvent[]> {
    // Get client
    const mx = this.clientService.getClient();

    // Form body to search
    const body: ISearchRequestBody = {
      search_categories: {
        room_events: {
          search_term: term,
          filter: {
            limit: 100,
            rooms: [this.roomId.value],
            types: [EventType.RoomMessage]
          },
          order_by: SearchOrderBy.Recent,
          event_context: {
            before_limit: 0,
            after_limit: 0,
            include_profile: true,
          },
        },
      },
    };

    // Search term
    const res = await mx.search({ body });

    // Process data
    const data = mx.processRoomEventsSearch({
      _query: body,
      results: [],
      highlights: [],
    }, res);

    // Get results
    const results: SearchResult[] = data.results;

    // Get events
    const events = results.map(r => r.context.getEvent());

    // Return matrix events
    return events;
  }

  /**
   * Remove group from the list
   * @param {string} groupId Group id
   */
  public removeGroup(groupId: string) {
    // Remove from list
    this.groups.delete(groupId);

    // Notifier listeners
    this.hasRoomsChanges.next({
      room: this.getRoom(groupId),
      type: 'group'
    });
  }

  /**
   * Remove dm from the list
   * @param {string} dmId Dm id
   */
  public removeDM(dmId: string) {
    // Remove from list
    this.directs.delete(dmId);

    // Notifier listeners
    this.hasRoomsChanges.next({
      room: this.getRoom(dmId),
      type: 'dm'
    });
  }

  /**
   * Store invite events
   * @param {RoomMember} member Room Member
   */
  public storeInviteEvents(member: RoomMember) {
    // Store invite events
    switch (member.membership) {
      case 'invite':
        this.invites.set(member.roomId, member);
        break;
    }
  }

  /**
   * Auto join
   * @param {RoomMember} member Room Member
   */
  public async autoJoin(member: RoomMember) {
    try {
      // Get client
      const matrixClient = this.clientService.getClient();

      // Check if is invite
      if (member.membership === 'invite') {
        try {
          // Join room
          const resultRoom = await matrixClient.joinRoom(member.roomId);

          // Get room
          const room = matrixClient.getRoom(member.roomId);

          // If is DM, add to m.direct
          if (this.isDMInvite(room)) {
            const targetUserId = this.guessDMRoomTargetId(
              matrixClient.getRoom(resultRoom.roomId),
              matrixClient.getUserId()
            );
            await this.addRoomToMDirect(resultRoom.roomId, targetUserId);
          } else {
            this.addGroupToRoomsLst(room);
          }
        } catch (error) {
          // Log error
          console.error(error);

          // Check if room is not found
          if (error.httpStatus == 404)
            this.handleErrors(ANALYTICS_EVENTS.auto_join_error_404, error);
        }
      }
    } catch (error) {
      // Log error
      this.handleErrors(ANALYTICS_EVENTS.auto_join_error, error);
    }
  }

  /**
   * Handle errors
   * @param {ANALYTICS_EVENTS} eventType Analytics event type
   * @param error Error
   */
  private handleErrors(eventType: ANALYTICS_EVENTS, error: any) {
    console.error(eventType, error);
    this.analytics.logEvent(eventType, error.toString());
  }

  /**
   * Added room to msg direct
   * @param {string} roomId
   * @param {string} userId
   */
  public addRoomToMDirect(roomId: string, userId: string = this.formMatrixUserId()) {
    const matrixClient = this.clientService.getClient();
    const mDirectsEvent = matrixClient.getAccountData('m.direct');
    let userIdToRoomIds = {};

    if (typeof mDirectsEvent !== 'undefined')
      userIdToRoomIds = mDirectsEvent.getContent();

    // remove it from the lists of any others users
    // (it can only be a DM room for one person)
    Object.keys(userIdToRoomIds).forEach((thisUserId) => {
      const roomIds = userIdToRoomIds[thisUserId];

      if (thisUserId !== userId) {
        const indexOfRoomId = roomIds.indexOf(roomId);
        if (indexOfRoomId > -1) {
          roomIds.splice(indexOfRoomId, 1);
        }
      }
    });

    // now add it, if it's not already there
    if (userId) {
      const roomIds = userIdToRoomIds[userId] || [];
      if (roomIds.indexOf(roomId) === -1) {
        roomIds.push(roomId);
      }
      userIdToRoomIds[userId] = roomIds;
    }

    return matrixClient.setAccountData('m.direct', userIdToRoomIds);
  }

  /**
   * Check if is direct
   * @param room
   * @returns
   */
  private isDMInvite(room: Room): boolean {
    const matrixClient = this.clientService.getClient();
    const me = room.getMember(matrixClient.getUserId());
    const myEventContent = me?.events?.member.getContent();
    return !!myEventContent?.is_direct;
  }

  /**
   * Get oldest room member id
   * @param room
   * @param myUserId
   * @returns
   */
  private guessDMRoomTargetId(room: Room, myUserId: string): string {
    let oldestMember: RoomMember;
    let oldestMemberTs: number;

    // Pick the joined user who's been here longest (and isn't us),
    room.getJoinedMembers().forEach((member: RoomMember) => {
      if (member.userId === myUserId) return;

      if (
        typeof oldestMemberTs === 'undefined' ||
        (member?.events.member && member?.events.member.getTs() < oldestMemberTs)
      ) {
        oldestMember = member;
        oldestMemberTs = member?.events.member.getTs();
      }
    });
    if (oldestMember) return oldestMember.userId;

    // if there are no joined members other than us, use the oldest member
    [...room.getLiveTimeline().getState(EventTimeline.FORWARDS).getMembers()].forEach((member) => {
      if (member.userId === myUserId) return;

      if (
        typeof oldestMemberTs === 'undefined' ||
        (member?.events.member && member?.events.member.getTs() < oldestMemberTs)
      ) {
        oldestMember = member;
        oldestMemberTs = member?.events.member.getTs();
      }
    });

    if (typeof oldestMember === 'undefined') return myUserId;
    return oldestMember.userId;
  }

  /**
   * Populate rooms
   */
  public populateRooms(): void {
    // Clear rooms
    this.directs.clear();
    this.groups.clear();

    // For each room check the room type
    this.getValidRooms().forEach((room) => {
      // Check if is DM
      const isDM = isDMRoom(room);

      // Get room id
      const roomId = room.roomId;

      // Check room type and add to list
      switch (true) {
        // Invalid
        case room.isSpaceRoom(): // Space
          return;

        // DM
        case isDM:
          this.directs.set(roomId, room);
          this.groups.delete(roomId);
          break;

        default:
          this.groups.set(roomId, room);
          this.directs.delete(roomId);
          break;
      }
    });
  }

  /**
   * Form matrix user id from contact
   * @param {string} userId Contact id
   * @returns {string} Matrix user id
   */
  public formMatrixUserId(userId: string = this.authService.userId): string {
    return formMatrixFromUserId(userId);
  }

  /**
   * Check if event is yours
   * @param {MatrixEvent} event Matrix Event
   * @returns {boolean} True if event is yours
   */
  public isTheOwner(event: MatrixEvent): boolean {
    return this.formMatrixUserId() == event.getSender();
  }

  /**
   * Form message content
   * @param {string} msg Message
   * @param {string} msgType Msg type
   * @returns {IContent} Msg content
   */
  public formMsgContent(msg: string, msgType: string = 'm.text'): IContent {
    return {
      body: msg,
      msgtype: msgType,
      displayname: getUserNameWithSector(this.authService.user),
      avatar_url: this.authService.user.photoUrl,
    };
  }

  /**
   * Generate temp event id
   * @param {string} roomId Room id
   * @returns {string} {string} Temp event id
   */
  public generateTempEventId(roomId: string): string {
    return `m-${Date.now()}-${Math.random()}-${roomId}`;
  }
  /**
   * Send message
   */
  public async sendMessage(roomId: string, content: IContent): Promise<{ eventId: string, content: IContent }> {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Send message to room
    const resp = await matrixClient.sendMessage(roomId, content);

    // Return response
    return { eventId: resp.event_id, content };
  }

  /**
   * Forward message
   */
  public async forwardEvent(roomId: string, event: MatrixEvent): Promise<void> {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Get content
    let content: IContent = {
      ...event.getContent(),
      forward: !!event.getContent().forward
        ? true
        : !(event.getSender() == this.formMatrixUserId()),
      displayname: getUserNameWithSector(this.authService.user),
      avatar_url: this.authService.user.photoUrl,
    }

    // Check if is a reply
    if (event.replyEventId) {
      content.body = parseReply(content.body).body; // Get body
      content['m.relates_to'] = {}; // Remove reply ref
    }

    // Send message to room
    await matrixClient.sendEvent(roomId, event.getType(), content);
  }

  /**
   * Send media
   * @param {string} roomId Room id
   * @param {IContent} content Msg content
   * @param {MatrixEvent} eventToReply Reply event
   */
  public async sendMedia(
    roomId: string,
    content: IContent,
    eventToReply?: MatrixEvent,
  ): Promise<{ eventId: string, content: IContent }> {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Form notification content
    let notificationContent: IContent = {
      ...content,
      displayname: getUserNameWithSector(this.authService.user),
      avatar_url: this.authService.user.photoUrl,
    };

    // If is reply bind content
    if (eventToReply) {
      notificationContent = {
        ...notificationContent,
        ...bindReplyToContent(eventToReply, content)
      }
    }

    // Send message to room
    const resp = await matrixClient.sendMessage(roomId, notificationContent);

    // Send msg
    return { eventId: resp.event_id, content: notificationContent };
  }

  /**
   * Get event by id in the room
   * @param {string} eventId Event id
   * @returns {MatrixEvent} Matrix Event
   */
  public getEventById(eventId: string): MatrixEvent {
    const room = this.getRoom(this.roomId.value)
    return room.findEventById(eventId)
  }

  /**
   * Get directs id
   * @returns {Set<string>} Directs ids
   */
  private getMDirects(): Set<string> {
    const matrixClient = this.clientService.getClient();
    const mDirectsId = new Set<string>();
    const mDirect = matrixClient.getAccountData('m.direct')?.getContent();

    if (typeof mDirect === 'undefined') return mDirectsId;

    Object.keys(mDirect).forEach((direct) => {
      mDirect[direct].forEach((directId) => mDirectsId.add(directId));
    });

    return mDirectsId;
  }

  /**
   * Get the timestamp of the last message in the room
   * @param room
   * @returns
   */
  public getLastMessageTimestamp(room: Room): number {
    // Get last event
    let lastEvent = this.getLastMsgEvent(room.roomId);

    // If not exist get create event
    if (!lastEvent) {
      // Get create event
      const createdEvent = room
        .getLiveTimeline() // Get timeline
        .getState(EventTimeline.FORWARDS) // Get state
        .getStateEvents(EventType.RoomCreate); // Get create event

      // Get last event
      lastEvent = !!createdEvent.length
        ? createdEvent[0]
        : null;
    }

    // Return timestamp or create date
    return lastEvent?.getDate()?.getTime() ?? new Date().getTime();
  }

  /**
   * Find the last msg event
   * @param {string} roomId Room id
   * @returns {MatrixEvent} the last msg event
   */
  public getLastMsgEvent(roomId: string): MatrixEvent {
    return this.getLastValidEvent(roomId, LAST_EVENTS_SUPPORT); // Get most recent event
  }

  /**
   * Get last valid event to this room
   * @param {string} roomId Room id
   * @param {string[]} arrayOfEventTypes Array Of Event Types
   * @returns {MatrixEvent} Last valid matrix event
   */
  public getLastValidEvent(roomId: string, arrayOfEventTypes?: string[]): MatrixEvent {
    const rLEvents: MatrixEvent[] = []; // Last room events
    const allLREvents = this.lastEvents.get(roomId); // All last room events

    // If room not exist
    if (!allLREvents) return null;

    // Get only necessary events
    arrayOfEventTypes
      ? arrayOfEventTypes.forEach(type => rLEvents.push(allLREvents.get(type)))
      : allLREvents.forEach(e => rLEvents.push(e))

    // Get most recent event
    return rLEvents.sort((a, b) => b?.getDate()?.getTime() - a?.getDate()?.getTime())[0];
  }

  /**
   * Get user name from id
   * @param {string} id User id
   * @returns {string}
   */
  public getNameFromId(id: string, contacts: User[]): string {
    const contact = contacts.find(contact => contact.id?.toLowerCase() == id?.toLowerCase())
    const name = contact?.displayName ?? 'Unknown';
    return this.authService.userId === contact?.id ? 'you' : name;
  }

  /**
   * Leave the room
   * @param {string} roomId Room id
   * @param {User[]} contacts Contacts
   */
  public async leave(roomId: string, contacts: User[]): Promise<void> {
    try {
      // Show spinner
      this.spinner.show();

      // Get client
      const matrixClient = this.clientService.getClient();

      // Check if user is admin of the group
      const isAdmin = this.isAdmin(roomId, matrixClient.getUserId());

      // Check if is admin
      if (isAdmin) {
        // Get members of the group
        const validMembers = this.getValidMembers(roomId);

        // Check if group has an owner before leave
        const hasOwner = !!validMembers
          .filter((value) => value.userId !== matrixClient.getUserId())
          .find((value) => value.powerLevel == 100);

        // Get members names
        const membersNames = validMembers.map((value) => value.name);

        // Get contacts members of the group
        const members = contacts
          .filter((contact) => contact.id !== this.authService.userId) // Remove current user from the list
          .filter((contact) => membersNames.includes(contact.id.toLowerCase())) // Get only members of the group
          .sort((a, b) => a.displayName.localeCompare(b.displayName)); // Sort by display name

        // Check if group has more members and has an owner
        if (members.length !== 0 && !hasOwner) {
          // Get matrix user id from the next owner of the group
          const mUserId = this.formMatrixUserId(members[0].id);

          // Update another user to be the admin
          await this.setPowerLevel(roomId, mUserId, 100);
        }
        // If is the last member delete group avatar
        else if (members.length === 0) {
          try {
            const room = matrixClient.getRoom(roomId);
            const isMeet = isMeetGroup(room);
            await this.deleteGroupAvatar(roomId, isMeet);
          } catch (error) { }
        }
      }

      // Leave room
      await matrixClient.leave(roomId);
    } catch (error) {
      console.error(error);
    } finally {
      this.spinner.hide();
    }
  }

  /**
   * Redact (delete) event
   * @param {string} eventId Event Id
   * @param {string} roomId Room id
   */
  public async redactEvent(eventId: string, roomId: string = this.roomId.value, reason = 'deletion') {
    // Get client
    const mx = this.clientService.getClient();

    // Delete message
    try {
      await mx.redactEvent(roomId, eventId, undefined, reason ? { reason } : undefined);
    } catch (e) {
      throw e;
    }
  }

  public async getServerMillis() {

    // Path to get the server GMT
    const path = environment.baseURL + '/message/get-server-gmt';

    // Response
    const resp = await lastValueFrom(this.http.get(path).pipe(first()));

    return resp;
  }
  /**
   * Send edited msg
   * @param {MatrixEvent} mEvent Event that will be edited
   * @param {string} editedBody New event content
   */
  public async sendEditedMessage(mEvent: MatrixEvent, editedBody: string) {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Check if is reply
    const isReply = typeof mEvent.getWireContent()['m.relates_to']?.['m.in_reply_to'] !== 'undefined';

    // Form msg content
    let content = {
      body: ` * ${editedBody}`,
      msgtype: 'm.text',
      'm.new_content': {
        body: editedBody,
        msgtype: 'm.text',
      },
      'm.relates_to': {
        event_id: mEvent.getId(),
        rel_type: 'm.replace',
      },
    } as any;

    // If is reply - bind reply to msg content
    if (isReply) {
      const evBody = mEvent.getContent().body;
      const newBody = bindReplyToEditContent(evBody, editedBody);
      content.body = newBody;
      content['m.new_content'].body = newBody;
    }

    // Send edit message
    matrixClient.sendMessage(this.roomId.value, content);
  }

  /**
   * Set power level of user in the room
   * @param {string} roomId Room id
   * @param {string} userId User id
   * @param {number} powerLevel Power level
   */
  public async setPowerLevel(
    roomId: string,
    userId: string,
    powerLevel: number
  ) {
    // Get client
    const matrixClient = this.clientService.getClient();

    // Get room
    const room = matrixClient.getRoom(roomId);

    // Get Power level event
    const powerLevelEvent = room
      .getLiveTimeline()
      .getState(EventTimeline.FORWARDS)
      .getStateEvents('m.room.power_levels')[0];

    // Update another user to be the admin
    await matrixClient.setPowerLevel(
      roomId,
      userId,
      powerLevel,
      powerLevelEvent
    );
  }

  /**
   * Get all direct rooms
   * @returns {Room[]} All DMs rooms
   */
  public getDMsRooms(): Room[] {
    return Array.from(this.directs.values());
  }

  /**
   * Get all group rooms
   * @returns {Room[]} All group rooms
   */
  public getGroupsRooms(): Room[] {
    return Array.from(this.groups.values());
  }

  /**
   * Get all valid rooms
   * @returns {Room[]} All valid rooms
   */
  public getValidRooms(): Room[] {
    return this.clientService
      .getClient() // Get client
      ?.getRooms() // Get rooms
      ?.filter(room => this.isMemberInRoom(room)) // Filter only joined rooms
      ?? [];
  }

  /**
   * Create Group call
   * @param {string} roomId Room id
   */
  public async createGroupCall(roomId: string = this.roomId.value): Promise<void> {
    // Get Client
    const matrixClient = this.clientService.getClient();

    // Form content
    const content: IContent = {
      avatar_url: this.authService.user.photoUrl,
      displayname: getUserNameWithSector(this.authService.user),
      type: CallEventTypes.INVITE,
    };

    // Send event
    await matrixClient.sendEvent(roomId, CALL_EVENT, content);

    // Stop call event handler
    matrixClient.groupCallEventHandler.stop();
  }

  /**
   * Destroy Group call
   * @param {string} roomId Room id
   */
  public async destroyGroupCall(roomId: string = this.roomId.value): Promise<void> {
    // If has no room id
    if (!roomId) return;

    // Get Client
    const matrixClient = this.clientService.getClient();

    // Get last call event in this room
    const lastCallEvent = this.getLastCallEvent(roomId);

    // Return if last event not found
    if (!lastCallEvent) return;

    // Form content
    const content: IContent = {
      type: CallEventTypes.HANGUP,
      startDate: lastCallEvent.getDate(),
      endDate: new Date()
    }

    // Send call event
    await matrixClient.sendEvent(roomId, CALL_EVENT, content);

    // Stop call event handler
    matrixClient.groupCallEventHandler.stop();
  }

  /**
   * Get last call event
   * @param {string} roomId Room id
   * @returns {MatrixEvent} Matrix Event
   */
  public getLastCallEvent(roomId: string = this.roomId.value): MatrixEvent {
    // Get last call event
    return this.lastEvents.get(roomId)?.get(CALL_EVENT);
  }

  /**
   * Set last messages
   */
  public setLastMessages(): void {
    // For each room set last event
    this.getValidRooms().forEach(room => this.setLastEventForRoom(room))
  }

  /**
   * Set last messages for a specific room
   * @param {Room} room Room
   */
  public setLastEventForRoom(room: Room) {
    // Init variables
    const types: Set<string> = new Set();
    const value = new Map<string, MatrixEvent>();

    // Filtered and sorted events
    const events = [...room.getLiveTimeline().getEvents()]
      .sort((a, b) => b?.getDate()?.getTime() - a?.getDate()?.getTime())
      .filter(event => !isReactionDeleted(event) && !isRedactionToReaction(event));

    // Get the events types
    events.forEach(e => types.add(e.getType()))

    // Get value
    types.forEach(type => value.set(type, events.find(e => e.getType() == type)))

    // Set last events
    this.lastEvents.set(room.roomId, value);
  }

  /**
   * Set new last event
   * @param {MatrixEvent} mEvent Matrix Event
   */
  public setNewLastEvent(mEvent: MatrixEvent): void {
    // Init flags
    const roomId = mEvent.getRoomId();
    const eType = mEvent.getType();
    const events = this.lastEvents?.get(roomId);
    const cEvent = events?.get(eType);

    switch (true) {
      // New room
      case !events:
        // Get room
        const room = this.clientService
          .getClient()
          .getRoom(roomId);

        // If not exist
        if (!room) return;

        // Populate last events to this room
        this.setLastEventForRoom(room);
        break;

      case isRedactionToReaction(mEvent):
        // Get current reaction event
        const cReactionEvent = events?.get(EventType.Reaction);

        // Check if is the same id to remove from Map
        if (cReactionEvent?.getId() == mEvent.event.redacts) {
          events.delete(EventType.Reaction)
          this.lastEvents?.set(roomId, events);
        };
        break;

      // Update Map - last events
      case !cEvent:
      case mEvent.localTimestamp > cEvent?.localTimestamp:
        events?.set(eType, mEvent)
        this.lastEvents?.set(roomId, events);
        break;

      default:
        break;
    }
  }
}
