import { AvatarWithStatusComponent } from './avatar-with-status/avatar-with-status.component';
import { AvatarComponent } from './avatar/avatar.component';
import { CaptureGroupsSelectComponent } from './capture-groups-select/capture-groups-select.component';
import { ChatButtonComponent } from './chat-button/chat-button.component';
import { ChipInputFieldComponent } from './chip-input-field/chip-input-field.component';
import { ConferenceLinkComponent } from './conference-link/conference-link.component';
import { ConfirmDeletionComponent } from './confirm-deletion/confirm-deletion.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { CopyElementComponent } from './copy-element/copy-element.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DesktopSettingsDialog } from './desktop-settings-dialog/desktop-settings-dialog.component';
import { DevModeModalComponent } from './dev-mode-modal/dev-mode-modal.component';
import { DevicesSettingsComponent } from './devices-settings/devices-settings.component';
import { DialVoiceCallButtonComponent } from './dial-voice-call-button/dial-voice-call-button.component';
import { DialerComponent } from './dialer/dialer.component';
import { DialogDatepickerComponent } from './dialog-datepicker/dialog-datepicker.component';
import { DialogForceUpdateComponent } from './dialog-force-update/dialog-force-update.component';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { DialpadComponent } from './dialpad/dialpad.component';
import { DownloadComponent } from './download/download.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { ExitingConfirmationComponent } from './exiting-confirmation/exiting-confirmation.component';
import { FileSizeExceededComponent } from './file-size-exceeded/file-size-exceeded.component';
import { FloatDialpadButtonComponent } from './float-dialpad-button/float-dialpad-button.component';
import { FooterPropsComponent } from './footer-props/footer-props.component';
import { FormAdvancedOptionsComponent } from './form-advanced-options/form-advanced-options.component';
import { FormPropsComponent } from './form-props/form-props.component';
import { FullscreenViewComponent } from './fullscreen-view/fullscreen-view.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { GroupAutocompleteChipComponent } from './group-autocomplete-chip/group-autocomplete-chip.component';
import { GroupInfoComponent } from './group-info/group-info.component';
import { HangupButtonComponent } from './hangup-button/hangup-button.component';
import { NotificationCenterComponent } from './header/components/notification-center/notification-center.component';
import { HeaderComponent } from './header/header.component';
import { EditMenuBarComponent } from './image-cropper-dialog/components/edit-menu-bar/edit-menu-bar.component';
import { ImageCropperDialog } from './image-cropper-dialog/image-cropper-dialog.component';
import { ImgPickerComponent } from './img-picker/img-picker.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { JitsiRecordingStatusComponent } from './jitsi-recording-status/jitsi-recording-status.component';
import { MeetingInfoComponent } from './meeting-info/meeting-info.component';
import { PlayAudioButtonComponent } from './play-audio-button/play-audio-button.component';
import { PlayerComponent } from './player/player.component';
import { RequestPermissionDeniedComponent } from './request-permission-denied/request-permission-denied.component';
import { RequestPermissionComponent } from './request-permission/request-permission.component';
import { RoomFullInfoDialogComponent } from './room-full-info-dialog/room-full-info-dialog.component';
import { SelectRoleComponent } from './room-full-info-dialog/select-role/select-role.component';
import { RoundedActionButtonComponent } from './rounded-action-button/rounded-action-button.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SelectExternalRouteComponent } from './select-external-route/select-external-route.component';
import { SelectPbxDialogComponent } from './select-pbx-dialog/select-pbx-dialog.component';
import { SelectTagsComponent } from './select-tags/select-tags.component';
import { RemoveProfilePictureDialog } from './settings-dialog/components/remove-profile-picture-dialog/remove-profile-picture-dialog.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { SimilarContactsComponent } from './similar-contacts/similar-contacts.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { SwitchDeviceNotificationComponent } from './switch-device-notification/switch-device-notification.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { TypingComponent } from './typing/typing.component';
import { VoiceCallButtonComponent } from './voice-call-button/voice-call-button.component';

export const components: any[] = [
  AvatarComponent,
  AvatarWithStatusComponent,
  ContactInfoComponent,
  DialVoiceCallButtonComponent,
  DialerComponent,
  DialpadComponent,
  DownloadComponent,
  CustomTooltipComponent,
  DropdownInputComponent,
  EditMenuBarComponent,
  FloatDialpadButtonComponent,
  FullscreenViewComponent,
  HangupButtonComponent,
  ImageCropperDialog,
  PlayAudioButtonComponent,
  PlayerComponent,
  DialogDatepickerComponent,
  RemoveProfilePictureDialog,
  RoundedActionButtonComponent,
  SearchBarComponent,
  SelectExternalRouteComponent,
  DownloadComponent,
  RequestPermissionComponent,
  RequestPermissionDeniedComponent,
  SettingsDialogComponent,
  VoiceCallButtonComponent,
  ChatButtonComponent,
  GroupInfoComponent,
  FileSizeExceededComponent,
  TypingComponent,
  ImgPickerComponent,
  ConfirmDeletionComponent,
  SimpleDialogComponent,
  SimilarContactsComponent,
  DialogInfoComponent,
  SwitchDeviceNotificationComponent,
  HeaderComponent,
  NotificationCenterComponent,
  CopyElementComponent,
  JitsiRecordingStatusComponent,
  DevicesSettingsComponent,
  ExitingConfirmationComponent,
  InfiniteScrollComponent,
  DatepickerComponent,
  DevModeModalComponent,
  MeetingInfoComponent,
  GroupAutocompleteChipComponent,
  PlayerComponent,
  DesktopSettingsDialog,
  ExitingConfirmationComponent,
  ConferenceLinkComponent,
  ImportDialogComponent,
  SelectPbxDialogComponent,
  SelectTagsComponent,
  ChipInputFieldComponent,
  FooterPropsComponent,
  FormPropsComponent,
  FormAdvancedOptionsComponent,
  RoomFullInfoDialogComponent,
  GeneralInfoComponent,
  TimePickerComponent,
  CaptureGroupsSelectComponent,
  SelectRoleComponent,
  ConfirmDialogComponent,
  DialogForceUpdateComponent
];

export * from './avatar-with-status/avatar-with-status.component';
export * from './avatar/avatar.component';
export * from './chat-button/chat-button.component';
export * from './chip-input-field/chip-input-field.component';
export * from './conference-link/conference-link.component';
export * from './confirm-deletion/confirm-deletion.component';
export * from './confirm-dialog/confirm-dialog.component';
export * from './contact-info/contact-info.component';
export * from './copy-element/copy-element.component';
export * from './custom-tooltip/custom-tooltip.component';
export * from './datepicker/datepicker.component';
export * from './desktop-settings-dialog/desktop-settings-dialog.component';
export * from './dev-mode-modal/dev-mode-modal.component';
export * from './devices-settings/devices-settings.component';
export * from './dial-voice-call-button/dial-voice-call-button.component';
export * from './dialer/dialer.component';
export * from './dialog-datepicker/dialog-datepicker.component';
export * from './dialog-force-update/dialog-force-update.component';
export * from './dialog-info/dialog-info.component';
export * from './dialpad/dialpad.component';
export * from './download/download.component';
export * from './dropdown-input/dropdown-input.component';
export * from './exiting-confirmation/exiting-confirmation.component';
export * from './file-size-exceeded/file-size-exceeded.component';
export * from './float-dialpad-button/float-dialpad-button.component';
export * from './footer-props/footer-props.component';
export * from './form-advanced-options/form-advanced-options.component';
export * from './fullscreen-view/fullscreen-view.component';
export * from './general-info/general-info.component';
export * from './group-info/group-info.component';
export * from './hangup-button/hangup-button.component';
export * from './header/components/notification-center/notification-center.component';
export * from './header/header.component';
export * from './image-cropper-dialog/components/edit-menu-bar/edit-menu-bar.component';
export * from './image-cropper-dialog/image-cropper-dialog.component';
export * from './img-picker/img-picker.component';
export * from './import-dialog/import-dialog.component';
export * from './infinite-scroll/infinite-scroll.component';
export * from './player/player.component';
export * from './request-permission-denied/request-permission-denied.component';
export * from './request-permission/request-permission.component';
export * from './rounded-action-button/rounded-action-button.component';
export * from './search-bar/search-bar.component';
export * from './select-external-route/select-external-route.component';
export * from './select-pbx-dialog/select-pbx-dialog.component';
export * from './select-tags/select-tags.component';
export * from './settings-dialog/components/remove-profile-picture-dialog/remove-profile-picture-dialog.component';
export * from './settings-dialog/settings-dialog.component';
export * from './similar-contacts/similar-contacts.component';
export * from './simple-dialog/simple-dialog.component';
export * from './switch-device-notification/switch-device-notification.component';
export * from './time-picker/time-picker.component';
export * from './typing/typing.component';
export * from './voice-call-button/voice-call-button.component';

